<!-- Фиксированное меню, дублирующее основное и накладывающееся поверх него: -->
<aside id="id1538394451" class="aside aside--fixed" *ngIf="showMenu" [ngClass]="{'aside--closet': collapseMenu== true}">
  <div class="logo">
    <app-logo></app-logo>
  </div>
  <nav class="nav nav--relative">
  <a id="id1541669727" *hasPermission="[[userRoles.FinancialDirector]]" [routerLink]="['/widgets']" routerLinkActive="active"
    title="Главная" class="nav__link nav__link--main">
    <span class="nav__text">Главная</span>
  </a>
  <a id="id1538394484" *hasPermission="[[userRoles.Administrator, userRoles.Manager, userRoles.ManagerPlus, userRoles.Accountant, userRoles.FinancialDirector]]" [routerLink]="['/points']"
      routerLinkActive="active" title="{{settingList.pointName | titlecase}}" class="nav__link nav__link--pzu">
    <span class="nav__text">
      {{settingList.pointName | titlecase}}
    </span>
  </a>
  <a id="id1538394502" *hasPermission="[[userRoles.Administrator, userRoles.ManagerPlus, userRoles.Manager]]" [routerLink]="['/users']"
      routerLinkActive="active" title="Сотрудники" class="nav__link nav__link--staff">
      <span class="nav__text">Сотрудники</span>
  </a>
  <!--<a class="nav__link shipment-link">Отгрузки</a>-->
  <a id="id1538394513" *hasPermission="[[userRoles.Administrator, userRoles.Manager, userRoles.ManagerPlus, userRoles.Accountant, userRoles.Cashier, userRoles.FinancialDirector]]"
      [routerLink]="['/deals']" routerLinkActive="active" title="Список оплат"
      class="nav__link nav__link--deals">
    <span class="nav__text">Оплаты</span>
  </a>
  <ng-container *ngIf="this.settingList.prepaidAccess">
    <a id="id1556888067" *hasPermission="[[userRoles.Administrator]]"  [routerLink]="['/prepaid-import-history']" routerLinkActive="active"
      title="Импорт карт" class="nav__link nav__link--importhistory">
      <span class="nav__text">Импорт карт</span>
    </a>
    <a id="id11571144158" [routerLink]="['/app-activation-request-list']" routerLinkActive="active" title="Активация карт" class="nav__link nav__link--cards">
      <span class="nav__text">Активация карт</span>
    </a>
	</ng-container>

  <a id="id1541669616" *hasPermission="[[userRoles.FinancialDirector]]" [routerLink]="['/balances']" routerLinkActive="active"
    title="Балансы" class="nav__link nav__link--balance">
    <span class="nav__text">Балансы</span>
  </a>

  <a id="id1556888065" *ngIf="showRegistry" [routerLink]="['/registries']" routerLinkActive="active"
    title="Реестры для РНКО 'Единая касса'" class="nav__link nav__link--registers">
    <span class="nav__text">Реестры</span>
  </a>

  <a id="id1556888066" *hasPermission="[[userRoles.Administrator, userRoles.FinancialDirector]]" [routerLink]="['/xplat-registries']" routerLinkActive="active"
    title="Суточные реестры" class="nav__link nav__link--xplatregisters">
    <span class="nav__text">Суточные реестры</span>
  </a>

  <ng-container>
    <a id="id1550498789" *ngIf="isFinancialDirectorOfHeadLicensee()" [routerLink]="['/holding-structure']"
      routerLinkActive="active" title="Структура холдинга" class="nav__link nav__link--holding-structure">
      <span class="nav__text">Структура холдинга</span>
    </a>
  </ng-container>

  <a id="id1542633085" *hasPermission="[['support']]" [routerLink]="['/licensees']" routerLinkActive="active"
      title="Лицензиаты"
      class="nav__link nav__link--licensors">
      <span class="nav__text">Лицензиаты</span>
  </a>

  <a id="id1542633100" *hasPermission="[['support']]" [routerLink]="['/deals']" routerLinkActive="active"
      title="Все оплаты"
      class="nav__link nav__link--deals">
      <span class="nav__text">Все оплаты</span>
  </a>

  <a id="id1542633086" *hasPermission="[['support']]" [routerLink]="['/support/turnover']" routerLinkActive="active"
    title="Обороты компании"
    class="nav__link nav__link--turnover">
    <span class="nav__text">Обороты компании</span>
  </a>

  <a id="id1538394533" [routerLink]="['/settings']" routerLinkActive="active"
    title="Настройки"
    class="nav__link nav__link--settings"><span class="nav__text">Настройки</span></a>

  <div class="aside__contacts absolute">
    <a id="id1538394556" href="mailto:support@asumet.ru" class="support__email"></a>
    <span class="nav__text">Поддержка</span>
  </div>
  </nav>
</aside>
