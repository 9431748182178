<div class="support-licensee-users">
  <div class="flex-wrapper header">
    <h5 class="h5">Список сотрудников</h5>
    <a id="id1538477805" class="legend__reset-filtres" (click)="resetFilters()">Сбросить фильтры</a>
  </div>

  <div class="table" *ngIf="users">
    <div class="d-thead">
      <tsuz-users-page-header class="table__header-row" [filter]="filter" (apply)="applyFilter()"></tsuz-users-page-header>
    </div>
    
    <!--Наличие класса opened означает, что table__hidden-row будет показываться-->
    <ng-container *ngFor="let user of users; let i = index">
      <div id="id1542711438{{i}}" class="table__row" [ngClass]="{'opened': user.isShowDropDown == true}"
           tsuz-user-item [user]="user" (click)="dropDownUser(user.id)">
      </div>
      <div class="table__hidden-row">
        <td colspan="6" class="table__hidden-data table__hidden-data-plus">
          <div class="dropdown">
            <div class="dropdown__left-menu">
              <!--При клике на меню, у него должен появляться класс active и должен подключаться соответствующий блок-->
              <a id="id1542711451{{i}}"
                 class="dropdown__left-menu--link"
                 [ngClass]="{'active': user.isShowPoint == true}"
                 (click)="showRight('point', user.id)">
                "{{settingList.pointName}}" сотрудника</a>

              <a id="id1542711452{{i}}"
                 class="dropdown__left-menu--link"
                 [ngClass]="{'active': user.isShowReset == true}"
                 (click)="showRight('reset', user.id)">
                Сбросить пароль</a>
                <a id="id1538409017{{i}}"
                   class="dropdown__left-menu--link"
                   [ngClass]="{'active': user.isShowBlock == true}"
                   (click)="showRight('block', user.id)">Статус</a>
            </div>
            <div class="dropdown__main" show-pzu [user]="user" [licenseeId]="licenseeId" [pointName]="settingList.pointName" *ngIf="user.isShowPoint">
            </div>
            <div class="dropdown__main" reset-pass [user]="user"  [licenseeId]="licenseeId" *ngIf="user.isShowReset">
            </div>
            <div class="dropdown__main" edit-activity [user]="user" *ngIf="user.isShowBlock">
            </div>
          </div>
        </td>
      </div>
    </ng-container>
  </div>

  <app-pagination [(model)]="filter" (apply)="getUsers()"></app-pagination>

</div>
