import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LicenseeFilter } from "../../licensee/models/LicenseeFilter";
import { HttpParams } from "@angular/common/http";
import {OrganizationTurnoverFilter} from '../../models/support/Turnover/OrganizationTurnoverFilter';
import {OrganizationTurnoverOutput} from '../../models/support/Turnover/OrganizationTurnoverResponse';
import { BaseHttpService, ILicenseeBalance, UserFilter, PointFilter } from 'tsuz-common';

@Injectable({
  providedIn: "root"
})
export class SupportService {
  constructor(private http: BaseHttpService) {}

  private licenseesUrl = "/support/licensees";

  addParam(params: HttpParams, value: any, paramName: any): HttpParams {
    if (value) {
      params = params.append(paramName, value);
    }
    return params;
  }

  addListParam(params: HttpParams, value: any, paramName: any): HttpParams {
    if (value) {

      let arrayValue: string[];
      if (Array.isArray(value))
      {
        arrayValue = value;
      } else {
        arrayValue = String(value).split(',');
      }


      for(let item of arrayValue as string[]) {
        params = params.append(paramName, item);
      }
    }
    return params;
  }

  /** Получение списка лицензиатов */
  getAllLicensees(filter: LicenseeFilter): Observable<any> {
    let params = new HttpParams();
    params = this.addParam(params, filter.licenseeId, 'id');
    params = this.addParam(params, filter.isActive, 'isActive');
    params = this.addParam(params, filter.organizationInn, 'organizationInn');
    params = this.addParam(params, filter.organizationName, 'organizationName');
    params = this.addParam(params, filter.directorName, 'directorName');
    params = this.addParam(params, filter.take, 'take');
    params = this.addParam(params, filter.page, 'page');
    
    // if (filter.categories){
    //   if (Array.isArray(filter.categories)){
    //     params = this.addListParam(params, filter.categories, 'categories');
    //   } else {
    //     params = this.addListParam(params, String(filter.categories).split(','), 'categories');
    //   }
    // }

    return this.http.get(this.licenseesUrl + "?" + params.toString());
  }

  getLicensee(licenseeId: string): Observable<any> {
    return this.http.get(`/support/licensees/${licenseeId}`);
  }

  /** Получение всех точек */
  getAllPoint(): Observable<any> {
    return this.http.get("/getallpoints");
  }

   /** Получение категорий лицензиатов */
   getLicenseeCategories(): Observable<any> {
    return this.http.get("/LicenseeCategory");
  }

  /** Получение точек лицензиата */
  getPoints(licenseeId: string, filter: PointFilter): Observable<any> {
    return this.http.get(`/support/licensees/${licenseeId}/points`, filter);
  }

  getUsers(licenseeId: string, filter: UserFilter): Observable<any> {
    return this.http.get(`/support/licensees/${licenseeId}/users`, filter);
  }

  /** Получение информации об оборотах организаций */
  getOrganizationsTurnover(filter: OrganizationTurnoverFilter): Observable<OrganizationTurnoverOutput> {
    const query = filter.toString();
    return this.http.get(`/support/turnover` + query);
  }

  /** Получение баланса лицензиата */
  getLicenseeBalance(licenseeId: string): Observable<ILicenseeBalance> {
    return this.http.get(`/support/licensee/${licenseeId}/balance`);
  }

  // Получение информации о лицензиата для тп
  getSupportLicenseeInfo(dealId: any): Observable<any> { 
    return this.http.get(`/support/deals/${dealId}/licensee`); 
  }

}
