import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class AppInformationService {
  constructor(protected http: HttpClient,
    @Inject('LOCALSTORAGE') private localStorage: any) {
  }

  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private currentVersion = "";

  checkVersion() {
     this.http.get('/auto-reload/version.json?t=' + new Date().getTime()).subscribe((data: any) => {
       const newHash = data.hash;
       const newVersion = data.version;
      
       const currentVersion = localStorage.getItem("version");

       if (this.currentHash !== newHash || currentVersion !== newVersion) {
         localStorage.setItem("version", newVersion);
         window.location.reload();
       }
    });
  }
  public initVersionCheck() {
    this.checkVersion();
    setInterval(() => {
      this.checkVersion();
    }, environment.appVersionInterval);
  }

}
