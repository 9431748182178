<div id="id1538395308" class="header-wrapper" *ngIf="profile && isLogged">
  <div class="header">
    <a id="id1538395341" class="header__toggler" (click)="collapseMenu()"></a>
    <a id="id1538395369" class="header__company-name">{{profile.licensee.title}}</a>
    <div class="header__username-wrapper">
      <div id="id1538395390" class="header__balance-wrapper" *ngIf="showBalance">
          <div class="header__balance-label label">
            <span>Счет лицензиата</span>
          </div>
         <div id="id1538395416" class="header__balance" title="Дата обновления: {{balanceInfo.updatedDate}} МСК" (click)="onBalanceClick()">
              {{balanceInfo.balance | money: balanceInfo.currency}}
          </div>
      </div>
      <app-accepted-deals *ngIf="showAcceptedDeals">
      </app-accepted-deals>
      <div class="account">
        <div class="account__hoverblock">
          <section class="account__hoverblock-bg">
            <div class="account__hoverblock-main">
              <div class="user-name"><span id="id1538395450">{{profile.surname}} {{profile.name}}</span></div>
              <div class="account__user-role"><span id="id1538395464">{{profile.roles | roleName}}</span></div>
            </div>
            <div class="account__hoverblock-footer">
                <a id="id1538395488" class="account__logout" (click)="logout()"> <span class="account__logout-text">Выход</span>  </a>
            </div>
          </section>
        </div>
        <div>
          <div class="account__user-info">
            <div class="account__initials">
              <span id="id1538395509" >{{profile.icon}}</span>
            </div>
            <div>
                <div id="id1538395546" class="account__main-role">{{profile.roles | roleName}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
