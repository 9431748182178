import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
import { DEFAULT_CATEGORY, DEFAULT_MEASURE_UNITS, DEFAULT_MEAUSURE } from '../constants/products-options';
import { IProductService } from '../../services/interfaces/IProductService';
import { UploadedFileListChangedEvent } from '../upload-files/UploadedFileListChangedEvent';
import { UPLOAD_PRODUCTS_EXAMPLE_BASE64 } from '../../constants/upload-products-example-base64';

interface AddUploadProduct {
  licenseeId: string;
  action: ('upload-file' | 'add-product');
  category?: string;
  categories?: {id: string, name: string}[];
}

@Component({
  selector: 'app-upload-products',
  templateUrl: './upload-products.component.html',
  styleUrls: ['./upload-products.component.scss']
})
export class UploadProductsComponent extends SimpleModalComponent<AddUploadProduct, any> implements OnInit {
  licenseeId: string;
  action: string;
  category: string;
  categories: {id: string, name: string}[] = [];

  isFileUpload = false;

  readonly measureUnitOptions = DEFAULT_MEASURE_UNITS;
  readonly productsFileFormat = 
`Ключ;Наименование
ключ_товара1;название_товара1
ключ_товара2;название_товара2
ключ_товараN;название_товараN
`;

  filteredMeasureUnitOptions: Observable<string[]>;

  form = this.fb.group({
    productName: [''],
    productKey: [''],

    productsFile: [''],
    category: [DEFAULT_CATEGORY],

    measure: [DEFAULT_MEAUSURE],
    measureUnits: this.fb.array([
      this.fb.group(this.measureUnitOptions[0]),
      this.fb.group(this.measureUnitOptions[1]),
    ])
  });

  readonly example = this.sanitizer.bypassSecurityTrustUrl(UPLOAD_PRODUCTS_EXAMPLE_BASE64);
  
  constructor(
    private fb: FormBuilder, 
    @Inject("ProductService") private productService: IProductService,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit() {
    // заменять дефолтную категорию явно указанной категорией
    if (this.category) {
      this.form.get('category').setValue(this.category);
    }

    this.isFileUpload = this.action === 'upload-file';
    if (this.isFileUpload) {
      this.categories.push({id: null, name: DEFAULT_CATEGORY});
    }
  }

  get measureUnits() : FormArray {
    return this.form.get('measureUnits') as FormArray;
  }

  get canDeleteMeasureUnit() : boolean {
    return this.measureUnits.length > 1;
  }

  deleteMeasureUnit(index) {
    if (this.canDeleteMeasureUnit) {
      this.measureUnits.removeAt(index);
    }
  }
  addMeasureUnit() {
    this.measureUnits.push(this.fb.group({name: '', shortName: ''}));
  }

  onUploadFileSubmit() {
    const formData = new FormData();
    const value = this.form.value;

    if (value.productsFile instanceof File) {
      formData.append('ProductsFile', value.productsFile);
    }

    formData.append('Category', value.category);
    formData.append('Measure', value.measure);

    for (var i = 0; i < value.measureUnits.length; i++) {
      formData.append(`MeasureUnits[${i}].name`, value.measureUnits[i].name);
      formData.append(`MeasureUnits[${i}].shortName`, value.measureUnits[i].shortName);
    }

    this.productService.uploadFile(this.licenseeId, formData)
      .subscribe(result => {
        this.result = true;
        this.close();
      });
  }

  onAddProductSubmit() {
    this.result = this.form.value;
    this.close();
  }

  productsFileListChanged(event: UploadedFileListChangedEvent): void {
    const fileList = event.uploadedFileList;
    const file = fileList.hasNonEmptyFile ? fileList.files[0].file : null;
    let value = { productsFile: file };
    this.form.patchValue(value);
  }    

  measureUnitChanged($event: MatAutocompleteSelectedEvent, group: FormGroup) {
    const option = this.measureUnitOptions.find(u => u.name === $event.option.value);
    if (option) {
      group.get('shortName').setValue(option.shortName);
    }
  }
}
