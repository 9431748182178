<div class="wrapper">
    <button class="download-points" type="button" (click)="download.emit()">
        <img src="/img/download.svg"/> Выгрузить
    </button>
    
    <tsuz-upload-files
        *hasPermission="[[userRoles.ExecutiveOfficer]]"
        [labelText]="'Загрузить'"
        [minFileCount]="0"
        [maxFileCount]="1"
        [acceptFileTypes]="['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
        [tooltip]="pointsFileFormat"
        [uploadedFileList]="pointsFileList"
        (fileListChanged)="onPointsFileChanged($event)">
    </tsuz-upload-files>
</div>