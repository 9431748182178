<!--SEARCH & CREATE-->
<div class="upblock">
  <h1 class="h1">Реестры для РНКО 'Единая касса'</h1>
  <div class="upblock__btn-wrapper">
    <div class="input__wrapper">
      <input class="input search-input"
             id="id1556035978"
             type="text"
             [(ngModel)]="filter.name"
             (input)="search()"
             placeholder="Найти...">
    </div>

    <div class="upblock__item">
      <button id="id1556035981"
              class="btn btn--active btn--plus"
              (click)="state=ViewState.createRegistry">
        Создать реестр
      </button>

      <div class="create-form zindex"
           *ngIf="state==ViewState.createRegistry">
        <div class="">
          <input class="input"
                 [(ngModel)]="createRegistryDateRange"
                 [owlDateTimeTrigger]="dt10"
                 [owlDateTime]="dt10"
                 [selectMode]="'range'"
                 readonly="readonly">
          <owl-date-time #dt10
                         [firstDayOfWeek]="1"></owl-date-time>
        </div>
        <div class="filter__footer">
          <a class="btn2 active float-left"
             id="id1556035985"
             (click)="save()">Сохранить</a>
          <a id="id1556035988"
             class="btn2 float-right"
             (click)="state=ViewState.none">Отмена</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!--FILTER-->
<div class="upblock registries__legend-wrapper">
  <div class="registries__legend">
    <div class="label--mobile">
      Фильтр статусов реестров:
    </div>

    <div class="registries__filter registries__filter--generated"
         id="id1556035991"
         [ngClass]="{'active': filter.status==RegistryStatuses.Generated}"
         (click)="onChangeFilterRegistryStatus(RegistryStatuses.Generated)">
      Cформирован
    </div>

    <div class="registries__filter registries__filter--sending"
         id="id1557040081"
         [ngClass]="{'active': filter.status==RegistryStatuses.Sending}"
         (click)="onChangeFilterRegistryStatus(RegistryStatuses.Sending)">
      Отправляется в РНКО
    </div>

    <div class="registries__filter registries__filter--successfully-sent"
         id="id1556035994"
         [ngClass]="{'active': filter.status==RegistryStatuses.SuccessfullySent}"
         (click)="onChangeFilterRegistryStatus(RegistryStatuses.SuccessfullySent)">
      Отправлен в РНКО
    </div>

    <div class="registries__filter registries__filter--rnko-applied"
         id="id1567421081"
         [ngClass]="{'active': filter.status==RegistryStatuses.RnkoApplied}"
         (click)="onChangeFilterRegistryStatus(RegistryStatuses.RnkoApplied)">
      Проведен в Банке
    </div>

    <div class="registries__filter registries__filter--rnko-rejected"
         id="id1567421092"
         [ngClass]="{'active': filter.status==RegistryStatuses.RnkoRejected}"
         (click)="onChangeFilterRegistryStatus(RegistryStatuses.RnkoRejected)">
      Отклонен в Банке
    </div>
    <div class="registries__filter registries__filter--rnko-partial-applied"
         id="id1567421093"
         [ngClass]="{'active': filter.status==RegistryStatuses.RnkoPartialApplied}"
         (click)="onChangeFilterRegistryStatus(RegistryStatuses.RnkoPartialApplied)">
      Частично обработан
    </div>
    <div class="registries__filter registries__filter--failure-sent"
         id="id1618307253"
         [ngClass]="{'active': filter.status==RegistryStatuses.FailureSent}"
         (click)="onChangeFilterRegistryStatus(RegistryStatuses.FailureSent)">
      Ошибка отправки
    </div>
  </div>

  <div>
    <a class="registries__filter--reset-filtres"
       id="id1556036001"
       (click)="resetFilters()">Сбросить фильтры</a>
  </div>
</div>

<!--LIST-->
<div class="d-table table">

  <!--HEADER-->
  <ng-container *ngIf="registries">
    <div class="table__header-row ">

      <div id="id1556036007"
           class="table__header-cell table__header-cell--w300 filter__column relative">
        <div>Название файла</div>
      </div>

      <div id="id1556036011"
           class="table__header-cell table__header-cell--w130 filter__column relative">
        <app-date-range-filter
          [(model)]="filter.create"
          (apply)="getRegistries()"
          id="id1557944883"
          [paginationFilter]="filter">
          Дата формирования реестра
        </app-date-range-filter>
      </div>

      <div class="table__header-cell table__header-cell--w130 relative"
            id="id1556036021">
        <div>Статус доставки реестра в рнко</div>
      </div>

      <div id="id1556036024"
           class="table__header-cell table__header-cell--w200 relative">
        <div>Действия</div>
      </div>

      <div class="table__header-cell relative  filter__column"
           id="id1557935240">
        <app-date-range-filter
          [(model)]="filter.period"
          (apply)="getRegistries()"
          id="id1557948448"
          [paginationFilter]="filter">
          Период реестра с и до
        </app-date-range-filter>
      </div>
    </div>
  </ng-container>

  <!--TABLE-->
  <ng-container *ngIf="registries">
    <ng-container *ngFor="let registry of registries.items; let i=index">

      <!--ROW-->
      <div class="d-table-row table__row">
      <div class="table__col">
          <div class="table__data">
            <div class="flex-wrapper">
              <div class="registries__filter registries__filter--{{registry.status | registryStatusesClass}}"
                  id="id1556036029{{i+1}}">
              </div>
              <div class="registries__name">
                {{registry.name}}
              </div>
            </div>
        </div>
      </div>

      <div class="table__cell">
        <div class="table__data">
          <span class="label--mobile">Дата:</span>
          {{registry.created | timeZone : registry.licenseeTimeZoneId}}
        </div>
      </div>

        <div class="table__cell ">
          <div class="table__data">
            <span class="label--mobile">Статус:</span>
            {{registry.status | registryStatusesString}}
          </div>
        </div>

        <div class="table__cell">
          <div class="table__data">
            <button class="btn btn-link"
                    (click)="download(registry)">
              Скачать
            </button>
            <button class="btn btn-link"
                    *ngIf="registry.status==RegistryStatuses.Generated || registry.status==RegistryStatuses.FailureSent"
                    (click)="state=ViewState.smsConfirmation;sms.check(registry)">
              Отправить
            </button>
          </div>
        </div>

        <div class="table__cell">
          <div class="table__data">
            <span class="label--mobile">Период:</span>
            {{(registry.dateFrom | timeZone : registry.licenseeTimeZoneId) || 'дата начала периода не указана' }} -
            {{(registry.dateTo | timeZone : registry.licenseeTimeZoneId) || 'дата окончания периода не указана'}}
          </div>
        </div>

      </div>

    </ng-container>
  </ng-container>

</div>

<!--PAGINATION-->
<app-pagination [(model)]="filter" (apply)="getRegistries()">
</app-pagination>

<!--SMS-->
<div class="modal zindex"
     [hidden]="state!=ViewState.smsConfirmation">
  <app-sms-confirmation #sms
                        (cancel)="state=ViewState.none"
                        (confirm)="send($event);state=ViewState.none;"></app-sms-confirmation>
</div>


<div class="modal zindex"  [hidden]="savingRegistry==false">
  <app-loading-modal  (cancel)="savingRegistry=false"
  [message] = "'Формрование реестра. Пожайлуста, подождите.'"  [title] = "'Формирование реестра'"
  ></app-loading-modal>
</div>
