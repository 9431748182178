import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPlatformBindingRequestCheckState } from '../shared/platform-binding-request-check-state.model';
import { PlatformBindingRequestService } from '../shared/platform-binding-request.service';
import { Observable ,  Subject ,  of } from 'rxjs';
import { IPlatformBindingRequest } from '../shared/platform-binding-request.model';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-platform-binding-request-check-state',
  templateUrl: './platform-binding-request-check-state.component.html',
  styleUrls: ['./platform-binding-request-check-state.component.scss']
})
export class PlatformBindingRequestCheckStateComponent {
  errorLoading$ = new Subject<string>();
  result$: Observable<IPlatformBindingRequest>;

  constructor(
    private platformBindingRequestsService: PlatformBindingRequestService,
    public dialogRef: MatDialogRef<PlatformBindingRequestCheckStateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPlatformBindingRequestCheckState
  ) {
    this.result$ = this.platformBindingRequestsService
      .checkState(data.id)
      .pipe(catchError((error: string) => {
        this.errorLoading$.next(error);
        return of<IPlatformBindingRequest>();
      }));
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
