<ng-container *ngIf="deal">
  <div class="electronic-psa">
    <div class="electronic-psa__header">
      <h1 class="electronic-psa__header-title">
        СДЕЛКА
      </h1>
      <h2 class="electronic-psa__header-subtitle">
        №{{ deal.dealNumber }} от {{ deal.createDate | timeZone : deal.licenseeTimeZoneId }}
      </h2>
      <p>К выплате продавцу: <span class="electronic-psa__header-total-amount">{{ deal.sum | money: deal.currency }}</span></p>      
      <p class="electronic-psa__header-commission">Комисcия: <span >{{ deal.tsuzComission | money: deal.currency }}</span></p>
      <p>Итого с учетом комисcии: <span class="electronic-psa__header-total-amount">{{ (deal.sum + deal.tsuzComission) | money: deal.currency }}</span></p>
      <div *ngIf="approvalMessage" class="electronic-psa__header-approval-error" innerHtml="{{ approvalMessage }}">
      </div>
      <div class="electronic-psa__header-button-container">
        <button *ngIf="canPay"
                [disabled]="isSaving"
                type="button"
                class="btn btn-primary"
                (click)="pay()">
          Оплатить
        </button>
      </div>
      <div>
        <button *ngIf="canEdit"
                type="button"
                class="btn btn-save"
                [disabled]="isLoading"
                (click)="cancel()"
                id="1592975911">
          Отменить
        </button>
      </div>
      <button *ngIf="canEdit"
              [disabled]="isSaving"
              type="button"
              class="btn btn-save"
              (click)="editDeal()"
              id="1592975996">
        Редактировать
      </button>
    </div>

    <div class="electronic-psa__header">
      <h2 class="electronic-psa__header-subtitle">
        Электронный ПСА №{{deal.agreement}} от {{deal.agreementDate}}
      </h2>
    </div>

    <ng-container>
      <div class="electronic-psa__block">
        <div class="electronic-psa__block-title">
          Получатель
        </div>
        <div class="electronic-psa__receiver electronic-psa__block-body">
          <div>
            <div class="electronic-psa__field">
              <div class="electronic-psa__field-label">
                Компания
              </div>
              <div>
                {{profile.licensee.title}}
              </div>
            </div>
          </div>
          <div>
            <div class="electronic-psa__field">
              <div class="electronic-psa__field-label">
                {{settingList.pointName}}
              </div>
              <span>{{deal.point.title}}</span>
            </div>
          </div>
          <div>
            <div class="electronic-psa__field">
              <div class="electronic-psa__field-label">
                Адрес
              </div>
              <div>
                <span>{{deal.point.address}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="electronic-psa__block">
        <div class="electronic-psa__block-title">
          Продавец
        </div>
        <div class="electronic-psa__seller electronic-psa__block-body">
          <div class="electronic-psa__seller__left">
            <div class="electronic-psa__field">
              <div class="electronic-psa__field-label">
                Фамилия
              </div>
              <div class="requisites__item">
                <span>{{deal.customer.surname}}</span>
              </div>

            </div>
            <div class="electronic-psa__field">
              <div class="electronic-psa__field-label">
                Имя
              </div>
              <div class="requisites__item">
                <span>{{deal.customer.firstName}}</span>
              </div>
            </div>
            <div class="electronic-psa__field">
              <div class="electronic-psa__field-label">
                Отчество
              </div>
              <div class="requisites__item">
                <span>{{deal.customer.middleName}}</span>
              </div>
            </div>
            <div class="electronic-psa__field">
              <div class="electronic-psa__field-label">
                Телефон
              </div>
              <div class="requisites__item">
                <span>{{deal.customer.phone}}</span>
              </div>
            </div>
            <div class="electronic-psa__field" *ngIf="deal.inn">
              <div class="electronic-psa__field-label">
                ИНН самозанятого
              </div>
              <div class="requisites__item">
                <span>{{deal.inn}}</span>
              </div>
            </div>
          </div>
          <div class="electronic-psa__seller__right">
            <div class="electronic-psa__seller__right-title">
              Паспортные данные
            </div>
            <div class="electronic-psa__seller__right-content">
              <div class="electronic-psa__seller__right-content-row">
                <div class="electronic-psa__field">
                  <div class="electronic-psa__field-label">
                    Гражданство
                  </div>
                  <span>{{passportCountry}}</span>
                </div>
                <div class="electronic-psa__field">
                  <div class="electronic-psa__field-label">
                    Серия и номер паспорта
                  </div>
                  <div class="requisites__item">
                    <span>{{deal.customer.passportSeries}}</span>
                  </div>
                </div>
              </div>
              <div class="electronic-psa__seller__right-content-row">
                <div class="electronic-psa__field">
                  <div class="electronic-psa__field-label">
                    Место рождения
                  </div>
                  <div class="requisites__item">
                    <span>{{deal.customer.birthPlace}}</span>
                  </div>
                </div>
                <div class="electronic-psa__field">
                  <div class="electronic-psa__field-label">
                    Дата рождения
                  </div>
                  <div class="requisites__item">
                    <span>{{deal.customer.birthday}}</span>
                  </div>
                </div>


              </div>
              <div class="electronic-psa__seller__right-content-row">
                <div class="electronic-psa__field">
                  <div class="electronic-psa__field-label">
                    Кем выдан
                  </div>
                  <div class="requisites__item">
                    <span>{{deal.customer.passportFrom}}</span>
                  </div>
                </div>
                <div class="electronic-psa__field">
                  <div class="electronic-psa__field-label">
                    Прописка
                  </div>
                  <div class="requisites__item">
                    <span>{{deal.customer.address}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="electronic-psa__block">
        <div class="electronic-psa__block-title">
          Товарная таблица
        </div>
        <div class="electronic-psa__products electronic-psa__block-body">
          <ng-container>
            <div class="electronic-psa__products-row" *ngFor=" let prod of deal.products;let i = index">
              <ng-container>

                <div class="electronic-psa__products-order">№ {{ i + 1 }}</div>
                <div class="electronic-psa__products-column">
                  <div class="electronic-psa__field">
                    <div class="electronic-psa__field-label">
                      Категория
                    </div>
                    <span>{{prod.title}}</span>
                  </div>
                </div>
                <div class="electronic-psa__products-column">
                  <div class="electronic-psa__field">
                    <div class="electronic-psa__field-label">
                      Цена
                    </div>
                    <span>{{prod.measureUnitPrice | money: deal.currency}}</span>
                  </div>
                </div>
                <div *ngIf="!deal.dealSettings.hideTrashPercent" class="electronic-psa__products-column">
                  <div class="electronic-psa__field">
                    <div class="electronic-psa__field-label">
                      Засор, %
                    </div>
                    <span>{{prod.trash}}</span>
                  </div>
                </div>
                <div *ngIf="!deal.dealSettings.hideWeight" class="electronic-psa__products-column">
                  <div class="electronic-psa__field">
                    <div class="electronic-psa__field-label">
                      {{getMeasurePlaceholder(i)}}
                    </div>
                    <span>{{prod.measureUnitValue}}</span>
                  </div>
                </div>
                <div class="electronic-psa__field">
                  <div class="electronic-psa__field__sum">
                    Сумма
                  </div>
                  <span>{{ prod.totalPrice | money: deal.currency }}</span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>

      </div>
      <div class="electronic-psa__block" *ngIf="hasFile()">
        <div class="electronic-psa__attach">
          <button class="payment-modal__psa-block__attach-button" type="button" (click)="getFile(deal.id, 0, $event)">
            <img src="/img/attach.svg" /> Скан ПСА
          </button>

        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
