import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {PointsComponent} from './points/points.component';
import {PointLimitComponent} from '../../../projects/tsuz-common/src/components/points/point-limit/point-limit.component';
import {PointEmpComponent} from './points/points-item/point-emp/point-emp.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxMaskModule} from 'ngx-mask';
import {WidgetsModule} from '../widgets/widgets.module';
import { TsuzCommonModule } from 'tsuz-common';
import { environment } from 'environments/environment';
import { UsersService } from '@services/users.service';
import { LicenseeService } from '@services/licensee.service';
import { UserSettingService } from '@services/user-setting.service';
import { PointService } from '@services/point.service';
import { WithdrawTypeService } from '@services/withdraw-type.service';
import { ProductService } from '@services/product.service';

@NgModule({
  declarations: [
    PointsComponent,
    PointLimitComponent,
    PointEmpComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgxMaskModule,
        WidgetsModule,
        TsuzCommonModule.forRoot(environment, LicenseeService, UsersService, UserSettingService, PointService, WithdrawTypeService, ProductService)
    ],
  exports: [
    PointsComponent,
    PointLimitComponent,
    PointEmpComponent,
  ]
})
export class PointsModule {
}
