import { Component } from '@angular/core';
import { LimitsData } from '../../../models/widgets/limits-data';
import { WidgetService } from '../../../services/widget.service';
import { WidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-limits-widget',
  templateUrl: './limits-widget.component.html',
  styleUrls: ['./limits-widget.component.scss', '../base-widget/base-widget.component.scss']
})
export class LimitsWidgetComponent implements WidgetComponent {
  
  public limitsData: LimitsData = {} as LimitsData;
  public isLoadingCompleted: boolean = false;
  public hasError: boolean = false;
  public error: string;

  constructor(private widgetService: WidgetService) { }

  async ngOnInit() {
    const limitsData = await this.widgetService.geLimitsData().catch(err => {
      this.error = err;
      this.hasError = true;
    });
    this.limitsData = limitsData as LimitsData || this.limitsData;

    this.isLoadingCompleted = true;
  }

}