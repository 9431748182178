import { emailMask } from "text-mask-addons";

export function getPhoneMask(rawValue: string) {
    //если во время вставки из буфера в номере был плюс - убрать лишний плюс
    if (rawValue[0] === '+' && rawValue[1] === '+') {
        rawValue = rawValue.substring(1);
    }

    // добавить плюс в начале, если его нет
    if (rawValue[0] !== '+') {
        rawValue = '+' + rawValue;
    }

    if (rawValue.length === 1) {
        // чтобы определить первую цифру 7 или 9 для подходящего паттерна
        // пробел - костыль для библиотеки text-mask, которая хочет удалить '+' для UX
        return ['+', /\d/, ' '];
    }
    else if (rawValue[1] === '7') {
        // российский номер +7
        return ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/];
        //russian
    }
    else if (rawValue.substring(1, 4) == '997') {
        // потенциальный казахстанский номер +997
        return ['+', /\d/, /\d/, /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/];
    }
    else {
        // 3 цифры по умолчанию, если не +7
        return ['+', /\d/, /\d/, /\d/];
    }
}

export function getLoginMask(rawValue: string) {
    const fullLoginMask: RegExp[] = [/[A-Za-z]/]; // первый символ - только латиница
    const symbolMask = /[A-Za-z0-9_.@-]/; // последующие символы - допустимы латиница, цифры и символы _.@-

    // проверить, подходит ли последний символ под стандартную маску, если нет - не учитываем его при формировании нового fullLoginMask
    let skip = RegExp(symbolMask).test(rawValue[rawValue.length - 1]) ? 0 : 1;

    // начинаем с 1, так как маска для первого символа была добавлена при инициализации массива loginMask
    for (let i = 1; i < rawValue.length - skip; i++) {
        fullLoginMask.push(symbolMask);
    }

    return fullLoginMask;
}

export function getEmailMask(raw, config) {
  const mask = emailMask.mask(raw, config);

  const rawAmpersandIndex = raw.indexOf('@');
  const rawDotIndex = raw.indexOf('.', rawAmpersandIndex);
  const maskAmpersandIndex = mask.indexOf('@');
  const maskDotIndex = mask.indexOf('.', maskAmpersandIndex);

  // стереть @ и точку, если они не были написаны
  if (maskAmpersandIndex >= 0) {
    if (rawAmpersandIndex >= 0) {
      if (maskDotIndex >= 0 && rawDotIndex < 0) {
        mask.splice(maskDotIndex);
      }
    }
    else {
      mask.splice(maskAmpersandIndex);
    }
  }
  return mask;
}

export function getFioMask(raw: string) {
  const space = /[ ]/;
  const firstLetter = /[A-zА-яёЁ]/;
  const otherLetter = /[A-zА-яёЁ-]/;

  const mask: RegExp[] = [];
  
  // убираем невалидные символы и берём максимум 3 части, разделённые пробелом
  const splitted = raw.replace(/[^A-zА-яёЁ -]/, '').split(' ').slice(0, 3);

  for (let i = 0; i < splitted.length; i++) {
    const part = splitted[i];
    if (part === '') {
      continue; 
    }

    mask.push(firstLetter);

    // пропускаем один символ, так как для первого символа уже добавлена маска выше
    [...part].slice(1).forEach(symbol => {
      mask.push(otherLetter);
    });

    // добавляем пробел для всех частей, кроме посленей
    if (i < splitted.length - 1) {
      mask.push(space); 
    }
  }
  return mask;
}