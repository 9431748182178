import {Component, HostListener, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService, MenuService, UserRoles, BaseHttpService} from 'tsuz-common';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  @Input() loadingLogo = true;
  title: string;
  logoUrl: string;
  mobileLogoUrl: string;
  showLogoForCollapsedMenu: boolean;
  logoLoadError = false;
  mobileLogoLoadError = false;
  showDefault = false;

  constructor(private baseHttpService: BaseHttpService,
              private authService: AuthService,
              private sanitizer: DomSanitizer,
              private menuService: MenuService
              ) { }

  ngOnInit() {
    this.title = environment.title;

    const isSupport = this.authService.inRole(UserRoles.Support);
    if (this.loadingLogo && !isSupport) {
      this.generateCustomLogoUrl();
    }

    this.onResize();
  }

  generateCustomLogoUrl() {
    const urlPrefix = `${environment.apiUrl}/Logo/GetLogo/${this.authService.getLicenseeId()}`;
    this.logoUrl = `${urlPrefix}/Desktop`;
    this.mobileLogoUrl = `${urlPrefix}/Mobile`;

    this.menuService.status.subscribe((val: boolean) => {
      this.showLogoForCollapsedMenu = val;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    let width = window.innerWidth;
    if (event) {
      width = event.target.innerWidth;
    }


    this.showDefault = !this.loadingLogo
                      ||  width > 790 && this.logoLoadError
                      || width <= 790 && this.mobileLogoLoadError;
  }
}
