<div class=" table__cell">
  <div class="point__number-wrapper">
    <div class="point__number">{{ index }}</div>
  </div>
</div>
<div class=" table__cell">
  <div class="table__data table__data--address">
    <div class="address">{{ document.accountDebitChart }}</div>
  </div>
</div>
<div class=" table__cell">
  <div class="table__data table__data--address">
    <div class="address">{{ document.chartDebit }}</div>
  </div>
</div>
<div class=" table__cell">
  <div class="table__data table__data--address">
    <div class="address">{{ document.absTransactionId }}</div>
  </div>
</div>
<div class="table__cell">
  <div class="table__data table__data--address">
    <div class="address">{{ document.oborotDebit | money: currency }}</div> 
  </div>
</div>
<div class=" table__cell">
  <div class="table__data table__data--address">
    <div class="address">{{ document.dateTrn | date: "dd-MM-yyyy HH:mm" }}</div>
  </div>
</div>
