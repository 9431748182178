import {map, catchError} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Paginated } from '../models/paginated';
import { HttpResponse, HttpClient } from '@angular/common/http';
import {XplatRegistriesFilter} from '../../models/xplatregistries/xplatregistries-filter';
import {IXplatRegistry} from '../../models/xplatregistries/xplatregistry';
import { AuthService, BaseHttpService, HttpErrorService } from 'tsuz-common';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class XplatRegistriesService extends BaseHttpService {

  constructor(
    http: HttpClient,
    httpErrorService: HttpErrorService,
    @Inject('LOCALSTORAGE') localStorage: any,
    authService: AuthService) {
    super(http, httpErrorService, localStorage, environment, authService);
  }

  getRegistries(filter: XplatRegistriesFilter): Observable<Paginated<IXplatRegistry[]>> {
    let queryStr = '';
    if(filter) {
      queryStr = filter.toString();
    }

    return this.get(`/xplatregistries` + queryStr);
  }
  download(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/xplatregistries/${id}/download`, { observe: 'response', responseType: 'blob' }).pipe(
      catchError(this.handleError),
      map(this.handleFileResponse),);
  }

  send(id: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/xplatregistries/${id}/SendToEmail`, null).pipe(
      catchError(this.handleError));
  }

  private handleFileResponse(response: HttpResponse<Blob>): { filename: string, data: Blob } {
    return {
      filename: `${response.headers.get('FileName')}`,
      data: response.body
    };
  }
}
