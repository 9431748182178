<div class="popup__wrapper">

  <h5 class="h5">
    <span>{{title}}</span>
  </h5>
  <div class="confirm-icon-wrapper confirm-icon-wrapper--question"></div>
  <div class="label">{{message}}</div>

  <div class="popup__footer text-center">
    <button id="id1622736234" class="btn btn--active" (click)="setResult(true)">Да</button>
    <button id="id1622736235" class="btn btn--active" (click)="setResult(false)">Нет</button>
  </div>
</div>
