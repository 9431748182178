import {HttpParams} from '@angular/common/http';
import {DateRange} from 'tsuz-common';

export class OrganizationTurnoverFilter {
  /**Список идентификаторов лицензиатов */
  licenseeIds: string[];

  /**Отобразить только активных лицензиатов (тех у которых были сделки) */
  onlyActive: boolean;

  /**Период формирования  */
  period: DateRange;

  get createFormattedFrom(): string { return this.period && this.period.dateFromAsUtcString || ''; }
  get createFormattedTo(): string { return this.period && this.period.dateToAsUtcString || ''; }

  public toString = (): string => {
    let params = new HttpParams();
    params = this.addParam(params, this.createFormattedFrom, 'Period.DateFrom');
    params = this.addParam(params, this.createFormattedTo, 'Period.DateTo');
    params = this.addParam(params, this.onlyActive, 'OnlyActive');

    if (this.licenseeIds) {
      this.licenseeIds.forEach(id => {
        params = params.append('LicenseeIds', id);
      });
    }

    return `?` + params.toString();
  };

  addParam(params: HttpParams, value: any, paramName: any): HttpParams {
    if (value) {
      params = params.append(paramName, value);
    }
    return params;
  }
}
