import { AbstractControl, ValidatorFn } from '@angular/forms';

//Валидатор длинны поля
export function LengthValidator(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value) {
            if (control.value.length < minLength) {
                return { 'minError': true };
            }
            if (control.value.length > maxLength) {
                return { 'maxError': true };
            }
        }
        return null;
    };
}
