<h2 mat-dialog-title>Отозвать заявку</h2>

<mat-dialog-content>
  <p>
    Вы уверены, что хотите отвязать самозанятого от платформы
  </p>

  <div *ngIf="result$ | async as result; else loadingOrError">
    <p>
      {{result.state | platformBindingRequestStateToString}}
    </p>
  </div>

  <ng-template #loadingOrError>
    <div *ngIf="errorLoading$ | async as error; else loading">
      {{error}}
    </div>
    <ng-template #loading>
      <p *ngIf="result$">
        Ждем ответ от сервера...
      </p>

    </ng-template>

  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button
          class="mat-raised-button__half"
          (click)="onClose()">
    Нет
  </button>
  <button mat-raised-button
          class="mat-raised-button__half"
          color="primary"
          (click)="onUnbind()">
    Да
  </button>
</mat-dialog-actions>