import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PlatformBindingRequestsCreateData} from './PlatformBindingRequestCreateData';
import {PlatformBindingRequestService} from '../shared/platform-binding-request.service';
import {AbstractControl, FormControl, FormGroup, FormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {SmzInnValidator} from '../../components/people-deal/SmzInnValidator';

@Component({
  selector: 'app-platform-binding-request-create',
  templateUrl: './platform-binding-request-create.component.html',
  styleUrls: ['./platform-binding-request-create.component.scss']
})
export class PlatformBindingRequestCreateComponent {
  invalid: true;
  errorMessage: string;
  platformBindingRequestForm: FormGroup = this.fb.group({
    inn: ['', [Validators.required, SmzInnValidator, this.serverErrorValidator()]],
    phone: ['', [Validators.pattern(/^((\+7|7|8)+([0-9]){10})$/)]],
    email: ['', [Validators.email]]
  }, {'validator': this.orValidator()});

  constructor(
    public dialogRef: MatDialogRef<PlatformBindingRequestCreateComponent>,
    private platformBindingRequestsService: PlatformBindingRequestService,
    private fb: FormBuilder
  ) {
  }

  get inn() {
    return this.platformBindingRequestForm ? this.platformBindingRequestForm.get('inn') : null;
  }
  get phone() {
    return this.platformBindingRequestForm ? this.platformBindingRequestForm.get('phone') : null;
  }
  get email() {
    return this.platformBindingRequestForm ? this.platformBindingRequestForm.get('email') : null;
  }

  private serverErrorValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const condition = !!control.value;
      if (condition) {
        if (this.invalid) {
          return {serverErrorValidator: this.errorMessage};
        }
      }
      return null;
    };
  }

  private orValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.email && this.phone && !this.email.value && !this.phone.value ? { 'or': true } : null;
    };
  }

  onSubmit(): void {
    this.platformBindingRequestsService
      .createRequest(this.inn.value, this.phone.value, this.email.value)
      .subscribe(response => {
          const data = {isSucces: true, PlatformBindingRequest: response};
          this.dialogRef.close(data as PlatformBindingRequestsCreateData);
        },
        error => {
          this.invalid = true;
          this.errorMessage = error;
          this.platformBindingRequestForm.value.updateValueAndValidity();
        });
  }
}
