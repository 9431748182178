<div>
  <div class="dropdown__header">
    <h5 class="h5">Настройки пользователя</h5>
  </div>
  <section class="dropdown__section" [class.disabled]="readonly" [formGroup]="empForm">
    <div class="dropdown__staff-settings-inpgroup">
      <div class="dropdown__staff-settings-inpgroup-item">
        <fio-input formControlName="Surname"   [id]="'id1807052124'" [labelName]="'Фамилия'" [formCtrl]="empForm.controls.Surname" ></fio-input>
    </div>
    <div class="dropdown__staff-settings-inpgroup-item">
        <fio-input formControlName="Name"   [id]="'id1807052125'" [labelName]="'Имя'" [formCtrl]="empForm.controls.Name" ></fio-input>
    </div>

    <div class="dropdown__staff-settings-inpgroup-item">
        <middlename-input formControlName="MiddleName"   [id]="'id1807052126'" [labelName]="'Отчество'" [formCtrl]="empForm.controls.MiddleName" ></middlename-input>
    </div>

    </div>
    <div class="dropdown__staff-settings-inpgroup">
      <div class="dropdown__staff-settings-inpgroup-item">

        <label for="pho{{user.id}}" class="label">Телефон</label>
        <input id="pho{{user.id}}"
          [textMask]="{mask: getPhoneMask}"
          placeholder="+"
          formControlName="Phone"
          required type="tel"
          class="input min-bored input--nonvalidate"
          [ngClass]="{'input--nonvalidate': empForm.controls.Phone.hasError('InvalidPhone') == true || empForm.controls.Phone.hasError('required') == true,
          'input--validate': empForm.controls.Phone.hasError('InvalidPhone') == false}">
        <span class="report report--false report--valid"
        [ngClass]="{'report--active': empForm.controls.Phone.hasError('InvalidPhone') == true}">
        *Номер телефона должен быть в формате +7(000)000-00-01
        </span>
        <input type="checkbox"
               id="464656"
               class="checkbox-filter"
               (change)="hasFakePhoneChange()"
               formControlName="HasFakePhone">
        <label class="checkbox-filter-label" for="464656"> Запрет отправки СМС</label>
      </div>
      <div class="dropdown__staff-settings-inpgroup-item">
        <login-input formControlName="Login" [id]="'id1807052127'" [labelName]="'Логин'" [formCtrl]="empForm.controls.Login" ></login-input>
      </div>
      <div class="dropdown__staff-settings-inpgroup-item dropdown__staff-settings-inpgroup-item--email">
        <label for="email{{user.id}}" class="label">Email</label>
        <input id="email{{user.id}}"
          type="text"
          class="input"
          formControlName="Email"
          [ngClass]="{'input--nonvalidate': empForm.controls.Email.hasError('pattern') == true,'input--validate': empForm.controls.Email.hasError('pattern') == false}">
        <span class="report report--false report--valid"
        [ngClass]="{'report--active': empForm.controls.Email.hasError('pattern') == true}">
        *Некорректный адрес электронной почты.
      </span>
      </div>
    </div>
  </section>
  <span class="report report--false report--valid" [ngClass]="{'report--active': errBool == true}">*{{errMes}}.</span>
  <div class="dropdown__footer" *ngIf="!readonly">
    <button id="id1538406326"
      class="btn"
      [ngClass]="{'btn--active': empForm.invalid == false}"
      (click)="toPost()">
      Сохранить
    </button>
  </div>
</div>
