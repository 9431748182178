import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { IGetPersonCardsResponse, IGetPersonInfoRequest, IGetPersonInfoResponse } from '../../models/peopledeal/person';
import {BaseHttpService} from 'tsuz-common';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  getPersonInfo(request: IGetPersonInfoRequest): Observable<IGetPersonInfoResponse> {
    return this.baseHttpService.get('/api/persons', request);
  }

  getPersonCards(personId: string): Observable<IGetPersonCardsResponse> {
    return this.baseHttpService.get(`/api/persons/${personId}/cards`);
  }


}
