import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-limit-info',
  templateUrl: './limit-info.component.html',
  styles: [`
    :host {
      white-space: nowrap; /*  to keep number and currency together on one line */
    }
  `]
})
export class LimitInfoComponent implements OnInit {

  @Input() limit : number;
  @Input() canBeUnlimited = true;

  constructor() { }

  ngOnInit(): void {
  }

}
