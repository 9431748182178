import { Component } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { WidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-products-incoming-widget',
  templateUrl: './products-incoming-widget.component.html',
  styleUrls: ['./products-incoming-widget.component.scss']
})
export class ProductsIncomingWidgetComponent
implements WidgetComponent{

  public hasError: boolean = false;

  public error: string;
  
  public isLoadingCompleted: boolean = false;

  // chart options
  view: number[] = [275, 234];
  colorScheme: object = {
    domain: ['#A3A0FB', '#FF8373', '#55D8FD', '#FEDA83']
  };
  chartData: any[];

  constructor(private widgetService: WidgetService)
  { }

  tooltipFormatting(item) {
    return `<div>${item.data.name}</div><div>${item.data.value}%</div>`;
  }

  async ngOnInit() {
    const data = await this.widgetService.getProductsIncomingData().catch(err => {
      this.error = err;
      this.hasError = true;
    });
    
    this.chartData = this.getMappedData(data);

    this.isLoadingCompleted = true;
  }

  getMappedData(data: any) : object[] {
    if (!data) {
      return [];
    }

    return data.map(item => {
      return {
        name: item.pointName,
        value: item.pointValue
      };
    });
  }
}
