import { formatNumber, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  transform(money: any, currency?: string) : string {
    if (!currency) {
      currency = localStorage.getItem('currency');
    }

    const number = formatNumber(money ?? 0, 'en', '.2-2');
    const currencySymbol = getCurrencySymbol(currency, "narrow");
    return `${number} ${currencySymbol}`;
  }

}
