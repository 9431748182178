import { Component, Inject, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { HttpErrorResponse } from '@angular/common/http';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { IUser } from '../../../../models/IUser';
import { IShortPoint } from '../../../../models/points/IShortPoint';
import { NotificationService } from '../../../../services/notification.service';
import { IUsersService } from '../../../../services/interfaces/IUsersService';

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
  licenseeId: string;
}

@Component({
  selector: 'app-reset-post',
  templateUrl: './reset-post.component.html'
})
export class ResetPostComponent
  extends SimpleModalComponent<ConfirmModel, IShortPoint>
  implements ConfirmModel, OnInit {

  title: string;
  message: string;
  user: IUser;
  licenseeId: string;

  constructor(
    @Inject('UsersService') private usersService: IUsersService, 
    private notifyService: NotificationService) {
    super();
  }

  ngOnInit() {
  }

  toPost() {
      this.usersService
        .resetPassword(this.user.id).pipe(catchError(this.handleError))
        .subscribe(
          data => {
            this.notifyService.successNotify('Пароль успешно сброшен' , 'Cброс пароля');
            this.close();
          },
          error => {
            this.notifyService.errorNotify(error , 'Ошибка сброса пароля');
          }
        );
  }

  handleError(error: HttpErrorResponse) {
    return observableThrowError(error);
  }

}
