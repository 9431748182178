<div *ngIf="isError" [formGroup]="peopleForm">
  <div class="newpayment__wrapper">
    <div class="newpayment__header">
      <h1 class="newpayment__header-h1 red-text">Ошибка редактирования</h1>
      <div class="newpayment__number">
      </div>
    </div>
    <ng-container *ngIf="errorMessage">
      <ng-container *ngFor="let errorItem of errorMessage.split(';')">
        <span class="false-code report report--false">*{{errorItem}}</span>
        <br>
      </ng-container>
    </ng-container>

    <div class="newpayment__footer">
      <div class="main-deals-btns">
        <a id="id1538468258" class="btn" [routerLink]="['/deals']">К списку оплат</a>
      </div>
    </div>
  </div>
</div>
<form *ngIf="stepNum==0 && isReady && deal" [formGroup]="peopleForm" (ngSubmit)="onSubmit()" #f="ngForm">
  <div class="newpayment__wrapper">
    <div class="newpayment__header">

      <h1 *ngIf ="!isCopy" class="newpayment__header-h1">Редактирование оплаты</h1>
      <h1 *ngIf ="isCopy" class="newpayment__header-h1">Создание оплаты на основе {{dealId}}</h1>
      <div class="newpayment__number">
      </div>
      <div class="newpayment__header-main">
        <div class="newpayment__header-select-point-block">
          <div>
            <label class="label">Выберите&nbsp;{{deal.pointTitle | lowercase}}</label>
          </div>
          <div class="newpayment__header-select-point-wrapper">
            <app-select-box-control id="id1538468301"
                                    [items]="listpoint"
                                    bindLabel="title"
                                    bindValue="id"
                                    placeholder="Выберите пункт оплаты"
                                    formControlName="PointId"
                                    (change)="checkPoint($event)">
            </app-select-box-control>
          </div>
        </div>
        <div class="newpayment__header-block">
          <label class="label">Cуточный лимит</label>
          <div class="daily-limit-amount">
            <span id="id1538468325">{{limitInfo | money}}</span>
          </div>
        </div>
        <div class="newpayment__header-block">
          <label class="label">Остаток лимита</label>
          <div class="limit-balance-amount">

            <span id="id1538468351">{{currentLimitInfo | money}}</span>
          </div>
        </div>
        <div class="newpayment__header-block">
          <label class="label">Лимит оплаты</label>
          <div class="payment-limit-amount">
            <span id="id1538468368">{{transcationInfo | money}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="newpayment__section">
      <div class="newpayment__section-h4-wrapper">
        <div *ngIf="deal.dealSettings.licenseeType === 'Default'">Товарная таблица
        </div>
      </div>
      <div class="d-table newpayment__table">

          <div class="newpayment__table-header-row">
            <div class="d-table-cell newpayment__table-header newpayment__table-header1" *ngIf="deal.dealSettings.licenseeType === 'Default'">№</div>
            <div class="d-table-cell newpayment__table-header newpayment__table-header2">Категория</div>

            <div class="d-table-cell newpayment__table-header newpayment__table-header3">{{cash}}</div>
            <div class="d-table-cell newpayment__table-header newpayment__table-header4" *ngIf="!deal.dealSettings.hideTrashPercent">Засор,&nbsp;%</div>
            <div class="d-table-cell newpayment__table-header newpayment__table-header5" *ngIf="!deal.dealSettings.hideWeight">Вес</div>
            <div class="d-table-cell newpayment__table-header newpayment__table-header5" *ngIf="!deal.dealSettings.hideUnit">Единица
              измерения</div>
            <div class="d-table-cell newpayment__table-header" *ngIf="!hideOper">Оператор</div>
            <div class="d-table-cell newpayment__table-header" *ngIf="!hidePp">Номер П.П.</div>
            <div class="d-table-cell newpayment__table-header" *ngIf="oneScreen">Номер телефона</div>
          </div>
          <ng-container formArrayName="products">
            <div class="newpayment__table-row" *ngFor="let prod of peopleForm.get('products')?.controls; let i=index">
              <ng-container [formGroupName]="i">
                <ng-container *ngIf="deal.dealSettings.licenseeType === 'Default'">
                  <div class="d-table-cell newpayment__table-col1">
                    <div class="newpayment__table-data edit-delete">
                      <a id="id1538468431{{i}}" class="delete-btn" (click)="deleteProduct(i)"><span class="label--mobile">Удалить строку</span></a>
                    </div>

                    <div class="newpayment__table-data newpayment__table-data-center"><span>{{i+1}}</span></div>
                  </div>
                </ng-container>
                <div class="d-table-cell newpayment__table-col3">
                  <div class="newpayment__table-data">
                    <app-select-box-control id="id1538468501{{i}}"
                                            [items]="listprod"
                                            bindLabel="title"
                                            bindValue="id"
                                            placeholder="Выберите категорию"
                                            formControlName="productId"
                                            (change)="changeProduct(peopleForm.controls.products.value[i].productId,i)">

                    </app-select-box-control>
                  </div>
                </div>
                <div class="d-table-cell newpayment__table-col4">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Цена</span>
                    <input id="id1538468535{{i}}" class="input calc" formControlName="measureUnitPrice" placeholder="&ensp;" (input)="checkPrice($event)"
                      [dropSpecialCharacters]="false" mask="0*.00"  (focus)="onFocus($event)" (focusout)="onFocusOut($event)"  (keyup)="increase()" />
                  </div>
                </div>
                <div class="d-table-cell newpayment__table-col5" *ngIf="!deal.dealSettings.hideTrashPercent">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Засор</span>
                    <input id="id1538468577{{i}}" class="input calc" formControlName="trash" placeholder="&ensp;" (input)="checkTrash($event)"
                      [dropSpecialCharacters]="false"   mask="00?.00"   (focus)="onFocus($event)" (focusout)="onFocusOut($event)"  (keyup)="increase()" />
                  </div>
                </div>
                <div class="d-table-cell newpayment__table-col6" *ngIf="!deal.dealSettings.hideWeight">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Вес</span>
                    <input id="id1538468600{{i}}" class="input calc" formControlName="measureUnitValue" placeholder="&ensp;" (input)="checkMeasure($event)"
                      [dropSpecialCharacters]="false" mask="0*.0000" (focus)="onFocus($event)" (focusout)="onFocusOut($event)"  (keyup)="increase()" />
                  </div>
                </div>
                <div class="d-table-cell newpayment__table-col6" *ngIf="!deal.dealSettings.hideUnit">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Единица измерения</span>
                    <app-select-box-control id="id1538461435{{i}}"
                                            [items]="productMeasureUnits[i]"
                                            bindLabel="fullName"
                                            bindValue="shortName"
                                            placeholder="Выберите ед. измерения"
                                            formControlName="measureUnit"
                                            (change)="increase()">
                    </app-select-box-control>
                  </div>
                </div>
                <div class="d-table-cell newpayment__table-col6" *ngIf="!listProductSettings[i].hideProviders">
                  <div class="newpayment__table-data">
                      <span class="label--mobile">Оператор</span>
                    <app-select-box-control id="id1538468660{{i}}"
                                            [items]="listProvider"
                                            bindLabel="title"
                                            bindValue="id"
                                            placeholder="&ensp;"
                                            formControlName="provider"
                                            (change)="increase()"
                                            (change)="changeProvider()">
                    </app-select-box-control>
                  </div>
                </div>
                <div class="d-table-cell newpayment__table-col6" *ngIf="!listProductSettings[i].hidePp">
                  <div class="newpayment__table-data">
                    <input id="id1538468685{{i}}" class="input calc" formControlName="pnumber" maxlength="20"
                      placeholder="&ensp;" (keyup)="increase()" />
                  </div>
                </div>
                <div class="newpayment__table-col6" *ngIf="oneScreen">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Номер телефона</span>
                    <app-phone-control  id="id1538468710{{i}}"
                                        formControlName="phone"
                                        hideValidationError="true"
                                        overrideCssClass="input input--calc"
                                        (input)="check()">
                    </app-phone-control>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

      </div>
      <span class="report report--false report--valid" [ngClass]="{'report--active': !hasOne.isValid}">{{hasOne.errorMessage}}</span>
    </div>
    <div class="newpayment__total-amount-wrapper">
      <tsuz-add-button id="id1538468740" (click)="addProduct()" *ngIf="deal.dealSettings.licenseeType === 'Default'"></tsuz-add-button>
      <div class="flex-wrapper">
        <div class="label">
          К&nbsp;оплате:
        </div>
        <div id="id1538468763" class="newpayment__total-amount">
          {{total | money: deal.currency}}
        </div>
      </div>
    </div>
    <div class="comment-textarea__block">
      <div class="texarea-label">Коментарий</div>
      <div class="comment-textarea__wrapper">
        <textarea id="id1538468818" maxlength="255" class="comment-textarea" [attr.disabled]="(this.deal.comment && this.deal.comment.length > 0) || (isPostAlready && (peopleForm.value.Comment && peopleForm.value.Comment.length > 0)) ?'disabled':null"
          formControlName="Comment" placeholder="Комментарий...">

            </textarea>
      </div>
    </div>
    <ng-container *ngIf="errorMessage">
      <ng-container *ngFor="let errorItem of errorMessage.split(';')">
          <span class="false-code report report--false">*{{errorItem}}</span>
        <br>
      </ng-container>
    </ng-container>
    <div class="newpayment-footer">
      <div class="main-deals-btns">
        <button id="id1538468931" class="btn btn--active btn--back" [routerLink]="['/deals']">К списку оплат</button>
        <button id="id1538468944" [submitDisable]="formSending" [ngClass]="{'btn--active': !formSending && hasOne.isValid && !sumLoading }" [disabled]="!hasOne.isValid || sumLoading || formSending"  value="Submit" type="submit" class="btn"
         >{{nextButton}}</button>
      </div>
    </div>
  </div>
</form>
