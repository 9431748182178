import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SystemService } from '../../services/system.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tsuz-point-timezone',
  templateUrl: './point-timezone.component.html',
  styleUrls: ['./point-timezone.component.scss']
})
export class PointTimeZoneComponent implements OnInit {
  @Input() timeZoneIdControl: FormControl = new FormControl();
  @Input() useLicenseeTimeZoneText: string = 'Использовать часовой пояс Лицензиата';
  @Input() countryCode: string;
  
  private _licenseeTimeZoneId: string = null
  @Input() get licenseeTimeZoneId(): string { return this._licenseeTimeZoneId; }
  set licenseeTimeZoneId(value: string) {
    this._licenseeTimeZoneId = value;
    if (!this._licenseeTimeZoneId) {
      this.licenseeTimeZoneDisplayName = null;
      return;
    }
    this.systemService.getTimeZones$(new Date(), [this._licenseeTimeZoneId]).subscribe(timeZones => {
      this.licenseeTimeZoneDisplayName = timeZones[0]?.displayName;
    });
  }
  
  isLicenseeTimeZone: boolean;
  useLicenseeTimeZoneControl: FormControl;
  licenseeTimeZoneDisplayName: string;
  private lastTimeZoneId: string = null;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private systemService: SystemService
  ) { }

  ngOnInit(): void {
    this.createFormControls();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  createFormControls() {
    this.isLicenseeTimeZone = !this.timeZoneIdControl.value;
    this.useLicenseeTimeZoneControl = new FormControl(this.isLicenseeTimeZone);

    this.lastTimeZoneId = this.timeZoneIdControl.value ?? this.systemService.getDefaultTimeZoneIdForCountry(this.countryCode);

    this.useLicenseeTimeZoneControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        this.useLicenseeTimeZone(value);
      });

    this.timeZoneIdControl.valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe(value => {
      this.useLicenseeTimeZoneControl.setValue(!value, { emitEvent: false});
        this.lastTimeZoneId = this.timeZoneIdControl.value ?? this.lastTimeZoneId;
      });
  }

  useLicenseeTimeZone(value: boolean): void {
    this.isLicenseeTimeZone = value;
    let timeZoneId: string = null;
    if (value) {
      this.lastTimeZoneId = this.timeZoneIdControl.value ?? this.lastTimeZoneId;
    } else {
      timeZoneId = this.lastTimeZoneId;
    }
    this.timeZoneIdControl.setValue(timeZoneId, { emitEvent: false});
  }
}
