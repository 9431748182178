<div class="popup__wrapper">
  <div>
    <h5 class="h5">
      <span>{{title}}</span>
    </h5>
    <hr>
    <div class="big-icon big-icon--reset"></div>
    <label class="label">{{message}}</label>
    <h5 class="h5">
      <span  class="label" id="id1538407885"> {{user.phone}} </span>
    </h5>
    <div class="popup__footer text-center">
      <a id="id1538407672" class="btn btn--active" (click)="toPost()">ОК</a>
    </div>
  </div>
</div>
