export enum UserRoles {
  /**Администратор  */
  Administrator = 'administrator',
  /**Менеджер с повышенными правами */
  ManagerPlus = 'managerplus',
  /**Менеджер */
  Manager = 'manager',
  /**Бухгалтер */
  Accountant = 'accountant',
  /**Оператор */
  Cashier = 'cashier',
  /**Техническая поддержка */
  Support = 'support',
  /**Финансовый директор */
  FinancialDirector = 'findir',
  /**Исполнительный директор */
  ExecutiveOfficer = 'executiveOfficer',
  /**Менеджер по работе с клиентами */
  ClientManager = 'clientManager',
}
