<div class="popup3__wrapper">
    <ng-container >
      <div class="popup3__main">
        <div class="popup__header">
          <h4 class="h4">Изменение канала оповещений</h4>
          <a id="id1538469562" class="tap-to-close" (click)="close()"></a>
        </div>
        <div class="popup3__pay-info">
          <div>Канал оповещений будет изменен на {{sendMethod}} </div>
        </div>
        <form  [formGroup]="codeForm" (ngSubmit)="confirm()" >
          <div class="popup3__sms-input-wrapper">
              <label class="label">для завершения введите ваш код подтверждения</label>
              <code-input formControlName="ConfirmCode"  class=" popup3__sms-input" [formCtrl]="codeForm.controls.ConfirmCode" ></code-input>
              <span class="report report--false report--valid" [ngClass]="{'report--active': codeForm.controls.ConfirmCode?.hasError('minLength') == true}">*Минимальная длина поля составялет 6 символов.</span>
          </div>
          <div class="popup3__btn-wrapper">
             <button
             [disabled]="codeForm.invalid"
             [ngClass]="{'btn&#45;&#45;active': codeForm.valid == true}" 
             type="submit" class="btn"
             >Подтвердить</button>
          </div>
        </form>
      </div>
    </ng-container>

  </div>
