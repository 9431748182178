import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timeZoneName'
})
/**
 * Converts IANA TimeZone ID (Europe/Moscow, Asia/Almaty) to a human readable name
 */
export class TimeZoneNamePipe implements PipeTransform {

  constructor(
    ) {
  }

  /**
   * Transforms timeZoneId to a human readable name
   * @param value - IANA TimeZone ID (Europe/Moscow, Asia/Almaty)
   * @param format - See Luxon table of tokens: https://moment.github.io/luxon/#/formatting?id=table-of-tokens 
   * @param locale - Ex.: 'ru', 'en', 'fr'. By default takes
   */
  transform(value: string, format: string = 'ZZ', locale?: string): string {
    if (!value) {
      return '';
    }

    let dateTime = DateTime.now().setZone(value);
    if (locale) {
      dateTime = dateTime.setLocale(locale);
    }
    const result = dateTime.toFormat(format);
    return result;
  }
}
