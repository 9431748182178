<div class="table__header-cell table__header-cell--w70 filter__column">
    <app-select-filter
        [items]="statusItems"
        [(model)]="filter.isActive"
        [queryName]="'isActive'"
        (apply)="onApply()"
        placeholder="Статус"
        [paginationFilter]="filter">
        Статус
    </app-select-filter>
</div>
<div class="table__header-cell filter__column">
    <app-string-filter
        [(model)]="filter.fio"
        (apply)="onApply()"
        queryName="fio"
        [textMask]="{mask: getFioMask}"
        placeholder="Иванов Иван Иванович"
        [paginationFilter]="filter">
        ФИО
    </app-string-filter>
</div>
<div class="table__header-cell table__header-cell--w100 filter__column">
    <app-string-filter
        [(model)]="filter.login"
        (apply)="onApply()"
        queryName="login"
        placeholder="Логин"
        [textMask]="{mask: getLoginMask}"
        [paginationFilter]="filter">
        Логин
    </app-string-filter>
</div>
<div class="table__header-cell table__header-cell--w130 filter__column">
    <app-string-filter
        [(model)]="filter.phone"
        (apply)="onApply()"
        queryName="phone"
        placeholder="Телефон"
        [textMask]="{mask: getPhoneMask}"
        [paginationFilter]="filter">
        Телефон
    </app-string-filter>
</div>
<div class="table__header-cell table__header-cell--w100 filter__column">
    <app-string-filter
        [(model)]="filter.email"
        queryName="email"
        (apply)="onApply()"
        placeholder="Email"
        [textMask]="{mask: getEmailMask}"
        [paginationFilter]="filter">
        Email
    </app-string-filter>
</div>
<div class="table__header-cell table__header-cell--w130 filter__column">
    <app-list-filter
        [items]="rolesList"
        [queryName]="'roles'"
        [(model)]= "filter.roles"
        (apply)="onApply()"
        [paginationFilter]="filter">
        Роли
    </app-list-filter>
</div>