import {Component, OnInit, Input} from '@angular/core';
import {SupportService} from '@services/support.service';
import {LicenseeService} from '@services/licensee.service';
import {LoaderService, ILicenseeSetting, IUser, UserFilter, NotificationService} from 'tsuz-common';

@Component({
  selector: 'app-support-licensee-users',
  templateUrl: './support-licensee-users.component.html',
  styleUrls: ['./support-licensee-users.component.scss']
})
export class SupportLicenseeUsersComponent implements OnInit {

  @Input() licenseeId: string;

  users: IUser[];
  filter: UserFilter = new UserFilter();
  settingList: ILicenseeSetting;

  hoverRole = false;
  hoverPzu = false;
  hoverFio = false;

  constructor(
    private supportService: SupportService,
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.filter = new UserFilter();

    // Получение настроек лицензиата
    this.licenseeService.getSettings(this.licenseeId).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
      // Установка меню в начальное состояние
      this.resetHover();
      this.getUsers();
    }, error => console.error(error));
  }

  resetHover() {
    this.hoverRole = false;
    this.hoverPzu = false;
    this.hoverFio = false;
  }

  // Установка настроек меню для сотрудника
  showRight(str: string, id: any) {
    const item = this.users.find(i => i.id === id);
    this.resetShowBlocks(item);
    if (str === 'point') {
      item.isShowPoint = true;
    }
    if (str === 'role') {
      item.isShowRole = true;
    }
    if (str === 'reset') {
      item.isShowReset = true;
    }
    if (str === 'block') {
      item.isShowBlock = true;
    }
    if (str === 'set') {
      item.isShowSet = true;
    }
  }

  resetShowBlocks(item: IUser) {
    item.isShowPoint = false;
    item.isShowRole = false;
    item.isShowReset = false;
    item.isShowBlock = false;
    item.isShowSet = false;
    item.isShowChangeCode = false;
  }

  setActiveBlock(item: IUser) {
    const notActiveBlock = !item.isShowPoint
      && !item.isShowRole
      && !item.isShowReset
      && !item.isShowBlock
      && !item.isShowSet
      && !item.isShowChangeCode;
    if (notActiveBlock) {
      item.isShowPoint = true;
    }
  }

  // Показ меню для текущего сотрудника
  dropDownUser(id: any) {
    const item = this.users.find(i => i.id === id);
    if (item.isShowDropDown) {
      item.isShowDropDown = false;
    } else {
      item.isShowDropDown = true;
      this.setActiveBlock(item);
    }
    this.changeDropDown(id);
  }

  // Переход к меню другого сотрудника
  changeDropDown(id: string): void {
    for (const item of this.users) {
      if (item.id !== id) {
        item.isShowDropDown = false;
      }
    }
  }

  getUsers(): void {
    this.loaderService.display(true);

    this.supportService.getUsers(this.licenseeId, this.filter).subscribe(result => {
      this.users = result.items;
      this.filter.total = result.total;

      this.loaderService.display(false);
    }, error => {
      console.error(error);
      this.notificationService.errorNotify(error, 'Ошибка')
    });
  }

  applyFilter(): void {
    this.filter.page = 1;
    this.getUsers();
  }

  resetFilters(): void {
    this.filter = new UserFilter();
    this.getUsers();
  }
}
