import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { LicenseeService } from '@services/licensee.service';
import { log } from 'console';
import { LoaderService, ILicenseeSetting } from 'tsuz-common';
import { NotificationService } from 'tsuz-common';

@Component({
  selector: 'base-settings',
  templateUrl: './base-settings.component.html'
})
export class BaseSettingsComponent implements OnInit {
  settingList: ILicenseeSetting;
  settingForm: FormGroup;
  hideDealProduct: FormControl;
  hideTrashPercent: FormControl;
  hideUnit: FormControl;
  hideWeight: FormControl;
  onlyIdenticalProduct: FormControl;
  sendReceipt: FormControl;
  disableLicenseeBalance: FormControl;
  licenseeId: string;

  organization: any;

  constructor(
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
    private notifyService: NotificationService,
    @Inject("LOCALSTORAGE") private localStorage: any,
  ) { }

  ngOnInit() {
    this.licenseeId = this.localStorage.getItem('licenseeId');
    // Получение настроек лицензиата
    this.getSettings();
    this.licenseeService.getOrganization()
      .subscribe(result => {
        this.organization = result;
      })
  }

  getSettings(): void {
    this.loaderService.display(true);
    
    this.licenseeService.getForSettings(this.licenseeId).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
      this.createFormControls();
      this.createForm();
      this.loaderService.display(false);
    }, error => {
      console.error(error);
    });
  }

  createFormControls() {
    if (this.settingList) {
      this.hideDealProduct = new FormControl(this.settingList.hideDealProduct, Validators.required);
      this.hideTrashPercent = new FormControl(this.settingList.hideTrashPercent, Validators.required);
      this.hideUnit = new FormControl(this.settingList.hideUnit, Validators.required);
      this.hideWeight = new FormControl(this.settingList.hideWeight, Validators.required);
      this.onlyIdenticalProduct = new FormControl(this.settingList.onlyIdenticalProduct, Validators.required);
      this.disableLicenseeBalance = new FormControl(this.settingList.disableLicenseeBalance, Validators.required);
      this.sendReceipt = new FormControl(this.settingList.sendReceipt, Validators.required);

    } else {
      this.hideUnit = new FormControl(false, Validators.required);
      this.hideWeight = new FormControl(false, Validators.required);
      this.hideDealProduct = new FormControl(false, Validators.required);
      this.hideTrashPercent = new FormControl(false, Validators.required);
      this.onlyIdenticalProduct = new FormControl(false, Validators.required);
      this.sendReceipt = new FormControl(false, Validators.required);
      this.disableLicenseeBalance = new FormControl(false, Validators.required);
    }
  }

  createForm() {
    this.settingForm = new FormGroup({
      HideDealProduct: this.hideDealProduct,
      HideTrashPercent: this.hideTrashPercent,
      HideUnit: this.hideUnit,
      HideWeight: this.hideWeight,
      OnlyIdenticalProduct: this.onlyIdenticalProduct,
      SendReceipt: this.sendReceipt,
      DisableLicenseeBalance: this.disableLicenseeBalance,
    });
  }

  //Отправка данных
  async onSubmit() {
    try {
      await  this.licenseeService.updateSettings(this.licenseeId, this.settingForm.value).toPromise();
      this.notifyService.successNotify('Настройки успешно изменены' , 'Настройки');
      this.licenseeService.getSettings(this.licenseeId, true).subscribe(result => {});
     } catch (error) {
       this.notifyService.errorNotify(error , 'Настройки');
     }
    }

    onSendReceiptChange($event) {
        if (this.sendReceipt.value) {
            const o = this.organization;
            const canSendReceipt = !!o.title && !!o.inn && !!o.phone && !!o.email;
            if (!canSendReceipt) {
                /*const fields = {
                  title: !!o.title,
                  inn: !!o.inn,
                  phone: !!o.phone,
                  email: !!o.email
                };
                const fieldsStr = Object.keys(fields).filter(key => !fields[key]).map(key => key.toTitleCase()).join(', ');
                */

                this.notifyService.errorNotify(
                    'Необходимо заполнить недостающие реквизиты организации. Обратитесь в техническую поддержку.',
                    'Настройка не может быть установлена');
                setTimeout(() => this.sendReceipt.setValue(false), 200); // вернуть тоггл в false после короткой паузы
            }
            console.log(canSendReceipt);
        }
    }
}
