<div class="licensee-settings" *ngIf="settingForm">
    <form id="id1538395961" class="licensee-settings__form" [formGroup]="settingForm" (ngSubmit)="onSubmit()">
      <div class="licensee-settings__wrapper">
        <div class="licensee-settings__block licensee-settings__block--point-name">
              <input id="id1538396343"
                type="text"
                formControlName="PointName"
                class="input"
                placeholder="Введите наименование"
                maxlength="25"
                [ngClass]="{'input--nonvalidate': settingForm?.controls.PointName.valid == false,'input--validate': settingForm?.controls.PointName.valid == true}">
              <div>
                <span id="id1538396378" class="report report--false report--valid" [ngClass]="{'report--active': settingForm?.controls.PointName.hasError('required') == true}">*Поле
                  обязательно к заполению.</span>
              </div>
        </div>
      </div>
      <button value="Submit" class="btn" [ngClass]="{'btn--active':settingForm.valid}" [disabled]="settingForm.invalid" type="submit">
        Сохранить
      </button>
    </form>
  </div>
