import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {LicenseeService} from '../services/licensee.service';
import {ILicenseeSetting} from 'tsuz-common';
import {Observable} from 'rxjs';

@Injectable()
export class LicenseesSettingResolver implements Resolve<ILicenseeSetting> {
  constructor(
    private licenseeService: LicenseeService,
    @Inject("LOCALSTORAGE") private localStorage: any,
    ) {}
    
    resolve(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
      ): Observable<any>|Promise<any>|any {
    const licenseeId = this.localStorage.getItem('licenseeId');
    return this.licenseeService.getSettings(licenseeId);
  }
}
