import { getCurrencySymbol } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(
    @Inject('LOCALSTORAGE') private localStorage: any
  ) {
    
  }

  getProfileCurrency(): string {
    return this.localStorage.getItem('currency');
  }

  getProfileCurrencySymbol(): string {
    const currency = this.getProfileCurrency();
    return getCurrencySymbol(currency, "narrow");
  }

  getCurrencySymbol(currency: string): string {
    return getCurrencySymbol(currency, "narrow");
  }
  
}