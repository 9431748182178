<form id="id1538402147" [formGroup]="setForm" (ngSubmit)="onSubmit()" *ngIf="point">
  <div class="dropdown__header">
    <h5 class="h5">Настройки {{point.title}}</h5>
    <hr>
  </div>

  <div class="toggler-wrapper" [class.disabled]="readonly">
    <input id="id4346{{point.id}}" type="checkbox" class="checkbox-big" formControlName="Active">
    <label for="id4346{{point.id}}"></label>
    <div class="label active">Активен</div>
  </div>

  <div class="dropdown__section" [class.disabled]="readonly">
    <label class="label" for="id496{{point.id}}">Название</label>
    <input id="id496{{point.id}}" class="input" type="text" formControlName="Title">
    <span class="report report--false report--valid" [ngClass]="{'report--active': setForm.controls.Title.hasError('required') == true}">*Поле является обязательным.</span>
    <span class="report report--false report--valid" [ngClass]="{'report--active': setForm.controls.Title.hasError('maxlength') == true}">*Максимальная длина поля составляет {{setForm.controls.Title.errors?.maxlength?.requiredLength}} символов.</span>
  </div>
  
  <div class="dropdown__section" [class.disabled]="readonly">
    <label class="label" for="id43657{{point.id}}">Адрес</label>
    <input id="id43657{{point.id}}" class="input" type="text" formControlName="Address">
    <span class="report report--false report--valid" [ngClass]="{'report--active': setForm.controls.Address.hasError('required') == true}">*Поле является обязательным.</span>
    <span class="report report--false report--valid" [ngClass]="{'report--active': setForm.controls.Address.hasError('maxlength') == true}">*Максимальная длина поля составляет {{setForm.controls.Address.errors?.maxlength?.requiredLength}} символов.</span>
  </div>

  <div class="dropdown__section" [class.disabled]="readonly">
    <div class="label">Часовой пояс</div>
    
    <tsuz-point-timezone
      [countryCode]="point.country"
      [timeZoneIdControl]="timeZoneIdControl"
      [licenseeTimeZoneId]="licensee.timeZoneId">
    </tsuz-point-timezone>
  </div>
  <div class="dropdown__section" *hasPermission="[[userRoles.ExecutiveOfficer]]">
    <div class="delete-point-wrapper" (click)="deletePoint.emit(point)">
      <a class="delete-btn" type="button"></a>
      <span>Удалить точку</span>
    </div>
  </div>

  <div class="dropdown__footer" *ngIf="!readonly">
    <button id="id1538402055" [disabled]="setForm.invalid" value="Submit" class="btn" [ngClass]="{'btn--active': setForm.valid == true}" type="submit">
      Сохранить
    </button>
  </div>
</form>
