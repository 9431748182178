<ng-container>
  <mat-checkbox
    class="use-licensee-timezone"
    color="primary"
    [formControl]="useLicenseeTimeZoneControl">
    {{useLicenseeTimeZoneText}}
  </mat-checkbox>

  <div class="licensee-timezone" *ngIf="isLicenseeTimeZone">
    <mat-label>{{licenseeTimeZoneDisplayName}}</mat-label>
  </div>
  <tsuz-timezone-select [countryCode]="countryCode" [control]="timeZoneIdControl" *ngIf="!isLicenseeTimeZone"> </tsuz-timezone-select>
</ng-container>
