import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { SelfEmployedRoutingModule } from './self-employed-routing.module';
import { PlatformBindingRequestListComponent } from './platform-binding-request-list/platform-binding-request-list.component';
import { PlatformBindingRequestStateToStringPipe } from './shared/platform-binding-request-state-to-string.pipe';
import { PlatformBindingRequestStateToActionStringPipe } from './shared/platform-binding-request-state-to-action-string.pipe';
import { PlatformBindingRequestCreateComponent } from './platform-binding-request-create/platform-binding-request-create.component';
import { MaterialModule } from '../modules/material-module';
import { PlatformBindingRequestCreatedComponent } from './platform-binding-request-created/platform-binding-request-created.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlatformBindingRequestCheckStateComponent } from './platform-binding-request-check-state/platform-binding-request-check-state.component';
import { PlatformBindingRequestUnbindComponent } from './platform-binding-request-unbind/platform-binding-request-unbind.component';
import {SharedModule} from "../shared/shared.module";
import {CommonModule} from "@angular/common";
import { TsuzCommonModule } from 'tsuz-common';
import { environment } from 'environments/environment';
import { UsersService } from '@services/users.service';
import { LicenseeService } from '@services/licensee.service';
import { UserSettingService } from '@services/user-setting.service';
import { PointService } from '@services/point.service';
import { WithdrawTypeService } from '@services/withdraw-type.service';
import { ProductService } from '@services/product.service';

@NgModule({
  declarations: [
    PlatformBindingRequestListComponent,
    PlatformBindingRequestStateToStringPipe,
    PlatformBindingRequestStateToActionStringPipe,
    PlatformBindingRequestCreateComponent,
    PlatformBindingRequestCreatedComponent,
    PlatformBindingRequestCheckStateComponent,
    PlatformBindingRequestUnbindComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SelfEmployedRoutingModule,
    SharedModule,
    TsuzCommonModule.forRoot(environment, LicenseeService, UsersService, UserSettingService, PointService, WithdrawTypeService, ProductService)
  ],
  entryComponents: [
    PlatformBindingRequestCreateComponent,
    PlatformBindingRequestCreatedComponent,
    PlatformBindingRequestCheckStateComponent,
    PlatformBindingRequestUnbindComponent
  ]
})
export class SelfEmployedModule { }
