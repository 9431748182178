<app-info></app-info>
<div class="flex-container">
  <app-nav-menu></app-nav-menu>
  <main class="main" [ngClass]="{'main--aside--closet': collapseMenu,'main--aside--open': showMenu}">
    <header class="main-header" app-profile-info></header>
    <div class="main-section">
      <router-outlet>
 <ng-progress #progressBar [spinner] = "false" [thick]="true"></ng-progress>
        <!-- <div class="cf" *ngIf="showLoader"></div>
        <div class="jb" *ngIf="showFullWidth">
          <div class="jf eC" event-click="close"></div>
          <div class="gf" style="/* display: none; */">
            <svg:svg class="fS" viewBox="25 25 50 50">
              <svg:circle class="jk" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></svg:circle>
            </svg:svg>
          </div>
        </div> -->
      </router-outlet>
    </div>
  </main>
</div>
