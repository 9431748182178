import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {FiasEntity} from '../../models/fias/FiasEntity';
import {BaseHttpService} from 'tsuz-common';

@Injectable()
export class FiasService {

  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  testListCountries: FiasEntity[] = [
    {
      code: '1',
      title: 'Россия'
    },
    {
      code: '2',
      title: 'Казахстан'
    }
  ];
  testListRegions: FiasEntity[] = [
    {
      code: '11',
      title: 'Московская область'
    },
    {
      code: '22',
      title: 'Вологодская область'
    }
  ];

  testListCities: FiasEntity[] = [
    {
      code: '111',
      title: 'Москва'
    },
    {
      code: '222',
      title: 'Вологда'
    }
  ];

  testListStreets: FiasEntity[] = [
    {
      code: '1111',
      title: 'Московская'
    },
    {
      code: '2222',
      title: 'Вологдская'
    }
  ];

  getCountries(): Observable<any> {
    return observableOf(this.testListCountries);
  }
  getRegionByCountry(countryCode: string): Observable<any> {
    return observableOf(this.testListRegions);
  }

  getCitiesByCountry(countryCode: string): Observable<any> {
    return observableOf(this.testListCities);
  }
  getCitiesByRegion(regionCode: string): Observable<any> {
    return observableOf(this.testListCities);
  }

  getStreetsByCity(regionCode: string): Observable<any> {
    return observableOf(this.testListStreets);
  }
}
