import { Component, OnInit } from '@angular/core';
import {AuthService} from 'tsuz-common';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(private readonly authenticationService: AuthService) { }

  isShow: boolean = true;
  ngOnInit() {
    this.authenticationService.infoStatus$.subscribe(x => {
      this.isShow = x;
      if(this.isShow){
        window.scroll(0,0);
      }
    });
  }

  close() {
    this.isShow = false;
  }
}
