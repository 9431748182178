import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ISupportPayInfo } from '../../../../models/supportdeal/ISupportPayInfo';

export interface ConfirmModel {
  title: string;
  message: string;
  supportPayInfo: ISupportPayInfo;
  pointName: string;
  // file: any;
}

@Component({
  selector: 'app-pay-modal-info',
  templateUrl: './pay-modal-info.component.html'
})
export class PayModalInfoComponent extends SimpleModalComponent<ConfirmModel, ISupportPayInfo> implements ConfirmModel, OnInit {
  supportPayInfo: ISupportPayInfo;
  title: string;
  message: string;
  pointName: string;
  constructor() {
    super();
   }

  ngOnInit() {
    console.log(this.supportPayInfo);
  }

}
