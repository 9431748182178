import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportLicenseesComponent } from './support-licensees/support-licensees.component';
import { SupportLicenseesPointsComponent } from './support-licensees/support-licensees-points/support-licensees-points.component';
import { SupportLicenseeUsersComponent } from './support-licensees/support-licensee-users/support-licensee-users.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../shared/shared.module';
import { PointsModule } from '../points/points.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { TsuzCommonModule, RoleNamePipe } from 'tsuz-common';
import { environment } from 'environments/environment';
import { UsersService } from '@services/users.service';
import { LicenseeService } from '@services/licensee.service';
import { UserSettingService } from '@services/user-setting.service';
import { PointService } from '@services/point.service';
import { WithdrawTypeService } from '@services/withdraw-type.service';
import { ProductService } from '@services/product.service';

@NgModule({
  declarations: [
    SupportLicenseesComponent,
    SupportLicenseesPointsComponent,
    SupportLicenseeUsersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    PointsModule,
    WidgetsModule,
    TsuzCommonModule.forRoot(environment, LicenseeService, UsersService, UserSettingService, PointService, WithdrawTypeService, ProductService)
  ],
  exports: [
    SupportLicenseesComponent,
    SupportLicenseesPointsComponent,
    SupportLicenseeUsersComponent
  ],
  providers: [
    RoleNamePipe
  ]
})
export class LicenseeModule {
}
