<div class="upload-files">
  <input 
    type="file"
    (change)="onFilesSelected($event)"
    class="hidden"
    placeholder=""
    [multiple]="maxFileCount > 1"
    [accept]="acceptFileTypes | join"
    #uploadFiles
    [disabled]="readonly"
  />
  <div class="add-button-group">
    <button class="add-button" type="button" (click)="uploadFiles.click()" [disabled]="readonly || uploadedFileList.files.length >= maxFileCount">
      <img src="/img/attach.svg"/>
      <label сlass="button-label">{{labelText}}</label>
    </button>
    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip" class="help-tooltip">help</mat-icon>

    <label *ngIf="uploadedFileList.errors?.length" class="error-text">{{uploadedFileList.errors | join:'; '}}</label>
  </div>
  <ul class="selected-file-list">
    <li *ngFor="let uploadedFile of uploadedFileList.files" class="selected-file">
      <a class="remove-button" color="warn" (click)="removeFile(uploadedFile)" *ngIf="!readonly">
        <mat-icon>delete</mat-icon>
      </a>
      <label class="file-label">{{uploadedFile.file.name}}</label>
      <label *ngIf="uploadedFile.errors?.length" class="error-text">{{uploadedFile.errors | join:'; '}}</label>
    </li>
  </ul>
</div>
