import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-date-input-form-control',
  templateUrl: './date-input-form-control.component.html',
  styleUrls: ['./date-input-form-control.component.scss']
})
export class DateInputFormControlComponent implements OnInit {

  @Input()
  control: FormControl;

  @Input()
  inputId: string;

  @Input()
  iconId: string;

  @Input()
  pluginInputId: string;

  @Input()
  placeholder: string;

  @Output() onChanged = new EventEmitter<any>();

  value: any;

  constructor() { }

  ngOnInit(): void {
    this.value = this.control.value;
  }


  onDateChanged(event: any): void {
    this.value = event.value;
    this.setValueAndEmit();
  }

  onChangeInput() {
    this.setValueAndEmit();
  }

  setValueAndEmit() {
    this.control.setValue(this.value);
    if (this.onChanged) {
      this.onChanged.emit(this.value);
    }
  }
}
