import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[product-trash]'
})
export class ProductTrashDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('focusout')
  onFocusOut(): void {
    let control = this.ngControl.control;
    if (control != null) {
      if (control.value == null || control.value == "") {
          control.setValue(0);
      } else if (control.value > 99.99) {
        control.setValue(99.99);
      }
    }
  }

  @HostListener('input')
  onInput(): void {
    let control = this.ngControl.control;
    if (control != null) {
      if (control.value == null || control.value < 0) {
          control.setValue(0);
      } else if (control.value > 99.99) {
        control.setValue(99.99);
      }
    }
  }

  @HostListener('focus')
  onFocus(): void {
    let control = this.ngControl.control;
    if (control != null) {
      if (control.value == null || control.value <= 0) {
          control.setValue('');
      }
    }
  }
}
