<div
  class="base-widget-component"
  >
  <div 
    [ngClass]="{'base-widget-container': true , 'widget-container-load-error': hasError}"
    [style.background-color]='backgoundColor'
  >
    <div class="widget-header">
      <div [ngClass]="['widget-ico', icoType + '-widget-ico']" ></div>
      <div class="widget-caption"
      [style.color]='headerFontColor'>
        {{header}}
      </div>
    </div>
    <div *ngIf="isLoading" style="height: 100%;">
      <div class="waiting-for-content-spinner"></div>
    </div>
    <div @contentTrigger  *ngIf="!isLoading && !hasError" class="base-widget-content">
      <ng-content></ng-content>
    </div>
    <div @contentTrigger  *ngIf="!isLoading && hasError">
      <div class="widget-load-error">
        ОШИБКА
      </div>
      <div class="widget-load-error-details">
        {{loadingError}}
      </div>
    </div>
    <div class="widget-footer">
      <div class="widget-footer-button-container">
        <a class="widget-footer-button-primary" [href]="primaryButtonRoute" *ngIf="!hidePrimaryButton">
          {{primaryButtonCaption}}
        </a>
      </div>
    </div>
  </div>
</div>
