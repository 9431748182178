import { Component, Input, OnInit } from '@angular/core';
import { ISummaryInfoModel } from '../../../models/holding-structure/ISummaryInfoModel';

@Component({
  selector: 'app-holding-summary-info',
  templateUrl: './holding-summary-info.component.html',
  styleUrls: ['./holding-summary-info.component.scss', '../holding-structure.component.scss']
})
export class HoldingSummuryInfoComponent implements OnInit {

  @Input()
  isHidden: boolean = false;
  @Input()
  isLoading: boolean = true;
  @Input()
  hasError: boolean = false;
  @Input()
  error: string;
  @Input()
  summaryInfo: ISummaryInfoModel = {} as ISummaryInfoModel;

  readonly dateTimeFormat = "dd.MM.yyyy HH:mm:ss";

  ngOnInit(): void {
    this.summaryInfo = {} as ISummaryInfoModel;
  }

}
