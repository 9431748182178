<form id="id1556096526"
      class="sms-confirmation-page__wrapper"
      [formGroup]="approvedForm"
      (ngSubmit)="onSubmitCode()"
      #fa="ngForm">

  <button class="sms-confirmation-page__close"
          type="button"
          (click)="close()">
    <span>×</span>
  </button>

  <div class="sms-confirmation-page__header">
    СМС подтверждение
  </div>

  <div class="sms-confirmation-page__body">
    <p>
      Для отправки реестра в РНКО "Единая Касса" введите код подтверждения.
      Для оповещения по СМС на Ваш номер было отправленно СМС с кодом.
      Для опции "Фиксированные коды" введите Ваш фиксированный код.
    </p>
  </div>

  <div class="sms-confirmation-page__busy"
       *ngIf="state==viewState.busy">
    <div class="app-loading">
      <div class="logo"></div>
      <svg class="spinner"
           viewBox="25 25 50 50">
        <circle class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"></circle>
      </svg>
    </div>
  </div>

  <div class="sms-confirmation-page__inputs"
       *ngIf="state==viewState.none || state==viewState.error">
    <div class="sms-confirmation-page__input-item2">

      <label class="sms-confirmation-page__label">
        СМС-код
        <input id="id1556096544"
               autofocus
               #autofocus
               [ngClass]="{'input--nonvalidate': !approvedForm.value.Code || (approvedForm.value.Code && approvedForm.value.Code < 4),'input--validate':approvedForm.value.Code && approvedForm.value.Code >=4}"
               placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;"
               required
               minlength="4"
               maxlength="8"
               type="text"
               mask="000000"
               formControlName="Code"
               class="sms-confirmation-page__sms-code input sms-confirmation-page__input text-center">
      </label>

      <div class="report report--false text-center"
           *ngIf="approvedForm.controls.Code.hasError('minlength')">
        Код подтверждения должен состоять из 4-6 символов
      </div>

      <div id="id1556096553"
           class="message report report--false text-center"
           *ngIf="errorMessage">
        {{errorMessage}}
      </div>

      <div id="id1556096555"
           class="sms-confirmation-page__sms-code-message">
        <a id="id1556096558"
           class="sms-code-anchor"
           *ngIf="timer.stopped"
           (click)="sendCode()">
          Отправить СМС еще раз
        </a>
        <span id="id1556096562"
              *ngIf="!timer.stopped">
          Отправить повторно через {{timer.currentValue}} сек.
        </span>
      </div>
    </div>
  </div>

  <div class="sms-confirmation-page__success"
       *ngIf="state==viewState.success">
    Успех
  </div>

  <div class="sms-confirmation-page__btn-wrapper">
    <button id="id1556096569"
            class="btn btn--big"
            [ngClass]="{'btn--active': approvedForm.valid && state!=viewState.busy}"
            event-click="click"
            [disabled]="approvedForm.invalid || state==viewState.busy"
            *ngIf="state!=viewState.success"
            type="submit">
      Отправить
    </button>
  </div>
</form>
