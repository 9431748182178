import {XplatRegistryStatuses} from '../../core/enums/xplatregistry-statuses';
import {HttpParams} from '@angular/common/http';
import {DateRange, PaginationFilter} from 'tsuz-common';

export class XplatRegistriesFilter extends  PaginationFilter {
    id = '';
    status?: XplatRegistryStatuses;
    create?: DateRange;
    organizationName: string;


    get createFormattedFrom(): string { return this.create && this.create.dateFromAsUtcString || ''; }
    get createFormattedTo(): string { return this.create && this.create.dateToAsUtcString || ''; }


    public toString = (): string => {

      let params = new HttpParams();
      params = this.addParam(params, this.createFormattedFrom, 'Create.DateFrom');
      params = this.addParam(params, this.createFormattedTo, 'Create.DateTo');
      params = this.addParam(params, this.id, 'id');
      params = this.addParam(params, this.status ? this.status.toString() : '', 'status');
      params = this.addParam(params, this.id, 'id');
      params = this.addParam(params, this.organizationName, 'organizationName');
      params = this.addParam(params, this.page, 'page');

      return `?` + params.toString();
    };

  addParam(params: HttpParams, value: any, paramName: any): HttpParams {
    if (value) {
      params = params.append(paramName, value);
    }
    return params;
  }
}
