<div class="table d-table">
    <ng-container id="id1538405102" *ngIf="points && settingList && licensee">
        <tsuz-points-page-header class="table__header-row" [filter]="filter" (apply)="applyFilter()"></tsuz-points-page-header>
        <ng-container id="id1538405129" *ngFor="let point of points">
            <tsuz-point-item id="id1538405225{{i}}" class="table__row"
                [ngClass]="{'opened': point.isShowDropDown == true}" 
                [point]="point"
                [pointName]="settingList.pointName" 
                [isDirector]="isDirector"
                [currency]="licensee.currency"
                (changePoint)="onChanged($event)"
                (click)="dropDownUser(point.id)"></tsuz-point-item>
            <div class="table__hidden-row" [ngClass]="{'unveiled': point.isShowDropDown == true}">
                <td colspan="6" class="table__hidden-data">
                    <div class="dropdown">
                        <div class="dropdown__left-menu">
                            <a id="id1538405150{{i}}" class="dropdown__left-menu--link"
                                [ngClass]="{'active': point.isShowLimit == true}"
                                (click)="showRight('limit', point.id)">Лимиты</a>
                            <!-- <a class="dropdown__left-menu--link" [ngClass]="{'active': point.isShowEmp == true}" (click)="showRight('emp', point.id)">Сотрудники</a> -->
                            <a id="id1538405167{{i}}" class="dropdown__left-menu--link"
                                [ngClass]="{'active': point.isShowSetting == true}"
                                (click)="showRight('setting', point.id)">Настройка</a>
                        </div>
                        <tsuz-point-limit class="dropdown__main" 
                            *ngIf="point.isShowLimit" [point]="point"
                            [licensee]="licensee"
                            [readonly]="readonly"
                            [currency]="point.licenseeCurrency"
                            [licenseeLimit]="point.licenseeLimit" 
                            [licenseeLimitInfo]="point.licenseeLimitInfo"
                            (updatePointLimits)="onUpdatePointLimits(point, $event)"
                            (resetPointLimits)="onResetPointLimits(point)" 
                        ></tsuz-point-limit>

                        <!-- <div class="dropdown__main" app-point-emp *ngIf="point.isShowEmp"></div> -->
                        <tsuz-point-setting 
                            class="dropdown__main dropdown__pzu-settings" 
                            *ngIf="point.isShowSetting"
                            [licensee]="licensee"
                            [point]="point"
                            [readonly]="readonly"
                            (updatePointSettings)="onUpdatePointSettings($event)"
                            (pointChange)="pointChanged($event,'set')" 
                            (deletePoint)="onDeletePoint($event)"
                            ></tsuz-point-setting>
                    </div>
                </td>
            </div>
        </ng-container>
    </ng-container>
</div>