import { Component, Inject } from '@angular/core';
import { IPlatformBindingRequestUnbind } from '../shared/platform-binding-request-unbind.model';
import { PlatformBindingRequestService } from '../shared/platform-binding-request.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable ,  Subject ,  of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { IPlatformBindingRequest } from '../shared/platform-binding-request.model';

@Component({
  selector: 'app-platform-binding-request-unbind',
  templateUrl: './platform-binding-request-unbind.component.html'
})
export class PlatformBindingRequestUnbindComponent {
  errorLoading$ = new Subject<string>();
  result$: Observable<IPlatformBindingRequest>;

  constructor(
    private platformBindingRequestsService: PlatformBindingRequestService,
    public dialogRef: MatDialogRef<PlatformBindingRequestUnbindComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPlatformBindingRequestUnbind
  ) {

  }

  onUnbind() {
    this.result$ = this.platformBindingRequestsService
      .unbind(this.data.id)
      .pipe(catchError((error: string) => {
        this.errorLoading$.next(error);
        return of<IPlatformBindingRequest>();
      }),
        shareReplay(1));

    this.result$.subscribe(result => {
      if (result.state === 5) {
        this.dialogRef.close(true);
      }
    });
  }

  onClose(): void {
    this.dialogRef.close(false);
  }
}
