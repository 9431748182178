import { DealStatusesClassPipe } from '../../../shared/directives/custom-pipes/deal-statuses-class.pipe';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { Subject, timer } from "rxjs";
import { PayRejectModalComponent } from "../pay-reject-modal/pay-reject-modal.component";
import { PayService } from "@services/pay.service";
import { DealService } from "@services/deal.service";
import { IPeopleDeal } from '../../../models/peopledeal/IPagePeopleDeal';
import { ConfirmDealModel} from '../../../models/peopledeal/ConfirmDealModel';
import { ICheckResult } from "../../../models/pay/ICheckResult";
import { ICheckDealResult } from "../../../models/pay/ICheckDealResult";
import { ISmsResult } from "../../../models/peopledeal/ISmsResult";
import { DealStateEnum } from '../../../models/peopledeal/DealStateEnum';
import { Guid } from 'guid-typescript';
import { IDealDetailsViewModel } from '../../../models/peopledeal/IDealDetailsViewModel';
import { RealtimeNotificationService } from '../../../core/services/realtime-notification.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService, UserRoles } from 'tsuz-common';

export interface ConfirmModel {
  title: string;
  message: string;
  deal: IDealDetailsViewModel;
  isEdit?: boolean;
  operPhone?: string;
  showSms?: boolean;
}
@Component({
  selector: 'app-pay-checkModal',
  templateUrl: './pay-checkModal.component.html'
})
export class PayCheckModalComponent
  extends SimpleModalComponent<ConfirmModel, IPeopleDeal>
  implements ConfirmModel, OnInit, OnDestroy {
  deal: IDealDetailsViewModel;
  isEdit = false;
  showSms = false;
  operPhone: string;
  title: string;
  message: string;
  hideButtons = true;
  isPsOK = false;
  invalid = true;
  stepNumber = 0;
  codeForm: FormGroup;
  code: FormControl;
  respMessage = 'Проверка платежа';
  smsRespMessage = 'Отправка СМС';
  smsCheckRespMessage = 'Проверка кода';
  cssClassNum = -1;
  smsCssClassNum: number = -1;
  smsCheckClassNum = -1;
  smsCount = 'Получение инормации о лимитах';
  smsCountBool = false;
  count = 3;
  smsAcceptCssClassNumber = -1;
  smsWaitCssClassNumber = -1;
  smsApprovalCssClassNumber = -1;
  approvalInfo = 'Получение информации';
  showLimError = false;
  showApprove = false;
  approveInfo = 'Отправка на согласование';
  smsApproveCssClassNumber = -1;
  sendEdit = false;
  showAdmin = false;
  showAccountant = false;
  showUser = false;
  minError = false;
  showApproval = true;
  timemerSubscribe: any;
  acceptRejected = false;
  waitStr = 'Получение информации о платеже';
  hideSmsButton: boolean;
  secondTxt: String;
  timerStopped = true;
  timemerSubscribeSms: any;
  isCardPay: boolean = true;
  isMobilePay: boolean = false;
  isPrintNow: boolean = false;
  currentOperPhone: string = "";
  errorInfo: string = "внутренняя ошибка сервера";
  isPaymentError: boolean = false;
  checkCount: number = 0;
  hideRepeatCodeInputButton: boolean = true;
  destroy: Subject<boolean> = new Subject<boolean>();
  isOnDealStateChangedSubscribed: boolean = false;
  readonly MAX_SMS_COUNT = 3;

  constructor(
    private router: Router,
    private simpleModalService: SimpleModalService,
    private payService: PayService,
    private dealService: DealService,
    private realtimeNotificationService: RealtimeNotificationService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    // Проверка на мобильный платеж
    this.initPayOption();
    // Вычисление количества попыток ввода смс
    this.count = this.MAX_SMS_COUNT - this.deal.countSms;
    // Если смс уже было введено, то проверяем статус оплаты в шлюзе
    if (this.deal && this.deal.smsSucces) {
      this.ngInitWhithoutSms();
    } else if (this.showSms) {
      this.acceptingSms();
    } else {
      // Действуем в зависимости от класса оплаты
      switch (new DealStatusesClassPipe().transform(this.deal.dealState)) {
        case 'payment-waiting':
          this.smsWaitCssClassNumber = 0;
          // Проверка необходимости отправки смс
          this.payService.checkForSms(this.deal.id).subscribe(
            data => {
              this.smsWaitCssClassNumber = 2;
              if (data) {
                this.waitStr = 'Информация получена';
                // Запуск проверки оплаты
                this.ngInitApproval();
              } else {
                this.waitStr = 'Информация получена';
                // Инициализация без смс
                this.ngInitWhithoutSms();
              }
            },
            error => this.setError(error)
          );
          break;
        case 'payment-paid':
        case 'payment-refusal':
        case 'payment-pay-error':
        case 'payment-kyc-error':
          // Инициализация без смс
          this.ngInitWhithoutSms();
          break;
        case 'payment-not-paid':
        case 'payment-approval':
          // Запуск проверки оплаты
          this.ngInitApproval();
          break;
      }
    }
  }

  setError(error: any): void {
    this.resetClasses();
    this.isPaymentError = true;
    this.errorInfo = error;
    console.error(error);
  }

  // Сброс классов окон для ngClass
  resetClasses(): void {
    this.cssClassNum = -1;
    this.smsCssClassNum = -1;
    this.smsCheckClassNum = -1;
    this.smsAcceptCssClassNumber = -1;
    this.smsWaitCssClassNumber = -1;
    this.smsApprovalCssClassNumber = -1;
    this.smsApproveCssClassNumber = -1;
    this.isPaymentError = false;
  }

  // Подписка на нотификацию изменения статуса сделки
  subscibeOnDealStateChangedEvent() {
    this.realtimeNotificationService
        .onDealStateChanged()
        .pipe(takeUntil(this.destroy))
        .subscribe(message => {
            this.checkPay();
        });
    this.isOnDealStateChangedSubscribed = true;
  }

  // Отписка от нотификаций изменения состояния сделки
  unsubscibeOnDealStateChangedEvent() {
    this.destroy.next(true);
    this.isOnDealStateChangedSubscribed = false;
  }

  // Получение текста об оставшихся попытках
  getSmsCountResponse() {
    switch (this.count) {
      case 1: 
        this.smsCount = 'Осталась 1 попытка';
        break;
      case 0: 
        this.smsCount ='Не осталось попыток';
        break;
      default:
        this.smsCount = `Осталось ${this.count} попытки`;
        break;
    }
  }

  // Проверка на мобильный платеж
  initPayOption(): void {
    if (this.deal) {
      let card = false;
      let phone = false;
      if (this.deal.phone) {
        card = false;
        phone = true;
      }
      if (this.deal.cardNumber) {
        card = true;
        phone = false;
      }
      this.isCardPay = card;
      this.isMobilePay = phone;
    }
  }

  // Запуск проверки оплаты
  ngInitApproval() {
    this.resetClasses();
    this.smsApprovalCssClassNumber = 0;
    
    if (this.deal.dealState === 'IsAcept' && 
      !this.authService.inRole(UserRoles.Administrator, UserRoles.ManagerPlus, UserRoles.Accountant))
    {
      this.smsApprovalCssClassNumber = 2;
      this.approvalInfo = "Оплата на согласовании";
      this.showApprove = true;
      return;
    }

    // Проверка до смс
    this.checkForApproval();
  }

  // Инициализация без смс
  ngInitWhithoutSms() {
    this.resetClasses();
    this.cssClassNum = 0;
    // Проверка оплаты в шлюзе
    this.checkPay();
  }

  // Инициализация отправки смс и формы
  acceptingSms() {
    this.resetClasses();
    this.smsCssClassNum = 0;
    this.generateCode();
    this.initForm();
  }

  accept(id: any) {
      this.acceptRequest(id);
  }

  reject(id: any) {
    this.simpleModalService
      .addModal(
        PayRejectModalComponent,
        {
          title: 'Отказ оплаты',
          message: 'Отказ оплаты'
        },
        { closeOnClickOutside: true })
      .subscribe(reason => {
        if (reason) {
          this.rejectRequest(id, reason);
        }
      });
  }

  acceptRequest(id: string): void {
    this.payService.accept(id, this.deal.concurrencyToken).subscribe(
      data => {
        const res = data as ICheckResult;
        this.deal.concurrencyToken = res.concurrencyToken.toString();
        if (res.dealState == 'PointNotActive') {
          this.resetClasses();
          this.smsAcceptCssClassNumber = 1;
          this.acceptRejected = true;
          this.smsCount = res.message;
        } else {
          this.resetClasses();
          this.smsCountBool = true;
          this.smsCount = "Отправить смс для подтверждения оплаты";
          //Получение текста об оставшихся попытках
          this.getSmsCountResponse();
          //Инициализация отправки смс и формы
          this.acceptingSms();
        }
      },
      error => this.setError(error)
    );
  }

  rejectRequest(id: string, reason: string): void {
    this.payService.reject(id, reason, this.deal.concurrencyToken).subscribe(
      data => {
        let res = data as ICheckResult;
        if (res.dealState == "PointNotActive") {
          this.resetClasses();
          this.smsAcceptCssClassNumber = 1;
          this.acceptRejected = true;
          this.smsCount = res.message;
        } else {
            this.closeModal();
        }
      },
      error => this.setError(error)
    );
  }

  //Инициализация таймера смс
  initSmsTimer(startInSeconds: number) {
    if (this.timemerSubscribeSms) {
      this.timemerSubscribeSms.unsubscribe();
    }
    let currentMin = Math.floor(startInSeconds / 60);
    let currentSecond = startInSeconds - currentMin * 60;

    this.timerStopped = false;
    this.timemerSubscribeSms = timer(0, 1000).subscribe(t => {
      if (currentSecond == 0) {
        currentMin--;
        currentSecond = 59;
      }
      currentSecond--;
      if (currentMin <= 0 && currentSecond <= 0) {
        this.stopSmsTimer();
      }
      this.secondTxt = `${currentMin}:${
        currentSecond < 10 ? '0' + currentSecond : '' + currentSecond
        }`;
    });
  }

  // Отписка от таймера смс
  stopSmsTimer() {
    this.timerStopped = true;
    if (this.timemerSubscribeSms) {
      this.timemerSubscribeSms.unsubscribe();
    }
  }

  // Отправить код повторно
  resendCode() {
    this.unsubscibeOnDealStateChangedEvent();
    this.againCode();
  }

  // Повторная генерация смс кода
  againCode() {
    this.resetClasses();
    this.smsCssClassNum = 0;
    this.respMessage = 'Проверка платежа';
    this.smsRespMessage = 'Отправка СМС';
    this.smsCheckRespMessage = 'Проверка кода';
    // Сброс формы
    this.resetForm();
    // Генерация кодав
    this.generateCode();
  }

  // Пhоверка для согласования
  checkForApproval() {
    this.payService.checkBeforeSms(this.deal.id).subscribe(
      data => {
        const res = data as ICheckDealResult;
        this.deal.operPhone = res.userPhone;
        this.operPhone = res.userPhone;
        this.approvalInfo = res.message;
        this.resetClasses();
        // Точка не активна
        if (res.dealState == 'PointNotActive') {
          this.smsAcceptCssClassNumber = 1;
          this.acceptRejected = true;
          this.smsCount = res.message;
        }
        // Готова к оплате
        if (res.dealState == 'ReadyForPay') {
          this.smsAcceptCssClassNumber = 2;
          this.smsCountBool = true;
          this.smsCount = res.message;
        }
        // На согласованиие окно оператора
        if (res.dealState == 'IsAcept') {
          this.smsApprovalCssClassNumber = 2;
          this.approvalInfo = res.message;
          this.showApprove = true;
        }
        // На согласовании окно менеджера
        if (res.dealState == 'IsAceptAdmin') {
          this.smsApprovalCssClassNumber = 2;
          this.approvalInfo = res.message;
          this.showAdmin = true;
        }
        // На согласовании окно Бухгалтера
        if (res.dealState === 'IsAceptAccountant') {
          this.smsApprovalCssClassNumber = 2;
          this.approvalInfo = res.message;
          this.showAccountant = true;
        }
        // Ошибка в лимитах либо отклонение оплаты
        if (
          res.dealState == 'MinLimitError' ||
          res.dealState == 'ZeroError' ||
          res.dealState == 'LimitError' ||
          res.dealState == 'MaxLimitError' ||
          res.dealState == 'InfoError' ||
          res.dealState == 'ApprovalBan'
        ) {
          this.smsApprovalCssClassNumber = 1;
          this.approvalInfo = res.message;
          this.showLimError = true;
        }
        // Ошибка в лимитах
        if (
          res.dealState == 'DayLimitError' ||
          res.dealState == 'TransactionLimitError'
        ) {
          this.smsApprovalCssClassNumber = 1;
          this.approvalInfo = res.message;
          this.showUser = true;
        }
      },
      error => this.setError(error)
    );
  }

  // Генерация кода смс
  generateCode() {
    //Запуск таймера для ввода смс кода
    this.payService.generateCode(this.deal.id, this.deal.concurrencyToken).subscribe(
      data => {
        let result = data as ISmsResult;
        this.deal.concurrencyToken = result.concurrencyToken.toString();

        // Запуск таймера, если ещё остались попытки, если последняя попытка - не запускаем
        if (result.countSendSms < this.MAX_SMS_COUNT) {
          this.initSmsTimer(result.sendSmsInterval + 1);
        }
        //Проверка количества попыток
        this.count = this.MAX_SMS_COUNT - result.countSendSms;
        if (this.count <= 0) {
          this.count = 0;
        }
        // Получение текста об оставшихся попытках
        this.getSmsCountResponse();
        if (result.countSendSms > this.MAX_SMS_COUNT) {
          this.smsRespMessage = result.description;
          this.smsCssClassNum = 1;
        }
        // Смс не отправлено
        if (!result.isSend) {
          this.smsRespMessage = result.description;
          this.smsCssClassNum = 1;
        }
        // Смс отправлено
        if (result.isSend) {
          this.smsRespMessage = result.description;
          this.smsCssClassNum = 2;
        }
        // Если смс уже введена
        if (result.isSucces) {
          this.resetClasses();
          this.cssClassNum = 0;
          this.checkPay();
        }
      },
      error => this.setError(error)
    );
  }

  // Создание формы ввода смс кода
  initForm() {
    this.code = new FormControl('', Validators.required);
    this.codeForm = new FormGroup({
      Code: this.code
    });
  }

  // Сброс формы ввода смс кода
  resetForm() {
    if (this.codeForm) {
      this.codeForm.controls['Code'].setValue('');
    }
  }

  // Отправка смс кода
  onSubmit() {
    if (this.codeForm.valid && this.codeForm.value.Code.length == 6) {
      this.smsCssClassNum = -1;
      this.smsCheckClassNum = 0;

      const confirmModel = new ConfirmDealModel();
      confirmModel.code = this.codeForm.value.Code;
      confirmModel.dealId = Guid.parse(this.deal.id);
      confirmModel.concurrencyToken = this.deal.concurrencyToken;
      //Валидация смс кода для оплаты
      this.payService.sendCodeForCheck(confirmModel).subscribe(
        data => {
          const result = data as ISmsResult;
          // Не пройдена валидация
          if (
            result.wrongCode ||
            result.limitError ||
            !result.isSend ||
            !result.isSucces
          ) {
            // Показываем сообщение, что был введен невенрый код или превышен лимит
            this.deal.concurrencyToken = result.concurrencyToken.toString();
            this.showWrongCodeGotScreen(result.description);
          }
          // Валидация пройдена
          if (result.isSucces) {
            this.smsCheckClassNum = -1;
            this.cssClassNum = 0;
            this.checkPay();
          }
          // Количество попыток отправки смс кода повторно равно нуль
          if (result.limitError) {
            this.hideSmsButton = true;
          }
        },
        error => this.setError(error)
      );
    }
  }

  showWrongCodeGotScreen(errorDescription: string) {
    this.smsCheckRespMessage = errorDescription;
    this.smsCheckClassNum = 1;
    this.hideSmsButton = true;
    this.hideRepeatCodeInputButton = false;
  }

  showCodeInputScreen() {
    // Провереяем, что экран для сообщения о неверном коде отп не был скрыт по кнопке
    const wrongCodeScreenIsNotDisplayingNow = this.smsCheckClassNum != 1;
    // Если был скрыт по кнопке или иным способом, то прерываем выполнение для избежания коллизий
    if (wrongCodeScreenIsNotDisplayingNow) {
      return;
    }
    this.codeForm.reset();
    this.smsCssClassNum = 2;
    this.smsCheckClassNum = -1;
    this.hideSmsButton = false;
    this.hideRepeatCodeInputButton = true;
    this.smsCheckRespMessage = "Проверка кода";
  }

  //Переход в редактирование оплаты
  sendToEdit() {
    this.sendEdit = true;
    this.closeModal();
  }

  sendToAprove() {
    this.showApprove = false;
    this.resetClasses();
    this.smsApproveCssClassNumber = 0;
    this.payService.sendForAprove(this.deal.id, this.deal.concurrencyToken).subscribe(
      data => {
        const result = data as ICheckResult;
        this.deal.operPhone = result.userPhone;
        this.operPhone = result.userPhone;
        this.smsApproveCssClassNumber = 2;
        this.approveInfo = result.message;
      },
      error => {
        const result = error as ICheckResult;
        this.resetClasses();
        if (result) {
          this.errorInfo = result.message ? result.message : result as any as string;
        }
        this.isPaymentError = true;
      }
    );
  }

  // Обновление класса оплаты при закрытии модального окна
  // Отписки от таймеров
  // Получение класса оплаты
  closeModal() {
    if (!this.timerStopped) this.stopSmsTimer();
    this.dealService.getDealById(this.deal.id).subscribe(
      deal => {
        this.result = deal;
        this.result.sendEdit = this.sendEdit;
        this.close();
      },
      error => this.setError(error)
    );
  }

  returnToDeals() {
    if (!this.timerStopped) this.stopSmsTimer();

    this.router.navigateByUrl("/deals");
    this.close();
  }

  // Активация кнопки отправить смскод, если его длина валидна
  validCode() {
    if (this.codeForm && this.codeForm.value.Code.length == 6) {
      this.invalid = false;
    } else {
      this.invalid = true;
    }
  }

  checkPay() {
    this.checkCount++;

    this.dealService.getDealById(this.deal.id).subscribe(
      deal => {
        if (this.checkCount <= 1) {
          this.deal.sum = deal.sum;
          this.deal.cardNumber = deal.cardNumber || this.deal.cardNumber;
        }
        this.onGetDeal(deal);
      },
      error => this.setError(error)
    );
  }

  onGetDeal(deal: IPeopleDeal) {
    this.respMessage = deal.dealStateDescription;

    switch (+DealStateEnum[deal.dealState]) {
      case DealStateEnum.Completed:
        this.isPsOK = true;
        this.unsubscibeOnDealStateChangedEvent();
        this.cssClassNum = 2;
        break;
      case DealStateEnum.InProgress:
        this.cssClassNum = 0;
        if (!this.isOnDealStateChangedSubscribed) {
          this.subscibeOnDealStateChangedEvent();
        }
        break;
      case DealStateEnum.DayLimitError:
      case DealStateEnum.LimitError:
      case DealStateEnum.TransactionLimitError:
      case DealStateEnum.SmsLimitError:
      case DealStateEnum.LimitedAttemptsEntryCode:
      case DealStateEnum.ZeroError:
      case DealStateEnum.PaymentNotFound:
      case DealStateEnum.Canceled:
      case DealStateEnum.KycError:
        this.cssClassNum = 1;
        this.unsubscibeOnDealStateChangedEvent();
        break;
    }
    
    this.hideButtons = false;
  }

  // При уничтожении компонента отписываемся от таймера
  ngOnDestroy() {
    this.destroy.next(true);
  }
}