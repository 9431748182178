<mat-form-field appearance="fill" class="timezone-select">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select [formControl]="control">
    <ng-container *ngIf="countryCode; then singleCountry else groupByCountry">
    </ng-container>
  
    <ng-template #singleCountry>
      <mat-option 
        *ngFor="let timeZone of countryTimeZones[0]?.timeZones"
        [value]="timeZone.id">
        {{timeZone.displayName}}
      </mat-option>
    </ng-template>

    <ng-template #groupByCountry>
      <mat-optgroup
        *ngFor="let countryTimeZone of countryTimeZones"
        [label]="countryTimeZone.countryName"
        [disabled]="false">
        <mat-option 
          *ngFor="let timeZone of countryTimeZone.timeZones"
          [value]="timeZone.id">
          {{timeZone.displayName}}
        </mat-option>
      </mat-optgroup>
    </ng-template>
    
  </mat-select>
</mat-form-field>
