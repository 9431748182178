import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DealInProcessingData } from '../../../models/widgets/deal-in-processing-data';
import { WidgetService } from '../../../services/widget.service';
import { WidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-deals-in-processing-widget',
  templateUrl: './deals-in-processing-widget.component.html',
  styleUrls: ['./deals-in-processing-widget.component.scss', '../base-widget/base-widget.component.scss']
})
export class DealsInProcessingWidgetComponent
  implements WidgetComponent {

  public isLoadingCompleted: boolean = false;

  public hasError: boolean = false;

  public error: string;
    
  public dealsInProcessing: DealInProcessingData = {} as DealInProcessingData;
  
  constructor(
    private widgetService: WidgetService,
    private router: Router,
  ) { }

  async ngOnInit() {
    const dealsInProcessing = await this.widgetService.getDealsInProcessingData().catch(err => {
      this.error = err;
      this.hasError = true;
    });
    this.dealsInProcessing = dealsInProcessing as DealInProcessingData || {} as DealInProcessingData;
    this.isLoadingCompleted = true;
  }

  async redirect(state) {
    await this.router.navigate(['/people-deal']);
  }

}
