<div class="filter__text-align-left" (clickOutside)="onCancel()">
    <div class="select-filter__header">
        <ng-content></ng-content>
    </div>

    <div class="filter__btn select-filter__show-button"
        [ngClass]="{'filter__btn-active': isActive,'filter__margin-left': !_sort}" (click)="isShowModal=!isShowModal;">
    </div>

    <div class="filter zindex select-filter__modal" *ngIf="isShowModal">
        <div class="select-filter__control">
            <ng-select [(ngModel)]="model" ngDefaultControl [placeholder]="placeholder" class="input"
                [items]="items" bindLabel="title" bindValue="value" (ngModelChange)="onApply()"></ng-select>
            <button *ngIf="model" class="select-filter__clear-button" (click)="onClear()"></button>
        </div>
    </div>
</div>