import { LicenseeSettingService } from '@services/licensee-setting.service';
import { Component, OnInit, Input } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, IShortPoint, ConfirmCodeValidator } from 'tsuz-common';

export interface ConfirmModel {
  title: string;
  message: string;
  newCode: string;
  sendMethod: string;
}

@Component({
  selector: 'app-change-confirm',
  templateUrl: './change-confirm.component.html'
})
export class ChangeChannelConfirmComponent
  extends SimpleModalComponent<ConfirmModel, IShortPoint>
  implements ConfirmModel, OnInit {
  codeForm: FormGroup;
  newCode: string;
  sendMethod: string;
  title: string;
  message: string;
  confirmCode: FormControl;
  newCodeDesc: string;

  constructor(private licenseeSettingService: LicenseeSettingService, private notifyService: NotificationService) {
    super();
  }

  // Создание формы
  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.codeForm = new FormGroup(
      {
        ConfirmCode: new FormControl('', [Validators.required, ConfirmCodeValidator()]),
      }
    );
  }
  async confirm() {
    if (this.codeForm.valid) {
      try {
       await this.licenseeSettingService.changeChannel(this.codeForm.value.ConfirmCode, this.sendMethod , this.newCode).toPromise();
       this.notifyService.successNotify('Канал оповещений успешно изменен' , 'Канал оповещений');
       this.close();
      } catch (error) {
        this.notifyService.errorNotify(error , 'Канал оповещений');
      }
    }
  }
}
