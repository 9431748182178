import { IDealDetailsViewModel } from './../../../models/peopledeal/IDealDetailsViewModel';
import { Component, OnInit, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder} from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { Output } from '@angular/core';
import { UsersService } from '@services/users.service';
import { PayCheckModalComponent, ConfirmModel } from '../pay-checkModal/pay-checkModal.component';
import { DealService } from '@services/deal.service';
import { LicenseeService } from '@services/licensee.service';
import { ProductValidateResult } from '../../../models/product/ProductValidateResult';
import { Guid } from 'guid-typescript';
import { UserRoles, AuthService, LoaderService, ILicenseeSetting, NotificationService } from 'tsuz-common';
import { switchMap, takeUntil } from "rxjs/operators";
import { DealDraftViewModel, PeopleDealProductBinding } from "../../../models/peopledeal/DealDraftViewModel";
import { DealFormService } from "@services/deal-form.service";
import { ReplaySubject } from "rxjs";
import { IDetailsPeopleDeal, ILicenseeProduct, IProductMeasureUnit } from '../../../models/peopledeal/IDetailsPeopleDeal';
import { IProvider } from '../../../models/peopledeal/IProvider';
import { IPointDeal } from '../../../models/peopledeal/IPointDeal';
import { CurrencyService } from '../../../core/services/currency.service';

@Component({
  selector: '[create-peopleDeal]',
  templateUrl: './create-peopleDeal.component.html'
})

export class CreatePeopleDeal implements OnInit, OnDestroy {
  @Output() editClose = new EventEmitter<number>();

  listprod: ILicenseeProduct[];
  listpoint: IPointDeal[];
  listForward: DealDraftViewModel;
  settingList: ILicenseeSetting;
  oneScreen = false;
  commentStr: string;
  isReady = false;
  infoLimit: string;
  productMeasureUnits: Array<IProductMeasureUnit[]> = [];
  total = 0;
  currency: string;
  array: FormArray;
  peopleForm: FormGroup;
  pointId: FormControl;
  comment: FormControl;
  externalId: FormControl;
  personId: string = '';
  errorMessage: string;
  productStr: string;
  stepNum = 0;
  hasOne: ProductValidateResult = new ProductValidateResult(true);
  url = '/deal';
  limitInfo: number;
  transcationInfo: number;
  currentLimitInfo: number;
  listProvider: IProvider[];
  listProviderFull: IProvider[];
  dealProvider: IProvider;
  cash = 'Цена';
  hideOper = true;
  hidePp = true;
  nextButton = 'Далее';
  isSaveInDb = false;
  isPostAlready = false;
  dealPorNumber = 0;
  isCp = false;
  sumLoading = true;
  formSending = false;

  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  licenseeId: string;

  constructor(
    private licenseeService: LicenseeService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private simpleModalService: SimpleModalService,
    private usersService: UsersService,
    private dealService: DealService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private dealFormService: DealFormService,
    private currencyService: CurrencyService,
    @Inject('LOCALSTORAGE') private localStorage: any) { }

  ngOnInit(): void {
    this.licenseeId = this.localStorage.getItem('licenseeId');

    this.loaderService.displayFullWidth(true);

    this.currency = this.currencyService.getProfileCurrency();

    this.dealFormService.getDraftModel().pipe(takeUntil(this.destroy)).subscribe(draft => {
      this.listForward = draft ? draft : new DealDraftViewModel();
      this.total = this.listForward.sum;
      this.getSettings();
    });
  }

  getSettings(): void {
    // Получение настроек лицензиата
    this.licenseeService.getSettings(this.licenseeId, true).pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Create deal Component work');

      // Определения настроек для КП
      if (this.settingList.licenseeType === 'CarPrice') {
        this.cash = 'Сумма';
        this.isCp = true;
      }
      // Установка инфо для лимита точки
      this.infoLimit = `Выберите "${this.settingList.pointName}"`;
      // Если скрывается товарная часть, то показывается второй экран
      if (this.settingList.hideDealProduct) {
        this.stepNum = 1;
      }
      // Получение товаров лиценизата
      this.dealService.getProducts().pipe(takeUntil(this.destroy)).subscribe(result => {
        let productsAll = result as ILicenseeProduct[];
        // Выводим товары только для оператора
        if (this.authService.getMainRole() === UserRoles.Cashier) {
          productsAll = productsAll.filter(x => x.forAll);
        }
        this.listprod = productsAll;
        // Получение точек сотрудника
        this.dealService.getUserPoints(localStorage.getItem('userId')).pipe(takeUntil(this.destroy)).subscribe(result2 => {
          this.listpoint = result2 as IPointDeal[];
          // Получение провайдеров
          this.dealService.getProviders().pipe(takeUntil(this.destroy)).subscribe(result3 => {
            this.listProvider = [];
            this.listProviderFull = [];
            this.listProviderFull = result3 as IProvider[];
            // Определение провайдера по умолчанию
            const mainProvider = this.listProviderFull.find(x => x.isMain);
            // Установка для оплаты провайдера
            this.dealProvider = mainProvider as IProvider;
            this.listProvider = result3 as IProvider[];
            // Определение мобильных провайдеров для КП
            if (this.settingList.licenseeType === 'CarPrice') {
              this.initProviders(['carp', 'crpo']);
            }
            // Если точек больше 0, то утсанавливаем о первой точке информацию
            if (this.listpoint.length > 0) {
              this.infoLimit = this.listpoint[0].info;
              // Инициализация точек
              this.initPoint();
            }
            this.loaderService.displayFullWidth(false);
            // Создание формы
            this.createFormControls();
            this.createForm();
            // Проверка одного экрана для мобильного платежа
            this.checkOneScreen();
            // Деактивация кнопки далее/оплатить
            this.peopleForm.setErrors({'invalid': true});
            if (this.listForward && this.listForward.products) {
              this.setProduct();
            } else {
              this.addProduct();
            }
            // Инициализация товарной таблицы
            this.initProductTable();

            this.increase();
            // Проверка формы
            this.check();
            this.isReady = true;
          }, error => console.error(error));
        }, error => console.error(error));
      }, error => console.error(error));
    }, error => {
      console.error(error);
      console.log('Create deal Component not work');
    });
  }

  // Создание контрола для товаров
  initProductTable() {
    const control = <FormArray>this.peopleForm.controls.products;
    for (let i = 0; i < control.controls.length; i++) {
      if (this.peopleForm.value.products[i].trash > 100) {
        <FormArray>control.controls[i]['controls'].trash.setValue(99);
      }
    }
  }

  // Инициализация мобильных првоайдеров(Исключение провайдера для платежей по картам)
  initProviders(provider: string[]) {
    const ids = [];
    for (let i = 0; i < provider.length; i++) {
      const index = this.listProvider.findIndex(x => x.id === provider[i]);
      if (index > -1) {
        ids.push(index);
      }
    }
    if (ids.length > 0) {
      const arr = [];
      for (let i = 0; i < this.listProvider.length; i++) {
        if (ids.findIndex(x => x === i) < 0) {
          arr.push(this.listProvider[i]);
        }
      }
      this.listProvider = arr as IProvider[];
    }
  }

  // Изменения провайдера в форме
  changeProvider() {
    const mainProvider = this.listProvider.find(x => x.id == this.peopleForm.value.products[0].provider);
    this.dealProvider = mainProvider as IProvider;
    this.checkOneScreen();
  }

  // Проверка одного экрана для мобильного платежа
  checkOneScreen() {
    const result = this.dealService.checkOneScreen(this.settingList, this.dealProvider, this.peopleForm.value.products, this.listprod);
    this.oneScreen = result[0];
    this.nextButton = result[1];
  }

  // Перерасчет суммы
  increase(): void {
    const products = this.peopleForm.getRawValue().products;
    if (products != null && products.length > 0) {
      this.sumLoading = true;
      this.dealService
        .getTotalSum(products, this.settingList)
        .pipe(takeUntil(this.destroy))
        .subscribe(
          data => {
            this.total = data.totalSum;
            this.sumLoading = false;
            this.check();
          },
          error => {
            this.sumLoading = false;
            this.notificationService.errorNotify(
              'Ошибка',
              'Ошибка расчёта суммы товарного раздела'
            );
          }
        );
    }
  }

  // Проверка на положительность данных формы
  zeroAmount(event: any) {
    if (!event.target.value || (event.target.value && event.target.value < 0)) {
      event.target.value = 0;
    }
  }

  onFocus(event: any) {
    if (!event.target.value || (event.target.value && event.target.value <= 0)) {
      event.target.value = '';
    }
  }

  onFocusOut(event: any) {
    if (!event.target.value || (event.target.value && event.target.value < 0)) {
      event.target.value = 0;
    }
  }

  checkTrash(event: any) {
    if (event.target.value > 99) {
      event.target.value = 99;
    }
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    this.check();
  }

  // Инициализация точек
  initPoint() {
    if (this.listpoint) {
      this.limitInfo = this.listpoint[0].limit;
      this.transcationInfo = this.listpoint[0].transactionLimit;
      this.currentLimitInfo = this.listpoint[0].currentLimit;
    }
  }

  // Получения инфо о выбранной точке
  checkPoint(event: any) {
    this.usersService.getUserPoint(localStorage.getItem('userId'), event.id, this.settingList).pipe(takeUntil(this.destroy)).subscribe(res => {
      this.listpoint = res[0];
      this.infoLimit = res[1];
      const index = this.listpoint.findIndex(x => x.id === event.id);
      if (index > -1) {
        this.limitInfo = this.listpoint[index].limit;
        this.transcationInfo = this.listpoint[index].transactionLimit;
        this.currentLimitInfo = this.listpoint[index].currentLimit;
      }
    });
    this.check();
  }

  // Проверка цены
  checkPrice(event: any) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    this.check();
  }

  // Проверка еденицы измерения
  checkMeasure(event: any) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    this.check();
  }

  // Проверка на валидность формы
  // this.hasOne = true Форма не валидна
  check() {
    this.hasOne = this.dealService.checkProducts(this.peopleForm.getRawValue().products, this.settingList, this.total);
  }

  // Добавление товара
  addProduct() {
    // Получение контрола товаров
    const control = <FormArray>this.peopleForm.controls.products;
    // Добавления в массив реактивных форм нового контрола
    control.push(
      this.fb.group({
        measureUnitValue: 0,
        trash: 0,
        measureUnitPrice: 0,
        totalPrice: 0,
        productId: [],
        pnumber: null,
        measureUnit: null,
        provider: null,
        phone: null,
        groupKey: null,
        hidePp: true,
        hideProviders: true,
      })
    );
    // Проверка формы на валидность
    this.check();
  }

  //Начальная установка товаров
  setProduct() {
    //Получение контрола товаров
    const control = <FormArray>this.peopleForm.controls.products;
    const phoneValue = this.listForward.phone ? this.listForward.phone.formatPhoneShort() : '';
    //Добавления товаров
    this.listForward.products.forEach(x => {
      // Добавления товаров в массив контролов товаров
      control.push(this.fb.group({
        measureUnitValue: x.measureUnitValue,
        trash: x.trash,
        measureUnitPrice: x.measureUnitPrice,
        productId: x.productId,
        provider: this.listForward.providerId,
        pnumber: x.pnumber,
        measureUnit: x.measureUnitShortName,
        phone: phoneValue,
        onseScreen: x.oneScreen,
        groupKey: x.groupKey,
        hidePp: x.hidePp,
        hideProviders: x.hideProvider,
      }));
      this.hidePp = x.hidePp;
      this.hideOper = x.hideProvider;
    });

    // Поиск првоайдера
    const mainProvider = this.listProviderFull.find(x => x.id === this.listForward.providerId);

    // Установка првоайдера оплате
    this.dealProvider = mainProvider as IProvider;

    // Проверка мобильного платежа на один экран
    this.checkOneScreen();
  }

  // Изменение товара
  changeProduct(id: string, indexControl: number) {
    if (id) {
      // Ищем Id товара
      const index = this.listprod.findIndex(x => x.id === id);
      // Если находим, то добавляем в массив товаров новый
      if (index > -1) {
        this.hideOper = this.listprod[index].hideProviders;
        this.hidePp = this.listprod[index].hidePp;
        const currentProduct = <FormArray>this.peopleForm.controls.products;
        currentProduct.controls[indexControl]['controls'].groupKey.setValue(this.listprod[index].groupKey);
        currentProduct.controls[indexControl]['controls'].hideProviders.setValue(this.listprod[index].hideProviders);
        currentProduct.controls[indexControl]['controls'].hidePp.setValue(this.listprod[index].hidePp);
        currentProduct.controls[indexControl]['controls'].measureUnit.setValue(null);
        this.productMeasureUnits[indexControl] = this.listprod[index].measures.reduce((accumulator, value) => accumulator.concat(value.measureUnits), []);
        // Установка провайдера
        if (this.listprod[index].hideProviders) {
          const mainProvider = this.listProviderFull.find(x => x.isMain);
          this.dealProvider = mainProvider as IProvider;
        } else {
          // Установка провайдера из списка
          if (this.peopleForm.value.products[0].provider) {
            const index = this.listprod.findIndex(x => x.id === id);
            // Если платеж мобильный, то пишем провайдера из мобильного листа провайдеров
            if (index > -1 && this.listprod[index].oneScreen && this.listProvider.length > 0) {
              this.dealProvider = this.listProvider[0] as IProvider;
              const control = <FormArray>this.peopleForm.controls.products;
              for (let i = 0; i < control.controls.length; i++) {
                <FormArray>control.controls[i]['controls'].provider.setValue(this.dealProvider.id);
              }
            } else {
              // Если мобильный лист пуст, то пишем провайдера из общего листа
              const mainProvider = this.listProviderFull.find(x => x.id == this.peopleForm.value.products[0].provider);
              this.dealProvider = mainProvider as IProvider;
            }
          }
        }
      }
      // Проверка на мобильный платеж
      this.checkOneScreen();
      // Проверка на валидность формы
      this.check();
    }
  }

  // Удаления товаров
  deleteProduct(index) {
    const control = <FormArray>this.peopleForm.controls.products;
    control.removeAt(index);
    if (this.peopleForm) {
      // Перерасчет суммы
      this.increase();
    }
    // Проверка формы на валидность
    this.check();
  }

  // Создание формы
  createFormControls() {
    if (this.listForward) {
      this.comment = new FormControl(this.listForward.comment);
    } else {
      this.comment = new FormControl('');
    }
    if (this.listForward && this.listForward.pointId) {
      this.pointId = new FormControl(this.listForward.pointId, Validators.required);
    } else {
      if (this.listpoint.length > 0) {
        this.pointId = new FormControl(this.listpoint[0].id, Validators.required);
      } else {
        this.pointId = new FormControl('', Validators.required);
      }
    }

    this.externalId = new FormControl(Guid.create().toString(), Validators.required);
  }

  createForm() {
    this.peopleForm = this.fb.group({
      products: this.fb.array([]),
      PointId: this.pointId,
      Comment: this.comment,
      ExternalId:  this.externalId
    });
  }

  // Отправка данных
  onSubmit() {
    this.check();
    if (!this.hasOne.isValid || this.sumLoading) {
      return;
    }
    const allProd = this.peopleForm.value.products;
    const mort = JSON.stringify(allProd);

    this.pointId = this.peopleForm.value.PointId;
    this.productStr = mort;
    this.commentStr = this.peopleForm.value.Comment;
    // Если платеж мобильный на один экран, то сразу отправляем данные без второй страницы
    if (this.oneScreen) {
      this.toPost(this.peopleForm.value.PointId, mort);
    } else {
      this.stepNum = 1;
    }
  }

  // Для мобильного платежа
  toPost(pointId: string, prod: any) {
    if (this.formSending) {
      return;
    }
    const formData = new FormData();
    formData.append('Comments', this.peopleForm.value.Comment);
    formData.append('Phone', this.peopleForm.value.products[0].phone.formatPhone());
    formData.append('ProviderId', this.dealProvider.id);
    formData.append('PointId', pointId);
    const jsonPost = JSON.stringify(this.settingList);
    formData.append('products', prod);
    formData.append('settings', jsonPost);
    
    formData.append('ExternalId', this.peopleForm.value.ExternalId);
    this.formSending = true;

    // Если в компоненте вернуться в редактирование оплаты, то вызывается метод PUT а не POST
    if (this.isSaveInDb) {
      // Редактирование из компонента создания
      this.dealService.update(this.dealPorNumber, formData).pipe(takeUntil(this.destroy)).subscribe(data => {
          this.isPostAlready = true;
          this.formSending = false;
          // Модальное окно оплаты для шлюза
          this.simpleModalService.addModal(PayCheckModalComponent, {
            title: 'Детали оплаты',
            deal: data as IDealDetailsViewModel,
            message: 'Confirm message'
          }, {closeOnClickOutside: false})
            .subscribe(() => {
              this.formSending = false;
            });
        },
        error => {
          this.errorMessage = error;
          this.formSending = false;
        });
    } else {
      // Создание оплаты
      this.dealService.create(formData).pipe(takeUntil(this.destroy)).subscribe(data => {
          this.formSending = false;
          if (data.isSaveInDb) {
            this.isSaveInDb = true;
            this.dealPorNumber = data.pornumber;
          }
          // Модальное окно оплаты для шлюза
          this.simpleModalService.addModal(PayCheckModalComponent, {
            title: 'Детали оплаты',
            deal: data as IDealDetailsViewModel,
            message: 'Confirm message'
          }, {closeOnClickOutside: false})
            .subscribe(() => {
              this.formSending = false;
            });
        },
        error => {
          this.errorMessage = error;
          this.formSending = false;
        });
    }
  }

  // Проброска номера оплаты
  porNumberEmmitered(event: any) {
    this.dealPorNumber = event;
  }

  // Проброска существования оплаты в базе
  existDbEmmitered(event: any) {
    this.isSaveInDb = event;
  }

  // Проброска данных из второй старницы
  paySave() {
    this.stepNum = 0;
  }

  // Приим эмиттера нажатия назад
  onPrevClick() {
    this.stepNum = 0;
    this.initProductTable();
  }

  ngOnDestroy(): void {
    if (this.isSaveInDb
      ||
      (!this.peopleForm.dirty
      && !this.peopleForm.value)
      || !this.peopleForm.value.products.filter(x => !!x.productId).length)  {
      return;
    }
    this.fillDraftModel();

    this.dealService.setDraft(this.listForward).pipe(takeUntil(this.destroy)).subscribe(result => {
      this.destroy.next(null);
    });
  }

  fillDraftModel() {

    const payFormValue =  this.peopleForm.value;

    this.listForward.pointId = payFormValue.PointId;
    this.listForward.providerId = this.dealProvider.id;
    this.listForward.comment = payFormValue.Comment;

    const itIsFirstScreen = this.stepNum === 0;
    if (itIsFirstScreen
      && this.peopleForm.value.products[0].phone) {
      this.listForward.phone = this.peopleForm.value.products[0].phone.formatPhone();
    }

    this.listForward.products = new Array;

    payFormValue.products.filter(x => !!x.productId).forEach(x => {
      let p = new PeopleDealProductBinding();
      p.productId = x.productId;
      p.trash = this.getValueOrDefault(x.trash, 0);
      p.measureUnitValue = this.getValueOrDefault(x.measureUnitValue, 0);
      p.measureUnitPrice = this.getValueOrDefault(x.measureUnitPrice, 0);
      p.measureUnitShortName = x.measureUnit.shortName;
      p.measureUnitFullName = x.measureUnit.fullName;
      p.oneScreen = x.oneScreen;
      p.groupKey = this.getValueOrDefault(x.groupKey, '');
      p.hideProvider = x.hideProviders;
      p.hidePp = x.hidePp;
      this.listForward.products.push(p);
    });
  }
  getValueOrDefault(value: any, defaultValue: any = null) {
    return value ? value : defaultValue;
  }
}
