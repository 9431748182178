import { AbstractControl, ValidatorFn } from '@angular/forms';

//Валидатор ФИО по паттерну
export function FioValidator(): ValidatorFn {
  return (control: AbstractControl): { 'maxLength': boolean } | { 'minLength': boolean } | { 'pattern': boolean } | null => {
    if (!control.value) {
      return null;
    }
    var value = control.value;
    if (value.length > 60) {
      return { 'maxLength': true };
    }
    if (value.length < 2) {
      return { 'minLength': true };
    }
    const fioPattern = new RegExp('(^[A-zА-яёЁ]+$)|(^([A-zА-яёЁ]+(\\s-\\s[A-zА-яёЁ]+)+$)|(^[A-zА-яёЁ]+(-[A-zА-яёЁ]+)+$)|(^[A-zА-яёЁ]+(\\s[A-zА-яёЁ]+)+$))');
    return fioPattern.test(value) ? null : { 'pattern': true };
  };
}

