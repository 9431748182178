<div class="licensee-settings__item licensee-settings__item--checkbox-wr">
  <div class="licensee-settings__set">
    <input class="checkbox" type="checkbox" [(ngModel)]="value"  [id]="id">
    <label   class="checkbox-label" for="{{id}}"></label>
  </div>
  <div class="licensee-settings__label">
    {{title}}
  </div>
</div>

