import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullableString'
})
export class NullableStringPipe implements PipeTransform {
  transform(value: any, args?: any): string {
    return  value ?  value : 'Не определен';
  }
}
