import { IDealDetailsViewModel } from './../../models/peopledeal/IDealDetailsViewModel';
import {
  ViewContainerRef,
  OnInit,
  OnDestroy, ViewChild, Component, Inject
} from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { saveAs } from 'file-saver/FileSaver';
import { cloneDeep } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { DealStatusesClassPipe } from '../../shared/directives/custom-pipes/deal-statuses-class.pipe';
import { PayCheckModalComponent } from './pay-checkModal/pay-checkModal.component';
import { DetailsPeopleDeal } from './details/details-peopleDeal.component';
import { SelectLicenseeComponent } from 'app/widgets/select-licensee/select-licensee.component';
import { DealFormService} from '@services/deal-form.service';
import { 
  IPoint,
  UserRoles,
  AuthService,
  LoaderService,
  NotificationService,
  ILicenseeSetting,
  LicenseeModel,
  FilterService,
  ListFilterItemModel,
} from 'tsuz-common';
import { takeUntil } from 'rxjs/operators';
import { PeopleDealFilter } from '../../models/peopledeal/PeopleDealFilter';
import { LicenseeService } from '@services/licensee.service';
import { DealService } from '@services/deal.service';
import { PointService } from '@services/point.service';
import { IPagePeopleDeal, IPeopleDeal } from '../../models/peopledeal/IPagePeopleDeal';
import { IPointDeal } from '../../models/peopledeal/IPointDeal';
import { IFilterState } from '../../models/peopledeal/IFilterState';
import { IProductDeal } from '../../models/peopledeal/IProductDeal';
import { ReplaySubject, timer } from 'rxjs';
import { RealtimeNotificationService } from '../../core/services/realtime-notification.service';
import { ScanDocumentsService } from '@services/scan-documents.service';

class DealCollectionColumns{
  id: string;
  display: string;
}

@Component({
  selector: 'app-deal',
  templateUrl: './people-deal.component.html'
})
export class PeopleDealComponent implements OnInit, OnDestroy {
  userRoles = UserRoles;
  // Создание элемента модального окна
  // @ViewChild('modalWrap', { read: ViewContainerRef, static: true })
  @ViewChild('selectLicenseeRef', { static: true })
  selectLicensee: SelectLicenseeComponent;
  entry: ViewContainerRef;
  peopleDeals: IPagePeopleDeal;
  listprod: IProductDeal[];
  details: IDealDetailsViewModel;
  liststate: IFilterState[];
  dealFilter: PeopleDealFilter;
  totalCount: number;
  listTakes = [5, 10, 15, 20, 50];
  showModals = false;
  showSumFilter = false;
  showMeasureUnitValueFilter = false;
  timemerSubscribe: any;
  timerIsInit = false;
  settingList: ILicenseeSetting;
  items: ListFilterItemModel[];
  smz = false;
  dealTableColumns: DealCollectionColumns[] = [];
  selectedDealTableColumns: any[] = [];
  licensees: LicenseeModel[];
  placeHolderTxt = 'дд.мм.гггг - дд.мм.гггг';
  destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private hasMultiLicenseePermission: Boolean = false;

  constructor(
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
    private dealService: DealService,
    private pointService: PointService,
    private notificationService: NotificationService,
    private simpleModalService: SimpleModalService,
    private dealFormService: DealFormService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private realtimeNotificationService: RealtimeNotificationService,
    private filterService: FilterService,
    private scanDocumentsService: ScanDocumentsService,
    @Inject('LOCALSTORAGE') private localStorage: any
  ) {

  }

  preloadFinish = false;

  private createPeopleDealFilter(licenseeId: string, timeZoneId: string): PeopleDealFilter {
    const result = new PeopleDealFilter();
    result.licenseeId = this.hasMultiLicenseePermission ? licenseeId : null;
    result.timeZoneId = timeZoneId;
    return result;
  }

  ngOnInit() {
    let licenseeId = this.localStorage.getItem('licenseeId');
    this.hasMultiLicenseePermission = this.authService.inRole(UserRoles.FinancialDirector) || this.authService.inRole(UserRoles.Accountant)

    this.dealFilter = this.createPeopleDealFilter(licenseeId, null);
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.dealFilter.initFromParam(val);
        licenseeId = this.dealFilter.licenseeId ?? licenseeId;
        this.licenseeService.getUserLicenseeById(licenseeId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((licensee: LicenseeModel) => {
            this.dealFilter = this.createPeopleDealFilter(licensee.id, licensee.timeZoneId);
            this.dealFilter.initFromParam(val);
            if (this.preloadFinish) {
              this.loadDeals();
              return;
            }
          }, error => { console.error(error); }
        );


        // Получение настроек лицензиата
        this.licenseeService.getSettings(licenseeId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            this.settingList = result as ILicenseeSetting;

            // Получение оплат
            if (this.hasMultiLicenseePermission) {
              this.selectLicensee.loadLicensees();
            }

            this.loadDeals();
            this.subscribeOnDealStateChangedEvent();
            this.getListPoints();

            this.preloadFinish = true;
          }, error => {
            console.error(error);
            console.log('Deal Component doesn\'t work');
          });

          this.dealService.GetSelfEmployedUsingState().subscribe(data => {
            this.smz = data;
            this.fillDealTeableColumnsList();
          });
    });
  }

  onDealTableColumnsChange(value: any): boolean {
     return this.localStorage.setItem('selectedDealTableColumns', JSON.stringify(value));
  }

  columnIsVisible(id: string): boolean{
    return this.selectedDealTableColumns.some(x => x === id);
  }

  getListPoints() {
    this.pointService.getAllPoint(this.dealFilter.licenseeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(points => {
      const listpoint = points as IPoint[];
      if (listpoint) {
        listpoint.sort((a, b) => a.title > b.title ? 1 : -1);
        this.items = [];
        listpoint.forEach(element => {
          const item = new ListFilterItemModel(element.address, element.pointId, false);
          this.items.push(item);
        });
      }
    }, error => console.error(error));
  }

  fillDealTeableColumnsList(): void {
    this.dealTableColumns = [
      { "id": 'status', "display": 'Статус'},
      { "id": 'dealNumber', "display": 'Номер оплаты'},
      { "id": 'date', "display": 'Дата'},
      { "id": 'operator', "display": 'Оператор'},
      { "id": 'sum', "display": 'Сумма'},
      { "id": 'client', "display": 'Продавец'},
      { "id": 'commision', "display": 'Комиссия'},
      { "id": 'psa', "display": 'ПСА'},
    ];

    if(this.smz){
      this.dealTableColumns.push({ "id": 'documents', "display": 'Фискальные документы'});
    }

    var columnsFromStorage = JSON.parse(this.localStorage.getItem('selectedDealTableColumns')) as Array<DealCollectionColumns>;
    if (columnsFromStorage){
      this.selectedDealTableColumns = columnsFromStorage.map((x) => (x.id));;
    }
    else {
      this.selectedDealTableColumns = this.dealTableColumns
      .map((x) => (x.id));
    }
  }

  // Получения описаний сканов в зависимости от типа
  getDescriptionScanByType(type: number): string {
    const description = this.scanDocumentsService.getScanDocumentDescription(type);
    return description;
  }

  // Сбор Guid оплат на странице, котоыре необходимо отправить на обновления статуса в шлюз
  updateDealStates() {
    if (this.peopleDeals) {
      const cart = [];
      for (let i = 0; i < this.peopleDeals.deals.length; i++) {
        // Не берем оплаты, которые отклонены или прошли оплаты
        if (
          !(
            new DealStatusesClassPipe().transform(this.peopleDeals.deals[i].dealState.toString()) === 'payment-refusal' ||
            new DealStatusesClassPipe().transform(this.peopleDeals.deals[i].dealState.toString()) === 'payment-paid'
          )
        ) {
          cart.push(this.peopleDeals.deals[i].id);
        }
      }
      if (cart.length > 0) {
        // Обновление статусов оплат
        this.dealService.updatePage(cart).subscribe(
          deals => {
          if (deals) {
            for (let i = 0; i < deals.length; i++) {
                const index = this.peopleDeals.deals.findIndex(
                  x => x.id === deals[i].id
                );
              // Обновление полей оплат
              if (index <= -1) {
                break;
              }
                  const currentDeal = this.peopleDeals.deals[index];
                  currentDeal.dealState = deals[i].dealState;
                  currentDeal.operPhone = deals[i].operPhone;
                  currentDeal.cardNumber = deals[i].cardNumber;
                  currentDeal.phone = deals[i].phone;
                  currentDeal.pointId = deals[i].pointId;
                  currentDeal.clientFIO = deals[i].clientFIO;
                  currentDeal.operatorFIO = deals[i].operatorFIO;
                  currentDeal.pointAddress = deals[i].pointAddress;
                  currentDeal.measureUnitTotal = deals[i].measureUnitTotal;
                  currentDeal.sum = deals[i].sum;
            }
          }
        },
          error => console.error(error)
        );
      }
    }
  }

  // При уничтожении компонента отписываемся от таймера
  ngOnDestroy() {
    this.destroy$.next(null);
  }

  // Подписка событие изменения статуса сделки
  subscribeOnDealStateChangedEvent() {
    this.realtimeNotificationService
    .onDealStateChanged()
    .pipe(takeUntil(this.destroy$))
    .subscribe(message => {
       this.notificationService.infoNotify(message, "Изменен статус сделки.");
       this.updateDealStates()
    });
  }

  // Сброс всех фильтров
  resetFilters() {
    this.dealFilter = this.createPeopleDealFilter(this.dealFilter.licenseeId, this.dealFilter.timeZoneId);
    this.applyFilter(true);
  }

  // Создание оплаты
  showConfirm() {
    this.dealFormService.createDeal();
  }

  // Строка посика для сотрудника
  dropDownUser(id: any) {
    const item = this.peopleDeals.deals.find(i => i.id === id);
    if (item.showCollapse) {
      item.showCollapse = false;
    } else {
      item.showCollapse = true;
      this.peopleDeals.deals.forEach(function (item2) {
        if (item.id !== item2.id) {
          item2.showCollapse = false;
        }
      });
    }
  }

  // Детали оплаты
  showDetails(id: string) {
    if (id !== '') {
      // Получение деталей оплаты
      this.dealService.getInfo(id).subscribe(
        result => {
        this.details = result as IDealDetailsViewModel;
        this.loaderService.displayFullWidth(true);
          // Модальное окно деталей
          const disposable = this.simpleModalService
            .addModal(
              DetailsPeopleDeal,
              {
                title: 'Детали оплаты',
                details: this.details,
                message: 'Confirm message',
                pointName: this.settingList.pointName
              },
              { closeOnClickOutside: true }
            )
            // Подписка на события копирования оплаты
            .subscribe(data => {
              const newDeal = data as IPeopleDeal;
              // Если есть новая оплата и оплат на странице n/n то удаляем нижнюю и добавляем новую
              if (newDeal) {
                this.peopleDeals.deals.unshift(newDeal);
                this.totalCount++;
                if (this.dealFilter.take <= this.peopleDeals.deals.length) {
                  this.peopleDeals.deals.pop();
                }
                // Переход в редактирование скопированной оплаты
                this.EditOrDetail(newDeal);
              }
            });
        },
        error => console.error(error)
      );
    }
  }

  // Редактирование либо детали доступны для сделок, которые еще не были отправленны в шлюз
  showPayOrEdit(deal: IPeopleDeal) {
    if (deal && new DealStatusesClassPipe().transform(deal.dealState.toString()) === 'payment-not-paid') {
      this.EditOrDetail(deal);
    } else {
      this.showCheckPayModal(deal);
    }
  }

  // Открытие модального окна оплыт для шлюза
  showCheckPayModal(deal: any) {
    // Добавление модального окна оплаты
    this.simpleModalService
      .addModal(
        PayCheckModalComponent,
        {
          title: 'Детали оплаты',
          deal: deal as IDealDetailsViewModel,
          operPhone: this.peopleDeals.operPhone,
          message: 'Confirm message',
          isEdit: true
        },
        { closeOnClickOutside: false }
      )
      // Подписка на событие из шлюза
      .subscribe(data => {
        if (data !== undefined) {
          const smsResponse = data as IPeopleDeal;
          const index = this.peopleDeals.deals.findIndex(x => x.id === deal.id);
          this.peopleDeals.deals[index].dealState = smsResponse.dealState;
          this.peopleDeals.deals[index].smsSucces = smsResponse.smsSucces;
          // Если подписка вернула отправку на редактирование
          if (data.sendEdit) {
            this.EditOrDetail(this.peopleDeals.deals[index]);
          }
        }
      });
  }
  
  // Получение оплат
  loadDeals(): void {
    this.loaderService.display(true);
    // Если поисковая строка не использовалась, обнуляем ее
    // Получение оплат
    this.dealService.getPage(this.dealFilter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
      result => {
        this.peopleDeals = result as IPagePeopleDeal;
        this.dealFilter.total = this.peopleDeals.total;
      },
      error => console.error(error)
    );
    this.loaderService.display(false);
  }

  // Получение оплат в формате XML
  getXML(): any {
    this.loaderService.displayFullWidth(true);
    return this.dealService.getXmlList(this.getFilterWithoutPaging(this.dealFilter)).subscribe(
      res => {
        this.saveToFileSystem(res);
      },
      error => {
        this.loaderService.displayFullWidth(false);
        this.notificationService.errorNotify(error.message);
      },
      () => {
        this.loaderService.displayFullWidth(false);
      }
    );
  }
   // Получение оплат в формате csv
   getCSV(): any {
    this.loaderService.displayFullWidth(true);

    return this.dealService.getCsvList(this.getFilterWithoutPaging(this.dealFilter)).subscribe(
      res => {
        this.saveToFileSystem(res);
      },
      error => {
        this.loaderService.displayFullWidth(false);
        this.notificationService.errorNotify(error.message);
      },
      () => {
      this.loaderService.displayFullWidth(false);
  }
    );
  }

  // Получение оплат в формате Excel
  getExcel(): any {
    this.loaderService.displayFullWidth(true);
    return this.dealService.getExcelList(this.getFilterWithoutPaging(this.dealFilter)).subscribe(
      res => {
        this.saveToFileSystem(res);
      },
      error => {
      this.loaderService.displayFullWidth(false);
        this.notificationService.errorNotify(error.message);
      },
      () => {
        this.loaderService.displayFullWidth(false);
      }
    );
  }

  // Получение файлов для скачивания
  getFile(id: string, type: number, event: any) {
    // Отмена действия
    event.stopPropagation();
    this.loaderService.displayFullWidth(true);
    if (!this.dealFilter.query) {
      this.dealFilter.query = '';
    }
    if (!this.dealFilter.empquery) {
      this.dealFilter.empquery = '';
    }
    this.dealService.downloadFile(id, type).subscribe(
      res => {
      this.saveToFileSystem(res);
      },
      error => {
      this.loaderService.displayFullWidth(false);
        this.notificationService.errorNotify(error.message);
      },
      () => {
        this.loaderService.displayFullWidth(false);
        console.log('Completed file download.');
  }
    );
  }

  getFilterWithoutPaging(filter: PeopleDealFilter): PeopleDealFilter {
    const result:PeopleDealFilter = cloneDeep(filter);

    result.take = 0;
    result.page = 0;

    return result;
  }

  // Начала скачивания файла
  private saveToFileSystem(res) {
    saveAs(res.data.body || res.data, res.filename);
  }

  // Получения даты из дата пикера
  getNowDate() {
    // Тип возврата
    let returnDate = '';
    // Получения сегодняшней даты
    const today = new Date();
    // Разделения
    const dd = today.getDate();
    const mm = today.getMonth() + 1; // Январь = 0
    const yyyy = today.getFullYear();
    // Проверка на месяца и дни с одной цифрой
    if (dd < 10) {
      returnDate += `0${dd}.`;
    } else {
      returnDate += `${dd}.`;
    }

    if (mm < 10) {
      returnDate += `0${mm}.`;
    } else {
      returnDate += `${mm}.`;
    }
    returnDate += yyyy;
    return returnDate;
  }

  // Переход к редактирвоанию оплаты
  showEditModal(id: any) {
    this.dealFormService.editDeal(id);
  }

  // Проверка на возможность редактирования оплаты
  EditOrDetail(deal: IPeopleDeal) {
    if (deal != null) {
      // Получение статуса по возможности редактирования
      this.dealService.getAvailableActions(deal.id).subscribe(
        data => {
        if (!data.edit) {
            // Детали
            this.showDetails(deal.id);
          } else {
            // Редактирование
            this.showEditModal(deal.id);
        }
        },
        error => {
          console.log(error);
    }
      );
  }
  }

  // Обновление оплат при возврате
  onEditClose() {
    this.entry.clear();
    this.applyFilter();
    window.scrollTo(0, 0);
    this.showModals = false;
  }

  // В зависимости от состояний получаем оплату
  changeState(st: string) {
    this.dealFilter.state = st;

    this.applyFilter();
  }


  onChangeLicensee(selected: LicenseeModel): void {
    this.dealFilter.licenseeId = selected.id;
    this.dealFilter.timeZoneId = selected.timeZoneId;
    this.getListPoints();

    this.applyFilter();
  }

  applyFilter(reset = false) {
    this.filterService.applyFromModel(this.dealFilter, true);
  }

  applyPagination(reset = false) {
    this.filterService.applyFromModel(this.dealFilter, true);
  }
}
