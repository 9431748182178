import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IUsersService } from '../../../services/interfaces/IUsersService';
import { IUser } from '../../../models/IUser';
import { FioValidator } from '../../../validators/fio.validator';
import { LengthValidator } from '../../../validators/length.validatorr';
import { CheckEmailValidator } from '../../../validators/email.validator';
import { PhoneValidator } from '../../../validators/phone.validator';
import { NotificationService } from '../../../services/notification.service';
import { changeFormGroupDisabledState } from '../../../helpers/form.helper';
import { getPhoneMask } from '../../../helpers/masks.helper';

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
}

@Component({
  selector: '[edit-user]',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements ConfirmModel, OnInit {
  @Input() user: IUser;
  @Input() licenseeId: string;
  @Input() readonly: boolean = false;

  empForm: FormGroup;
  name: FormControl;
  surname: FormControl;
  middlename: FormControl;
  phone: FormControl;
  hasFakePhone: FormControl;
  title: string;
  message: string;
  login: FormControl;
  email: FormControl;
  errMes: string;
  errBool: boolean = false;
  getPhoneMask = getPhoneMask;
  
  constructor(
    @Inject('UsersService') private usersService: IUsersService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    //Создание формы
    this.createFormControls();
    this.createForm();

    if (this.readonly) {
      changeFormGroupDisabledState(this.empForm, !this.readonly);
    }
  }

  createFormControls() {
    if (this.user) {
      this.name = new FormControl(this.user.name, [Validators.required, FioValidator()]);
      this.phone = new FormControl((<any>this.user.phone).formatPhoneShort(), [Validators.required, PhoneValidator()]);
      this.surname = new FormControl(this.user.surname, [Validators.required, FioValidator()]);
      if (this.user.middleName === 'null' || !this.user.middleName) {
        this.user.middleName = null;
      }
      this.middlename = new FormControl(this.user.middleName, FioValidator());
      if (this.user.email === 'null') {
        this.user.email = null;
      }
      if (this.user.login === 'null' || !this.user.login) {
        this.user.login = null;
      }
      this.login = new FormControl(this.user.login, LengthValidator(2, 60));
      this.email = new FormControl(this.user.email, CheckEmailValidator());
      this.hasFakePhone = new FormControl(this.user.hasFakePhone);
    } else {
      this.name = new FormControl('', [Validators.required, FioValidator()]);
      this.phone = new FormControl(null, [Validators.required, PhoneValidator()]);
      this.surname = new FormControl('', [Validators.required, FioValidator()]);
      this.middlename = new FormControl(null, FioValidator());
      this.email = new FormControl(null, CheckEmailValidator());
      this.login = new FormControl('');
      this.hasFakePhone = new FormControl(false);
    }
  }

  createForm() {
    this.empForm = new FormGroup({
      Name: this.name,
      Phone: this.phone,
      Surname: this.surname,
      MiddleName: this.middlename,
      Email: this.email,
      Login: this.login,
      HasFakePhone: this.hasFakePhone
    });
  }

  // Отправка данных
  toPost() {
    if (!this.empForm.invalid) {
      if (this.user && this.user.id) {
        this.errBool = false;
        const phoneNumber = this.empForm.value.Phone;
        let formData = new FormData();
        formData.append('Name', this.empForm.value.Name);
        formData.append('Phone', phoneNumber.formatPhone());
        formData.append('Surname', this.empForm.value.Surname);
        formData.append('HasFakePhone', this.empForm.value.HasFakePhone);
        if (this.empForm.value.MiddleName !== 'null' && this.empForm.value.MiddleName) {
          formData.append('MiddleName', this.empForm.value.MiddleName);
        }
        if (this.empForm.value.Login !== 'null' && this.empForm.value.Login) {
          formData.append('Login', this.empForm.value.Login);
        }
        if (this.empForm.value.Email !== 'null' && this.empForm.value.Email) {
          formData.append('Email', this.empForm.value.Email);
        }

        this.usersService.editUser(this.licenseeId, this.user.id, formData).subscribe(data => {
          let result = data as IUser;
          if (result.errorMessage != null) {
            this.errBool = true;
            this.errMes = result.errorMessage;
          }
          else {
            this.user.name = result.name;
            this.user.surname = result.surname;
            this.user.middleName = result.middleName;
            this.user.login = result.login;
            this.user.phone = result.phone;
            this.user.email = result.email;
            this.user.hasFakePhone = result.hasFakePhone;
            
            this.notificationService.successNotify('Пользователь успешно сохранён', 'Редактирование пользователя');
          }

        },
          error => {
            this.notificationService.errorNotify('Произошла ошибка и пользователь не был сохранён', 'Редактирование пользователя');
            console.error(error);
            this.errBool = true;
            this.errMes = error;
          });
      }
    }
  }

  hasFakePhoneChange() {
    const matchingControl = this.empForm.controls['HasFakePhone'];
    if (matchingControl.value) {
      this.email.setValidators([Validators.required, CheckEmailValidator()]);
    } else {
      this.email.setValidators([CheckEmailValidator()]);
    }
    this.email.updateValueAndValidity();
  }
}
