<label for="id" class="label empty">{{labelName}}</label>
<input
  #inputElement
  class="input min-bored"
  [ngClass]="{'input--nonvalidate': formCtrl?.hasError('pattern') == true || formCtrl?.value?.length <= 0,'input--validate': formCtrl?.hasError('pattern') == false}"
  [value]="value"
  type="text"
  [id]="id"
  [placeholder]="placeholder"
  (change)="onChange($event)"
  (keyup)="onKeyup($event)"
  (focusout)="onFocusOut($event)"
  (blur)="onBlur($event)"
  >


  <span class="report report--false report--valid" [ngClass]="{'report--active': formCtrl?.hasError('pattern') == true}">*Неверный формат</span>

    <span class="report report--false report--valid" [ngClass]="{'report--active': formCtrl.hasError('maxLength') == true}">*Максимальная длина поля составляет 60 символов</span>
