
import {map, share, merge, takeUntil} from 'rxjs/operators';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription ,  Observable, Subject , ReplaySubject} from 'rxjs';
import { LicenseeService } from '../services/licensee.service';
import { RealtimeNotificationService } from '../services/realtime-notification.service';
import { AuthService, MenuService, Profile, UserRoles, IHomeBalance, NotificationService, ILicenseeSetting } from 'tsuz-common';

@Component({
  selector: '[app-profile-info]',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css']
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  onBalanceManualUpdate = new Subject<IHomeBalance>();
  homeBalance$: Observable<IHomeBalance>;
  writeOffLicenseeBalance$: Observable<string>;
  balanceInfo: IHomeBalance = <IHomeBalance> { balance: 0, currency: 'RUB'}; //initial value to prevent null reference exceptions

  isLogged = false;
  profile: Profile;
  isAdministrator: boolean;
  isAccountant: boolean;
  showAcceptedDeals: boolean;
  showBalance = false;
  isCollpase: boolean;

  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  settingList: ILicenseeSetting;

  constructor(
    private authenticationService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private licenseeService: LicenseeService,
    private realtimeNotificationService: RealtimeNotificationService,
    private notificationService: NotificationService,

    @Inject('BASE_URL') public baseUrl: string) { }

  loadProfile(): void {
    this.authenticationService.getProfile().subscribe(result => {
      this.profile = result as Profile;
      // Получение инициалов
      if (this.profile.surname.length > 0 && this.profile.name.length > 0) {
        this.profile.icon = this.profile.surname[0] + this.profile.name[0];
      } else {
        this.profile.icon = 'N/A';
      }
      // Определение прав просмотра
      this.isAdministrator = this.authenticationService.inRole(UserRoles.Administrator);
      this.isAccountant = this.authenticationService.inRole(UserRoles.Accountant);
      this.showAcceptedDeals = this.authenticationService.inRole(UserRoles.Administrator, UserRoles.ManagerPlus, UserRoles.Accountant);
      // Получение баланса

      this.licenseeService.getSettings(this.profile.licensee.id).subscribe(result => {
        this.settingList = result as ILicenseeSetting;

        this.showBalance = this.authenticationService.inRole(UserRoles.Administrator, UserRoles.Accountant, UserRoles.FinancialDirector, UserRoles.ManagerPlus)
                            && !this.settingList.disableLicenseeBalance;

        if (this.showBalance) {
          this.onBalanceClick();
        }
      });

      if (!this.profile) {
        this.logout();
      }
    }, error => {
      console.error(error);
      this.logout();
    });
  }

  // Выход
  logout(): void {
    this.authenticationService.logout();
    this.isLogged = this.authenticationService.isLoggedIn();
    this.isCollpase = false;
    this.menuService.display(this.isCollpase);
    this.router.navigate(['/login']);

  }

  // Полпись на события проверка авторизации
  ngOnInit() {
    this.authenticationService.authNavStatus$.subscribe(status => {
      this.isLogged = status;
      if (status) {
        this.loadProfile();
      }
    });

    this.addRealtimeNotification();
  }

  onBalanceClick(): void {
    if (this.showBalance) {
      this.licenseeService
        .getBalance()
        .subscribe(result => {
          this.onBalanceManualUpdate.next(result);
        }, error => console.error(error));
    }
  }

  // Колапсирование меню
  collapseMenu() {
    this.isCollpase = !this.isCollpase;
    this.menuService.display(this.isCollpase);
  }

  addRealtimeNotification() {
    this.realtimeNotificationService
      .onWriteOffLicenseeBalance()
      .pipe(takeUntil(this.destroy))
      .subscribe(message => this.notificationService.warningNotify(message, 'Списание со счета'));

    this.realtimeNotificationService
      .onTopUpBalanceNotification()
      .pipe(takeUntil(this.destroy))
      .subscribe(message => this.notificationService.successNotify(message, 'Пополнение счета'));

    this.homeBalance$ = this.realtimeNotificationService
      .onChangeBalance().pipe(
      merge(this.onBalanceManualUpdate),
      share(),);


    this.homeBalance$.subscribe(balance => this.balanceInfo = balance);
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
