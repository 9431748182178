<form *ngIf="stepNum==0 && isReady && settingList"
  [formGroup]="peopleForm"
  (ngSubmit)="onSubmit()"
  #f="ngForm">
  <div class="newpayment__wrapper">
    <div class="newpayment__header">
      <h1 class="newpayment__header-h1">Новая оплата</h1>
      <div class="newpayment__number">
      </div>
      <div class="newpayment__header-main">
        <div class="newpayment__header-select-point-block">
          <div>
            <label class="label">Выберите&nbsp;{{settingList.pointName | lowercase}}</label>
          </div>
          <div class="newpayment__header-select-point-wrapper">
            <app-select-box-control id="id1538461008"
                                    [items]="listpoint"
                                    bindLabel="title"
                                    bindValue="id"
                                    placeholder="Выберите точку"
                                    formControlName="PointId"
                                    (change)="checkPoint($event)">
            </app-select-box-control>
          </div>
        </div>
        <div class="newpayment__header-block">
          <label class="label">Cуточный лимит</label>
          <div class="daily-limit-amount">
            <span id="id1538463940" >{{limitInfo | money}}</span>
          </div>
        </div>
        <div class="newpayment__header-block">
          <label class="label">Остаток лимита</label>
          <div class="limit-balance-amount">
            <span id="id1538461035">{{currentLimitInfo | money}}</span>
          </div>
        </div>
        <div class="newpayment__header-block">
          <label class="label">Лимит оплаты</label>
          <div class="payment-limit-amount">
            <span id="id1538461071">{{transcationInfo | money}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="newpayment__section">
      <div class="newpayment__section-h4-wrapper">
        <div *ngIf="settingList.licenseeType === 'Default'">Товарная таблица
        </div>
      </div>
      <div class="newpayment__table">
          <div class="newpayment__table-header-row ">
            <div class="newpayment__table-header newpayment__table-header1"
            *ngIf="settingList.licenseeType === 'Default'">№</div>
            <div class="newpayment__table-header newpayment__table-header2">Категория</div>
            <div class="newpayment__table-header newpayment__table-header3">{{cash}}</div>
            <div class="newpayment__table-header newpayment__table-header4" *ngIf="!settingList.hideTrashPercent">Засор,&nbsp;%</div>
            <div class="newpayment__table-header newpayment__table-header5" *ngIf="!settingList.hideWeight">Вес</div>
            <div class="newpayment__table-header newpayment__table-header5" *ngIf="!settingList.hideUnit">Единица измерения</div>
            <div class="newpayment__table-header" *ngIf="!hideOper">Оператор</div>
            <div class="newpayment__table-header" *ngIf="!hidePp">Номер П.П.</div>
            <div class="newpayment__table-header" *ngIf="oneScreen">Номер телефона</div>
          </div>
          <ng-container formArrayName="products">
            <div class="newpayment__table-row"
              *ngFor="let prod of peopleForm.get('products')?.controls; let i=index">
              <ng-container [formGroupName]="i">
                <ng-container *ngIf="settingList.licenseeType === 'Default'">
                  <div class="newpayment__table-col1">
                    <div class="newpayment__table-data edit-delete">
                      <a id="id1538461128{{i}}"
                      class="delete-btn"
                      (click)="deleteProduct(i)">
                      <span class="label--mobile">
                        Удалить строку
                      </span></a>
                    </div>
                  <!-- </div>
                  <div class="newpayment__table-col2"> -->
                    <div class="newpayment__table-data newpayment__table-data-center"><span>{{i+1}}</span></div>
                  </div>
                </ng-container>
                <div class="newpayment__table-cell">
                  <div class="newpayment__table-data">
                    <app-select-box-control id="id1538461184{{i}}"
                                            [items]="listprod"
                                            bindLabel="title"
                                            bindValue="id"
                                            placeholder="Выберите категорию"
                                            formControlName="productId"
                                            (change)="changeProduct(peopleForm.controls.products.value[i].productId,i)">
                    </app-select-box-control>
                  </div>
                </div>
                <div class="newpayment__table-cell">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Цена</span>
                    <input id="id1538461225{{i}}"
                    class="input input--calc"
                    formControlName="measureUnitPrice"
                    placeholder="&ensp;"
                    (input)="checkPrice($event)"
                    (focus)="onFocus($event)"
                    (focusout)="onFocusOut($event)" [dropSpecialCharacters]="false"
                    mask="0*.00"
                    (keyup)="increase()" />
                  </div>
                </div>
                <div class="newpayment__table-cell" *ngIf="!settingList.hideTrashPercent">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Засор</span>
                    <input id="id1538461385{{i}}"
                    class="input input--calc"
                    formControlName="trash"
                    placeholder="&ensp;"
                    (input)="checkTrash($event)"
                    (focus)="onFocus($event)"
                    (focusout)="onFocusOut($event)" [dropSpecialCharacters]="false"
                    mask="00?.00"
                    (keyup)="increase()" />
                  </div>
                </div>
                <div class="newpayment__table-cell" *ngIf="!settingList.hideWeight">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Вес</span>
                    <input id="id1538461411{{i}}"
                    class="input input--calc"
                    formControlName="measureUnitValue"
                    placeholder="&ensp;"
                    (input)="checkMeasure($event)"
                    (focus)="onFocus($event)"
                    (focusout)="onFocusOut($event)" [dropSpecialCharacters]="false"
                    mask="0*.0000"
                    (keyup)="increase()" />
                  </div>
                </div>
                <div class="newpayment__table-cell" *ngIf="!settingList.hideUnit">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Единица измерения</span>
                    <app-select-box-control id="id1538461435{{i}}"
                                            [items]="productMeasureUnits[i]"
                                            bindLabel="fullName"
                                            bindValue="shortName"
                                            placeholder="Выберите ед. измерения"
                                            formControlName="measureUnit"
                                            (change)="increase()">
                    </app-select-box-control>
                  </div>
                </div>
                <div class="newpayment__table-cell"
                *ngIf="!peopleForm.controls.products.value[i].hideProviders">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Оператор</span>
                    <app-select-box-control id="id1538461469{{i}}"
                                            [items]="listProvider"
                                            bindLabel="title"
                                            bindValue="id"
                                            placeholder="&ensp;"
                                            formControlName="provider"
                                            (change)="increase()"
                                            (change)="changeProvider()">
                    </app-select-box-control>
                  </div>
                </div>
                <div class="newpayment__table-cell"
                *ngIf="!peopleForm.controls.products.value[i].hidePp">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Номер П.П.</span>
                    <input id="id1538461495{{i}}"
                      class="input input--calc"n
                      style="margin-bottom:0"
                      formControlName="pnumber"
                      maxlength="20"
                      placeholder="&ensp;"
                      (keyup)="increase()" />
                  </div>
                </div>
                <div class="newpayment__table-cell"
                *ngIf="oneScreen">
                  <div class="newpayment__table-data">
                    <span class="label--mobile">Номер телефона</span>
                    <app-phone-control  id="id1538461528{{i}}"
                                        formControlName="phone"
                                        hideValidationError="true"
                                        overrideCssClass="input input--calc"
                                        (change)="check()"
                                        (keyup)="check()">
                    </app-phone-control>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
      </div>
      <span class="report report--false report--valid"
        [ngClass]="{'report--active': !hasOne.isValid}">
        {{hasOne.errorMessage}}
      </span>
    </div>
    <div class="newpayment__total-amount-wrapper">
      <tsuz-add-button id="id1538461568" (click)="addProduct()" *ngIf="settingList.licenseeType === 'Default'"></tsuz-add-button>
      <div class="flex-wrapper">
        <div class="label">
          К&nbsp;оплате:
        </div>
        <div id="id1538461591" class="newpayment__total-amount">
          {{ total | money: currency }}
        </div>
      </div>
    </div>
    <div class="comment-textarea__block">
      <div class="comment-textarea__label">Комментарий</div>
      <div class="comment-textarea__wrapper">
        <textarea id="id1538461673"
          maxlength="255 "
          class="comment-textarea"
          [attr.disabled]="(isPostAlready && (peopleForm.value.Comment && peopleForm.value.Comment.length > 0)) ?'disabled':null"
          formControlName="Comment"
          placeholder="Комментарий..."
          (change)="check()"
          (keyup)="check()">
        </textarea>
      </div>
    </div>
    <ng-container *ngIf="errorMessage">
      <ng-container *ngFor="let errorItem of errorMessage.split(';')">
        <span class="false-code report report--false">*{{errorItem}}</span>
        <br>
      </ng-container>
    </ng-container>

    <div class="newpayment__footer">
      <div class="main-deals-btns">
        <button id="id1538461703"
          class="btn btn--active btn--back"
          [routerLink]="['/deals']">
          К списку оплат
        </button>
        <button id="id1538461724"  [submitDisable]="formSending"
          [ngClass]="{'btn--active': !formSending && hasOne.isValid && !sumLoading }"
          [disabled]="formSending || !hasOne.isValid || sumLoading"
          value="Submit"
          type="submit"
          class="btn"
          >{{nextButton}}
        </button>
      </div>
    </div>
  </div>
</form>
