export const environment = {
  title: 'Цифровой учёт закупок',
  production: true,
  apiUrl: '/api',
  cardUrl: '/api/banks/cards?bin=',
  signalrEnabled: true,
  // время проверки версии клиента в милисекундах
  appVersionInterval: 300000,
  signalrUrl: '/signalr/notification',
  showLicenseeTransaction: true,
  fileSize: '2097152',
  minYear: 1898,
  notifyUrl: '/notifier',

// Состояние сотрудников
empState: [
  {
    value: 'red',
    title: 'Красный'
  }
  ,
  {
    value: 'yellow',
    title: 'Желтый'
  },
  {
    value: 'green',
    title: 'Зеленый'
  }
],  // Страны по паспорту и паттерны к ним
passportCountry: [
  {
    value: 'AZE',
    title: 'Азербайджан',
    message: 'A00000000',
    ngmask: 'S00000000?',
    regex: /[A-Za-z]{1}[0-9]{7,8}/
  },
  {
    value: 'ARM',
    title: 'Армения',
    message: 'AA0000000',
    ngmask: 'SS?0000000?',
    regex: /[A-Za-z]{1,2}[0-9]{6,7}/
  },
  {
    value: 'BLR',
    title: 'Белоруссия',
    message: 'AA0000000',
    ngmask: 'SS0000000',
    regex: /[A-Za-z]{2}[0-9]{7}/
  },
  {
    value: 'GEO',
    title: 'Грузия',
    message: '00AA00000',
    ngmask: '00SS00000',
    regex: /[0-9]{2}[A-Za-z]{2}[0-9]{5}/
  },
  {
    value: 'KAZ',
    title: 'Казахстан',
    message: 'N00000000',
    ngmask: 'A?00000000000',
    regex: /^(([N,S,D]{1}[0-9]{8})|([0-9]{9})|([0-9]{12}))$/
  },
  {
    value: 'KGZ',
    title: 'Киргизия',
    message: 'AA0000000',
    ngmask: 'SS0000000',
    regex: /[A-Za-z]{2}[0-9]{7}/
  },
  {
    value: 'LVA',
    title: 'Латвия',
    message: 'AA0000000',
    ngmask: 'SS0000000',
    regex: /[A-Za-z]{2}[0-9]{7}/
  },
  {
    value: 'LTU',
    title: 'Литва',
    message: 'AA00000000',
    ngmask: 'SS000000',
    regex: /[A-Za-z]{2}[0-9]{6}/
  },
  {
    value: 'MDA',
    title: 'Молдавия',
    message: 'AA0000000',
    ngmask: 'SS0000000',
    regex: /[A-Za-z]{2}[0-9]{7}/
  },
  {
    value: 'TJK',
    title: 'Таджикистан',
    message: '000000000',
    ngmask: '0000000?0?0?',
    regex: /[0-9]{6,9}/
  },
  {
    value: 'TKM',
    title: 'Туркмения',
    message: 'A0000000',
    ngmask: 'S0000000',
    regex: /[A-Za-z]{1}[0-9]{7}/
  },
  {
    value: 'UZB',
    title: 'Узбекистан',
    message: 'AA0000000',
    ngmask: 'SS0000000',
    regex: /[A-Za-z]{2}[0-9]{7}/
  },
  {
    value: 'UKR',
    title: 'Украина',
    message: 'A000000',
    ngmask: 'S000000',
    regex: /[A-Za-z]{1}[0-9]{6}/
  },
  {
    value: 'EST',
    title: 'Эстония',
    message: 'A0000000',
    ngmask: 'S0000000',
    regex: /[A-Za-z]{1}[0-9]{7}/
  },
  {
    value: 'ABH',
    title: 'Абхазия',
    message: '00 000000',
    ngmask: '00 000000',
    regex: /[0-9]{2} [0-9]{6}/
  },
  {
    value: 'RUS',
    title: 'Россия',
    message: '00 00 000000',
    ngmask: '00 00 000000',
    regex: /[0-9]{2} [0-9]{2} [0-9]{6}/
  },
  {
    value: 'Other',
    title: 'Другое',
    message: '',
    ngmask: '',
    regex: /[a-zA-Zа-яА-я0-9]+( [a-zA-Zа-яА-я0-9]+)*/
  }
],
// Согласование
pointGate: [
  {
    value: false,
    title: 'Запретить'
  },
  {
    value: true,
    title: 'Согласовывать'
  }],
  licenseeDefault: 'Default',
  licenseeCarPrice: 'CarPrice'
};
