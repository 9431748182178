import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: "cardStars"
})
//Пайп для шифрования номера карты на стороне клиента
export class CardStarsPipe implements PipeTransform {
    transform(
        value: string
    ): string {
        let cardStr = "**** **** **** ****";
        if (value) {
            cardStr = value;
            if (value.length == 16) {
                cardStr = `${value.substring(0, 4)} **** **** ${value.substring(12, 16)}`;
            }

            if (value.length > 16) {
                cardStr = `${value.substring(0, 4)} **** **** ${value.substring(12, 16)} ***`;
            }
        }
        return cardStr;
    }
}