<div class="table__cell" *ngIf="columnIsVisible('status')">
  <div class="table__data">
    <div class="flex-wrapper">
      <div class="{{deal.dealState | dealStatusesClass}} legend__filter" id="id1542712554{{index+1}}"></div>
    </div>
  </div>
</div>

<div class="table__cell" *ngIf="columnIsVisible('organization')">
  <div class="table__data">
      <span class="label--mobile">Организация:</span>{{deal.organizationName}}
  </div>
</div>

<div class="table__cell" *ngIf="columnIsVisible('dealNumber')">
  <div class="table__data">
    <span class="label--mobile">Номер оплаты:</span>
    <a id="id1542712575{{index+1}}"
      (click)="dealInfoEmit()"
      class="deal-number-blue">
      {{deal.dealNumber}}</a>
  </div>
</div>

<div class="table__cell" *ngIf="columnIsVisible('sum')">
  <div class="table__data">
      <span class="label--mobile">Сумма: </span>{{deal.sum | money: deal.currency}}
  </div>
</div>

<div class="table__cell" *ngIf="columnIsVisible('commision')">
  <div class="table__data">
    <span class="label--mobile">Cумма комиссии: </span>{{deal.tsuzCommission | money: deal.currency}}
  </div>
</div>

<div class="table__cell" *ngIf="columnIsVisible('withdrawTypeName')">
  <div class="table__data">
    <span class="label--mobile">Тип оплаты: </span>{{deal.withdrawTypeName}}
  </div>
</div>

<div class="table__cell" *ngIf="columnIsVisible('cardNumber')">
  <div class="table__data table__data--break" *ngIf="deal.cardNumber; else mobilePay">
      <span class="label--mobile">Номер карты:</span>{{deal.cardNumber}}
  </div>
  <ng-template #mobilePay>
      <div class="table__data">
          Мобильный платеж
      </div>
  </ng-template>
</div>

<div class="table__cell" *ngIf="columnIsVisible('phone')">
  <div class="table__data">
    <span class="label--mobile">Телефон клиента:</span>{{deal.clientPhone | phoneFormated}}
  </div>
</div>

<div class="table__cell" *ngIf="columnIsVisible('date')">
  <div class="table__data">
    <span class="label--mobile">Создан:</span>
    {{deal.created | timeZone : deal.licenseeTimeZoneId}}
  </div>
</div>
<!-- Документы сделки -->

<div class="table__cell" *ngIf="columnIsVisible('documents')">
  <div class="table__data">
    <ng-container *ngIf="deal.scanDocuments && deal.scanDocuments.length > 0">
      <a
        class="download__btn"
        *ngFor="let file of deal.scanDocuments"
        [title]="getScanDocumentDescription(file.type)"
        (click)="downloadScanDocumentAsync(deal.id, file.type)">

        <span class="label--mobile">
          {{ getScanDocumentDescription(file.type) }}
        </span>

      </a>
    </ng-container>

  </div>
</div>
