import {checkINN} from 'ru-validation-codes';
import {AbstractControl, ValidationErrors} from '@angular/forms';

export function SmzInnValidator(control: AbstractControl): ValidationErrors | null {
  const condition = !!control.value;
  if (condition) {
    if (!checkINN(control.value)) {
      return {smzInnValidator: 'ИНН невалидный'};
    }
  }
  return null;
}
