import { Component, OnInit } from '@angular/core';
import { PlatformBindingRequestService } from '../shared/platform-binding-request.service';
import { PlatformBindingRequestFilter } from '../shared/platform-binding-request-filter.model';
import { Paginated } from '../../core/models/paginated';
import { IPlatformBindingRequest } from '../shared/platform-binding-request.model';
import { Observable } from 'rxjs';
import { PlatformBindingRequestCreateComponent } from '../platform-binding-request-create/platform-binding-request-create.component';
import { MatDialog } from '@angular/material/dialog';
import { PlatformBindingRequestCreatedComponent } from '../platform-binding-request-created/platform-binding-request-created.component';
import { PlatformBindingRequestCheckStateComponent } from '../platform-binding-request-check-state/platform-binding-request-check-state.component';
import { PlatformBindingRequestUnbindComponent } from '../platform-binding-request-unbind/platform-binding-request-unbind.component';
import { IPlatformBindingRequestCheckState } from '../shared/platform-binding-request-check-state.model';
import { IPlatformBindingRequestUnbind } from '../shared/platform-binding-request-unbind.model';
import {IAngularMyDpOptions } from 'angular-mydatepicker';
import {DatePickerSettingsService} from '@services/date-picker-settings.service';
import {shareReplay} from 'rxjs/operators';
import { DateRange } from 'tsuz-common';

@Component({
  selector: 'app-platform-binding-request-list',
  templateUrl: './platform-binding-request-list.component.html',
  styleUrls: ['./platform-binding-request-list.component.scss']
})
export class PlatformBindingRequestListComponent implements OnInit {
  viewState?: string;
  filter: PlatformBindingRequestFilter = new PlatformBindingRequestFilter();
  requests$: Observable<Paginated<IPlatformBindingRequest[]>>;
  listTakes = [5, 10, 15, 20, 50];
  listStates = [
    { value: null, label: 'Очистить выбор...' },
    { value: 1, label: 'Новая' },
    { value: 2, label: 'В процессе привязки' },
    { value: 3, label: 'Самозанятый привязан' },
    { value: 4, label: 'Самозанятый отказался от привязки' },
    { value: 5, label: 'Самозанятый отвязан' }];
  selectedState = null;
  responseKey = null;
  inn = null;
  dtRange: DateRange;
  placeHolderTxt = 'дд.мм.гггг - дд.мм.гггг';

  // Установка настроке для дата пикера.
  myDateRangePickerOptions: IAngularMyDpOptions;

  constructor(private platformBindingRequestsService: PlatformBindingRequestService,
    private datePickerSettingsService: DatePickerSettingsService,
    public dialog: MatDialog) {
    this.filter.take = this.listTakes[1];
  }

  ngOnInit() {
    this.myDateRangePickerOptions = this.datePickerSettingsService.GetDefaultOptions();
    this.load();
  }

  load() {
    this.requests$ = this.platformBindingRequestsService
      .getRequests(this.filter)
      .pipe(shareReplay(1));
  }

  CheckState(id: string) {
    const dialogRef = this.dialog.open(PlatformBindingRequestCheckStateComponent, {
      panelClass: 'dialog',
      data: { 'id': id } as IPlatformBindingRequestCheckState
    });

    dialogRef.afterClosed()
      .subscribe(() => {
        this.load();
      });
  }

  Unbind(id: string) {
    const dialogRef = this.dialog.open(PlatformBindingRequestUnbindComponent, {
      panelClass: 'dialog',
      data: { 'id': id } as IPlatformBindingRequestUnbind
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.load();
        }
      });
  }

  createRequest() {
    const dialogRef = this.dialog.open(PlatformBindingRequestCreateComponent, {
      panelClass: 'dialog'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result.isSucces) {
          this.created();
        }
      });
  }

  created() {
    const dialogRef = this.dialog.open(PlatformBindingRequestCreatedComponent, {
      panelClass: 'dialog'
    });

    dialogRef.afterClosed()
      .subscribe(() => {
        this.load();
      });
  }

  onNextPage(total: number) {
    if (this.filter.count < total) {
      this.filter.page++;
      this.load();
    }
  }

  onPreviousPage() {
    if (this.filter.page > 1) {
      this.filter.page--;
      this.load();
    }
  }

  getRange(): DateRange {
    return new DateRange().setDateRangeFromMyDateRangePicker(this.dtRange);
  }
}
