import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidateLimit } from '../../../validators/limit.validator';
import { IPzuPoint } from '../../../models/points/IPzuPoint';
import { changeFormGroupDisabledState } from '../../../helpers/form.helper';
import { LicenseeModel } from '../../../models/LicenseeModel';

@Component({
  selector: 'tsuz-point-limit',
  templateUrl: './point-limit.component.html'
})
export class PointLimitComponent implements OnInit {
  @Input() point: IPzuPoint;
  @Input() licensee: LicenseeModel;
  @Input() readonly: boolean = false;
  @Input() errorMessage: string;

  @Output() updatePointLimits = new EventEmitter<any>();
  @Output() resetPointLimits = new EventEmitter<any>();

  limitForm: FormGroup;
  limit: FormControl;
  transactionLimit: FormControl;
  limitMaxError: boolean = false;
  transactionMaxError: boolean = false;
  listGate: any;
  isAcceptedByManagerPlus: FormControl;

  constructor(
    @Inject('environment') private environment: any
  )
  { }

  //Создание форм
  ngOnInit() {
    //получение названий полей сверхлимитных оплат
    this.listGate = this.environment.pointGate;
    
    this.createFormControls();
    this.createForm();

    changeFormGroupDisabledState(this.limitForm, !this.readonly);
    this.changeFormControlDisabledState(this.limit, !this.readonly);
    this.changeFormControlDisabledState(this.transactionLimit, !this.readonly);
  }

  private changeFormControlDisabledState(control: FormControl, shouldEnable: boolean) {
    shouldEnable ? control.enable() : control.disable();
  }

  createFormControls() {
    if (this.point) {
      this.limit = new FormControl(this.point.limit, [Validators.required, ValidateLimit(this.point.currentlimit)]);
      this.transactionLimit = new FormControl(this.point.transactionLimit, Validators.required);
      this.isAcceptedByManagerPlus = new FormControl(this.point.isAcceptedByManagerPlus);
    }
    else {
      this.limit = new FormControl(0, Validators.required);
      this.transactionLimit = new FormControl(0, Validators.required);
      this.isAcceptedByManagerPlus = new FormControl(false);
    }
  }
  createForm() {
    this.limitForm = new FormGroup({
      Limit: this.limit,
      TransactionLimit: this.transactionLimit,
      isAcceptedByManagerPlus: this.isAcceptedByManagerPlus,
    });
  }

  readonly maxInputNumber: number = 1000000000;

  //Проверка максимального значения для вводимого значения
  checkMaxLimit() {
    this.limitMaxError = Number(this.limitForm.value.Limit) > this.maxInputNumber;
    }

  //Проверка максимального значения для транзакций
  checkMaxTransactionLimit() {
    const transactionLimit = Number(this.limitForm.value.TransactionLimit);
    this.transactionMaxError = (this.licensee.transactionLimit > 0 && transactionLimit > this.licensee.transactionLimit) || transactionLimit > this.maxInputNumber;
  }

  onSubmit() {
    this.checkMaxTransactionLimit();
    this.checkMaxLimit();

    if (this.limitForm.valid && !this.limitMaxError && !this.transactionMaxError) {
      this.updatePointLimits.emit(this.limitForm.value);
    }
  }

  resetLimitById() {
    if (this.point.id) {
      this.resetPointLimits.emit(this.point);
    }
  }

}
