import { Injectable } from '@angular/core';

@Injectable()
export class ProviderService {
  alfa: string[];

  //Названия банка для провайдера КП
  initAlfa() {
    let alfa = new Array();
    alfa = ["JOINT STOCK COMPANY ALFA-BANK", "COMMERCIAL INNOVATION BANK ALFA-BANK",
      "CJSC ALFA-BANK", "ALFA BANK", "OJSC ALFA-BANK", "ALFA-BANK"];
    this.alfa = alfa as string[];
  }
}
