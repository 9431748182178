import {Component, OnInit} from '@angular/core';
import {XplatRegistryStatuses} from '../../core/enums/xplatregistry-statuses';
import {Paginated} from '../../core/models/paginated';
import {XplatRegistriesService} from '@services/xplatregistries.service';
import {saveAs} from 'file-saver/FileSaver';
import {LoaderService, NotificationService} from 'tsuz-common';
import {XplatRegistriesFilter} from '../../models/xplatregistries/xplatregistries-filter';
import {IXplatRegistry} from '../../models/xplatregistries/xplatregistry';

@Component({
  selector: 'app-xplatregistries',
  templateUrl: './xplatregistries.component.html',
  styleUrls: ['./xplatregistries.component.scss']
})
export class XplatregistriesComponent implements OnInit {

  filter: XplatRegistriesFilter;
  registries: Paginated<IXplatRegistry[]>;
  RegistryStatuses = XplatRegistryStatuses;

  constructor(private registriesService: XplatRegistriesService,
              private loaderService: LoaderService,
              private notifciationService: NotificationService) {
    this.filter = new XplatRegistriesFilter();
  }

  ngOnInit() {
    this.getRegistries();
  }

  getRegistries() {
    console.log(this.filter);
    this.registriesService.getRegistries(this.filter).subscribe(
      result => {
        this.registries = result as Paginated<IXplatRegistry[]>;
        this.filter.total = this.registries.total;
      },
      error => {
        this.notifciationService.errorNotify('Ошибка получения данных', 'Ошибка');
      }
    );
  }

  download(registry: IXplatRegistry) {
    this.loaderService.displayFullWidth(true);
    this.registriesService.download(registry.id).subscribe(
      result => this.saveToFileSystem(result),
      error => {
        this.loaderService.displayFullWidth(false);
        console.log(error);
        this.notifciationService.errorNotify('Ошибка скачивания реестра', 'Ошибка');
      },
      () => {
        this.loaderService.displayFullWidth(false);
        console.log('Completed file download.');
      }
    );
  }

  send(registry: IXplatRegistry) {
    this.registriesService.send(registry.id).subscribe(
      result => {
        this.getRegistries();
        this.notifciationService.successNotify('Реестр успешно отправлен', 'Уведомление');
      },
      error => {
        this.getRegistries();
        this.notifciationService.errorNotify(error, 'Ошибка');
      });
  }

  resetFilters() {
    this.filter = new XplatRegistriesFilter();
    this.getRegistries();
  }

  private saveToFileSystem(file: { filename: string, data: Blob }) {
    saveAs(file.data, file.filename);
  }
}
