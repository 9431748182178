import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SimpleModalService} from 'ngx-simple-modal';
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from '@angular/router';
import {DealService} from '@services/deal.service';
import {AuthService, LoaderService, Profile, NotificationService, ILicenseeSetting} from 'tsuz-common';
import {environment} from '../../../../environments/environment';
import {PayCheckModalComponent} from '../pay-checkModal/pay-checkModal.component';
import {PaymentModalComponent} from '../payment-modal/payment-modal.component';
import { saveAs } from 'file-saver/FileSaver';
import { DealDraftViewModel } from '../../../models/peopledeal/DealDraftViewModel';
import { IDealDetailsViewModel} from '../../../models/peopledeal/IDealDetailsViewModel';
import { IPeopleDeal } from '../../../models/peopledeal/IPagePeopleDeal';
import { LicenseeService } from '@services/licensee.service';

@Component({
  selector: 'electronic-psa',
  templateUrl: './electronic-psa.component.html'
})
export class ElectronicPsaComponent implements OnInit, OnDestroy  {
  approvalMessage = '';

  isSaving = false;
  errorMessage: string;
  url = '/deal';
  deal: IDealDetailsViewModel;
  operPhone: string;
  passportCountry: string;
  profile: Profile;
  canEdit: boolean = false;
  canPay: boolean = false;
  navigationSubscription: any;
  dealId: string;
  isLoading = false;
  settingList: ILicenseeSetting;
  licenseeId: string;

  @ViewChild('uploaderDeal') inputElDeal: ElementRef;

  constructor(
   private loaderService: LoaderService,
   private simpleModalService: SimpleModalService,
   private dealService: DealService,
   private notifyService: NotificationService,
   private readonly router: Router,
   private licenseeService: LicenseeService,
   private authenticationService: AuthService,
   private route: ActivatedRoute,
   @Inject("LOCALSTORAGE") private localStorage: any,
  ) {
    this.licenseeId = this.localStorage.getItem('licenseeId');
  }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params: ParamMap) => {
        this.dealId = params.get('dealId');
        this.getDealDeatils(this.dealId);
      });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.getDealDeatils(this.dealId);
      }
    });

    this.licenseeService.getForSettings(this.licenseeId).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
    }, error => {
      console.error(error);
    });
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getDealDeatils(dealId: string) {
    this.dealService.getInfo(dealId).subscribe(dealResult => {
      this.authenticationService.getProfile().subscribe(profileResult => {
        this.profile = profileResult as Profile;
        this.deal = <IDealDetailsViewModel>dealResult;

        this.passportCountry = environment.passportCountry.filter(s => s.value === this.deal.customer.passportCountry)[0].title;

        this.dealService.getAvailableActions(this.deal.id).subscribe(
          data => {
            this.canEdit = data.edit;
            this.canPay = data.pay;
          });
      });
    });
  }

  editDeal() {
    this.isSaving = true;
    this.isLoading = true;
    this.loaderService.displayFullWidth(true);
    this.dealService.getForEdit(this.deal.id).subscribe(result => {
      this.loaderService.displayFullWidth(false);
      this.openEditPaymentModal(result);
    }, error => {
      this.approvalMessage ="Ошибка редактирования";
      this.isLoading = false;
    }, () => {
      this.isLoading = true;
    });

  }

  pay() {
    this.isLoading = true;
    this.simpleModalService
      .addModal(
        PayCheckModalComponent,
        {
          title: 'Детали оплаты',
          deal: this.deal,
          isEdit: false,
          showSms: false,
          operPhone: this.operPhone,
          message: 'Confirm message'
        },
        {closeOnClickOutside: false}
      )
      .subscribe(data => {
        if (data !== undefined) {
          const smsResponse = data as IPeopleDeal;
          // Если подписка вернула отправку на редактирование
          if (data.sendEdit) {
            this.editDeal();
          }
        }
      },
      error => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = true;
      });
  }

  cancel(): void {
    this.isLoading = true;
    this.dealService.cancel(this.dealId, this.deal.concurrencyToken).subscribe(res => {
      this.getDealDeatils(this.dealId);
      this.notifyService.successNotify('Оплата успешно отменена', 'Уведомление');
    }, err => {
      this.isLoading = false;
      this.notifyService.errorNotify(err, 'Ошибка');
    });
  }

  hasFile(): boolean {
    return this.deal.scanDocuments
           && this.deal.scanDocuments.length > 0
           && this.deal.scanDocuments.filter(x => x.type === 0).length > 0;
  }
  getFile(id: string, type: number, event: any) {
    event.stopPropagation();
    this.dealService.downloadFile(id, type).subscribe(res => {
      this.saveToFileSystem(res);
    }, error => {

      console.log('download error:', JSON.stringify(error));
    }, () => {

      console.log('Completed file download.');
    });
  }
  private saveToFileSystem(res) {
    saveAs(res.data, res.filename);
  }

  openEditPaymentModal(deal: DealDraftViewModel) {
    // очищаем поле кредитной карты, поскольку переданное значение не соответствует маске
    let disposable = this.simpleModalService.addModal(PaymentModalComponent, {
      title: 'Редактирование оплаты ' + this.deal.dealNumber,
      message: 'Confirm message',
      initialFormValues: deal,
      isEditingForm: true
    })
      .subscribe((isConfirmed) => {
          this.isSaving = false;
          if (isConfirmed) {
            console.log('accepted');
          } else {
            console.log('declined');
          }
        },
        error => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        });
  }

  getMeasurePlaceholder(productIndex: number) {
    if (this.deal.dealSettings.hideUnit) {
      return 'Вес';
    }

    const product = this.deal.products[productIndex];
    const productMeasure = product.measureUnit;

    return productMeasure.measureName + ', ' + productMeasure.shortName;
  }
}
