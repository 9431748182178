import { Injectable } from '@angular/core';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatePickerSettingsService {

  pickerCssClassWrapper = 'dateTimePickerWrapper';

  constructor() { }

  GetDefaultOptions(alignRight = false, margin: number | null = null): IAngularMyDpOptions {
    const styles = this.getStyles(alignRight, margin);
    return {
      dayLabels: {
        su: 'Вс',
        mo: 'Пн',
        tu: 'Вт',
        we: 'Ср',
        th: 'Чт',
        fr: 'Пт',
        sa: 'Сб'
      },
      monthLabels: {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь'
      },
      minYear: environment.minYear,
      maxYear: new Date().getFullYear(),
      dateFormat: 'dd.mm.yyyy',
      alignSelectorRight: alignRight,
      stylesData: {
        selector: `${this.pickerCssClassWrapper}`,  // добавляем свой класс wrapper для удобства изменения стилей
        styles: `${styles}`
      }
    };
  }

  getStyles(alignRight = false, margin: number | null = null): any {
    return `.${this.pickerCssClassWrapper} .myDpSelector {
                    left: ${this.getMarginOrDefault(margin, alignRight)}px !important;
                }`;
  }

  getMarginOrDefault(margin: number | null = null, alignRight = false) {
    const defaultMargin = alignRight ? -210 : -7;

    return margin == null ? defaultMargin : margin;
  }
}
