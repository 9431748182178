import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nominal-account-connect-on',
  templateUrl: './nominal-account-connect-on.component.html',
  styleUrls: ['./nominal-account-connect-on.component.scss', '../holding-structure.component.scss']
})
export class NominalAccountConnectOnComponent implements OnInit {
  @Input()
  isHidden: boolean = false;
  @Input()
  isLoading: boolean = true;
  @Input()
  hasError: boolean = false;
  @Input()
  error: string;

  displayContacts: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  toogle() {
    this.displayContacts = !this.displayContacts;
  }
}
