import { Component, OnInit } from '@angular/core';
import { WidgetService } from '../../../services/widget.service';
import { WidgetComponent } from '../base-widget/base-widget.component';
import { curveMonotoneX } from 'd3-shape';
import { months } from 'moment';
import { CurrencyService } from '../../../core/services/currency.service';

@Component({
  selector: 'app-money-turnover-widget',
  templateUrl: './money-turnover-widget.component.html',
  styleUrls: ['./money-turnover-widget.component.scss']
})
export class MoneyTurnoverWidgetComponent
implements WidgetComponent{

  public hasError: boolean = false;

  public error: string;
  
  public isLoadingCompleted: boolean = false;

  // chart optiions
  chartData: any[];
  view: number[] = [275, 234];
  legend: boolean = true;
  showLabels: boolean = false;
  animations: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;
  legendPosition: string = 'below';
  legendTitle: string = '';
  curve: any = curveMonotoneX;
  
  xAxisTickFormatting(value: number) {
    return '';
  }

  yAxisTickFormatting(value: number) {
    if (value >= 1e3 && value < 1e6) {
      return (value / 1e3) + ' тыс';
    }

    if (value >= 1e6 && value < 1e9) {
      return (value / 1e6) + ' млн';
    }

    if (value >= 1e9) {
      return (value / 1e9) + ' млрд';
    }

    return value;
  }


  colorScheme = {
    domain: ['#3F50B4', '#3F8CB4']
  };

  constructor(private widgetService: WidgetService, private currencyService: CurrencyService) {
  }

  async ngOnInit() {
    const data = await this.widgetService.getTurnoverForMonth().catch(err => {
      this.hasError = true;
      this.error = err;
    });
    this.chartData = this.getMappedData(data);
    this.isLoadingCompleted = true;
  }

  private getMappedData(data: any): object[] {
    const mappedData = new Array();
    if (!data) {
      return mappedData;
    }

    // filter money turnover by main currency for now
    const currency = this.currencyService.getProfileCurrency();

    for (let month in data) {
      const monthText = this.monthNumberToText(month);
      const points = data[month]
        .filter(v => v.currency === currency)
        .map(value => {
          return { name: new Date(value.date).getDate(), value: value.sum };
        });

      mappedData.push({ name: monthText, series: points });
    }

    return mappedData;
  }

  private monthNumberToText(monthNumber: string) {
    const monthes = new Map([
      ['1', 'Январь'],
      ['2', 'Февраль'],
      ['3', 'Март'],
      ['4', 'Апрель'],
      ['5', 'Май'],
      ['6', 'Июнь'],
      ['7', 'Июль'],
      ['8', 'Август'],
      ['9', 'Сентябрь'],
      ['10', 'Октябрь'],
      ['11', 'Ноябрь'],
      ['12', 'Декабрь'],
    ]);

    return monthes.get(monthNumber);
  }

}
