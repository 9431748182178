<ng-select [attr.id]="id ? id : null"
           [items]="items"
           [(ngModel)]="value"
           [disabled]="disabledValue"
           [bindValue]="bindValue"
           [bindLabel]="bindLabel"
           [placeholder]="placeholder"
           (change)="handleChange($event)"
           [ngClass]="{ 'input--nonvalidate': ngControl.invalid }">
</ng-select>
