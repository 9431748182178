<form [formGroup]="platformBindingRequestForm" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Новая заявка</h2>

  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>ИНН самозанятого</mat-label>
      <input matInput
             formControlName="inn"
             required>

      <mat-error *ngIf="inn.invalid && (inn.dirty || inn.touched) && inn.errors.required">
        Введите ИНН
      </mat-error>

      <mat-error *ngIf="inn.invalid && (inn.dirty || inn.touched) && inn.errors.smzInnValidator">
        невалидный номер
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Телефон самозанятого</mat-label>
      <input type="tel"
             matInput
             formControlName="phone">

      <mat-error *ngIf="phone.invalid && (phone.dirty || phone.touched) && phone.errors.pattern">
        невалидный номер телефона
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email самозанятого</mat-label>
      <input type="email"
             matInput
             formControlName="email">

      <mat-error *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email">
        невалидный email
      </mat-error>
    </mat-form-field>

    <div *ngIf="platformBindingRequestForm.errors?.or && (platformBindingRequestForm.touched || platformBindingRequestForm.dirty)">
      Укажите email или номер телефона
    </div>

    <mat-error *ngIf="invalid">
      {{errorMessage}}
    </mat-error>

  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-raised-button
            color="primary"
            type="submit" [disabled]="!platformBindingRequestForm.valid">
      Создать
    </button>
  </mat-dialog-actions>
</form>
