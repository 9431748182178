import { Component, OnInit } from '@angular/core';
import {UserRoles} from 'tsuz-common';
import {AuthService} from 'tsuz-common';
import {UserPermissions} from '../../user-permissions';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {
  userRoles = UserRoles;
  allRolesWithoutSupport: any;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.allRolesWithoutSupport = [... UserPermissions.dataPermissionsNotSupportRoles.permission.permittedRoles];
  }

}
