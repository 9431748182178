import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Сервис для загрузчика
@Injectable()
export class LoaderService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public statusFullWithLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  display(value: boolean) {
    this.status.next(value);
  }
  displayFullWidth(value: boolean) {
    this.statusFullWithLoader.next(value);
  }
}
