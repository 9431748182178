import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
export interface ConfirmModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-pay-reject-modal',
  templateUrl: './pay-reject-modal.component.html'
})
export class PayRejectModalComponent extends SimpleModalComponent<ConfirmModel, string> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  reason: string;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  //Закрытие модального окна
  closeClick() {
    this.close();
  }

  //Получения причины отказа оплаты от сотрудника
  reject(): void {
    this.result = this.reason;
    this.close();
  }
}
