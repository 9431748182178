import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableTextComponent),
      multi: true
    }
  ]
})
export class EditableTextComponent implements ControlValueAccessor {

  @Input() value: string;
  @Input() label: string = "Название";
  @Input() readonly: boolean = false;
  @Output() save = new EventEmitter<string>();

  isEditing = false;
  originalValue: string;

  @ViewChild('toggleElement') toggleElement: ElementRef;
  @ViewChild('inputElement', {static: false}) inputElement: ElementRef;

  propagateChange = (_: any) => { };
  propagateTouched = (_: any) => { };

  constructor(private changeDetectorRef: ChangeDetectorRef) { }
  
  writeValue(obj: any): void {
    this.value = obj;
    this.originalValue = this.value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  onInsideClick() {
    if (this.readonly) return;
    
    this.isEditing = true;
    this.changeDetectorRef.detectChanges(); //to find inputElement
    this.inputElement.nativeElement.focus();
  }

  finishEditing() {
    this.isEditing = false;

    if (!this.value) {
      this.value = this.originalValue; //if empty - reset to original value
    }

    this.save.emit(this.value);
  }

}
