<div class="dropdown__header">
  <h5 class="h5">Редактирование ролей</h5>
</div>
<div class="dropdown__section" *ngIf="!isExecutiveOfficer && (isAdmin() || !compareRoles() || isFinDirector())">
  <div class="msg msg--reset-pass"> Изменение ролей недоступно</div>
</div>
<ng-container *ngIf="isExecutiveOfficer || (!isAdmin() && compareRoles() && !isFinDirector())">
  <div class="dropdown__section" [class.disabled]="readonly">
    <ul class="" [formGroup]="roleForm">
        <ng-container *ngFor="let role of roleArray.controls; let i=index">
          <li class="dropdown__table-row">
            <div class="dropdown__table-cell">

                <input class="checkbox-filter" id="id{{user.id}}ssd{{i}}" type="checkbox" [formControl]="role" (change)="checkValid(i)">
                <label class="checkbox-filter-label" for="id{{user.id}}ssd{{i}}">{{roleList[i] | roleName}}</label>

            </div>
          </li>
        </ng-container>
    </ul>
  </div>
  <div class="dropdown__footer" *ngIf="!readonly">
    <button id="id1538406680" class="btn" *ngIf="!readonly"
      [ngClass]="{'btn--active': hasOne == true}" (click)="toPost()">Сохранить</button>
  </div>
</ng-container>
