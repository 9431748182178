import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZoneOffset'
})
/**
 * Async Pipe that converts UTC Date to Date adding timezone offset in minutes
 * Internally used built-in DatePipe, so the parameters are the same as for DatePipe except the first one: `timeZoneId`
 * Example:
 * <div>{{ deal.createDate | timeZoneOffset : deal.timeZoneOffset : 'dd-MM-yyyy HH:mm (ZZ)' }}</div>
 * will show: '30-03-2024 12:49 (+06:00)'
 * See Luxon table of tokens: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 */
export class TimeZoneOffsetPipe implements PipeTransform {

  transform(value: Date | string, timeZoneOffset: number = 0, format: string = 'dd.MM.yyyy HH:mm (ZZZZZ)', locale?: string): string | null {
    if (!value) {
      return '';
    }
    let dateStr: string = typeof(value) === typeof(Date) ? (value as Date).toUTCString() : value as string;
    if (!dateStr.endsWith('Z')) {
      dateStr += 'Z';
    }
    const date = new Date(dateStr);

    const hours = Math.floor(Math.abs(timeZoneOffset) / 60);
    const hoursStr = String(hours).padStart(2, '0');
    const minutes = Math.abs(timeZoneOffset) % 60;
    const minutesStr = String(minutes).padStart(2, '0');
    const hoursMinutes = hoursStr + minutesStr;
    const timeZone = timeZoneOffset >= 0 ? `+${hoursMinutes}` : `-${hoursMinutes}`;
    // ToDo: ARCH-4: Use 'LOCALE_ID' to initialize DatePipe
    const datePipe = new DatePipe('en-US');
    const result = datePipe.transform(date, format, timeZone, locale);
    return result;
  }
}
