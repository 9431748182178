<div class="users-change-confirm popup3__wrapper">
    <ng-container >
      <div class="popup3__main">
        <div class="popup__header">
          <h4 class="h4">Изменение кода подверждения</h4>
          <a id="id1538469562" class="tap-to-close" (click)="close()"></a>
        </div>
        <div class="popup3__pay-info">
          <div>Пользователю {{user.surname}} {{user.name}}</div>
          <div>{{newCodeDesc}}</div>
          <div *ngIf="showChannelNotification" class="notification">
            Внимание! После сброса кода подтверждения будет использоваться канал связи по умолчанию
          </div>
        </div>
        <form  [formGroup]="codeForm" (ngSubmit)="confirm()" >
          <div class="popup3__sms-input-wrapper">
              <label class="label">для завершения введите ваш код подтверждения</label>
              <password-input 
                formControlName="ConfirmCode"
                class="popup3__sms-input" 
                maxlength="6"
                ></password-input>

          </div>
          <div class="popup3__btn-wrapper">
             <button
             [disabled]="codeForm.invalid"
             [ngClass]="{'btn&#45;&#45;active': codeForm.valid == true}"
             type="submit" class="btn" >
              Подтвердить
            </button>
          </div>
        </form>
      </div>
    </ng-container>

  </div>
