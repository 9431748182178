<app-base-widget
  [header]="'Динамика оборотов'"
  [icoType]="'chart'"
  [isLoading]="!isLoadingCompleted"
  [hasError]="hasError"
  [loadingError]="error"
  [hidePrimaryButton]="true">
    <ngx-charts-line-chart
      class="money-turnover-widget-component"
      [view]="view"
      [scheme]="colorScheme"
      [legend]="legend"
      [legendPosition]="legendPosition"
      [legendTitle]="legendTitle"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [xAxisTickFormatting]="xAxisTickFormatting"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [timeline]="timeline"
      [results]="chartData"
      [curve]="curve">
    </ngx-charts-line-chart>
</app-base-widget>
