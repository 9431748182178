import {Pipe, PipeTransform} from '@angular/core';
import {XplatRegistryStatuses} from '../../../core/enums/xplatregistry-statuses';

@Pipe({
  name: 'xplatRegistryStatusesString'
})
export class XplatRegistryStatusesStringPipe implements PipeTransform {
  transform(value: XplatRegistryStatuses, args?: any): string {

    switch (value) {
      case XplatRegistryStatuses.Generated: return "Сформирован";
      case XplatRegistryStatuses.Sending: return "Отправляется";
      case XplatRegistryStatuses.SuccessfullySent: return "Отправлен";
      case XplatRegistryStatuses.FailureSent: return "Ошибка";
    }
  }
}
