import { LicenseeService } from '@services/licensee.service';
import { Component, OnInit, Input } from '@angular/core';
import { SupportService } from '@services/support.service';
import { IPzuPoint, LoaderService, ILicenseeSetting, PointFilter } from 'tsuz-common';

@Component({
  selector: 'app-support-licensees-points',
  templateUrl: './support-licensees-points.component.html',
  styleUrls: ['./support-licensees-points.component.scss']

})
export class SupportLicenseesPointsComponent implements OnInit {

  points: IPzuPoint[];
  filter: PointFilter = new PointFilter();
  currency: string;
  settingList: ILicenseeSetting;

  @Input() licenseeId: string;

  constructor(
    private supportService: SupportService,
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
  ) {
  }
  ngOnInit() {
    // Получение настроек лицензиата
    this.licenseeService.getSettings(this.licenseeId).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
      this.getPoints();
    }, error => {
      console.error(error);
    });

    this.supportService.getLicensee(this.licenseeId)
      .subscribe(result => this.currency = result.organization.currency);
  }

  // Очистка фильтров
  clearFilter() {
    this.filter = new PointFilter();
    this.getPoints();
  }

  // Получение точек
  getPoints(): void {
    this.loaderService.display(true);

    // Получение точек
    this.supportService.getPoints(this.licenseeId, this.filter).subscribe(result => {
      this.points = result.items;
      this.filter.total = result.total;
      this.loaderService.display(false);
    }, error => console.error(error));
  }

  // Эмиттер обновления точек
  onChanged(edit: IPzuPoint) {
    this.getPoints();
  }

  applyFilter(): void {
    this.filter.page = 1;
    this.getPoints();
  }

  resetFilters(): void {
    this.filter = new PointFilter();
    this.getPoints();
  }

}
