<div class="upblock">
  <h1 class="h1">Список оплат</h1>
<!--  <div class="upblock__btn-wrapper">-->
<!--    <div class="input-wrapper">-->
<!--      <input id="id1542712643" type="text" class="input search-input" [(ngModel)]="filter.query" #searchEmp-->
<!--        placeholder="Найти...">-->
<!--    </div>-->
<!--  </div>-->
    </div>
<div class="upblock legend__wrapper">
  <div class="legend__wrapper">
    <div class="label--mobile">Фильтры статусов оплат:</div>
    <div id="id1538477712" class="legend__filter payment-paid" (click)="changeState('Completed')">
      Оплачено
    </div>
    <div id="id1538477723" class="legend__filter payment-refusal" (click)="changeState('Rejected')">
      Отказ оплаты
    </div>
    <div id="id1538477744" class="legend__filter payment-waiting" (click)="changeState('Wait')">
      Ожидает оплаты
    </div>
    <div id="id1538477765" class="legend__filter payment-approval" (click)="changeState('Accepting')">
      На согласовании
    </div>
    <div id="id1538477777" class="legend__filter payment-not-paid" (click)="changeState('NoPay')">
      Не оплачено
    </div>
    <div id="id1554377896" class="legend__filter  payment-pay-error" (click)="changeState('PayError')">
      Ошибка оплаты
    </div>
    <div id="id1566999358 " class="legend__filter payment-kyc-error" (click)="changeState('KycError')">
      Отказ террористу/экстремисту
    </div>
  </div>
  <div>

    <a id="id1538477805" class="legend__reset-filtres" (click)="resetFilters()">Сбросить фильтры</a>
  </div>
</div>
<!-- Deal state filter END-->
<div class="upblock legend__wrapper">
  <ng-select
    [items]="dealTableColumns"
    class="rounded-dropdown"
    bindLabel="display"
    bindValue="id"
    placeholder="Выберите значения сделки..."
    [multiple]="true"
    (change)="onDealTableColumnsChange($event)"   
    [(ngModel)]="selectedDealTableColumns">
  </ng-select>
</div>
<div class="table">
  <!-- Header filter START-->
  <div class="table__header-row">
    <div *ngIf="columnIsVisible('status')" id="id1542712803" class="table__header-cell table__header-cell--w60">
      <div>Статус</div>
    </div>

    <div id="id1538478394" *ngIf="columnIsVisible('organization')" class="table__header-cell table__header-cell--w200 filter__column relative">
      <app-string-filter
        [(model)]="filter.organizationQuery"
        [queryName]="'organizationQuery'"
        (apply)="applyFilter()"
        placeholder = "Организация..."
        [paginationFilter]="filter">
        Организация
      </app-string-filter>
    </div>
    
    <div id="id1607608257" *ngIf="columnIsVisible('dealNumber')" class="table__header-cell filter__column relative">
      <app-string-filter
        [(model)]="filter.dealNumber"
        [queryName]="'dealNumber'"
        (apply)="applyFilter()"
        mask = "0*"
        [paginationFilter]="filter">
        №&nbsp;Оплаты
      </app-string-filter>
    </div>

    <div id="id1553078617" *ngIf="columnIsVisible('sum')" class="table__header-cell filter__column relative">
      <app-number-range-filter
        [(model)]="filter.summ"
        (apply)="applyFilter()"
        [paginationFilter]="filter">
        Сумма
      </app-number-range-filter>
    </div>
    
    <div class="table__header-cell" *ngIf="columnIsVisible('commision')">
      <div>Cумма комиссии</div>
    </div>
    <div *ngIf="columnIsVisible('withdrawTypeName')" class="table__header-cell filter__column relative">
      <app-list-filter
        [items]="withdrawTypeFilterItems"
        [queryName]="'withdrawTypeKeys'"
        [(model)]= "filter.withdrawTypeKeys"
        (apply)="applyFilter()"
        [paginationFilter]="filter">
        Тип выплаты
      </app-list-filter>
    </div>

    <div  id="id1607608281" *ngIf="columnIsVisible('cardNumber')" class="table__header-cell  filter__column relative">
      <app-string-filter
        [(model)]="filter.cardNumber"
        [queryName]="'cardNumber'"
        (apply)="applyFilter()"
        mask = "0*"
        [paginationFilter]="filter">
        Номер карты
      </app-string-filter>
    </div>
    
    <div id="id1607608289"
         *ngIf="columnIsVisible('phone')"
         class="table__header-cell  filter__column relative">
      <app-string-filter
        [(model)]="filter.phone"
        [queryName]="'phone'"
        (apply)="applyFilter()"
        [textMask]="{mask: getPhoneMask}"
        placeholder = "Номер телефона..."
        [paginationFilter]="filter">
          Номер телефона
      </app-string-filter>
    </div>
    
    <div id="id1538478416"
         *ngIf="columnIsVisible('date')"
         class="table__header-cell filter__column relative">
      <app-date-range-filter
        [(model)]="filter.dateRange"
        (apply)="applyFilter()"
        [paginationFilter]="filter">
        Дата создания
      </app-date-range-filter>
    </div>
    
    <div class="table__header-cell" *ngIf="columnIsVisible('documents')">
      <div>Документы</div>
    </div>
  </div>

  <!-- Header filter END-->
  <!-- Rows START-->
  <ng-container *ngIf="peopleDeals">
    <ng-container *ngFor="let deal of peopleDeals.deals; let i=index">
      <div support-deal-item [deal]="deal" [columns]="selectedDealTableColumns" [index]="i" (dealIdInfo)="showDealInfo($event)"
        (payInfo)="showPayInfo($event)" class="d-table-row table__row ">
      </div>
    </ng-container>
  </ng-container>
  <!-- Rows END-->
</div>
<app-pagination *ngIf="peopleDeals" [(model)]="filter" (apply)="applyFilter()">
</app-pagination>

<div #modalWrap>
</div>

