export enum IntegrationTypes {
  none = 0,
  partnerApi = 1,
  webApi = 2
}
export const IntegrationTypesEnumLabels = new Map<number , string>([
  [0, 'Не установлен'],
  [1, 'апи'],
  [2, 'веб-интеграция'],
]);
