import { Component } from '@angular/core';
import { DateRange, FilterComponentBase, FilterService } from 'tsuz-common';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})

export class DateRangeFilterComponent extends FilterComponentBase<DateRange> {

  constructor(filterService: FilterService) {
    super(filterService);
  }

  protected getDefaultModelValue(): DateRange {
    return new DateRange();
  }

  protected onModelChanging(value: DateRange): DateRange {
    return value ? value : this.getDefaultModelValue();
  }

  get isActive(): boolean {
    return this.filterService.isActive(this.model.queryNameFrom)
     || this.filterService.isActive(this.model.queryNameTo);
  }
}
