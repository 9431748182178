import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'platformBindingRequestStateToString'
})
export class PlatformBindingRequestStateToStringPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    switch (value) {
      case 1: return 'Новая';
      case 2: return 'В процессе привязки';
      case 3: return 'Самозанятый привязан';
      case 4: return 'Самозанятый отказался от привязки';
      case 5: return 'Самозанятый отвязан';
    }
    return 'Не удалось определить состояние заявки';
  }

}
