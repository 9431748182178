import { Injectable } from '@angular/core';
import { IWithdrawTypeService, BaseHttpService } from 'tsuz-common';

@Injectable()
export class WithdrawTypeService implements IWithdrawTypeService {

  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  getAll() {
    return this.baseHttpService.get('/withdraw-types');
  }

  getById(id: string) {
    return this.baseHttpService.get(`/withdraw-types/${id}`);
  }
}
