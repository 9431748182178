import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPzuPoint } from '../../../models/points/IPzuPoint';
import { ILicenseeSetting } from '../../../models/ILicenseeSetting';
import { AuthService } from '../../../services/auth.service';
import { UserRoles } from '../../../models/auth/user-roles';
import { PointFilter } from '../../../filters/point.filter';
import { LicenseeModel } from '../../../models/LicenseeModel';

@Component({
  selector: 'tsuz-points-page',
  templateUrl: './points-page.component.html',
  styleUrls: ['./points-page.component.scss']
})
export class PointsPageComponent implements OnInit {

  @Input() points: IPzuPoint[];

  private _licensee: LicenseeModel;
  get licensee(): LicenseeModel { return this._licensee; }
  @Input() set licensee(value: LicenseeModel) {
    this._licensee = value;
  }

  @Input() settingList: ILicenseeSetting;
  @Input() readonly: boolean = false;
  @Input() filter: PointFilter;
  
  @Output() changed = new EventEmitter<IPzuPoint>();
  @Output() resetPointLimits = new EventEmitter<IPzuPoint>();
  @Output() updatePointLimits = new EventEmitter<any>();
  @Output() updatePointSettings = new EventEmitter<any>();
  @Output() refreshPoints = new EventEmitter<any>();
  @Output() deletePoint = new EventEmitter<any>();

  isDirector = false;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    // Определение прав просмотра
    this.isDirector = this.authService.inRole(UserRoles.Administrator) ||
      this.authService.inRole(UserRoles.ExecutiveOfficer) ||
      this.authService.inRole(UserRoles.ManagerPlus);
  }

  // Показ определенного меню
  showRight(str: string, id: any) {
    if (this.isDirector) {
      const item = this.points.find(i => i.id === id);
      item.isShowUser = false;
      item.isShowLimit = false;
      item.isShowSetting = false;
      if (str === 'user') { item.isShowUser = true; }
      if (str === 'limit') { item.isShowLimit = true; }
      if (str === 'setting') { item.isShowSetting = true; }
    }
  }

  onUpdatePointSettings($event) {
    this.updatePointSettings.emit($event);
  }

  onChanged($event) {
    this.changed.emit($event);
  }

  // Права для доступа к меню
  dropDownUser(id: any) {
    if (this.isDirector) {
      const item = this.points.find(i => i.id === id);
      const dropdownWasOpen = item.isShowDropDown;

      this.points.forEach(p => p.isShowDropDown = false);
      
      if (!dropdownWasOpen) {
        item.isShowDropDown = true;
      }
    }
  }

  onResetPointLimits(point: IPzuPoint) {
    this.resetPointLimits.emit(point);
  }

  onUpdatePointLimits(point: IPzuPoint, updated: any) {
    this.updatePointLimits.emit({ ...updated, pointId: point.id })
  }
  
  onDeletePoint(point: IPzuPoint) {
    this.deletePoint.emit(point);
  }
  
  applyFilter() {
    this.filter.page = 1;
    this.refreshPoints.emit();
  }

}
