<div class="dropdown__header">
  <h5 class="h5">Сотрудник может работать на следующих "{{pointName}}"</h5>

  <ng-container *ngIf="!readonly && !hideAddButton">
    <tsuz-add-button id="id1538408472" [text]="'Добавить ' + pointName" (click)="showManagePzu()" *hasPermission="[[userRoles.ExecutiveOfficer, userRoles.Administrator,userRoles.ManagerPlus]]"></tsuz-add-button>
  </ng-container>
</div>
<div class="dropdown__section" [class.disabled]="readonly">
  <table class="dropdown__table" *ngIf="listPoints">
    <tr class="dropdown__table-row" *ngFor="let point of listPoints; let i = index">
      <td id="id1538408513{{i}}" class="dropdown__table-col">{{point.title}}</td>
      <td id="id1538408528{{i}}" class="dropdown__table-col">{{point.address}}</td>
    </tr>
  </table>
</div>
