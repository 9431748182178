import {LicenseeFilter} from '../models/LicenseeFilter';
import {ILicensee} from '../models/ILicensee';
import {AfterContentInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {FormGroup} from '@angular/forms';
import {ILicenseeCategory} from '../models/ILicenseeCategory';
import {
  LoaderService,
  FilterService,
  ListFilterItemModel,
  ILicenseeBalance,
  BalanceState,
  DEFAULT_STATUS_SELECT_ITEMS
} from 'tsuz-common';
import {SupportService} from '@services/support.service';
import { CurrencyService } from '../../core/services/currency.service';

@Component({
  selector: 'app-support-licensees',
  templateUrl: './support-licensees.component.html',
  styleUrls: ['./support-licensees.component.scss']
})
export class SupportLicenseesComponent implements OnInit, AfterContentInit {
  licensees: ILicensee[];
  // licenseeCategories: ListFilterItemModel[];
  filter: LicenseeFilter = new LicenseeFilter();
  form: FormGroup;
  isCommissionActive: boolean = true;
  isPointsActive: boolean;
  isUsersActive: boolean;
  isBalanceActive: boolean;
  licenseeBalance: ILicenseeBalance = <ILicenseeBalance> { };
  currency: string;

  statusItems = DEFAULT_STATUS_SELECT_ITEMS;
  public BalanceState: typeof BalanceState = BalanceState;
  
  constructor(
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private supportService: SupportService,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private currencyService: CurrencyService
  ) {
    this.filter.take = 10;
  }

  ngOnInit() {
    this.currency = this.currencyService.getProfileCurrencySymbol();
  }

  ngAfterContentInit() {
    this.route.queryParams.subscribe(val => {
      var _filter = new LicenseeFilter();
      _filter.take = 10;
      _filter.initFromParam(val);
      this.filter = _filter;

      // if (!this.licenseeCategories) {
      //   this.getLicenseeCategories();
      // }

      this.getLicensees();
    });
  }

  // Получение списка лицензиатов
  getLicensees() {
    this.loaderService.displayFullWidth(true);
    this.supportService
      .getAllLicensees(this.filter)
      .subscribe(result => {
        this.licensees = result.items;
        this.filter.total = result.total;
        this.loaderService.displayFullWidth(false);
      }, error => {
        this.loaderService.displayFullWidth(false);
        this.toastr.error('Ошибка получения списка лицензиатов', 'Ошибка', {
          closeButton: true,
          progressBar: true
        });
      });
  }

  // getLicenseeCategories() {
  //   this.supportService
  //     .getLicenseeCategories().subscribe(result => {
  //     this.licenseeCategories = result.categories.map(
  //       (item: ILicenseeCategory) => {
  //         return new ListFilterItemModel(item.name, item.id, false);
  //       }
  //     );
  //   }, error => {
  //     this.toastr.error('Ошибка получения списка категорий', 'Ошибка', {
  //       closeButton: true,
  //       progressBar: true
  //     });
  //   });
  // }

  resetFilters() {
    this.filter = this.filterService.reset<LicenseeFilter>(this.filter);
  }

  applyFilter() {
    this.filterService.applyFromModel(this.filter, true);
  }

  // Показ меню
  dropDownLic(id: any) {
    const item = this.licensees.find(l => l.id === id);
    if (item.isShowDropDown) {
      item.isShowDropDown = false;
    } else {
      item.isShowDropDown = true;
      this.setCommissionActive();
    }
    this.changeDropDown(id);
  }

  // Переход к меню
  changeDropDown(id: string): void {
    for (const item of this.licensees) {
      if (item.id !== id) {
        item.isShowDropDown = false;
      }
    }
  }

  setInactiveAllMenuItems(): void {
    this.isPointsActive = false;
    this.isCommissionActive = false;
    this.isUsersActive = false;
    this.isBalanceActive = false;
  }

  setCommissionActive(): void {
    this.setInactiveAllMenuItems();
    this.isCommissionActive = true;
  }

  setPointsActive(): void {
    this.setInactiveAllMenuItems();
    this.isPointsActive = true;
  }

  setUsersActive(): void {
    this.setInactiveAllMenuItems();
    this.isUsersActive = true;
  }

  setBalanceActive(licenseeId: string): void {
    this.setInactiveAllMenuItems();
    this.isBalanceActive = true;
    this.getBalance(licenseeId);
  }

  getDirectorsString(licensee: ILicensee) {
    return Array.from(licensee.directors)
      .map(director => {
        return `${director.surname} ${director.name} ${director.middleName}`;
      })
      .join(', ');
  }

  getBalance(licenseeId: string): void {
    this.licenseeBalance = <ILicenseeBalance>{ };
    
    this.supportService.getLicenseeBalance(licenseeId).subscribe(result => {
      this.licenseeBalance = result;
    }, 
    error => {
      console.error(error)
    });
  }

  balanceClick(licenseeId: string): void {
    this.getBalance(licenseeId);
  }
}
