import { Component, OnInit, Input, Inject } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ManagePzuComponent } from './manage-pzu/manage-pzu.component';
import { IManagePzuPoints } from '../../../models/points/IManagePzuPoints';
import { IShortPoint } from '../../../models/points/IShortPoint';
import { IUser } from '../../../models/IUser';
import { UserRoles } from '../../../models/auth/user-roles';
import { IUsersService } from '../../../services/interfaces/IUsersService';
import { AuthService } from '../../../services/auth.service';

export interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
  pointName: string;
}

@Component({
  selector: '[show-pzu]',
  templateUrl: './show-pzu.component.html',
  styleUrls: ['./show-pzu.component.scss']
})
export class ShowPzuComponent implements ConfirmModel, OnInit {
  userRoles = UserRoles;
  @Input() user: IUser;
  @Input() pointName: string;
  @Input() licenseeId: string;
  @Input() readonly: boolean = false;

  title: string;
  message: string;
  listPoints: any[];
  hideAddButton = false;
  showThis = true;

  constructor(
    @Inject('UsersService') private usersService: IUsersService,
    private simpleModalService: SimpleModalService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.hideAddButton = !this.isEditPzuAvailable();
    if (this.user.pointList) {
      this.listPoints = this.user.pointList;
    } else {
      this.usersService.getAvailablePoints(this.licenseeId, this.user.id).subscribe(result => {
        this.listPoints = result.pointsAdded as IShortPoint[];
      }, error => console.error(error));
    }
  }

  // Создание модального окна с точками для Сотрудника
  showManagePzu() {
    this.usersService.getAvailablePoints(this.licenseeId, this.user.id).subscribe(result => {
      const aviablePoints = result as IManagePzuPoints;
      this.showThis = false;
      const titleModal = `Добавление "${this.pointName}" к сотруднику`;
      const disposable = this.simpleModalService.addModal(ManagePzuComponent, {
        title: titleModal,
        message: 'Confirm message',
        user: this.user,
        pointName: this.pointName,
        points: aviablePoints,
        licenseeId: this.licenseeId
      }, { closeOnClickOutside: true })
        .subscribe((resultActive) => {
          if (resultActive) {
            this.showThis = true;
            this.listPoints = this.user.pointList;
          }
        });
    }, error => console.error(error));
  }

  isEditPzuAvailable(): boolean {
    if (this.user.roles.findIndex(x => x === UserRoles.Administrator 
      || x === UserRoles.FinancialDirector 
      || x === UserRoles.Accountant 
      || x === UserRoles.Support) > -1) {
      return false;
    }

    if (this.user.roles.findIndex(x => x === UserRoles.ManagerPlus) > -1){
      return this.authService.inRole(UserRoles.Administrator, UserRoles.ExecutiveOfficer);
    }

    return true;
  }
}
