import {Component, EventEmitter, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import { IMyDateModel, IMyInputFieldChanged, IAngularMyDpOptions } from 'angular-mydatepicker';
import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DatePickerSettingsService } from '@services/date-picker-settings.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {DateRange} from 'tsuz-common';

@Component({
  selector: 'app-date-range-input',
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateRangeInputComponent),
    multi: true,
  }]
})
export class DateRangeInputComponent implements OnInit, ControlValueAccessor {

  constructor(private datePickerSettingsService: DatePickerSettingsService) { }

  @Input()
  placeholder = 'дд.мм.гггг - дд.мм.гггг';

  @Input()
  pluginInputId: string;

  change: EventEmitter<any> = new EventEmitter<any>();
  value: DateRange;
  myDateModel: IMyDateModel;
  myDateRangePickerOptionsNormal: IAngularMyDpOptions;
  mask: RegExp[] = [/\d/, /\d/, /\./, /\d/, /\d/, /\./, /\d/, /\d/, /\d/, /\d/, /\s/, /-/, /\s/, /\d/, /\d/, /\./, /\d/, /\d/, /\./, /\d/, /\d/, /\d/, /\d/];
  faCalendarIcon = faCalendarAlt;
  faClearIcon = faTimes;
  valueIsNoEmpty: boolean;

  @Input()
  alignRight = false;

  @ViewChild('datepicker') datepicker: any;
  private _onChange = (_: any) => {
    this.change.emit(_);
  }
  private _onTouched = () => { };

  ngOnInit(): void {
    this.myDateRangePickerOptionsNormal = this.datePickerSettingsService.GetDefaultOptions(this.alignRight);
    this.myDateRangePickerOptionsNormal.dateRange = true;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.setPickerValue(this.value);
  }

  private setPickerValue(dateRange: DateRange) {
    if (!dateRange || !dateRange.dateFrom || !dateRange.dateTo) {
      this.myDateModel = {
        isRange: true,
        singleDate: null
      };

      this.deleteCharsNotValidChars(0);
      return;
    }
    this.myDateModel = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: {
          year: dateRange.dateFrom.getFullYear(), month: dateRange.dateFrom.getMonth() + 1, day: dateRange.dateFrom.getDate()
        },
        endDate: {
          year: dateRange.dateTo.getFullYear(), month: dateRange.dateTo.getMonth() + 1, day: dateRange.dateTo.getDate()
        }
      }
    };
  }

  onChange() {
    if (!this.myDateModel) {
      return;
    }
    const dataRange = this.myDateModel.dateRange;
    if (!this.valueWasReset()
      && dataRange
      && dataRange.formatted !== '') {
      this.value = new DateRange('dateFrom', 'dateTo', dataRange);
    } else {
      this.value = null;
      this.myDateModel.dateRange = null;
    }

    this._onChange(this.value);
  }

  onInputFieldChanged(event: IMyInputFieldChanged) {
    this.valueIsNoEmpty = !!event.value;
    // Если модель валидная не проверяем маску
    if (event.valid) {
      return;
    }
    this.checkMask(event.value, this.mask);
  }

  checkMask(value: string, mask: RegExp[]) {
    const charArray = value.split('');
    // Если поле пустое, ничего не делаем
    if (charArray.length <= 0) {
      return;
    }
    // Если символов в поле больше чем в маске, то удаляем "лишние" символы
    if (charArray.length > mask.length) {
      this.deleteCharsNotValidChars(mask.length);
      return;
    }
    // Бежим по символам и проверям соответствие маске
    for (let i = 0; i < charArray.length; i++) {
      const regexp = new RegExp(mask[i]);
      // Если встретили не валидный символ, удаляем его и все что правее него
      if (!regexp.test(charArray[i])) {
        this.deleteCharsNotValidChars(i);
        return;
      }
    }
  }

  deleteCharsNotValidChars(index: number) {
    // Получаем инпут датапикера
    const input = this.getCalendarInput();

    if (!input) {
      return;
    }
    // Удаляем символы правее указанного индекса, включая указанный
    input.value = String(input.value).substring(0, index);
  }

  clearInput() {
    this.deleteCharsNotValidChars(0);
    this.valueIsNoEmpty = false;
    this.onChange();
  }

  private getCalendarInput() {
    if (!this.datepicker) {
      return  null;
    }
    return this.datepicker.elem.nativeElement;
  }

  private valueWasReset() {
    const input = this.getCalendarInput();
    return input && input.value === '';
  }
}
