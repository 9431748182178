import { Component, OnInit, Input } from '@angular/core';
import { DocumentModel } from '../../../models/documents/document.model';
import {TransactionTypes} from '../../../models/documents/transaction-types.enum';

@Component({
  selector: '[document-item]',
  templateUrl: './document-item.component.html',
})
export class DocumentItemComponent implements OnInit {
  @Input() document: DocumentModel;
  @Input() type: TransactionTypes;
  @Input() index: number;
  @Input() currency: string;

  constructor() {
  }

  ngOnInit() {
  }
}
