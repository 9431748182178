import { AbstractControl, ValidatorFn } from '@angular/forms';

// Валидатор Кода подтверждения
export function ConfirmCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    if (!(/^\d+$/.test(value))) {
      return { 'onlyDigits': true }
    }

    if (value.length < 6) {
      return { 'minLength': true };
    }

    if (value.length > 6) {
      return { 'maxLength': true };
    }

    return null;
  };
}
