import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IUserSettingService, BaseHttpService, ChangeAvailableResources} from 'tsuz-common';

@Injectable()
export class UserSettingService implements IUserSettingService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  sendConfirmCode(userId: string): Observable<any> {
    return this.baseHttpService.get('/UserSetting/' + userId);
  }

  changeCode(confirmCode: string, userId: string, defaultCode: any): Observable<any> {
    const requestModel = {
      confirmCode: confirmCode,
      userId: userId,
      defaultCode: defaultCode
    };

    return this.baseHttpService.post('/UserSetting/', requestModel);
  }

  getUserAvailableResources(userId: string): Observable<any> {
    return this.baseHttpService.get('/UserSetting/permissions/' + userId);
  }

  changeUserAvailableResourcesPermissions(userId: string,  model: ChangeAvailableResources): Observable<any> {
    return this.baseHttpService.post('/UserSetting/permissions/' + userId, model);
  }
}
