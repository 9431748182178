import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html'
})
export class CreateUserComponent implements OnInit {
  licenseeId: string;

  constructor(@Inject("LOCALSTORAGE") private localStorage: any) {
  }

  ngOnInit(): void {
    this.licenseeId = this.localStorage.getItem('licenseeId');
  }
}
