import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorService {
  private _errorMessage: string;
  private _errorFields: object;

  get message(): string {
    return this._errorMessage;
  }

  get fields(): object {
    return this._errorFields;
  }

  setError(error: any): void {
    if (typeof error === 'object') {
      if (error.message) {
        this._errorMessage = error.message;
      }

      if (error.fields) {
        this._errorFields = error.fields;
      }
    }
  }

  clear(): void {
    this._errorMessage = null;
    this._errorFields = null;
  }

  getFieldError(key: string): string {
    if (this._errorFields && this._errorFields[key]) {
      return this._errorFields[key][0];
    }

    return null;
  }
}
