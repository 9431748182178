import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@services/users.service';
import { UserFilter, FilterService, IUser, LoaderService, UserRoles, NotificationService } from 'tsuz-common';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  licenseeId: string;
  myId: string;

  users : IUser[] = [];
  filter = new UserFilter();
  userRoles = UserRoles;
  
  constructor(
    private usersService: UsersService,
    private loaderService: LoaderService,
    private filterService: FilterService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject("LOCALSTORAGE") private localStorage: any
  ) { }
  
  ngOnInit(): void {
    this.licenseeId = this.localStorage.getItem('licenseeId');
    this.myId = this.localStorage.getItem('userId');

    this.route.queryParams
      .subscribe(val => {
        this.filter = new UserFilter();
        this.filter.initFromParam(val);
        
        this.getUsers();
    });
  }

  getUsers(): void {
    this.loaderService.display(true);

    this.usersService.getPage(this.licenseeId, this.filter)
      .subscribe(result => {
        this.users = result.items;
        this.filter.total = result.total;
        this.loaderService.display(false);
      }, error => {
        console.error(error);
        this.notificationService.errorNotify(error, 'Ошибка')
      });
  }

  applyPagination() {
    this.filterService.applyFromModel(this.filter, true);
  }

  resetFilters() {
    this.filter = new UserFilter();
    this.filterService.applyFromModel(this.filter, true);
  }

  addUser() {
    this.router.navigate(['/users/create']);
  }

}
