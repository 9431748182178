import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {SelectLicenseeComponent} from './select-licensee/select-licensee.component';
import { TsuzCommonModule } from 'tsuz-common';
import { environment } from 'environments/environment';
import { UsersService } from '@services/users.service';
import { LicenseeService } from '@services/licensee.service';
import { UserSettingService } from '@services/user-setting.service';
import { PointService } from '@services/point.service';
import { WithdrawTypeService } from '@services/withdraw-type.service';
import { ProductService } from '@services/product.service';

@NgModule({
  declarations: [
    SelectLicenseeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TsuzCommonModule.forRoot(environment, LicenseeService, UsersService, UserSettingService, PointService, WithdrawTypeService, ProductService)
  ],
  exports: [
    SelectLicenseeComponent,
  ],
  entryComponents: [
  ]
})
export class WidgetsModule {
}
