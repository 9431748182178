import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleModalModule } from 'ngx-simple-modal';
import { AppComponent } from './app.component';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ChangeChannelComponent} from './components/licensee-settings/change-code/change-channel.component';
import {PointSettingsComponent} from './components/licensee-settings/point-setting/point-settings.component';
import {PeopleDealComponent} from './components/people-deal/people-deal.component';
import {SupportDealComponent} from './components/support/support-deal/support-deal.component';
import {SupportDealItemComponent} from './components/support/support-deal/support-deal-item/support-deal-item.component';
import {TariffsComponent} from './components/tariffs/tariffs.component';
import {ShowProductsComponent} from './components/tariffs/show-products/show-products.component';
import {ManageProductsComponent} from './components/tariffs/show-products/manage-products/manage-products.component';
import {CreatePeopleDeal} from './components/people-deal/create/create-peopleDeal.component';
import {DetailsPeopleDeal} from './components/people-deal/details/details-peopleDeal.component';
import {DealModalInfoComponent} from './components/support/support-deal/deal-modal-info/deal-modal-info.component';
import {PayModalInfoComponent} from './components/support/support-deal/pay-modal-info/pay-modal-info.component';
import {PointsComponent} from './points/points/points.component';
import {ShowEditModal} from './components/people-deal/edit/edit-peopleDeal.component';
import {PayCheckModalComponent} from './components/people-deal/pay-checkModal/pay-checkModal.component';
import {CatalogComponent} from './components/catalog/catalog.component';
import {LicenseeSettingsComponent} from './components/licensee-settings/licensee-settings.component';
import {PayModalInfoItemComponent} from './components/support/support-deal/pay-modal-info/pay-modal-info-item/pay-modal-info-item.component';
import {PayRejectModalComponent} from './components/people-deal/pay-reject-modal/pay-reject-modal.component';
import {LicenseeBalancesComponent} from './components/licensee-balances/licensee-balances.component';
import {DocumentListComponent} from './components/documents/document-list.component';
import {DocumentItemComponent} from './components/documents/document-item/document-item.component';
import {LimitSettingsComponent} from './components/licensee-settings/limit-setting/limit-settings.component';
import {BaseSettingsComponent} from './components/licensee-settings/base-setting/base-settings.component';
import {ChangeChannelConfirmComponent} from './components/licensee-settings/change-code/change-confirm/change-confirm.component';
import {RegistriesComponent} from './components/registries/registries.component';
import {SmsConfirmationComponent} from './components/registries/sms-confirmation/sms-confirmation.component';
import {ActivationRequestListComponent} from './components/prepaids/activation-request/activation-request-list.component';
import {ActivationRequestCreateComponent} from './components/prepaids/activation-request-create/activation-request-create.component';
import {XplatregistriesComponent} from './components/xplatregistries/xplatregistries.component';
import {ActivationRequestItemComponent} from './components/prepaids/activation-request/activation-request-item/activation-request-item.component';
import {ImportHistoryItemComponent} from './components/prepaids/import-history/import-history-item/import-history-item.component';
//import {ImportHistoryListComponent} from './components/prepaids/import-history/import-history-list.component';
import { PaymentModalComponent } from './components/people-deal/payment-modal/payment-modal.component';
import { ProgressBar } from './components/people-deal/payment-modal/progress-bar/progress-bar.component';
import { ElectronicPsaComponent } from './components/people-deal/electronic-psa/electronic-psa.component';
import { SupportTurnoverComponent } from './components/support/support-turnover/support-turnover.component';
import { SupportTurnoverItemComponent } from './components/support/support-turnover/support-turnover-item/support-turnover-item.component';
import { SelfEmployedModule } from './self-employed/self-employed.module';
import { UserPermissions } from './user-permissions';
import { SharedModule } from './shared/shared.module';
import { DealsComponent } from './components/deals/deals.component';
import {LoginGuard} from './core/guards/login.guard';
import { HoldingStructureComponent } from './components/holding-structure/holding-structure.component';
import { NominalAccountConnectOnComponent } from './components/holding-structure/nominal-account-connect-on/nominal-account-connect-on.component';
import { HoldingLicenseesContainerComponent } from './components/holding-structure/holding-licensees-container/holding-licensees-container.component';
import { LicenseeWidgetComponent } from './components/holding-structure/licensee-widget/licensee-widget.component';
import { WaitingComponent } from './components/holding-structure/waiting/waiting.component';
import { WidgetsComponent } from './components/widgets/widgets.component';
import { BaseWidgetComponent } from './components/widgets/base-widget/base-widget.component';
import { HoldingWidgetComponent } from './components/widgets/holding-widget/holding-widget.component';
import { WidgetContainerComponent } from './components/widgets/widget-container/widget-container.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

// factories
import { WidgetTypeFactory } from './components/widgets/widget-type-factory';
import { MoneyTurnoverWidgetComponent } from './components/widgets/money-turnover-widget/money-turnover-widget.component';
import { ProductsIncomingWidgetComponent } from './components/widgets/products-incoming-widget/products-incoming-widget.component';
import { DealsWidgetComponent } from './components/widgets/deals-widget/deals-widget.component';
import { DealsInProcessingWidgetComponent } from './components/widgets/deals-in-processing-widget/deals-in-processing-widget.component';
import { RnkoRegisterWidgetComponent } from './components/widgets/rnko-register-widget/rnko-register-widget.component';
import { LimitsWidgetComponent } from './components/widgets/limits-widget/limits-widget.component';
import { TransactionsWidgetComponent } from './components/widgets/transactions-widget/transactions-widget.component';
import {PointsModule} from './points/points.module';
import {SupportLicenseesComponent} from './licensee/support-licensees/support-licensees.component';
import {LicenseeModule} from './licensee/licensee.module';
import {LicenseesSettingResolver} from './core/route-resolvers/licensees-setting-resolver';
import {HideLicenseeTransactionGuard} from './core/guards/hide-licensee-transaction.guard';
import {TokenInterceptor} from '@services/auth-service/token.interceptor';
import {CacheInterceptor} from './core/interceptors/cache-interceptor';
import {CoreModule} from './core/core.module';
import {WidgetsModule} from './widgets/widgets.module';
import { CashpoolingAdComponent } from './components/widgets/cashpooling-ad/cashpooling-ad.component';
import { HoldingSummuryInfoComponent } from './components/holding-structure/holding-summary-info/holding-summary-info.component';
import { ChangePasswordComponent } from './components/licensee-settings/change-password/change-password.component';
import { ChangePasswordConfirmComponent } from './components/licensee-settings/change-password/change-password-confirm/change-password-confirm.component';
import { ApiSettingsComponent } from './components/licensee-settings/api-setting/api-settings.component';
import { OwlDateTimeIntl, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { TsuzCommonModule, AuthGuard, UserRoles } from 'tsuz-common';
import { environment } from 'environments/environment';
import { UsersService } from '@services/users.service';
import { LicenseeService } from '@services/licensee.service';
import { UsersComponent } from './components/users/users.component';
import { CreateUserComponent } from './components/users/create-user/create-user.component';
import { UserSettingService } from '@services/user-setting.service';
import { PointService } from '@services/point.service';
import { WithdrawTypeService } from '@services/withdraw-type.service';
import { ProductService } from '@services/product.service';
import '@extensions/string'; //to aware code of string.extensions
import { LoginComponent } from './components/login/login.component';

const dataPermissionsForRegistries = {
  permission: {
    permittedRoles: [UserRoles.FinancialDirector, UserRoles.Administrator]
  }
};

const dataPermissionsForFinDirector = {
  permission: {
    permittedRoles: [UserRoles.FinancialDirector]
  }
};

const dataPermissionsForSupport = {
  permission: {
    permittedRoles: [UserRoles.Support]
  }
};

const dataPermissionsForAdmin = {
  permission: {
    permittedRoles: [UserRoles.Manager, UserRoles.ManagerPlus]
  }
};

const dataPermissionsForAdminDirector = {
  permission: {
    permittedRoles: [UserRoles.Manager, UserRoles.Administrator, UserRoles.ManagerPlus]
  }
};

const dataPermissionsForAdminDirectorAccountant = {
  permission: {
    permittedRoles: [UserRoles.Manager,
      UserRoles.Administrator,
      UserRoles.ManagerPlus,
      UserRoles.Accountant,
      UserRoles.FinancialDirector]
  }
};

const dataPermissionsForDocuments = {
  permission: {
    permittedRoles: [UserRoles.Manager,
      UserRoles.Administrator,
      UserRoles.ManagerPlus,
      UserRoles.Accountant,
      UserRoles.Support,
      UserRoles.FinancialDirector]
  }
};

const dataPermissionsForMainAdminDirector = {
  permission: {
    permittedRoles: [UserRoles.Manager, UserRoles.Administrator]
  }
};

const dataPermissionsForDirectorMpp = {
  permission: {
    permittedRoles: [UserRoles.ManagerPlus, UserRoles.Administrator]
  }
};


const dataPermissionsWithoutAccountant = {
  permission: {
    permittedRoles: [UserRoles.Administrator, UserRoles.Manager, UserRoles.Cashier, UserRoles.ManagerPlus]
  }
};


const dataPermissionsSupport = {
  permission: {
    permittedRoles: ['support']
  }
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DocumentListComponent,
    DocumentItemComponent,
    PeopleDealComponent,
    SupportDealComponent,
    SupportDealItemComponent,
    CreateUserComponent,
    TariffsComponent,
    CreatePeopleDeal,
    DetailsPeopleDeal,
    DealModalInfoComponent,
    PayModalInfoComponent,
    PayModalInfoItemComponent,
    ManageProductsComponent,
    ShowProductsComponent,
    PayCheckModalComponent,
    ShowEditModal,
    CatalogComponent,
    LicenseeSettingsComponent,
    LimitSettingsComponent,
    BaseSettingsComponent,
    PointSettingsComponent,
    ChangeChannelComponent,
    ChangeChannelConfirmComponent,
    PayRejectModalComponent,
    LicenseeBalancesComponent,
    RegistriesComponent,
    SmsConfirmationComponent,
    XplatregistriesComponent,
    ActivationRequestListComponent,
    ActivationRequestItemComponent,
    ActivationRequestCreateComponent,
    ImportHistoryItemComponent,
    //ImportHistoryListComponent,
    PaymentModalComponent,
    ProgressBar,
    ElectronicPsaComponent,
    SupportTurnoverComponent,
    SupportTurnoverItemComponent,
    // FocusPlaceholderDirective,
    // LogoComponent,
    // ValidationErrorsOutputComponent,
    WidgetsComponent,
    BaseWidgetComponent,
    HoldingWidgetComponent,
    WidgetContainerComponent,
    MoneyTurnoverWidgetComponent,
    ProductsIncomingWidgetComponent,
    DealsWidgetComponent,
    DealsInProcessingWidgetComponent,
    RnkoRegisterWidgetComponent,
    LimitsWidgetComponent,
    TransactionsWidgetComponent,
    DealsComponent,
    CashpoolingAdComponent,
    WaitingComponent,
    NominalAccountConnectOnComponent,
    LicenseeWidgetComponent,
    HoldingStructureComponent,
    HoldingLicenseesContainerComponent,
    HoldingSummuryInfoComponent,
    ChangePasswordComponent,
    ChangePasswordConfirmComponent,
    ApiSettingsComponent,
    UsersComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule, // required animations module
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleModalModule.forRoot({ container: document.body }),
    // NgDatepickerModule,
    ShowHidePasswordModule,
    // ShowHidePasswordModule.forRoot(),
    // MaterialModule,
    NgxChartsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'login',  pathMatch: 'full' },
      { path: 'login', canActivate: [LoginGuard],   component: LoginComponent },
      { path: 'deals', data: UserPermissions.dataPermissionsForAll, canActivate: [AuthGuard],  component: DealsComponent },
      { path: 'users/create', data: dataPermissionsForDirectorMpp, canActivate: [AuthGuard], component: CreateUserComponent, pathMatch: 'full' },
      { path: 'electronic-psa', data: UserPermissions.dataPermissionsNotSupportRoles, canActivate: [AuthGuard],  runGuardsAndResolvers: 'always', component: ElectronicPsaComponent, pathMatch: 'full' },
      // { path: 'catalog', data: dataPermissionsWithoutAccountant, canActivate: [AuthGuard], component: DealFilterComponent, pathMatch: 'full' },
      {
        path: 'settings',
        data: UserPermissions.dataPermissionsForAll,
        canActivate: [AuthGuard],
        component: LicenseeSettingsComponent,
        pathMatch: 'full'
      },
      {
        path: 'balances',
        data: dataPermissionsForFinDirector,
        canActivate: [AuthGuard],
        component: LicenseeBalancesComponent,
        pathMatch: 'full'
      },
      {path: 'users', data: dataPermissionsForAdminDirector, canActivate: [AuthGuard], component: UsersComponent, pathMatch: 'full'},
      // { path: 'tariffs', data: dataPermissionsForAdminDirector, canActivate: [AuthGuard], component: TariffsComponent, pathMatch: 'full' },
      { path: 'points', data: dataPermissionsForAdminDirectorAccountant, canActivate: [AuthGuard], component: PointsComponent },
      { path: 'people-deal-create', data: dataPermissionsWithoutAccountant, canActivate: [AuthGuard], component: CreatePeopleDeal, pathMatch: 'full' },
      { path: 'people-deal-edit', data: UserPermissions.dataPermissionsNotSupportRoles, canActivate: [AuthGuard], component: ShowEditModal, pathMatch: 'full' },
      { path: 'documents', data: dataPermissionsForDocuments, canActivate: [AuthGuard, HideLicenseeTransactionGuard], component: DocumentListComponent, pathMatch: 'full' },
      { path: 'registries', data: dataPermissionsForFinDirector, canActivate: [AuthGuard], component: RegistriesComponent, pathMatch: 'full' },
      { path: 'xplat-registries', data: dataPermissionsForRegistries, canActivate: [AuthGuard], component: XplatregistriesComponent, pathMatch: 'full' },
      // { path: 'app-activation-request-list', pathMatch: 'full', data: dataPermissionsForAll, canActivate: [AuthGuard],  component: ActivationRequestListComponent,  },
      // { path: 'activation-request-create', pathMatch: 'full', data: dataPermissionsForAll, canActivate: [AuthGuard],  component: ActivationRequestCreateComponent,  },
      // { path: 'prepaid-import-history', data: dataPermissionsForDirector, canActivate: [AuthGuard], component: ImportHistoryListComponent, pathMatch: 'full' },
      // { path: 'fetch-data', component: FetchDataComponent },
      { path: 'widgets', data: dataPermissionsForFinDirector, canActivate: [AuthGuard], component: WidgetsComponent, pathMatch: 'full' },
      { path: 'support/turnover', data: dataPermissionsForSupport, canActivate: [AuthGuard], component: SupportTurnoverComponent, pathMatch: 'full' },
      { path: 'licensees', data: dataPermissionsForSupport, canActivate: [AuthGuard], component: SupportLicenseesComponent, pathMatch: 'full' },
      { path: 'holding-structure', data: dataPermissionsForFinDirector, canActivate: [AuthGuard], component: HoldingStructureComponent, pathMatch: 'full' },
      { path: '**', redirectTo: 'deals' },
    ], { onSameUrlNavigation: 'reload' }),
    SelfEmployedModule,
    SharedModule,
    PointsModule,
    LicenseeModule,
    CoreModule,
    WidgetsModule,
    ClipboardModule,
    TsuzCommonModule.forRoot(environment, LicenseeService, UsersService, UserSettingService, PointService, WithdrawTypeService, ProductService)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    AuthGuard,
    HideLicenseeTransactionGuard,
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru'},
    {
      provide: OwlDateTimeIntl,
      // useClass: DefaultIntl
    },
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    LicenseesSettingResolver,
    WidgetTypeFactory,
    DatePipe,
  ],
  // модальные окна и не только
  entryComponents: [
    CreateUserComponent,
    PayModalInfoItemComponent,
    CreatePeopleDeal,
    DetailsPeopleDeal,
    DealModalInfoComponent,
    PayModalInfoComponent,
    ShowProductsComponent,
    ShowEditModal,
    CatalogComponent,
    LicenseeSettingsComponent,
    LimitSettingsComponent,
    BaseSettingsComponent,
    PointSettingsComponent,
    ChangeChannelComponent,
    ChangePasswordComponent,
    ChangePasswordConfirmComponent,
    ApiSettingsComponent,
    ShowProductsComponent,
    PayCheckModalComponent,
    PayRejectModalComponent,
    ChangeChannelConfirmComponent,
    XplatregistriesComponent,
    ActivationRequestListComponent,
    ActivationRequestItemComponent,
    XplatregistriesComponent,
    SupportTurnoverComponent,
    SupportTurnoverItemComponent,
    PaymentModalComponent,
    ProgressBar,
    // widgets
    HoldingWidgetComponent,
    MoneyTurnoverWidgetComponent,
    ProductsIncomingWidgetComponent,
    DealsWidgetComponent,
    DealsInProcessingWidgetComponent,
    RnkoRegisterWidgetComponent,
    LimitsWidgetComponent,
    TransactionsWidgetComponent,
    CashpoolingAdComponent,
  ],
  bootstrap: [AppComponent],
  exports: [AppComponent]
})
export class AppModule {

  public showLoader() {
  }
}