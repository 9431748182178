import {Component, Inject, OnInit} from '@angular/core';
import {LicenseeService} from '../services/licensee.service';
import {environment} from '../../../environments/environment';
import {DealService} from '../services/deal.service';
import {UserRoles, AuthService, MenuService, ILicenseeSetting} from 'tsuz-common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})

export class NavMenuComponent implements OnInit {
  userRoles = UserRoles;
  isExpanded = false;
  showMenu = false;
  collapseMenu = false;
  settingList: ILicenseeSetting;
  isFixed = false;
  showTransaction = false;
  showRegistry = false;
  title: string;
  isSmz: boolean = false;

  constructor(private readonly authenticationService: AuthService,
              private readonly menuService: MenuService,
              private dealService: DealService,
              private licenseeService: LicenseeService,
              @Inject("LOCALSTORAGE") private localStorage: any,
  ) {
  }

  // Получение настроек лицензиата и подпись на состояния бокового меню
  ngOnInit() {
    this.title = environment.title;
    this.showTransaction = environment.showLicenseeTransaction;
    this.authenticationService.authNavStatusSource.subscribe(status => {
      if (!status) {
        this.showMenu = false;
        return;
      }
      const licenseeId = this.localStorage.getItem('licenseeId');
      this.licenseeService.getSettings(licenseeId, true).subscribe(result => {
        this.settingList = result as ILicenseeSetting;
        this.showRegistry = this.getShowRegistry();
        this.initSubscribeOnCollapseMenu();
        this.showMenu = true;
      }, error => {
        console.error(error);
      });

      this.dealService.GetSelfEmployedUsingState().subscribe(result => {
        this.isSmz = result;
      });
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  openInfo() {
    this.authenticationService.setInfoStatus(true);
  }

  getShowRegistry(): boolean {
    return this.isFinancialDirectorOfHeadLicensee();
  }

  getShowHoldingStructure(): boolean {
    return this.isFinancialDirectorOfHeadLicensee();
  }

  isFinancialDirectorOfHeadLicensee(): boolean{
     if (this.authenticationService.inRole(UserRoles.FinancialDirector)) {
      return this.settingList.isHeadOrganization === true;
    }

    return false;
  }

  initSubscribeOnCollapseMenu() {
    this.menuService.status.subscribe((val: boolean) => {
      this.collapseMenu = val;
    });
  }

}
