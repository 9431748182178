import { AbstractControl, ValidatorFn } from '@angular/forms';

//Валидатор длинны номера телефона
export function PhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      let phoneNumber = control.value;
      phoneNumber = phoneNumber.formatPhone();
      if (phoneNumber.length < 11 || phoneNumber.length > 13) {
        return { 'InvalidPhone': true };
      }
    }
    return null;
  };
}