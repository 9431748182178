<div class="dropdown__header">
  <h5 class="h5">Сброс пароля</h5>
</div>
<div class="dropdown__section">
  <div class="msg msg--reset-pass"> Сотруднику
    <span class="bold staff-name" id="id1538407964">{{user.surname}} {{user.name}} {{user.middleName}}</span>
    <br> будет сброшен пароль
    <br> (новый пароль будет отправлен сотруднику по смс)
  </div>
</div>
<div class="dropdown__section">
  <a (click)="resetPass()" class="btn btn--active" id="id1538407984">Сбросить пароль</a>
</div>
