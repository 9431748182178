import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {ConfirmDealModel} from '../../models/peopledeal/ConfirmDealModel';
import { BaseHttpService } from 'tsuz-common';


@Injectable()
export class PayService {
  constructor(
  private baseHttpService: BaseHttpService
  ) { }

  //Проверка необходимости смс кода
  checkForSms(id: string): Observable<any> {
    return this.baseHttpService.get('/deals/checkforsms/' + id);
  }

  // Принять оплату.
  accept(id: string, concurrencyToken: string): Observable<any> {
    return this.baseHttpService.post('/deals/' + id + '/payment/accept', {
      concurrencyToken: concurrencyToken
    });
  }

  // Отклонить оплату.
  reject(id: string, reason: string, concurrencyToken: string): Observable<any> {
    return this.baseHttpService.patch('/deals/' + id + '/state/reject', {
      reason: reason,
      concurrencyToken: concurrencyToken
    } );
  }

  checkBeforeSms(id: string, isEdit: boolean = false): Observable<any> {
    return this.baseHttpService.post('/deals/' + id + '/payment/validate', { isEdit: isEdit });
  }

  generateCode(id: string, concurrencyToken: string): Observable<any> {
    return this.baseHttpService.post('/deals/' + id + '/payment/otp', {
      concurrencyToken: concurrencyToken
    });
  }
  //Валидация смс кода
  sendCodeForCheck(model: ConfirmDealModel): Observable<any> {
    return this.baseHttpService.patch('/deals/' + model.dealId + '/payment/confirm', {
      code: model.code,
      concurrencyToken: model.concurrencyToken,
    });
  }
  //Отправка на согласование
  sendForAprove(id: string, concurrencyToken: string): Observable<any> {
    return this.baseHttpService.patch('/deals/' + id + '/payment/need-accept', {
      concurrencyToken: concurrencyToken
    });
  }
  //Получение деталей оплаты для отправки в шлюз
  getDealInfo(id: string): Observable<any> {
    return this.baseHttpService.get('/deals/' + id + '/payment');
  }

  //Получение проводок.
  getAccountingDocuments(id: string): Observable<any> {
    return this.baseHttpService.get('/deals/' + id + '/payment/accounting-documents');
  }
  //Получение деталей оплаты для отправки в шлюз
  getSupportPayInfo(id: string): Observable<any> {
    return this.baseHttpService.get('/support/paydetail/' + id);
  }
  //Получение текущего состояния платежа
  getCurrentState(id: string): Observable<any> {
    return this.baseHttpService.get('/deals/' + id + '/payment/state', {});
  }

  pay(id: string): Observable<any> {
    return this.baseHttpService.post('/deal/pay/' + id, {});
  }

  //Инфо для печати чека по оплате
  getReceipt(id: string): Observable<any> {
    return this.baseHttpService.get("/deals/" + id + "/payment/receipt");
  }

}
