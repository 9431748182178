import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, IANAZone } from 'luxon';
import { TimeZoneOffsetPipe } from './timezone-offset.pipe';

@Pipe({
  name: 'timeZone'
})
/**
 * Converts UTC Date to Date in the specified Time Zone
 * If timeZone parameter is null, converts to the local Time Zone
 * Internally used built-in DatePipe, so the parameters are the same as for DatePipe except the first one: `timeZoneId`
 * Example: <div>{{ deal.createDate | timeZone : deal.licenseeTimeZoneId : 'dd-MM-yyyy HH:mm' }}</div
 */
export class TimeZonePipe implements PipeTransform {

  constructor(
    ) {
  }

  transform(value: Date | string, timeZoneId?: string, format?: string, locale?: string): string | null {
    if (!value) {
      return '';
    }

    let dateStr: string = typeof(value) === typeof(Date) ? (value as Date).toUTCString() : value as string;
    if (!dateStr.endsWith('Z')) {
      dateStr += 'Z';
    }

    timeZoneId = timeZoneId ?? DateTime.local().zoneName;
    const dateTime = DateTime.fromISO(dateStr);
    const ianaZone = new IANAZone(timeZoneId);
    const offset = ianaZone.offset(dateTime);

    const timeZoneOffsetPipe = new TimeZoneOffsetPipe();
    const result = timeZoneOffsetPipe.transform(dateStr, offset, format, locale);
    return result;
  }
}
