import {SortType} from './sort-type.enum';

export class SortModel {
  fieldName: string;
  type: SortType;

  public toggleSortType() {
    switch (this.type) {
      case SortType.None:
        this.type = SortType.Desc;
        break;
      case SortType.Desc:
        this.type = SortType.Asc;
        break;
      case SortType.Asc:
        this.type = SortType.None;
        break;
     default:
        this.type = SortType.Desc;
        break;
    }
  }
}
