<div class="support-licensees">
  <div class="upblock">
    <h1 class="h1">Список лицензиатов</h1>
    <div>
      <a id="id1538477805"
         class="legend__reset-filtres"
         (click)="resetFilters()">
        Сбросить фильтры</a>
    </div>
  </div>
  <div class="table">
    <div class="table__header-row">
      <div  class="table__header-cell filter__column status-column">
        <app-select-filter
          [items]="statusItems"
          [(model)]="filter.isActive"
          [queryName]="'isActive'"
          (apply)="applyFilter()"
          placeholder="Статус"
          [paginationFilter]="filter">
          Статус
      </app-select-filter>
      </div>
      <div  class="table__header-cell filter__column relative table__header-cell--w80">
        <app-string-filter
          [(model)]="filter.licenseeId"
          [queryName]="'licenseeId'"
          (apply)="applyFilter()"
          placeholder = "Id лицензиата..."
          [paginationFilter]="filter">
          Id лицензиата
        </app-string-filter>
      </div>
      <!-- <div id="id1538478373"
        class="table__header-cell filter__column relative table__header-cell--w80">
        <app-list-filter
          [items]="licenseeCategories"
          [(model)]="filter.categories"
          (apply)="applyFilter()"
          [queryName]="'categories'"
          [paginationFilter]="filter">
          Тип лицензиата
        </app-list-filter>
      </div> -->
      <div class="table__header-cell filter__column relative table__header-cell--w110">
        <app-string-filter
          [(model)]="filter.organizationName"
          [queryName]="'organizationName'"
          (apply)="applyFilter()"
          placeholder = "Название лицензиата..."
          [paginationFilter]="filter">
          Название лицензиата
        </app-string-filter>
      </div>
      <div  class="table__header-cell filter__column relative table__header-cell--w90">
        <app-string-filter
          [(model)]="filter.organizationInn"
          [queryName]="'organizationInn'"
          (apply)="applyFilter()"
          placeholder = "ИНН..."
          [paginationFilter]="filter">
          ИНН организации
        </app-string-filter>
      </div>

      <div class="table__header-cell filter__column relative table__header-cell--w80 sortable">
          Лимит на транзакцию
      </div>

      <div class="table__header-cell table__header-cell--w70 sortable">
        Комиссия
      </div>

      <div class="table__header-cell table__header-cell--w70 sortable">
        Часовой пояс
      </div>
    </div>
    
    <ng-container *ngIf="licensees">
      <ng-container id="id1542711246" *ngFor="let licensee of licensees; let i = index">
        <div class="table__row" [class.opened]="licensee.isShowDropDown" (click)="dropDownLic(licensee.id)">
          <div class="table__cell">
            <div class="table__data">
              <div class="label--mobile">Статус:</div>
              <div>
                <a class="status-btn" 
                  [ngClass]="{'status-btn--green': licensee.active == true,'status-btn--red': licensee.active == false}"></a>
              </div>
            </div>
          </div>
          <div class="table__cell ">
            <div class="table__data  table__data--break">
              <div class="label--mobile">Id:</div>
              <div>{{licensee.id}}</div>
            </div>
          </div>
          <!-- <div class="table__cell table__data--break">
            <div class="table__data">
              <div class="label--mobile">Тип лизензиата:</div>
              {{licensee.category}}
            </div>
          </div> -->
          <div class="table__cell">
            <div class="table__data">
              <div class="label--mobile">Название лицензиата:</div>
              {{licensee.organization.name}} ({{licensee.title || licensee.contractNumber}})
            </div>
          </div>
          
          <div class="table__cell">
            <div class="table__data table__data--break">
              <div class="label--mobile">ИНН организации:</div>
              {{licensee.organization.inn}}
            </div>
          </div>
          
          <div class="table__cell">
            <div class="table__data">
              <div class="label--mobile">Лимит на транзакцию:</div>
              {{licensee.limit | money: licensee.organization.currency}}
            </div>
          </div>
          
          <div class="table__cell">
            <div class="table__data">
              <div class="label--mobile">Комиссия:</div>
              {{licensee.licenseeCommission.commission || "Не указана"}}
            </div>
          </div>

          <div class="table__cell">
            <div class="table__data">
              <div class="label--mobile">Часовой пояс</div>
              {{licensee.timeZoneId | timeZoneName : 'ZZ'}}
            </div>
          </div>
        </div>

        <div class="table__hidden-row">
          <td colspan="6" class="table__hidden-data">
            <div class="dropdown">
              <div class="dropdown__left-menu">
                <!--При клике на меню, у него должен появляться класс active и должен подключаться соответствующий блок-->
                <a id="id158624200{{i}}"
                   class="dropdown__left-menu--link"
                   [ngClass]="{'active': isCommissionActive}"
                   (click)="setCommissionActive()">
                  Комиссия
                </a>
                <a id="id1709375300{{i}}"
                   class="dropdown__left-menu--link"
                   [ngClass]="{'active': isPointsActive}"
                   (click)="setPointsActive()">
                  Точки
                </a>
                <a id="id1709375400{{i}}"
                   class="dropdown__left-menu--link"
                   [ngClass]="{'active': isUsersActive}"
                   (click)="setUsersActive()">
                  Сотрудники
                </a>
                <a id="id1709375500{{i}}"
                   class="dropdown__left-menu--link"
                   [ngClass]="{'active': isBalanceActive}"
                   (click)="setBalanceActive(licensee.id)">
                  Баланс
                </a>
              </div>

              <div class="dropdown__main" *ngIf="isCommissionActive && licensee.isShowDropDown == true">
                <div class="dropdown__header">
                  <h5 class="h5">Комиссия</h5>
                </div>
                <div class="dropdown__section">
                  <div class="flex-wrapper">
                    <label class="label-for">Комиссия, %: </label>
                    <label>{{licensee.licenseeCommission.commission|| "Не указан"}}</label>
                  </div>
                  <div class="flex-wrapper">
                    <label class="label-for">Минимальная сумма комиссии: </label>
                    <label>
                      {{
                        licensee.licenseeCommission.minAmountCommission
                        ? (licensee.licenseeCommission.minAmountCommission | money: licensee.organization.currency)
                        : "Не указан"
                      }}
                    </label>
                  </div>
                  <div class="flex-wrapper">
                    <label class="label-for">Максимальная сумма комиссии: </label>
                    <label>
                      {{
                        licensee.licenseeCommission.maxAmountCommission 
                        ? (licensee.licenseeCommission.maxAmountCommission | money: licensee.organization.currency)
                        : "Не указан"
                      }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="dropdown__main" *ngIf="isPointsActive && licensee.isShowDropDown == true">
                <div class="dropdown__section">
                  <app-support-licensees-points [licenseeId]="licensee.id"> </app-support-licensees-points>
                </div>
              </div>

              <div class="dropdown__main" *ngIf="isUsersActive && licensee.isShowDropDown == true">
                <div class="dropdown__section">
                  <app-support-licensee-users [licenseeId]="licensee.id"> </app-support-licensee-users>
                </div>
              </div>

              <div class="dropdown__main" *ngIf="isBalanceActive && licensee.isShowDropDown == true">
                <div class="dropdown__header">
                  <h5 class="h5">Баланс</h5>
                </div>
                <div class="dropdown__section">
                  <div class="flex-wrapper">
                    <label class="label-for">Баланс: </label>
                    <label *ngIf="licenseeBalance.error === BalanceState.none">{{licenseeBalance.balance | money: licenseeBalance.currency}}</label>
                  </div>
                  <div class="flex-wrapper">
                    <label class="label-for">Процессинг: </label>
                    <label>{{licenseeBalance.processingName}}</label>
                  </div>
                  <div class="flex-wrapper">
                    <button
                      id="id1709652200"
                      class="btn btn--active back"
                      (click)="balanceClick(licensee.id)"
                      type="button"
                      [disabled]="licenseeBalance.error === BalanceState.balanceDisabled">
                      Запросить Баланс
                    </button>
                    <label class="error-validation error-right" *ngIf="licenseeBalance.error === BalanceState.balanceDisabled">По данному процессингу запрос баланса невозможен</label>
                  </div>
                </div>
              </div>

            </div>
          </td>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<!--PAGINATION-->
<app-pagination *ngIf="licensees"
                [(model)]="filter"
                (apply)="applyFilter()">
</app-pagination>
