import { IMyDateRangeModel } from 'angular-mydatepicker';

export class DateRange {
  queryNameFrom: string;
  queryNameTo: string;
  dateFrom?: Date;
  dateTo?: Date;

  get dateFromAsUtcString(): string {
    return this.convertToUtcString(this.dateFrom);
  }

  get dateToAsUtcString(): string {
    return this.convertToUtcString(this.dateTo);
  }

  constructor(queryNameFrom = 'dateFrom', queryNameTo = 'dateTo', myDateRange?: IMyDateRangeModel) {
    this.setDateRange(myDateRange);
    this.queryNameFrom = queryNameFrom;
    this.queryNameTo = queryNameTo;
  }

  private convertToUtcString(date?: Date): string {
    return date ? date.toISOString() : '';
  }

  setDateRange(myDateRange: IMyDateRangeModel) {
    if (myDateRange) {
      this.dateFrom = myDateRange.beginJsDate;
      this.dateTo = myDateRange.endJsDate;
    }
  }

  setDateRangeFromMyDateRangePicker(dtRange: any): DateRange {
    if (dtRange == null) {
      return null;
    }

    this.dateFrom = dtRange.beginDate
      ? new Date(dtRange.beginDate.year, dtRange.beginDate.month - 1, dtRange.beginDate.day)
      : null;
    this.dateTo = dtRange.endDate
      ? new Date(dtRange.endDate.year, dtRange.endDate.month - 1, dtRange.endDate.day)
      : null;

    return this;
  }
}
