import { Component } from '@angular/core';
import { DealData } from '../../../models/widgets/deal-data';
import { WidgetService } from '../../../services/widget.service';
import { WidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-deals-widget',
  templateUrl: './deals-widget.component.html',
  styleUrls: ['./deals-widget.component.scss', '../base-widget/base-widget.component.scss']
})
export class DealsWidgetComponent implements WidgetComponent {

  deals: DealData[] = [] as DealData[];
  public isLoadingCompleted: boolean = false;
  public hasError: boolean = false;
  public error: string;
  public readonly dateTimeFormat = "hh:mm:ss dd.MM";
  
  constructor(private widgetService: WidgetService) {

  }

  async ngOnInit() {
    const deals = await this.widgetService.getDealsData().catch(err => {
      this.error = err;
      this.hasError = true;
    });
    
    this.deals = deals as DealData[];

    this.isLoadingCompleted = true;
  }

}
