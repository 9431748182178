import { Pipe, PipeTransform } from '@angular/core';
import { RegistryStatuses } from '../../../core/enums/registry-statuses';
import {XplatRegistryStatuses} from '../../../core/enums/xplatregistry-statuses';

@Pipe({
  name: 'xplatRegistryStatusesClass'
})
export class XplatRegistryStatusesClassPipe implements PipeTransform {
  transform(value: XplatRegistryStatuses, args?: any): string {
    switch (value) {
      case XplatRegistryStatuses.Generated: return "generated";
      case XplatRegistryStatuses.Sending: return "sending";
      case XplatRegistryStatuses.SuccessfullySent: return "successfully-sent";
      case XplatRegistryStatuses.FailureSent: return "failure-sent";
    }
  }
}
