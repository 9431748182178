import { AbstractControl, ValidatorFn } from '@angular/forms';

//Валидатор лимитов точки
export function ValidateLimit(currentLimit: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value !== undefined && (isNaN(control.value) || control.value < currentLimit)) {
            return { 'limitCheck': true };
        }
        return null;
    };
}