export class ListFilterItemModel {
  title: string;
  value: string;
  checked: boolean;
  
  constructor(title: string, value: number | string, checked?: boolean) {
    this.title = title || '';
    this.value = value !== undefined && value !== null ? value.toString() : '';
    this.checked = checked || false;
  }
}
