import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {DocumentsFilter} from '../../models/documents/documents-filter';
import { BaseHttpService } from "tsuz-common";

@Injectable()
export class DocumentService {
  constructor(private baseHttpService: BaseHttpService) {}

  // Получение списка документов
  getDocumnets(
    filter: DocumentsFilter
  ): Observable<any> {
      return this.baseHttpService.get('/documents?' + filter.toString());
  }
}
