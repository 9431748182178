import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoComponent} from './logo/logo.component';
import {InfoComponent} from './info/info.component';
import {AcceptedDealsComponent} from './accepted-deals/accepted-deals.component';
import {ProfileInfoComponent} from './profile-info/profile-info.component';
import {NavMenuComponent} from './nav-menu/nav-menu.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {DealFilterService} from './services/dealfilter.service';
import {LicenseeService} from './services/licensee.service';
import {RealtimeNotificationService} from './services/realtime-notification.service';
import {PointService} from './services/point.service';
import {HideLicenseeTransactionGuard} from './guards/hide-licensee-transaction.guard';
import {UsersService} from './services/users.service';
import {DealService} from './services/deal.service';
import {PayService} from './services/pay.service';
import {ProviderService} from './services/provider.service';
import {DocumentService} from './services/document.service';
import {ScanDocumentsService} from './services/scan-documents.service';
import {LicenseeSettingService} from './services/licensee-setting.service';
import {CacheService} from './services/Cache/cache-service';
import {LicenseesSettingResolver} from './route-resolvers/licensees-setting-resolver';
import {FiasService} from './services/fias.service';
import {ActivationRequestService} from './services/activation-request.service';
import {PrepaidRegistryCardService} from './services/prepaid-registry-card.service';
import {DealFormService} from './services/deal-form.service';
import {PersonService} from './services/person.service';
import {AppInformationService} from './services/app-information.service';
import {LoginGuard} from './guards/login.guard';
import { HoldingStructureService } from './services/holding-structure.service';
import {UserService} from './services/user.service';
import { TsuzCommonModule } from 'tsuz-common';
import { environment } from 'environments/environment';
import { UserSettingService } from '@services/user-setting.service';
import { WithdrawTypeService } from '@services/withdraw-type.service';
import { ProductService } from '@services/product.service';

@NgModule({
  declarations: [
    LogoComponent,
    InfoComponent,
    AcceptedDealsComponent,
    ProfileInfoComponent,
    NavMenuComponent
  ],
  providers: [
    DealFilterService,
    LicenseeService,
    RealtimeNotificationService,
    PointService,
    HideLicenseeTransactionGuard,
    UsersService,
    DealService,
    PayService,
    ProviderService,
    DocumentService,
    ScanDocumentsService,
    LicenseeSettingService,
    CacheService,
    LicenseesSettingResolver,
    FiasService,
    ActivationRequestService,
    PrepaidRegistryCardService,
    DealFormService,
    PersonService,
    AppInformationService,
    LoginGuard,
    HoldingStructureService,
    UserService,
    WithdrawTypeService,
    ProductService
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TsuzCommonModule.forRoot(environment, LicenseeService, UsersService, UserSettingService, PointService, WithdrawTypeService, ProductService)
  ],
  exports: [
    LogoComponent,
    InfoComponent,
    AcceptedDealsComponent,
    ProfileInfoComponent,
    NavMenuComponent,
  ],
  entryComponents: [
    InfoComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
