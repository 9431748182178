import { RegistryStatuses } from '../../core/enums/registry-statuses';
import { DateRange, PaginationFilter } from 'tsuz-common';

export class RegistriesFilter extends PaginationFilter {

    name = '';
    status?: RegistryStatuses = null;
    create?: DateRange;
    period?: DateRange;

    get createFormattedFrom(): string { return this.create && this.create.dateFromAsUtcString || ''; }
    get createFormattedTo(): string { return this.create && this.create.dateToAsUtcString || ''; }
    get periodFormattedFrom(): string { return this.period && this.period.dateFromAsUtcString || ''; }
    get periodFormattedTo(): string { return this.period && this.period.dateToAsUtcString || ''; }


    public toString = (): string => {
        return `?CreateRange.DateFrom=${this.createFormattedFrom}` +
            `&CreateRange.DateTo=${this.createFormattedTo}` +
            `&name=${this.name}` +
            `&status=${this.status === null ? '' : this.status}` +
            `&take=${this.take}` +
            `&page=${this.page}` +
            `&PeriodRange.DateFrom=${this.periodFormattedFrom}` +
            `&PeriodRange.DateTo=${this.periodFormattedTo}`;
    }
}
