import { Component, Input, OnInit } from '@angular/core';
import { FilterComponentBase } from '../filter-component-base';
import { FilterService } from '../../../services/filter.service';
import { SelectFilterItemModel } from '../../../filters/select-filter-item.model';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent extends FilterComponentBase<string> {

  @Input() items : SelectFilterItemModel[];

  constructor(filterService: FilterService) {
     super(filterService);
  }

  protected getDefaultModelValue(): string {
    return null; // чтобы показывать плейсхолдер в ng-select
  }

}
