<app-base-widget
[header]="'Сделки в работе'"
[icoType]="'card-i'"
[isLoading]="!isLoadingCompleted"
[hasError]="hasError"
[loadingError]="error"
[primaryButtonCaption]="'Все сделки'"
[primaryButtonRoute]="'/deals'"
>
  <div class="deals-in-processing-widget-component widget-content-columns-container">
    <div class="widget-content-column-100">
      <a [href]="'/deals?page=1&take=5&state=Accepting'" class="widget-content-row-left widget-content-row-100 deals-in-processing-widget-row" (click)="redirect('')">
        <div class="widget-content-row-left widget-content-row-baseline-content"  style="width: 90%;">
          <span class="info-dot-container inline-label">
            <div class="info-dot in-approving-dot"></div>
          </span>
          <span class="label-amount inline-label">
            {{dealsInProcessing.inApproving}}
          </span>
          <span class="label-info-small inline-label">
            На согласовании
          </span>
        </div>
        <div class="widget-content-row-right widget-content-row-baseline-content" style="width: 10%;">
          <span class="inline-label">
          </span>
          <span class="label-info">
            ›
          </span>
        </div>
      </a>

      <a [href]="'/deals?page=1&take=5&state=Wait'" class="widget-content-row-left widget-content-row-100 deals-in-processing-widget-row" (click)="redirect('')">
        <div class="widget-content-row-left widget-content-row-baseline-content"  style="width: 90%;">
          <span class="info-dot-container inline-label">
            <div class="info-dot in-approving-dot"></div>
          </span>
          <span class="label-amount inline-label">
            {{dealsInProcessing.waitForPay}}
          </span>
          <span class="label-info-small inline-label">
            Ожидает оплаты
          </span>
        </div>
        <div class="widget-content-row-right widget-content-row-baseline-content" style="width: 10%;">
          <span class="inline-label">
          </span>
          <span class="label-info">
            ›
          </span>
        </div>
      </a>

      <a [href]="'/deals?page=1&take=5&state=NoPay'" class="widget-content-row-left widget-content-row-100 deals-in-processing-widget-row" (click)="redirect('')">
        <div class="widget-content-row-left widget-content-row-baseline-content"  style="width: 90%;">
          <span class="info-dot-container inline-label">
            <div class="info-dot not-paid-dot"></div>
          </span>
          <span class="label-amount inline-label">
            {{dealsInProcessing.notPaid}}
          </span>
          <span class="label-info-small inline-label">
            Не оплачено
          </span>
        </div>
        <div class="widget-content-row-right widget-content-row-baseline-content" style="width: 10%;">
          <span class="inline-label">
          </span>
          <span class="label-info">
            ›
          </span>
        </div>
      </a>

      <a [href]="'/deals?page=1&take=5&state=Rejected'" class="widget-content-row-left widget-content-row-100 deals-in-processing-widget-row" (click)="redirect('')">
        <div class="widget-content-row-left widget-content-row-baseline-content"  style="width: 90%;">
          <span class="info-dot-container inline-label">
            <div class="info-dot rejected-dot"></div>
          </span>
          <span class="label-amount inline-label">
            {{dealsInProcessing.rejected}}
          </span>
          <span class="label-info-small inline-label">
            Отказ
          </span>
        </div>
        <div class="widget-content-row-right widget-content-row-baseline-content" style="width: 10%;">
          <span class="inline-label">
          </span>
          <span class="label-info">
            ›
          </span>
        </div>
      </a>

      <a [href]="'/deals?page=1&take=5&state=KycError'" class="widget-content-row-left widget-content-row-100 deals-in-processing-widget-row" (click)="redirect('')">
        <div class="widget-content-row-left widget-content-row-baseline-content"  style="width: 90%;">
          <span class="info-dot-container inline-label">
            <div class="info-dot rejected-dot"></div>
          </span>
          <span class="label-amount inline-label">
            {{dealsInProcessing.rejectedForTerrorist}}
          </span>
          <span class="label-info-small inline-label">
            Отказ террористу
          </span>
        </div>
        <div class="widget-content-row-right widget-content-row-baseline-content" style="width: 10%;">
          <span class="inline-label">
          </span>
          <span class="label-info">
            ›
          </span>
        </div>
      </a>
    </div>
  </div>
</app-base-widget>