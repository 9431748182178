<div class="waiting-component
holding-structure--flex-container
holding-structure--flex-column
holding-structure--flex-center
holding-structure--flex-grow-1
">
  <div @contentTrigger *ngIf="!showSpinner && !errorHasHappened">
    <ng-content></ng-content>
  </div>
  <div 
  *ngIf=" showSpinner || errorHasHappened"
  class="holding-structure--flex-container
  holding-structure--flex-row
  holding-structure--flex-center
  holding-structure--flex-grow-1">
    <div
    [ngClass]="{
    'waiting-component--spinner': showSpinner,
    'holding-structure--flex-container': showSpinner}">
    </div>
    <div @contentTrigger *ngIf="errorHasHappened"
    class="waiting-component--error-area">
      <label>ОШИБКА</label>
      <span>{{errorMessage}}</span>
    </div>
  </div>

</div>
