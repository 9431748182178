import { BaseQueryFilter } from "./base-query-filter";

export class PaginationFilter extends BaseQueryFilter {
  private _page = 1;

  total: number;
  take: number = 5;

  get page(): number { return this._page; }
  set page(value: number) {
    const totalPage = Math.ceil(this.total / this.take);

    if (value < 1) {
      this._page = 1;
    } else if (value > totalPage) {
      this._page = totalPage;
    } else {
      this._page = value;
    }
  }

  protected getKeys(obj: Object): string[] {
    const result = super.getKeys(obj);
    result.push('page');
    return result;
  }

  get startIndex(): number {
    return (this.page - 1) * this.take;
  }

  protected reset(): void {
    super.reset()  
    this.page = 1;
  }
}
