interface String {
  formatPhone(...args: string[]): string;
  formatPhoneShort(...args: string[]): string;
  toTitleCase(...args: string[]): string;
  decodeBase64UTF8String(): string;
}

String.prototype.formatPhone = function (...args: string[]): string {
  let s = this;
  if (s.length === 10) {
    s = '7' + s;
  }
  return s.replace('-', '').replace(/\D/g, '').replace(/\s/g, '');
};

String.prototype.formatPhoneShort = function (...args: string[]): string {
  const s = this;
  if (s.length === 11) {
    let cleanstr = s.replace('-', '').replace(/\D/g, '').replace(/\s/g, '').substring(1);
    cleanstr = '+7' + cleanstr;
    return cleanstr;
  }
  return s;
};

String.prototype.toTitleCase = function (...args: string[]): string {
  let s = this;
  if (s && s.length > 0) {
    s = s.charAt(0).toUpperCase() + s.slice(1);
  }
  return s;
};

String.prototype.decodeBase64UTF8String = function(): string {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(this).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};
