import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from '@myndmanagement/text-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';

// Angular Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

// Tsuz
import { DefaultLogoComponent } from './components/default-logo/default-logo.component';
import { MenuService } from './services/menu.service';
import { SelectBoxControlComponent } from './controls/select-box-control/select-box-control.component';
import { PointsPageComponent } from './components/points/points-page/points-page.component';
import { PointsPageHeaderComponent } from './components/points/points-page-header/points-page-header.component';
import { PointsSpreadsheetActionsComponent } from './components/points/points-spreadsheet-actions/points-spreadsheet-actions.component';
import { PointItemComponent } from './components/points/point-item/point-item.component';
import { JoinPipe } from './pipes/join.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { PointLimitComponent } from './components/points/point-limit/point-limit.component';
import { PointSettingComponent } from './components/points/point-setting/point-setting.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { LoaderService } from './services/loader.service';
import { AuthGuard } from './guards/auth.guard';
import { PhoneFormatedPipe } from './pipes/phoneFormated.pipe';
import { RoleNamePipe } from './pipes/role-name.pipe';
import { NotificationService } from './services/notification.service';
import { UsersPageComponent } from './components/users/users-page/users-page.component';
import { LoginInputComponent } from './controls/login-input/login-input.component';
import { FioInputComponent } from './controls/fio-control/fio-input.component';
import { MiddlenameInputComponent } from './controls/middlename-control/middlename-input.component';
import { UsersPageHeaderComponent } from './components/users/users-page-header/users-page-header.component';
import { UserItemComponent } from './components/users/user-item/user-item.component';
import { ShowPzuComponent } from './components/users/show-pzu/show-pzu.component';
import { ManagePzuComponent } from './components/users/show-pzu/manage-pzu/manage-pzu.component';
import { EditRoleComponent } from './components/users/edit-role/edit-role.component';
import { ResetPassComponent } from './components/users/reset-pass/reset-pass.component';
import { ResetPostComponent } from './components/users/reset-pass/reset-post/reset-post.component';
import { EditActivityComponent } from './components/users/edit-activity/edit-activity.component';
import { EditActivityPostComponent } from './components/users/edit-activity/edit-activity-post/edit-activity-post.component';
import { EditUserComponent } from './components/users/edit-user/edit-user.component';
import { ChangeUserCodeComponent } from './components/users/change-code/change-code.component';
import { ChangeCodeConfirmComponent } from './components/users/change-code/change-confirm/change-confirm.component';
import { EditPermissionsComponent } from './components/users/edit-permissions/edit-permissions.component';
import { CreateUserComponent } from './components/users/create-user/create-user.component';
import { PasswordInputComponent } from './controls/password-input/password-input.component';
import { HttpErrorService } from './services/http-error.service';
import { BaseHttpService } from './services/base-http.service';
import { SystemDictionaryService } from './services/system-dictionary-service';
import { AuthService } from './services/auth.service';
import { RegistrySettingsComponent } from './components/registry-setting/registry-settings.component';
import { EditableTextComponent } from './components/editable-text/editable-text.component';
import { ProductsTreeComponent } from './components/products-tree/products-tree.component';
import { UploadProductsComponent } from './components/upload-products/upload-products.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AddButtonComponent } from './components/add-button/add-button.component';
import { ApiKeyComponent } from './controls/api-key/api-key.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FilterService } from './services/filter.service';
import { SystemService } from './services/system.service';
import { SortingHeaderComponent } from './components/filters/sorting-header/sorting-header.component';
import { StringFilterComponent } from './components/filters/string-filter/string-filter.component';
import { ListCheckboxFilterComponent } from './components/filters/list-checkbox-filter/list-checkbox-filter.component';
import { NumberRangeFilterComponent } from './components/filters/number-range-filter/number-range-filter.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { TimeZoneSelectComponent } from './components/timezone-select/timezone-select.component';
import { PointTimeZoneComponent } from './components/point-timezone/point-timezone.component';
import { CodeInputComponent } from './controls/code-input/code-input.component';
import { AutoFocusDirective } from './directives/autofocus.directive';
import { SelectFilterComponent } from './components/filters/select-filter/select-filter.component';
import { JwtAuthService } from './services/jwt.auth.service';
import { TokenStorageService } from './services/token-storage.service';

// Register Locales
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu);

@NgModule({
  declarations: [
    DefaultLogoComponent,
    SelectBoxControlComponent,
    TimeZoneSelectComponent,
    PointTimeZoneComponent,
    PointsPageComponent,
    PointsPageHeaderComponent,
    PointsSpreadsheetActionsComponent,
    PointItemComponent,
    PointLimitComponent,
    PointSettingComponent,
    UsersPageComponent,
    UsersPageHeaderComponent,
    UserItemComponent,
    ShowPzuComponent,
    ManagePzuComponent,
    EditRoleComponent,
    ResetPassComponent,
    ResetPostComponent,
    EditActivityComponent,
    EditActivityPostComponent,
    EditUserComponent,
    LoginInputComponent,
    FioInputComponent,
    ApiKeyComponent,
    MiddlenameInputComponent,
    PasswordInputComponent,
    ChangeUserCodeComponent,
    ChangeCodeConfirmComponent,
    EditPermissionsComponent,
    CreateUserComponent,
    RegistrySettingsComponent,
    ConfirmDialogComponent,
    PaginationComponent,
    SortingHeaderComponent,
    StringFilterComponent,
    ListCheckboxFilterComponent,
    NumberRangeFilterComponent,
    SelectFilterComponent,
    HasPermissionDirective,
    ClickOutsideDirective,
    AutoFocusDirective,
    JoinPipe,
    MoneyPipe,
    PhoneFormatedPipe,
    RoleNamePipe,
    EditableTextComponent,
    ProductsTreeComponent,
    UploadProductsComponent,
    UploadFilesComponent,
    AddButtonComponent,
    CodeInputComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    FontAwesomeModule,
    ShowHidePasswordModule,
    TextMaskModule,
    NgxMaskModule,
    NgSelectModule,
    ClipboardModule,
    
    // Angular Material
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatExpansionModule,
    MatStepperModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTreeModule,
    MatChipsModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    DefaultLogoComponent,
    SelectBoxControlComponent,
    TimeZoneSelectComponent,
    PointTimeZoneComponent,
    PointsPageComponent,
    PointsPageHeaderComponent,
    PointsSpreadsheetActionsComponent,
    PointItemComponent,
    PointSettingComponent,
    UsersPageComponent,
    UsersPageHeaderComponent,
    UserItemComponent,
    ShowPzuComponent,
    ManagePzuComponent,
    EditRoleComponent,
    ResetPassComponent,
    ResetPostComponent,
    EditActivityComponent,
    EditActivityPostComponent,
    EditUserComponent,
    LoginInputComponent,
    FioInputComponent,
    ApiKeyComponent,
    MiddlenameInputComponent,
    PasswordInputComponent,
    ChangeUserCodeComponent,
    ChangeCodeConfirmComponent,
    EditPermissionsComponent,
    CreateUserComponent,
    RegistrySettingsComponent,
    ConfirmDialogComponent,
    PaginationComponent,
    SortingHeaderComponent,
    StringFilterComponent,
    ListCheckboxFilterComponent,
    NumberRangeFilterComponent,
    SelectFilterComponent,
    HasPermissionDirective,
    ClickOutsideDirective,
    AutoFocusDirective,
    JoinPipe,
    MoneyPipe,
    PhoneFormatedPipe,
    RoleNamePipe,
    EditableTextComponent,
    ProductsTreeComponent,
    UploadProductsComponent,
    UploadFilesComponent,
    AddButtonComponent,
    CodeInputComponent
  ],
  providers: [
    MenuService,
    LoaderService,
    AuthGuard,
    NotificationService,
    BaseHttpService,
    HttpErrorService,
    SystemDictionaryService,
    FilterService,
    AuthService,
    SystemService,
    JwtAuthService,
    TokenStorageService,
    {
      provide: 'LOCALSTORAGE',
      useFactory: () => (typeof window !== 'undefined') ? window.localStorage : null
    },
    {provide: LOCALE_ID, useValue: 'ru-RU'},
  ],
  entryComponents: [
    ManagePzuComponent,
    ResetPostComponent,
    EditActivityPostComponent,
    ChangeCodeConfirmComponent,
  ]
})
export class TsuzCommonModule { 

  public static forRoot(
    environment: any, 
    licenseeService: any, 
    usersService: any,
    userSettingsService: any,
    pointService: any,
    withdrawTypeService: any,
    productService: any,
    ): ModuleWithProviders<TsuzCommonModule> {

    return {
        ngModule: TsuzCommonModule,
        providers: [
            { provide: 'environment', useValue: environment },
            { provide: 'LicenseeService', useClass: licenseeService },
            { provide: 'UsersService', useClass: usersService },
            { provide: 'UserSettingsService', useClass: userSettingsService },
            { provide: 'PointService', useClass: pointService },
            { provide: 'WithdrawTypeService', useClass: withdrawTypeService },
            { provide: 'ProductService', useClass: productService },
        ]
    };
  }
}
