import { ILicenseeSetting } from "tsuz-common";

    export class DealDraftViewModel {
        /**Идентификатор оплаты */
        id: string;

        /**Номер карты */
        cardNumber: string;

        /**ID банка для платежа по СБП */
        sbpMemberId: any;

        /**ФИО клиента */
        surname: string;

        /**ФИО клиента */
        pornumber: number;

        /**Имя */
        benificiare: string;

        /**Отчество */
        middleName: string;

        /**Номер паспорта клиента */
        passportseries: string;

        /**Кем выдан паспорт клиента */
        passportfrom: string;

        /**Страна проживания клиента */
        country: string;

        /**Дата рождения клиента */
        birthday: string;

        /**Место рождения клиента */
        birthplace: string;

        /**Адрес точки */
        adress: string;

        /**Идентификатор точки */
        pointId: string;

        /**Номер телефона */
        phone: string;

        /**Сумма к оплате */
        sum: number;

        /**Валюта */
        currency: string;

        /**Дата довогора */
        dealDate: string;

        /**Номер договора */
        dealNumber: string;

        /**Идентификатор провайдера */
        providerId: string;

        /**Товара оплаты */
        products: PeopleDealProductBinding[];

        /**Список сканов оплаты */
        scanDocuments: ScanDocumentViewModel[];

        /**Показывать согласование */
        showApproval: boolean;

        /**Комментарий к оплате */
        comment: string;

        /**Внешний идентификатор */
        externalId: string;

        /**ИНН */
        inn: string;

        dealSettings: ILicenseeSetting;

        /**Дата создания */
        createDate: string;

        /**Название точки */
        pointTitle: string;

        concurrencyToken: number;
    }

    export class PeopleDealProductBinding {

        /**Id товара */
        productId: string;

        /**Вес */
        measureUnitValue: number;

        /**Процент засора */
        trash: number;

        /**Цена за кг */
        measureUnitPrice: number;

        /**Цена за кг */
        totalPrice: number;

        /**описание */
        pnumber: string;

        /**ключ единицы массы */
        measureUnitShortName: string;

        /**ключ единицы массы */
        measureUnitFullName: string;

        /**Идентификатор провайдера */
        provider: string;

        /**Сокрытие Платежного поручения  */
        hidePp: boolean;

        /**Сокрытие провайдера */
        hideProvider: boolean;

        /**Сокрытие провайдера */
        disabled: boolean;

        /**Сокрытие провайдера */
        oneScreen: boolean;

        /**Сокрытие провайдера */
        groupKey: string;
    }

    export class ScanDocumentViewModel {
        /**ID */
        id: string;
        
        /**Путь */
        scanUrl: string;

        /**Тип */
        type: number;

        /**Идентификатор клиента сделки */
        peopleMetalId: string;

        /**Original file name when it was uploaded */
        originalFileName: string;
    }
