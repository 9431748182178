import { Component, Input, OnInit } from '@angular/core';
import { ILicenseeWidgetInfoModel } from '../../../models/holding-structure/ILicenseeWidgetInfoModel';

@Component({
  selector: 'app-holding-licensees-container',
  templateUrl: './holding-licensees-container.component.html',
  styleUrls: ['./holding-licensees-container.component.scss', '../holding-structure.component.scss']
})
export class HoldingLicenseesContainerComponent implements OnInit {
  
  sortedLicenseeInfo: ILicenseeWidgetInfoModel[];
  displayedLicenseeInfo: ILicenseeWidgetInfoModel[];

  @Input()
  set licenseesInfo(value: ILicenseeWidgetInfoModel[]){
    this.sortedLicenseeInfo = this.sortLicensees(value, this.selectedSortOption);
    this.displayedLicenseeInfo = this.sortedLicenseeInfo;
  };

  @Input()
  isLoading: boolean = true;

  @Input()
  hasError: boolean = false;
  
  @Input()
  error: string;

  sortOptions: Array<any>;
  selectedSortOption: string = "asc";
  searchableValue: string;

  constructor() { }

  ngOnInit() {
    this.sortOptions = [{
      display: 'Максимальный расход',
      code: 'asc'
    },
    {
      display: 'Минимальный расход',
      code: 'desc'
    }];
  }

  onChangeSorting() {
    this.sortedLicenseeInfo = this.sortLicensees(this.sortedLicenseeInfo, this.selectedSortOption);
    this.displayedLicenseeInfo = this.findLicensees(this.sortedLicenseeInfo, this.searchableValue);
  }

  sortLicensees(licensees: ILicenseeWidgetInfoModel[], order: string) {
    if (!licensees) {
      return [];
    }
    
    return [...licensees].sort((left, right) => {
      const compare = order == 'asc'
        ? left.dailyExpense < right.dailyExpense
        : left.dailyExpense > right.dailyExpense;
      
      return left.dailyExpense == right.dailyExpense ? 0 : compare ? 1 : -1;
    });
  }

  onChangeSearching() {
    this.displayedLicenseeInfo = this.findLicensees(this.sortedLicenseeInfo, this.searchableValue);
  }

  findLicensees(licensees: ILicenseeWidgetInfoModel[], keyToSearch: string): ILicenseeWidgetInfoModel[] {
    if (!keyToSearch || keyToSearch.trim().length == 0) {
      return licensees;
    }

    let foundLicensees = [...licensees];
    return foundLicensees.filter(item => item.licenseeName?.toLocaleLowerCase().indexOf(keyToSearch.toLocaleLowerCase()) >= 0);
  }
}
