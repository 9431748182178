<div style="background: white">
  <div class="table__hidden-data">
    <div class="dropdown">
      <div class="dropdown__left-menu">
        <ng-container>
          <ng-container *ngIf="isDefaultLicenseeType">
            <a class="dropdown__left-menu--link" id="common-setting" #settingSelector [ngClass]="{'active': showIndex == 'common-setting'}" *hasPermission="[[userRoles.Administrator]]" (click)="showIndex = 'common-setting'">Настройки сделки</a>
          </ng-container>
          <a class="dropdown__left-menu--link" id="point-settings" #settingSelector [ngClass]="{'active': showIndex == 'point-settings'}" *hasPermission="[[userRoles.Administrator]]" (click)="showIndex = 'point-settings'">{{settingList.pointName}}</a>
          <a class="dropdown__left-menu--link" id="limits-settings" #settingSelector [ngClass]="{'active': showIndex == 'limits-settings'}" *hasPermission="[[userRoles.Administrator]]" (click)="showIndex = 'limits-settings'">Лимиты</a>
          <a class="dropdown__left-menu--link" id="registry-settings" #settingSelector [ngClass]="{'active': showIndex == 'registry-settings'}" *hasPermission="[[userRoles.Administrator]]" (click)="showIndex = 'registry-settings'">Получатели суточного реестра операций</a>
          <a class="dropdown__left-menu--link" id="notification-settings" #settingSelector [ngClass]="{'active': showIndex == 'notification-settings'}" *hasPermission="[[userRoles.Administrator]]" (click)="showIndex = 'notification-settings'">Канал оповещений</a>
          <a class="dropdown__left-menu--link" id="change-password-settings" #settingSelector [ngClass]="{'active': showIndex == 'change-password-settings'}" (click)="showIndex = 'change-password-settings'">Смена пароля</a>
          <a class="dropdown__left-menu--link" id="product-settings" #settingSelector [ngClass]="{'active': showIndex == 'product-settings'}" *hasPermission="[[userRoles.Administrator]]" (click)="showIndex = 'product-settings'">Товары</a>
          <a class="dropdown__left-menu--link" id="api-settings" #settingSelector [ngClass]="{'active': showIndex == 'api-settings'}" *hasPermission="[[userRoles.Administrator]]" (click)="showIndex = 'api-settings'">API</a>
        </ng-container>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'common-setting'">
        <div class="dropdown__header">
          <h5 class="h5">Настройки сделки</h5>
        </div>
        <div class="dropdown__section">
          <base-settings></base-settings>
        </div>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'point-settings'">
        <div class="dropdown__header">
          <h5 class="h5">Наименование пунктов обслуживания</h5>
        </div>
        <div class="dropdown__section">
          <point-settings></point-settings>
        </div>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'limits-settings'">
        <div class="dropdown__header">
          <h5 class="h5">Настройка оповещений о лимитах</h5>
        </div>
        <div class="dropdown__section">
          <limit-settings></limit-settings>
        </div>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'registry-settings'">
        <div class="dropdown__header">
          <h5 class="h5">Настройка ежедневной рассылки реестра операций</h5>
        </div>
        <div class="dropdown__section">
          <tsuz-registry-settings [licenseeId]="licenseeId"></tsuz-registry-settings>
        </div>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'notification-settings'">
        <div class="dropdown__header">
          <h5 class="h5">Настройка канала оповещений</h5>
        </div>
        <div class="dropdown__section">
          <change-channel></change-channel>
        </div>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'change-password-settings'">
        <div class="dropdown__header">
          <h5 class="h5">Смена пароля</h5>
        </div>
        <div class="dropdown__section">
          <change-password></change-password>
        </div>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'product-settings'">
        <div class="dropdown__header">
          <h5 class="h5">Товары</h5>
        </div>
        <div class="dropdown__section">
          <tsuz-products-tree [licenseeId]="licenseeId"></tsuz-products-tree>
        </div>
      </div>

      <div class="dropdown__main" *ngIf="showIndex == 'api-settings'">
        <div class="dropdown__header">
          <h5 class="h5">API</h5>
        </div>
        <div class="dropdown__section">
          <api-settings></api-settings>
        </div>
      </div>

    </div>
  </div>
</div>
