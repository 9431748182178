import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserFilter } from '../../../filters/user.filter';
import { getEmailMask, getFioMask, getLoginMask, getPhoneMask } from '../../../helpers/masks.helper';
import { DEFAULT_STATUS_SELECT_ITEMS } from '../../../filters/select-filter-item.model';
import { ListFilterItemModel } from '../../../filters/list-filter-item.model';
import { UserRoles } from '../../../models/auth/user-roles';

@Component({
  selector: 'tsuz-users-page-header',
  templateUrl: './users-page-header.component.html'
})
export class UsersPageHeaderComponent {
  @Input() filter: UserFilter;
  @Output() apply = new EventEmitter<void>();

  statusItems = DEFAULT_STATUS_SELECT_ITEMS;
  getLoginMask = getLoginMask;
  getPhoneMask = getPhoneMask;
  getEmailMask = getEmailMask;
  getFioMask = getFioMask;

  readonly rolesList =  [
    new ListFilterItemModel('Администратор', UserRoles.Administrator),
    new ListFilterItemModel('Менеджер с повышенными правами', UserRoles.ManagerPlus),
    new ListFilterItemModel('Менеджер', UserRoles.Manager),
    new ListFilterItemModel('Бухгалтер', UserRoles.Accountant),
    new ListFilterItemModel('Оператор', UserRoles.Cashier),
    new ListFilterItemModel('Финансовый директор', UserRoles.FinancialDirector),
  ];
  
  onApply() {
    // у textMask нет подобного dropSpecialCharacters и нужно очищать значение от символов маски вручную
    if (this.filter.phone) {
      this.filter.phone = this.filter.phone.replace(/\D/g, '');
    }

    this.apply.emit();
  }
  
}
