<div class="dropdown__header">
  <h5 class="h5">"Локации" сотрудника</h5>
</div>

<ng-container>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="dropdown__section">
      <ul [class.disabled]="readonly">
        <ng-container formArrayName="availableResources"
                      *ngFor="let availableResource of form.controls.availableResources['controls']; let i=index">
          <li class="dropdown__table-row">
            <div class="dropdown__table-col">

              <input class="checkbox-filter" id="id{{user.id}}ssd{{i}}" type="checkbox" [formControlName]="i">
              <label class="checkbox-filter-label"
                     for="id{{user.id}}ssd{{i}}">{{systemDictionary.availableResources[i].value}}</label>

            </div>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="dropdown__footer" *ngIf="!readonly">
      <button type="submit" class="btn btn--active">Сохранить</button>
    </div>
  </form>
</ng-container>
