import { Pipe, PipeTransform } from '@angular/core';
import { RegistryStatuses } from '../../../core/enums/registry-statuses';

@Pipe({
  name: 'registryStatusesString'
})
export class RegistryStatusesStringPipe implements PipeTransform {
  transform(value: RegistryStatuses, args?: any): string {

    switch (value) {
      case RegistryStatuses.Generated: return "Сформирован";
      case RegistryStatuses.Sending: return "Отправляется в РНКО";
      case RegistryStatuses.SuccessfullySent: return "Отправлен в РНКО";
      case RegistryStatuses.FailureSent: return "Ошибка отправки";
      case RegistryStatuses.RnkoApplied: return "Проведен в банке";
      case RegistryStatuses.RnkoRejected: return "Отклонен в банке";
      case RegistryStatuses.RnkoPartialApplied: return "Частично обработан";
    }
  }
}
