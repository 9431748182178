import { HttpParams } from '@angular/common/http';
import { DateRange } from 'tsuz-common';

export class PlatformBindingRequestFilter {
    state?: number;
    requestKey?: string;
    range?: DateRange;
    inn?: string;
    page = 1;
    take = 10;

    get count(): number {
        return this.page * this.take;
    }

    public toString = (): string => {
        let params = new HttpParams();

        if (this.state != null) {
            params = params.set('state', this.state.toString());
        }

        if (this.requestKey != null) {
            params = params.set('requestKey', this.requestKey.toString());
        }

        if (this.range != null) {

            if (this.range.dateFrom != null) {
                params = params.set('range.dateFrom', this.range.dateFromAsUtcString);
            }

            if (this.range.dateTo != null) {
                params = params.set('range.dateTo', this.range.dateToAsUtcString);
            }
        }


        if (this.inn != null) {
            params = params.set('inn', this.inn.toString());
        }

        if (this.page != null) {
            params = params.set('page', this.page.toString());
        }

        if (this.take != null) {
            params = params.set('take', this.take.toString());
        }

        return params.toString();
    }
}
