/**Тип ошибки получения баланса */
export enum BalanceState {
    /**Не определено */
    none = 0,

    /**Не настроен счет в подсистеме Accounting */
    accountIdIsNull = 1,
  
    /**Ошибка при получении счета */
    accountError = 2,
    
    /**Балансы лицензиата отключены в настройках */
    balanceDisabled = 3
}
