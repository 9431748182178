import {Component, Input, OnInit} from '@angular/core';
import {DocumentModel} from '../../../../models/documents/document.model';
import {OrganizationTurnoverItem} from '../../../../models/support/Turnover/OrganizationTurnoverItem';

@Component({
  selector: '[app-support-turnover-item]',
  templateUrl: './support-turnover-item.component.html',
  styleUrls: ['./support-turnover-item.component.scss']
})
export class SupportTurnoverItemComponent implements OnInit {
  @Input() organization: OrganizationTurnoverItem;
  @Input() index: number;


  constructor() {
  }

  ngOnInit() {
  }
}
