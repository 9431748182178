import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PointFilter } from '../../../filters/point.filter';
import { DEFAULT_STATUS_SELECT_ITEMS } from '../../../filters/select-filter-item.model';

@Component({
  selector: 'tsuz-points-page-header',
  templateUrl: './points-page-header.component.html'
})
export class PointsPageHeaderComponent  {

  @Input() filter: PointFilter;
  @Output() apply = new EventEmitter<void>();

  statusItems = DEFAULT_STATUS_SELECT_ITEMS;

  onApply() {
    this.apply.emit();
  }
}
