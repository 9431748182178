import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'platformBindingRequestStateToActionString'
})
export class PlatformBindingRequestStateToActionStringPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    switch (value) {
      case 1:
      case 2: return 'Проверить статус';
      case 3: return 'Отозвать заявку';
      case 4: return '';
      case 5: return '';
    }
    return 'Обратитесь в службу поддержки';
  }

}
