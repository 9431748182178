import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {
  
  private timeOut = 5000;
  private closeButton = true;
  private progressBar = true;
  private config = {
    timeOut: this.timeOut,
    closeButton: this.closeButton,
    progressBar: this.progressBar
  };

  constructor(private toastr: ToastrService) {
  }

  public successNotify(message: string, title: string, userConfig: any= null) {
    this.notify(message, title, userConfig, "toast-success");
  }

  public infoNotify(message: string, title: string, userConfig: any= null) {
    this.notify(message, title, userConfig, "toast-info");
  }

  public errorNotify(message: string, title: string = 'Ошибка', userConfig: any= null) {
    this.notify(message, title, userConfig, "toast-error");
  }

  public warningNotify(message: string, title: string, userConfig: any= null) {
    this.notify(message, title, userConfig, "toast-warning");
  }

  private notify(message: string, title: string, userConfig: any = null, notificationType) {
    const conf = userConfig ? userConfig : this.config;
    this.toastr.show(message, title, conf, notificationType);
  }
}
