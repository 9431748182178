import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'validation-errors-output',
  templateUrl: './validation-errors-output.component.html',
})

export class ValidationErrorsOutputComponent implements OnInit{
  ngOnInit(): void {
  }
  @Input() errorsDictionary: object;
  @Input() fieldName: string;
}