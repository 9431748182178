import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TimeZoneModel } from '../../models/TimeZoneModel';
import { SystemService } from '../../services/system.service';

interface CountryTimeZone {
  countryName: string;
  timeZones: TimeZoneModel[];
}

@Component({
  selector: 'tsuz-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss']
})
export class TimeZoneSelectComponent implements OnInit {
  public countryTimeZones: CountryTimeZone[] = [{countryName: "", timeZones: []}];

  @Input() placeholder: string = 'Часовой Пояс';
  @Input() control: FormControl = new FormControl();
  
  private _timeZones: TimeZoneModel[] = []
  get timeZones(): TimeZoneModel[] { return this._timeZones };
  /**
   * If timeZones are not set, the component will send request to the server to get them.
   * Consider providing timeZones to reduce request count if you have a cached value
   */
  @Input() set timeZones(value: TimeZoneModel[])
  {
    this._timeZones = value;
    this.updateCountryTimeZones();
  }

  private _countryCode: string;
  get countryCode(): string { return this._countryCode; }
  /**
   * If countryCode is set, the component will show only TimeZones for this single country
   */
  @Input() set countryCode(value: string) {
    if (this._countryCode === value) {
      return;
    }
    this._countryCode = value;
    this.updateCountryTimeZones();
  }

  constructor(
    private systemService: SystemService
  ) { 
  }

  ngOnInit(): void {
    if (!this.timeZones?.length) {
      this.systemService.getTimeZones$(new Date()).subscribe((data: TimeZoneModel[]) => {
        this.timeZones = data;
      },
      (error: any) => console.log(error));
    }
  }

  private updateCountryTimeZones(): void {
    if (!this.timeZones?.length) {
      return;
    }
    
    let countryNames: string[] = [];
    if (this.countryCode) {
      const countryCodeIso2 = this.systemService.countryCodeToIso2(this.countryCode);
      const timeZoneModel = this.timeZones.find(tzm => tzm.countryCode === countryCodeIso2);
      countryNames = timeZoneModel ? [timeZoneModel.countryName] : countryNames;
    } else {
      countryNames = [...new Set(this.timeZones.map(tz => tz.countryName))];
    }
    
    const result: CountryTimeZone[] = [];
    for (const countryName of countryNames) {
      const timeZonesFilteredByCountry = this.timeZones.filter(tz => tz.countryName === countryName);
      result.push({countryName: countryName, timeZones: timeZonesFilteredByCountry});
    }
    
    this.countryTimeZones = result;
  }
}
