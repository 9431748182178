import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'booleanToString' })
// Пайп для определения падежа точки в предложениях
export class BooleanToStringPipe implements PipeTransform {

  transform(value: any, trueValue: string = "Да", falseValue = "Нет") {
    return value ? trueValue : falseValue;
  }
}
