import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Сервис для определения вида бокового меню
@Injectable()
export class DealFilterService {
  public status: BehaviorSubject<string> = new BehaviorSubject<string>("All");


  getToAcceptedPage(value: string) {
    this.status.next(value);
  }

}
