import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { DealFormService } from '@services/deal-form.service';
import { IPeopleDeal } from '../../../models/peopledeal/IPagePeopleDeal';
import { UserRoles, MoneyPipe } from 'tsuz-common';
import { IDealDetailsViewModel } from '../../../models/peopledeal/IDealDetailsViewModel';
import { PayService } from "@services/pay.service";
import { IPeopleDealPrint } from '@models/peopledeal/IPeopleDealPrint';
import { ToastrService } from "ngx-toastr";
import { TimeZonePipe } from '@shared/directives/custom-pipes/timezone.pipe';

export interface ConfirmModel {
  title: string;
  message: string;
  details: IDealDetailsViewModel;
  pointName: string;
  // file: any;
}
@Component({
  selector: '[details-peopleDeal]',
  templateUrl: './details-peopleDeal.component.html',
  styleUrls: ['./details-peopleDeal.component.scss']
})
export class DetailsPeopleDeal extends SimpleModalComponent<ConfirmModel, IPeopleDeal> implements ConfirmModel, OnInit {
  userRoles = UserRoles;
  details: IDealDetailsViewModel;
  title: string;
  message: string;
  pointName: string;
  dealNumber = 'Номер ПСА';
  dealDate = 'Дата ПСА';
  errorMessage: string;
  isCarPrice = false;
  toPrint: IPeopleDealPrint;
  readonly longDateFormat = 'dd.MM.yyyy HH:mm:ss';
  readonly shortDateFormat = 'dd.MM.yyyy HH:mm';


  constructor(
    private dealFormService: DealFormService,
    private payService: PayService,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  // Копирование оплаты
  copyDeal() {
    this.close().then(r => this.dealFormService.copyDeal(this.details.id));
  }

  // Получение информации об оплате для печати чека
  printReady() {
    const dealId = this.details.id;
    this.payService.getReceipt(dealId).subscribe(
      data => {
        this.toPrint = data as IPeopleDealPrint;
        // Печать чека
        this.print();
      },
      error => {
        console.log(error);
      }
    );
  }

  // Создание окна для печати чека
  print() {
    const currency = this.toPrint.currency;
    const toMoney = new MoneyPipe().transform;
    const toTimeZone = new TimeZonePipe().transform;
    let popupWin = window.open('', '_blank');
    try {
      popupWin.document.open();

      let strBody = `
  <html>
    <head>
      <title>ЦИФРОВОЙ УЧЁТ ЗАКУПОК</title>
      <style>
      @page { size: auto;  margin: 0mm; }
      .to-print-page{
        padding: 50px;
        margin: 0 auto;
        width: max-content;
      }
      hr {
        border:none;
        border-top:1px dotted black;
        color:#fff;
        background-color:white;
        height:1px;
      }
      </style>
    </head>
  <body onload="window.print();">
  <div class="to-print-page">
  Квитанция к ПСА № ${this.toPrint.payNumber} от ${
    toTimeZone(this.toPrint.datePay, this.toPrint.timeZoneId, this.longDateFormat)
  }<br/>
  <hr/>
  Плательщик (лицензиат) - ${this.toPrint.licenseeTitle}, ИНН ${
        this.toPrint.licenseeInn
        }<br/>`;
      if (this.toPrint.isMobilePay) {
        strBody += `Номер мобильного телефона ${this.toPrint.clientPhone}<br/>`;
      } else {
        strBody += `ФИО получателя ${this.toPrint.baneficiare}<br/>
    Номер кредитной карты ${this.toPrint.cardNumber}<br/>`;
      }
      strBody += `Сумма - ${toMoney(this.toPrint.sum, currency)}<br/>
  Дата и время - ${toTimeZone(this.toPrint.datePay, this.toPrint.timeZoneId, this.shortDateFormat)}<br/>
  <hr/>
  Дата создания платежа: ${toTimeZone(this.toPrint.dateDealCreated, this.toPrint.timeZoneId, this.longDateFormat)}<br/>
  Оператор: ${this.toPrint.operatorFio}<br/>
  Контролер: ${this.toPrint.smsFio}<br/>
  <hr/>
  Безналичный платеж: ${toMoney(this.toPrint.sum, currency)}<br/>
  Итог: ${toMoney(this.toPrint.sum, currency)}<br/>

  </div>
  </body>
  </html>`;
      popupWin.document.write(strBody);
      popupWin.document.close();
    } catch (error) {
      // Тост о разрешении всплывающих окон для сайта
      this.showToast();
    }
  }
  
  showToast() {
    this.toastr.warning(
      'Для печати чека разрешите показывать всплывающие окна для сайта',
      'Всплывающее окно заблокировано',
      {
        timeOut: 15000,
        closeButton: true,
        progressBar: true
      }
    );
  }
}
