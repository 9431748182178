import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[submitDisable]'
})
export class SubmitDisableDirective implements OnChanges  {

  currentElement: ElementRef;
  renderer: Renderer2;

  @Input('submitDisable') formSending: boolean;

  constructor(el: ElementRef, private renderer2: Renderer2) {
    this.currentElement = el;
    this.renderer = renderer2;
    renderer2.addClass(el.nativeElement, 'btn--loading');
  }

  ngOnChanges(changes: SimpleChanges){
      if (this.formSending) {
        this.renderer.addClass(this.currentElement.nativeElement, 'sending');
      } else {
        this.renderer.removeClass(this.currentElement.nativeElement, 'sending');
      }
  }
}
