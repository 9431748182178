import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IAuthGuardPermission } from '../../models/auth/auth-guard-permission';

// Получение ролей
@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private router: Router,
              @Inject('LOCALSTORAGE') private localStorage: any) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // если пользователь уже авторизирован то ридеректим на странциу сделок
    if (this.localStorage != null && this.localStorage.getItem('isAuth')) {
      this.router.navigate(['/deals']);
      return false;
    }

    return true;
  }
}
