<div class="table__cell">
    <div class="point__number-wrapper">
        <a id="id1538404434" class="btn-for-open"
            *hasPermission="[[userRoles.ExecutiveOfficer, userRoles.Administrator, userRoles.Manager ,userRoles.ManagerPlus, userRoles.FinancialDirector]]"></a>
        <a id="id1538404450" class="status-btn "
            [ngClass]="{'status-btn--green': point.active == true,'status-btn--red': point.active == false}"></a>
    </div>
</div>
<div class="table__cell ">
    <div class="table__data">
        <span class="label--mobile">Название:</span>
        {{point.title}}
    </div>
</div>
<div class="table__cell">
    <div class="table__data table__data--address">
        <span class="label--mobile">Адрес:</span>
        <div class="address">{{point.address}}</div>
        <!--<div><a>{{point.usersCount}} оператора(ов)</a></div>-->
    </div>
</div>
<div class="table__cell">
    <div class="table__data">
        <span class="label--mobile">Суточный лимит:</span>
        <span *ngIf="point.limit===0">Безлимит</span>
        <span *ngIf="point.limit!==0">{{point.limit | money: currency}}</span>
    </div>
</div>
<div class="table__cell">
    <div class="table__data">
        <span class="label--mobile">Остаток суточного лимита:</span>
        {{point.dayLimitBalance | money: currency}}
    </div>
</div>
<div class="table__cell">
    <div class="table__data">
        <span class="label--mobile">Лимит на транзакцию:</span>
        <span *ngIf="point.transactionLimit===0">Безлимит</span>
        <span *ngIf="point.transactionLimit!==0">{{point.transactionLimit | money: currency}}</span>
    </div>
</div>