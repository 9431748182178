import { PayCheckModalComponent } from '../pay-checkModal/pay-checkModal.component';
import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { LoaderService, NotificationService } from 'tsuz-common';
import { UsersService } from '@services/users.service';
import { DealService } from '@services/deal.service';
import { ProductValidateResult } from '../../../models/product/ProductValidateResult';
import { IPeopleDealPay } from '../../../models/peopledeal/IPagePeopleDeal';
import { Guid} from 'guid-typescript';
import { DealFormService} from '@services/deal-form.service';
import { DealDraftViewModel } from '../../../models/peopledeal/DealDraftViewModel';
import { IDealDetailsViewModel, IProductMeasureUnit } from '../../../models/peopledeal/IDealDetailsViewModel';
import { IPointDeal } from '../../../models/peopledeal/IPointDeal';
import { IProvider } from '../../../models/peopledeal/IProvider';
import { IProductSetting } from '../../../models/peopledeal/IProductSettings';
import { ILicenseeSetting } from 'tsuz-common';
import { ILicenseeProduct } from '../../../models/peopledeal/IDetailsPeopleDeal';

@Component({
  selector: '[app-edit]',
  templateUrl: './edit-peopleDeal.component.html'
})

export class ShowEditModal implements OnInit {
  dealId: string;
  isCopy = false;
  @Output() editClose = new EventEmitter<number>();
  oneScreen: boolean = false;
  listTypes: any;
  infoLimit = `Выберите`;
  commentStr: string;
  total = 0;
  listForward: IPeopleDealPay;
  hasOne: ProductValidateResult = new ProductValidateResult(true);
  dateOld: any;
  array: FormArray;
  peopleForm: FormGroup;
  title: string;
  message: string;
  pointId: FormControl;
  comment: FormControl;
  externalId: FormControl;
  personId: string = '';
  deal: DealDraftViewModel;
  listprod: ILicenseeProduct[];
  listpoint: IPointDeal[];
  errorMessage: string;
  productStr: string;
  stepNum: Number = 0;
  isReady = false;
  isError = false;
  headerInfo: string;
  limitInfo: number;
  transcationInfo: number;
  currentLimitInfo: number;
  url = '/deal';
  defaultUnit: any;
  listProvider: IProvider[];
  settingList: ILicenseeSetting;
  listProviderFull: IProvider[];
  listProductSettings: IProductSetting[];
  dealProvider: IProvider;
  productMeasureUnits: Array<IProductMeasureUnit[]> = [];
  cash = 'Цена';
  hideOper = true;
  hidePp = true;
  nextButton = 'Далее';
  isCp = false;
  sumLoading = false;
  isPostAlready = false;
  formSending = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private simpleModalService: SimpleModalService,
    private usersService: UsersService,
    private dealService: DealService,
    private notificationService: NotificationService
  ) {

  }

  ngOnInit(): void {
    this.listProductSettings = [];
    this.route
      .queryParamMap
      .subscribe((params: ParamMap) => {
        this.dealId = params.get('dealId');
        this.isCopy = params.get('isCopy') === 'true';
      });
    this.loaderService.displayFullWidth(true);
    // Получение данных оплаты для редактирования
    this.dealService.getForEdit(this.dealId).subscribe(result => {
      this.deal = result;
      if (this.isCopy) {
        this.deal.cardNumber = '';
        this.deal.dealNumber = '';
      }
      if (this.deal.products.some(p => p.disabled)) {
        this.hasOne = new ProductValidateResult(false, 'Выбранный товар был удален. Выберите другой товар из списка.');
      }
      // Определения настроек для КП
      if (this.deal.dealSettings.licenseeType === 'CarPrice') {
        this.cash = 'Сумма';
        this.isCp = true;
      }
      // Установка инфо для номера редактирования
      this.headerInfo = `№ ${this.deal.pornumber} от ${this.deal.createDate}`;
      // Установка инфо для лимита точки
      this.infoLimit = `Выберите "${this.deal.pointTitle}"`;
      this.total = this.deal.sum;
      if (this.deal.dealSettings.hideDealProduct) {
        this.stepNum = 1;
      }
      // Если оплата не найдена
      if (!result) {
        this.isError = true;
      }
      else {
        this.loaderService.displayFullWidth(true);
        // Получение товаров лиценизата
        this.dealService.getProducts().subscribe(result => {
          this.listprod = result as ILicenseeProduct[];
          // Получение точек сотрудника
          this.dealService.getUserPoints(localStorage.getItem('userId')).subscribe(result2 => {
            this.listpoint = result2 as IPointDeal[];
            // Добавления инфо точки
            if (this.listpoint.length > 0) {
              this.infoLimit = this.listpoint[0].info;
            }
            // Получение провайдеров
            this.dealService.getProviders().subscribe(result3 => {
              this.listProvider = [];
              this.listProviderFull = [];
              this.listProviderFull = result3 as IProvider[];
              // Определение провайдера по умолчанию
              const mainProvider = this.listProviderFull.find(x => x.isMain);
              // Установка для оплаты провайдера
              this.dealProvider = mainProvider as IProvider;
              this.listProvider = result3 as IProvider[];
              // Определение мобильных провайдеров для КП
              if (this.deal.dealSettings.licenseeType === 'CarPrice') {
                this.initProviders(['carp', 'crpo']);
              }
              this.loaderService.displayFullWidth(false);
              // Создание формы
              this.createFormControls();
              this.createForm();
              // Проверка одного экрана для мобильного платежа
              this.checkOneScreen();
              // Инициализация товаров оплаты
              this.setProduct();
              // Инициализация товароной таблицы
              this.initProductTable();
              // Инициализация точек
              this.initPoint();
              this.isReady = true;
            }, error => this.isError = true);
          }, error => this.isError = true);
        }, error => this.isError = true);
      }

      this.loaderService.displayFullWidth(false);
    }, error => this.isError = true);

  }

  // Создание контрола для товаров
  initProductTable() {
    const control = <FormArray>this.peopleForm.controls.products;
    for (let i = 0; i < control.controls.length; i++) {
      if (this.peopleForm.value.products[i].trash > 100) {
        <FormArray>control.controls[i]['controls'].trash.setValue(99);
      }
    }
  }

  // Инициализация мобильных првоайдеров(Исключение провайдера для платежей по картам)
  initProviders(provider: string[]) {
    const ids = [];
    for (let i = 0; i < provider.length; i++) {
      const index = this.listProvider.findIndex(x => x.id === provider[i]);
      if (index > -1) {
        ids.push(index);
      }
    }
    if (ids.length > 0) {
      const arr = [];
      for (let i = 0; i < this.listProvider.length; i++) {
        if (ids.findIndex(x => x === i) < 0) {
          arr.push(this.listProvider[i]);
        }
      }
      this.listProvider = arr as IProvider[];
    }
  }

  // Изменения провайдера в форме
  changeProvider() {
    const mainProvider = this.listProvider.find(x => x.id == this.peopleForm.value.products[0].provider);
    this.dealProvider = mainProvider as IProvider;
    this.checkOneScreen();
  }

  // Проверка одного экрана для мобильного платежа
  checkOneScreen() {
    const result = this.dealService.checkOneScreen(this.deal.dealSettings, this.dealProvider, this.peopleForm.value.products, this.listprod);
    this.oneScreen = result[0];
    this.nextButton = result[1];
  }


  // Перерасчет суммы
  increase(): void {
    const products = this.peopleForm.getRawValue().products;
    if (products != null && products.length > 0) {
      this.sumLoading = true;
      this.dealService
        .getTotalSum(products, this.settingList)
        .subscribe(
          data => {
            this.total = data.totalSum;
            this.sumLoading = false;
            this.check();
          },
          error => {
            this.sumLoading = false;
            this.notificationService.errorNotify(
              'Ошибка',
              'Ошибка расчёта суммы товарного раздела'
            );
          }
        );
    }
  }
  // Проверка на положительность данных формы
  zeroAmount(event: any) {
    if (!event.target.value || (event.target.value && event.target.value < 0)) { event.target.value = 0; }
  }



    // Инициализация точек
  initPoint() {
    if (this.listpoint) {
      this.limitInfo = this.listpoint[0].limit;
      this.transcationInfo = this.listpoint[0].transactionLimit;
      this.currentLimitInfo = this.listpoint[0].currentLimit;
    }
  }

    // Получения инфо о выбранной точке
  checkPoint(event: any) {
    this.usersService.getUserPoint(localStorage.getItem('userId'), event.id, this.deal.dealSettings).subscribe(res => {
      this.listpoint = res[0];
      this.infoLimit = res[1];
      const index = this.listpoint.findIndex(x => x.id === event.id);
      if (index > -1) {
        this.limitInfo = this.listpoint[index].limit;
        this.transcationInfo = this.listpoint[index].transactionLimit;
        this.currentLimitInfo = this.listpoint[index].currentLimit;
      }
    });
    this.check();

  }

   // Проверка цены
  checkPrice(event: any) {
    if (event.target.value < 0) { event.target.value = 0; }
  }

  onFocus(event: any) {
    if (!event.target.value || (event.target.value && event.target.value <= 0)) { event.target.value = ''; }
  }
  onFocusOut(event: any) {
    if (!event.target.value || (event.target.value && event.target.value < 0)) { event.target.value = 0; }
  }

   // Проверка веса
  checkMeasure(event: any) {
    if (event.target.value < 0) { event.target.value = 0; }
    this.check();
  }

  checkTrash(event: any) {
    if (event.target.value > 99) { event.target.value = 99; }
    if (event.target.value < 0) { event.target.value = 0; }
    this.check();
  }

    // Проверка на валидность формы
  // this.hasOne = true Форма не валидна
  check() {
    this.hasOne = this.dealService.checkProducts(this.peopleForm.getRawValue().products, this.deal.dealSettings, this.total);
  }

  // Добавление товара
  addProduct() {
    // Получение контрола товаров
    const control = <FormArray>this.peopleForm.controls.products;
    this.listProductSettings.push({
      hidePp: true,
      hideProviders: true,
      id: ''
    });
    // Добавления в массив реактивных форм нового контрола
    control.push(
      this.fb.group({
        measureUnitValue: null,
        trash: null,
        measureUnitPrice: null,
        totalPrice: 0,
        productId: [],
        pnumber: null,
        measureUnit: null,
        provider: null,
        groupKey: null
      })
    );
      // Проверка формы на валидность
    this.check();
  }

    // Изменение товара
  changeProduct(id: string, indexControl: number) {
    if (id) {
            // Ищем Id товара
      const index = this.listprod.findIndex(x => x.id == id);
            // Если находим, то добавляем в массив товаров новый
      if (index > -1) {
        const currentProduct = <FormArray>this.peopleForm.controls.products;
        this.listProductSettings[indexControl].hidePp = this.listprod[index].hidePp;
        this.listProductSettings[indexControl].hideProviders = this.listprod[index].hideProviders;
        this.listProductSettings[indexControl].id = id;
        this.hideOper = this.listprod[index].hideProviders;
        this.hidePp = this.listprod[index].hidePp;
        currentProduct.controls[indexControl]['controls'].measureUnit.setValue(null);
        this.productMeasureUnits[indexControl] = this.listprod[index].measures.reduce((accumulator, value) => accumulator.concat(value.measureUnits), []);
        // Установка провайдера
        if (this.listProductSettings[indexControl].hideProviders) {
          const mainProvider = this.listProviderFull.find(x => x.isMain);
          this.dealProvider = mainProvider as IProvider;
        } else {
                    // Установка провайдера из списка
          if (this.peopleForm.value.products[0].provider) {
            const index = this.listprod.findIndex(x => x.id === id);
                        // Если платеж мобильный, то пишем провайдера из мобильного листа провайдеров
            if (index > -1 && this.listprod[index].oneScreen && this.listProvider.length > 0) {
              this.dealProvider = this.listProvider[0] as IProvider;
              const control = <FormArray>this.peopleForm.controls.products;
              for (let i = 0; i < control.controls.length; i++) {
                <FormArray>control.controls[i]['controls'].provider.setValue(this.dealProvider.id);
              }
            } else {
                            // Если мобильный лист пуст, то пишем провайдера из общего листа
              const mainProvider = this.listProviderFull.find(x => x.id == this.peopleForm.value.products[0].provider);
              this.dealProvider = mainProvider as IProvider;
            }
          }
        }
      }
      // Проверка на мобильный платеж
      this.checkOneScreen();
      // Проверка на валидность формы
      this.check();
    }
  }


  // Удаления товаров
  deleteProduct(index) {
    const control = <FormArray>this.peopleForm.controls.products;
    control.removeAt(index);
    if (this.peopleForm) {
      // Перерасчет суммы
      this.increase();
    }
    // Проверка формы на валидность
    this.check();
  }

  // Начальная установка товаров
  setProduct() {
    // Получение контрола товаров
    const control = <FormArray>this.peopleForm.controls.products;
    // Добавления товаров
    this.deal.products.forEach(x => {
      this.hideOper = x.hideProvider;
      this.hidePp = x.hidePp;
      this.listProductSettings.push({
        hidePp: x.hidePp,
        hideProviders: x.hideProvider,
        id: x.productId
      });
      // Добавления товаров в массив контролов товаров
      control.push(this.fb.group({
        trash: x.trash,
        productId: x.productId,
        provider: this.deal.providerId,
        pnumber: x.pnumber,
        measureUnitValue: x.measureUnitValue,
        measureUnitPrice: x.measureUnitPrice,
        measureUnit: x.measureUnitShortName,
        phone: this.deal.phone.formatPhoneShort(),
        onseScreen: x.oneScreen,
        groupKey: x.groupKey
      }));
    });
    // Поиск првоайдера
    const mainProvider = this.listProviderFull.find(x => x.id === this.deal.providerId);
    // Установка првоайдера оплате
    this.dealProvider = mainProvider as IProvider;
    // Проверка мобильного платежа на один экран
    this.checkOneScreen();
  }

  // Создание формы
  createFormControls() {
    this.externalId = new FormControl(Guid.create().toString(), Validators.required);
    if (this.deal) {
      this.pointId = new FormControl(this.deal.pointId, Validators.required);
      if (this.deal.comment) {
        this.comment = new FormControl(this.deal.comment);
      } else {
        this.comment = new FormControl(null);
      }
    } else {
      this.comment = new FormControl(null);
      if (this.listpoint.length > 0) {
        this.pointId = new FormControl(this.listpoint[0].id, Validators.required);
      } else {
        this.pointId = new FormControl('', Validators.required);
      }
    }
  }

  createForm() {

    this.peopleForm = this.fb.group({
      products: this.fb.array([]),
      PointId: this.pointId,
      Comment: this.comment
    });
  }

    // Приим эмиттера нажатия назад
  onPrevClick(event) {
    this.stepNum = 0;
    this.initProductTable();
  }

    // Проброска данных из второй старницы
  paySave(event: any) {
    if (event) {
      this.listForward = event as IPeopleDealPay;
    }
    this.stepNum = 0;
  }

  // Обновление оплаты
  onSubmit() {
    this.check();
    if (!this.hasOne.isValid || this.sumLoading) {
      return;
    }
    if (this.peopleForm.valid && this.deal.id.length > 0) {
      const prodCount: number = this.peopleForm.value.products.length;
      const allProd = this.peopleForm.value.products;
      const mort = JSON.stringify(allProd);

      this.pointId = this.peopleForm.value.PointId;
      this.productStr = mort;
      this.commentStr = this.peopleForm.value.Comment;
      // Если платеж мобильный на один экран, то сразу отправляем данные без второй страницы
      if (this.oneScreen) {
          this.toPost(this.peopleForm.value.PointId, mort);
      } else {
        this.stepNum = 1;
      }
    }
  }

    // Для мобильного платежа
  toPost(pointId: string, prod: any) {
    if (this.formSending) {
      return;
    }
    const formData = new FormData();
    if (this.peopleForm.value.Comment) {
      formData.append('Comments', this.peopleForm.value.Comment);
    }
    formData.append('Phone', this.peopleForm.value.products[0].phone.formatPhone());
    formData.append('ProviderId', this.dealProvider.id);
    formData.append('PointId', pointId);
    const jsonPost = JSON.stringify(this.deal.dealSettings);
    formData.append('products', prod);
    formData.append('settings', jsonPost);
    formData.append('ExternalId', this.peopleForm.value.ExternalId);
    formData.append('personId', this.personId);
    formData.append('concurrencyToken', this.deal.concurrencyToken.toString())

    this.formSending = true;
    if (this.isCopy) {
      this.dealService.create(formData).subscribe(data => {
          this.showPayCheckModal(data);
        },
        error => {
          this.errorMessage = error;
          this.formSending = false;
        });
      return;
    }
    this.dealService.update(this.deal.pornumber, formData).subscribe(data => {
        this.showPayCheckModal(data);
      },
        error => {
          this.errorMessage = error;
          this.formSending = false;
        });
  }

  showPayCheckModal(data: any) {
    this.isPostAlready = true;
    // Модальное окна для оплаты в шлюзе
    this.simpleModalService.addModal(PayCheckModalComponent, {
      title: 'Детали оплаты',
      deal: data as IDealDetailsViewModel,
      isEdit: true,
      message: 'Confirm message'
    }, { closeOnClickOutside: false })
      .subscribe((deal) => {
        this.formSending = false;
        this.deal.concurrencyToken = Number.parseInt(deal.concurrencyToken);
      });
  }

}
