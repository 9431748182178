import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { ILicenseeSetting } from '../../models/ILicenseeSetting';
import { LoaderService } from '../../services/loader.service';
import { NotificationService } from '../../services/notification.service';
import { ILicenseeService } from '../../services/interfaces/ILicenseeService';
import { changeFormGroupDisabledState } from '../../helpers/form.helper';

@Component({
  selector: 'tsuz-registry-settings',
  templateUrl: './registry-settings.component.html'
})
export class RegistrySettingsComponent implements OnInit {
  @Input() licenseeId: string;
  @Input() readonly: boolean = false;
  
  settingList: ILicenseeSetting;
  settingForm: FormGroup;
  registryEmailInfo: FormControl;
  postEmail: FormControl;

  constructor(
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private notifyService: NotificationService,
    @Inject('LicenseeService') private licenseeService: ILicenseeService,
  ) { }

  ngOnInit() {
    //Получение настроек лицензиата
    this.getSettings();
  }

  getSettings(): void {
    if (!this.licenseeId) {
      this.createFormControls();
      this.createForm();
      this.toggleDisabled();
    }
    else {
      this.loaderService.display(true);
      this.licenseeService.getForSettings(this.licenseeId).subscribe(result => {
        this.settingList = result as ILicenseeSetting;
        this.createFormControls();
        this.createForm();
        this.InitEmails();
        this.toggleDisabled();
        this.loaderService.display(false);
      }, error => {
        console.error(error);
      });
    }
  }

  createFormControls() {
    if (this.settingList) {
      this.registryEmailInfo = new FormControl(this.settingList.registryEmailInfo);
    }
    this.postEmail = new FormControl('');
  }

  createForm() {
    this.settingForm = new FormGroup({
      RegistryEmailInfo: this.registryEmailInfo,
      PostEmail: this.postEmail,
      Emails: this.fb.array([])
    });
  }

  toggleDisabled() {
    if (this.readonly) {
      changeFormGroupDisabledState(this.settingForm, !this.readonly);
    }
  }

  //Добавление эл. почты
  InitEmails(): void {
    if (!this.settingList.registryEmailInfo) {
      return;
    }
    let splitted = this.settingList.registryEmailInfo.split(',', Number(this.settingList.registryEmailInfoCount));
    const control = <FormArray>this.settingForm.controls.Emails;
    for (let i = 0; i < splitted.length; i++) {
      if (splitted[i] || splitted.length === 1) {
        control.push(this.fb.group({
          email: [splitted[i]]
        })
        );
      }
    }
  }

  //Добавление эл. почты
  addEmail(): void {
    //Получение контрола эл. почты
    const control = <FormArray>this.settingForm.controls.Emails;
    //Добавления в массив реактивных форм нового контрола
    control.push(
      this.fb.group({
        email: ['']
      })
    );
  }

  //Удаления эл. почты
  deleteEmail(index: any): void {
    //Получение контрола эл. почты
    const control = <FormArray>this.settingForm.controls.Emails;
    control.removeAt(index);
  }

  //Удалить пустые email-ы из контролов
  deleteEmpty(): void {
    //Получение контрола эл. почты
    const control = <FormArray>this.settingForm.controls.Emails;
    let index = control.controls.findIndex(x => !x['controls'].email.value);
    if (index > -1) {
      this.deleteEmail(index);
      this.deleteEmpty();
    }
  }

  //Отправка данных
  async onSubmit() {
    const control = <FormArray>this.settingForm.controls.Emails;
    let arr = [];
    for (let i = 0; i < control.controls.length; i++) {
      if (<FormArray>control.controls[i]['controls'].email.value) {
        //Исключаем дубликаты перед отравкой
        if(arr.indexOf(<FormArray>control.controls[i]['controls'].email.value) < 0)
          arr.push(<FormArray>control.controls[i]['controls'].email.value);
      }
    }
    this.settingForm.controls['RegistryEmailInfo'].setValue(arr.toString());
    this.deleteEmpty();
    try {
      await this.licenseeService.updateSettings(this.licenseeId, this.settingForm.value).toPromise();
      this.notifyService.successNotify('Настройки успешно изменены', 'Настройки');
    } catch (error) {
      this.notifyService.errorNotify('Ошибка сохранения', 'Настройки');
    }
  }

}
