
import { Pipe, PipeTransform } from '@angular/core';
import { CodeFnsForSelfEmployed } from '../../../core/enums/CodeFnsForSelfEmployed';

@Pipe({
  name: 'codeFnsForSelfEmployedPipe'
})
export class CodeFnsForSelfEmployedPipe implements PipeTransform {
  transform(value?: CodeFnsForSelfEmployed, args?: any): string {
    switch (value) {
      case CodeFnsForSelfEmployed.ApiNotResponding: return 'Возникла ошибка в обработке фискального документа. Просьба сообщить в службу технической поддержки';
      case CodeFnsForSelfEmployed.TaxpayerUnbound: return 'Самозанятый не привязан к партнеру ЦУЗ в личном кабинете "Мой налог". Следует отправить запрос на привязку и одобрить его в личном кабинете приложения "Мой налог"';
      case CodeFnsForSelfEmployed.TaxpayerUnregistered: return 'Самозанятый снят с учета или не обнаружен. Пожалуйста, уберите ИНН из реквизитов и проведите оплату в штатном режиме.';
      case CodeFnsForSelfEmployed.PartnerDeny: return 'Партнеру отказано в регистрации дохода для НП НПД.-sent';
      case CodeFnsForSelfEmployed.Duplicate: return 'Такой доход уже был зарегистрирован.';
      case CodeFnsForSelfEmployed.PermissionNotGranted: return 'У партнера нет прав на регистрацию дохода.';
      case CodeFnsForSelfEmployed.ReceiptIdNotFound: return 'Чек с таким ID не зарегистрирован.';
      case CodeFnsForSelfEmployed.InvalidHash: return 'Чек не может быть принят, так как у него не корректный фискальный признак.';
      case CodeFnsForSelfEmployed.InvalidSequence: return 'Для получения фискального признака был использован не валидный номер последовательности.';
      default: return '';
    }
  }
}
