<div class="date-range-filter__my-date-range-picker-wrapper">
  <div class="date-range-filter__my-date-range-picker">
    <input
      [attr.id]="pluginInputId ? pluginInputId : null"
      placeholder="{{placeholder}}"
      autocomplete="off"
      angular-mydatepicker
      name="myDate"
      [options]="myDateRangePickerOptionsNormal"
      #datepicker="angular-mydatepicker"
      [(ngModel)]="myDateModel"
      (ngModelChange)="onChange()"
      (inputFieldChanged)="onInputFieldChanged($event)"
    />
  </div>
  <div class="date-range-filter__my-date-range-buttons">
    <button type="button" (click)="clearInput()"
            [hidden]="!valueIsNoEmpty">
      <fa-icon [icon]="faClearIcon" class="icon"></fa-icon>
    </button>
    <button type="button" (click)="datepicker.toggleCalendar()">
      <fa-icon [icon]="faCalendarIcon" class="icon"></fa-icon>
    </button>
  </div>
</div>
