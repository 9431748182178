import {HttpParams} from '@angular/common/http';
import {Params} from '@angular/router';
import {NumberRange} from './number-range';
import {DateRange} from './date-range';
import { DateTime } from 'luxon';
import { DateHelper } from '../helpers/date.helper';

export class BaseQueryFilter {

  initFromParam(params: Params) {

    let complexKeys: string[] = [];

    this.getKeys(this).filter(x => this[x] instanceof NumberRange).forEach(key => {
      const numberRange = this[key] as NumberRange;
      complexKeys.push(numberRange.queryNameFrom);
      complexKeys.push(numberRange.queryNameTo);
      if (params[numberRange.queryNameFrom]) {
        numberRange.numberFrom = params[numberRange.queryNameFrom];
      }
      if (params[numberRange.queryNameTo]) {
        numberRange.numberTo = params[numberRange.queryNameTo];
      }
    });

    this.getKeys(this).filter(x => this[x] instanceof DateRange).forEach(key => {
      const dateRange = this[key] as DateRange;
      complexKeys.push(dateRange.queryNameFrom);
      complexKeys.push(dateRange.queryNameTo);
      dateRange.dateFrom = this.fromIsoStringToDate(params[dateRange.queryNameFrom]);
      dateRange.dateTo = this.fromIsoStringToDate(params[dateRange.queryNameTo]);
    });

    Object.keys(params).filter(x => this[x] instanceof Array).forEach(key => {
      complexKeys.push(key);
      if (params[key]) {
        this[key] = params[key].split(',');
      }
    });

    Object.keys(params).filter(x => complexKeys.indexOf(x) < 0).forEach(key => {
      if (params[key]) {
        this[key] = params[key];
      }
    });
  }

  toHttpParams(){
    var params = this.getParams();
    var nonNullParams = this.removeNullValuesFromQueryParams(params);
    var plainParamsList = this.separateArrayValues(nonNullParams);

    return plainParamsList;
  }

  toRouterParams() {
    const params = {};
    const httpParams = this.getParams();
    httpParams.keys().forEach(x => {
      const value = httpParams.get(x);

      // params.keys() с Ангуляр 13 перегоняет все значения в строки
      if (value !== 'null' && value !== 'undefined' && value !== '') {
        params[x] = value;
      }
    });

    return params;
  }

  /**
   * Returns array of object keys not including properties starting with '_' like '_page'
   * Override this method to return additional keys. For example, if the property is a pair of get(), set() methods
   */
  protected getKeys(obj: Object): string[] {
    const result = Object.keys(obj).filter(key => !(typeof key === 'string' && key.charAt(0) === '_'));
    return result;
  }

  private getParams(): HttpParams {
    let params = new HttpParams();

    this.getKeys(this).forEach(key => {
      params = this.getParamsFromNumberRange(params, key);
      params = this.getParamsFromDateRange(params, key);
      params = this.getParamsFromArrayTypes(params, key);
      params = this.getParamsFromSimpleTypes(params, key);
    });

    return params;
  }

  private separateArrayValues(params: HttpParams): HttpParams{
    var result = new HttpParams();
    params.keys().forEach(x => {
      var value = params.get(x);
      if (Array.isArray(value)){
        result = result.appendAll({ [x] : value})
      } else {
        result = result.append(x, value);
      }
    });

    return result;
  }

  private removeNullValuesFromQueryParams(params: HttpParams) {
    const paramsKeysAux = params.keys();
    paramsKeysAux.forEach((key) => {
      const value = params.get(key);
      if (Array.isArray(value) && value.length < 1) {
        params['map'].delete(key);
      }

      // params.keys() с Ангуляр 13 перегоняет все значения в строки
      if (value === 'null' || value === 'undefined' || value === '') {
        params['map'].delete(key);
      }
    });

    return params;
  }

  private getParamStringFromDate(date: Date): string {
    const timeZoneDateTime = DateHelper.replaceTimeZone(date, DateHelper.getTimeZoneId(this));
    if (!timeZoneDateTime) {
      return '';
    }

    const result = timeZoneDateTime.toUTC();
    return result;
  };

  private fromIsoStringToDate(param: string): Date {
    if (!param) {
      return null;
    }

    const dateTime = DateTime.fromISO(param).setZone(DateHelper.getTimeZoneId(this));
    const localDateTime = DateHelper.replaceTimeZone(dateTime, DateHelper.localTimeZoneId);
    return localDateTime.toJSDate();
  }

  private getParamsFromDateRange(params: HttpParams, key: string): HttpParams {
    const dateRange = this[key] as DateRange;
    if (!dateRange) {
      return params;
    }

    const fromValue = this.getParamStringFromDate(dateRange.dateFrom);
    const toValue = this.getParamStringFromDate(dateRange.dateTo);

    params = this.addParam(params, fromValue, dateRange.queryNameFrom);
    params = this.addParam(params, toValue, dateRange.queryNameTo);

    return params;
  }

  private getParamsFromNumberRange(params: HttpParams, key: string): HttpParams {
    if (!(this[key] instanceof NumberRange)) {
      return params;
    }

    const numberRange = this[key] as NumberRange;
    const fromValue = numberRange.numberFrom ? numberRange.numberFrom : null;
    const toValue = numberRange.numberTo ? numberRange.numberTo : null;

    params = this.addParam(params, fromValue, numberRange.queryNameFrom);
    params = this.addParam(params, toValue, numberRange.queryNameTo);

    return params;
  }

  private getParamsFromSimpleTypes(params: HttpParams, key: string): HttpParams {
    if ((this[key] instanceof NumberRange)
      || (this[key] instanceof DateRange)
      || (this[key] instanceof Array)
      || key == 'total') {
      return params;
    }

    params = this.addParam(params, this[key], key);

    return params;
  }

  private getParamsFromArrayTypes(params: HttpParams, key: string): HttpParams {
    if (!Array.isArray(this[key])) {
      return params;
    }

    if (this[key].length < 1) {
      return params;
    }

    params = this.addParam(params, this[key], key);

    return params;
  }

  addParam(params: HttpParams, value: any, paramName: any): HttpParams {
    if (value && value != null) {
      params = params.append(paramName, value);
    }
    else if (value === "" || value === null){
      params = params.append(paramName, null);
    }
    return params;
  }

  addListParam(params: HttpParams, value: any, paramName: any): HttpParams {
    if (value && value.length > 0) {
      for (let item of value as string[]) {
        params = this.addParam(params, item, paramName);
      }
    }
    return params;
  }

  protected reset(): void {
  }
}
