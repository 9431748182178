import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { RegistriesFilter } from '../../models/registries/registries-filter';
import { Observable } from 'rxjs';
import { IRegistry } from '../../models/registries/registry';
import { CreateRegistry } from '../../models/registries/create-registry';
import { Paginated } from '../models/paginated';
import { BaseHttpService } from 'tsuz-common';

@Injectable({
  providedIn: 'root'
})
export class RegistriesService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  GetRegistries(filter: RegistriesFilter): Observable<Paginated<IRegistry[]>> {
    return this.baseHttpService.get(`/registries${filter}`);
  }

  Create(request: CreateRegistry): Observable<any> {
    return this.baseHttpService.postWithBlobResponse(`/registry${request}`, null, { observe: 'response', responseType: 'blob' }).pipe(
      map(this.handleFileResponse));
  }

  download(id: string): Observable<any> {
    return this.baseHttpService.getFile(`/registries/${id}/download`).pipe(
      map(this.handleFileResponse));
  }

  SendSmsCode(id: string): Observable<any> {
    return this.baseHttpService.post(`/reestr/${id}/confirmingcode`, null);
  }

  CheckCode(id: string, code: string): Observable<any> {
    return this.baseHttpService.post(`/registry/${id}/sendingtornko`, { ConfirmationCode: code });
  }

  private handleFileResponse(response: HttpResponse<Blob>): { filename: string, data: Blob } {
    return {
      filename: `${response.headers.get('FileName')}`,
      data: response.body
    };
  }
}
