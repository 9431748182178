import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "[payment-modal-progress-bar]",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.css"]
})
export class ProgressBar implements OnInit {
  @Input() maxStep: number;
  @Input() step: number;

  public steps: number[];

  ngOnInit(): void {
    this.steps = new Array(this.maxStep + 1).fill(0).map((_, i) => i);
  }
}
