import { Component, Input, OnInit } from '@angular/core';
import { CurrencyService } from '../../../core/services/currency.service';
import { Balance, BalanceState } from 'tsuz-common';

@Component({
  selector: 'app-balance-view',
  templateUrl: './balance-view.component.html'
})
export class BalanceViewComponent implements OnInit {

  @Input() balance: Balance;
  @Input() currency: string;
  
  BalanceState: typeof BalanceState = BalanceState;

  constructor(private currencyService: CurrencyService) {

  }

  ngOnInit(): void {
    if (!this.currency) {
      this.currency = this.currencyService.getProfileCurrency();
    }
  }

}