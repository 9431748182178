import { IDealDetailsViewModel } from './../../../models/peopledeal/IDealDetailsViewModel';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {SimpleModalService} from 'ngx-simple-modal';
import {DealModalInfoComponent} from './deal-modal-info/deal-modal-info.component';
import {PayModalInfoComponent} from './pay-modal-info/pay-modal-info.component';
import {DealFilter} from '../../../models/support/DealFilter';
import {ISupportPayInfo} from '../../../models/supportdeal/ISupportPayInfo';
import {SubscriptionLike, ReplaySubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {SupportService} from '@services/support.service';
import {LoaderService, NotificationService, HttpErrorService, DateRange, NumberRange, FilterService, SortModel, ListFilterItemModel,
  getPhoneMask} from 'tsuz-common';
import {DealService} from '@services/deal.service';
import {PayService} from '@services/pay.service';
import {IPageSupportDeal} from "../../../models/supportdeal/IPageSupportDeal";
import { WithdrawTypeService } from '@services/withdraw-type.service';

class DealCollectionColumns{
  id: string;
  display: string;
}

@Component({
  selector: 'app-support-deal',
  templateUrl: './support-deal.component.html'
})
export class SupportDealComponent implements OnInit, OnDestroy {
  peopleDeals: IPageSupportDeal;
  totalCount: number;
  listTakes = [5, 10, 15, 20, 50];
  isDescSort = true;
  filter: DealFilter;
  withdrawTypeFilterItems: ListFilterItemModel[];
  numberRange: NumberRange;
  dateModel: DateRange;
  dataSort: SortModel = new SortModel();
  subscriptions: SubscriptionLike[] = new Array<SubscriptionLike>();
  dealTableColumns: DealCollectionColumns[] = [];
  selectedDealTableColumns: any[] = [];
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  getPhoneMask = getPhoneMask;

  constructor(
    private supportService: SupportService,
    private simpleModalService: SimpleModalService,
    private loaderService: LoaderService,
    private httpErrorService: HttpErrorService,
    private dealService: DealService,
    private payService: PayService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private withdrawTypeService: WithdrawTypeService,
    @Inject('LOCALSTORAGE') private localStorage: any
  ) {
  }

  ngOnInit() {
    // Получение оплат
    this.initWithdrawTypes();
    this.initFilterModel();
    this.fillDealTeableColumnsList();
    this.route.queryParams
      .pipe(takeUntil(this.destroy))
      .subscribe(val => {
      this.filter = new DealFilter();
      this.filter.initFromParam(val);

      this.getSupportDeal();
    });
  }

  resetFilters() {
    this.initFilterModel();
    this.applyFilter(true);
  }

  initFilterModel() {
    this.filter = new DealFilter();
  }

  initWithdrawTypes() {
    this.withdrawTypeService.getAll()
      .subscribe(result => {
        this.withdrawTypeFilterItems = [];
        result.items.forEach(wt => this.withdrawTypeFilterItems.push(new ListFilterItemModel(wt.name, wt.key)));
    }, error => {
      this.notificationService.errorNotify('Ошибка получения типов оплаты', 'Ошибка');
    });
  }

  fillDealTeableColumnsList(): void {
    this.dealTableColumns = [
      { "id": 'status', "display": 'Статус'},
      { "id": 'organization', "display": 'Организация'},
      { "id": 'dealNumber', "display": 'Номер оплаты'},
      { "id": 'sum', "display": 'Сумма'},
      { "id": 'commision', "display": 'Сумма комиссии'},
      { "id": 'withdrawTypeName', "display": 'Тип выплаты'},
      { "id": 'cardNumber', "display": 'Номер карты'},
      { "id": 'phone', "display": 'Номер телефона'},
      { "id": 'date', "display": 'Дата создания'},
      { "id": 'documents', "display": 'Фискальные документы'},
    ];

    var columnsFromStorage = JSON.parse(this.localStorage.getItem('selectedSupportDealTableColumns')) as Array<DealCollectionColumns>;
    this.selectedDealTableColumns = columnsFromStorage
      ? columnsFromStorage.map((x) => (x.id))
      : this.selectedDealTableColumns = this.dealTableColumns.map((x) => (x.id));
  }

  columnIsVisible(id: string): boolean{
    return this.selectedDealTableColumns.some(x => x === id);
  }

  onDealTableColumnsChange(value: any): boolean {
    return this.localStorage.setItem('selectedSupportDealTableColumns', JSON.stringify(value));
  }

  // Получение оплат
  getSupportDeal(newPage: number = 0) {
    this.loaderService.display(true);
    this.dealService.getPageSupport(this.filter)
      .pipe(takeUntil(this.destroy))
      .subscribe(result => {
      this.peopleDeals = result as IPageSupportDeal;
      this.filter.total = this.peopleDeals.total;
    }, error => {
      this.loaderService.display(false);
      this.notificationService.errorNotify('Ошибка получения данных', 'Ошибка');
    });
  }

  // В зависимости от состояний получаем оплату
  changeState(st: string) {
    this.filter.states = [ st ];
    this.filter.page = 1;
    this.applyFilter();
  }

  // Изменение количества оплат на странице
  changeTakeCount() {
    this.filter.page = 1;
    this.applyFilter();
  }

  // Следующая страница страница
  nextPage() {
    if (this.peopleDeals.total > this.filter.take * this.filter.page) {
      this.filter.page += 1;
      this.applyFilter();
    }
  }

  // Предыдущая страница
  prevPage() {
    if (this.filter.page > 1) {
      this.filter.page -= 1;
      this.applyFilter();
    }
  }

  // Получаем информацию об оплате для тп
  showDealInfo(event: any) {
    this.dealService.getInfo(event)
      .pipe(takeUntil(this.destroy))
      .subscribe(
      (supportDealInfo: IDealDetailsViewModel) => {
        if (supportDealInfo) {
          this.subscriptions.push(
            this.supportService.getSupportLicenseeInfo(event).subscribe(
              lic => {
                    const dealmodal = this.simpleModalService
                    .addModal(
                      DealModalInfoComponent,
                      {
                        title: 'Детали оплаты',
                        supportDealInfo: supportDealInfo,
                        licenseeInfo: lic,
                        message: 'Confirm message',
                        pointName: supportDealInfo.dealSettings.pointName,
                      },
                      {closeOnClickOutside: true}
                    );
                  },
              error => console.error(error)
            ));
        }
      },
      error => console.error(error)
    );
  }

  showPayInfo(event: any) {
    this.payService.getSupportPayInfo(event)
      .pipe(takeUntil(this.destroy))
      .subscribe(
      data => {
        const supportPayInfo = data as ISupportPayInfo;
        if (supportPayInfo) {
          const paymodal = this.simpleModalService
            .addModal(
              PayModalInfoComponent,
              {
                title: 'Детали оплаты',
                supportPayInfo: supportPayInfo,
                message: 'Confirm message',
                pointName: ''
              },
              {closeOnClickOutside: true}
            )
            .subscribe(data => {
            });
        }
      },
      error => {
        const {message, fields} = this.httpErrorService;
        if (message){
         this.notificationService.errorNotify(message,'Ошибка');
        }
        }
    );
  }

  applyFilter(reset = false) {
    // у textMask нет подобного dropSpecialCharacters и нужно очищать значение от символов маски вручную
    if (this.filter.phone) {
      this.filter.phone = this.filter.phone.replace(/\D/g, '');
    }

    this.filterService.applyFromModel(this.filter, reset);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.destroy.next(null);
  }
}
