import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { LicenseeService } from '@services/licensee.service';
import { LoaderService, ILicenseeSetting, NotificationService } from 'tsuz-common';

@Component({
  selector: 'limit-settings',
  templateUrl: './limit-settings.component.html',
  styleUrls: ['./limit-settings.component.scss']
})
export class LimitSettingsComponent implements OnInit {

  licenseeId: string;
  settingList: ILicenseeSetting;
  settingForm: FormGroup;
  limitBalanceInfo: FormControl;
  public mask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    @Inject("LOCALSTORAGE") private localStorage: any,
  ) { }

  ngOnInit() {
    this.licenseeId = this.localStorage.getItem('licenseeId');

    //Получение настроек лицензиата
    this.getSettings();
  }

  getSettings(): void {
    this.loaderService.display(true);

    this.licenseeService.getForSettings(this.licenseeId).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
      this.createFormControls();
      this.createForm();
      this.initPhones();
      this.loaderService.display(false);
    }, error => {
      console.error(error);
    });
  }

  createFormControls() {
    if (this.settingList) {
      this.limitBalanceInfo = new FormControl(this.settingList.limitBalanceInfo, Validators.required);
    } else {
      this.limitBalanceInfo = new FormControl(this.settingList.limitBalanceInfo);
    }
  }

  createForm() {
    this.settingForm = new FormGroup({
      LimitBalanceInfo: this.limitBalanceInfo,
      PhoneBalance: this.fb.array([]),
    });
    if (this.settingList?.disableLicenseeBalance) {
      this.settingForm.disable();
      this.limitBalanceInfo.disable();
    }
  }

  // Добавление телефона
  initPhones(): void {
    const control = <FormArray>this.settingForm.controls.PhoneBalance;

    if (!this.settingList.phoneBalance) {
      return;
    }

    let splitted = this.settingList.phoneBalance.split(',', Number(this.settingList.phoneBalanceCount));
    for (let i = 0; i < splitted.length; i++) {
      const phoneValue = splitted[i].replace(/\D/g, '');
      control.push(this.fb.group({
        phone: [phoneValue]
      }));
    }
  }

  //Добавление телефона
  addPhone(): void {
    //Получение контрола телефона
    const control = <FormArray>this.settingForm.controls.PhoneBalance;
    //Добавления в массив реактивных форм нового контрола
    control.push(
      this.fb.group({
        phone: ['']
      })
    );
  }

  //Удаления телефона
  deletePhone(index: any): void {
    //Получение контрола телефона
    const control = <FormArray>this.settingForm.controls.PhoneBalance;
    control.removeAt(index);
  }

  //Удалить пустые телефоны из контролов
  deleteEmpty(): void {
    //Получение контрола телефона
    const control = <FormArray>this.settingForm.controls.PhoneBalance;
    let index = control.controls.findIndex(x => !x['controls'].phone.value);
    if (index >= 0) {
      this.deletePhone(index);
      this.deleteEmpty();
    }
  }

  //Отправка данных
  async onSubmit() {
    this.deleteEmpty();

    const value = this.settingForm.value;
    const phoneBalance = this.settingForm.get('PhoneBalance').value.map(p => p.phone.replace(/\D/g, '')).join(', ');
    value.PhoneBalance = phoneBalance;

    try {
      await this.licenseeService.updateSettings(this.licenseeId, value).toPromise();
      this.notifyService.successNotify('Настройки успешно изменены', 'Настройки');
      this.licenseeService.getSettings(this.licenseeId, true).subscribe(result => {});
    } catch (error) {
      this.notifyService.errorNotify('Ошибка сохранения', 'Настройки');
    }
  }

  getPhoneItemId(i:number) {
    return `set${ i + 5 }`;
  }

}
