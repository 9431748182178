<div class="upblock">
    <h1 class="h1">Заявки на привязку самозанятых</h1>

    <div class="upblock__btn-wrapper">
        <button class="btn btn--active"
                (click)="createRequest();"
                id="id1538477677">
            Новая заявка
        </button>
    </div>
</div>

<div class="d-table table">
    <div class="d-table-row table__header-row">
        <div class="table__header-cell table__header-col table__header-col--w200 filter__column relative">
            <div class="filter__text-align-left">
                <div>
                    Статус
                </div>
                <div class="filter__btn"
                     (click)="viewState='state'"
                     id="id1578864042">
                </div>

                <div class="filter zindex"
                     *ngIf="viewState=='state'">
                    <div class="filter__input-wrapper">
                        <app-select-box-control
                                   id="id1578864034"
                                   [items]="listStates"
                                   [(ngModel)]="selectedState"
                                   class="states">
                        </app-select-box-control>
                    </div>
                    <div class="filter__footer">
                        <a class="btn2 float-right"
                           (click)="viewState=null"
                           id="id1578864037">
                            Отмена
                        </a>
                        <a class="btn2 btn2--active active float-left"
                           (click)="filter.state = selectedState.value; load();viewState=null"
                           id="id1578864026">
                            Применить
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table__header-cell table__header-col table__header-col--w200 filter__column relative">
            <div class="filter__text-align-left">
                <div>
                    № Заявки
                </div>
                <div class="filter__btn"
                     (click)="viewState='responseKey'"
                     id="id1578864011">
                </div>
                <div class="filter zindex"
                     *ngIf="viewState=='responseKey'">
                    <div class="filter__input-wrapper">
                        <input class="input"
                               id="id1538477452"
                               placeholder="№ Заявки..."
                               [(ngModel)]="responseKey"
                               type="text">
                    </div>
                    <div class="filter__footer">
                        <a class="btn2 float-right"
                           (click)="viewState=null"
                           id="id1538477950">
                            Отмена
                        </a>

                        <a class="btn2 btn2--active active float-left"
                           (click)="filter.requestKey = responseKey; load();viewState=null"
                           id="id1538477923">
                            Применить
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table__header-cell table__header-col table__header-col--w200 filter__column relative">
            <app-date-range-filter
              [(model)]="filter.range"
              (apply)="load()"
              [paginationFilter]="filter">
                Дата/время создания
            </app-date-range-filter>
        </div>
        <div class="table__header-cell table__header-col table__header-col--w200 filter__column relative">
            <div class="filter__text-align-left">
                <div>
                    ИНН самозанятого
                </div>
                <div class="filter__btn"
                     (click)="viewState='inn'"
                     id="id1578864006">
                </div>
                <div class="filter zindex"
                     *ngIf="viewState=='inn'">
                    <div class="filter__input-wrapper">
                        <input class="input"
                               id="id1578863994"
                               placeholder="ИНН..."
                               [(ngModel)]="inn"
                               type="text"
                               mask="000000000000">
                    </div>
                    <div class="filter__footer">
                        <a class="btn2 float-right"
                           (click)="viewState=null"
                           id="id1538477950">
                            Отмена
                        </a>
                        <a class="btn2 btn2--active active float-left"
                           (click)="filter.inn = inn; load(); viewState=null"
                           id="id1538477923">
                            Применить
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table__header-cell table__header-col table__header-col--w200 filter__column relative">
            <div class="filter__text-align-left">
                <div>
                    Действие
                </div>
                <div class="filter__btn action"
                     id="id1578864016">
                </div>
            </div>
        </div>
    </div>

    <div class="d-table-row table__row"
         *ngFor="let request of (requests$ | async)?.items;">
        <div class="table__header-cell table__col">
            <div class="table__data">
                <span class="label--mobile">
                    Статус:
                </span>
                <div class="img-status"
                     [ngClass]="{'img-status__new':request.state==1,
                                 'img-status__process-binding':request.state==2,
                                 'img-status__bound':request.state==3,
                                 'img-status__rejected':request.state==4,
                                 'img-status__unbound':request.state==5}">
                    {{request.state | platformBindingRequestStateToString}}
                </div>
            </div>
        </div>

        <div class="table__header-cell table__col">
            <div class="table__data">
                <span class="label--mobile">
                    № Заявки:
                </span>
                {{request.requestKey}}
            </div>
        </div>

        <div class="table__header-cell table__col">
            <div class="table__data">
                <span class="label--mobile">
                    Дата/время создания:
                </span>
                {{request.dateCreate | date:"dd.MM.yyyy hh:mm" }}
            </div>
        </div>

        <div class="table__header-cell table__col">
            <div class="table__data">
                <span class="label--mobile">
                    ИНН самозанятого:
                </span>
                {{request.inn}}
            </div>
        </div>

        <div class="table__header-cell table__col">
            <div class="table__data">
                <span class="label--mobile">
                    Действие:
                </span>
                <a class="action-link"
                   (click)="request.state == 1 || request.state == 2 ? CheckState(request.id) : (request.state == 3 ? Unbind(request.id) : null);"
                   id="id1577661818">
                    {{request.state | platformBindingRequestStateToActionString}}
                </a>
            </div>
        </div>
    </div>
</div>

<div class="pagination"
     *ngIf="requests$ | async as requestsLoaded">
    <div class="pagination__text">
        Выводить по:
    </div>

    <app-select-box-control
               id="id1538482171"
               [items]="listTakes"
               [(ngModel)]="filter.take"
               (change)="filter.page=1;load()">
    </app-select-box-control>

    <span class="pagination__text">&nbsp;&nbsp;&nbsp;Всего записей
        {{filter.count > requestsLoaded.total ? requestsLoaded.total : filter.count }}
        из
        {{requestsLoaded.total}}</span>

    <a class="pagination__btn pagination__btn--previous"
       (click)="onPreviousPage()"
       id="id1538477163"></a>

    <a class="pagination__btn pagination__btn--next"
       (click)="onNextPage(requestsLoaded.total)"
       id="id1538477174"></a>
</div>
