<div class="filter__text-align-left"
     (clickOutside)="onCancel()">
  <div class="number-range-filter__header">
    <ng-content></ng-content>
  </div>

  <app-sorting-header *ngIf="_sort"
                      [(sort)]="_sort"
                      [sortField]="sortField"
                      (sortChange)="onSortClick()">
  </app-sorting-header>

  <div class="filter__btn number-range-filter__show-button"
       [ngClass]="{'filter__btn-active': isActive, 'filter__margin-left': !_sort}"
       (click)="isShowModal=true;">
  </div>

  <div class="filter zindex number-range-filter__modal"
       *ngIf="isShowModal">
    <div class="filter__input-wrapper number-range-filter__my-number-range-picker-wrapper">
      <input id="id1538477343"
             placeholder="от"
             [(ngModel)]="numberFrom"
             [dropSpecialCharacters]="false"
             mask="0*.00"
             type="text"
             class="input">
      <input id="id1538477394"
             placeholder="до"
             [(ngModel)]="numberTo"
             [dropSpecialCharacters]="false"
             mask="0*.00"
             type="text"
             class="input">
    </div>

    <div class="filter__footer number-range-filter__controls">
      <a class="btn2 active float-left number-range-filter__apply"
         (click)="onApply()">Применить</a>
      <a class="btn2 float-right number-range-filter__cancel"
         (click)="onCancel()">Отмена</a>
    </div>
  </div>
</div>

