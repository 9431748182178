import {Component, OnInit} from '@angular/core';
import {LicenseeService} from '@services/licensee.service';
import {SupportService} from '@services/support.service';
import {OrganizationTurnoverOutput} from '../../../models/support/Turnover/OrganizationTurnoverResponse';
import {OrganizationTurnoverFilter} from '../../../models/support/Turnover/OrganizationTurnoverFilter';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { LoaderService, NotificationService, DateRange, LicenseeModel, DateHelper } from 'tsuz-common';
import { OptionItemModel } from '@shared/controls/search-select/option-item-model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-support-turnover',
  templateUrl: './support-turnover.component.html',
  styleUrls: ['./support-turnover.component.scss']
})
export class SupportTurnoverComponent implements OnInit {
  filter: OrganizationTurnoverFilter;
  organizationsTurnover: OrganizationTurnoverOutput;
  licenseeList: LicenseeModel[];
  licenseeOptionList: OptionItemModel[];
  searchForm: FormGroup;
  selectedLicenseeIdsControl: FormControl;
  onlyActiveControl: FormControl;
  periodControl: FormControl;
  currency: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  constructor(private supportService: SupportService,
              private licenseeService: LicenseeService,
              private loaderService: LoaderService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.getLicensees();
    this.initFilter();
    this.createForm();
  }
  
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  createForm() {
    this.onlyActiveControl = new FormControl(false);
    this.periodControl = new FormControl('', Validators.required);
    this.selectedLicenseeIdsControl = new FormControl(this.getAllLicenseeIds());

    this.searchForm = new FormGroup({
      OnlyActive: this.onlyActiveControl,
      Period: this.periodControl,
      LicenseeIds: this.selectedLicenseeIdsControl,
    }, this.licenseeIdsValidator);

    this.onlyActiveControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(error => {
        console.error(error);
        this.updateLicenseeOptionList(false);
      });
  }

  licenseeIdsValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
    if (this.onlyActiveControl?.value) {
      return null;
    }
    
    const licenseeIds = formGroup.get('LicenseeIds').value;
    return licenseeIds?.length ? null : {'licenseesRequired': true};
  }

  resetFilters() {
    this.initFilter();
    this.createForm();
    this.organizationsTurnover = null;
  }

  initFilter() {
    this.filter = new OrganizationTurnoverFilter();
  }

  private getAllLicenseeIds(): string[] {
    return this.licenseeOptionList ? [...this.licenseeOptionList.map(l => l.value)] : [];
  }

  private updateLicenseeOptionList(selectAll: boolean) {
    if (!this.licenseeList) {
      return [];
    }

    this.licenseeOptionList = this.licenseeList
      .map(x => <OptionItemModel> {'display': x.title, 'value': x.id});

    const onlyActive = this.onlyActiveControl?.value;
    if (onlyActive) {
      this.selectedLicenseeIdsControl.disable();
      this.selectedLicenseeIdsControl.setValue([]);
    } else {
      this.selectedLicenseeIdsControl.enable();
    }

    // Update selected licensee IDs
    const licenseeIds = selectAll ? this.getAllLicenseeIds() : this.selectedLicenseeIdsControl.value;
    const availableLicenseIds = licenseeIds.filter(licenseeId => this.licenseeOptionList.some(l => l.value === licenseeId));
    if (this.selectedLicenseeIdsControl) {
      this.selectedLicenseeIdsControl.setValue(availableLicenseIds);
    } else {
      this.selectedLicenseeIdsControl = new FormControl(availableLicenseIds);
    }

  }

  getLicensees() {
    this.licenseeService.getUserLicensees().subscribe(result => {
      this.licenseeList = result;
      this.updateLicenseeOptionList(false);
    }, error => {
      console.error(error);
      this.notificationService.errorNotify('Ошибка получения лицензиатов');
    });
  }

  getOrganizationsTurnover() {
    this.supportService.getOrganizationsTurnover(this.filter).subscribe(result => {
      this.loaderService.display(true);
      this.organizationsTurnover = result;

      //TODO: represent different currencies in total commision, for now the first currency
      const firstCurrency = this.organizationsTurnover.organizations[0]?.currency;
      this.currency = firstCurrency;
    }, error => {
      this.loaderService.display(false);
      this.notificationService.errorNotify(error);
    });
  }

  /** Sets period starting from the dateFrom start of the day till dateTo end of the day */
  setPeriod(dateRange: DateRange) {
    this.searchForm.controls['Period'].setValue(dateRange);
  }

  setTodayPeriod(): void {
    this.setPeriod(DateHelper.getTodayDateRange());
  }

  setYesterdayPeriod(): void {
    this.setPeriod(DateHelper.getYesterdayDateRange());
  }

  setCurrentWeekPeriod(): void {
    this.setPeriod(DateHelper.getCurrentWeekDateRange());
  }

  setPreviousWeekPeriod(): void {
    this.setPeriod(DateHelper.getPreviousWeekDateRange());
  }

  setCurrentMonthPeriod(): void {
    this.setPeriod(DateHelper.getCurrentMonthDateRange());
  }

  setPreviousMonthPeriod(): void {
    this.setPeriod(DateHelper.getPreviousMonthDateRange());
  }

  onSubmit() {
    this.filter = new OrganizationTurnoverFilter();
    this.filter.licenseeIds = this.searchForm.value.LicenseeIds;
    this.filter.onlyActive = this.searchForm.value.OnlyActive;
    this.filter.period = this.searchForm.value.Period;

    const maxLicenseeCount = 10;
    if (this.searchForm.value.LicenseeIds && this.searchForm.value.LicenseeIds.length > maxLicenseeCount) {
      this.notificationService.errorNotify(`Максимальное количество лицензиатов составляет ${maxLicenseeCount}`);
      return;
    }
    this.getOrganizationsTurnover();
  }
}
