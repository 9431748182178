import { Component, OnInit } from '@angular/core';
import { LicenseeService } from '@services/licensee.service';
import { Router } from '@angular/router';
import {AppInformationService} from '@services/app-information.service';
import { MenuService, AuthService, LoaderService, AuthGuard } from 'tsuz-common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  showLoader: boolean;
  showFullWidth: boolean;
  showMenu = false;
  collapseMenu = false;
  constructor(
    private readonly authService: AuthService,
    private readonly apiInformationService: AppInformationService,
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
    private authGuard: AuthGuard,
    private readonly router: Router,
    private readonly menuService: MenuService,
  ) {}

  ngOnInit() {
    this.authService.authNavStatus$.subscribe(status => {
      this.showMenu = status;
      if (status) {
        this.setLicenseeType();
      }
    });
    
    this.menuService.status.subscribe((val: boolean) => {
      this.collapseMenu = val;
    });

    this.apiInformationService.initVersionCheck();
  }

  async setLicenseeType() {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    this.loaderService.statusFullWithLoader.subscribe((val: boolean) => {
      this.showFullWidth = val;
    });
  }
}
