<h2 mat-dialog-title>Статус заявки</h2>

<mat-dialog-content>
  <div *ngIf="result$ | async as request; else loadingOrError">
    <div class="img-status"
         [ngClass]="{'img-status__new':request.state==1,
                     'img-status__process-binding':request.state==2,
                     'img-status__bound':request.state==3,
                     'img-status__rejected':request.state==4,
                     'img-status__unbound':request.state==5}"></div>
    <p>
      {{request.state | platformBindingRequestStateToString}}
    </p>
  </div>

  <ng-template #loadingOrError>
    <div *ngIf="errorLoading$ | async as error; else loading">
      {{error}}
    </div>
    <ng-template #loading>
      Ждем ответ от сервера...
    </ng-template>

  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button
          color="primary"
          (click)="onClose()">
    Список заявок
  </button>
</mat-dialog-actions>