import { DateRange, NumberRange, PaginationFilter, ITimeZoneId } from 'tsuz-common';

export class PeopleDealFilter extends PaginationFilter implements ITimeZoneId {
  licenseeId: string;
  query: string;
  operatorQuery: string;
  empquery: string;
  clientQuery: string;
  measureUnitValue = new NumberRange('measureUnitValueFrom', 'measureUnitValueTo');
  sum = new NumberRange('summFrom', 'summTo');
  dealNumber: string;
  pointIds: string[];
  state: string;
  dateRange = new DateRange();
  
  /**
   * TimeZone ID for filters to convert user input dates into UTC dates.
   * Notes:
   * 1. TimeZone Offset is for licensee timezone which can be different from user timezone
   * 2. Make it via get() and set() to avoid including into query params
   */
  private _timeZoneId: string = null;
  public get timeZoneId(): string { return this._timeZoneId; }
  public set timeZoneId(value: string) { this._timeZoneId = value; }
}
