<div class="nominal-account-connect-on-component
            holding-structure--flex-container"
      *ngIf="!isHidden">
  <app-waiting
    [showSpinner] = "isLoading"
    [errorHasHappened]="hasError"
    [errorMessage]="error"
    class="holding-structure--flex-container holding-structure--flex-grow-1">
    <div class="holding-structure--flex-container 
                holding-structure--flex-row
                holding-structure--flex-center
                holding-structure--flex-grow-big
                nominal-account-connect-on-component--content"> 

      <div class="holding-structure--flex-column
                  holding-structure--flex-grow-1">
        <div class="holding-structure--flex-row">
          <div class="holding-structure--flex-column
                      holding-structure--flex-center
                      holding-structure--flex-grow-1
                      holding-structure--flex-shrink-2">
            <div class="nominal-account-connect-on-component--logo"></div>
          </div>
          <div class="holding-structure--flex-column
                      holding-structure--flex-center
                      nominal-account-connect-on-component--info
                      holding-structure--flex-grow-2
                      holding-structure--flex-shrink-1">
            <div class="nominal-account-connect-on-component--info-big-label holding-structure--flex-row">
              Подключите номинальный счет
            </div>
            <div class="nominal-account-connect-on-component--info-low-label holding-structure--flex-row">
              Опция позволяет подключить механизм кешпуллинга.
            </div>
            <div class="nominal-account-connect-on-component--info-low-label holding-structure--flex-row">
              За дополнительной информацией обращайтесь к вашему менеджеру ЦУЗ
            </div>
          </div>
        </div>

      </div> 

      <div class="holding-structure--flex-column
                  holding-structure--flex-center
                  holding-structure--flex-grow-1">
        <div class="holding-structure--flex-row
                    holding-structure--flex-right
                    relative
                    hover-list__wrapper">
          <div class="nominal-account-connect-on-component--button-container
                      holding-structure--flex-row
                      holding-structure--flex-right">
            <div class="nominal-account-connect-on-component--button">
              <span class="nominal-account-connect-on-component--button-caption">Контакты менеджера ЦУЗ</span>
            </div>
            <div 
            class="hover-list absolute nominal-account-connect-on-component--info-popup"
            >
            <ul>
              <li class="hover-list__li"><a href="mailto:finnance@asumet.ru">Финансовая служба: finnance@asumet.ru</a>
              </li>
              <li class="hover-list__li"><a href="mailto:support@asumet.ru">Техническая поддержка: support@asumet.ru</a>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>

    </div>
  </app-waiting>
</div>
