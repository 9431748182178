import {HttpParams} from '@angular/common/http';
import {TransactionTypes} from './transaction-types.enum';
import {DateRange, PaginationFilter} from 'tsuz-common';

export class DocumentsFilter extends  PaginationFilter  {

  licenseeId = '';
  organizationName = '';
  absTransactionId = '';
  sumFrom?: number = null;
  sumTo?: number = null;
  dateRange?: DateRange;
  type: TransactionTypes;

  public toString = (): string => {
    let params = new HttpParams();
    params = this.addParam(params, this.page, 'page');
    params = this.addParam(params, this.take, 'take');
    params = this.addParam(params, this.licenseeId, 'licenseeId');
    params = this.addParam(params, this.absTransactionId, 'absTransactionId');
    params = this.addParam(params, this.sumFrom, 'oborotDebitFrom');
    params = this.addParam(params, this.sumTo, 'oborotDebitTo');
    params = this.addParam(params, this.type, 'type');
    if (this.dateRange && this.dateRange.dateFrom) {
      params = this.addParam(params, this.dateRange.dateFromAsUtcString, 'startDate');
    }
    if ( this.dateRange && this.dateRange.dateTo) {
      params = this.addParam(params,  this.dateRange.dateToAsUtcString, 'endDate');
    }
    return params.toString();
  };

  addParam(params: HttpParams, value: any, paramName: any): HttpParams {
    if (value) {
      params = params.append(paramName, value);
    }
    return params;
  }
}
