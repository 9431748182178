import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-passport-info-input-control',
  templateUrl: './passport-info-input-control.component.html',
  styleUrls: ['./passport-info-input-control.component.scss']
})
export class PassportInfoInputControlComponent implements OnInit {

  passportNumberModel: string;
  selectedCitizenshipModel: any;
  citizenshipsList: any[];
  mask: string;
  regexp: RegExp;
  placeholder: string = 'Номер и серия паспорта';
  hasValidationError: boolean;

  @Input()
  licenseeType: string = "Default";

  @Input()
  passportNumberIsRequired: boolean;

  @Input()
  formName: string;

  @Input()
  passportNumber: string;

  @Input()
  citizenship: string;

  @Input()
  errorMessages: object;

  @Output() citizeshipChangedEvent = new EventEmitter<string>();
  @Output() passportNumberChangedEvent = new EventEmitter<string>();
  @Output() passportNumberValidationChangedEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.initPassportCheckList();
    this.passportNumberModel = this.passportNumber;
    const comparator = i => i.value === this.citizenship;
    this.citizenship = this.citizenshipsList.some(comparator) ?
      this.citizenship : 'RUS';
    
    const selectedCitizenship = this.citizenshipsList.find(comparator);
    this.selectedCitizenshipModel = selectedCitizenship.value;
    this.onCitizenshipChanged(selectedCitizenship);
  }

  initPassportCheckList() {
    // Список кодов закрепленных стран
    const pinnedCountryCodes: Array<string> = ['RUS'];

    // Сортируем список стран по названию (title)
    const sortedCountries = environment.passportCountry.sort((a, b) => {
      const aTitle = a.title.toLowerCase();
      const bTitle = b.title.toLowerCase();

      if (b.value === 'Other' || a.value === 'Other' ) {
        return 1;
      } else if (aTitle < bTitle) {
        return -1;
      } else if (aTitle > bTitle) {
        return 1;
      }
      return 0;
    });

    const pinnedCountries = [],
      unpinnedCountries = [];

    // Пробегаемся по отсортированному списку стран и распределяем по двум группам,
    // чтобы РФ была сверху списка, остальыне по алфавиту
    sortedCountries.forEach(country => {
      if (pinnedCountryCodes.includes(country.value)) {
        pinnedCountries.push(country);
      } else {
        unpinnedCountries.push(country);
      }
    });

    // Собираем страны в единый список
    this.citizenshipsList = pinnedCountries.concat(unpinnedCountries);
  }

  onCitizenshipChanged(value: any) {
    this.mask = value.ngmask;
    this.regexp = value.regex;
    this.placeholder = value.message;
    this.citizeshipChangedEvent.emit(value.value);
    this.passportNumberChangedEvent.emit(this.passportNumberModel);
    this.validatePassportSeries();
  }

  onPassportNumberChanged(value: any) {
    this.passportNumberChangedEvent.emit(this.passportNumberModel);
    this.validatePassportSeries();
  }


  validatePassportSeries() {
  // Проверка данных на паттерн страны
    let hasError = this.hasValidationError;
    const regexp = new RegExp(this.regexp);
    const matchedValue = this.passportNumberModel?.match(
      this.regexp);
    
    hasError =
      !regexp.test(this.passportNumberModel) ||
      (matchedValue?.length > 0 &&
        matchedValue[0].length !== this.passportNumberModel?.length);
    
    hasError = this.passportNumberModel?.length === 0 &&
      !this.passportNumberIsRequired ? false : hasError;

    this.hasValidationError = hasError;

    this.passportNumberValidationChangedEvent.emit(hasError);
  }
}
