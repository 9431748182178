import { Pipe, PipeTransform } from '@angular/core';
import { RegistryStatuses } from '../../../core/enums/registry-statuses';

@Pipe({
  name: 'registryStatusesClass'
})
export class RegistryStatusesClassPipe implements PipeTransform {
  transform(value: RegistryStatuses, args?: any): string {
    switch (value) {
      case RegistryStatuses.Generated: return "generated";
      case RegistryStatuses.Sending: return "sending";
      case RegistryStatuses.SuccessfullySent: return "successfully-sent";
      case RegistryStatuses.FailureSent: return "failure-sent";
      case RegistryStatuses.RnkoApplied: return "rnko-applied";
      case RegistryStatuses.RnkoRejected: return "rnko-rejected";
      case RegistryStatuses.RnkoPartialApplied: return "rnko-partial-applied";
    }
  }
}
