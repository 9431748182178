import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RnkoRegisterData } from '../../../models/widgets/rnko-register-data';
import { WidgetService } from '../../../services/widget.service';
import { WidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-rnko-register-widget',
  templateUrl: './rnko-register-widget.component.html',
  styleUrls: ['./rnko-register-widget.component.scss', '../base-widget/base-widget.component.scss']
})
export class RnkoRegisterWidgetComponent
implements WidgetComponent {

  public lastRegisterData: RnkoRegisterData = {} as RnkoRegisterData;
  public isLoadingCompleted: boolean = false;
  public hasError: boolean = false;
  public error: string;
  
  constructor(
    private widgetService: WidgetService,
    private datePipe: DatePipe,
  ) { }

  async ngOnInit() {
    const lastRegisterData = await this.widgetService.getRnkoRegisterData().catch(err => {
      this.error = err;
      this.hasError = true;
    });

    this.lastRegisterData = lastRegisterData || this.lastRegisterData;
    this.isLoadingCompleted = true;
  }
}
