import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import {catchError } from 'rxjs/operators';
import {throwError as observableThrowError, Observable} from 'rxjs';
import { JwtResponse } from '../models/auth/jwt-response';

@Injectable()
export class JwtAuthService {

    apiUrl = this.environment.apiUrl;

    constructor(private http: HttpClient,
        @Inject('environment') private environment: any,
        ) {

        }

    refreshToken(accessToken: string, refreshToken: string) : Observable<JwtResponse> {
        const body = { accessToken, refreshToken };
        return this.http.post<JwtResponse>(this.apiUrl + '/auth/refresh', body).pipe(
          catchError(this.handleError));;
    }

    handleError(error: HttpErrorResponse) {
        const parsedError = Object.assign({}, error, { error: JSON.stringify(error.error) });
        const message = JSON.parse(parsedError?.error).message || 'Ошибка сервера';
        const code = error.status;
        const errorBody = {
            message,
            code
        };
        return observableThrowError(errorBody);
    }

    login(login: string, password: string) : Observable<JwtResponse> {
        const body = { login, password };
        return this.http.post<JwtResponse>(this.apiUrl + '/auth/login', body)
            .pipe(catchError(this.handleError));
    }

    logout() : Observable<any> {
        return this.http.put(this.apiUrl + '/auth/revoke', {})
            .pipe(catchError(this.handleError));
    }

}