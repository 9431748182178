import { AbstractControl, ValidatorFn } from '@angular/forms';
import { environment } from '../../../environments/environment';

//Валидатор даты
//isNeedAge - проверка на возраст
//isNeedAbstract - обязательное поле
//isCp - валлидация даты не больше сегодняшней
export function ValidateDate(isNeedAge: boolean, isNeedAbstract: boolean, isCp: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value) {
            let date = String(control.value);
            if (!isNeedAbstract && !control.value){
                return null;
            }
            if (!isDate(date)) {
                return { 'invalidDate': true };
            }
            if (date.length == 10) {
                let parseDate = new Date(`${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`);
                if (String(parseDate) === "Invalid Date" || Number(date.substring(6, 10)) < environment.minYear) {
                    return { 'invalidDate': true };
                }
                else{
                    let today = new Date();
                    if (parseDate > today && isCp){
                       return { 'biggerCurrentDate': true };
                    }
                    else{
                        const ageDifMs = Date.now() - parseDate.getTime();
                        const ageDate = new Date(ageDifMs); // miliseconds from epoch
                        if (Number(Math.abs(ageDate.getUTCFullYear() - 1970)) < 18 && isNeedAge)
                        {
                            return { 'ageError': true };
                        }
                    }
                }
            }
            else{
                return null;
            }
        }
        //return null;
    };
}

function isDate(str) {
    if (str === "[object Object]")
        return true;
    if (str == undefined) { return false; }
    var parms = str.split(/[\.\-\/]/);
    var yyyy = parseInt(parms[2], 10);
    if (yyyy < environment.minYear) { return false; }
    var mm = parseInt(parms[1], 10);
    if (mm < 1 || mm > 12) { return false; }
    var dd = parseInt(parms[0], 10);
    if (dd < 1 || dd > 31) { return false; }
    var dateCheck = new Date(yyyy, mm - 1, dd);
    return (dateCheck.getDate() === dd && (dateCheck.getMonth() === mm - 1) && dateCheck.getFullYear() === yyyy);
};
