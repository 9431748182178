
import {debounceTime} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {RegistriesService} from '@services/registries.service';
import {RegistriesFilter} from '../../models/registries/registries-filter';
import {IRegistry} from '../../models/registries/registry';
import {CreateRegistry} from '../../models/registries/create-registry';
// tslint:disable-next-line: import-blacklist
import {Subject} from 'rxjs';
import {saveAs} from 'file-saver/FileSaver';
import {LoaderService, NotificationService} from 'tsuz-common';
import {RegistryStatuses} from '../../core/enums/registry-statuses';
import {Paginated} from '../../core/models/paginated';
import {RegistriesViewState} from './registries-view-state.enum';

@Component({
  selector: 'app-registries',
  templateUrl: './registries.component.html',
  styleUrls: ['./registries.component.scss'],
  host: { class: 'registries'}
})
export class RegistriesComponent implements OnInit {

  filter: RegistriesFilter;
  registries: Paginated<IRegistry[]>;
  createRegistryDateRange: Date[];
  state: RegistriesViewState;
  RegistryStatuses = RegistryStatuses;
  ViewState = RegistriesViewState;
  savingRegistry = false;
  private searchTextChanged = new Subject<string>();

  constructor(private registriesService: RegistriesService,
              private loaderService: LoaderService,
              private notificationService: NotificationService) {
      this.filter = new RegistriesFilter();
      this.state = RegistriesViewState.none;
  }

  ngOnInit() {
    this.getRegistries();
    this.searchTextChanged.pipe(debounceTime(1000)).subscribe(() => this.getRegistries());
  }

  getRegistries() {
    this.registriesService.GetRegistries(this.filter).subscribe(
      result => {
        this.registries = result as Paginated<IRegistry[]>;
        this.filter.total = this.registries.total;
      },
      error => console.error(error)
    );
  }

  save() {
    const request = new CreateRegistry();
    request.dateFrom = this.createRegistryDateRange[0];
    request.dateTo = this.createRegistryDateRange[1];
    this.savingRegistry = true;
    this.registriesService.Create(request).subscribe(
      result => {
        this.state = RegistriesViewState.none;
        this.saveToFileSystem(result);
        this.getRegistries();
        this.notificationService.successNotify('Реестр успешно создан', 'Информация');
      },
      error => {
        this.notificationService.errorNotify(error.message);
      }
    ).add(() => {
      this.savingRegistry = false;
    });
  }

  search() {
    this.searchTextChanged.next();
  }

  download(registry: IRegistry) {
    this.loaderService.displayFullWidth(true);
    this.registriesService.download(registry.id).subscribe(
      result => this.saveToFileSystem(result),
      error => {
        this.loaderService.displayFullWidth(false);
        this.notificationService.errorNotify(error.message);
      },
      () => {
        this.loaderService.displayFullWidth(false);
      }
    );
  }

  send(registry: IRegistry) {
    this.getRegistries();
  }

  resetFilters() {
    this.filter = new RegistriesFilter();
    this.getRegistries();
  }

  private saveToFileSystem(file: { filename: string, data: Blob }) {
    saveAs(file.data, file.filename);
  }

  onChangeFilterRegistryStatus(registryStatus: RegistryStatuses) {
    this.filter.status = registryStatus;
    this.getRegistries();
  }
}
