
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '../services/Cache/cache-service';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const CACHABLE_URL = [
  {
    regex: new RegExp('\/licensee\/.+\/settings'),
    expireTime: 60000
  },
  {
    regex: new RegExp('\/Dictionary'),
    expireTime: 1440000
  }
];

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cache: CacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRequestCachable(req)) {
      return next.handle(req);
    }
    const cachedResponse = this.cache.get(req.url);
    const resetClientCache = req.headers.get('reset-client-cache');
    if (cachedResponse !== null && !resetClientCache) {
      return of(cachedResponse);
    }
    //удаляем заголовок запроса reset-client-cache
    const reqWithoutCustomHeader = req.clone({ headers: req.headers.delete('reset-client-cache') });
    return next.handle(reqWithoutCustomHeader).pipe(
      tap(event => {
        if (event instanceof HttpResponse && event.ok) {
          const cachableUrl = this.findCachableUrl(reqWithoutCustomHeader);
          this.cache.put(reqWithoutCustomHeader.url, event, cachableUrl.expireTime);
        }
      })
    );
  }
  private isRequestCachable(req: HttpRequest<any>) {
    const cachableUrl = this.findCachableUrl(req);
    return req.method === 'GET' && cachableUrl != null;
  }

  private findCachableUrl(request: HttpRequest<any>) {
    const url = request.url.replace(environment.apiUrl, ""); //remove server name from url

    const cachableUrl = CACHABLE_URL.find(x => x.regex.test(url));
    return cachableUrl;
  }
}
