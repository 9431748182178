import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ProductValidateResult} from '../../models/product/ProductValidateResult';
import {DealFilter} from '../../models/support/DealFilter';
import {PeopleDealFilter} from '../../models/peopledeal/PeopleDealFilter';
import {DealDraftViewModel} from '@models/peopledeal/DealDraftViewModel';
import {map, switchMap} from "rxjs/operators";
import {ILicenseeSetting, BaseHttpService} from "tsuz-common";
import {IProviderAttributes} from "../../models/peopledeal/IProviderAttributes";
import {IProvider} from "../../models/peopledeal/IProvider";
import {IDealSettings} from "../../models/licensee-setting/IDealSettings";
import {IPeopleDeal} from '../../models/peopledeal/IPagePeopleDeal';
import {IRequestStatePeopleDeal} from '../../models/peopledeal/IRequestStatePeopleDeal';
import { IDealDetailsViewModel } from '../../models/peopledeal/IDealDetailsViewModel';
import { IAvailableDealActions } from '../../models/peopledeal/AvailableActions';

@Injectable()
export class DealService {
  // Поля для оплаты
  notReqFields: string[] = [
    'PassportSeries',
    'ScanPassport',
    'Birthplace',
    'PassportIssuedBy',
    'RegistrationAddress',
    'Birthday',
    'DealNumber',
    'ScanDeal',
    'ScanPts',
    'ScanPsa',
    'DealDate',
    'LastName',
    'CardNumber',
    'MiddleName',
    'FirstName'
  ];

  constructor(private baseHttpService: BaseHttpService) {
  }

  // Округление суммы оплаты
  getConvertSum(sum: number) {
    const cent = 0.01;
    const sum2 = Number(Number(sum).toFixed(2));
    sum = sum2 >= sum ? sum2 : sum2 + cent;
    return sum;
  }

  getTotalSum(prod: any, settings: ILicenseeSetting): Observable<any> {
    let products = [];
    const setZeroIsteadNullForTrash = (products) => products.forEach(element => {
      element.trash = element.trash === null ? 0 : element.trash;
    });
    if (settings.hideWeight) {
      products = prod.filter(x => x.measureUnitPrice > 0);
    } else {
      products = prod.filter(x => x.measureUnitPrice > 0 && x.measureUnitValue > 0);
    }
    setZeroIsteadNullForTrash(products);
    return this.baseHttpService.post('/deals/products/amount', {products: products});
  }

  // Получение товаров
  getProducts(): Observable<any> {
    return this.baseHttpService.get('/licensees/current/products');
  }

  // Получение точек сотрудника
  getUserPoints(userId): Observable<any> {
    return this.baseHttpService.get('/users/' + userId + '/points');
  }

  // Получение точек тп
  getSupportPoints(): Observable<any> {
    return this.baseHttpService.get('/support/points');
  }

  // Получение провайдеров лицензиата
  getProviders(): Observable<any> {
    return this.baseHttpService.get('/licensees/current/providers');
  }

  getSbpMembers(): Observable<any> {
    return this.baseHttpService.get('/banks/sbp/members');
  }

  // Обновление оплаты
  update(dealNumber: number, formData: FormData): Observable<any> {
    this.cleanUpPoints(formData);
    return this.baseHttpService.put('/deals/' + dealNumber, formData);
  }

  // Создание оплаты
  create(formData: FormData): Observable<any> {
    this.cleanUpPoints(formData);
    return this.baseHttpService.post('/deals', formData).pipe(
      switchMap(
        createdDeal => this.deleteDraft()
          .pipe(
            map(resultDeleteDraft => createdDeal)
          )
      ));
  }

  // Здесь мы очищаем ненужные данные о склонениях названия точки по падежам
  cleanUpPoints(formData: FormData) {
    const settingsEntry = formData.get("settings");
    if (!settingsEntry) {
      return;
    }

    const settings = JSON.parse(settingsEntry.valueOf() as string);
    delete settings.pointName;
    delete settings.pointNameTitle;

    formData.set("settings", JSON.stringify(settings))
  }

  // Отмена оплаты
  cancel(id: string, concurrencyToken: string): Observable<any> {
    return this.baseHttpService.patch("/deals/" + id + '/state/canceled', {
      concurrencyToken: concurrencyToken
    });
  }

  //Включена ли работа с смз
  GetSelfEmployedUsingState(): Observable<boolean> {
    return this.baseHttpService.get('/api/SelfEmployed/IsEnabled');
  }

  // Инфо оплаты для редактирования
  getForEdit(id: string): Observable<DealDraftViewModel> {
    return this.baseHttpService.get('/deals/getedit/' + id);
  }

  // Получение сделки по id
  getDealById(id: string): Observable<IPeopleDeal> {
    return this.baseHttpService.get('/deals/' + id);
  }

  // Обновление классов по ответу из шлюза для активной страницы оплат
  updatePage(cart: any): Observable<IRequestStatePeopleDeal[]> {
    return this.baseHttpService.get('/deals/states', cart, null, 'dealIds');
  }

  // Получение количества оплат на согласовании
  getOnAcceptedCount(): Observable<any> {
    return this.baseHttpService.get('/deals/count?state=accepting');
  }
  // Инфо для деталей
  getInfo(id: string): Observable<IDealDetailsViewModel> {
    return this.baseHttpService.getOfT<IDealDetailsViewModel>('/deals/' + id + '/payment/details');
  }

  // Получение страницы оплат
  getPage(resultQuery: PeopleDealFilter): Observable<any> {
    return this.baseHttpService.get('/deals', resultQuery);
  }

  // Получение страницы оплат
  getPageSupport(filter: DealFilter): Observable<any> {
    return this.baseHttpService.get(`/support/deal`, filter);
  }

  //Получение оплат в формате XML
  getXmlList(dealFilter: PeopleDealFilter): Observable<any> {
    return this.baseHttpService
      .getFile('/deals/xml', dealFilter).pipe(
       map(res => {
        return {
          filename: "TSUZ_Deals.xml",
          data: res.body
        };
      }));
  }

  // Получение оплат в формате CSV
  getCsvList(dealFilter: PeopleDealFilter): Observable<any> {
    return this.baseHttpService
      .getFile('/deals/csv', dealFilter).pipe(
      map(res => {
        return {
          filename: 'TSUZ_Deals.csv',
          data: res.body
        };
      }));
  }

  // Получение оплат в формате CSV
  getExcelList(dealFilter: PeopleDealFilter): Observable<any> {
    return this.baseHttpService
      .getFile('/deals/excel', dealFilter).pipe(
      map(res => {
        return {
          filename: 'TSUZ_Deals.xlsx',
          data: res.body
        };
      }));
  }

  // Скачивание файлов
  downloadFile(id: string, type: number): Observable<any> {
    return this.baseHttpService
      .getFile('/download/' + id + '/' + type).pipe(
        map(res => {
          const filename = res.headers.get('content-disposition')
            .split(';')
            .find(n => n.includes('filename='))
            .replace('filename=', '')
            .replaceAll('\"', '')
            .trim();

          return {
            filename: filename.decodeBase64UTF8String(),
            data: res.body
          };

      }));
  }

  // Проверка оплаты на возможность редактирования
  getAvailableActions(id: string): Observable<IAvailableDealActions> {
    return this.baseHttpService.get('/deals/' + id + '/access/');
  }

  // Проверка на мобильный платеж и одну страницу оплату
  checkOneScreen(
    settingsList: IDealSettings,
    dealProvider: IProvider,
    prod: any,
    listProd: any[]
  ): [boolean, string] {
    let result: [boolean, string] = [false, 'Далее'];
    if (settingsList.licenseeType === 'CarPrice') {
      let catched = 0;
      for (let i = 0; i < this.notReqFields.length; i++) {
        if (
          dealProvider.fields.findIndex(
            x =>
              x.name === this.notReqFields[i] &&
              (x.isRequired || x.isGateRequired)
          ) > -1
        ) {
          catched++;
        }
      }
      if (
        catched === 0 &&
        dealProvider.fields.findIndex(
          x => x.name === 'Phone' && (x.isRequired || x.isGateRequired)
        ) > -1
      ) {
        result = [true, 'Выплатить'];
      }
      for (let i = 0; i < prod.length; i++) {
        const index = listProd.findIndex(x => x.id === prod[i].productId);
        if (index > -1 && listProd[index].oneScreen) {
          result = [true, 'Выплатить'];
        }
      }
    }
    return result;
  }

  // Инициализация аттрибутов
  initAttribute(
    dealProvider: IProvider,
    settingList: ILicenseeSetting
  ): IProviderAttributes {
    const user: IProviderAttributes = {
      phone: dealProvider.fields.find(x => x.name === 'Phone'),
      promo: dealProvider.fields.find(x => x.name === 'Promo'),
      passportSeries: dealProvider.fields.find(x => x.name === 'PassportSeries'),
      scanPassport: dealProvider.fields.find(x => x.name === 'ScanPassport'),
      birthplace: dealProvider.fields.find(x => x.name === 'Birthplace'),
      passportIssuedBy: dealProvider.fields.find(x => x.name === 'PassportIssuedBy'),
      registrationAddress: dealProvider.fields.find(x => x.name === 'RegistrationAddress'),
      birthday: dealProvider.fields.find(x => x.name === 'Birthday'),
      dealNumber: dealProvider.fields.find(x => x.name === 'DealNumber'),
      scanDeal: dealProvider.fields.find(x => x.name === 'ScanDeal'),
      description: dealProvider.fields.find(x => x.name === 'Description'),
      scanPts: dealProvider.fields.find(x => x.name === 'ScanPts'),
      scanPsa: dealProvider.fields.find(x => x.name === 'ScanPsa'),
      dealDate: dealProvider.fields.find(x => x.name === 'DealDate'),
      lastName: dealProvider.fields.find(x => x.name === 'LastName'),
      cardNumber: dealProvider.fields.find(x => x.name === 'CardNumber'),
      middleName: dealProvider.fields.find(x => x.name === 'MiddleName'),
      firstName: dealProvider.fields.find(x => x.name === 'FirstName'),
      sbpMemberId: dealProvider.fields.find(x => x.name === 'SbpMemberId'),
    };
    let providerAttributes = user as IProviderAttributes;
    if (settingList.licenseeType === 'CarPrice') {
      providerAttributes.dealDate.title = 'Дата договора';
      providerAttributes.dealNumber.title = 'Номер договора';
    }
    return providerAttributes;
  }

  // Расчет возраста
  calculateAge(birthday): number {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  // Алгоритм Луна для карты
  algLun(event: any): any {
    if (event && event.length >= 16) {
      const card = event;
      let summ = 0;
      const card_number = String(card);
      if (card_number.length > 0) {
        for (let i = 0; i < card_number.length; i++) {
          let m = Number(card_number[i]);
          if ((card_number.length - i) % 2 === 0) {
            m = m * 2;
            if (m > 9) {
              m = m - 9;
            }
          }
          summ = summ + m;
        }

        return (
          summ % 10 !== 0 && (event.length >= 16 || event.length <= 19)
        );
      }
    }
    return false;
  }

  // Валидатор для карты по Алгоритму Луна
  cardChecker(control: FormControl): any {
    const card = control.value;
    let summ = 0;
    const card_number = String(card);
    if (card_number.length > 0) {
      for (let i = 0; i < card_number.length; i++) {
        let m = Number(card_number[i]);
        if ((card_number.length - i) % 2 === 0) {
          m = m * 2;
          if (m > 9) {
            m = m - 9;
          }
        }
        summ = summ + m;
      }

      if (summ % 10 === 0) {
        return null;
      } else {
        return {'InvalidCardNumber': true};
      }
    }
  }

  // проверяет правильность данных введенный продуктов сделки
  checkProducts(
    products: any[],
    settingList: ILicenseeSetting,
    total: number
  ): ProductValidateResult {
    if (products.length < 1 && total <=0 ){
      return new ProductValidateResult(false);
    }

    if (settingList.onlyIdenticalProduct && products.some(x => x.productId !== products[0].productId)) {
      return new ProductValidateResult(false, 'Ввод различных видов товаров запрещен');
    }

    if (settingList.oneProduct && products.length !== 1) {
      return new ProductValidateResult(false, 'Запрещено добавление более чем одного продукта.');
    }

    for (let i = 0; i < products.length; i++) {
      if (products[i].productId == null || products[i].measureUnitPrice < 0.001){
        return new ProductValidateResult(false);
      }

      if (!settingList.hideTrashPercent) {
        if (products[i] == null || products[i].trash < 0 || products[i].trash > 99.99) {
          return new ProductValidateResult(false);
        }
      }

      if (!settingList.hideWeight) {
        if (products[i] == null || products[i].measureUnitValue <= 0) {
          return new ProductValidateResult(false);
        }
      }
    }

    return new ProductValidateResult(true);
  }

  // Сохранение черновика сделки
  setDraft(draft: DealDraftViewModel): Observable<any> {
    return this.baseHttpService.post("/DealDraft", draft);
  }

   // Получение черновика сделки
   getDraft(): Observable<DealDraftViewModel> {
    return this.baseHttpService.get("/DealDraft");
  }

  // Удаление черновика сделки
  deleteDraft(): Observable<string> {
    return this.baseHttpService.delete("/DealDraft");
  }
}
