import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TurnoverData } from '../../../models/widgets/turnover-data';
import { WidgetService } from '../../../services/widget.service';
import { WidgetComponent } from '../base-widget/base-widget.component';

@Component({
  selector: 'app-holding-widget',
  templateUrl: './holding-widget.component.html',
  styleUrls: ['./holding-widget.component.scss', '../base-widget/base-widget.component.scss']
})
export class HoldingWidgetComponent
  implements WidgetComponent{
  public turnoverData: TurnoverData = {} as TurnoverData;
  
  public isLoadingCompleted: boolean = false;
  
  public hasError: boolean = false;

  public error: string;

  constructor(
    private widgetService: WidgetService,
    private datePipe: DatePipe
  ) {
  }
  
  async ngOnInit() {
    const turnover = await this.widgetService.getBalance().catch(err => {
      this.hasError = true;
      this.error = err;
    });
    this.turnoverData = turnover as TurnoverData || this.turnoverData;
    this.isLoadingCompleted = true;
  }

  getFormattedCurrentDate() {
    const currentDate = new Date();
    return this.datePipe.transform(currentDate, 'dd.MM');
  }

  getFormattedLastDayDate() {
    const currentDate = new Date();
    return this.datePipe.transform(currentDate.setDate(currentDate.getDate() - 1), 'dd.MM');
  }

  getFormattedCurrentMonthRangeDate() {
    const currentDate = new Date();
    const firstDayOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
    return `${this.datePipe.transform(firstDayOfMonthDate, 'dd.MM')} - ${this.datePipe.transform(currentDate, 'dd.MM.yyyy')}`;
  }

  getHeader(): string {
    if (!this.isLoadingCompleted) {
      return '';
    }
    return this.turnoverData.isHolding ? 'Холдинг' : 'Компания';
  }

  hidePrimaryButton() {
    // wait for Holding Structure component
    //return !this.turnoverData.isHolding;
    return true;
  }
}
