import { Injectable } from '@angular/core';

import { ScanType } from '../enums/scan-type.enum';

@Injectable()
export class ScanDocumentsService {

  private readonly scanTypes = [
    { type: ScanType.Psa, description: "Скан ПСА" },
    { type: ScanType.Pts, description: "Скан ПТС" },
    { type: ScanType.Passport, description: "Скан паспорта" },
    { type: ScanType.Contract, description: "Скан договора" }];

  getScanDocumentDescription(type: ScanType): string {
    let scanType = this.scanTypes.find(x => x.type === type);
    if (scanType) return scanType.description;
    return "Скан";
  }

}
