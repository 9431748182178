<div class="enter-page__background">
  <div class="enter-page-wrapper enter-page__wrapper">
    <form id="id1538392558" *ngIf="!isApproved && !isTemp && !isAuthRedirect" [formGroup]="loginForm" (ngSubmit)="onSubmit()" #f="ngForm">
      <div class="enter-page__header">
        <tsuz-default-logo></tsuz-default-logo>
      </div>
      <div class="enter-page__inputs">
        <div id="id1538391989" class="message report report--false text-center" *ngIf="isError">{{errorMessage}}</div>
        <div class="enter-page__input-item login-input">
          <label for="id1538391851" class="label enter-page__label">Введите логин или номер телефона (в формате: +7)</label>
          <input id="id1538391851" #phoneOrLoginInput autofocus [ngClass]="{'nonvalidate': isErrorPassPattern == false,'validate': isErrorPassPattern == true}"
            autofocus
            event-keydown="keydown"
            [textMask]="{mask: onlyValitadedFormSend ? changeMask : changeMaskForTesting}"
            (focus) = "onFocusGet()"
            (click) = "onFocusGet()"
            (ngModelChange)="onChangeInput($event)"
            formControlName="PhoneOrLogin"
            required autocomplete="tel" type="text" class="input enter-page__input enter-page__input--login validate" title="Для ввода логина сотрите маску.
Для ввода телефона введите символ '+', появится маска для ввода телефона" />
          <div class="message report report--false text-center" *ngIf="!isErrorPassPattern">
              Неверный телефон/логин
          </div>
          <validation-errors-output
          [errorsDictionary]="errorsMessages"
          [fieldName]="'phoneOrLogin'">
          </validation-errors-output>
        </div>
        <div
        class="enter-page__input-item password-input-container"
        style="margin-top: 10px">
          <label for="id1538391934" class="label enter-page__la bel">Введите ваш пароль</label>
          <div class="relative \r">
            <show-hide-password size="sm">
              <input id="id1538391934" formControlName="Password" [ngClass]="{'input--nonvalidate': loginForm.controls.Password.hasError('minlength') == true,'input--validate': loginForm.controls.Password.hasError('minlength') == false}"
                event-keydown="keydown" required [attr.minlength]="passwordMinLength" [attr.maxlength]="passwordMaxLength" required autocomplete="current-password"
                type="password" class="input enter-page__input enter-page__input--pass">
                <validation-errors-output
                [errorsDictionary]="errorsMessages"
                [fieldName]="'password'">
                </validation-errors-output>
            </show-hide-password>
          </div>
        </div>
      </div>
      <div class="enter-page__btn-wrapper">
        <button
        id="id1538392015"
        class="btn btn--big"
        [ngClass]="{'btn--active': !onlyValitadedFormSend || (loginForm.valid == true && isErrorPassPattern == true) }"
        [disabled]="onlyValitadedFormSend && loginForm.invalid"
        value="Submit"
        type="submit">
        Продолжить
      </button>
      </div>
    </form>
    <form id="id1538392580" *ngIf="isApproved" [formGroup]="approvedForm" (ngSubmit)="onSubmitCode()" #fa="ngForm">
      <div class="enter-page__header">
        <app-logo [loadingLogo]="false"></app-logo>
      </div>
      <div class="enter-page__inputs">
        <div class="enter-page__input-item2">
          <label for="id1538392068" for="" class="label enter-page__label">Введите смс-код подтверждения</label>
          <input id="id1538392068" autofocus #autofocus [ngClass]="{'input--nonvalidate': !approvedForm.value.Code || (approvedForm.value.Code && approvedForm.value.Code < 4),'input--validate':approvedForm.value.Code && approvedForm.value.Code >=4}"
            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;" required minlength="4" maxlength="8" type="text" mask="000000"
            formControlName="Code" class="enter-page__sms-code input enter-page__input text-center "
            [attr.disabled]="maxAttemptsCountExceeded ? 'disabled' : null">
            <div class="message report report--false report--valid text-center" *ngIf="approvedForm.controls.Code.hasError('minlength')">Код подтверждения должен
              состоять из 4-6 символов</div>
            <div id="id1538392107" class="message report report--false text-center" *ngIf="isErrorApproved">
              *Неправильный код подтверждения
            </div>
            <div id="id1538392108" class="message report report--false text-center" *ngIf="maxAttemptsCountExceeded">
              *Превышено количество попыток ввода кода подтверждения
            </div>
            <div id="id1538392133" class="enter-page__sms-code-message">
                <span>Не получили код подтверждения?</span>
                <br>
                <a id="id1538392164" class="sms-code-anchor" *ngIf="timerStopped" (click)="sendCode()">Отправить СМС еще раз</a>
                <span id="id1538392223" *ngIf="!timerStopped">Отправить повторно через {{secondTxt}} сек.</span>
          </div>
        </div>
      </div>
      <div class="enter-page__btn-wrapper">
        <button
        id="id1538392388"
        class="btn btn--big"
        [ngClass]="{'btn--active': approvedForm.valid || !onlyValitadedFormSend}"
        event-click="click"
        [disabled]="onlyValitadedFormSend && approvedForm.invalid"
        type="submit"
        [attr.disabled]="maxAttemptsCountExceeded ? 'disabled' : null">
        Войти
        </button>
      </div>
    </form>

    <div *ngIf="isAuthRedirect">
      <label class="login-redirect-message-label">
        В связи с доработкой сервиса мы перенесли функционал, которым Вы ранее пользовались на
      </label>
        <a [href]="redirectUrl" class="login-redirect-link">{{redirectUrl}}</a>
      <label class="login-redirect-message-label">
        Для входа в личный кабинет по новому адресу используйте, пожалуйста, Ваш логин и пароль или
      </label>
      <a [routerLink]="" (click)="onBackToLoginPage()" class="login-redirect-link">попробуйте зайти с использованием другой учетной записи</a>
    </div>
    
    <form *ngIf="isTemp" [formGroup]="passForm" (ngSubmit)="onSubmitPass()" #pasF="ngForm">
      <div class="enter-page__header">
        <div class="enter-page__logo"></div>
      </div>
      <div class="enter-page__inputs">
        <div class="enter-page__input-item">
          <label for="id1538392415" class="label enter-page__label">Введите новый пароль</label>
          <input id="id1538392415" autofocus [ngClass]="{'input--nonvalidate': passCtrl.invalid == true,'input--validate': passCtrl.invalid == false}"
            type="text" class="input enter-page__input" event-keydown="keydown" formControlName="Pass" type="password">

          <div *ngIf="passCtrl.touched && (passCtrl.hasError('minlength') || passCtrl.hasError('maxlength'))"
            class="message report report--false text-center">Пароль должен состоять из 6-20 символов</div>
        </div>
        <div class="enter-page__input-item">
          <label for="id1538392440" class="label enter-page__label">Повторите новый пароль</label>
          <input id="id1538392440" [ngClass]="{'input--nonvalidate': reenterPassCtrl.invalid == true,'input--validate': reenterPassCtrl.invalid == false}" type="text"
            class="input enter-page__input" event-keydown="keydown" formControlName="ReEnterPass" type="password">

          <div *ngIf="reenterPassCtrl.touched && (reenterPassCtrl.hasError('minlength') || reenterPassCtrl.hasError('maxlength'))"
            class="message report report--false text-center">Пароль должен состоять из 6-20 символов</div>
          <div *ngIf="passCtrl.touched && reenterPassCtrl.dirty &&  passForm.errors?.mismatch as error"
            class="message report report--false text-center">Пароли не совпадают</div>
          <div id="id1538392469" class="message report report--false text-center" *ngIf="isErrorPass">{{errorPassMessage}}</div>
        </div>
      </div>
      
      <div class="enter-page__btn-wrapper">
        <button id="id1538392487" [disabled]="passForm.invalid" [ngClass]="{'btn--active': passForm.valid == true}" type="submit" class="btn btn--big">Подтвердить</button>
      </div>
    </form>
    <div class="enter-page__footer">
      <div class="support__wrapper">
        <div id="id1538392503" class="label enter-page__label">Поддержка</div>
        <a id="id1538392530" href="mailto:support@asumet.ru" class="support__email"></a>
      </div>
    </div>
  </div>
</div>
