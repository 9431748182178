<div class="upblock">
  <h1 class="h1">Список оплат</h1>
  <div class="upblock__btn-wrapper">
    <div class="upblock__item">
      <div *ngIf="settingList && settingList.licenseeType == 'Default'">
        <button
          (click)="showConfirm()"
          *hasPermission="[[userRoles.Administrator, userRoles.Manager, userRoles.Cashier, userRoles.ManagerPlus]]"
          class="btn btn--active btn--plus-o">
          Новая оплата
        </button>
      </div>
    </div>
    <div class="upblock__item relative hover-list__wrapper">
      <button
        id="id1538477693"
        class="btn btn__xml"
        [ngClass]="{
          'btn__xml--disabled':
            !peopleDeals?.deals || peopleDeals.deals.length <= 0}">
        Выгрузить
      </button>
      <div class="hover-list absolute" *ngIf="peopleDeals?.deals && peopleDeals.deals.length > 0">
        <ul>
          <li class="hover-list__li" id="1539578603" (click)="getXML()">XML</li>
          <li class="hover-list__li" id="1539578681" (click)="getCSV()">CSV</li>
          <li class="hover-list__li" id="1539578697" (click)="getExcel()">Excel</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-select-licensee
  #selectLicenseeRef
  (change)="onChangeLicensee($event)"
  [loadOnStart]="false"
  [initLicensee]="dealFilter.licenseeId"
  [(ngModel)]="dealFilter.licenseeId"
></app-select-licensee>

<div class="upblock legend__wrapper">
  <div class="legend__wrapper">
    <div class="label--mobile">Фильтры статусов оплат:</div>
    <div
      id="id1538477712"
      class="legend__filter  payment-paid"
      (click)="changeState('Completed')">
      Оплачено
    </div>
    <div
      id="id1538477723"
      class="legend__filter  payment-refusal"
      (click)="changeState('Rejected')">
      Отказ оплаты
    </div>
    <div
      id="id1538477744"
      class="legend__filter  payment-waiting"
      (click)="changeState('Wait')">
      Ожидает оплаты
    </div>
    <div
      id="id1538477765"
      class="legend__filter  payment-approval"
      (click)="changeState('Accepting')">
      На согласовании
    </div>
    <div
      id="id1538477777"
      class="legend__filter  payment-not-paid"
      (click)="changeState('NoPay')">
      Не оплачено
    </div>
    <div
      id="id1554377896 "
      class="legend__filter  payment-pay-error"
      (click)="changeState('PayError')">
      Ошибка оплаты
    </div>
    <div
      id="id1566999348 "
      class="legend__filter  payment-kyc-error"
      (click)="changeState('KycError')">
      Отказ террористу/экстремисту
    </div>
  </div>
  <div>
    <a id="id1538477805" class="legend__reset-filtres" (click)="resetFilters()">
      Сбросить фильтры
    </a>
  </div>
</div>
<div class="upblock legend__wrapper">
  <ng-select
    [items]="dealTableColumns"
    class="rounded-dropdown"
    bindLabel="display"
    bindValue="id"
    placeholder="Выберите значения сделки..."
    appendTo="body"
    [multiple]="true"
    (change)="onDealTableColumnsChange($event)"
    [(ngModel)]="selectedDealTableColumns">
  </ng-select>
</div>
<!-- Deal state filter END-->
<div class="table " *ngIf="!showModals">
  <ng-container *ngIf="settingList">
    <!-- Header filter START-->

    <div class="table__header-row">
      <div id="id1538478373" class="table__header-cell table__header-cell--w200 filter__column relative"
        *ngIf="columnIsVisible('status')">
        <app-list-filter
          [items]="items"
          [queryName]="'pointIds'"
          [(model)]= "dealFilter.pointIds"
          (apply)="applyFilter()"
          [paginationFilter]="dealFilter">
          "{{ settingList.pointName}}", статус
        </app-list-filter>
      </div>

      <div id="id1538478394" class="table__header-cell table__header-cell--w100 filter__column relative"
        *ngIf="columnIsVisible('dealNumber')">
        <app-string-filter
          [(model)]="dealFilter.dealNumber"
          [queryName]="'dealNumber'"
          (apply)="applyFilter()"
          mask="0*"
          [paginationFilter]="dealFilter">
          №&nbsp;Оплаты
        </app-string-filter>
      </div>

      <div id="id1538478416" class="table__header-cell table__header-cell--w90 filter__column relative"
        *ngIf="columnIsVisible('date')">
        <app-date-range-filter
          [(model)]="dealFilter.dateRange"
          (apply)="applyFilter()"
          [paginationFilter]="dealFilter">
          Дата
        </app-date-range-filter>
      </div>

      <div id="id1538478436"
        class="table__header-cell filter__column  table__header-col relative"
        *ngIf="settingList.licenseeType === 'Default' && columnIsVisible('operator')">
        <app-string-filter
          [(model)]="dealFilter.operatorQuery"
          [queryName]="'operatorQuery'"
          (apply)="applyFilter()"
          placeholder="ФИО..."
          [paginationFilter]="dealFilter">
          Оператор
        </app-string-filter>
      </div>

      <div id="id1538478457"
            *ngIf="columnIsVisible('client')"
            class="table__header-cell filter__column table__header-col relative">
        <app-string-filter
          [(model)]="dealFilter.clientQuery"
          [queryName]="'clientQuery'"
          (apply)="applyFilter()"
          placeholder="ФИО..."
          [paginationFilter]="dealFilter">
          Продавец
        </app-string-filter>
      </div>

      <div
        id="id1538478474"
        class="table__header-cell filter__column relative"
        *ngIf="columnIsVisible('sum')">
        <app-number-range-filter
          [(model)]="dealFilter.sum"
          (apply)="applyFilter()"
          [paginationFilter]="dealFilter">
          Сумма
      </app-number-range-filter>
      </div>
      <div
        id="id1538478475"
        class="table__header-cell"
        *ngIf="settingList.licenseeType === 'Default' && columnIsVisible('commision')">
        <div id="id1538478124">Комиссия</div>
      </div>
      <div
        id="id1538478492"
        class="table__header-cell table__header-cell--w60"
        *ngIf="columnIsVisible('psa')">
        <div>ПСА</div>
      </div>
      <div class="table__header-cell table__header-col"
           *ngIf="smz && columnIsVisible('documents')"
           id="id1573412369">
        <div>Фискальные документы</div>
      </div>
    </div>
    <!-- Header filter END-->
  </ng-container>

  <!-- Rows START-->
  <ng-container *ngIf="peopleDeals">
    <ng-container *ngFor="let deal of peopleDeals.deals; let i = index">
      <div class="d-table-row table__row"
           [ngClass]="{ opened: deal.showCollapse == true }">
        <div class="table__col" *ngIf="columnIsVisible('status')">
          <div class="table__data">
            <div class="flex-wrapper">
              <div class="legend__filter  {{ deal.dealState | dealStatusesClass }}"
                id="id1538476836{{ i + 1 }}"
                (click)="showPayOrEdit(deal)">
              </div>
              <div class="payment-table-address">
                {{ deal.pointAddress }}
              </div>
            </div>
          </div>
        </div>
        <div class="table__cell " *ngIf="columnIsVisible('dealNumber')">
          <div class="table__data table__data--mobile-senior">
            <span class="label--mobile label--mobile-senior">Номер оплаты:</span>
            <a
              id="id1538476884{{ i + 1 }}"
              class="deal-number-blue"
              (click)="EditOrDetail(deal)"
              >{{ deal.pornumber }}</a>
          </div>
        </div>
        <div class="table__cell" *ngIf="columnIsVisible('date')">
          <div class="table__data table__data--mobile-senior">
            <span class="label--mobile label--mobile-senior">Дата:</span>
            {{ deal.createDate | timeZone : dealFilter.timeZoneId }}
          </div>
        </div>
        <div class="table__cell"
          *ngIf="settingList.licenseeType === 'Default' && columnIsVisible('operator')">
          <div class="table__data">
            <span class="label--mobile">Оператор:</span>{{ deal.operatorFIO }}
          </div>
        </div>
        <div class="table__cell" *ngIf="columnIsVisible('client')">
          <div class="table__data">
            <span class="label--mobile">Продавец:</span
            >{{ deal.clientFIO | phoneFormated }}
          </div>
        </div>
        <div class="table__cell" *ngIf="columnIsVisible('sum')">
          <div class="table__data table__data--mobile-senior">
            <span class="label--mobile label--mobile-senior">Сумма:</span
            >{{ deal.sum | money: deal.currency }}
          </div>
        </div>
        <div
          class="table__cell"
          *ngIf="settingList.licenseeType === 'Default' && columnIsVisible('commision')">
          <div class="table__data">
            <span class="label--mobile">Комиссия: </span
            >{{ deal.tsuzComission | money: deal.currency }}
          </div>
        </div>
        <div class="table__cell text-center" *ngIf="columnIsVisible('psa')">
          <div class="table__data download">
            <ng-container *ngIf="deal.scanDocuments && deal.scanDocuments.length > 0">
              <a
                id="id1538476764{{ i }}"
                *ngFor="let file of deal.scanDocuments"
                title="{{ getDescriptionScanByType(file.type) }}"
                class="download__btn"
                target="_blank"
                (click)="getFile(deal.id, file.type, $event)">
                <span class="label--mobile">{{
                  getDescriptionScanByType(file.type)
                }}</span>
              </a>
            </ng-container>
          </div>
        </div>
        <div class="d-table-cell table__col" *ngIf="smz && columnIsVisible('documents')">
          <div class="table__data">
            <a id="id1573413189{{i+1}}"
               class="download__btn"
               *ngIf="deal.smzTaxLink"
               href="{{deal.smzTaxLink}}"
               target="_blank"
               title="Открыть фискальный документ"><span class="label--mobile">Фискальные документы:</span></a>
          </div>
        </div>
      </div>
      <div class="table__hidden-row">
        <div class="table__cell">
          <td colspan="8" class="table__hidden-data">
            <div class="dropdown">
              <div class="dropdown__main">
                <div class="dropdown__header">
                  <h5 class="h5">Действия:</h5>
                </div>

                <div class="dropdown__footer">
                  <a
                    id="id1538476995{{ i }}"
                    (click)="showDetails(deal.id)"
                    class="people-deal-btn btn btn--active">Подробнее</a>
                  <a
                    id="id1538477009{{ i }}"
                    class="people-deal-btn btn btn--active"
                    *ngIf="!deal.hideEdit"
                    (click)="showEditModal(deal.id)">Изменить</a>
                </div>
              </div>
            </div>
          </td>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- Rows END-->
</div>

<!--PAGINATION-->
<app-pagination *ngIf="!showModals"
                [(model)]="dealFilter"
                (apply)="applyPagination()">
</app-pagination>

<div #modalWrap></div>
