import {map} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {IPointDeal} from '../../models/peopledeal/IPointDeal';
import { IUsersService, BaseHttpService, PaginationFilter } from 'tsuz-common';

@Injectable()
export class UsersService implements IUsersService {
  constructor(private baseHttpService: BaseHttpService, @Inject('LOCALSTORAGE') private localStorage: any) { }

  getUserPoint(userId: any, pointId: any, pointName: any): Observable<any[]> {
    const resultTupple = [];
    return this.baseHttpService.get('/users/' + userId + '/points').pipe(map(result => {
      resultTupple[0] = result as IPointDeal[];
      if (resultTupple[0].length > 0) {
        const selectedPoint = resultTupple[0].find(x => x.id === pointId);
        if (selectedPoint) {
          resultTupple[1] = selectedPoint.info;
        } else {
          resultTupple[1] = `Выберите "${pointName}"`;
        }
      }
      return resultTupple;
    }));
  }

  addNewEmp(licenseeId: string, formData: FormData): Observable<any> { return this.baseHttpService.post('/users/', formData); }

  editUser(licenseeId: string, userId: string, formData: FormData): Observable<any> { 
    return this.baseHttpService.put(`/licensees/${licenseeId}/users/${userId}`, formData); 
  }

  blockUser(id: string, reason: string): Observable<any> {
    return this.baseHttpService.post('/users/' + id + '/block', { reason });
  }

  unBlockUser(id: string): Observable<any> {
    return this.baseHttpService.post('/users/' + id + '/unblock', {});
  }

  addUserRole(licenseeId: string, userId: string, newRole: any): Observable<any> { 
    return this.baseHttpService.put(`/licensees/${licenseeId}/users/${userId}/roles`, { roles: newRole }); 
  }

  getPage(licenseeId: string, filter: PaginationFilter): Observable<any> {
    return this.baseHttpService.get(`/licensee/${licenseeId}/users`, filter);
  }

  getUserPoints(id: string): Observable<any> {
    return this.baseHttpService.get('/users/' + id + '/points');
  }

  resetPassword(userId: string): Observable<any> {
    return this.baseHttpService.put(`/users/${userId}/resetpassword`, null);
  }

  updateEmpPoints(licenseeId: string, userId: string, points: any): Observable<any> {
    return this.baseHttpService.post('/users/' + userId + '/points', points);
  }

  getAvailablePoints(licenseeId: string, id: string): Observable<any> {
    if (licenseeId) {
      return this.baseHttpService.getWithoutCache('/users/' + id + '/availablepoints?licenseeId=' + licenseeId);
    } else {
      return this.baseHttpService.getWithoutCache('/users/' + id + '/availablepoints');
    }
  }

  deletePoints(id: string, pointId: string): Observable<any> {
    return this.baseHttpService.delete('/users/' + id + '/points/' + pointId);
  }

}
