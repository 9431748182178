import { animate, transition, trigger, style } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { WidgetsComponent } from '../widgets.component';

export interface WidgetComponent extends OnInit
{
  isLoadingCompleted: boolean;
  
  hasError: boolean;

  error: string;
}

@Component({
  selector: 'app-base-widget',
  templateUrl: './base-widget.component.html',
  styleUrls: ['./base-widget.component.scss'],
  animations: [
    trigger('contentTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('700ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})  
export class BaseWidgetComponent
  implements OnInit {

  @Input()
  public header: string = "Widget";

  @Input()
  public icoType: string = "doc";

  @Input()
  public isLoading: boolean = true;

  @Input()
  public hasError: boolean = false;

  @Input()
  public loadingError: string;

  @Input()
  public hidePrimaryButton: boolean = false;

  @Input()
  public primaryButtonCaption: string = "Подробнее";

  @Input()
  public primaryButtonRoute: string = "/widgets";

  @Input()
  public backgoundColor: string = "#fff";

  @Input()
  public headerFontColor: string = '#031B4E';

  constructor() { }
  
  ngOnInit(): void {
  }
}
