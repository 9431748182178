import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { LicenseeService } from '@services/licensee.service';
import {SettingCaseViewModel} from "../../../models/licensee-setting/ISettingCaseViewModel";
import {NotificationService, LoaderService, ILicenseeSetting, FioValidator} from "tsuz-common";

@Component({
  selector: 'point-settings',
  templateUrl: './point-settings.component.html'
})
export class PointSettingsComponent implements OnInit {

  licenseeId: string;
  settingList: ILicenseeSetting;
  settingForm: FormGroup;
  PointName: FormControl;
  
  constructor(
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    @Inject("LOCALSTORAGE") private localStorage: any,
  ) { }

  ngOnInit() {
    this.licenseeId = this.localStorage.getItem('licenseeId');
    //Получение настроек лицензиата
    this.getSettings();
  }

  getSettings(): void {
    this.loaderService.display(true);
    this.licenseeService.getForSettings(this.licenseeId).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
      //Создание формы
      this.createFormControls(this.settingList.pointName);
      this.createForm();
      this.loaderService.display(false);
    }, error => {
      console.error(error);
    });
  }

  createFormControls(initialPointName: string) {
    this.PointName = new FormControl(initialPointName, [Validators.required]);
  }

  createForm() {
    this.settingForm = new FormGroup({
      PointName: this.PointName
    });
  }

  async onSubmit() {
    try {
      await this.licenseeService.updateSettings(this.licenseeId, this.settingForm.value).toPromise();
      this.notifyService.successNotify('Настройки успешно изменены' , 'Настройки');
      this.licenseeService.getSettings(this.licenseeId, true).subscribe(result => {});
     } catch (error) {
       this.notifyService.errorNotify('Ошибка сохранения' , 'Настройки');
     }
  }
}
