<h1 class="h1">Список сотрудников</h1>

<div class="row">
    <a class="legend__reset-filtres" (click)="resetFilters()">Сбросить фильтры</a>
    <ng-container *hasPermission="[[userRoles.Administrator, userRoles.ManagerPlus, userRoles.ExecutiveOfficer]]">
        <button class="btn btn--active plus" (click)="addUser()">Новый сотрудник</button>
    </ng-container>
</div>

<tsuz-users-page 
    [licenseeId]="licenseeId" 
    [users]="users" 
    [filter]="filter"
    [myId]="myId"
    (refresh)="applyPagination()"
    ></tsuz-users-page>

<app-pagination [(model)]="filter" (apply)="applyPagination()"></app-pagination>