<div class="popup__wrapper">

  <div class="popup__header">
    <h4 class="h4">Детали оплаты</h4>
    <a id="id1542712429" class="tap-to-close" (click)="close()"></a>
  </div>

  <div>
    <ng-container *ngIf="licenseeInfo">
      <h5 class="divider__left line one-line h5">Лицензиат</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr >
            <td class="w-50">Лицензиат: </td>
            <td class="w-50">{{licenseeInfo.title}} </td>
          </tr>
          <tr >
            <td class="w-50">ИНН: </td>
            <td class="w-50">{{licenseeInfo.inn}} </td>
          </tr>
          <tr >
            <td class="w-50">Телефон лицензиата: </td>
            <td class="w-50">{{licenseeInfo.phone}} </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo">
      <h5 class="divider__left line one-line h5">Детали сделки</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr *ngIf="supportDealInfo.createDate">
            <td class="w-50">Дата создания (Лицензиат): </td>
            <td class="w-50">{{supportDealInfo.createDate | timeZone : supportDealInfo.licenseeTimeZoneId}}</td>
          </tr>
          <tr *ngIf="supportDealInfo.createDate">
            <td class="w-50">Дата создания ("{{pointName}}"): </td>
            <td class="w-50">{{supportDealInfo.createDate | timeZone : supportDealInfo.pointTimeZoneId}}</td>
          </tr>
          <tr *ngIf="supportDealInfo.creator.surname">
            <td class="w-50">Оператор: </td>
            <td class="w-50">{{supportDealInfo.creator.surname}} {{supportDealInfo.creator.firstName}} {{supportDealInfo.creator.middleName}}</td>
          </tr>
          <tr *ngIf="supportDealInfo.creator.phone">
            <td class="w-50">Телефон оператора: </td>
            <td class="w-50">{{supportDealInfo.creator.phone}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.dealState">
            <td class="w-50">Состояние оплаты: </td>
            <td class="w-50">{{supportDealInfo.dealStateDescription}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.codeFnsForSelfEmployed">
            <td class="w-50">Ответ системы ФНС: </td>
            <td class="w-50">{{supportDealInfo.codeFnsForSelfEmployed | codeFnsForSelfEmployedPipe}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.agreementDate">
            <td class="w-50">Дата ПСА: </td>
            <td class="w-50">{{supportDealInfo.agreementDate}}</td>
          </tr>
          <tr *ngIf="supportDealInfo.agreement">
            <td class="w-50">Номер ПСА: </td>
            <td class="w-50">{{supportDealInfo.agreement}} </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <h5 class="divider__left line one-line h5">Получатель</h5>
    <ng-container *ngIf="supportDealInfo.customer">
      <table class="popup__table-noborder">
          <tr  *ngIf="supportDealInfo.customer.surname">
            <td class="w-50">Получатель: </td>
            <td class="w-50">{{supportDealInfo.customer.surname}} {{supportDealInfo.customer.firstName}} {{supportDealInfo.customer.middleName}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.customer.passportSeries">
            <td class="w-50">Номер и серия паспорта: </td>
            <td class="w-50">{{supportDealInfo.customer.passportSeries}} </td>
          </tr>
          <tr  *ngIf="supportDealInfo.customer.passportFrom">
            <td class="w-50">Кем выдан паспорт: </td>
            <td class="w-50">{{supportDealInfo.customer.passportFrom}} </td>
          </tr>
          <tr  *ngIf="supportDealInfo.customer.address">
            <td class="w-50">Прописка: </td>
            <td class="w-50">{{supportDealInfo.customer.address}} </td>
          </tr>
          <tr  *ngIf="supportDealInfo.customer.birthday">
            <td class="w-50">Дата рождения: </td>
            <td class="w-50">{{supportDealInfo.customer.birthday}} </td>
          </tr>
          <tr  *ngIf="supportDealInfo.customer.birthPlace">
            <td class="w-50">Место рождения: </td>
            <td class="w-50">{{supportDealInfo.customer.birthPlace}} </td>
          </tr>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.products">
      <h5 class="divider__left line one-line h5">Продукты</h5>
      <table class="popup__product-table">
        <tbody class="">
          <ng-container *ngFor="let prod of supportDealInfo.products; let i=index">
            <tr class="popup__product-table-row popup__product-table-header-row">
              <th class="popup__product-table-th" *ngIf="!supportDealInfo.dealSettings.oneProduct"># </th>
              <th class="popup__product-table-th">Товарная категория </th>
              <th class="popup__product-table-th">Сумма: </th>
              <th class="popup__product-table-th" *ngIf="!supportDealInfo.dealSettings.hideWeight">Цена: </th>
              <th class="popup__product-table-th" *ngIf="!supportDealInfo.dealSettings.hideTrashPercent">Засор,&nbsp;%: </th>
              <th class="popup__product-table-th" *ngIf="!supportDealInfo.dealSettings.hideWeight">{{prod.measureUnit.measureName}},&nbsp;{{prod.measureUnit.shortName}}:</th>
              <th class="popup__product-table-th" *ngIf="!prod.hideProviders">Оператор</th>
              <th class="popup__product-table-th" *ngIf="!prod.hidePp">Номер П.П.</th>
            </tr>
            <tr class="popup__product-table-row">
              <th class="popup__product-table-td" *ngIf="!supportDealInfo.dealSettings.oneProduct">{{i+1}}</th>
              <th class="popup__product-table-td">{{prod.title}}</th>
              <th class="popup__product-table-td">{{prod.totalPrice | money: supportDealInfo.currency}} </th>
              <th class="popup__product-table-td" *ngIf="!supportDealInfo.dealSettings.hideWeight">{{prod.measureUnitPrice | money: supportDealInfo.currency}} </th>
              <th class="popup__product-table-td" *ngIf="!supportDealInfo.dealSettings.hideTrashPercent">{{prod.trash | number: '.2-2'}} </th>
              <th class="popup__product-table-td" *ngIf="!supportDealInfo.dealSettings.hideWeight">{{prod.measureUnitValue}} </th>
              <th class="popup__product-table-td" *ngIf="!prod.hideProviders">{{supportDealInfo.provider.title}}</th>
              <th class="popup__product-table-td" *ngIf="!prod.hidePp">{{prod.description}}</th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.payment">
      <h5 class="divider__left line one-line h5">Платежная информация</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr *ngIf="supportDealInfo.payment.withdrawId">
            <td class="w-50">Идентификатор выплаты: </td>
            <td class="w-50">{{supportDealInfo.payment.withdrawId}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.payment.errorCode">
            <td class="w-50">Код ответа от шлюза: </td>
            <td class="w-50">{{supportDealInfo.payment.errorCode}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.payment.createdAt">
            <td class="w-50">Дата платежа: </td>
            <td class="w-50">{{supportDealInfo.payment.createdAt | timeZone : supportDealInfo.licenseeTimeZoneId }}</td>
          </tr>
          <tr *ngIf="supportDealInfo.payment.paymentStateDescription">
            <td class="w-50">Состояние платежа: </td>
            <td class="w-50">{{supportDealInfo.payment.paymentStateDescription}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.payment.processingName">
            <td class="w-50">Процессинг: </td>
            <td class="w-50">{{supportDealInfo.payment.processingName}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.payment.rrn">
            <td class="w-50">Идентификатор банковской транзакции (RRN): </td>
            <td class="w-50">{{supportDealInfo.payment.rrn}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.payment.reason">
            <td class="w-50">Ответ от процессинга: </td>
            <td class="w-50">{{supportDealInfo.payment.reason}} </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.point">
      <h5 class="divider__left line one-line h5">{{pointName}}</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr *ngIf="supportDealInfo.point.title">
            <td class="w-50">"{{pointName}}": </td>
            <td class="w-50">{{supportDealInfo.point.title}} </td>
          </tr>
          <tr *ngIf="supportDealInfo.point.address">
            <td class="w-50">Адрес: </td>
            <td class="w-50">{{supportDealInfo.point.address}} </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.confirmation">
      <h5 class="divider__left line one-line h5">Подтверждение оплаты</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr  *ngIf="supportDealInfo.confirmation.surname">
            <td class="w-50">Пользователь, подтвердивший оплату: </td>
            <td class="w-50">{{supportDealInfo.confirmation.surname}} {{supportDealInfo.confirmation.firstName}} {{supportDealInfo.confirmation.middleName}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.confirmation.phone">
            <td class="w-50">Телефон: </td>
            <td class="w-50">{{supportDealInfo.confirmation.phone}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.confirmation.confirmationDate">
            <td class="w-50">Дата и время подтверждения оплаты: </td>
            <td class="w-50">{{supportDealInfo.confirmation.confirmationDate | timeZone : supportDealInfo.licenseeTimeZoneId}}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.confirmCodes && supportDealInfo.confirmCodes.length > 0">
      <h5 class="divider__left line one-line h5">Коды подтверждения</h5>
      <table class="popup__product-table">
        <tbody class="">
          <ng-container *ngFor="let confirmCode of supportDealInfo.confirmCodes; let i=index">
            <tr class="popup__product-table-row popup__product-table-header-row">
              <th class="popup__product-table-th"># </th>
              <th class="popup__product-table-th">Получатель</th>
              <th class="popup__product-table-th">Попыток ввода</th>
              <th class="popup__product-table-th">Подтвержден</th>
              <th class="popup__product-table-th">Дата отправки</th>
            </tr>
            <tr class="popup__product-table-row">
              <th class="popup__product-table-td">{{i+1}}</th>
              <th class="popup__product-table-td">{{confirmCode.name}}</th>
              <th class="popup__product-table-td">{{confirmCode.attemptsCount}}</th>
              <th class="popup__product-table-td">{{confirmCode.isUsed | booleanToString}} </th>
              <th class="popup__product-table-td">{{confirmCode.sendDate | timeZone : supportDealInfo.licenseeTimeZoneId}} </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.acception">
      <h5 class="divider__left line one-line h5">Согласование лимитов</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr  *ngIf="supportDealInfo.acception.surname">
            <td class="w-50">Пользователь, согласовавший оплату:</td>
            <td class="w-50">{{supportDealInfo.acception.surname}} {{supportDealInfo.acception.firstName}} {{supportDealInfo.acception.middleName}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.acception.phone">
            <td class="w-50">Телефон:</td>
            <td class="w-50">{{supportDealInfo.acception.phone}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.acception.date">
            <td class="w-50">Дата и время согласования оплаты:</td>
            <td class="w-50">{{supportDealInfo.acception.date | timeZone : supportDealInfo.licenseeTimeZoneId}}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.update">
      <h5 class="divider__left line one-line h5">Обновление оплаты</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr  *ngIf="supportDealInfo.update.surname">
            <td class="w-50">Пользователь, обновивший оплату:</td>
            <td class="w-50">{{supportDealInfo.update.surname}} {{supportDealInfo.update.firstName}} {{supportDealInfo.update.middleName}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.update.phone">
            <td class="w-50">Телефон:</td>
            <td class="w-50">{{supportDealInfo.update.phone}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.update.date">
            <td class="w-50">Дата обновления оплаты:</td>
            <td class="w-50">{{supportDealInfo.update.date | timeZone : supportDealInfo.licenseeTimeZoneId}}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngIf="supportDealInfo.dealState && supportDealInfo.dealState === 'Rejected' && supportDealInfo.rejection">
      <h5 class="divider__left line one-line h5">Отклонение согласования сделки</h5>
      <table class="popup__table-noborder">
        <tbody>
          <tr  *ngIf="supportDealInfo.rejection.surname">
            <td class="w-50">Пользователь, отклонивший оплату: </td>
            <td class="w-50">{{supportDealInfo.rejection.surname}} {{supportDealInfo.rejection.firstName}} {{supportDealInfo.rejection.middleName}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.rejection.phone">
            <td class="w-50">Телефон: </td>
            <td class="w-50">{{supportDealInfo.rejection.phone}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.rejection.rejectionDate">
            <td class="w-50">Дата и Время отклонения оплаты:</td>
            <td class="w-50">{{supportDealInfo.rejection.rejectionDate | timeZone : supportDealInfo.licenseeTimeZoneId}}</td>
          </tr>
          <tr  *ngIf="supportDealInfo.rejection.rejectionReason">
            <td class="w-50">Причина отклонения оплаты: </td>
            <td class="w-50">{{supportDealInfo.rejection.rejectionReason}} </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <h5 class="divider__right line one-line h5">Итого:&nbsp;<strong> {{supportDealInfo.sum | money: supportDealInfo.currency}}</strong></h5>

    <div class="popup__btn-group">
      <div><a id="id1542712458" class="popup3__back" (click)="close()">К списку оплат</a></div>
    </div>
  </div>
</div>
