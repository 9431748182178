import { WidgetComponent } from './base-widget/base-widget.component';
import { HoldingWidgetComponent } from './holding-widget/holding-widget.component';
import { ComponentFactory, ComponentFactoryResolver, Injectable } from '@angular/core';
import { MoneyTurnoverWidgetComponent } from './money-turnover-widget/money-turnover-widget.component';
import { ProductsIncomingWidgetComponent } from './products-incoming-widget/products-incoming-widget.component';
import { DealsWidgetComponent } from './deals-widget/deals-widget.component';
import { DealsInProcessingWidgetComponent } from './deals-in-processing-widget/deals-in-processing-widget.component';
import { RnkoRegisterWidgetComponent } from './rnko-register-widget/rnko-register-widget.component';
import { LimitsWidgetComponent } from './limits-widget/limits-widget.component';
import { CashpoolingAdComponent } from './cashpooling-ad/cashpooling-ad.component';

@Injectable()
export class WidgetTypeFactory{

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {    
  }
  
  public getWidget(key: string): ComponentFactory<WidgetComponent>
  {
    var map = new Map(Object.entries({
      Holding: this.componentFactoryResolver.resolveComponentFactory(HoldingWidgetComponent),
      MoneyTurnover: this.componentFactoryResolver.resolveComponentFactory(MoneyTurnoverWidgetComponent),
      ProductsIncoming: this.componentFactoryResolver.resolveComponentFactory(ProductsIncomingWidgetComponent),
      Deals: this.componentFactoryResolver.resolveComponentFactory(DealsWidgetComponent),
      DealsInProcessing: this.componentFactoryResolver.resolveComponentFactory(DealsInProcessingWidgetComponent),
      RnkoRegister: this.componentFactoryResolver.resolveComponentFactory(RnkoRegisterWidgetComponent),
      Limits: this.componentFactoryResolver.resolveComponentFactory(LimitsWidgetComponent),
      CashpoolingAd: this.componentFactoryResolver.resolveComponentFactory(CashpoolingAdComponent),
    }));

    return map.get(key);
  }
}