<app-base-widget
[header]="'Приход лома в %'"
[icoType]="'chart'"
[isLoading]="!isLoadingCompleted"
[primaryButtonRoute]="'/deals'"
[hasError]="hasError"
[loadingError]="error"
>
<div>
  <ngx-charts-pie-chart
  class="products-incoming-widget-component"
  [view]="view"
  [scheme]="colorScheme"
  [results]="chartData"
  [gradient]="true"
  [doughnut]="true"
  [legend]="true"
  [legendPosition]="'below'"
  [legendTitle]="''"
  [tooltipText]="tooltipFormatting"
  >
  </ngx-charts-pie-chart>
</div>
<div>

</div>
</app-base-widget>
