import {ControlValueAccessor} from '@angular/forms';

export abstract class AbstractListValueAccessor implements ControlValueAccessor {

  constructor() { }

  value: string;

  protected _onChange = (_: any) => { };
  protected _onTouched = () => { };

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChange(event: any) {
    this._onChange(this.value);
  }
  onKeyup(event: any) {
    this._onChange(this.value);
  }
  onBlur(event: any) {
    this._onTouched();
  }
  onFocusOut(event: any) {
    this.onChange(event);
  }
}
