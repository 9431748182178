import { Pipe, PipeTransform } from '@angular/core';
import { UserRoles } from '../models/auth/user-roles';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform {
  private readonly allRoleRussianNaming: Map<UserRoles, string>;

  constructor(private authService: AuthService) {
    this.allRoleRussianNaming = new Map<UserRoles, string>([
    [UserRoles.Administrator, 'Администратор'],
    [UserRoles.Manager, 'Менеджер'],
    [UserRoles.ManagerPlus, 'Менеджер с повышенными правами'],
    [UserRoles.Cashier, 'Оператор'],
    [UserRoles.Accountant, 'Бухгалтер'],
    [UserRoles.FinancialDirector, 'Финансовый директор'],
    [UserRoles.ExecutiveOfficer, 'Исполнительный директор'],
    [UserRoles.ClientManager, 'Менеджер по работе с клиентами'],
    [UserRoles.Support, 'Техническая поддержка']]);
  }

  transform(value: string[], args?: any): string {
    const roleId = this.authService.getMainRole(value);
    if (this.allRoleRussianNaming.has(roleId)) {
      return this.allRoleRussianNaming.get(roleId);
    }
    return 'Нет ролей';
  }
}
