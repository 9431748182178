import { AfterContentInit, AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[focusPlaceholder]'
})
export class FocusPlaceholderDirective implements AfterViewInit {
  @Input()
  focusPlaceholder: string;
  defaultPlaceholder: string;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.defaultPlaceholder = this.el.nativeElement.placeholder || '';
  }

  @HostListener('focus')
  onFocus(): void {
    this.el.nativeElement.placeholder = this.focusPlaceholder;
  }

  @HostListener('blur')
  onBlur(): void {
    this.el.nativeElement.placeholder = this.defaultPlaceholder;
  }
}
