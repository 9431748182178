<div class="list-transactions">

  <div class="upblock bordered">
    <h1 class="h1">Обороты компаний</h1>
  </div>

  <div class="upblock legend__wrapper" *ngIf="licenseeList">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <div class="flex-wrapper">
        <app-search-select
          [control]="selectedLicenseeIdsControl"
          [options]="licenseeOptionList"
          [title]="'Выберите лицензиатов'"
          [multiple]="true">
        </app-search-select>

        <div class="active-licensees">
          <input
            class="checkbox-filter"
            type="checkbox"
            id="id1710247700"
            formControlName="OnlyActive" />
          <label
            class="checkbox-filter-label"
            for="id1710247700">
            Только активные
          </label>
        </div>
      </div>
      <div class="upblock upblock-columns upblock--flex-start select-licensor__block">
        <app-date-range-input formControlName="Period" placeholder="Период отчёта" class="date-range"></app-date-range-input>
        
        <div class="relative-span">
          <span class="date-relative" (click)="setTodayPeriod()">Сегодня</span>
          <span class="date-relative" (click)="setYesterdayPeriod()">Вчера</span>
          <span class="date-relative" (click)="setCurrentWeekPeriod()">Текущая неделя</span>
          <span class="date-relative" (click)="setPreviousWeekPeriod()">Предыдущая неделя</span>
          <span class="date-relative" (click)="setCurrentMonthPeriod()">Текущий месяц</span>
          <span class="date-relative" (click)="setPreviousMonthPeriod()">Предыдущий месяц</span>
        </div>
      </div>
  <div class="button-wrapper">
    <button class="btn btn--active back"
            (click)="resetFilters()"
            type="button">
      Сбросить
    </button>
    <button class="btn btn--loading "
            [ngClass]="{'btn--active': searchForm.valid}"
            [disabled]="searchForm.invalid"
            value="Submit"
            type="submit">Сформировать
    </button>
  </div>
  </form>
</div>

<div class="table">
  <ng-container id="id1538405102">
    <div class="d-table-row table__header-row">
      <div class="table__header-cell table__header-cell--w80">№"</div>
      <div class="table__header-cell">Идентификатор компании</div>
      <div class="table__header-cell">ИНН</div>
      <div class="table__header-cell">Название</div>
      <div class="table__header-cell">Тип интеграции</div>
      <div class="table__header-cell">Процент комиссии</div>
      <div class="table__header-cell">Сумма операций</div>
      <div class="table__header-cell">Количество оплат</div>
    </div>
    <ng-container *ngIf="organizationsTurnover">
      <ng-container>
        <ng-container id="id1538405129"
                      *ngFor="let organization of organizationsTurnover.organizations;let i = index">
          <div id="id1538405225{{i}}"
               class="table__row"
               app-support-turnover-item
               [organization]="organization"
               [index]="i+1"></div>
        </ng-container>
        <div class="caption">
          Прибыль компании за построенный период : 
          {{ organizationsTurnover.totalCommission | money: currency }}
        </div>

      </ng-container>
    </ng-container>
  </ng-container>
</div>
</div>
