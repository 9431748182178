<div class="upblock">
  <h1 class="h1">Суточные реестры</h1>
</div>

<!--FILTER-->
<div class="upblock registries__legend-wrapper">
  <div class="registries__legend">
    <div class="label--mobile">
      Фильтр статусов реестров:
    </div>

    <div class="registries__filter registries__filter--generated"
         id="id11568979809"
         [ngClass]="{'active': filter.status == RegistryStatuses.Generated}"
         (click)="filter.status = RegistryStatuses.Generated;getRegistries()">
      Cформирован
    </div>

    <div class="registries__filter registries__filter--sending"
         id="id11568979824"
         [ngClass]="{'active': filter.status == RegistryStatuses.Sending}"
         (click)="filter.status=RegistryStatuses.Sending;getRegistries()">
      Отправляется
    </div>

    <div class="registries__filter registries__filter--successfully-sent"
         id="id11568979834"
         [ngClass]="{'active': filter.status == RegistryStatuses.SuccessfullySent}"
         (click)="filter.status =RegistryStatuses.SuccessfullySent;getRegistries()">
      Отправлен
    </div>

    <div class="registries__filter registries__filter--failure-sent"
         id="id1568979845"
         [ngClass]="{'active': filter.status == RegistryStatuses.FailureSent}"
         (click)="filter.status=RegistryStatuses.FailureSent;getRegistries()">
      Ошибка отправки
    </div>
  </div>

  <div>
    <a class="registries__filter--reset-filtres"
       id="id1568979857"
       (click)="resetFilters()">Сбросить фильтры</a>
  </div>
</div>

<!--LIST-->
<div class="d-table table">

  <!--HEADER-->
  <ng-container *ngIf="registries">
    <div class="d-table-row table__header-row ">

      <div id="id1568978323"  class="table__header-cell table__header-col table__header-col--w300 filter__column relative">
      <app-string-filter
        [(model)]="filter.id"
        (apply)="getRegistries()"
        placeholder = "Идентификатор реестра..."
        [paginationFilter]="filter">
          Идентификатор реестра
        </app-string-filter>
      </div>

      <div id="id1584636717"  class="table__header-cell table__header-col table__header-col--w300 filter__column relative">
        <app-string-filter
          [(model)]="filter.organizationName"
          (apply)="getRegistries()"
          placeholder = "Организация..."
          [paginationFilter]="filter">
            Организация
          </app-string-filter>
      </div>

      <div id="id1568978340" class="table__header-cell table__header-col table__header-col--w130 filter__column relative">
        <app-date-range-filter
          [(model)]="filter.create"
          (apply)="getRegistries()"
          id="id1557944883"
          [paginationFilter]="filter">
          Дата формирования реестра
        </app-date-range-filter>
      </div>

      <div class="table__header-cell table__header-col table__header-col--w130 relative"
           id="id1568979752">
        <div>Статус доставки реестра</div>
      </div>

      <div id="id1568979769"
           class="table__header-cell table__header-col table__header-col--w200 relative">
        <div>Действия</div>
      </div>

    </div>
  </ng-container>

  <!--TABLE-->
  <ng-container *ngIf="registries">
    <ng-container *ngFor="let registry of registries.items; let i=index">

      <!--ROW-->
      <div class="d-table-row table__row">

        <div class="table__col">
          <div class="table__data">
            <div class="flex-wrapper">
              <div class="registries__filter registries__filter--{{registry.status | xplatRegistryStatusesClass}}"
                   id="id11568979784{{i+1}}">
              </div>
              <div class="registries__name">
                {{registry.id}}
              </div>
            </div>
          </div>
        </div>

        <div class="table__cell">
          <div class="table__data">
            <span class="label--mobile">Организация:</span>
            {{registry.organizationName}}
          </div>
        </div>

        <div class="table__cell">
          <div class="table__data">
            <span class="label--mobile">Дата:</span>
            {{registry.created | timeZone : registry.licenseeTimeZoneId}}
          </div>
        </div>

        <div class="table__cell">
          <div class="table__data">
            <span class="label--mobile">Статус:</span>
            {{registry.status | xplatRegistryStatusesString}}
          </div>
        </div>

        <div class="table__cell">
          <div class="table__data">
            <button class="btn btn-link" (click)="download(registry)">
              Скачать
            </button>

            <button class="btn btn-link" (click)="send(registry)"
                    *ngIf="registry.status == RegistryStatuses.FailureSent || registry.status == RegistryStatuses.Generated ">
              Отправить
            </button>
          </div>
        </div>
      </div>

    </ng-container>
  </ng-container>

</div>

<!--PAGINATION-->
<app-pagination [(model)]="filter" (apply)="getRegistries()" *ngIf="registries">

</app-pagination>
