<app-base-widget
[header]="'Реестр РНКО'"
[icoType]="'doc'"
[isLoading]="!isLoadingCompleted"
[hasError]="hasError"
[loadingError]="error"
[primaryButtonCaption]="'Список реестов'"
[primaryButtonRoute]="'/registries'"
>
  <div class="rnko-register-widget-component widget-content-columns-container">
    <div class="widget-content-column-100">
      <div class="widget-content-row-left label-info-small widget-content-bottom-gap">
        Последний сформированный реестр
      </div>
      <div class="widget-content-row-left label-info-bold-dark widget-content-bottom-gap">
        {{lastRegisterData.createDate | timeZone : lastRegisterData.timeZoneId}}
      </div>
      <div class="widget-content-row-left label-info-small widget-content-bottom-gap"  style="align-items: center;"
      *ngIf="lastRegisterData.sentToRnko">
        <span class="info-dot-container inline-label" style="align-items: center;">
          <div class="info-dot register-sent-dot"></div>
        </span>
        <span class="inline-label">
          Отправлен в РНКО
        </span>
      </div>
    </div>
  </div>
</app-base-widget>
