<div class="holding-structure-component">
  <div *ngIf="!nominalAccountIsHidden">
    <div class="holding-structure--component-container
                holding-structure--angles-rounded-container">
      <app-nominal-account-connect-on
        [isHidden] = "nominalAccountIsHidden"
        [isLoading] = "!nominalAccountLoadCompleted"
        [hasError] = "nominalAccountLoadHasError"
        [error] = "nominalAccountError">
      </app-nominal-account-connect-on>
    </div>

    <div class="holding-structure--component-container
                holding-structure--angles-rounded-container">
      <app-holding-licensees-container
        [licenseesInfo]="licenseesInfo"
        [isLoading]="!licenseesInfoLoadCompleted"
        [hasError] = "licenseesLoadHasError"
        [error] = "licenseesError">
      </app-holding-licensees-container>
    </div>
  </div>

  <div *ngIf="nominalAccountIsHidden">
    <div class="holding-structure--component-container
                holding-structure--angles-rounded-container">

        <div class="holding-structure--container-header">
            С кешпулингом
        </div>

        <app-holding-summary-info
          [isHidden]="summaryInfoIsHidden"
          [isLoading] = "!summaryInfoLoadCompleted"
          [hasError] = "summaryInfoLoadHasError"
          [error] = "summaryInfoError"
          [summaryInfo]="summaryInfo">
        </app-holding-summary-info>

        <app-holding-licensees-container
          [licenseesInfo]="cashPoolingLicenseesInfo"
          [isLoading]="!licenseesInfoLoadCompleted"
          [hasError] = "licenseesLoadHasError"
          [error] = "licenseesError">
        </app-holding-licensees-container>
    </div>

    <div class="holding-structure--component-container
                holding-structure--angles-rounded-container">

      <div class="holding-structure--container-header">
          Без кешпулинга
      </div>

      <app-holding-licensees-container
        [licenseesInfo]="licenseesInfo"
        [isLoading]="!licenseesInfoLoadCompleted"
        [hasError] = "licenseesLoadHasError"
        [error] = "licenseesError">
      </app-holding-licensees-container>
    </div>
  </div>
</div>
