import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ChangeChannelConfirmComponent } from './change-confirm/change-confirm.component';
import { LicenseeSettingService } from '@services/licensee-setting.service';
import { IListMethodResponse } from '../../../models/licensee-setting/IListMethodsResponse';
import { NotificationService, ConfirmCodeValidator } from 'tsuz-common';

@Component({
  selector: 'change-channel',
  templateUrl: './change-channel.component.html'
})
export class ChangeChannelComponent implements OnInit {
  codeForm: FormGroup;
  sendMethod: FormControl;
  code: FormControl;
  reEnterCode: FormControl;
  listSendMethods: any;
  showCode = false;
  currentSendMethod:string;
  constructor(
    private simpleModalService: SimpleModalService,
    private licenseeService: LicenseeSettingService,
    private notifyService: NotificationService
  ) {}

  async ngOnInit() {
    try {
      let response = await this.licenseeService.getListMethods().toPromise()  as IListMethodResponse;
      this.listSendMethods = response.listMethods;
      this.currentSendMethod = this.listSendMethods.find(x => x.selected) ? this.listSendMethods.find(x => x.selected).key : this.listSendMethods[0].key;
      this.createFormControls();
      this.createForm();
    } catch (error) {
      this.notifyService.errorNotify(error , 'Ошибка получения списка доступных каналов оповещений');
    }
  }

  createForm() {
    this.codeForm = new FormGroup(
      {
        SendMethod: this.sendMethod,
        Code: this.code,
        ReEnterCode: this.reEnterCode
      },
      this.codeMatchValidator
    );
  }

  codeMatchValidator(g: FormGroup) {

    return g.get('Code').value === g.get('ReEnterCode').value
      ? null
      : { mismatch: true };
  }
  async sendConfirmCode() {
    if (this.codeForm.valid) {
      try {
        await this.licenseeService.sendConfirmCode().toPromise();
        const disposable = this.simpleModalService
        .addModal(
          ChangeChannelConfirmComponent,
          {
            title: 'Изменение канала оповещений',
            message: 'Введите код подтвержедния',
            newCode: this.codeForm.value.Code,
            sendMethod: this.codeForm.value.SendMethod
          },
          { closeOnClickOutside: true }
        )
        .subscribe(resultActive => {
          this.code.reset();
          this.reEnterCode.reset();
        });
      } catch (error) {
        this.notifyService.errorNotify(error , 'Ошибка отправки кода подтверждения');
      }

    }
  }

  //Создание формы
  createFormControls() {
    this.sendMethod = new FormControl(this.currentSendMethod, Validators.required);
    this.initCodeContorls(this.currentSendMethod);
  }
   initCodeContorls(sendMethod: string) {
    if (sendMethod=="Fixed") {
      this.code = new FormControl('',[Validators.required, ConfirmCodeValidator()]),
      this.reEnterCode = new FormControl('',[Validators.required, ConfirmCodeValidator()]);
      this.showCode = true;
    } else {
      this.code = new FormControl(''),
      this.reEnterCode = new FormControl('');
      this.showCode = false;
    }
   }
  changeSendMethod(event: any) {
    this.initCodeContorls(event.key);
    this.createForm();
    console.log(event);
  }
}
