import { Component, ElementRef, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractTextInput } from '../abstract-base-controls/abstract-text-input';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'tsuz-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: [ './api-key.component.scss' ],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ApiKeyComponent),
    }
  ]
})
export class ApiKeyComponent extends AbstractTextInput {

  @Input() label: string = 'API ключ';
  @Input() readonly: boolean = false;
  @Output() clearApiKey = new EventEmitter();
  @Output() refreshApiKey = new EventEmitter();

  constructor(public notificationService: NotificationService) {
    super();
  }

  get inputElement(): ElementRef {
    return this._inputElement;
  }

}
