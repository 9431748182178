
import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';

import { UserRoles } from '../../../models/auth/user-roles';
import { AuthService } from '../../../services/auth.service';
import { ILicenseeSetting } from '../../../models/ILicenseeSetting';
import { IUser } from '../../../models/IUser';
import { ILicenseeService } from '../../../services/interfaces/ILicenseeService';
import { UserFilter } from '../../../filters/user.filter';

@Component({
  selector: 'tsuz-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {

  @Input() licenseeId: string;
  @Input() readonly: boolean = false;
  @Input() users: IUser[];
  @Input() filter: UserFilter;
  @Input() myId = ''; // в админке этого не будет, в вебклиенте нужно будет взять userId из локалсторедж
  @Output() refresh = new EventEmitter<any>();

  userRoles = UserRoles;


  settingList: ILicenseeSetting;
  isExecutiveOfficer = false;
  mainRole: string;
  title: string;

  constructor(
    @Inject('LicenseeService') private licenseeService: ILicenseeService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.isExecutiveOfficer = this.authService.inRole(UserRoles.ExecutiveOfficer);

    // Получение настроек лицензиата
    this.licenseeService.getSettings(this.licenseeId, false).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
      this.mainRole = this.authService.getMainRole();
    }, error => {
      console.error(error);
    });
  }

  // Установка настроек меню для сотрудника
  showRight(str: string, id: any) {
    const item = this.users.find(i => i.id === id);
    this.resetShowBlocks(item);
    switch (str) {
      case 'point':
        item.isShowPoint = true;
        break;
      case 'role':
        item.isShowRole = true;
        break;
      case 'reset':
        item.isShowReset = true;
        break;
      case 'block':
        item.isShowBlock = true;
        break;
      case 'set':
        item.isShowSet = true;
        break;
      case 'code':
        item.isShowChangeCode = true;
        break;
      case 'permissions':
        item.isShowChangePermissions = true;
        break;
    }
  }
  resetShowBlocks(item: IUser) {
    item.isShowPoint = false;
    item.isShowRole = false;
    item.isShowReset = false;
    item.isShowBlock = false;
    item.isShowSet = false;
    item.isShowChangeCode = false;
    item.isShowChangePermissions = false;
  }
  setActiveBlock(item: IUser) {
    const notActiveBlock = !item.isShowPoint
    && !item.isShowRole
    && !item.isShowReset
    && !item.isShowBlock
    && !item.isShowSet
    && !item.isShowChangeCode;
    item.isShowChangePermissions = false;
    if (notActiveBlock) {
      item.isShowPoint = true;
    }
  }

  // Показ меню для текущего сотрудника
  dropDownUser(id: any) {
    const item = this.users.find(i => i.id === id);
    if (item.isShowDropDown) {
      item.isShowDropDown = false;
    } else {
      item.isShowDropDown = true;
     this.setActiveBlock(item);
    }
    this.changeDropDown(id);
  }

  // Переход к меню другого сотрудника
  changeDropDown(id: string): void {
    for (const item of this.users) {
      if (item.id !== id) {
        item.isShowDropDown = false;
      }
    }
  }

  applyFilter() {
    this.filter.page = 1;
    this.refresh.emit();
  }

  editUserAccess(user: IUser): boolean {

    if (this.authService.inRole(UserRoles.ExecutiveOfficer))
      return true;

    if (this.authService.inRole(UserRoles.Administrator))
        return user.roles.findIndex(x => x === UserRoles.Administrator || x === UserRoles.ExecutiveOfficer || x === UserRoles.FinancialDirector) < 0

    if (this.authService.inRole(UserRoles.ManagerPlus))
      return user.roles.findIndex(x => x === UserRoles.Administrator || x === UserRoles.ExecutiveOfficer || x === UserRoles.FinancialDirector || x === UserRoles.ManagerPlus) < 0

    return false;
  }
}
