import { Component, OnInit } from '@angular/core';
import { CashpoolingAdData } from '../../../models/widgets/cashpooling-ad-data';

@Component({
  selector: 'app-cashpooling-ad',
  templateUrl: './cashpooling-ad.component.html',
  styleUrls: ['./cashpooling-ad.component.scss']
})
export class CashpoolingAdComponent implements OnInit {

  public isLoadingCompleted: boolean = true;
  
  public hasError: boolean = false;

  public error: string;

  public hideWidget: boolean = true;

  constructor(
  ) { }

  ngOnInit() {
  }

}
