import { PaginationFilter } from "tsuz-common";

export class LicenseeFilter  extends  PaginationFilter {
  licenseeId: string;
  categories: string[];
  organizationName: string;
  organizationInn: string;
  directorName: string;
  isActive?: boolean;
}
