import { Component, OnInit } from '@angular/core';
import { CashpoolingAdData } from '../../models/widgets/cashpooling-ad-data';
import { WidgetService } from '../../services/widget.service';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent
  implements OnInit {
    public hideCashpoolingWidget: boolean = true;
    
  
    constructor(
      private widgetService : WidgetService
    )
    { }

    async ngOnInit() {
      const cashpoolingData = await this.widgetService.getCashpoolingAdData().catch(err => {
        console.error(err);
        this.hideCashpoolingWidget = true;
      });
      
      if (cashpoolingData as CashpoolingAdData) {
        this.hideCashpoolingWidget = (cashpoolingData as CashpoolingAdData).cashpoolingIsSetUp;
      }
    }
}