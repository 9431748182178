import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UploadedFileList } from '../../upload-files/UploadedFileList';
import { UploadedFileListChangedEvent } from '../../upload-files/UploadedFileListChangedEvent';
import { UserRoles } from '../../../models/auth/user-roles';

@Component({
  selector: 'tsuz-points-spreadsheet-actions',
  templateUrl: './points-spreadsheet-actions.component.html',
  styleUrls: ['./points-spreadsheet-actions.component.scss']
})
export class PointsSpreadsheetActionsComponent implements OnInit {

  @Output() download = new EventEmitter<string>();
  @Output() upload = new EventEmitter<FormData>();

  readonly userRoles = UserRoles;

  constructor() { }

  ngOnInit(): void {
  }

  public pointsFileList: UploadedFileList = new UploadedFileList();

  onPointsFileChanged(event: UploadedFileListChangedEvent): void {
    const uploadedFileList = event.uploadedFileList;
    const file = uploadedFileList.hasNonEmptyFile ? uploadedFileList.files[0].file : null;
    if (file) {
      const formData = new FormData();
      formData.append('PointsFile', file);
      this.upload.emit(formData);
    }
    
    // Clear uploaded file list to upload again
    this.pointsFileList = new UploadedFileList();
  }

  readonly pointsFileFormat = 
`Наименование;Адрес;Часовой пояс(IANA)
название_точки1;адрес_точки1;часовой_пояс_точки1
название_точки2;адрес_точки2;часовой_пояс_точки2
название_точкиN;адрес_точкиN;часовой_пояс_точкиN
`;

}
