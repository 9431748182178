import { Component, Input, OnInit } from '@angular/core';
import { ILicenseeWidgetInfoModel } from '../../../models/holding-structure/ILicenseeWidgetInfoModel';

@Component({
  selector: 'app-licensee-widget',
  templateUrl: './licensee-widget.component.html',
  styleUrls: ['./licensee-widget.component.scss', '../holding-structure.component.scss']
})
export class LicenseeWidgetComponent implements OnInit {

  @Input()
  licenseeInfo: ILicenseeWidgetInfoModel;

  ngOnInit() {
  }

}
