import { Injectable } from '@angular/core';
import { ILicenseeWidgetInfoModel } from '../../models/holding-structure/ILicenseeWidgetInfoModel';
import { INominalAccountStateModel } from '../../models/holding-structure/INominalAccountStateModel';
import { ISummaryInfoModel } from '../../models/holding-structure/ISummaryInfoModel';
import { BaseHttpService } from 'tsuz-common';

@Injectable()
export class HoldingStructureService {

  constructor(private baseHttpService: BaseHttpService) { }

  async getNominalAccountState() : Promise<INominalAccountStateModel> {
    return this.baseHttpService.get('/api/holdingstructure/nominal_account_state').toPromise();
  }

  async getLicensees(): Promise<ILicenseeWidgetInfoModel[]> {
    return this.baseHttpService.get('/api/holdingstructure/licensees_info').toPromise();
  }

  async getSummaryInfo() : Promise<ISummaryInfoModel> {
    return this.baseHttpService.get('/api/holdingstructure/summary_data').toPromise();
  }
}