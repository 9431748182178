import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Сервис для определения вида бокового меню
@Injectable()
export class MenuService {
  
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  display(value: boolean) {
    this.status.next(value);
  }

}
