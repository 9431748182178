import { DateTime } from "luxon";
import { DateRange } from "../filters/date-range";

export interface ITimeZoneId {
  timeZoneId: string;
}

export class DateHelper {

  public static get localTimeZoneId(): string {
    return DateTime.local().zoneName;
  }
  
  /**
   * Returns timeZoneId if 'obj' implements ITimeZoneId.
   * Otherwise returns defaultValue.
   * If defaultValue is not specified, returns localTimeZoneId
   */
  public static getTimeZoneId(obj: any, defaultValue: string = undefined): string {
    return (obj as ITimeZoneId)?.timeZoneId ?? defaultValue ?? DateHelper.localTimeZoneId;
  }

  /**
   * Create luxon DateTime from any of the next types: DateTime, Date, string
   */
  public static getDateTime(date: DateTime | Date | string | null): DateTime | null {
    if (!date) return null;
    if (date instanceof Date) return DateTime.fromJSDate(date);
    if (date instanceof DateTime) return date;
    return DateTime.fromISO(date);
  }

  /**
   * Takes date and applies TimeZone to it while keeping local time the same
   * @param date - Date where TimeZone should be applied. Can be of type: DateTime, Date, string
   * @param timeZoneId - IANA TimeZone ID ('Europe/Moscow', 'Asia/Almaty')
   * @returns luxon DateTime object with the same local time and with TimeZone set to timeZoneId
   */
  public static replaceTimeZone(date: DateTime | Date | string | null, timeZoneId: string): DateTime | null {
    const dateTime = this.getDateTime(date);
    const result = dateTime?.setZone(timeZoneId, { keepLocalTime: true });
    return result;
  }

  /** Adjust the date to the start of the week (Monday) */
  public static startOfWeek(date: DateTime | Date | string | null): DateTime | null {
    if (!date) {
      return null;
    }
    const dateTime = DateHelper.getDateTime(date);
    const result = dateTime.startOf('week');
    return result;
  }

  /** Adjust the date to the start of the week (Sunday) */
  public static endOfWeek(date: DateTime | Date | string | null): DateTime | null {
    return this.startOfWeek(date)?.plus({ days: 6 }).endOf('day');
  }

  /** Get period starting from the dateFrom start of the day till dateTo end of the day */
  public static getDateRange(dateFrom: DateTime | Date | string | null, dateTo: DateTime | Date | string | null): DateRange {
    const from = DateHelper.getDateTime(dateFrom)?.startOf('day').toJSDate();
    const to = DateHelper.getDateTime(dateTo)?.endOf('day').toJSDate();

    const dateRange = new DateRange('dateFrom', 'dateTo', {
      beginJsDate: from,
      endJsDate: to
    });

    return dateRange;
  }

  /**
   * Get today's period from start to end of the day
   * @returns {DateRange} The date range for today.
   */
  public static getTodayDateRange(): DateRange {
    const today = DateTime.local();
    return this.getDateRange(today, today);
  }

  /**
   * Get yesterday's period from start to end of the day
   * @returns {DateRange} The date range for yesterday.
   */
  public static getYesterdayDateRange(): DateRange {
    const yesterday = DateTime.local().minus({ days: 1 });
    return this.getDateRange(yesterday, yesterday);
  }

  /**
   * Get the current week's period from the start of the week till today
   * @returns {DateRange} The date range for the current week.
   */
  public static getCurrentWeekDateRange(): DateRange {
    const dateFrom = this.startOfWeek(DateTime.local());
    return this.getDateRange(dateFrom, DateTime.local());
  }

  /**
   * Get the previous week's period from start to end of the week
   * @returns {DateRange} The date range for the previous week.
   */
  public static getPreviousWeekDateRange(): DateRange {
    const dateFrom = this.startOfWeek(DateTime.local().minus({ weeks: 1 }));
    const dateTo = this.endOfWeek(dateFrom);
    return this.getDateRange(dateFrom, dateTo);
  }

  /**
   * Get the current month's period from the start of the month till today
   * @returns {DateRange} The date range for the current month.
   */
  public static getCurrentMonthDateRange(): DateRange {
    const dateFrom = DateTime.local().startOf('month');
    return this.getDateRange(dateFrom, DateTime.local());
  }

  /**
   * Get the previous month's period from start to end of the month
   * @returns {DateRange} The date range for the previous month.
   */
  public static getPreviousMonthDateRange(): DateRange {
    const dateFrom = DateTime.local().minus({ months: 1 }).startOf('month');
    const dateTo = dateFrom.endOf('month');
    return this.getDateRange(dateFrom, dateTo);
  }
}