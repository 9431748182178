export class NumberRange {
  numberFrom?: number;
  numberTo?: number;

  queryNameFrom: string;
  queryNameTo: string;

  constructor(queryNameFrom: string, queryNameTo: string, numberFrom?: number, numberTo?: number) {
    this.numberFrom = numberFrom;
    this.numberTo = numberTo;

    this.queryNameFrom = queryNameFrom;
    this.queryNameTo = queryNameTo;
  }
}
