import { AbstractControl, ValidatorFn } from '@angular/forms';

//Валидатор электронной почты
export function CheckEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { 'pattern': boolean } | null => {
    if (!control.value) {
      return null;
    }

    const emailPattern = new RegExp('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');
    return emailPattern.test(control.value) ? null : { 'pattern': true };
  };
}

