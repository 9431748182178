import {Component, ElementRef, forwardRef, Renderer2, ViewChild} from '@angular/core';
import { AbstractTextInput } from 'tsuz-common';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'simple-input',
  templateUrl: './simple-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SimpleInputComponent),
    multi: true,
  }]
})

export class SimpleInputComponent extends AbstractTextInput {
  constructor(private _renderer: Renderer2) {
    super();
  }

  get inputElement(): ElementRef {
    return this._inputElement;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._inputElement.nativeElement, 'disabled', isDisabled);
  }
}
