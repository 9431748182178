<div class="pagination">

  <div class="pagination__text">
    Выводить по:
  </div>
 <app-select-box-control [items]="[5, 10, 15, 20, 50]"
                         [(ngModel)]="_model.take"
                         (change)="_model.page = 1;onApply('take')">
 </app-select-box-control>

  <span class="d-mob-none"  >&nbsp;&nbsp;&nbsp;Всего записей
    {{_model.page * _model.take > _model.total ? _model.total :_model.page * _model.take }} из
    {{_model.total}}</span>

  <a class="pagination__btn pagination__btn--previous" (click)="prevPage()"></a>
  <a class="pagination__btn pagination__btn--next"  (click)="nextPage()"></a>
</div>
