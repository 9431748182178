import { Component, OnInit, Input, Inject } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { SimpleModalService } from "ngx-simple-modal";
import { ChangeCodeConfirmComponent } from "./change-confirm/change-confirm.component";
import { ConfirmCodeValidator } from "../../../validators/confirm-code.validator";
import { IUser } from "../../../models/IUser";
import { NotificationService } from "../../../services/notification.service";
import { IUserSettingService } from "../../../services/interfaces/IUserSettingService";
import { UserRoles } from "../../../models/auth/user-roles";
import { AuthService } from "../../../services/auth.service";
import { getChannelNameMessageByCode, getSuccessMessage, notifyTitle } from "./change-code-messages";

@Component({
  selector: '[change-code]',
  templateUrl: './change-code.component.html'
})
export class ChangeUserCodeComponent implements OnInit {
  codeForm: FormGroup;
  @Input() user: IUser;
  @Input() readonly: boolean = false;

  constructor(
    private simpleModalService: SimpleModalService,
    @Inject('UserSettingsService') private userSettingService: IUserSettingService,
    private notifyService: NotificationService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.codeForm = new FormGroup({
        Code: new FormControl('', [Validators.required, ConfirmCodeValidator()]),
        ReEnterCode: new FormControl('', [Validators.required, ConfirmCodeValidator()]),
      },
      this.codeMatchValidator,
    );
  }

  codeMatchValidator(g: FormGroup) {
    return g.get('Code').value === g.get('ReEnterCode').value
      ? null
      : { mismatch: true };
  }

  async sendConfirmCode() {
    if (this.codeForm.valid) {
      await this.sendConfrimCodeAndOpenConfirmModal();
    }
  }

  async sendConfimCodeOnReset() {
    this.codeForm.reset();
    await this.sendConfrimCodeAndOpenConfirmModal();
  }

  async sendConfrimCodeAndOpenConfirmModal() {
    if (this.authService.inRole(UserRoles.ExecutiveOfficer)) {
      this.changeForExecutiveOfficer();
      return;
    }

    try {
      await this.userSettingService.sendConfirmCode(this.user.id).toPromise();
      const disposable = this.simpleModalService
      .addModal(
        ChangeCodeConfirmComponent,
        {
          title: "Изменение кода подтверждения",
          message: "Введите код подтвержедния",
          user: this.user,
          newCode: this.codeForm.value.Code,
        },
        { closeOnClickOutside: true }
      )
      .subscribe(resultActive => {
        this.codeForm.reset();
      });
     } catch (error) {
       this.notifyService.errorNotify(error , 'Ошибка отправки код подтверждения');
     }
  }

  // для суперадмина не нужен confirm code
  changeForExecutiveOfficer() {
    const defaultCode = this.codeForm.value.Code;
    this.userSettingService.changeCode(null, this.user.id, defaultCode)
      .subscribe(result => {
        this.notifyService.successNotify(getSuccessMessage(defaultCode), notifyTitle);
        const message = getChannelNameMessageByCode(result.actualChannel);
        this.notifyService.warningNotify(message, notifyTitle);
        this.codeForm.reset();
    }, error => this.notifyService.errorNotify(error , 'Ошибка отправки код подтверждения'));
  }

}
