<app-base-widget
[header]="'Лимиты'"
[icoType]="'chart'"
[isLoading]="!isLoadingCompleted"
[hasError]="hasError"
[loadingError]="error"
[primaryButtonRoute]="'/points'"
>
  <div class="limits-widget-component widget-content-columns-container">
    <div class="widget-content-column-100">
      <div class="widget-content-row-left label-amount rub-currency">
        {{limitsData.restAmount | money}}
      </div>
      <div class="widget-content-row-left widget-content-bottom-gap label-info-small">
        Суммарные остатки суточных лимитов
      </div>
      <div class="widget-content-row-left widget-content-bottom-gap">
      </div>
      <div class="widget-content-row-left widget-content-bottom-gap">
      </div>
      <div class="widget-content-row-left label-info-small widget-content-bottom-gap">
        <div class="widget-content-row-left" style="width: 40%; align-items: baseline;" >
          <span class="info-dot-container inline-label" style="align-items: center;">
            <div class="info-dot over-limit-dot"></div>
          </span>
          <span class="inline-label label-info-bold-dark">
            {{limitsData.overLimit}}
          </span>
          <span class="inline-label label-info-bold-light">
            ПЗУ
          </span>
        </div>
        <div class="widget-content-row-left" style="width: 60%;  align-items: center;" >
          превышен лимит
        </div>
      </div>

      <div class="widget-content-row-left label-info-small widget-content-bottom-gap">
        <div class="widget-content-row-left" style="width: 40%; align-items: baseline;" >
          <span class="info-dot-container inline-label" style="align-items: center;">
            <div class="info-dot less-ten-percentes-dot"></div>
          </span>
          <span class="inline-label label-info-bold-dark">
            {{limitsData.lessTen}}
          </span>
          <span class="inline-label label-info-bold-light">
            ПЗУ
          </span>
        </div>
        <div class="widget-content-row-left" style="width: 60%;  align-items: center;" >
          остаток лимита<10%
        </div>
      </div>

      <div class="widget-content-row-left label-info-small">
        <div class="widget-content-row-left" style="width: 40%; align-items: baseline;" >
          <span class="info-dot-container inline-label" style="align-items: center;">
            <div class="info-dot greater-ten-percentes-dot"></div>
          </span>
          <span class="inline-label label-info-bold-dark">
            {{limitsData.greaterTen}}
          </span>
          <span class="inline-label label-info-bold-light">
            ПЗУ
          </span>
        </div>
        <div class="widget-content-row-left" style="width: 60%;  align-items: center;" >
          остаток лимита>10%
        </div>
      </div>
    </div>
  </div>
</app-base-widget>
