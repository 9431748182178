import { ILicenseeInfo } from './../../../../models/supportdeal/ISupportDealInfo';
import { IDealDetailsViewModel } from './../../../../models/peopledeal/IDealDetailsViewModel';
import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IAccountingDocuments } from '../../../../models/peopledeal/IAccountingDocuments';
import { CurrencyService } from '../../../../core/services/currency.service';

export interface ConfirmModel {
  title: string;
  message: string;
  supportDealInfo: IDealDetailsViewModel;
  licenseeInfo: ILicenseeInfo
  pointName: string;
  // file: any;
}

@Component({
  selector: 'app-deal-modal-info',
  templateUrl: './deal-modal-info.component.html'
})
export class DealModalInfoComponent extends SimpleModalComponent<ConfirmModel, IDealDetailsViewModel> implements ConfirmModel, OnInit {
  supportDealInfo: IDealDetailsViewModel;
  licenseeInfo: ILicenseeInfo;
  title: string;
  message: string;
  pointName: string;
  currency: string;

  constructor(private currencyService: CurrencyService) {
    super();
  }

  ngOnInit() {
    this.currency = this.currencyService.getProfileCurrencySymbol();
  }
}
