import { UploadedFile } from "./UploadedFile";

export class UploadedFileList {
  public errors: string[] = null;
  public files: UploadedFile[] = [];

  constructor(fileList: UploadedFile[] = [], errors: string[] = null) {
    this.files = fileList;
    this.errors = errors;
  }

  public getIsValid(): boolean {
    return !this.errors?.length && (!this.files || !this.files.find(f => f.errors?.length));
  }

  public get hasNonEmptyFile() : boolean {
    return this.files.length && this.files[0].file.size > 0;
  }

  public get firstUploadedFile() : UploadedFile {
    return this.files?.length ? this.files[0] : null;
  }
}
