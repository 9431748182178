import { Component, OnInit } from '@angular/core';
import { INominalAccountStateModel } from '../../models/holding-structure/INominalAccountStateModel';
import { HoldingStructureService } from '../../core/services/holding-structure.service';
import { ILicenseeWidgetInfoModel } from '../../models/holding-structure/ILicenseeWidgetInfoModel';
import { ISummaryInfoModel } from '../../models/holding-structure/ISummaryInfoModel';

@Component({
  selector: 'app-holding-structure',
  templateUrl: './holding-structure.component.html',
  styleUrls: ['./holding-structure.component.scss']
})
export class HoldingStructureComponent implements OnInit {

  nominalAccountError: string;
  nominalAccountLoadHasError: boolean;
  nominalAccountLoadCompleted: boolean = false;
  nominalAccountIsHidden: boolean = false;

  summaryInfoError: string;
  summaryInfoLoadHasError: boolean;
  summaryInfoLoadCompleted: boolean = false;
  summaryInfoIsHidden: boolean = true;
  summaryInfo: ISummaryInfoModel;

  licenseesError: string;
  licenseesLoadHasError: boolean;
  licenseesInfoLoadCompleted: boolean = false;
  licenseesInfo: ILicenseeWidgetInfoModel[];
  cashPoolingLicenseesInfo: ILicenseeWidgetInfoModel[];

  constructor(private holdingStructureService: HoldingStructureService) { }

  async ngOnInit() {
    await Promise.all([
      await this.loadHeadWidgetsData(),
      await this.loadLicenseeData()
    ]);
  }
  
  async loadHeadWidgetsData() {
    let nominalAccountState = await this.holdingStructureService.getNominalAccountState()
    .catch(err => {
      this.nominalAccountError = err;
      this.nominalAccountLoadHasError = true;
    });
    this.nominalAccountLoadCompleted = true;
    this.nominalAccountIsHidden = !this.nominalAccountLoadHasError ?
      (nominalAccountState as INominalAccountStateModel).accountIsConnected : false;
    this.summaryInfoIsHidden = !this.nominalAccountIsHidden;

    if (this.nominalAccountIsHidden) {
      let summaryInfoResponse = await this.holdingStructureService.getSummaryInfo()
      .catch(err => {
        this.summaryInfoError = err.message;
        this.summaryInfoLoadHasError = true;
      });
      this.summaryInfo = summaryInfoResponse as ISummaryInfoModel;
      this.summaryInfoLoadCompleted = true;
    }
  }

  async loadLicenseeData() {
    let licenseeInfoResponse = await this.holdingStructureService.getLicensees()
    .catch(err => {
      this.licenseesError = err;
      this.licenseesLoadHasError = true;
    });
    this.licenseesInfoLoadCompleted = true;
    const allLicensees = licenseeInfoResponse as ILicenseeWidgetInfoModel[];
    this.cashPoolingLicenseesInfo = allLicensees.filter(l => l.hasTransitAccount);
    this.licenseesInfo = allLicensees.filter(l => !this.cashPoolingLicenseesInfo.includes(l))
  }
}
