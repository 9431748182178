import { DocumentsListModel } from "./../../models/documents/document-list.model";
import { Component, OnInit } from "@angular/core";
import { DocumentService } from "@services/document.service";
import { LoaderService, LicenseeModel, NotificationService, NumberRange } from "tsuz-common";
import {DocumentsFilter} from '../../models/documents/documents-filter';
import {TransactionTypesLabel} from '../../models/documents/transaction-types.enum';

@Component({
  selector: "document-list",
  styleUrls: ['./document-list.component.scss'],
  templateUrl: "./document-list.component.html"
})
export class DocumentListComponent implements OnInit {
  constructor(
    private documentService: DocumentService,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
  ) {}
  listDocuments: DocumentsListModel;
  filter: DocumentsFilter;
  transactionTypes = [];
  hoverSum = false;
  groupDocumentByDate = false;
  numberRange: NumberRange = new NumberRange('summFrom', 'summTo');
  selectedLicensee: LicenseeModel;

  ngOnInit(): void {
    TransactionTypesLabel.forEach((value, key) => {
      this.transactionTypes.push({
        id: key,
        title: value
      });
    });

   this.initFilter();
  }

  async getDocuments() {
    this.loaderService.display(true);
    try {
      this.filter.sumFrom = this.numberRange.numberFrom;
      this.filter.sumTo = this.numberRange.numberTo;
      const result = await this.documentService.getDocumnets(this.filter).toPromise();
      this.listDocuments = result as DocumentsListModel;
      this.filter.total = this.listDocuments.total;
    } catch (error) {
      this.notificationService.errorNotify('Ошибка полученния данных', 'Ошибка');
    } finally {
      this.loaderService.displayFullWidth(false);
    }
  }
  resetFilters() {
    const licenseeId = this.filter.licenseeId;
    this.initFilter();
    this.filter.licenseeId = licenseeId;
    this.hoverSum = false;
    this.getDocuments();
  }

  onChangeLicensee(): void {
    this.resetFilters();
  }

  initFilter() {
    this.filter = new DocumentsFilter();
    this.filter.type = this.transactionTypes[0].id;
  }
}
