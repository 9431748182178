import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
  selector: 'app-validation-error-item',
  templateUrl: './validation-error-item.component.html',
  styleUrls: ['./validation-error-item.component.scss']
})
export class ValidationErrorItemComponent implements OnInit {

  @Input()
  formCtrl: AbstractControl;

  @Input()
  errorKey: string;

  constructor() { }

  ngOnInit() {
  }

}
