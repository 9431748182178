import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadedFile } from './UploadedFile';
import { UploadedFileList } from "./UploadedFileList";
import { UploadedFileListChangedEvent } from './UploadedFileListChangedEvent';

@Component({
  selector: 'tsuz-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements  OnInit {
  
  private _uploadedFileList: UploadedFileList = new UploadedFileList();
  public get uploadedFileList(): UploadedFileList { return this._uploadedFileList; }
  @Input()
  public set uploadedFileList(value: UploadedFileList) {
    this._uploadedFileList = value;
    this.onFileListChanged(null, null);
  }
  
  private _minFileCount: number = 1;
  public get minFileCount(): number { return this._minFileCount; }
  @Input()
  public set minFileCount(value: number) {
    this._minFileCount = value;
    if (this._minFileCount > this._maxFileCount) {
      this._maxFileCount = this._minFileCount;
    }

    this.validate();
  }

  private _maxFileCount: number = 1;
  public get maxFileCount(): number { return this._maxFileCount; }
  @Input()
  public set maxFileCount(value: number) {
    if (value < 1) {
      value = 1;
    }
    this._maxFileCount = value;
    if (this._maxFileCount < this._minFileCount) {
      this._minFileCount = this._maxFileCount;
    }

    this.validate();
  }
  
  public readonly defaultMaxFileSize: number = 2_000_000;
  private _maxFileSize: number = this.defaultMaxFileSize;
  public get maxFileSize(): number { return this._maxFileSize; }
  @Input()
  public set maxFileSize(value: number) {
    if (this._maxFileSize === value) {
      return;
    }
    if (value <= 0) {
      value = this.defaultMaxFileSize;
    }
    this._maxFileSize = value;
    
    this.validate();
  }
  
  @Input()
  public readonly: boolean = false;
  
  @Input()
  public tooltip: string = null;

  @Input()
  public labelText: string = null;

  @Input()
  public acceptFileTypes: string[];

  @Output()
  fileListChanged = new EventEmitter<UploadedFileListChangedEvent>();

    // Workaround: Если выбирать тот же самый файл, то <input type="file" /> не дёргает "change" event
    // https://stackoverflow.com/questions/40165271/how-to-reset-selected-file-with-input-tag-file-type-in-angular-2
    @ViewChild('uploadFiles') uploadFilesElementRef: ElementRef;

  constructor() {
  }

  ngOnInit() {
    this.labelText = this.labelText ?? 'Загрузить файл.'; 
    this.acceptFileTypes = this.acceptFileTypes ??  ['application/pdf', 'image/png', 'image/tiff', ' image/jpg', 'image/jpeg'];
  }

  private formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0B';
    const base = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб'];

    const i = Math.floor(Math.log(bytes) / Math.log(base));

    return `${parseFloat((bytes / Math.pow(base, i)).toFixed(dm))}${sizes[i]}`;
  }

  private addFileError(uploadedFile: UploadedFile, errorMessage: string): void {
    if (!uploadedFile.errors) {
      uploadedFile.errors = [];
    }
    uploadedFile.errors.push(errorMessage);
  }

  private validateUploadedFile(uploadedFile: UploadedFile): void {
    uploadedFile.errors = [];
    if (uploadedFile.isFileAlreadyUploaded) {
      return;
    }
    if (uploadedFile.file.size > this.maxFileSize) {
      this.addFileError(uploadedFile,`Размер файла не должен превышать ${this.formatBytes(this.maxFileSize)}`);
    }
    if (!this.acceptFileTypes.includes(uploadedFile.file.type)) {
      this.addFileError(uploadedFile,`Неверный тип файла`);
    }
  }

  private addError(errorMessage: string): void {
    if (!this._uploadedFileList.errors) {
      this._uploadedFileList.errors = [];
    }
    this._uploadedFileList.errors.push(errorMessage);
  }

  private validate(): void {
    this.uploadedFileList.files.forEach(fi => this.validateUploadedFile(fi));

    this._uploadedFileList.errors = [];
    const count = this.uploadedFileList.files.length;
    if (count < this.minFileCount) {
      if (this.minFileCount === 1 && this.maxFileCount === 1) {
        this.addError(`Файл не загружен. Максимальный размер ${this.formatBytes(this.maxFileSize)}`);
      } else {
        this.addError(`Минимальное количество файлов: ${this.minFileCount}`);
      }
    } else if (count > this.maxFileCount) {
      this.addError(`Максимальное количество файлов: ${this.maxFileCount}`);
    }
  }

  private addUploadedFile(file: File): number {
    const uploadedFile = new UploadedFile(file);
    let index = this.uploadedFileList.files.findIndex(fi => fi.file.name === uploadedFile.file.name);
    if (index < 0) {
      if (this.uploadedFileList.files.length >= this.maxFileCount) {
        return -1;
      }
      this.uploadedFileList.files.push(uploadedFile);
      index = this.uploadedFileList.files.length - 1;
    } else {
      this.uploadedFileList[index] = uploadedFile;
    }

    return index;
  }

  public onFilesSelected(event: any): void {
    const files: FileList = event.target.files;
    if (!files) return;
    const addedfiles: UploadedFile[] = [];
    for (let i = 0; i < files.length;  i++) {
      const file = files[i];
      if (file.size < 1) {
        continue;
      }
      const index = this.addUploadedFile(file);
      addedfiles.push(UploadedFileList[index]);
    }

    this.onFileListChanged(addedfiles, null);
  }

  public removeFile(uploadedFile: UploadedFile): void {
    const index = this.uploadedFileList.files.findIndex(fi => fi.file.name === uploadedFile.file.name);
    if (index < 0) {
      return;
    }
    const deletedFiles = this.uploadedFileList.files.splice(index, 1);

    this.onFileListChanged(null, deletedFiles);
  }

  private onFileListChanged(addedfiles: UploadedFile[], deletedfiles: UploadedFile[]): void {
    if (this.uploadFilesElementRef?.nativeElement) 
    {
      this.uploadFilesElementRef.nativeElement.value = '';
    }

    this.validate();

    const event = new UploadedFileListChangedEvent(addedfiles, deletedfiles, this.uploadedFileList);

    this.fileListChanged.emit(event);
  }
}
