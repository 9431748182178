
<div id="id1556096527"
      class="loading-modal__wrapper">

  <button class="loading-modal__close"
          type="button"
          (click)="close()">
    <span>×</span>
  </button>

  <div class="loading-modal__header">
   {{title}}
  </div>

  <div class="loading-modal__body">
    <p>
     {{message}}
    </p>
  </div>

  <div class="loading-modal__busy">
    <div class="app-loading">
      <div class="logo"></div>
      <svg class="spinner"
           viewBox="25 25 50 50">
        <circle class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"></circle>
      </svg>
    </div>
  </div>

</div>
