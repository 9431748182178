<label for="id" class="label empty">{{labelName}}</label>
<input
  mask="000000"
  class="input min-bored"
  [ngClass]="{'input--nonvalidate': formCtrl.dirty && formCtrl?.invalid  ,'input--validate': formCtrl?.valid}"
  [value]="value"
  type="text"
  [id]="id"
  [placeholder]="placeholder"
  (change)="onChange($event)"
  (keyup)="onKeyup($event)"
  (focusout)="onFocusOut($event)"
  (blur)="onBlur($event)"
  >

  <span class="report report--false report--valid" [ngClass]="{'report--active': formCtrl?.hasError('required') == true}">*Поле является обязательным.</span>
  <span class="report report--false report--valid" [ngClass]="{'report--active': formCtrl?.hasError('maxLength') == true}">*Превышена максимальная длина поля.</span>
    
    