<div>
  <div class="dropdown__header">
    <h5 class="h5">Статус</h5>
  </div>
  <div class="dropdown__section">
    <div class="msg msg--blocking"> Сотрудник <span class="bold staff-name">{{user.surname}} {{user.name}}
        {{user.middleName}}</span><br> будет {{user.active ? "заблокирован" : "разблокирован"}} </div>
  </div>
  <div class="dropdown__section">
    <button class="btn btn--active" (click)="blockPost()" id="id1538406051">{{user.active ? "Заблокировать" : "Разблокировать"}}</button>
  </div>
</div>
