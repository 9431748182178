import { timer ,  Subscription } from 'rxjs';

export class Timer {

  private timemerSubscribe: Subscription;
  currentValue: string;
  stopped = false;

  constructor(private event: () => void) { }

  // Инициализация таймера.
  startTimer() {
    this.stopTimer();
    this.stopped = false;
    let currentMin = 5;
    let currentSecond = 0;

    // Подпись на события таймера.
    this.timemerSubscribe = timer(0, 1000).subscribe(t => {
      this.event();

      if (currentSecond <= 0) {
        currentMin--;
        currentSecond = 59;
      }

      currentSecond--;

      if (currentMin <= 0 && currentSecond <= 0) {
        this.stopTimer();
      }

      this.currentValue = `${currentMin}:${currentSecond < 10 ? '0' + currentSecond : '' + currentSecond}`;
    });
  }

  // Остановка таймера.
  stopTimer() {

    if (this.timemerSubscribe) {
      this.timemerSubscribe.unsubscribe();
    }
    
    this.stopped = true;
  }
}
