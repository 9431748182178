<div class="row">
    <mat-form-field class="col-5">
        <mat-label>{{label}}</mat-label>
        <input
            matInput
            readonly
            #inputElement
            [value]="value"
            type="text"
            [id]="id"
            (change)="onChange($event)"
            (keyup)="onKeyup($event)"
            (focusout)="onFocusOut($event)"
            (blur)="onBlur($event)">
    </mat-form-field>
    
    <div class="col-7">
        <button mat-icon-button type="button" title="Скопировать" *ngIf="inputElement.value"
            [cdkCopyToClipboard]="inputElement.value"
            (cdkCopyToClipboardCopied)="notificationService.infoNotify('API ключ скопирован в буфер обмена, нажмите Ctrl+V для того, чтобы вставить его.', 'Скопировано в буфер')">
            <mat-icon>content_copy</mat-icon>
        </button>
        <button mat-icon-button type="button" title="Очистить" *ngIf="!readonly && inputElement.value" (click)="clearApiKey.emit()">
            <mat-icon>cleaning_services</mat-icon>
        </button>
        <button mat-icon-button type="button" title="Сгенерировать" *ngIf="!readonly" (click)="refreshApiKey.emit()">
            <mat-icon>autorenew</mat-icon>
        </button>
    </div>
</div>