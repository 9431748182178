<div class="table__header-cell table__header-cell--w70 filter__column">
    <app-select-filter
        [items]="statusItems"
        [(model)]="filter.isActive"
        [queryName]="'isActive'"
        (apply)="onApply()"
        placeholder="Статус"
        [paginationFilter]="filter">
        Статус
    </app-select-filter>
</div>

<div class="table__header-cell filter__column">
    <app-string-filter
        [(model)]="filter.title"
        (apply)="onApply()"
        queryName="title"
        placeholder="Название"
        [paginationFilter]="filter">
        Название
    </app-string-filter>
</div>

<div class="table__header-cell filter__column">
    <app-string-filter
        [(model)]="filter.address"
        (apply)="onApply()"
        queryName="address"
        placeholder="Адрес"
        [paginationFilter]="filter">
        Адрес
    </app-string-filter>
</div>

<div class="table__header-cell table__header-cell--w130">Суточный лимит</div>
<div class="table__header-cell table__header-cell--w130">Остаток суточного лимита</div>
<div class="table__header-cell table__header-cell--w130">Лимит на транзакцию</div>