import { IProductMeasure, IProductMeasureUnit } from './../../../models/peopledeal/IDetailsPeopleDeal';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SimpleModalComponent} from 'ngx-simple-modal';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup, ValidationErrors, ValidatorFn,
  Validators
} from '@angular/forms';
import {ProductValidateResult} from '../../../models/product/ProductValidateResult';
import {LicenseeService} from '@services/licensee.service';
import {DealService} from '@services/deal.service';
import {ValidateDate} from '../../../shared/validators/date.validator';
import {Router} from '@angular/router';
import {DecimalValidator} from '../../../shared/validators/decimalValidator';
import {Guid} from 'guid-typescript';
import {IPeopleDeal, IPeopleDealPay} from '../../../models/peopledeal/IPagePeopleDeal';
import {SmzInnValidator} from '../SmzInnValidator';
import { 
  UserRoles,
  AuthService,
  LoaderService,
  ILicenseeSetting,
  NotificationService,
  PhoneValidator,
  FioValidator,
  LengthValidator,
  HttpErrorService,
  BaseHttpService,
  UploadedFile,
  UploadedFileList,
  UploadedFileListChangedEvent
} from 'tsuz-common';
import {IGetPersonInfoRequest, IGetPersonInfoResponse, IPersonCard} from '../../../models/peopledeal/person';
import {Subject, Subscription, Observable, of} from 'rxjs';
import {PersonService} from '@services/person.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DealDraftViewModel, PeopleDealProductBinding } from '../../../models/peopledeal/DealDraftViewModel';
import {ReplaySubject} from "rxjs";
import {ILicenseeProduct} from "../../../models/peopledeal/IDetailsPeopleDeal";
import {IPointDeal} from "../../../models/peopledeal/IPointDeal";
import {IProvider} from "../../../models/peopledeal/IProvider";
import {ICardChecker} from "../../../models/peopledeal/ICardChecker";
import {IProviderAttributes} from "../../../models/peopledeal/IProviderAttributes";
import { CurrencyService } from '../../../core/services/currency.service';
import { ScanType } from 'app/core/enums/scan-type.enum';
import { environment } from 'environments/environment';

export interface ConfirmModel {
  title: string;
  message: string;
  initialFormValues: DealDraftViewModel;
  isEditingForm: boolean;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent
  extends SimpleModalComponent<ConfirmModel, boolean>
  implements ConfirmModel, OnInit, OnDestroy {
  
  userRoles = UserRoles;
  listprod: ILicenseeProduct[];
  listpoint: IPointDeal[];
  settingList: ILicenseeSetting;
  oneScreen = false;
  editDealNumber: string;
  isReady = false;
  isPointReady = false
  infoLimit: string;
  productMeasureUnits: Array<IProductMeasureUnit[]> = [];
  total = 0;
  array: FormArray;
  payForm: FormGroup;
  pointId: FormControl;
  errorMessage: string;
  step = 0;
  maxStep = 3;
  hasOne: ProductValidateResult = new ProductValidateResult(true);
  url = '/deal';
  defaultUnit: any;
  limitInfo: number;
  transcationInfo: number;
  currentLimitInfo: number;
  cash = 'Цена';
  nextButton = 'Далее';
  isSaveInDb = false;
  isPostAlready = false;
  dealPorNumber = 0;
  sumLoading = true;
  formSending = false;
  dealProvider: IProvider;
  responseDeal: IPeopleDeal;
  initialFormValues: DealDraftViewModel;
  isEditingForm: boolean;
  userId: string | null;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  private readonly scanPsaAttributeName = 'scanPsa';
  private readonly dealDateAttributeName = 'dealDate';
  private readonly disablePsaValidationAttributes = [this.scanPsaAttributeName];

  withdrawTypes = {
    bankCard: '976c6c2a-55e4-4010-b1ac-287d72b93136',
    cashTransferSystem: 'af1d371b-fc3b-48c8-9a1d-c6a508bdebcc'
  }

  /**
   * FROM PAY-CREATE
   */
    // Создание элементов загрузки сканов
  @ViewChild('uploaderPsa') inputElPsa: ElementRef;
  @ViewChild('uploaderPts') inputElPts: ElementRef;
  @ViewChild('uploaderPassport') inputElPassport: ElementRef;
  @ViewChild('uploaderDeal') inputElDeal: ElementRef;
  date: any;
  // Проверка уникальности номера ПП для КП
  isUniqueError: boolean;
  currentCountry: FormControl;
  passportErrorCheck = true;
  smzinnMask = '000000000000';
  smzinnPlaceholder = '000000000000';
  passportLengthError = true;
  dealPay: IPeopleDealPay;
  benificiare: FormControl;
  surname: FormControl;
  middleName: FormControl;
  passportseries: FormControl;
  smzinn: FormControl;
  passportfrom: FormControl;
  birthday: any;
  birthplace: FormControl;
  registrationAddress: FormControl;
  dealNumber: FormControl;
  dealDate: FormControl;
  state: FormControl;
  phone: FormControl;
  cardNumber: FormControl;
  sum: FormControl;
  externalId: FormControl;
  providerId: FormControl;
  sbpMemberId: FormControl;
  personId = '';
  providerAttributes: IProviderAttributes;
  limit = '0.00';
  currentLimit = '0.00';
  transactionLimit = '0.00';
  cardResult: ICardChecker;
  file: any;
  scanPsaControl: FormControl;
  cardResultCheck = true;
  checkCard = false;
  binDbQuery = false;
  smz = false;
  listProvider: IProvider[];
  concurrencyToken: number = 0;
  currency: string;

  title: string;
  message: string;

  isNewCardEnter = true;
  canGoToCardsSelection = false;
  personCards: IPersonCard[] = [];

  sbpMembers = [];

  private personRequestModelChanged: Subject<any> = new Subject<any>();
  private requestPersonInfoSubscription: Subscription;
  licenseeId: string;

  public maxScanFileSize: number = parseInt(environment.fileSize, 10);
  public scanPsaList: UploadedFileList = new UploadedFileList();
  public isScanPsaRequired: boolean = true;
  private deletedScanPsaIds: string[] = [];

  constructor(
    private httpService: BaseHttpService,
    private licenseeService: LicenseeService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private dealService: DealService,
    private notificationService: NotificationService,
    private readonly router: Router,
    private httpErrorService: HttpErrorService,
    private authService: AuthService,
    private personService: PersonService,
    private currencyService: CurrencyService,
    @Inject("LOCALSTORAGE") private localStorage: any,
  ) {
    super();
    this.userId = localStorage.getItem('userId');
  }

  ngOnInit(): void {
    this.licenseeId = this.localStorage.getItem('licenseeId');
    this.loaderService.displayFullWidth(true);

    this.dealService.GetSelfEmployedUsingState().pipe(takeUntil(this.destroy)).subscribe(
      result => {
        this.smz = result;
      },

      error => console.error(error));

    if (this.initialFormValues && this.initialFormValues.products.some(p => p.disabled)) {
      this.hasOne = new ProductValidateResult(false, 'Выбранный товар был удален. Выберите другой товар из списка.');
    }

    this.getSettings();
}

  ngOnDestroy(): void {
    this.requestPersonInfoSubscription.unsubscribe();
    if ((!this.payForm.dirty
    || this.isEditForm())
    && !this.isSaveInDb) {
      this.destroy.next(null);
      return;
    }

    if (this.isSaveInDb) {
      this.dealService.deleteDraft().pipe(takeUntil(this.destroy)).subscribe(() => this.destroy.next(null));
      return;
    }

    this.dealService.setDraft(this.getDraftModel()).pipe(takeUntil(this.destroy)).subscribe(result => {
      this.destroy.next(null);
    }, error => {
      console.error('Deal Draft Create Error');
      console.error(error);
      this.notificationService.errorNotify('', 'Ошибка создания черновика сделки');
      this.destroy.next(null);
    });
  }

  get IsAddProductButtonAvailable(): boolean {
    return this.settingList.licenseeType === 'Default' && this.hasOne.isValid && this.settingList.oneProduct === false;
  }

  get invalidStep(): number {
    const {fields} = this.httpErrorService;
    let keys: string[];

    keys = ['registrationAddress', 'benificiare', 'middlename', 'passportfrom', 'lastName'];
    if (keys.find(x => fields[x])) {
      return 2;
    }

    return 0;
  }

  onProviderChanged(provider: IProvider) {
    if (this.sbpMembers.length === 0 && provider.withdrawTypeId === this.withdrawTypes.cashTransferSystem) {
      this.dealService.getSbpMembers()
        .subscribe(response =>{
          this.sbpMembers = response.members;
        });
    }

    const newProvider = this.listProvider.find(p => p.id === provider.id);
    this.dealProvider = newProvider;

    this.initProviderAttributes();
    this.cardNumber.updateValueAndValidity();
    this.sbpMemberId.updateValueAndValidity();
  }

  getProviderWithdrawTypeId() {
    const provider = this.listProvider.find(p => p.id === this.providerId.value);
    return provider.withdrawTypeId;
  }

  private isRequiredByProvider(providerAttrKey: string): boolean {
    const providerAttribute = this.providerAttributes[providerAttrKey];
    if (!providerAttribute) {
      return false;
    }

    // Check if 'ILicenseeSettings.disablePsaValidation' is set for this attribute
    if (this.settingList.disablePsaValidation && this.disablePsaValidationAttributes.indexOf(providerAttrKey) >= 0) {
      return false;
    }

    const isRequired = providerAttribute.isRequired || providerAttribute.isGateRequired;
    
    return isRequired;
  }

  private calculateIsScanPsaRequired() {
    let isRequired = this.isRequiredByProvider(this.scanPsaAttributeName);
    this.isScanPsaRequired = isRequired;
  }

  private getScanPsaValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const requiredError: ValidationErrors = { 'required': true };
      if (this.isScanPsaRequired && !control?.value) {
        return requiredError;
      }
      const uploadedScanList = control.value as UploadedFileList;
      const isValid = uploadedScanList?.getIsValid();
      return isValid ? null : requiredError;
    };
  }

  private providerRequiredValidator(providerAttrKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let isRequired = this.isRequiredByProvider(providerAttrKey);
      isRequired = isRequired && !control.value;

      return isRequired ? {'required': true} : null;
    };
  }

  dealNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this.isUniqueError) {
        return {'dealNumberUniqError': true};
      }
      return null;
    };
  }

  getFieldError(fieldName:string): any {
    this.httpErrorService.getFieldError(fieldName);
  }

  getDefaultMeasure(id: string): IProductMeasure | null {
    const product = this.listprod.find(p => p.id === id);

    if (product == null)  return null;

    if (!product.measures || product.measures.length != 1)
    {
      this.notificationService.errorNotify(
        'Ошибка',
        'Невозможно однозначно определить систему измерения для продукта.'
      );

      return null;
    }

    return product.measures[0];
  }

  getDefaultMeasureUnit(id: string) : IProductMeasureUnit | null {
    var measure = this.getDefaultMeasure(id);

    if (!measure){
      return null;
    }

    var defaultMeasureUnit = measure.measureUnits.find(mu => mu.isDefault);
    if (!defaultMeasureUnit) {
      this.notificationService.errorNotify(
        'Ошибка',
        'Не определена ед. измерения по умолчанию для продукта.'
      );

      return null;
    }

    return defaultMeasureUnit;
  }

  getProductMeasures(productId: string): IProductMeasure[] {
    const product = this.listprod.find(p => p.id === productId);

    if (!product || !product.measures || product.measures.length < 1)
    {
      this.notificationService.errorNotify(
        'Ошибка',
        'Для не продукта не определено ни одной системы измерения.'
      );

      return [];
    }

    return product.measures;
  }

  getMeasureUnitPlaceholder(formControlIndex: number): any {
    const productId = this.payForm.controls.products.value[formControlIndex].productId;

    if (this.settingList.hideUnit) {
      return this.getDefaultMeasureUnit(productId)?.shortName;
    }
    // Получаем список форм по ключу
    const products = this.payForm.get('products') as FormArray;
    // Выбираем форму по индексу
    const productControls = products.controls[formControlIndex];

    if (productControls && !!this.productMeasureUnits[formControlIndex]) {
      // Из формы получаем значение веса по ключу
      const measureUnitValue = productControls.get('measureUnit')?.value;

      if (measureUnitValue) {
        // Если объект веса существует, возвращаем его короткое имя
        return 'Вес, ' + measureUnitValue;
      }
    }
  }

  getSettings(): void {
    /**
     * FROM PAY-CREATE
     */
    forkJoin([
        this.licenseeService.getSettings(this.licenseeId, true),
        this.dealService.getProducts(),
        this.dealService.getUserPoints(this.userId),
        this.dealService.getProviders()
      ]).pipe(
      map(([settingsResult, productsResult, userPointsResult, providersResut]) => {
        return { settingsResult, productsResult, userPointsResult, providersResut };
      }))
      .pipe(takeUntil(this.destroy))
      .subscribe(responses => {

      this.settingList = responses.settingsResult as ILicenseeSetting;

      if (this.isEditForm() && this.initialFormValues.dealSettings) {
        this.settingList = this.initialFormValues.dealSettings;
      }

      // Установка инфо для лимита точки
      this.infoLimit = `Выберите "${this.settingList.pointName}"`;

      // Выводим товары только для оператора
      if (this.authService.getMainRole() === UserRoles.Cashier) {
        this.listprod = (responses.productsResult as ILicenseeProduct[]).filter(x => x.forAll);
      }
      else {
        this.listprod = responses.productsResult as ILicenseeProduct[];
      }

      // Инициализация точек
      this.listpoint = responses.userPointsResult as IPointDeal[];
      this.initPoint();

      this.listProvider = responses.providersResut as IProvider[];

      // Установка для оплаты провайдера
      this.dealProvider = this.getDefaultProvider()

      // Очищаем список ошибок
      this.httpErrorService.clear();

      this.loaderService.displayFullWidth(false);

      /**
       * FROM PAY-CREATE
       */

      this.initProviderAttributes();
      this.initScanPsa();

      this.createFormControls(this.initialFormValues);
      this.createForm();

      // Проверка одного экрана для мобильного платежа
      this.checkOneScreen();
      // Деактивация кнопки далее/оплатить
      this.payForm.setErrors({invalid: true});

      this.initProductTable(this.initialFormValues);
      
      this.calculateIsScanPsaRequired();

      // Проверка формы
      this.check();

      this.isReady = true;
    }, error =>{
      console.error(error);
      console.log('Create deal Component not work');
    });

  }

  // Создание контрола для товаров
  initProductTable(draft: DealDraftViewModel) {
    if (this.settingList.hideDealProduct) {
      return;
    }
    if (draft && draft.products && draft.products.length > 0) {
      this.setProduct(draft);
    } else {
      this.addProduct();
    }
    const control = <FormArray>this.payForm.controls.products;
    for (let i = 0; i < control.controls.length; i++) {
      if (this.payForm.value.products[i].trash > 100) {
        <FormArray>control.controls[i]['controls'].trash.setValue(99);
      }
    }
  }

  setProduct(draft: DealDraftViewModel) {
    // Получение контрола товаров
    const control = <FormArray>this.payForm.controls.products;

    // Добавления товаров

    for (let i=0; i < draft.products.length; i++){
      const currentProduct = draft.products[i];
      const aviableProduct = this.listprod.filter(p => p.id === currentProduct.productId && !p.disabled );

      const productIndex = this.listprod.findIndex(p => p.id == currentProduct.productId);
      this.productMeasureUnits[i] = this.listprod[productIndex].measures.reduce((accumulator, value) => accumulator.concat(value.measureUnits), []);
      // Добавления товаров в массив контролов товаров
      control.push(this.fb.group({
        trash: currentProduct.trash,
        totalPrice: this.calculateProductTotalSum(currentProduct),
        productId: [{ value: aviableProduct.length > 0 ? currentProduct.productId : null , disabled: this.settingList.onlyIdenticalProduct ? true : false }],
        provider: draft.providerId,
        measureUnitValue: currentProduct.measureUnitValue,
        measureUnitPrice: currentProduct.measureUnitPrice,
        measureUnitShortName: currentProduct.measureUnitShortName,
        phone: draft.phone.formatPhoneShort(),
        onseScreen: currentProduct.oneScreen,
        groupKey: currentProduct.groupKey,
        measureUnit: new FormControl(this.productMeasureUnits[i].length > 0 ? currentProduct.measureUnitShortName : null, Validators.required)
      }));
    }

    // Проверка на мобильный платеж
    this.checkOneScreen();

    this.increase();
  }

  // Проверка одного экрана для мобильного платежа
  checkOneScreen() {
    const result = this.dealService.checkOneScreen(
      this.settingList,
      this.dealProvider,
      this.payForm.value.products,
      this.listprod
    );
    this.oneScreen = result[0];
    this.nextButton = result[1];
  }

  // Перерасчет суммы
  increase(): void {
    const products = this.payForm.getRawValue().products;
    if (products != null && products.length > 0) {
      const lastIndex = this.payForm.value.products.length - 1;
      const sum = this.calculateProductTotalSum(
        this.payForm.value.products[lastIndex]
      );

      if (!this.settingList.hideDealProduct) {
        this.payForm.controls.products
          // @ts-ignore
          .at(lastIndex)
          .patchValue({totalPrice: sum});
      }

      this.sumLoading = true;
      this.dealService
        .getTotalSum(products, this.settingList)
        .pipe(takeUntil(this.destroy))
        .subscribe(
          data => {
            this.total = data.totalSum;
            this.sumLoading = false;
            this.check();
          },
          error => {
            this.sumLoading = false;
            this.notificationService.errorNotify(
              'Ошибка',
              'Ошибка расчёта суммы товарного раздела'
            );
          }
        );
    }
  }

  // Проверка на положительность данных формы
  zeroAmount(event: any) {
    if (!event.target.value || (event.target.value && event.target.value < 0)) {
      event.target.value = 0;
    }
  }

  initPoint() {
    if (this.initialFormValues) {
      this.setPointInfo(this.initialFormValues.pointId);
    } else {
      this.setPointInfo('');
    }
  }

  // Получения инфо о выбранной точке
  checkPoint(event: any) {
    this.setPointInfo(event.id);
    this.check();
  }

  setPointInfo(pointId: string) {
    let index = 0;
    if (pointId) {
      index = this.listpoint.findIndex(x => x.id === pointId);
    }
    if (index > -1) {
      this.limitInfo = this.listpoint[index].limit;
      this.transcationInfo = this.listpoint[index].transactionLimit;
      this.currentLimitInfo = this.listpoint[index].currentLimit;
    }
  }

  // Проверка на валидность формы
  // this.hasOne = true Форма не валидна
  check() {
    if (!this.settingList.hideDealProduct) {
      this.hasOne = this.dealService.checkProducts(
        this.payForm.getRawValue().products,
        this.settingList,
        this.total
      );
    }
    this.checkCard = this.dealService.algLun(this.cardNumber.value);
    this.getCardInfo();
  }

  // Добавление товара
  addProduct() {
    if(this.settingList.onlyIdenticalProduct){
      this.addIdenticalProduct();
      return;
    }

    this.addEmptyProductControl();
    this.check();
  }

  addIdenticalProduct(){
    // Получение контрола товаров
    const rawProductsModel = <FormArray>this.payForm.getRawValue().products;
    const control = <FormArray>this.payForm.controls.products;
    // Добавления в массив реактивных форм нового контрола

    if(control.length > 0){
      const firstProductId = rawProductsModel[0].productId
      control.push(
        this.fb.group({
          measureUnitValue: null,
          trash: null,
          measureUnitPrice: null,
          totalPrice: 0,
          productId: [{ value: firstProductId , disabled: true }],
          measureUnit: null,
          provider: null,
          phone: null,
          groupKey: null
        })
      );
      this.changeProduct(firstProductId, control.length - 1);
    }
    else {
      this.addEmptyProductControl();
    }
    // Проверка формы на валидность
    this.check();
  }

  addEmptyProductControl(){
    const productControlsArray = <FormArray>this.payForm.controls.products;
    productControlsArray.push(
      this.fb.group({
        measureUnitValue: null,
        trash: null,
        measureUnitPrice: null,
        totalPrice: 0,
        productId: [],
        measureUnit: null,
        provider: null,
        phone: null,
        groupKey: null
      })
    );
  }

  // Изменение товара
  changeProduct(id: string, indexControl: number) {
    if (id) {
      // Ищем Id товара
      const index = this.listprod.findIndex(x => x.id == id);
      // Если находим, то добавляем в массив товаров новый
      if (index > -1) {
        const currentProduct = <FormArray>this.payForm.controls.products;

        currentProduct.controls[indexControl]['controls'].measureUnit.setValue(null);
        currentProduct.controls[indexControl]['controls'].measureUnitValue.setValue(null);
        currentProduct.controls[indexControl]['controls'].trash.setValue(null);
        currentProduct.controls[indexControl]['controls'].measureUnitPrice.setValue(null);

        currentProduct.controls[indexControl]['controls'].groupKey.setValue(this.listprod[index].groupKey);

        this.productMeasureUnits[indexControl] = this.listprod[index].measures.reduce((accumulator, value) => accumulator.concat(value.measureUnits), []);

        // Установка провайдера
        if ( this.listprod[index].hideProviders) {
          this.dealProvider = this.getDefaultProvider();
        } else {
          // Установка провайдера из списка
          if (this.payForm.value.products[0].provider) {
            const mainProvider = this.listProvider.find(x => x.id == this.payForm.value.products[0].provider);
            this.dealProvider = mainProvider as IProvider;
          }
        }

        if(this.settingList.hideUnit){
          currentProduct.controls[indexControl]['controls'].measureUnit.setValue(this.getDefaultMeasureUnit(id)?.shortName);
        }

        if(this.settingList.hideWeight){
          currentProduct.controls[indexControl]['controls'].measureUnitValue.setValue(1);
        }
      }
      // Проверка на мобильный платеж
      this.checkOneScreen();
      // Проверка на валидность формы
      this.check();
    }
  }

  // Удаления товаров
  deleteProduct(index) {
    console.log(index)
    const control = <FormArray>this.payForm.controls.products;
    control.removeAt(index);
    
    this.changeProductControlDisabledStatus(0, false); // TODO: why do we need this function?
    
    if (this.payForm) {
      // Перерасчет суммы
      this.increase();
    }
    // Проверка формы на валидность
    this.check();
  }
  
  changeProductControlDisabledStatus(controlIndex: number, disabledStatus: boolean) {
    const productsControlArray = <FormArray>this.payForm.controls.products;
    var productControl = productsControlArray.controls[controlIndex].get('productId')

    if (productControl == null) return;

    if (!disabledStatus){
      if (productControl.disabled){
        productControl.enable();
      }
    }
    else {
      if (productControl.enabled){
        productControl.disable();
      }
    }
  }

  // Создание формы
  createFormControls(draft?: DealDraftViewModel) {
    if (draft) {
      const existPoints = this.listpoint.filter(x => x.id === draft.pointId);

      this.pointId = new FormControl(existPoints.length > 0  ? draft.pointId : null, Validators.required);
    } else {
      if (this.listpoint.length > 0) {
        this.pointId = new FormControl(
          this.listpoint[0].id,
          Validators.required
        );
      } else {
        this.pointId = new FormControl('', Validators.required);
      }
    }

    /**
     * FROM PAY-CREATE
     */
    this.currentCountry = new FormControl(!draft ? 'RUS' : draft.country);
    this.passportseries = new FormControl(!draft ? '' : draft.passportseries, this.providerRequiredValidator('passportSeries'));
    this.currency = !draft ? this.currencyService.getProfileCurrency() : draft.currency;

    this.smzinn = new FormControl('', SmzInnValidator);

    this.surname = new FormControl(!draft ? '' : draft.surname, [Validators.required, FioValidator(), this.providerRequiredValidator('lastName')]);
    this.benificiare = new FormControl(!draft ? '' : draft.benificiare, [
      Validators.required,
      FioValidator(),
      this.providerRequiredValidator('firstName')
    ]);
    this.middleName = new FormControl(!draft ? '' : draft.middleName, [
      Validators.required,
      FioValidator(),
      this.providerRequiredValidator('middleName')
    ]);

    this.passportfrom = new FormControl(!draft ? '' : draft.passportfrom, [Validators.required, this.providerRequiredValidator('passportIssuedBy')]);
    this.birthplace = new FormControl(!draft ? '' : draft.birthplace, this.providerRequiredValidator('birthplace'));

    this.birthday = new FormControl(!draft ? '' : draft.birthday, [
      this.providerRequiredValidator('birthday'),
      ValidateDate(true, true)
    ]);

    this.registrationAddress = new FormControl(!draft ? '' : draft.adress, [Validators.required, this.providerRequiredValidator('registrationAddress')]);

    this.phone = new FormControl(!draft ? '' : draft.phone.formatPhoneShort(), [this.providerRequiredValidator('phone'), PhoneValidator()]);

    this.scanPsaControl = new FormControl(null, [ this.getScanPsaValidator() ]);

    this.dealNumber = new FormControl(!draft ? '' : draft.dealNumber, [
      this.dealNumberValidator(),
      this.providerRequiredValidator('dealNumber'),
      LengthValidator(2, 20)
    ]);

    this.dealDate = new FormControl(!draft ? '' : draft.dealDate, [
      this.providerRequiredValidator(this.dealDateAttributeName),
      ValidateDate(false, true, true)
    ]);

    if (this.settingList.hideDealProduct) {
      this.sum = new FormControl(!draft ? '' : draft.sum.toString(),
        [Validators.min(1),
          DecimalValidator(),
          Validators.required]);
    } else {
      this.sum = new FormControl('');
    }

    // если провайдера нет - использовать провайдера по умолчанию
    let provider = this.initialFormValues?.providerId
      ? this.listProvider.find(x => x.id === this.initialFormValues.providerId)
      : this.getDefaultProvider();
    this.providerId = new FormControl(provider.id, [ Validators.required ]);
    this.dealProvider = provider;

    this.cardNumber = new FormControl('', [
      this.providerRequiredValidator('cardNumber'), Validators.pattern('[0-9]{16,19}'), this.dealService.cardChecker
    ]);

    this.sbpMemberId = new FormControl(!draft?.sbpMemberId ? '' : draft.sbpMemberId, [
      this.providerRequiredValidator('sbpMemberId')
    ]);
    
    this.onProviderChanged(this.dealProvider);

    if (!this.initialFormValues|| !this.initialFormValues.externalId) {
      const externalId = Guid.create().toString();
      this.externalId = new FormControl(externalId, Validators.required);
    } else {
      this.externalId = new FormControl(draft ? draft.externalId : null);
    }

    this.concurrencyToken = draft ? draft.concurrencyToken : 0;
  }

  createForm() {
    this.payForm = this.fb.group({
      products: this.fb.array([]),
      PointId: this.pointId,
      CurrentCountry: this.currentCountry,
      Surname: this.surname,
      Benificiare: this.benificiare,
      middleName: this.middleName,
      Passportseries: this.passportseries,
      Smzinn: this.smzinn,
      Passportfrom: this.passportfrom,
      Birthday: this.birthday,
      Birthplace: this.birthplace,
      ProviderId: this.providerId,
      SbpMemberId: this.sbpMemberId,
      CardNumber: this.cardNumber,
      registrationAddress: this.registrationAddress,
      Phone: this.phone,
      ScanPsa: this.scanPsaControl,
      Sum: this.sum,
      DealNumber: this.dealNumber,
      DealDate: this.dealDate,
      ExternalId: this.externalId
    });

    this.subscribeForPersonDataFetch();
  }

  confirm() {
    // we set modal result as true on click on confirm button,
    // then we can get modal result from caller code
    this.result = true;
    this.close();
  }

  /**
   * FROM PAY-CREATE
   */
  initProviderAttributes() {
    // Атрибуты провайдера оплаты
    this.providerAttributes = this.dealService.initAttribute(
      this.dealProvider,
      this.settingList
    );
  }

  private initScanPsa() {
    const scanDocuments = this.initialFormValues?.scanDocuments;
    if (!scanDocuments) {
      return;
    }
    const scanPsa = scanDocuments.find(sd => sd.type === ScanType.Psa);
    if (!scanPsa) {
      return;
    }
    const file = new UploadedFile(new File([], scanPsa.originalFileName, {}));
    file.id = scanPsa.id;
    const fileList = new UploadedFileList([file]);
    this.scanPsaList = fileList;
  }

  fileListChanged(event: UploadedFileListChangedEvent): void {
    if (event.deletedfiles?.length) {
      event.deletedfiles.forEach(deletedFile => {
        if (deletedFile.id) {
          this.deletedScanPsaIds.push(deletedFile.id);
        }
      });
    }
    
    this.payForm.patchValue({
      ScanPsa: this.scanPsaList
    });
  }

  onPassportNumberChanged(value: string) {
    if (this.payForm) {
      this.payForm.value.Passportseries = value;
    }
    this.passportseries.setValue(value);
  }

  onCitizenshipChanged(value: string) {
    if (this.payForm) {
      this.payForm.value.CurrentCountry = value;
    }

    this.currentCountry.setValue(value);
  }

  onPassportNumberValidated(value: boolean) {
    this.passportLengthError = value;
  }

  onChangeAutoFillSerejkin(): void {
    if (
      this.currentCountry.value === 'RUS' &&
      this.surname.value === 'Сережкин' &&
      this.benificiare.value === 'Денис' &&
      this.middleName.value === 'Анатольевич' &&
      this.passportseries.value === '00 00 000000'
    ) {
      this.passportfrom.setValue('ОТДЕЛЕНИЕМ УМВД по г. Оренбург');
      this.phone.setValue('+79000000000');
      this.birthday.setValue('20.05.1976');
      this.birthplace.setValue('г. Оренбург');
      this.registrationAddress.setValue('г. Москва');
    }
  }

  // Алгоритм Луна для карты
  algLun(event: any) {
    this.checkCard = this.dealService.algLun(event);
  }

  // Получение данных о карте по BIN
  getCardInfo() {
    this.cardResultCheck = true;
    this.binDbQuery = false;
    if (this.payForm && this.payForm.value.CardNumber.length >= 16) {
      const cardSix = this.payForm.value.CardNumber.substring(0, 6);
      this.httpService.getCardInfo(cardSix)
        .pipe(takeUntil(this.destroy))
        .subscribe(result => {
          this.cardResult = result as ICardChecker;
          this.binDbQuery = true;

          const licenseeCountry = this.localStorage.getItem('country');
          if (this.cardResult && this.cardResult.countryIsoThreeLetterCode === licenseeCountry) {
            this.cardResultCheck = false;
          } else {
            this.cardResultCheck = true;
          }
        });
    }
  }

  // Отправка данных
  onSubmit() {
    if (
      !this.formSending &&
      this.payForm.valid &&
      (this.dealProvider.withdrawTypeId !== this.withdrawTypes.bankCard || !this.cardResultCheck) &&
      !this.passportLengthError
    ) {
      const formData = new FormData();
      formData.append('Surname', this.payForm.value.Surname);
      formData.append('Middlename', this.payForm.value.middleName);
      formData.append('Benificiare', this.payForm.value.Benificiare);
      formData.append('Passportfrom', this.payForm.value.Passportfrom);
      formData.append('Passportseries', this.payForm.value.Passportseries);
      formData.append('Inn', this.payForm.value.Smzinn);
      formData.append('registrationAddress', this.payForm.value.registrationAddress);
      formData.append('Birthday', this.payForm.value.Birthday);
      formData.append('DealDate', this.payForm.value.DealDate);
      formData.append('DealNumber', this.payForm.value.DealNumber);
      formData.append('Birthplace', this.payForm.value.Birthplace);
      formData.append('CardNumber', this.payForm.value.CardNumber);
      formData.append('SbpMemberId', this.payForm.value.SbpMemberId ?? '');
      formData.append('Phone', this.payForm.value.Phone.formatPhone());
      formData.append('WithdrawTypeId', this.dealProvider.withdrawTypeId);
      formData.append('Country', this.payForm.value.CurrentCountry);
      formData.append('PointId', this.payForm.value.PointId);

      const jsonPost = JSON.stringify(this.settingList);
      formData.append('Sum', this.payForm.value.Sum);
      formData.append('Currency', this.currency ?? '');
      formData.append('products', JSON.stringify(this.payForm.getRawValue().products));
      formData.append('settings', jsonPost);
      const scanPsa = this.getScanPsaFileFromUploadedFileList(this.payForm.value.ScanPsa as UploadedFileList);
      formData.append('filePsa', scanPsa);
      const deletedScanPsaIdsJson = JSON.stringify(this.deletedScanPsaIds);
      formData.append('deletedFilePsaIds', deletedScanPsaIdsJson);
      formData.append('personId', this.personId ?? '');
      formData.append('concurrencyToken', this.concurrencyToken.toString());

      this.isUniqueError = false;
      // Если необходим PUT запрос как и в первом компоненте
      this.formSending = true;

      if (this.isEditForm()) {
        this.updateDeal(formData);
      } else {
        formData.append('ExternalId', this.payForm.value.ExternalId);
        this.createDeal(formData);
      }
    }
  }

  private getScanPsaFileFromUploadedFileList(uploadedFileList: UploadedFileList): File {
    const uploadedFile = uploadedFileList?.files[0];
    if (!uploadedFile) {
      return null;
    }
    const scanPsa = this.isEditForm && uploadedFile.isFileAlreadyUploaded ? null : uploadedFile.file;
    return scanPsa;
  }

  updateDeal(formData: FormData) {
    this.dealService.update(this.initialFormValues.pornumber, formData)
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
        this.isPostAlready = true;
        this.responseDeal = data as IPeopleDeal;
        this.formSending = false;
        
        this.notificationService.successNotify('Оплата была успешно отредактирована.', 'Редактирование оплаты')
        this.router.navigate(['/electronic-psa'], {queryParams: {dealId: data.id}});
        this.confirm();
    }, this.onDealError.bind(this));
  }

  createDeal(formData: FormData) {
    // Если необходим POST запрос
    this.dealService.create(formData)
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
        console.log('data', data);
        this.formSending = false;
        if (data.isSaveInDb) {
          this.isSaveInDb = true;
          this.dealPorNumber = data.pornumber;
          console.log('this.dealPorNumber', this.dealPorNumber);
        }
        if (data.exception) {
          console.error('data.exception', data.exception);
          this.isUniqueError = true;
          return;
        }
        this.router.navigate(['/electronic-psa'], {queryParams: {dealId: data.id}});
        this.confirm();
      }, this.onDealError.bind(this));
  }

  onDealError(error) {
    this.notificationService.errorNotify(error, 'Ошибка');
    console.log(error);
    const {message, fields} = this.httpErrorService;

    if (fields && this.invalidStep !== null) {
      this.step = this.invalidStep;
    }

    if (message) {
      this.notificationService.errorNotify(message, 'Ошибка');
    }

    // 413 Request Entity Too Large - обработка возможной ошибки от nginx
    if (error.status === 413) {
      this.notificationService.errorNotify('Ошибка', 'Размер скана ПСА слишком большой');
    }

    this.errorMessage = error;
    this.formSending = false;
  }
  /**
   * CUSTOM
   */
  goForward(step: number) {

    // если переходим от данных персоны к его картам
    if (step === 2) {
      this.loadPersonCards();
    }

    if (step < this.maxStep) {
      this.step = step + 1;
    }
  }

  goBack(step: number) {
    if (step === 0) {
      this.router.navigate(['/people-deal']);
      this.confirm();
    } else {
      this.step = step - 1;
    }
  }

  calculateProductTotalSum(product) {
    const sum = 0;
    if (
      product &&
      product.measureUnitValue != null &&
      product.measureUnitPrice != null
    ) {
      return (
        Number(product.measureUnitValue) *
        Number(product.measureUnitPrice) *
        (1 - Number(product.trash) / 100)
      );
    }
    return sum;
  }

  subscribeForPersonDataFetch() {
    this.requestPersonInfoSubscription = this.personRequestModelChanged
      .pipe(
        switchMap(request => this.getPersonInfo(request)),
      )
      .subscribe(personInfo => {
          this.setPersonInfo(personInfo);
        },
        error => {
          console.log(error);
        });
  }

  getPersonInfo(request: IGetPersonInfoRequest): Observable<any> {
    if (this.step !== 2) {
      return of(null);
    }
    if (this.personInfoRequestCanBeSend(request)) {
      return this.personService.getPersonInfo(request);
    } else {
      return of(null);
    }
  }

  setPersonInfo(info: IGetPersonInfoResponse) {
    if (info) {
      this.personId = info.id;
      this.requestPersonInfoSubscription.unsubscribe();
      this.payForm.patchValue(
        {
          Surname: info.lastName,
          middleName: info.patronymic,
          Phone: info.phone,
          Benificiare: info.firstName,
          smzinn: info.inn,
          Passportfrom: info.passport.issuer,
          Birthday: moment(info.passport.birthDate).format('DD.MM.YYYY'),
          Birthplace: info.passport.birthPlace
        }
      );
    }
  }

  get personInfoRequest(): IGetPersonInfoRequest {
    const lastName = this.payForm.get('Surname')?.valid ? this.payForm.value.Surname : null;
    const firstName = this.payForm.get('Benificiare')?.valid ? this.payForm.value.Benificiare : null;
    const patronymic = this.payForm.get('middleName')?.valid ? this.payForm.value.middleName : null;
    const passportnumber = !this.passportLengthError ? this.payForm.value.Passportseries : null;
    const citizenship = this.payForm.value.CurrentCountry;

    return {lastName, firstName, patronymic, passportData: passportnumber, citizenship};
  }

  onPersonFieldFocusLost() {
    this.personRequestModelChanged.next(this.personInfoRequest);
  }

  personInfoRequestCanBeSend(request: IGetPersonInfoRequest): boolean {
    for (const key in request) {
      if (!request[key]) {
        return false;
      }
    }
    return true;
  }

  loadPersonCards() {
    if (!this.checkCard) { // если уже ввели валидную карту, то грузить другие не будем
      if (this.personId) { // если есть инфо о персоне то загрузим карты
        this.personService.getPersonCards(this.personId).pipe(takeUntil(this.destroy)).subscribe(
          cards => {
            if (cards && cards.mainCard) {
              const mainCard = cards.mainCard;
              mainCard.isActive = true;
              this.personCards.push(mainCard);
              for (const hCard of cards.cards) {
                this.personCards.push(hCard);
              }
              this.payForm.get('CardNumber')?.setValue(mainCard.number);
              this.canGoToCardsSelection = true;
              this.isNewCardEnter = false;
            } else {
              this.canGoToCardsSelection = false;
              this.isNewCardEnter = true;
            }
          }
        );
      }
    }
  }

  goToCardsSelection() {
    this.isNewCardEnter = false;
    this.payForm.get('CardNumber')?.setValue(this.personCards[0].number);
    // this.canGoToCardsSelection = false;
  }

  goToCardEnter() {
    this.isNewCardEnter = true;
    this.payForm.get('CardNumber')?.setValue(null);
  }

  onCloseModal() {
    this.close();
    return;
    }

  getDraftModel(): DealDraftViewModel {
    const payFormValue =  this.payForm.getRawValue();

    let draftModel = new DealDraftViewModel();

    draftModel.pointId = payFormValue.PointId;
    draftModel.comment = payFormValue.Comment;
    draftModel.products = new Array;

    payFormValue.products.filter(x => !!x.productId).forEach(x => {
      let p = new PeopleDealProductBinding();
      p.productId = x.productId;
      p.trash = this.getValueOrDefault(x.trash, 0);
      p.measureUnitValue = this.getValueOrDefault(x.measureUnitValue, 0);
      p.measureUnitPrice = this.getValueOrDefault(x.measureUnitPrice, 0);
      p.measureUnitShortName = x.measureUnit;
      p.measureUnitFullName = x.measureUnit;
      p.oneScreen = x.oneScreen;
      p.groupKey = x.groupKey;

      draftModel.products.push(p);
    });

    draftModel.surname = payFormValue.Surname;
    draftModel.middleName = payFormValue.middleName;
    draftModel.benificiare = payFormValue.Benificiare;
    draftModel.phone = payFormValue.Phone.formatPhone();
    draftModel.passportseries = payFormValue.Passportseries;
    draftModel.passportfrom = payFormValue.Passportfrom;
    draftModel.country = payFormValue.CurrentCountry;
    draftModel.birthday = payFormValue.Birthday;
    draftModel.birthplace = payFormValue.Birthplace;
    draftModel.adress = payFormValue.registrationAddress;
    draftModel.sbpMemberId = payFormValue.SbpMemberId;
    draftModel.providerId = payFormValue.ProviderId;


    draftModel.dealDate = payFormValue.DealDate;
    draftModel.dealNumber = payFormValue.DealNumber;

    draftModel.sum = this.getValueOrDefault(payFormValue.Sum, 0);

    return draftModel;
  }

  isEditForm(): boolean {
    return this.isEditingForm;
  }

  getValueOrDefault(value: any, defaultValue: any = null) {
   return value ? value : defaultValue;
  }

  // Определение провайдера по умолчанию
  getDefaultProvider() : IProvider {
    if (!Array.isArray(this.listProvider) || !this.listProvider.length){
      console.log("Не удалось определить провайдер оплаты");
      return null;
    }

    var mainProvider = this.listProvider.find(x => x.withdrawTypeId == this.withdrawTypes.bankCard);      
    if (mainProvider == null)
      mainProvider = this.listProvider[0];

    return mainProvider;
  }
}
