import { Component, OnInit, Input } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, IShortPoint, ConfirmCodeValidator } from 'tsuz-common';
import { UserService } from '@services/user.service';

export interface ConfirmPasswordChangeModel {
  newPassword: string;
  oldPassword: string;
}

@Component({
  selector: 'app-change-password-confirm',
  templateUrl: './change-password-confirm.component.html',
})
export class ChangePasswordConfirmComponent
  extends SimpleModalComponent<ConfirmPasswordChangeModel, IShortPoint>
  implements ConfirmPasswordChangeModel, OnInit {

    changePasswordForm: FormGroup;
    oldPassword: string;
    newPassword: string;
    confirmCode: FormControl;

    constructor(private userService: UserService, private notifyService: NotificationService) {
      super();
    }

  // Создание формы
  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.changePasswordForm = new FormGroup(
      {
        ConfirmCode: new FormControl('', [Validators.required, ConfirmCodeValidator()]),
      }
    );
  }

  async confirm() {
    if (this.changePasswordForm.valid) {
      try {
       await this.userService.changePassword(this.changePasswordForm.value.ConfirmCode, this.oldPassword , this.newPassword).toPromise();
       this.notifyService.successNotify('Пароль успешно изменен' , 'Смена пароля');
       this.close();
      } catch (error) {
        this.notifyService.errorNotify(error , 'Смена пароля');
        this.close();
      }
    }
  }
}
