import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {DealService} from '../../core/services/deal.service';
import {RealtimeNotificationService} from "../../core/services/realtime-notification.service";
import {map, takeUntil, switchMap} from "rxjs/operators";
import {ReplaySubject} from "rxjs";
import {NotificationService} from "tsuz-common";
import {Subject} from "rxjs";

@Component({
  selector: 'app-accepted-deals',
  templateUrl: './accepted-deals.component.html',
  styleUrls: []
})
export class AcceptedDealsComponent implements OnInit, OnDestroy {
  countOnAccept = 0;
  onGetCountOnAccepted = new Subject<number>();

  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private router: Router,
    private dealService: DealService,
    private realtimeNotificationService: RealtimeNotificationService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.initAcceptedDealsCount();
    this.realtimeNotificationService
      .onSendToApprovalDeal()
      .pipe(switchMap(dealNumber => {
        return this.dealService.getOnAcceptedCount()
          .pipe(takeUntil(this.destroy))
          .pipe(map(countAccepted => ({ dealNumber, countAccepted })));
      }))
      .pipe(takeUntil(this.destroy))
      .subscribe(({ dealNumber, countAccepted }) => {
        this.countOnAccept = countAccepted;
      });

      this.realtimeNotificationService
      .onDealApproved()
      .pipe(switchMap(dealNumber => {
        return this.dealService.getOnAcceptedCount()
          .pipe(takeUntil(this.destroy))
          .pipe(map(countAccepted => ({ dealNumber, countAccepted })));
      }))
      .pipe(takeUntil(this.destroy))
      .subscribe(({ dealNumber, countAccepted }) => {
        this.countOnAccept = countAccepted;
      });
  }

  // Подписка на таймер
  initAcceptedDealsCount() {
    this.dealService.getOnAcceptedCount()
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
        this.countOnAccept = data;
    });
  }

  openOnAccepted() {
    this.router.navigate(['/deals?'], {
      queryParams:
      {
        state: 'Accepting'
      }
    });
  }

  // При унитожении компонента идет отписка от его зависимостей
  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
