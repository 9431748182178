import { Component, Input } from '@angular/core';
import { FilterComponentBase } from '../filter-component-base';
import { FilterService } from '../../../services/filter.service';

@Component({
  selector: 'app-string-filter',
  templateUrl: './string-filter.component.html',
  styleUrls: ['./string-filter.component.scss'],
  host: { class: 'app-string-filter' }
})
export class StringFilterComponent extends FilterComponentBase<string> {

  @Input() mask: string;
  @Input() maskPatterns: any;
  @Input() textMask;
  @Input() maxLength: number = 250;

  constructor(filterService: FilterService) {
     super(filterService);
  }

  protected getDefaultModelValue(): string {
    return '';
  }

  onCancel() {
    // при отмене обнуляем изменившуюся модель до прошлого фильтра
    if (this.paginationFilter[this.queryName] !== this.model) {
      this.model = this.paginationFilter[this.queryName];
    }

    super.onCancel();
  }
}
