import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import { ILicenseeService, IHomeBalance, LicenseeModel, BalanceOrganization, BaseHttpService } from 'tsuz-common';
import { ILicenseeApiSettings } from '@models/licensee-setting/ILicenseeApiSettings';

@Injectable()
export class LicenseeService implements ILicenseeService {

  constructor(
      private baseHttpService: BaseHttpService,
  ) { }

  // Получение настроек
  getForSettings(licenseeId: string): Observable<any> { 
    return this.baseHttpService.get(`/licensee/${licenseeId}/settings`); 
  }

  // Получение настроек главного компонента
  getSettings(licenseeId: string, shouldResetCache: boolean = false): Observable<any> {
    const url = `/licensee/${licenseeId}/settings`;
    if (shouldResetCache) {
      let headers = new HttpHeaders();
      headers = headers.set('reset-client-cache', 'true');
      return this.baseHttpService.get(url, null, headers);
    }
    return this.baseHttpService.get(url);
  }

  // Получение баланса
  getBalance(): Observable<IHomeBalance> {
    return this.baseHttpService.get('/licensee/balance');
  }

  // Получение балансов группы компаний
  getBalances(): Observable<BalanceOrganization[]> { return this.baseHttpService.getOfT<BalanceOrganization[]>('/licensee/balances'); }

  // Получение списка доступных Лицензиатов
  getUserLicensees(): Observable<LicenseeModel[]> { return this.baseHttpService.get('/licensees'); }

  // Получение Лицензиата по ID
  getUserLicenseeById(licenseeId: string): Observable<LicenseeModel> { return this.baseHttpService.get(`/licensees/${licenseeId}`); }

  getOrganization(): Observable<any> {return this.baseHttpService.get(`/settings/organization`)}

  private readonly apiSettingsUrl: string = `/settings/licensee/apikey`;

  // Получение настроек интеграции
  getApiSettings(): Observable<ILicenseeApiSettings> {
    return this.baseHttpService.get(this.apiSettingsUrl);
  }

  // Обновление настроек
  updateSettings(licenseeId: string, value: any): Observable<any> {
    return this.baseHttpService.patch('/settings/licensee', value);
  }
  updateLimitSettings(value: any): Observable<any> { return this.baseHttpService.put('/settings/limit', value); }
  updateRegistrySettings(value: any): Observable<any> { return this.baseHttpService.put('/settings/registry', value); }
  updateBaseSettings(value: any): Observable<any> { return this.baseHttpService.put('/settings/base', value); }
  updatePointSettings(value: any): Observable<any> { return this.baseHttpService.put('/settings/point', value); }
  updateApiSettings(value: ILicenseeApiSettings): Observable<any> { return this.baseHttpService.patch(this.apiSettingsUrl, value); }
}
