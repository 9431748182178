import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IUsersService } from '../../../../services/interfaces/IUsersService';
import { IUser, IPoint } from '../../../../models/IUser';
import { NotificationService } from '../../../../services/notification.service';

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
}

@Component({
  selector: 'app-edit-activity-post',
  templateUrl: './edit-activity-post.component.html'
})
export class EditActivityPostComponent 
  extends SimpleModalComponent<ConfirmModel, IPoint> 
  implements ConfirmModel, OnInit, OnDestroy {

  title: string;
  message: string;
  user: IUser;

  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject('UsersService') private usersService: IUsersService, 
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
  }

  //Отправка данных
  toPost() {
    const newStatus = !this.user.active;
    if (newStatus === true) {
      this.unBlockUser();
    }
    if (newStatus === false) {
      this.blockUser();
    }
  }

  blockUser() {
    this.usersService
      .blockUser(this.user.id, '')
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
          this.user.active = false;
          this.notificationService.successNotify('Пользователь успешно заблокирован', 'Блокировка пользователя');
          this.close();
        },
        error => {
          this.notificationService.errorNotify(error, 'Блокировка пользователя');
        });
  }

  unBlockUser() {
    this.usersService
      .unBlockUser(this.user.id)
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
          this.user.active = true;
          this.notificationService.successNotify('Пользователь успешно разблокирован', 'Блокировка пользователя');
          this.close();
        },
        error => {
          this.notificationService.errorNotify(error, 'Разблокировка пользователя');
        });
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }

}
