import { ControlValueAccessor, FormControl } from '@angular/forms';
import {Input, ViewChild, ElementRef, Component} from '@angular/core';

@Component({
  template: ''
})
export abstract class AbstractTextInput implements ControlValueAccessor {
  value: string = '';
  @Input() id: string;
  @Input() labelName: string;
  @Input() formCtrl: FormControl;
  @Input() placeholder: string = '';

  @ViewChild('inputElement') protected _inputElement: ElementRef;

  constructor(
  ) { }

  get inputElement(): ElementRef {
    return this._inputElement;
  }

  protected _onChange = (_: any) => { };
  protected _onTouched = () => { };

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChange(event: any) {
    this.writeValue(event.target.value);
    this._onChange(event.target.value);
  }
  onKeyup(event: any) {
    this._onChange(event.target.value);
  }
  onBlur(event: any) {
    this._onTouched();
  }
  onFocusOut(event: any) {
    this.onChange(event);
  }
}
