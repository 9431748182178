import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable()
export class SystemDictionaryService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  getSystemDictionaries(): Observable<any> {
    return this.baseHttpService.get('/Dictionary');
  }

}
