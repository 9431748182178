import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-platform-binding-request-created',
  templateUrl: './platform-binding-request-created.component.html',
  styleUrls: ['./platform-binding-request-created.component.scss']
})
export class PlatformBindingRequestCreatedComponent {

  constructor(
    public dialogRef: MatDialogRef<PlatformBindingRequestCreatedComponent>
  ) {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
