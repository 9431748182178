import { UserRoles } from "tsuz-common";

export class UserPermissions {
    public static dataPermissionsNotSupportRoles = {
        permission: {
          permittedRoles: [
            UserRoles.Administrator,
            UserRoles.Manager,
            UserRoles.Cashier,
            UserRoles.ManagerPlus,
            UserRoles.Accountant,
            UserRoles.FinancialDirector
          ]
        }
      };

  public static dataPermissionsForAll = {
    permission: {
      permittedRoles: [
        UserRoles.Administrator,
        UserRoles.Manager,
        UserRoles.Cashier,
        UserRoles.ManagerPlus,
        UserRoles.Accountant,
        UserRoles.FinancialDirector,
        UserRoles.Support,
      ]
    }
  };
}
