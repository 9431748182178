import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[product-measure]'
})
export class ProductMeasureDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('focusout')
  onFocusOut(): void {
    let control = this.ngControl.control;
    if (control != null) {
      if (control.value == null || control.value == "") {
          control.setValue(0);
      }
    }
  }

  @HostListener('focus')
  onFocus(): void {
    let control = this.ngControl.control;
    if (control != null) {
      if (control.value == null || control.value <= 0) {
          control.setValue('');
      }
    }
  }
}
