<div  class="filter__text-align-left"
      (clickOutside)="onCancel()">
  <div class="list-checkbox-filter__header">
    <ng-content></ng-content>
  </div>

  <app-sorting-header *ngIf="_sort"
                      [(sort)]="_sort"
                      [sortField]="sortField"
                      (sortChange)="onSortClick()">
  </app-sorting-header>

  <div  class="filter__btn list-checkbox-filter__show-button"
        [ngClass]="{'filter__btn-active': isActive,'filter__margin-left': !_sort}"
        (click)="showModal()">
  </div>

  <div class="filter zindex list-checkbox-filter__modal" *ngIf="isShowModal">
      <div class="filter__header">
        <input class="checkbox-filter" id="id1807060825" type="checkbox" (change)="allCheck()" [checked]="isAll">
        <label class="checkbox-filter-label" for="id1807060825">{{'Выбрать все'}}</label>
      </div>
      <div class="filter__section">
        <ul class="filter-ul" *ngIf="items">
          <li class="filter__li" *ngFor="let item of items; let i=index">
            <input class="checkbox-filter" id="idssd{{i}}" type="checkbox" (change)="editState(i)" [checked]="item.checked">
            <label class="checkbox-filter-label" for="idssd{{i}}">{{item.title}}</label>
          </li>
        </ul>
      </div>
      <div class="filter__footer">
        <a class="btn2 btn2--active" (click)="onApply()">Применить</a>
        <a class="btn2" (click)="onCancel()">Отмена</a>
      </div>
  </div>
</div>
