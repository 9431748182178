<div class="modal-content payment-modal">
  <a id="id1538469447" class="payment-modal__close" (click)="onCloseModal()" ></a>
  <div *ngIf="!isReady" class="payment-modal__busy" >
    <div class="app-loading">
      <div class="logo"></div>
      <svg class="spinner"
           viewBox="25 25 50 50">
        <circle class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"></circle>
      </svg>
    </div>
  </div>
  <form *ngIf="isReady && settingList" [formGroup]="payForm" #f="ngForm" (ngSubmit)="onSubmit()">
    <div class="payment-modal__header modal-header">
      <h4>{{ title }}</h4>

      <div payment-modal-progress-bar [maxStep]="maxStep" [step]="step"></div>
    </div>
    <div class="modal-body">
      <div [hidden]="step !== 0">
        <div class="payment-modal__select-point-block">
          <label class="payment-modal__label">Выбор {{settingList.pointName | titlecase}}</label>
          <app-select-box-control
            [items]="listpoint"
            bindLabel="title"
            bindValue="id"
            placeholder="Выберите точку"
            formControlName="PointId"
            (change)="checkPoint($event)">
          </app-select-box-control>
        </div>

        <div class="payment-modal__limits-block">
          <div class="payment-modal__limits-block__item">
            <div>
              <app-limit-info [limit]="limitInfo"></app-limit-info>
            </div>
            <label class="label">Cуточный лимит</label>
          </div>
          <div class="payment-modal__limits-block__item">
            <div>
              <app-limit-info [limit]="currentLimitInfo" [canBeUnlimited]="limitInfo === 0"></app-limit-info>
            </div>
            <label class="label">Остаток лимита</label>
          </div>
          <div class="payment-modal__limits-block__item">
            <div>
              <app-limit-info [limit]="transcationInfo"></app-limit-info>
            </div>
            <label class="label">Лимит оплаты</label>
          </div>
        </div>

        <div class="payment-modal__psa-block">
          <label class="payment-modal__label">Информация о ПСА</label>
          <div class="payment-modal__psa-block__inputs">
            <div class="payment-modal__psa-block__inputs-item">
              <div class="requisites__item">
                <input formControlName="DealNumber" type="text" autocomplete="address-level1"
                  class="input flex-shrink-max" [ngClass]="{
                    'input--nonvalidate':
                      dealNumber.invalid,
                    'input--validate':
                       dealNumber.valid
                  }" placeholder="{{ providerAttributes.dealNumber.title }}" maxlength="21"/>
              </div>
              <span class="report report--false report--valid" [ngClass]="{ 'report--active': isUniqueError }">*Номер договора должен
                быть уникальным.</span>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.DealNumber.errors?.minError === true
                }">*Минимальная длина 2 символа.</span>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.DealNumber.errors?.maxError === true
                }">*Максимальная длина 20 символов.</span>
            </div>

            <div class="payment-modal__psa-block__inputs-item">
              <div class="requisites__item">
                <app-date-input-form-control
                  [control]="payForm.controls.DealDate"
                  [placeholder]="providerAttributes.dealDate.title">
                </app-date-input-form-control>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.DealDate.errors?.biggerCurrentDate == true
                }">
                *Нельзя указать дату ПСА выше текущей.
              </span>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.DealDate.errors?.invalidDate === true
                }">
                *Некорректная дата.
              </span>
            </div>
          </div>

          <tsuz-upload-files
            [labelText]="'Скан ПСА'"
            [minFileCount]="isScanPsaRequired ? 1 : 0"
            [maxFileCount]="1"
            [maxFileSize]="maxScanFileSize"
            [acceptFileTypes]="['application/pdf', 'image/png', 'image/tiff', ' image/jpg', 'image/jpeg']"
            [uploadedFileList]="scanPsaList"
            (fileListChanged)="fileListChanged($event)">
          </tsuz-upload-files>
        </div>
      </div>

      <div [hidden]="step !== 1">
        <div class="payment-modal__product-table-block">
          <ng-container *ngIf="settingList.hideDealProduct">
            <label class="payment-modal__label">Сумма сделки</label>

            <div class="requisites__item">
              <input type="text" formControlName="Sum" class="input flex-shrink-max" mask="0*.00"
                [dropSpecialCharacters]="false"
                [ngClass]="{'nonvalidate': payForm.controls['Sum'].invalid,'validate': payForm.controls['Sum'].valid}"
                placeholder="Сумма сделки" />
            </div>
          </ng-container>
          <ng-container *ngIf="!settingList.hideDealProduct">
            <label class="payment-modal__label">Товарная таблица</label>
            <div>
              <ng-container formArrayName="products">
                <div *ngFor="
                  let prod of payForm.get('products')?.controls;
                  let productIndex = index
                ">
                  <ng-container [formGroupName]="productIndex">
                    <div *ngIf="productIndex < payForm.get('products')?.controls.length - 1">
                      <div class="payment-modal__product-table-block__item">
                        <div class="payment-modal__product-table-block__item-header">
                          <div>№ {{ productIndex + 1 }}</div>
                          <div>
                            <div class="edit-delete">
                              <a class="delete-btn" (click)="deleteProduct(productIndex)">
                                <span class="label--mobile">
                                  Удалить строку
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="payment-modal__product-table-block__item-body">
                          <div>
                            <div class="label">Категория</div>
                            <div class="payment-modal__product-table-block__category">
                              <app-select-box-control
                                [items]="listprod"
                                class="disabled-dropdown"
                                bindLabel="title"
                                bindValue="id"
                                formControlName="productId">
                              </app-select-box-control>
                            </div>
                          </div>
                          <div>
                            <div class="label">Цена</div>
                            <div>{{ prod.value.measureUnitPrice | money: currency }}</div>
                          </div>
                          <div *ngIf="!settingList.hideTrashPercent">
                            <div class="label">Засор</div>
                            <div>{{ prod.value.trash || 0 }} %</div>
                          </div>
                          <div *ngIf="!settingList.hideWeight">
                            <div class="label">Вес</div>
                            <div>
                              {{ prod.value.measureUnitValue }}
                              <ng-container *ngIf="!settingList.hideUnit">
                                <span>{{prod.value.measureUnit}}</span>
                              </ng-container>
                            </div>
                          </div>
                        </div>

                        <div class="payment-modal__product-table-block__item-footer">
                          Сумма: {{ prod.value.totalPrice | money: currency }}
                        </div>
                      </div>
                    </div>

                    <div *ngIf="productIndex === payForm.get('products')?.controls.length - 1"
                         class="payment-modal__product-table-block__add">
                      <div class="payment-modal__product-table-block__add__top">
                        <div class="payment-modal__product-table-block__add__top-order">
                          № {{ productIndex + 1 }}
                        </div>
                        <div *ngIf="productIndex > 0" class="payment-modal__product-table-block__add__top-delete">
                          <div class="edit-delete">
                            <a class="delete-btn" (click)="deleteProduct(productIndex)">
                              <span class="label--mobile">
                                Удалить строку
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="payment-modal__product-table-block__add-category">
                        <app-select-box-control [items]="listprod"
                                                class="rounded-dropdown"
                                                bindLabel="title"
                                                bindValue="id"
                                                placeholder="Выберите категорию"
                                                formControlName="productId" (change)=" changeProduct(payForm.controls.products.value[productIndex].productId,productIndex)">
                        </app-select-box-control>
                      </div>
                      <div *ngIf="!settingList.hideUnit && !!productMeasureUnits[productIndex]"
                           class="payment-modal__product-table-block__add-measure-unit">
                           <app-select-box-control class="rounded-dropdown"
                                                [items]="productMeasureUnits[productIndex]"
                                                bindLabel="fullName"
                                                bindValue="shortName"
                                                placeholder="Выберите ед. измерения"
                                                formControlName="measureUnit"
                                                (change)="increase()">
                        </app-select-box-control>
                      </div>
                      <div *ngIf="!!prod.value.measureUnit" class="payment-modal__product-table-block__add-price-trash-unit">
                        <div class="flex-grow">
                          <input  product-price
                            class="input input--calc"
                            formControlName="measureUnitPrice"
                            placeholder="Цена"
                            [dropSpecialCharacters]="false"
                            mask="0*.00"
                            (keyup)="increase()"/>
                        </div>
                        <div class="flex-grow" *ngIf="!settingList.hideTrashPercent">
                          <input  product-trash
                            class="input input--calc"
                            formControlName="trash"
                            placeholder="Засор, %"
                            [dropSpecialCharacters]="false"
                            mask="0*.00"
                            (keyup)="increase()" />
                        </div>
                        <div class="flex-grow" *ngIf="!settingList.hideWeight">
                          <input product-measure
                            class="input input--calc"
                            formControlName="measureUnitValue"
                            [placeholder]="getMeasureUnitPlaceholder(productIndex)"
                            [dropSpecialCharacters]="false"
                            mask="0*.0000"
                            (keyup)="increase()"/>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <span class="report report--false report--valid" [ngClass]="{ 'report--active': !hasOne.isValid }">
                  {{ hasOne.errorMessage }}
                </span>
              </ng-container>

              <div class="payment-modal__product-table-block__add-new-row">
                <tsuz-add-button (click)="addProduct()" *ngIf="IsAddProductButtonAvailable"></tsuz-add-button>
                <div>
                  Сумма:
                  <div class="payment-modal__product-table-block__add-new-row__total-value">
                    {{
                    payForm.value.products[payForm.value.products.length - 1]
                      .totalPrice | money: currency
                    }}
                  </div>
                </div>
              </div>

              <div class="payment-modal__product-table-block__total">
                Итого к оплате:
                <div class="payment-modal__product-table-block__total-value">
                  {{ total | money: currency }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div [hidden]="step !== 2">
        <div class="payment-modal__seller-info-block">
          <label class="payment-modal__label">Информация о продавце</label>
          <div class="payment-modal__seller-info-block__inputs">
            <div class="payment-modal__seller-info-block__inputs-item">
              <div class="requisites__item">
                <input formControlName="Surname" type="text" autocomplete="cc-family-name" class="input empty "
                  [ngClass]="{
                    'input--nonvalidate':
                      surname.invalid,
                    'input--validate':
                      surname.valid
                  }" (change)="onChangeAutoFillSerejkin()" placeholder="{{ providerAttributes.lastName.title }}"
                       (focusout)="onPersonFieldFocusLost()"/>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.Surname.hasError('pattern') == true
                }">*Неверный формат</span>
              <span *ngIf="getFieldError('surname')" class="report report--false report--valid report--active">
                {{getFieldError('surname')}}
              </span>
            </div>
            <div class="payment-modal__seller-info-block__inputs-item">
              <div class="requisites__item">
                <input formControlName="Benificiare" autocomplete="cc-given-name" type="text" class="input " [ngClass]="{
                    'input--nonvalidate':
                     benificiare.invalid,
                    'input--validate':
                      benificiare.valid
                  }" (change)="onChangeAutoFillSerejkin()" placeholder="{{ providerAttributes.firstName.title }}"
                       (focusout)="onPersonFieldFocusLost()"/>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.Benificiare.hasError('pattern') == true
                }">*Неверный формат</span>
              <span *ngIf="getFieldError('benificiare')" class="report report--false report--valid report--active">
                {{getFieldError('benificiare')}}
              </span>
            </div>
          </div>
          <div class="payment-modal__seller-info-block__inputs">
            <div class="payment-modal__seller-info-block__inputs-item">
              <div class="requisites__item">
                <input formControlName="middleName" type="text" class="input" autocomplete="cc-additional-name"
                       [ngClass]="{
                    'input--nonvalidate':
                    middleName.invalid,
                    'input--validate':
                       middleName.valid
                  }" (change)="onChangeAutoFillSerejkin()" placeholder="{{ providerAttributes.middleName.title }}"
                (focusout)="onPersonFieldFocusLost()"/>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.middleName.hasError('pattern') == true
                }">*Неверный формат.</span>
              <span *ngIf="getFieldError('middleName')" class="report report--false report--valid report--active">
                {{getFieldError('middleName')}}
              </span>
            </div>
            <div class="payment-modal__seller-info-block__inputs-item">
              <div class="requisites__item">
                <app-phone-control formControlName="Phone" style="width: 100%"></app-phone-control>
              </div>
            </div>
          </div>
          <div class="payment-modal__seller-info-block__inputs">
            <div class="payment-modal__seller-info-block__inputs-item">
              <div class="requisites__item">
                <input formControlName="registrationAddress" type="text" class="input flex-shrink-max" autocomplete="address-level2"
                  [ngClass]="{
                    'input--nonvalidate':
                    registrationAddress.invalid,
                    'input--validate':
                    registrationAddress.valid
                  }" placeholder="{{ providerAttributes.registrationAddress.title }}" />
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.registrationAddress.hasError('pattern') == true
                }">*Неверный формат.</span>
              <span *ngIf="getFieldError('registrationAddress')" class="report report--false report--valid report--active">
                {{getFieldError('registrationAddress')}}
              </span>
            </div>
          </div>
          <div class="payment-modal__seller-info-block__inputs" *ngIf="smz">
            <div class="payment-modal__seller-info-block__inputs-item">
              <div class="requisites__item">
                <input formControlName="Smzinn"
                       type="text"
                       class="input flex-shrink-max"
                       title="ИНН обязателен для заполнения при выплате самозанятым физическим лицам"
                       mask="{{smzinnMask}}"
                       [ngClass]="{nonvalidate:smzinn.invalid, validate:smzinn.valid}"
                       [placeholder]="'ИНН самозанятого'"
                       [focusPlaceholder]="smzinnPlaceholder"/>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.Smzinn.hasError('pattern') == true
                }">*Неверный формат.</span>
              <span *ngIf="getFieldError('registrationAddress')" class="report report--false report--valid report--active">
                {{getFieldError('registrationAddress')}}
              </span>
            </div>
          </div>
        </div>

        <div class="payment-modal__passport-info-block">
          <label class="payment-modal__label">Паспортные данные</label>
          <div class="payment-modal__passport-info-block__inputs">
                <app-passport-info-input-control style="width: 100%;"
                  id='id7fab80319d'
                  class="payment-modal__passport-info-block__inputs"
                  [licenseeType]="'Default'"
                  [passportNumber]="initialFormValues?.passportseries"
                  [citizenship]="initialFormValues?.country"
                  [passportNumberIsRequired]="this.providerAttributes.passportSeries.isGateRequired ||
                  this.providerAttributes.passportSeries.isRequired"
                  (citizeshipChangedEvent)="onCitizenshipChanged($event)"
                  (passportNumberChangedEvent)="onPassportNumberChanged($event)"
                  (passportNumberValidationChangedEvent)="onPassportNumberValidated($event)"
                  >
                </app-passport-info-input-control>
          </div>
          <div class="payment-modal__passport-info-block__inputs">
            <div class="payment-modal__passport-info-block__inputs-item">
              <div class="requisites__item">
                <input type="text" formControlName="Passportfrom" class="input flex-shrink-max" [ngClass]="{
                    'input--nonvalidate':
                    passportfrom.invalid,
                    'input--validate':
                      passportfrom.valid
                  }" placeholder="{{ providerAttributes.passportIssuedBy.title }}"/>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.Passportfrom.hasError('pattern') == true
                }">*Неверный формат.</span>
              <span *ngIf="getFieldError('passportfrom')" class="report report--false report--valid report--active">
                {{getFieldError('passportfrom')}}
              </span>
            </div>
          </div>
          <div class="payment-modal__passport-info-block__inputs">
            <div class="payment-modal__passport-info-block__inputs-item">
              <div class="requisites__item">
                <input formControlName="Birthplace" type="text" autocomplete="address-level1"
                  class="input flex-shrink-max" [ngClass]="{
                    'input--nonvalidate':
                      payForm.controls.Birthplace.hasError('pattern') == true ||
                      (payForm.value.Birthplace.length == 0 &&
                        (providerAttributes.birthplace.isRequired == true ||
                          providerAttributes.birthplace.isGateRequired ==
                            true)),
                    'input--validate':
                      payForm.controls.Birthplace.hasError('pattern') == false
                  }" placeholder="{{ providerAttributes.birthplace.title }}"/>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.Birthplace.hasError('pattern') == true
                }">*Поле обязательно к заполению.</span>
            </div>
            <div class="payment-modal__passport-info-block__inputs-item">
              <div class="requisites__item">
                <app-date-input-form-control
                  style="width: 100%"
                  [control]="payForm.controls.Birthday"
                  [placeholder]="providerAttributes.birthday.title">
                </app-date-input-form-control>
              </div>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.Birthday.errors?.ageError == true
                }">*Физ. лицо должно быть старше 18 лет.</span>
              <span class="report report--false report--valid" [ngClass]="{
                  'report--active':
                    payForm.controls.Birthday.errors?.invalidDate == true
                }">*Некорректная дата.</span>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="step !== 3">
        <div class="payment-modal__pay-block">
          <div class="payment-modal__pay-block__select">
            <label class="payment-modal__label">Способы оплаты</label>
            <div class="radio-select">

              <label class="radio-select__item" *ngFor="let provider of listProvider">
                <input type="radio" [formControl]="providerId" [value]="provider.id" (change)="onProviderChanged(provider)" />
                <span class="icon"></span>
                <span class="text">{{provider.title}}</span>
              </label>

            </div>
          </div>

          <div class="payment-modal__pay-block__card" *ngIf="isNewCardEnter && providerId && providerId.value">
            <div class="payment-modal__pay-block__card__go-back-link-block">
              <a
                (click)="goToCardsSelection()"
                *ngIf="canGoToCardsSelection">
                &#8592; Вернуться к выбору карт
              </a>
            </div>
            <div class="payment-modal__pay-block__card_new-card" *ngIf="getProviderWithdrawTypeId() === withdrawTypes.bankCard">
              <label class="payment-modal__pay-block__card-label">{{
                providerAttributes.cardNumber.title
                }}</label>
              <div class="payment-modal__pay-block__card-number">
                <input class="card__number-input payment-modal__pay-block__card-number-input"
                       type="text"
                       mask="0000 - 0000 - 0000 - 0000 - 000"
                       placeholder="0000 0000 0000 0000 000"
                       [validation]="false"
                       [dropSpecialCharacters]="true"
                       (input)="algLun(payForm.controls.CardNumber.value)"
                       formControlName="CardNumber"
                       [ngClass]="{
                  'input--nonvalidate':
                    payForm.controls.CardNumber.hasError('pattern') == true,
                  'input--validate':
                    payForm.controls.CardNumber.hasError('pattern') == false
                }" (input)="getCardInfo()" />
                <span class="message report report--false" type="text" *ngIf="payForm.controls.CardNumber.hasError('pattern')">
                Номер банковской карты должен содержать 16-19 цифр.
              </span>
                <span class="message report report--false" type="text" *ngIf="checkCard && payForm.value.CardNumber.length > 0">
                Некорректный номер карты.
              </span>
                <span class="message report report--false" type="text" *ngIf="
                  cardResultCheck &&
                  payForm.value.CardNumber.length > 0 &&
                  binDbQuery
                ">
                Данный банк не поддерживается
              </span>
              </div>
              <img src="/img/payment-gateways.svg"/>
            </div>

            <div class="payment-modal__pay-block__card_new-card" 
              *ngIf="getProviderWithdrawTypeId() === withdrawTypes.cashTransferSystem">
              <label class="payment-modal__pay-block__card-label">
                {{providerAttributes.phone.title}}
              </label>
              <div class="payment-modal__pay-block__card-number">
                  <input class="card__number-input payment-modal__pay-block__card-number-input disabled" 
                    [value]="phone.value" readonly />
              </div>

              <div class="payment-modal__select-point-block" *ngIf="sbpMembers && sbpMembers.length > 0">
                <app-select-box-control class="sbp-banks"
                  [items]="sbpMembers"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="Выберите банк"
                  formControlName="SbpMemberId">
                </app-select-box-control>
              </div>

            </div>
          </div>

          <div class="payment-modal__pay-block__card-selection" *ngIf="!isNewCardEnter">
            <mat-form-field appearance="outline" class="payment-modal__pay-block__card-selection-select">
              <mat-label>Выберите карту:</mat-label>
              <mat-select formControlName="CardNumber">
                <mat-option *ngFor="let card of personCards"
                            [value]="card.number">{{card.number | cardStars}}</mat-option>
                <button class="payment-modal__pay-block__card-selection-select-add-button"
                        (click)="goToCardEnter()">Добавить новую карту
                </button>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-modal__footer modal-footer">
      <button type="button" [disabled]="formSending || !isReady || !settingList" class="btn btn-outline-danger"
              (click)="goBack(step)">
        {{ step === 0 ? "К списку оплат" : "Назад" }}
      </button>
      <button *ngIf="step < 3" type="button" [disabled]="
          !isReady ||
          !settingList ||
          (step === 0 &&
            (payForm.controls.PointId.invalid ||
              payForm.controls.ScanPsa.invalid ||
              payForm.controls.DealDate.invalid ||
              payForm.controls.DealNumber.invalid ||
              payForm.controls.DealNumber.errors?.minError === true ||
              isUniqueError)) ||
          (step === 1 && (payForm.controls.products.invalid || !hasOne.isValid && !settingList.hideDealProduct || settingList.hideDealProduct && payForm.controls.Sum.invalid)) ||
          (step === 2 &&
            (passportLengthError ||
              payForm.controls.Surname.invalid ||
              payForm.controls.Benificiare.invalid ||
              payForm.controls.middleName.invalid ||
              payForm.controls.Phone.invalid ||
              payForm.controls.Passportfrom.invalid ||
              payForm.controls.Birthplace.invalid ||
              payForm.controls.Birthday.invalid ||
              payForm.controls.registrationAddress.invalid))
        " class="btn btn-primary" (click)="goForward(step)">
        Далее
      </button>

      <div *ngIf="step === 3">
        <button [submitDisable]="formSending" *hasPermission="[[userRoles.Administrator, userRoles.Manager, userRoles.ManagerPlus, userRoles.Cashier]]"
          class="btn btn-primary" [ngClass]="{
            'btn--pay-active':
              !formSending &&
              payForm.invalid == false &&
              passportLengthError == false &&
              cardResultCheck == false
          }" [disabled]="
            formSending ||
            payForm.invalid ||
            payForm.controls.CardNumber.invalid ||
            (payForm.value.CardNumber.length > 0 && checkCard) ||
            (payForm.value.CardNumber.length > 0 &&
              cardResultCheck &&
              binDbQuery)
          " value="Submit" type="submit">
          Готово
        </button>
      </div>
    </div>
  </form>
</div>
