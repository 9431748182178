<div class="licensee-settings" *ngIf="settingForm">

  <div class="alert alert-warning flex-wrapper" *ngIf="settingList.disableLicenseeBalance">
    <mat-icon color="warn" class="alert-icon">error</mat-icon>
    Установлена настройка отключения отображения баланса
  </div>
  
  <form
    id="id1538395961"
    class="licensee-settings__form"
    [formGroup]="settingForm"
    (ngSubmit)="onSubmit()"
    *ngIf="!settingList.disableLicenseeBalance">
      <div class="licensee-settings__wrapper">
        <div id="id1538395990" class="licensee-settings__block">

          <div class="licensee-settings__item">
            <div class="licensee-settings__set">
              <input class="input"
              type="text"
              id="set4"
              formControlName="LimitBalanceInfo"
              mask="0*"
              [ngClass]="{'input--nonvalidate': !settingForm.controls.LimitBalanceInfo.value,
                      'input--validate': settingForm.controls.LimitBalanceInfo.value && settingForm.controls.LimitBalanceInfo.value >= 0}">
              <label class="checkbox-label" for="set4"></label>
            </div>
            <div id="id1538391454" class="licensee-settings__label">
              {{settingList.limitBalanceInfoTitle}}
            </div>
          </div>

          <ng-container formArrayName="PhoneBalance">
            <ng-container *ngFor="let phoneBalance of settingForm.get('PhoneBalance')?.controls; let i=index">
              <ng-container [formGroupName]="i">
                <div class="licensee-settings__item">
                  <div class="licensee-settings__set relative">
                    <app-phone-control
                      [id]="getPhoneItemId(i)"
                      formControlName="phone"
                      hideValidationError="true"
                    ></app-phone-control>
                    <a id="id1538461128{{i}}" class="delete-btn delete-btn--ininput absolute" (click)="deletePhone(i)"></a>
                    <label class="checkbox-label" for="set{{i + 5}}"></label>
                  </div>
                  <div id="id1538391454" class="licensee-settings__label">
                    Номер телефона для оповещения
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          
          <div class="licensee-settings__item">
            <tsuz-add-button
              id="id1538461568"
              (click)="addPhone()"
              text="Добавить телефон"
              *ngIf="settingForm.get('PhoneBalance')['controls'].length < settingList.phoneBalanceCount; else limitForPhoneError">
            </tsuz-add-button>
            <ng-template #limitForPhoneError>
              <p class="report report--false report--valid report--active">
                Установлено ограничение на количество телефонов для оповещений равное {{settingList.phoneBalanceCount}}. <br/>
                Для его изменения обратитесь в техническую поддержку
              </p>
            </ng-template>
          </div>
        </div>
      </div>

      <button
        id="id1713359700"
        value="Submit"
        class="btn"
        [ngClass]="{'btn--active': settingForm.valid}"
        [disabled]="settingForm.invalid"
        type="submit">
        Сохранить
      </button>
  </form>
</div>
