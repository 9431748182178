<form  [formGroup]="changePasswordFormGroup" (ngSubmit)="sendConfirmCode()" >
  <section class="dropdown__section">
    <div class="dropdown__staff-settings-inpgroup">
      <div class="dropdown__staff-settings-inpgroup-item">
        <password-input
          id="oldPassword"
          formControlName="oldPassword"
          [maxlength]="passwordMaxLength"
          [labelName]="'Введите старый пароль'"
          [formCtrl]="changePasswordFormGroup.controls.oldPassword">
        </password-input>
        <span class="report report--false report--valid" [ngClass]="{'report--active': changePasswordFormGroup.controls.oldPassword?.hasError('minError') == true}">
          *Минимальная длина поля составялет {{passwordMinLength}} символов.
        </span>
        <span class="report report--false report--valid" [ngClass]="{'report--active': changePasswordFormGroup.controls.oldPassword?.hasError('maxError') == true}">
          *Максимальная длина поля составялет {{passwordMaxLength}} символов.
        </span>
      </div>
    </div>
    <div class="dropdown__staff-settings-inpgroup">
      <div class="dropdown__staff-settings-inpgroup-item">
        <password-input
          id="newPassword"
          formControlName="newPassword"
          [maxlength]="passwordMaxLength"
          [labelName]="'Введите новый пароль'"
          [formCtrl]="changePasswordFormGroup.controls.newPassword">
        </password-input>
        <span class="report report--false report--valid" [ngClass]="{'report--active': changePasswordFormGroup.controls.newPassword?.hasError('minError') == true}">
          *Минимальная длина поля составялет {{passwordMinLength}} символов.
        </span>
        <span class="report report--false report--valid" [ngClass]="{'report--active': changePasswordFormGroup.controls.newPassword?.hasError('maxError') == true}">
          *Максимальная длина поля составялет {{passwordMaxLength}} символов.
        </span>
      </div>
    </div>
    <div class="dropdown__staff-settings-inpgroup">
      <div class="dropdown__staff-settings-inpgroup-item">
        <password-input
          id="repeatNewPassword"
          formControlName="repeatNewPassword"
          [maxlength]="passwordMaxLength"
          [labelName]="'Повторите новый пароль'"
          [formCtrl]="changePasswordFormGroup.controls.repeatNewPassword">
        </password-input>
        <span class="report report--false report--valid" [ngClass]="{'report--active': changePasswordFormGroup.controls.repeatNewPassword?.hasError('minError') == true}">
          *Минимальная длина поля составялет {{passwordMinLength}} символов.
        </span>
        <span class="report report--false report--valid" [ngClass]="{'report--active': changePasswordFormGroup.controls.repeatNewPassword?.hasError('maxError') == true}">
          *Максимальная длина поля составялет {{passwordMaxLength}} символов.
        </span>
      </div>
    </div>
    <div class="message report report--false text-center" *ngIf="changePasswordFormGroup.hasError('mismatch') ">Введенные значения не совпадают</div>
</section>
<div class="dropdown__footer">
    <button  [disabled]="changePasswordFormGroup.invalid" [ngClass]="{'btn--active': changePasswordFormGroup.valid == true}" type="submit" class="btn">Подтвердить</button>
</div>
</form>
