<div *ngIf="formGroup">
  <form class="container" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <tsuz-api-key 
      formControlName="apiKey" 
      (clearApiKey)="apiKey.setValue('')" 
      (refreshApiKey)="refreshApiKey()">
    </tsuz-api-key>

    <button value="Submit" class="btn" [ngClass]="{'btn--active':formGroup.valid}" [disabled]="formGroup.invalid" type="submit">
      Сохранить
    </button>
  </form>
</div>