<div class="table" *ngIf="users">
    <tsuz-users-page-header class="table__header-row" [filter]="filter" (apply)="applyFilter()"></tsuz-users-page-header>

    <!--Наличие класса opened означает, что table__hidden-row будет показываться-->
    <ng-container *ngFor="let user of users;let i = index">
        <div id="id1538408848{{i}}" class="table__row" [ngClass]="{'opened': user.isShowDropDown == true}"
            tsuz-user-item [user]="user" (click)="dropDownUser(user.id)">
        </div>
        <div class="table__hidden-row">
            <td colspan="6" class="table__hidden-data">

                <div class="dropdown">
                    <div class="dropdown__left-menu">
                        <!--При клике на меню, у него должен появляться класс active и должен подключаться соответствующий блок-->
                        <a id="id1538408905{{i}}" class="dropdown__left-menu--link"
                            [ngClass]="{'active': user.isShowPoint == true}" (click)="showRight('point', user.id)">
                            "{{settingList?.pointName}}" сотрудника
                        </a>
                        <ng-container *ngIf="editUserAccess(user)">
                            <ng-container *ngIf="user.id != myId">
                                <a id="id1538408935{ {i}}" class="dropdown__left-menu--link"
                                    [ngClass]="{'active': user.isShowRole == true}" *ngIf="!user.hideRoles || isExecutiveOfficer"
                                    (click)="showRight('role', user.id)">Роли</a>
                            </ng-container>
                            <a id="id1538408981{{i}}" class="dropdown__left-menu--link" *ngIf="!readonly"
                                [ngClass]="{'active': user.isShowReset == true}"
                                (click)="showRight('reset', user.id)">Сбросить
                                пароль</a>
                            <a id="id1538409017{{i}}" class="dropdown__left-menu--link" *ngIf="!readonly"
                                [ngClass]="{'active': user.isShowBlock == true}"
                                (click)="showRight('block', user.id)">Статус</a>
                            <a id="id1538409043{{i}}" class="dropdown__left-menu--link"
                                [ngClass]="{'active': user.isShowSet == true}"
                                (click)="showRight('set', user.id)">Настройки</a>
                            <a id="id1538409043{{i}}" class="dropdown__left-menu--link" *ngIf="!readonly"
                                [ngClass]="{'active': user.isShowChangeCode == true}"
                                (click)="showRight('code', user.id)">Код подтверждения</a>
                            <a id="id1572444318{{i}}" class="dropdown__left-menu--link"
                                *hasPermission="[[userRoles.Administrator, userRoles.ExecutiveOfficer]]"
                                [ngClass]="{'active': user.isShowChangePermissions == true}"
                                (click)="showRight('permissions', user.id)">"Локации" сотрудника</a>
                        </ng-container>
                    </div>

                    <div class="dropdown__main" show-pzu *ngIf="user.isShowPoint" [readonly]="readonly"
                        [user]="user" [pointName]="settingList.pointName" [licenseeId]="licenseeId"></div>
                    <div class="dropdown__main" edit-role *ngIf="user.isShowRole" [readonly]="readonly"
                        [user]="user" [licenseeId]="licenseeId" [userMainRole]="mainRole"></div>
                    <div class="dropdown__main" reset-pass *ngIf="user.isShowReset" [readonly]="readonly"
                        [user]="user" [licenseeId]="licenseeId"></div>
                    <div class="dropdown__main" edit-activity *ngIf="user.isShowBlock" [readonly]="readonly"
                        [user]="user"></div>
                    <div class="dropdown__main" edit-user *ngIf="user.isShowSet" [readonly]="readonly"
                        [user]="user" [licenseeId]="licenseeId"></div>
                    <div class="dropdown__main" change-code *ngIf="user.isShowChangeCode" [readonly]="readonly"
                        [user]="user"></div>
                    <div class="dropdown__main" edit-permissions *ngIf="user.isShowChangePermissions" [readonly]="readonly"
                        [user]="user"></div>
                </div>
            </td>
        </div>
    </ng-container>
</div>
