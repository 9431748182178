import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ILicenseeApiSettings } from '@models/licensee-setting/ILicenseeApiSettings';
import { LicenseeService } from '@services/licensee.service';
import { LoaderService, ILicenseeSetting, NotificationService } from 'tsuz-common';
import { v4 as randomUUID } from 'uuid';

@Component({
  selector: 'api-settings',
  templateUrl: './api-settings.component.html'
})
export class ApiSettingsComponent implements OnInit {

  licenseeId: string;
  readonly: boolean = false;
  settingList: ILicenseeSetting;
  formGroup: FormGroup = this.formBuilder.group({
    apiKey: ['']
  });

  constructor(
    private licenseeService: LicenseeService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    public notificationService: NotificationService,
    @Inject("LOCALSTORAGE") private localStorage: any,
  ) { }
  
  ngOnInit() {
    this.licenseeId = this.localStorage.getItem('licenseeId');
    this.getApiSettings();
  }

  get apiKey() : FormControl {
    return this.formGroup.get('apiKey') as FormControl;
  }

  refreshApiKey() {
    if (this.readonly) return;

    const newGuid = randomUUID();
    this.apiKey.setValue(newGuid);
  }

  getApiSettings(): void {
    this.loaderService.display(true);
    this.licenseeService
      .getApiSettings()
      .subscribe(result => {
        this.init(result as ILicenseeApiSettings);
        this.loaderService.display(false);
      }, error => {
      console.error(error);
    });
  }
  
  init(apiSettings: ILicenseeApiSettings) {
    this.apiKey.setValue(apiSettings.apiKey);
  }

  //Отправка данных
  async onSubmit() {
    if (this.readonly) return;

    const data = this.formGroup.getRawValue();
    this.licenseeService.updateApiSettings(data)
      .subscribe(_ => {
        this.notificationService.successNotify('Настройки API успешно изменены', 'Сохранено');
      });
  }
}
