import {Component, Inject, Input, OnInit} from '@angular/core';
import {finalize} from 'rxjs/operators';
import { Location } from '@angular/common'
import {FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { IUsersService } from '../../../services/interfaces/IUsersService';
import { IPoint } from '../../../models/IUser';
import { ILicenseeSetting } from '../../../models/ILicenseeSetting';
import { AuthService } from '../../../services/auth.service';
import { UserRoles } from '../../../models/auth/user-roles';
import { FioValidator } from '../../../validators/fio.validator';
import { PhoneValidator } from '../../../validators/phone.validator';
import { LengthValidator } from '../../../validators/length.validatorr';
import { CheckEmailValidator } from '../../../validators/email.validator';
import { ILicenseeService } from '../../../services/interfaces/ILicenseeService';
import { IPointService } from '../../../services/interfaces/IPointService';
import { getPhoneMask } from '../../../helpers/masks.helper';

interface ConfirmModel {
  title: string;
  message: string;
  isAdmin: boolean;
}

@Component({
  selector: 'tsuz-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements ConfirmModel, OnInit {
  @Input() licenseeId: string;

  form: FormGroup;
  name: FormControl;
  surname: FormControl;
  roleForm: FormGroup;
  pointForm: FormGroup;
  middlename: FormControl;
  login: FormControl;
  roleArray: FormArray;
  pointArray: FormArray;
  phone: FormControl;
  email: FormControl;
  hasFakePhone: FormControl;
  isAllPointsSelectedControl: FormControl;
  title: string;
  message: string;
  roleList: any;
  isAdmin: boolean;
  errorMes: string;
  isDirector: boolean;
  settingList: ILicenseeSetting;
  pointCheck = false;
  roleCheck = false;
  errorInfo: string;
  errorCheck = false;
  submitting = false;
  pointAll: IPoint[];
  // Роли, для которых доступны все точки
  private readonly allPointsRoles: UserRoles[] = [UserRoles.Administrator, UserRoles.Accountant, UserRoles.FinancialDirector];
  public isAllPointsRoleSelected: boolean = false;
  getPhoneMask = getPhoneMask;


  constructor(
    private toastr: ToastrService,
    private location: Location,
    private authService: AuthService,
    @Inject("PointService") private pointService: IPointService,
    @Inject("LicenseeService") private licenseeService: ILicenseeService,
    @Inject("UsersService") private usersService: IUsersService,
  ) {
  }

  ngOnInit(): void {
    // Определение прав просмотра
    this.isDirector = this.authService.inRole(UserRoles.Administrator) || this.authService.inRole(UserRoles.ExecutiveOfficer);
    // Получение настроек Лицензиата
    this.licenseeService.getSettings(this.licenseeId, true).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
      // Создание форм
      this.createFormControls();
      this.createForm();
    }, error => {
      console.error(error);
    });
  }

  createFormControls() {
    this.name = new FormControl('', [Validators.required, FioValidator()]);
    this.phone = new FormControl(null, [Validators.required, PhoneValidator()]);
    this.surname = new FormControl('', [Validators.required, FioValidator()]);
    this.login = new FormControl('', LengthValidator(2, 60));
    this.middlename = new FormControl(null, FioValidator());
    this.email = new FormControl(null, [CheckEmailValidator()]);
    this.hasFakePhone = new FormControl(false);
    this.isAllPointsSelectedControl = new FormControl(false);
    this.roleArray = new FormArray([]);
    this.roleList = this.authService.getAllRoles().filter(x => x !== UserRoles.Support);

    // Создаем политику отображения ролей
    if (!this.authService.inRole(UserRoles.ExecutiveOfficer)) {
      this.roleList = this.roleList.filter(x => x !== UserRoles.Administrator && x !== UserRoles.FinancialDirector);
    }
    
    if (!this.isDirector) {
      this.roleList = this.roleList.filter(x => x !== UserRoles.ManagerPlus);
    }
    const arrCheckBoxs = this.roleList.map(() => {
      return new FormControl(false);
    });
    for (let i = 0; i < arrCheckBoxs.length; i++) {
      this.roleArray.push(arrCheckBoxs[i]);
    }
    this.roleForm = new FormGroup({
      roles: this.roleArray
    });
    // Получаем точки Лицензиата
    this.pointService.getAllPoint(this.licenseeId)
      .subscribe(result => {
        this.pointAll = result as IPoint[];
        this.pointAll.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
        const pointList: { value: string, title: string }[] = [];
        for (let i = 0; i < this.pointAll.length; i++) {
          pointList.push({
            value: String(this.pointAll[i].pointId),
            title: String(this.pointAll[i].title + ' ' + this.pointAll[i].address)
          });
        }
        this.pointArray = new FormArray([]);
        const arrCheckBoxs1 = pointList.map(() => {
          return new FormControl(false);
        });
        for (let i = 0; i < arrCheckBoxs1.length; i++) {
          this.pointArray.push(arrCheckBoxs1[i]);
        }
        this.pointForm = new FormGroup({
          points: this.pointArray
        });
      }, error => console.error(error));
  }

  private get isAllPointsSelected(): boolean {
    return this.isAllPointsSelectedControl.value;
  };

  private set isAllPointsSelected(value: boolean) {
    this.isAllPointsSelectedControl.patchValue(value);
  };

  public toggleSelectAllPoints(): void {
    this.setSelectForAllPoints(this.isAllPointsSelected);
  }

  private setSelectForAllPoints(value: boolean): void {
    this.pointArray.controls.forEach(c => c.setValue(value));
    this.isAllPointsSelected = value;
  }

  private setDisabledForAllPoints(value: boolean): void {
    this.pointArray.controls.forEach(c => value ? c.disable() : c.enable());
    value ? this.isAllPointsSelectedControl.disable() : this.isAllPointsSelectedControl.enable();
  }


  private isEveryPointSelected(): boolean {
    return this.pointArray?.value.every((x: boolean) => x);
  }
  
  private isAnyPointSelected(): boolean {
    return this.pointArray?.value.some((x: boolean) => x);
  }
  
  private updateIsAllPointsSelected(): void {
    this.isAllPointsSelected = this.isEveryPointSelected();
  }

  // Должна быть выбрана хотя бы одна точка
  validPoints() {
    this.pointCheck = this.isAnyPointSelected();
  }

  // Должна быть выбрана хотя бы одна точка
  pointCheckChanged() {
    this.validPoints();
    this.updateIsAllPointsSelected();
  }

  // Должна быть хотя бы одна роль
  validRole(roleIndex: any) {
    if (this.roleArray) {
      // Применяем все точки, если роль входит в роли, которым доступны все точки
      this.isAllPointsRoleSelected = this.allPointsRoles.includes(this.roleList[roleIndex]) && this.roleArray.value[roleIndex];
      this.setDisabledForAllPoints(this.isAllPointsRoleSelected);
      if (this.isAllPointsRoleSelected) {
        this.setSelectForAllPoints(true);
      }

      for (let i = 0; i < this.roleArray.length; i++) {
        if (i !== roleIndex) {
          this.roleArray.controls[i].setValue(false);
        }
      }
      // Хотя бы одна роль
      this.roleCheck = (this.roleArray.value.filter(x => x).length === 1);
    }
    this.validPoints();
  }

  createForm() {
    this.form = new FormGroup({
      Name: this.name,
      Phone: this.phone,
      Surname: this.surname,
      MiddleName: this.middlename,
      Email: this.email,
      Login: this.login,
      HasFakePhone: this.hasFakePhone,
      IsAllPointsSelectedControl: this.isAllPointsSelectedControl
    });
  }

  // Отправка данных
  toPost() {
    if (this.form.valid && this.roleCheck && this.pointCheck) {
      const points = [];
      for (let i = 0; i < this.pointArray.value.length; i++) {
        if (this.pointArray.value[i]) {
          points.push(this.pointAll[i].pointId);
        }
      }

      const formData = new FormData();
      const phoneNumber = this.form.value.Phone;

      formData.append('Surname', this.form.value.Surname);
      formData.append('Name', this.form.value.Name);

      if (this.form.value.MiddleName !== 'null' && this.form.value.MiddleName) {
        formData.append('MiddleName', this.form.value.MiddleName);
      }

      if (this.form.value.Login !== 'null' && this.form.value.Login) {
        formData.append('Login', this.form.value.Login);
      }

      if (this.form.value.Email !== 'null' && this.form.value.Email) {
        formData.append('Email', this.form.value.Email);
      }

      for (let i = 0; i < this.roleArray.value.length; i++) {
        if (this.roleArray.value[i]) {
          // программно запрещен выбор нескольких ролей, поэтому массив всегда будет содержать 1 элемент
          formData.append('Role', this.roleList[i]);
          break;
        }
      }

      formData.append('Phone', phoneNumber.formatPhone());

      formData.append('HasFakePhone', this.form.value.HasFakePhone);
      this.submitting = true;

      this.usersService.addNewEmp(this.licenseeId, formData).pipe(
        finalize(() => this.submitting = false))
        .subscribe(
          data => {
            this.usersService.updateEmpPoints(this.licenseeId, data.userId, points).subscribe(() => {
                this.showToast(null, false);
                this.errorCheck = false;
              
                this.goBack();
              },
              error => this.showToast(error, true));
          },
          error => this.showToast(error, true));
    }
  }

  showToast(message: string, isError: boolean) {
    if (isError) {
      this.toastr.error(message, 'Ошибка!', {
        closeButton: true,
        progressBar: true
      });
    } else {
      this.toastr.success('Успешно зарегистрирован!', 'Cотрудник ' + this.form.value.Phone, {
        closeButton: true,
        progressBar: true
      });
    }
  }

  hasFakePhoneChange() {
    const matchingControl = this.form.controls['HasFakePhone'];
    if (matchingControl.value) {
      this.email.setValidators([Validators.required, CheckEmailValidator()]);
    } else {
      this.email.setValidators([CheckEmailValidator()]);
    }
    this.email.updateValueAndValidity();
  }

  goBack() {
    this.location.back();
  }
}
