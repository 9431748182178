import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseQueryFilter } from '../filters/base-query-filter';

@Injectable()
export class FilterService {
  constructor(private route: ActivatedRoute, private router: Router) { }

  getValue(key: string): any {
    const {queryParams} = this.route.snapshot;
    if (!key) {
      return null;
    }

    return queryParams[key];
  }

  isActive(key: string): boolean {
    const val = this.getValue(key);

    if (Array.isArray(val)){
      return val.length > 0;
    }

    return val !== undefined && val !== null && val !== '';
  }

  apply(key: string, value: any): Promise<boolean> {
    const isNotNil = value !== undefined && value !== null;

    if (key && isNotNil) {
      return this.router.navigate(
        [], {
        relativeTo: this.route,
        queryParams: { [key]: String(value) },
        queryParamsHandling: 'merge',
      });
    }

    return Promise.resolve(true);
  }

  applyFromModel(model: BaseQueryFilter, reset = false) {
    const queryHandling = reset ? '' : 'merge';

    var params = model.toRouterParams();

    this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: queryHandling,
      });
  }

  initModelFromQueryParamms(paramms: any, model: any) {
    Object.keys(paramms).forEach(key => {
        model[key] = paramms[key];
    });
  }

  reset<T extends object>(model: T): T {
    const params = {...this.route.snapshot.queryParams};

    Object.keys(model).forEach(key => {
      if (key === '_page') {
        key = 'page';
      }

      if (params[key]) {
        delete model[key];
        delete params[key];

        if (key === 'page') {
          model[key] = 1;
        }
        if (key === 'take') {
          model[key] = 10;
        }
      }
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params
    });

    return model;
  }
}
