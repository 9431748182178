import { Injectable } from '@angular/core';
import { DealData } from '../models/widgets/deal-data';
import { DealInProcessingData } from '../models/widgets/deal-in-processing-data';
import { LimitsData } from '../models/widgets/limits-data';
import { RnkoRegisterData } from '../models/widgets/rnko-register-data';
import { TransactionData } from '../models/widgets/trasnsaction-data';
import { TurnoverData } from '../models/widgets/turnover-data';
import { BaseHttpService } from 'tsuz-common';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  constructor(private baseHttpService: BaseHttpService) { }

  public getBalance(): Promise<TurnoverData> {
    return this.baseHttpService.get('/api/widgets/turnover_company').toPromise();
  }

  public getTurnoverForMonth(): Promise<any> {
    return this.baseHttpService.get('/api/widgets/turnover_money').toPromise();
  }

  public getProductsIncomingData(): Promise<any[]> {
    return this.baseHttpService.get('/api/widgets/incomming_products').toPromise();
  }

  public getDealsData(): Promise<DealData[]> {
    return this.baseHttpService.get('/api/widgets/deals').toPromise();
  }

  public getDealsInProcessingData(): Promise<DealInProcessingData> {
    return this.baseHttpService.get('/api/widgets/deals_in_processing').toPromise();
  }

  public getRnkoRegisterData(): Promise<RnkoRegisterData> {
    return this.baseHttpService.get('/api/widgets/rnko_register').toPromise();
  }

  public geLimitsData(): Promise<LimitsData> {
    return this.baseHttpService.get('/api/widgets/points_limits').toPromise();
  }

  public geTransactionsData(): Promise<TransactionData[]> {
    return this.baseHttpService.get('/api/widgets/incomming_transactions').toPromise();
  }

  public getCashpoolingAdData(): Promise<any> {
    return this.baseHttpService.get('/api/widgets/cashpooling_ad_data').toPromise();
  }

}
