<input [attr.id]="id ? id : null"
       [value]="value"
       [textMask]="{mask: getPhoneMask}"
       (change)="onChange($event)"
       (keyup)="onKeyup($event)"
       (focusout)="onFocusOut($event)"
       (blur)="onBlur($event)"
       [ngClass]="{'input--nonvalidate': ngControl.invalid,
                    'input--validate': ngControl.valid}"
       placeholder="+"
       type="text"
       class="{{overrideCssClass}}"
       [disabled]="disabled">

<ng-container *ngIf="!hideValidationError">
  <app-validation-error-item  [formCtrl]="ngControl" [errorKey]="'InvalidPhone'">
    Номер телефона должен быть в формате +7(000)000-00-00
  </app-validation-error-item>
</ng-container>


