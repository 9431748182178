<div class="holding-structure--component-container
holding-structure--licensee-widget-component
holding-structure--angles-rounded-container">
  <div class="holding-structure--licensee-widget-component--grid">
    <div class="holding-structure--licensee-widget-component--grid--caption-area">
      {{licenseeInfo.licenseeName}}
    </div>
    <div class="holding-structure--licensee-widget-component--grid--points-count-area
                holding-structure--licensee-widget-component--grid--section-bottom-edge">
      {{licenseeInfo.pointsCount}} ПЗУ
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-label-area"
          *ngIf=licenseeInfo.hasTransitAccount>
      Лимит установленный в РНКО
    </div>
    <div class="holding-structure--licensee-widget-component--grid--content-value-area"
         *ngIf="licenseeInfo.hasTransitAccount">
      <span class="blue-font">{{licenseeInfo.limit | money: licenseeInfo.currency}}</span>
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-label-area"
          *ngIf="!licenseeInfo.hasTransitAccount && !licenseeInfo.disableLicenseeBalance">
      Баланс
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-value-area"
          *ngIf="!licenseeInfo.hasTransitAccount && !licenseeInfo.disableLicenseeBalance">
      <span class="blue-font">{{licenseeInfo.balance | money: licenseeInfo.currency}}</span>
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-label-area"
          *ngIf="licenseeInfo.hasTransitAccount">
      Суточный расход
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-value-area"
          *ngIf="licenseeInfo.hasTransitAccount">
      <span class="red-font">{{licenseeInfo.dailyExpense | money: licenseeInfo.currency}}</span>
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-label-area"
          *ngIf="!licenseeInfo.hasTransitAccount">
      Суточный лимит
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-value-area"
          *ngIf="!licenseeInfo.hasTransitAccount">
      <span class="blue-font">{{licenseeInfo.limit | money: licenseeInfo.currency}}</span>
    </div>
    <div class="holding-structure--licensee-widget-component--grid--content-label-area">
      Доступный остаток по лимиту
    </div>
    <div class="holding-structure--licensee-widget-component--grid--content-value-area">
      <span class="blue-font">{{licenseeInfo.availableBalance | money: licenseeInfo.currency}}</span>
    </div>
    <div class="holding-structure--licensee-widget-component--grid--content-label-area">
      Прогноз остатка на конец дня
    </div>
    <div class="holding-structure--licensee-widget-component--grid--content-value-area">
      <span
      [class.red-font]="licenseeInfo.restPrediction < 0"
      [class.blue-font]="licenseeInfo.restPrediction >= 0"
      >{{licenseeInfo.restPrediction | money: licenseeInfo.currency}}</span>
    </div>
    <div  class="holding-structure--licensee-widget-component--grid--content-top-up-balance-area"
          [class.holding-structure--licensee-widget-component--grid--content-top-up-balance-area-red]="licenseeInfo.restPrediction < 0">
      Рекомендуем пополнить баланс
    </div>
    <div class="holding-structure--licensee-widget-component--grid--footer-area
                holding-structure--licensee-widget-component--grid--section-top-edge">
        <span>
          <a href="/points?licensee={{licenseeInfo.licenseeId}}">Перейти к ПЗУ</a>
        </span>
        <span>
          <a href="/deals?page=1&take=5&licenseeId={{licenseeInfo.licenseeId}}">Список оплат</a>
        </span>
    </div>
  </div>
</div>
