<div class="licensee-settings" *ngIf="settingForm">
    <form id="id1538395961" class="licensee-settings__form" [formGroup]="settingForm" (ngSubmit)="onSubmit()">
        <div class="licensee-settings__wrapper">
            <div id="id1538395990" class="licensee-settings__block">
                <div class="licensee-settings__item"></div>
                <ng-container formArrayName="Emails">
                    <ng-container *ngFor="let email of settingForm.get('Emails')?.controls; let i=index">
                        <ng-container [formGroupName]="i">
                            <div class="licensee-settings__item">
                                <div class="licensee-settings__set relative">
                                    <a id="id1538461128{{i}}" class="delete delete--ininput absolute"
                                        (click)="deleteEmail(i)"></a>
                                        <input class="input" type="text" id="set{{i + 5}}" formControlName="email"
                                        pattern="^([\w\.%\+\-]+)@([\w\-]+\.)+([\w]{2,})$"
                                        placeholder="MyEmail@mail.ru">
                                    <label class="checkbox-label" for="set{{i + 5}}"></label>
                                    <span class="report report--false report--valid"
                                    [ngClass]="{'report--active-block': email.invalid}">*Некорректный email.</span>
                                </div>
                                <div id="id1538391454" class="licensee-settings__label">
                                    Электронная почта для рассылки
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="licensee-settings__item">
                    <tsuz-add-button id="id1538461568" (click)="addEmail()" *ngIf="!readonly && settingForm.get('Emails')['controls'].length < settingList.registryEmailInfoCount"></tsuz-add-button>
                    <ng-template #limitForEmailError
                        *ngIf="settingForm.get('Emails')['controls'].length > settingList.registryEmailInfoCount">
                        <span class="report report--false report--valid report--active">*Достигнуто максимальное количество
                            email-ов -
                            {{settingList.registryEmailInfoCount}}</span>
                    </ng-template>
                </div>
            </div>
        </div>
        <button id="id1538396430" value="Submit" class="btn" *ngIf="!readonly" [ngClass]="{'btn--active':settingForm.valid}" [disabled]="settingForm.invalid" type="submit">
          Сохранить
        </button>
    </form>
</div>
