<div  class="filter__text-align-left"
      (clickOutside)="onCancel()">
  <div class="date-range-filter__header">
    <ng-content></ng-content>
  </div>

  <app-sorting-header *ngIf="_sort"
                      [(sort)]="_sort"
                      [sortField]="sortField"
                      (sortChange)="onSortClick()">
  </app-sorting-header>

  <div  class="filter__btn date-range-filter__show-button"
        [ngClass]="{'filter__btn-active': isActive, 'filter__margin-left': !_sort}"
        (click)="isShowModal=true;">
  </div>

  <div class="filter zindex date-range-filter__modal"
       *ngIf="isShowModal">
    <div class="filter__input-wrapper">
      <app-date-range-input [(ngModel)]="model"></app-date-range-input>
    </div>

    <div class="filter__footer date-range-filter__controls">
      <a class="btn2 active float-left date-range-filter__apply"
         (click)="onApply()">Применить</a>
      <a class="btn2 float-right date-range-filter__cancel"
         (click)="onCancel()">Отмена</a>
    </div>
  </div>
</div>
