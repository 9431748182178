import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Subject} from 'rxjs';
import {OptionItemModel} from './option-item-model';
import {debounceTime, delay, filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchSelectComponent implements OnInit, OnDestroy {
  public readonly allTitle: string = 'Все';
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  @Input() control: FormControl;
  
  private _options: OptionItemModel[];
  get options() : OptionItemModel[] {
    return this._options;
  }
  @Input() set options(value: OptionItemModel[]) {
    this._options = value;
    this.filteredOptions = this._options;
    if (this.filterControl) {
      this.filterControl.setValue('');
    }
    this._isAllSelected = this.isAllOptionsSelected();
  }
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() showAll: boolean = false;

  filterControl: FormControl = new FormControl();
  public displayTitle: string = '';
  public isSearching = false;
  protected _onDestroy = new Subject<void>();

  private _filteredOptions: Array<OptionItemModel>;
  public get filteredOptions(): Array<OptionItemModel> {
    return this._filteredOptions;
  }
  public set filteredOptions(value: Array<OptionItemModel>) {
    this._filteredOptions = value;
  }
  
  private _isAllSelected: boolean;
  public get isAllSelected(): boolean {
    return this._isAllSelected;
  }
  public set isAllSelected(value: boolean) {
    this._isAllSelected = value;
    if (value) {
      const optionValues = this.getOptionValues();
      this.control.patchValue(optionValues);
    } else {
      this.control.patchValue([]);
    }
  }
  
  constructor() { }

  ngOnInit() {
    this.filteredOptions = this.options;
    this._isAllSelected = this.isAllOptionsSelected();
    this.updateDisplayTitle();

    this.filterControl.valueChanges
      .pipe(
        tap(() => this.isSearching = true),
        debounceTime(200),
        map(search => {
          if (!this.options) {
            return [];
          }
          return this.options.filter(bank => bank.display.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredBanks => {
          this.isSearching = false;
          const currentSelectedOptions = this.getCurrentSelectedOption();
          this.filteredOptions = this.options.filter(o => currentSelectedOptions.includes(o) || filteredBanks.includes(o));
        },
        error => {
          console.error(error);
          this.isSearching = false;
        });
  }

  private getOptionValues(): string[] {
    return [...this.options.map(item => item.value)];
  }

  getCurrentSelectedOption(): Array<OptionItemModel> {
    if (!this.options || !this.control.value) {
      return [];
    }

    return this.options.filter(option => this.control.value.some(el => el === option.value));
  }

  public updateDisplayTitle() { 
    if (this.showAll && this.isAllSelected) {
      this.displayTitle = this.allTitle;
    } else {
      const selectedOptions = this.getCurrentSelectedOption();
      this.displayTitle = selectedOptions.map(o => o.display).toString();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public onClose() {
    this.updateDisplayTitle()
  }

  private isAllOptionsSelected(): boolean {
    return this.control.value.length === this.options.length;
  }

  public clickSingleOption() { 
    if (this.isAllSelected) {
      this._isAllSelected = false;
      return;
    }
    if (this.isAllOptionsSelected()) {
      this.isAllSelected = true;
    }
  }
}
