import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { IRegistry } from '../../../models/registries/registry';
import { RegistriesService } from '@services/registries.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Timer } from './timer';
import { SmsConfirmationState } from './sms-confirmation-state.enum';
import { state } from '@angular/animations';

@Component({
  selector: 'app-sms-confirmation',
  templateUrl: './sms-confirmation.component.html',
  styleUrls: ['./sms-confirmation.component.scss']
})
export class SmsConfirmationComponent implements OnInit, OnDestroy {
  // Создание события для фокуса на строке ввода телефона.
  @ViewChild('autofocus', { static: false })
  autofocusElement: ElementRef;

  @Output()
  confirm = new EventEmitter<IRegistry>();

  @Output()
  cancel = new EventEmitter();

  approvedForm: FormGroup;
  registry: IRegistry;
  timer: Timer;
  errorMessage?: string;
  state: SmsConfirmationState;
  viewState = SmsConfirmationState;

  constructor(
    private registriesService: RegistriesService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.approvedForm = this.fb.group({
      Code: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(8)]]
    });
    // tslint:disable-next-line: no-non-null-assertion
    this.timer = new Timer(() => {
      if (this.autofocusElement) {
        this.autofocusElement.nativeElement.focus();
      }
    });
  }

  check(registry: IRegistry) {
    this.errorMessage = null;
    this.registry = registry;
    this.state = SmsConfirmationState.none;
    this.approvedForm.reset();
    this.sendCode();
  }

  // Генерация кода смс.
  sendCode() {
    this.registriesService.SendSmsCode(this.registry.id)
      .subscribe(
        result => this.timer.startTimer(),
        error => this.errorMessage = error);
  }

  onSubmitCode() {
    this.state = SmsConfirmationState.busy;
    this.registriesService.CheckCode(this.registry.id, this.approvedForm.value.Code)
      .subscribe(
        result => {
          this.timer.stopTimer();
          this.state = SmsConfirmationState.success;
        },
        error => {
          this.approvedForm.reset();
          this.errorMessage = error;
          this.state = SmsConfirmationState.error;
        });
  }

  close() {
    if (this.state === SmsConfirmationState.success) {
      this.confirm.emit(this.registry);
    }
    this.cancel.emit();
  }

  ngOnDestroy(): void {
    this.timer.stopTimer();
  }
}
