import { HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';

export interface CacheEntry {
  key: string;
  response: HttpResponse<any>;
  entryTime: number;
  // in milliseconds
  expireTime: number;
}

// in milliseconds
export const MAX_CACHE_AGE = 3600000;
