<div class="popup__wrapper">
    <div class="popup__header">
        <h4 class="h4">{{isFileUpload ? 'Загрузка товаров' : 'Добавление товара'}}</h4>
        <a class="tap-to-close" (click)="close()"></a>
    </div>
    <div class="popup__main">
        <form [formGroup]="form" class="modal-content" (ngSubmit)="isFileUpload ? onUploadFileSubmit() : onAddProductSubmit()">
            <div class="container modal-body">
                <div class="row">
                    <ng-container *ngIf="isFileUpload; else addProduct">
                        <div class="col-6">
                            <label>Файл с товарами</label>
                            <tsuz-upload-files
                                [labelText]="'Файл с товарами, xslx/csv'"
                                [minFileCount]="0"
                                [maxFileCount]="1"
                                [tooltip]="productsFileFormat"
                                [acceptFileTypes]="['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
                                (fileListChanged)="productsFileListChanged($event)">
                            </tsuz-upload-files>
                        </div>

                        <div class="col-6">
                            <a download="Пример для загрузки номенклатурных позиций.xlsx" [href]="example" class="btn2 btn2--active">
                                Образец таблицы <mat-icon>download</mat-icon>
                            </a>
                        </div>
                    </ng-container>
                
                    <mat-form-field class="col-6">
                        <mat-label>Категория</mat-label>
                        <input matInput formControlName="category" [matAutocomplete]="categoryAuto">

                        <mat-autocomplete #categoryAuto="matAutocomplete">
                            <mat-option *ngFor="let option of categories" [value]="option.name">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error *ngIf="form.get('category').hasError('required')">Обязательное поле</mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-6">
                        <mat-label>Система измерения</mat-label>
                        <input matInput formControlName="measure">

                        <mat-error *ngIf="form.get('measure').hasError('required')">Обязательное поле</mat-error>
                    </mat-form-field>

                    <ng-template #addProduct>
                        <mat-form-field class="col-6">
                            <mat-label>Название товара</mat-label>
                            <input matInput formControlName="productName">
    
                            <mat-error *ngIf="form.get('productName').hasError('required')">Обязательное поле</mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-6">
                            <mat-label>Ключ товара</mat-label>
                            <input matInput formControlName="productKey">
    
                            <mat-error *ngIf="form.get('productKey').hasError('required')">Обязательное поле</mat-error>
                        </mat-form-field>
                    </ng-template>
                </div>

                <ng-container formArrayName="measureUnits">
                    <h3 class="array-header">Единицы измерения</h3>
                    <div class="row" [formGroup]="unit" *ngFor="let unit of measureUnits.controls; index as i">
                        <mat-form-field class="col-6">
                            <mat-label>Единица измерения</mat-label>
                            <input matInput formControlName="name" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="measureUnitChanged($event, unit)">
                                <mat-option *ngFor="let option of measureUnitOptions" [value]="option.name">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field class="col-5">
                            <mat-label>Сокращённо</mat-label>
                            <input matInput formControlName="shortName">
                        </mat-form-field>

                        <a class="col-1" (click)="deleteMeasureUnit(i)" [class.disabled]="!canDeleteMeasureUnit">
                            <mat-icon color="warn">delete</mat-icon>
                            <span class="label--mobile">
                                Удалить строку
                            </span>
                        </a>
                    </div>

                    <div class="row">
                        <tsuz-add-button id="id1706085600" [text]="'Добавить единицу измерения'" (click)="addMeasureUnit()"></tsuz-add-button>
                    </div>

                </ng-container>

            </div>

            <div class="modal-footer">
                <button class="btn btn--active" type="submit" [disabled]="form.invalid">{{isFileUpload ? 'Загрузить' : 'Сохранить'}}</button>
            </div>
        </form>
    </div>
</div>