<div class="list-transactions">
  <div class="upblock">
    <h1 class="h1">Список транзакций</h1>
  </div>
  <div class="upblock legend__wrapper">
    <app-select-licensee
      [(ngModel)]="filter.licenseeId"
      (loaded)="getDocuments()"
      (change)="selectedLicensee = $event; onChangeLicensee()">
    </app-select-licensee>
    <div class="upblock upblock--flex-start select-licensor__block">

      <app-select-box-control
                 [(ngModel)]="filter.type"
                 [items]="transactionTypes"
                 bindValue="id"
                 bindLabel="title"
                 placeholder="Тип зачисления"
                 (change)="getDocuments()"
                 id="id11593526494">
      </app-select-box-control>
    </div>
    <div class="legend__wrapper">

      <app-toggle [(ngModel)]="groupDocumentByDate"
                  title="Группировать по дате"
                  id="id1570098509"></app-toggle>
      <a class="legend__reset-filtres"
         (click)="resetFilters()"
         id="id1538477805">Сбросить фильтры</a>
    </div>
  </div>

  <div class="table" [ngClass]="{'table__group': groupDocumentByDate}">
    <ng-container id="id1538405102">
      <div class="table__header-row">
        <div class="table__header-cell table__header-cell--w80">№"</div>
        <div class="table__header-cell">Номер счета</div>
        <div class="table__header-cell">Название счета</div>
        <div class="table__header-cell filter__column relative">
          <app-string-filter
            [maxLength]="50"
            [(model)]="filter.absTransactionId"
            (apply)="getDocuments()"
            placeholder="Номер платежного документа..."
            [paginationFilter]="filter">
            Номер платежного документа
          </app-string-filter>
        </div>
        <div class="table__header-cell filter__column relative table__header-cell--w80"
             [ngClass]="{'active': hoverSum == true}"
             id="id1538478474">
          <app-number-range-filter
            [(model)]="numberRange"
            (apply)="getDocuments()"
            [paginationFilter]="filter">
            Сумма
          </app-number-range-filter>
        </div>
        <div class="table__header-cell table__header-cell--w130 filter__column relative"
             id="id1538478416">
          <app-date-range-filter
            [(model)]="filter.dateRange"
            (apply)="getDocuments()"
            id="id1538477902"
            [paginationFilter]="filter">
            Дата транзакции
          </app-date-range-filter>
        </div>
      </div>
      <ng-container *ngIf="listDocuments">
        <ng-container *ngIf="!groupDocumentByDate">
          <ng-container *ngFor="let document of listDocuments.items;let i = index"
                        id="id1538405129">
            <div class="table__row"
                 [document]="document"
                 [type]="filter.type"
                 [index]="i+1"
                 [currency]="selectedLicensee.currency"
                 document-item
                 id="id1538405225{{i}}"></div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="groupDocumentByDate">
          <ng-container *ngFor="let group of listDocuments.items | groupBy:'dateTrn';let k = index">
            <span class="date-group-header"
                  colspan="5">{{ group.key | date: "dd-MM-yyyy"}}</span>
            <ng-container *ngFor="let document of group.value;let k = index">
              <div class="table__row"
                   [document]="document"
                   [index]="k+1"
                   [currency]="selectedLicensee.currency"
                   document-item
                   id="id1538405225{{k}}"></div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!--PAGINATION-->
<app-pagination [(model)]="filter"
                (apply)="getDocuments()">
</app-pagination>

