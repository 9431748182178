import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { LicenseeModel, BaseHttpService } from 'tsuz-common';

@Component({
  selector: 'app-select-licensee',
  templateUrl: './select-licensee.component.html',
  styleUrls: ['./select-licensee.component.scss']
})
export class SelectLicenseeComponent implements ControlValueAccessor, OnInit {
  licensees: LicenseeModel[] = [];

  @Input()
  loadOnStart = true;
  @Input()
  initLicensee: string;
  @Output()
  loaded = new EventEmitter<void>();
  @Output()
  change = new EventEmitter<LicenseeModel>();

  constructor(
    @Self() @Optional() private control: NgControl,
    private baseHttpService: BaseHttpService) {
    this.control.valueAccessor = this;
  }

  private _value: string;
  get value(): string {
    return this._value || '';
  }
  set value(val: string) {
    this._value = val;
    this._onChange(val);
  }

  get isNotEmpty(): boolean {
    return this.licensees && this.licensees.length > 0;
  }

  ngOnInit(): void {
    if (this.loadOnStart) {
      this.loadLicensees();
    }
  }

  loadLicensees(): void {
    this.baseHttpService.get('/licensees').subscribe(res => {
      this.licensees = res;

      if (this.isNotEmpty) {
        this.value = this.initLicensee || this.licensees[0].id;
      }

      this.handleLoaded();
    },
    error => {
      console.error(error);
    });
  }

  handleLoaded(): void {
    if (this.loaded.observers.length) {
      this.loaded.emit();
    }
  }

  handleChange(selected: LicenseeModel): void {
    if (this.change.observers.length) {
      this.change.emit(selected);
    }
  }

  writeValue(val: string): void {}

  private _onChange: any = () => {};
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  private _onTouch: any = () => {};
  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
}
