import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService, IPointService, PointFilter } from 'tsuz-common';

@Injectable()
export class PointService implements IPointService {

    constructor(private baseHttpService: BaseHttpService) {
    }

    //Получение всех точек
    getAllPoint(licenseeId: string): Observable<any> { return this.baseHttpService.get('/getallpoints'); }

    //Сброс лимитов
    resetLimits(id: string): Observable<any> { return this.baseHttpService.get('/resetpoint/' + id); }

    //Получение тарифа
    getTariffs(): any {
        this.baseHttpService.get('/tariffs').subscribe(result => {
            return result;
        }, error => console.error(error));
    }

    //Обновление
    update(licenseeId: string, pointId: string, value: any): Observable<any> {
      return this.baseHttpService.patch('/points/' + pointId, value);
    }

    getPage(licenseeId: string, filter: PointFilter) : Observable<any> { 
        return this.baseHttpService.get(`/points`, filter); 
    }
}
