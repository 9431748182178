<app-base-widget
  [header]="getHeader()"
  [icoType]="'holding'"
  [isLoading]="!isLoadingCompleted"
  [hasError]="hasError"
  [loadingError]="error"
  [primaryButtonCaption]="'Структура холдинга'"
  [hidePrimaryButton]="hidePrimaryButton()"
>
  <div class="holding-widget-component widget-content-columns-container">
    <div class="widget-content-column-100">
      <ng-container *ngIf="!turnoverData.disableLicenseeBalance">
        <div class="label-caption widget-content-row-left">
          БАЛАНС СЧЕТА
        </div>
        <div class="label-balance rub-currency widget-content-row-left widget-content-bottom-gap">
          {{turnoverData.balance | money: turnoverData.currency}}
        </div>
      </ng-container>
      <div class="label-caption widget-content-row-left widget-content-bottom-gap">
        обороты
      </div>
      <div class="widget-content-row-left">
        <div class="widget-content-column-50">
          <div class="label-amount rub-currency">
            {{turnoverData.forCurrentDay | money: turnoverData.currency}}
          </div>
          <div class="label-info-small widget-content-row-left widget-content-bottom-gap">
            текущий день
          </div>
        </div>
        <div class="widget-content-column-50">
          <div class="widget-content-row-right">
            <div [ngClass]="{
              'label-increase-turnover': true,
              'label-increase-turnover-green': turnoverData.currentIncrease >= 0,
              'label-increase-turnover-red': turnoverData.currentIncrease < 0
            }"
            >
            {{turnoverData.currentIncrease}}<span>&nbsp;%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="widget-content-row-left">
        <div class="widget-content-column-50">
          <div class="label-amount rub-currency">
            {{turnoverData.forLastDay | money: turnoverData.currency}}
          </div>
          <div class="label-info-small widget-content-row-left widget-content-bottom-gap">
            {{getFormattedLastDayDate()}}
          </div>
        </div>
        <div class="widget-content-column-50">
          <div class="widget-content-row-right">
            <div [ngClass]="{
              'label-increase-turnover': true,
              'label-increase-turnover-green': turnoverData.lastIncrease >= 0,
              'label-increase-turnover-red': turnoverData.lastIncrease < 0
            }"
            >
            {{turnoverData.lastIncrease}}<span>&nbsp;%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="label-amount rub-currency widget-content-row-left">
        {{turnoverData.forCurrentMonth | money: turnoverData.currency}}
      </div>
      <div class="label-info-small widget-content-row-left">
        {{getFormattedCurrentMonthRangeDate()}}
      </div>
    </div>
  </div>
</app-base-widget>