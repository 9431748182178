export class UploadedFile {
  /** 
   * Uploaded file.
  */
  public file: File;
  
  /**
   * ID of the uploaded file.
   * Empty if a file is not yet uploaded to the server
  */
  public id: string;

  /**
   * True if the file was already uploaded to the server
  */
  public get isFileAlreadyUploaded(): boolean {
    return !!this.id;
  };
  
  /** Error list after validation */
  public errors: string[] = null;

  public constructor(file: File) {
    this.file = file;
  }
}
