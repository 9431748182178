<div class="popup__wrapper">

  <div class="popup__header">
    <h4 class="h4">Детали оплаты</h4>
    <a id="id1542712429" class="tap-to-close" (click)="close()"></a>
  </div>

  <ng-container *ngIf="details">
    <h5 class="divider__left line one-line h5">Детали сделки</h5>
    <table class="popup__table-noborder">
      <tbody>
        <tr *ngIf="details.createDate">
          <td class="w-50"> Дата создания (Лицензиат): </td>
          <td class="w-50">{{details.createDate | timeZone : details.licenseeTimeZoneId}} </td>
        </tr>
        <tr *ngIf="details.createDate && details.pointTimeZoneId">
          <td class="w-50"> Дата создания ("{{pointName}}"): </td>
          <td class="w-50">{{details.createDate | timeZone : details.pointTimeZoneId}} </td>
        </tr>
        <tr *ngIf="details.creator.surname">
          <td class="w-50">Оператор: </td>
          <td class="w-50">{{details.creator.surname}} {{details.creator.firstName}} {{details.creator.middleName}}</td>
        </tr>
        <tr *ngIf="details.creator.phone">
          <td class="w-50">Телефон оператора: </td>
          <td class="w-50">{{details.creator.phone}} </td>
        </tr>
        <tr *ngIf="details.dealState">
          <td class="w-50">Состояние сделки: </td>
          <td class="w-50">{{details.dealStateDescription}} </td>
        </tr>
        <tr *ngIf="details.codeFnsForSelfEmployed">
          <td class="w-50">Ответ системы ФНС: </td>
          <td class="w-50">{{details.codeFnsForSelfEmployed | codeFnsForSelfEmployedPipe}} </td>
        </tr>
        <tr *ngIf="details.agreementDate">
          <td class="w-50">Дата ПСА: </td>
          <td class="w-50">{{details.agreementDate}} </td>
        </tr>
        <tr *ngIf="details.agreement">
          <td class="w-50">Номер ПСА: </td>
          <td class="w-50">{{details.agreement}} </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <h5 class="divider__left line one-line h5">Получатель</h5>
  <ng-container *ngIf="details.customer">
    <table class="popup__table-noborder">
        <tr  *ngIf="details.customer.surname">
          <td class="w-50">Получатель: </td>
          <td class="w-50">{{details.customer.surname}} {{details.customer.firstName}} {{details.customer.middleName}}</td>
        </tr>
        <tr  *ngIf="details.customer.passportSeries">
          <td class="w-50">Номер и серия паспорта: </td>
          <td class="w-50">{{details.customer.passportSeries}} </td>
        </tr>
        <tr  *ngIf="details.customer.passportFrom">
          <td class="w-50">Кем выдан паспорт: </td>
          <td class="w-50">{{details.customer.passportFrom}} </td>
        </tr>
        <tr  *ngIf="details.customer.address">
          <td class="w-50">Прописка: </td>
          <td class="w-50">{{details.customer.address}} </td>
        </tr>
        <tr  *ngIf="details.customer.birthday">
          <td class="w-50">Дата рождения: </td>
          <td class="w-50">{{details.customer.birthday}} </td>
        </tr>
        <tr  *ngIf="details.customer.birthPlace">
          <td class="w-50">Место рождения: </td>
          <td class="w-50">{{details.customer.birthPlace}} </td>
        </tr>
    </table>
  </ng-container>

  <ng-container *ngIf="details.products">
    <h5 class="divider__left line one-line h5">Продукты</h5>
    <table class="popup__product-table">
      <tbody class="">
        <ng-container *ngFor="let prod of details.products; let i=index">
          <tr class="popup__product-table-row popup__product-table-header-row">
            <th class="popup__product-table-th" *ngIf="!details.dealSettings.oneProduct"># </th>
            <th class="popup__product-table-th">Товарная категория </th>
            <th class="popup__product-table-th">Сумма: </th>
            <th class="popup__product-table-th" *ngIf="!details.dealSettings.hideWeight">Цена: </th>
            <th class="popup__product-table-th" *ngIf="!details.dealSettings.hideTrashPercent">Засор,&nbsp;%: </th>
            <th class="popup__product-table-th" *ngIf="!details.dealSettings.hideWeight">{{prod.measureUnit.measureName}},&nbsp;{{prod.measureUnit.shortName}}:</th>
            <th class="popup__product-table-th" *ngIf="!prod.hideProviders">Оператор</th>
            <th class="popup__product-table-th" *ngIf="!prod.hidePp">Номер П.П.</th>
          </tr>
          <tr class="popup__product-table-row">
            <th class="popup__product-table-td" *ngIf="!details.dealSettings.oneProduct">{{i+1}}</th>
            <th class="popup__product-table-td">{{prod.title}}</th>
            <th class="popup__product-table-td">{{prod.totalPrice | money: details.currency}} </th>
            <th class="popup__product-table-td" *ngIf="!details.dealSettings.hideWeight">{{prod.measureUnitPrice | money: details.currency}} </th>
            <th class="popup__product-table-td" *ngIf="!details.dealSettings.hideTrashPercent">{{prod.trash | number: '.2-2'}} </th>
            <th class="popup__product-table-td" *ngIf="!details.dealSettings.hideWeight">{{prod.measureUnitValue}} </th>
            <th class="popup__product-table-td" *ngIf="!prod.hideProviders">{{details.provider.title}}</th>
            <th class="popup__product-table-td" *ngIf="!prod.hidePp">{{prod.description}}</th>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="details.payment">
    <h5 class="divider__left line one-line h5">Платежная информация</h5>
    <table class="popup__table-noborder">
      <tbody>
        <tr *ngIf="details.payment.withdrawId">
          <td class="w-50">Идентификатор выплаты: </td>
          <td class="w-50">{{details.payment.withdrawId}} </td>
        </tr>
        <tr *ngIf="details.payment.errorCode">
          <td class="w-50">Код ответа от шлюза: </td>
          <td class="w-50">{{details.payment.errorCode}} </td>
        </tr>
        <tr *ngIf="details.payment.createdAt">
          <td class="w-50">Дата платежа: </td>
          <td class="w-50">{{details.payment.createdAt | timeZone : details.licenseeTimeZoneId }}</td>
        </tr>
        <tr *ngIf="details.payment.paymentStateDescription">
          <td class="w-50">Состояние платежа: </td>
          <td class="w-50">{{details.payment.paymentStateDescription}} </td>
        </tr>
        <tr *ngIf="details.payment.processingName">
          <td class="w-50">Процессинг: </td>
          <td class="w-50">{{details.payment.processingName}} </td>
        </tr>
        <tr *ngIf="details.payment.rrn">
          <td class="w-50">Идентификатор банковской транзакции (RRN): </td>
          <td class="w-50">{{details.payment.rrn}} </td>
        </tr>
        <tr *ngIf="details.payment.reason">
          <td class="w-50">Ответ от процессинга: </td>
          <td class="w-50">{{details.payment.reason}} </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="details.point">
    <h5 class="divider__left line one-line h5">{{pointName}}</h5>
    <table class="popup__table-noborder">
      <tbody>
        <tr *ngIf="details.point.title">
          <td class="w-50">{{pointName}}: </td>
          <td class="w-50">{{details.point.title}} </td>
        </tr>
        <tr *ngIf="details.point.address">
          <td class="w-50">Адрес: </td>
          <td class="w-50">{{details.point.address}} </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="details.confirmation">
    <h5 class="divider__left line one-line h5">Подтверждение оплаты</h5>
    <table class="popup__table-noborder">
      <tbody>
        <tr  *ngIf="details.confirmation.surname">
          <td class="w-50">Пользователь, подтвердивший оплату: </td>
          <td class="w-50">{{details.confirmation.surname}} {{details.confirmation.firstName}} {{details.confirmation.middleName}}</td>
        </tr>
        <tr  *ngIf="details.confirmation.phone">
          <td class="w-50">Телефон: </td>
          <td class="w-50">{{details.confirmation.phone}}</td>
        </tr>
        <tr  *ngIf="details.confirmation.confirmationDate">
          <td class="w-50">Дата и время подтверждения оплаты: </td>
          <td class="w-50">{{details.confirmation.confirmationDate | timeZone : details.licenseeTimeZoneId}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="details.acception">
    <h5 class="divider__left line one-line h5">Согласование лимитов</h5>
    <table class="popup__table-noborder">
      <tbody>
        <tr  *ngIf="details.acception.surname">
          <td class="w-50">Пользователь, согласовавший оплату:</td>
          <td class="w-50">{{details.acception.surname}} {{details.acception.firstName}} {{details.acception.middleName}}</td>
        </tr>
        <tr  *ngIf="details.acception.phone">
          <td class="w-50">Телефон:</td>
          <td class="w-50">{{details.acception.phone}}</td>
        </tr>
        <tr  *ngIf="details.acception.date">
          <td class="w-50">Дата и время согласования оплаты:</td>
          <td class="w-50">{{details.acception.date | timeZone : details.licenseeTimeZoneId}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="details.update">
    <h5 class="divider__left line one-line h5">Обновение оплаты</h5>
    <table class="popup__table-noborder">
      <tbody>
        <tr  *ngIf="details.update.surname">
          <td class="w-50">Пользователь, обновивший оплату:</td>
          <td class="w-50">{{details.update.surname}} {{details.update.firstName}} {{details.update.middleName}}</td>
        </tr>
        <tr  *ngIf="details.update.phone">
          <td class="w-50">Телефон:</td>
          <td class="w-50">{{details.update.phone}}</td>
        </tr>
        <tr  *ngIf="details.update.date">
          <td class="w-50">Дата и время обновления оплаты:</td>
          <td class="w-50">{{details.update.date | timeZone : details.licenseeTimeZoneId}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container *ngIf="details.dealState && details.dealState === 'Rejected' && details.rejection">
    <h5 class="divider__left line one-line h5">Отклонение согласования сделки</h5>
    <table class="popup__table-noborder">
      <tbody>
        <tr  *ngIf="details.rejection.surname">
          <td class="w-50">Пользователь, отклонивший оплату: </td>
          <td class="w-50">{{details.rejection.surname}} {{details.rejection.firstName}} {{details.rejection.middleName}}</td>
        </tr>
        <tr  *ngIf="details.rejection.phone">
          <td class="w-50">Телефон: </td>
          <td class="w-50">{{details.rejection.phone}}</td>
        </tr>
        <tr  *ngIf="details.rejection.rejectionDate">
          <td class="w-50">Дата и Время отклонения оплаты:</td>
          <td class="w-50">{{details.rejection.rejectionDate | timeZone : details.licenseeTimeZoneId}}</td>
        </tr>
        <tr  *ngIf="details.rejection.rejectionReason">
          <td class="w-50">Причина отклонения оплаты: </td>
          <td class="w-50">{{details.rejection.rejectionReason}} </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <h5 class="divider__right line one-line h5">Итого:&nbsp;<strong> {{details.sum | money: details.currency}}</strong></h5>

  <div class="popup__btn-group">
    <div><a id="id1538467748" class="popup3__back" (click)="close()">К списку оплат</a></div>
    <div *hasPermission="[[userRoles.Administrator, userRoles.Manager, userRoles.ManagerPlus, userRoles.Cashier]]">
      <button
        *ngIf="details.dealState === 'Completed'"
        id="id1710335100"
        class="btn btn--active plus float-right"
        (click)="printReady()">
        Распечатать чек
      </button>

      <button 
        id="id1538467771"
        class="btn btn--active plus float-right"
        (click)="copyDeal()">
        Копировать оплату
      </button>
    </div>
  </div>
</div>
