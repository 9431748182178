<div  class="filter__text-align-left"
      (clickOutside)="onCancel()">
  <div class="string-filter__header">
    <ng-content></ng-content>
  </div>

  <app-sorting-header *ngIf="_sort"
                      [(sort)]="_sort"
                      [sortField]="sortField"
                      (sortChange)="onSortClick()">
  </app-sorting-header>

  <div  class="filter__btn string-filter__show-button"
        [ngClass]="{'filter__btn-active': isActive,'filter__margin-left': !_sort}"
        (click)="isShowModal=!isShowModal;">
  </div>

  <div class="filter zindex string-filter__modal" *ngIf="isShowModal">
    <div class="string-filter__control">
      <ng-container *ngIf="textMask; else ngxMaskTemplate" #textMaskTemplate>
        <input [(ngModel)]="model" autoFocus placeholder="{{placeholder}}" type="text" maxlength="{{maxLength}}" 
          class="input" [class.input-clearable]="model" [textMask]="textMask"
          (keyup.enter)="onApply()" (keyup.esc)="onCancel()" />
      </ng-container>
      <ng-template #ngxMaskTemplate>
        <input [(ngModel)]="model" autoFocus placeholder="{{placeholder}}" type="text" maxlength="{{maxLength}}" 
          class="input" [class.input-clearable]="model" [mask]="mask" [patterns]="maskPatterns"
          (keyup.enter)="onApply()" (keyup.esc)="onCancel()" />
      </ng-template>
      <button *ngIf="model" class="string-filter__clear-button" (click)="onClear()"></button>
    </div>

    <div class="filter__footer string-filter-filter__controls">
      <a class="btn2 active float-left string-filter__apply" (click)="onApply()" [class.disabled]="!model">Применить</a>
      <a class="btn2 float-right string-filter__cancel" (click)="onCancel()">Отмена</a>
    </div>
  </div>
</div>
