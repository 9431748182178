
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {HttpErrorService} from './http-error.service';
import {BaseQueryFilter} from '../filters/base-query-filter';
import { AuthService } from './auth.service';

@Injectable()
export class BaseHttpService {
  authToken: any;
  headers = new HttpHeaders();
  apiUrl: string;
  cardUrl: string;

  constructor(
    protected http: HttpClient,
    protected httpErrorService: HttpErrorService,
    @Inject('LOCALSTORAGE') private localStorage: any,
    @Inject('environment') private environment: any,
    public authService: AuthService,
  ) {
    if (this.localStorage != null) {
      this.authToken = this.localStorage.getItem('currentSession');
    }
    //  let headers = new Headers();
    // headers.append('Authorization', `Bearer ${this.authToken}`);

    // this.headers = new RequestOptions({ headers: headers });

    this.apiUrl = environment.apiUrl;
    this.cardUrl = environment.cardUrl;
  }

  getCardInfo(bin: string): Observable<any> {
    const resultUrl = this.cardUrl + bin;
    return this.http.get(resultUrl).pipe(
      catchError(this.handleError));
  }

  get(url: string, data: any = null, headers: HttpHeaders = null, arrayParameterName: string = null ): Observable<any> {
    const resultUrl = this.apiUrl + url;
    if (data) {
      let params = this.getHttpParamFromObject(data, arrayParameterName);
      return this.http.get(`${resultUrl}?${params.toString()}`, {headers: headers}).pipe(catchError(this.handleError.bind(this)));
    }
    return this.http.get(resultUrl,{headers: headers}).pipe(catchError(this.handleError.bind(this)));
  }


  getOfT<T>(url: string): Observable<T> {
    const resultUrl = this.apiUrl + url;
    return this.http.get<T>(resultUrl).pipe(
      catchError(this.handleError));
  }

  getFile(url: string, data: any = null) {
    let resultUrl = this.apiUrl + url;
    if (data) {
      resultUrl = `${resultUrl}?${this.getHttpParamFromObject(data).toString()}`;
    }
    return this.http.get(resultUrl, {observe: 'response', responseType: 'blob'})
      .pipe(catchError(this.parseErrorBlob));

  }

  post(url: string, model: any, options: any = null): Observable<any> {
    const resultUrl = this.apiUrl + url;
    if (options != null) {
      return this.http.post(resultUrl, model, options).pipe(
        catchError(this.handleError.bind(this)));
    }
    return this.http.post(resultUrl, model).pipe(
      catchError(this.handleError.bind(this)));
  }

  postWithBlobResponse(url: string, model: any, options: any = null): Observable<any> {
    const resultUrl = this.apiUrl + url;
    if (options != null) {
      return this.http.post(resultUrl, model, options)
        .pipe(catchError(this.parseErrorBlob));
    }
    return this.http.post(resultUrl, model)
      .pipe(catchError(this.parseErrorBlob));
  }

  parseErrorBlob(error: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      };
    });
    reader.readAsText(error.error);
    return obs;
  }

  patch(url: string, model: any, options: any = null): Observable<any> {
    const resultUrl = this.apiUrl + url;
    if (options != null) {
      return this.http.patch(resultUrl, model, options).pipe(
        catchError(this.handleError.bind(this)));
    }
    return this.http.patch(resultUrl, model).pipe(
      catchError(this.handleError.bind(this)));
  }

  postFile(url: string, model: any): Observable<any> {
    const resultUrl = this.apiUrl + url;
    // headers.append('enctype', 'application/x-www-form-urlencoded');
    // headers.append('Accept', 'application/x-www-form-urlencoded');
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // let body = JSON.stringify(model);
    return this.http.post(resultUrl, model).pipe(
      catchError(this.handleError));
  }

  put(url: string, model: any, options: any = null): Observable<any> {
    const resultUrl = this.apiUrl + url;
    if (options != null) {
      return this.http.put(resultUrl, model, options).pipe(
        catchError(this.handleError.bind(this)));
    }
    return this.http.put(resultUrl, model).pipe(
      catchError(this.handleError.bind(this)));
  }

  delete(url: string): Observable<any> {
    const resultUrl = this.apiUrl + url;
    return this.http.delete(resultUrl).pipe(
      catchError(this.handleError));
  }

  handleError(response: HttpErrorResponse) {
    if (this.httpErrorService && response.error) {
      this.httpErrorService.setError(response.error);
    }

    if (response.error?.errors) {
      response.error.fields = response.error.errors; // FluentValidation ошибки находятся в поле errors
    }

    if (response.status === 404) {
      return observableThrowError('Сервер не может найти запрошенный адрес');
    }

    const parsedError = Object.assign({}, response, { error: JSON.stringify(response.error) });

    if (!parsedError) {
      return observableThrowError('Ошибка обработки ответа от сервера');
    }

    let formattedBody = JSON.parse(parsedError.error);
    if (!formattedBody) {
      return observableThrowError('Ошибка обработки ответа от сервера');
    }

    if (formattedBody.message) {
      return observableThrowError(formattedBody.message);
    }

    if (formattedBody.fields) {
      const values = Object.keys(formattedBody.fields).map(key => formattedBody.fields[key]);
      if (values) {
        const joinedErrors = values.join(';');
        return observableThrowError(joinedErrors);
      }
    }

    if (response.status === 400) {
      const errors = [];
      for (const fieldName in formattedBody) {
        if (formattedBody.hasOwnProperty(fieldName)) {
          errors.push(formattedBody[fieldName]);
        }
      }

      return observableThrowError(errors.join());
    }
    //сюда попадут необработанные ошибки апи и ошибки nginx
    if (response.status >= 500) {
      return observableThrowError('Ошибка сервера. Попробуйте позже или обратитесь в службу поддержки.');
    }
    return observableThrowError(formattedBody);
  }

  getWithoutCache(url: string): Observable<any> {
    const resultUrl = this.apiUrl + url;
    return this.http.get(resultUrl, {headers: this.headers}).pipe(
      catchError(this.handleError));
  }


  private getHttpParamFromObject(data: any, arrayParameterName: string = null) : HttpParams {
    let params = new HttpParams();
    if (data instanceof BaseQueryFilter) {
      return data.toHttpParams();
    }
    if (this.isArrayOfString(data)) {
      params = this.appendArrayOfStringsToParams(params, arrayParameterName, data);
      return params;
    }
    Object.keys(data).forEach(key => {
      let value = data[key];
      if (value != 'undefined' && value != null) {
        if (this.isArrayOfObjects(value)) {
          key = key =='_page' ? 'page' : key;
          params = this.appendArrayOfObjectsToParams(params, key, value);
        }
        else if (Array.isArray(value) && value.length) {
          value.forEach(arrItemValue => params = params.append(key, arrItemValue));
        }
        else {
          params = params.append(key, value);
        }
      }
    });

    return params;
  }

  private isArrayOfObjects(data: any) {
    return Array.isArray(data) && data.length && typeof (data[0]) === 'object';
  }

  private isArrayOfString(data: any) {
    return Array.isArray(data) && data.length && typeof (data[0]) === 'string';
  }

  private appendArrayOfObjectsToParams(params: HttpParams, arrayProperty: string, array: object[]) {
    let index = 0;
    array.forEach(arrayItem => {
      Object.keys(arrayItem).forEach(key => params = params.append(`${arrayProperty}[${index}].${key}`, arrayItem[key]));
      index++;
    });

    return params;
  }

  private appendArrayOfStringsToParams(params: HttpParams, arrayParameterName: string, array: string[]) {
    array.forEach(arrayItem => {
      params = params.append(`${arrayParameterName}`, arrayItem);
    });

    return params;
  }
}
