<h2 mat-dialog-title>Заявка создана</h2>

<mat-dialog-content>
  <p>
    Статус заявки вы можете проверить выбрав &laquo;Проверить статус&raquo; в списке заявок
  </p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button
          color="primary"
          (click)="onClose()"
          cdkFocusInitial>
    Список заявок
  </button>
</mat-dialog-actions>