import { Component,  OnInit, Input } from '@angular/core';
import { IUser } from '../../../models/IUser';
import { AuthGuard } from '../../../guards/auth.guard';

@Component({
  selector: '[tsuz-user-item]',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})

export class UserItemComponent implements OnInit {

  @Input() user: IUser;

  constructor(public readonly authGuard: AuthGuard) {
  }

  //Инициализация для меню Сотрудника
  ngOnInit() {
    this.user.isShowDropDown = false;
    this.user.isShowBlock = false;
    this.user.isShowPoint = false;
    this.user.isShowReset = false;
    this.user.isShowRole = false;
    this.user.isShowSet = false;
  }
}


