<div class="popup3__wrapper" *ngIf="deal">
  <ng-container *ngIf="cssClassNum == 0 || cssClassNum == 1 || cssClassNum == 2">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Оплата</h4>
        <a id="id1538469447" class="tap-to-close" (click)="returnToDeals()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking " [ngClass]="{'process': cssClassNum == 0 || !cssClassNum,'rejected': cssClassNum == 1,'successfully': cssClassNum == 2}">
        {{respMessage}}
      </div>
      <ng-container *ngIf="!hideButtons">
        <a id="id1538469492"
        class="popup3__back"
        [ngClass]="{'float-left': isPsOK == true}"
        (click)="returnToDeals()" >
        К списку оплат</a>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="smsCssClassNum == 0 || smsCssClassNum == 1">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">СМС подтверждение</h4>
        <a id="id1538469521" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <div>Подтверждающее СМС будет отправлено</div>
        <div>на номер {{operPhone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking " [ngClass]="{'process': smsCssClassNum == 0 || !smsCssClassNum,'rejected': smsCssClassNum == 1}">
        {{smsRespMessage}}
      </div>
      <ng-container *ngIf="smsCssClassNum == 1">
        <a id="id1538469540" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="smsCssClassNum == 2">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">СМС подтверждение</h4>
        <a id="id1538469562" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <div>Подтверждающее СМС будет отправлено</div>
        <div>на номер {{operPhone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <form [formGroup]="codeForm" (ngSubmit)="onSubmit()">
        <div class="popup3__sms-input-wrapper">
            <label class="label">СМС-КОД</label>
            <input id="id1538470063" autofocus mask="000000" formControlName="Code" type="text" class="input popup3__sms-input" [ngClass]="{'input--nonvalidate': invalid == true,'input--validate': invalid == false}"
              placeholder="******" (input)="validCode()">

            <div *ngIf="timerStopped" class="d-block">
              <a *ngIf="count > 0" id="id1538469581" class="sms-code-anchor" (click)="resendCode()">
              Отправить СМС еще раз
              </a>
              <div class="popup3__countdown-label"> <span>{{this.smsCount}}</span></div>
            </div>
            <div *ngIf="!timerStopped" class="d-block"><span>Отправить повторно через {{secondTxt}} сек.</span></div>
        </div>
        <div class="popup3__btn-wrapper">
            <button type="submit" class="btn" [ngClass]="{'btn--pay-active': !invalid}" [disabled]="invalid">Оплатить</button>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-container *ngIf="smsAcceptCssClassNumber == 0 || smsAcceptCssClassNumber == 1 || smsAcceptCssClassNumber == 2">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Проверка оплаты на лимиты</h4>
        <a id="id1538469606" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <div>Подтверждающее СМС будет отправлено</div>
        <div>на номер {{operPhone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking " [ngClass]="{'process': smsAcceptCssClassNumber == 0 || !smsAcceptCssClassNumber,'rejected': smsAcceptCssClassNumber == 1,'successfully': smsAcceptCssClassNumber == 2}">
        <div innerHTML="{{smsCount}}">
        </div>
      </div>
      <ng-container *ngIf="smsCountBool">
        <div class="popup3__btn-flex-container">
          <a id="id1538469629" class="popup3__back" [ngClass]="{'float-left': smsAcceptCssClassNumber == 2}" (click)="returnToDeals()">К списку оплат</a>
          <a id="id1538469653" class="popup3__next" (click)="acceptingSms()" *ngIf="smsAcceptCssClassNumber == 2">Подтвердить платеж</a>
        </div>
      </ng-container>
      <ng-container *ngIf="acceptRejected">
        <div class="popup3__btn-flex-container">
          <a id="id1538469671" class="popup3__back d-block" (click)="closeModal()" >К списку оплат</a>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="smsWaitCssClassNumber == 0 || smsWaitCssClassNumber == 1 || smsWaitCssClassNumber == 2">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Проверка оплаты на лимиты</h4>
        <a id="id1538469695" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <div>Подтверждающее СМС будет отправлено</div>
        <div>на номер {{operPhone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking " [ngClass]="{'process': smsWaitCssClassNumber == 0 || !smsWaitCssClassNumber,'rejected': smsWaitCssClassNumber == 1,'successfully': smsWaitCssClassNumber == 2}">
        {{waitStr}}
      </div>
      <ng-container *ngIf="smsWaitCssClassNumber">
        <a id="id1538469720" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="smsApproveCssClassNumber == 0 || smsApproveCssClassNumber == 1 || smsApproveCssClassNumber == 2">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Отправка на согласование</h4>
        <a id="id1538469742" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <div>Подтверждающее СМС будет отправлено</div>
        <div>на номер {{operPhone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking " [ngClass]="{'process': smsApproveCssClassNumber == 0 || !smsApproveCssClassNumber,'rejected': smsApproveCssClassNumber == 1,'successfully': smsApproveCssClassNumber == 2}">
        <div innerHTML="{{approveInfo}}">
        </div>
      </div>
      <ng-container *ngIf="smsApproveCssClassNumber == 1 || smsApproveCssClassNumber == 2">
        <a id="id1538469760" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="smsApprovalCssClassNumber == 0 || smsApprovalCssClassNumber == 1 || smsApprovalCssClassNumber == 2">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Согласование оплаты</h4>
        <a id="id1538469781" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <ng-container *ngIf="!showApprove">
          <div>Подтверждающее СМС будет отправлено</div>
          <div>на номер {{operPhone}}</div>
        </ng-container>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking " [ngClass]="{'process': smsApprovalCssClassNumber == 0 || !smsApprovalCssClassNumber,'rejected': smsApprovalCssClassNumber == 1,'successfully': smsApprovalCssClassNumber == 2}">
        <div innerHTML="{{approvalInfo}}">
        </div>
      </div>
      <ng-container *ngIf="showAdmin || showAccountant">
        <div class="popup3__btn-flex-container">
          <a id="id1538469830" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
          <a id="id1538469814" class="popup3__next" (click)="accept(deal.id)">Одобрить</a>
        </div>
        <div class="popup3__btn-flex-container mt-25">
          <a id="id1538469842" *ngIf="showAdmin" class="popup3__btn" (click)="sendToEdit()">Редактировать</a>
          <a id="id1538469860" class="popup3__btn" (click)="reject(deal.id)">Отказать</a>
        </div>
      </ng-container>
      <ng-container *ngIf="showUser">
        <div class="popup3__btn-flex-container">
          <a id="id1538469890" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
          <a id="id1538469876" class="popup3__btn" (click)="sendToAprove()">Согласовать</a>
        </div>
        <div class="popup3__btn-flex-container mt-25">
          <a id="id1538469912" class="popup3__btn" (click)="sendToEdit()">Редактировать</a>
        </div>
      </ng-container>
      <ng-container *ngIf="showLimError">
        <div class="popup3__btn-flex-container">
          <a id="id1538469928" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
          <a id="id1538469943" class="popup3__btn" (click)="sendToEdit()">Редактировать</a>
        </div>
      </ng-container>
      <ng-container *ngIf="showApprove">
        <a id="id1538469966" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="smsCheckClassNum == 0 || smsCheckClassNum == 1">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Проверка кода</h4>
        <a id="id1538469989" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <div>Подтверждающее СМС будет отправлено</div>
        <div>на номер {{operPhone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking " [ngClass]="{'process': smsCheckClassNum == 0 || !smsCheckClassNum,'rejected': smsCheckClassNum == 1}">
        {{smsCheckRespMessage}}
      </div>
      <ng-container *ngIf="smsCheckClassNum == 1">
        <div class="popup3__btn-flex-container">
          <a id="id1538470013" class="popup3__back" (click)="returnToDeals()" *ngIf="hideRepeatCodeInputButton">К списку оплат</a>
          <a id="id1538470013" class="popup3__back" (click)="showCodeInputScreen()" *ngIf="!hideRepeatCodeInputButton">Ввести код еще раз</a>
          <a id="id1538470025" class="popup3__btn" (click)="againCode()" *ngIf="!hideSmsButton">Отправить код повторно</a>
        </div>
      </ng-container>
    </div>
  </ng-container>

<ng-container *ngIf="isPaymentError">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Ошибка</h4>
        <a id="id1538469989" class="tap-to-close" (click)="closeModal()" ></a>
      </div>
      <div class="popup3__pay-info">
        <div>№ {{deal.dealNumber}} от {{deal.createDate | timeZone : deal.licenseeTimeZoneId}}</div>
        <div *ngIf="isCardPay">на карту {{deal.cardNumber}}</div>
        <div *ngIf="isMobilePay">на номер {{deal.phone}}</div>
        <div *ngIf="operPhone">Подтверждающее СМС будет отправлено</div>
        <div *ngIf="operPhone">на номер {{operPhone}}</div>
      </div>
      <div class="popup3__amount">
        {{deal.sum | money: deal.currency}}
      </div>
      <div class="popup3__checking rejected" >
        {{errorInfo}}
      </div>
        <div class="popup3__btn-flex-container">
          <a id="id1538470013" class="popup3__back" (click)="returnToDeals()">К списку оплат</a>
        </div>
    </div>
  </ng-container>
</div>
