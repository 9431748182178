<div class="popup__wrapper">
  <div class="popup__header">
    <h4 class="h4">Детали платежа</h4>
    <a id="id1542712501" class="tap-to-close" (click)="close()"></a>
  </div>
  <div class="popup__main">
      <ng-container *ngIf="supportPayInfo.licensee">
      <table class="popup__table">
        <tbody>
          <tr class="staff-associate-row">
            <td>Лицензиат: </td>
            <td>{{supportPayInfo.licensee.title}} </td>
          </tr>
          <tr class="staff-associate-row">
            <td>ИНН: </td>
            <td>{{supportPayInfo.licensee.inn}} </td>
          </tr>
          <tr class="staff-associate-row">
            <td>Телефон лицензиата: </td>
            <td>{{supportPayInfo.licensee.phone}} </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

      <table class="popup__table">
        <tbody>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.created">
            <td>Отправлено в шлюз, дата и время: </td>
            <td>{{supportPayInfo.created}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.dealId">
            <td>Идентификатор оплаты: </td>
            <td>{{supportPayInfo.dealId}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.dealNumber">
            <td>Номер оплаты: </td>
            <td>{{supportPayInfo.dealNumber}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.operFio">
            <td>Оператор создавший оплату: </td>
            <td>{{supportPayInfo.operFio}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.operPhone">
            <td>Телефон оператора подтвердившего оплату: </td>
            <td>{{supportPayInfo.operPhone}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.smsOperFio">
            <td>Оператор подтвердивший оплату: </td>
            <td>{{supportPayInfo.smsOperFio}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.smsOperPhone">
            <td>Телефон оператора создавшего оплату: </td>
            <td>{{supportPayInfo.smsOperPhone}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.providerId">
            <td>Идентификатор провайдера: </td>
            <td>{{supportPayInfo.providerId}} </td>
          </tr>
          <tr class="staff-associate-row" *ngIf="supportPayInfo.providerTitle">
            <td>Наименование провайдера: </td>
            <td>{{supportPayInfo.providerTitle}} </td>
          </tr>
          <tr class="staff-associate-row">
            <td>Номер RRN: </td>
            <td>
            <ng-container *ngIf="supportPayInfo.rrn; else nullRRN">
            {{supportPayInfo.rrn}}
            </ng-container>
            <ng-template #nullRRN>
            Номер RRN не указан в настройках банка экваера
            </ng-template>
          </td>
          </tr>
        </tbody>
      </table>

    <table class="popup__table">
      <tbody>
        <tr class="staff-associate-row">
          <td>Итого:</td>
          <td><strong>{{supportPayInfo.sum | money: supportPayInfo.currency}}</strong></td>
        </tr>
      </tbody>
    </table>

    <div class="popup__btn-group">
      <div><a id="id1542712501" class="popup3__back" (click)="close()">К списку оплат</a></div>
    </div>
  </div>
</div>
