import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'tsuz-common';

@Injectable()
export class UserService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  sendOtpForPasswordChange(): Observable<any> {
    return this.baseHttpService.post('/users/change-password/otp', null);
  }

  changePassword(confirmCode: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.baseHttpService.post('/users/change-password/', { confirmCode, oldPassword, newPassword});
  }
}
