
import {switchMap} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpTransportType, HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { LicenseeService } from './licensee.service';
import { AuthService, IHomeBalance } from 'tsuz-common';

@Injectable()
export class RealtimeNotificationService {

  private changeBalance$ = new Subject();
  private writeOffLicenseeBalance$ = new Subject<string>();
  private topUpLicenseeBalance$ = new Subject<string>();
  private sendToApprovalDeal$ = new Subject<string>();
  private dealApproved$ = new Subject<string>();
  private dealStateChanged$ = new Subject<string>();

  private connectionOptions: IHttpConnectionOptions = {
    accessTokenFactory: () => {
      return this.getAuthToken();
    },
    transport: HttpTransportType.WebSockets,
    skipNegotiation: true
  };

  private connection: HubConnection = new HubConnectionBuilder()
    .withUrl(environment.signalrUrl, this.connectionOptions)
    .withAutomaticReconnect()
    .build();

  private getAuthToken(): string {
    return this.localStorage.getItem('currentSession');
  }

  private connect() {
    if (this.connection.connectionId === null) {
      this.connection
        .start()
        .then(() => console.log('Connected'))
        .catch(err => console.warn(`Error while establishing connection: ${err}`));
    }
  }

  constructor(private licenseeService: LicenseeService,
              private authenticationService: AuthService,
              @Inject('LOCALSTORAGE') private localStorage: any) {

    this.authenticationService.authNavStatus$.subscribe(logged => {
      if (logged && environment.signalrEnabled) {
        console.log("SignalR connection has started");
        this.connect();
      }
      else {
        console.log(`SignalR connection hasn't started. Logged is ${logged}, SignalR Enabled is ${environment.signalrEnabled}`);
      }
    });

    this.connection.on('OnChangeBalance', () => this.changeBalance$.next());
    this.connection.on('OnWriteOffLicenseeBalance', (message) => this.writeOffLicenseeBalance$.next(message));
    this.connection.on('OnTopUpLicenseeBalance', (message) => this.topUpLicenseeBalance$.next(message));
    this.connection.on('OnSendToApprovalDeal', (message) => this.sendToApprovalDeal$.next(message));
    this.connection.on('DealApproved', (message) => this.dealApproved$.next(message));
    this.connection.on('OnDealStateChanged', (message) => this.dealStateChanged$.next(message));
  }

  onChangeBalance(): Observable<IHomeBalance> {
    return this.changeBalance$.pipe(switchMap( () => this.licenseeService.getBalance()));
  }

  onWriteOffLicenseeBalance(): Observable<string> {
    return this.writeOffLicenseeBalance$;
  }

  onTopUpBalanceNotification(): Observable<string> {
    return this.topUpLicenseeBalance$;
  }

  onSendToApprovalDeal(): Observable<string> {
    return this.sendToApprovalDeal$;
  }

  onDealApproved(): Observable<string> {
    return this.dealApproved$;
  }

  onDealStateChanged(): Observable<string> {
    return this.dealStateChanged$;
  }
}
