import { Component, OnInit, Input } from '@angular/core';
import { SimpleModalService } from "ngx-simple-modal";
import { ResetPostComponent } from './reset-post/reset-post.component';
import { IUser } from '../../../models/IUser';

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
}

@Component({
  selector: '[reset-pass]',
  templateUrl: './reset-pass.component.html'
})
export class ResetPassComponent implements ConfirmModel, OnInit {
  title: string;
  message: string;
  @Input() user: IUser;
  @Input() licenseeId: string;
  @Input() readonly: boolean = false;

  constructor(
    private simpleModalService: SimpleModalService
  ) {
  }

  ngOnInit(): void {
  }

  // Модальное окно для сброса пароля
  resetPass() {
    const titleModal = 'Сброс пароля';
    const disposable = this.simpleModalService.addModal(ResetPostComponent, {
      title: titleModal,
      message: 'Отправить новый пароль на номер',
      user: this.user,
      licenseeId: this.licenseeId
    }, { closeOnClickOutside: true })
      .subscribe((resultActive) => {

      });
  }
}
