import {tap} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'tsuz-common';
import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  authToken: any;
  constructor(
    @Inject('LOCALSTORAGE') private localStorage: any,
    public authService: AuthService,
    private router: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.localStorage != null) {
      this.authToken = this.localStorage.getItem('currentSession');
    }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authToken}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      });
    return next.handle(request).pipe(tap(event => {
    }, (error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.authService.logout();
        this.router.navigate(["/login"]);
    }
    }));
  }
}
