<div class="upblock">
    <h1 class="h1">Балансы лицензиатов</h1>
</div>
<div class="list__wrapper" *ngIf="balance">
  <div class="list__item">
    <span class="list__title">Название Организации</span>
    <span class="list__title">Кешпулинг</span>
    <span class="list__credit">Суточный расход</span>
    <span class="list__balance">Баланс</span>
  </div>

  <div class="list__item" *ngFor="let organization of balance">
    <span class="list__title">{{organization.title}}: </span>
    <span class="list__title"></span>
    <span class="list__credit">{{organization.credit | money: organization.currency}}</span>
    <span class="list__balance">
      <app-balance-view [balance]="organization.balance" [currency]="organization.currency"></app-balance-view>
    </span>
  </div>

  <div class="list__item lic-balance__item_child" *ngFor="let child of balance[0].childrenOrganization">
    <span class="list__title-child">{{child.title}}: </span>
    <span class="list__title-child">
      <input id="{{child.id}}" type="checkbox" class="checkbox-big" [checked]="child.hasTransitAccount" disabled><label for="{{child.id}}"></label>
    </span>
    <span class="list__credit">{{child.credit | money: child.currency}}</span>
    <span class="list__balance">
      <app-balance-view [balance]="child.balance" [currency]="child.currency"></app-balance-view>
    </span>
  </div>

</div>
