import { Component, OnInit, Input, ViewChild, ViewContainerRef, AfterViewInit, ElementRef } from '@angular/core';
import { element } from 'protractor';
import { WidgetTypeFactory } from '../widget-type-factory';

@Component({
  selector: 'app-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss']
})
export class WidgetContainerComponent implements OnInit, AfterViewInit {

  @Input()
  public widthKey: string;
 
  @Input()
  public widgetTypeKey: string;
  
  @ViewChild('dynamicInsert', { read: ViewContainerRef }) dynamicInsert: ViewContainerRef;
  constructor(
    private widgetFactory: WidgetTypeFactory,
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.elementRef.nativeElement.classList.add(`widget-width-${this.widthKey}`);
  }

  ngAfterViewInit() {
    const componentFactory = this.widgetFactory.getWidget(this.widgetTypeKey);
    this.dynamicInsert.clear();
    this.dynamicInsert.createComponent(componentFactory);
  }

}
