<div #toggleElement (click)="onInsideClick()">
    <ng-container *ngIf="!readonly && isEditing; else showLabel">

        <mat-form-field>
            <mat-label>{{label}}</mat-label>
            <input matInput #inputElement [(ngModel)]="value" (keyup.enter)="finishEditing()" 
                (focusout)="finishEditing()"/>
        </mat-form-field>

    </ng-container>
    <ng-template #showLabel>
        <p title="{{label}}">{{value}}</p>
    </ng-template>
</div>