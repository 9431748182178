<app-base-widget
[header]="'Сделки'"
[icoType]="'card'"
[isLoading]="!isLoadingCompleted"
[hasError]="hasError"
[loadingError]="error"
[primaryButtonCaption]="'Все сделки'"
[primaryButtonRoute]="'/deals'"
>
  <div class="deals-widget-component widget-content-columns-container">
    <div class="widget-content-column-100">
      <div class="widget-content-row-left deals-widget-row widget-content-bottom-gap" *ngFor="let deal of deals">
        <div class="widget-content-column-50">
          <div class="label-amount rub-currency">
            {{deal.amount | money: deal.currency}}
          </div>
          <div class="label-info-small widget-content-row-left deals-widget-date-container">
            {{deal.date | date: dateTimeFormat}}
          </div>
        </div>
        <div class="widget-content-column-50 deals-widget-point-info-container">
          <div class="label-info-small widget-content-row-left">
            {{deal.licensee}}
          </div>
          <div class="label-info-small widget-content-row-left">
            {{deal.point}}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-base-widget>