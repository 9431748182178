import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import {throwError as observableThrowError, Observable, BehaviorSubject, ObservableInput} from 'rxjs';
import {catchError,  finalize } from 'rxjs/operators';
import { UserRoles } from '../models/auth/user-roles';
import {LicenseeType} from "../models/auth/licensee-type";
import {Profile} from "../models/profile";

@Injectable()
export class AuthService {
  authToken: string;
  headers = new HttpHeaders();
  apiUrl: string;
  // Observable navItem source
  public authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this.authNavStatusSource.asObservable();

  // Observable navItem source
  public infoStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  infoStatus$ = this.infoStatusSource.asObservable();

  constructor(private http: HttpClient,
    @Inject('environment') private environment: any,
    @Inject('LOCALSTORAGE') private localStorage: any) {

    var isLoggedIn = false;
    this.apiUrl = this.environment.apiUrl;
    if (this.localStorage != null) {
      isLoggedIn = this.localStorage.getItem('isAuth');
    }
    this.authNavStatusSource.next(isLoggedIn);
  }

  // Сюда добавляем роли, чтобы можно было отсечь те, которые нельзя менять
  private static getRoleIndex(role: string): number {
    switch (role) {
      case UserRoles.ExecutiveOfficer:
        return 6;
      case UserRoles.Administrator:
        return 5;
      case UserRoles.ManagerPlus:
        return 4;
      case UserRoles.Accountant:
        return 3;
      case UserRoles.Manager:
        return 2;
      case UserRoles.Cashier:
        return 1;
      default:
        return 0;
    }
  }

  setInfoStatus(newStatus: boolean = true) {
    this.infoStatusSource.next(newStatus);
  }

  getUserRoles() {
    const userRoles = this.localStorage.getItem('currentRoles');
    const res = userRoles.split(',');
    return res;
  }

  setSessionId(sessionId: string) {
    this.localStorage.setItem('currentSession', sessionId);
    this.authToken = this.localStorage.getItem('currentSession');
  }

  setLicenseeType(licenseeType: string){
    this.localStorage.setItem('licenseeType', licenseeType);
  }

  createSession(phoneOrLoginModel: string, password: string, handleError: (err: any, caught: Observable<Object>) => ObservableInput<any> = null): Observable<any> {
    const localHandleError = handleError || this.handleError;
    return this.http.post(this.apiUrl + '/session', { phoneOrLogin: phoneOrLoginModel, password: password }).pipe(
      catchError(localHandleError));
  }

  sendOtp(): Observable<any> {
    return this.http.post(this.apiUrl + '/session/otp', null).pipe(catchError(this.handleError));
  }

  changePassword(password: any){
    return this.http.post(this.apiUrl + '/profile/password', { Pass: password }).pipe(catchError(this.handleError));
  }

  verifySessionThroughOtp(code: any){
    return this.http.put(this.apiUrl + '/session', { code: code }).pipe(
      catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    const parsedError = Object.assign({}, error, { error: JSON.stringify(error.error) });
    const message = JSON.parse(parsedError.error).message || 'Ошибка сервера';
    const code = error.status;
    const errorBody = {
      message,
      code,
      errors: error.error.errors
    };
    return observableThrowError(errorBody);
  }

  logout() {
    if(this.isLoggedIn()){
      // remove user from local storage to log user out

      this.localStorage.removeItem('currentRoles');
      this.localStorage.removeItem('isAuth');
      this.localStorage.removeItem('licenseeType');
      this.authToken = null;

      const authHeaderContent = `Bearer ${this.localStorage.getItem('currentSession')}`;
      const headers = { headers: new HttpHeaders({ 'Authorization':  authHeaderContent })};

      const request = this.http.delete(this.apiUrl + '/session', headers)
      .pipe(
        catchError(this.handleError),
        finalize(() => {
          this.localStorage.clear();
        }),);

      request.subscribe();

      this.authNavStatusSource.next(false);
    }
  }

  isLoggedIn() {
    return this.localStorage.getItem("isAuth");
  }

  getProfile(): Observable<Profile> {
    return this.http.get<Profile>(this.apiUrl + '/profile').pipe(catchError(this.handleError));
  }

  getLicenseeId(): string {
    return this.localStorage.getItem('licenseeId');
  }

  getUserId(): string {
    return this.localStorage.getItem('userId');
  }

  inRole(...listRoles: string[]): boolean {
    const userRoles = this.localStorage.getItem('currentRoles');
    if (!userRoles) {
      return false;
    }
    if (!listRoles) {
      return false;
    }
    for (const roleName of listRoles) {
      if (userRoles.indexOf(roleName) > -1) {
        return true;
      }
    }
    return false;
  }

  getLicenseeType(): string {
    if (this.localStorage) {
      return this.localStorage.getItem('licenseeType');
    }
    return this.environment.licenseeDefault;
  }

  isCarPrice(): boolean {
    const licenseeType = this.getLicenseeType();

    return licenseeType === LicenseeType.CarPrice;
  }

  getAllRoles(): UserRoles[] {
    const availableRoles = (({ ExecutiveOfficer: ExecutiveOfficer, ClientManager: ClientManager, ...roles }) => roles)(UserRoles); // все роли кроме ExecutiveOfficer и ClientManager
    return Object.values(availableRoles);
  }

  getMainRole(empRoles?: string[]): UserRoles {
    if (!empRoles) {
      empRoles = this.localStorage.getItem('currentRoles');
    }
   
    if (empRoles.includes(UserRoles.ExecutiveOfficer)) {
      return UserRoles.ExecutiveOfficer;
    }
    if (empRoles.includes(UserRoles.ClientManager)) {
      return UserRoles.ClientManager;
    }

    if (empRoles.includes(UserRoles.Administrator)) {
      return UserRoles.Administrator;
    }
    if (empRoles.includes(UserRoles.ManagerPlus)) {
      return UserRoles.ManagerPlus;
    }
    if (empRoles.includes(UserRoles.Manager)) {
      return UserRoles.Manager;
    }
    if (empRoles.includes(UserRoles.Cashier)) {
      return UserRoles.Cashier;
    }
    if (empRoles.includes(UserRoles.Accountant)) {
      return UserRoles.Accountant;
    }
    if (empRoles.includes(UserRoles.FinancialDirector)) {
      return UserRoles.FinancialDirector;
    }
    if (empRoles.includes(UserRoles.Support)) {
      return UserRoles.Support;
    }
  }

  showEditRole(userMainRole: string, targetEmpMainRole: string): boolean {
    const userRoleIndex = AuthService.getRoleIndex(userMainRole);
    const empRoleIndex = AuthService.getRoleIndex(targetEmpMainRole);
    return userRoleIndex > empRoleIndex;
  }

  getHomePage(): string {
    if (this.inRole(UserRoles.FinancialDirector)) {
      return '/widgets';
    }
    return this.inRole(UserRoles.Support) ? '/support/deal' : '/people-deal';
  }

}
