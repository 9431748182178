import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { CacheEntry, MAX_CACHE_AGE } from './cache-entry';
import {Observable} from 'rxjs';

@Injectable()
export class CacheService {
  cacheMap = new Map<string, CacheEntry>();

  get(key: string): HttpResponse<any> | null {
    const entry = this.cacheMap.get(key);
    if (!entry) {
      return null;
    }
    return this.entryIsExpired(entry) ? null : entry.response;
  }

  put(key: string, res: HttpResponse<any> , expireTime: number = 0): void {
    const entry: CacheEntry = {key: key, response: res, entryTime: Date.now(), expireTime: expireTime};
    this.cacheMap.set(key, entry);
    this.deleteExpiredCache();
  }

  private deleteExpiredCache() {
    this.cacheMap.forEach(entry => {
      if (this.entryIsExpired(entry)) {
        this.cacheMap.delete(entry.key);
      }
    });
  }

  private entryIsExpired(entry: CacheEntry) {
    let expired = false;
    if (entry.expireTime) {
      expired = (Date.now() - entry.entryTime) > entry.expireTime;
    }
    return expired || (Date.now() - entry.entryTime) > MAX_CACHE_AGE;
  }
}
