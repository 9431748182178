import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl} from '@angular/forms';
import {PhoneValidator, AbstractTextInput, getPhoneMask} from 'tsuz-common';

@Component({
  selector: 'app-phone-control',
  templateUrl: './phone-control.component.html',
  styleUrls: ['./phone-control.component.scss'],
})
export class PhoneControlComponent extends AbstractTextInput implements OnInit {

  @Input()
  hideValidationError = false;

  @Input()
  overrideCssClass = 'input flex-shrink-max';

  @Input()
  disabled: Boolean = false;

  ngControl: NgControl;
  getPhoneMask = getPhoneMask;

  constructor(@Optional() @Self() public ctrl: NgControl) {
    super();
    this.ngControl = ctrl;
    this.ngControl.valueAccessor = this;
  }

  setValidators(control: AbstractControl) {
    const validators = control.validator ? [control.validator, PhoneValidator()] : PhoneValidator();
    control.setValidators(validators);

    control.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.setValidators(this.ngControl.control);
  }
  
}
