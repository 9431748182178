<mat-form-field class="search-select">
  <mat-select
    [formControl]="control"
    [multiple]="multiple"
    [disabled]="disabled"
    disableOptionCentering="true"
    panelClass="select-search-panel"
    placeholder="{{ title }}"
    (closed)="onClose()">
    
    <mat-select-trigger>{{displayTitle}}</mat-select-trigger>
    
    <div class="flex-wrapper">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterControl"
          placeholderLabel="введите больше 3 символов..."
          noEntriesFoundLabel="ничего не найдено">
        </ngx-mat-select-search>
      </mat-option>
    </div>
    
    <mat-checkbox
      class="select-all"
      color="primary"
      [(ngModel)]="isAllSelected"
      [ngModelOptions]="{standalone: true}"
      *ngIf="showAll"
    >
      {{allTitle}}
    </mat-checkbox>

    <mat-option *ngFor="let bank of filteredOptions" [value]="bank.value" (click)="clickSingleOption()">
      {{ bank.display }}
    </mat-option>

  </mat-select>
</mat-form-field>
