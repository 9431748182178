import { Component, Inject, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IUser } from '../../../../models/IUser';
import { IManagePzuPoints, IManagePzuPoint } from '../../../../models/points/IManagePzuPoints';
import { NotificationService } from '../../../../services/notification.service';
import { IUsersService } from '../../../../services/interfaces/IUsersService';

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
  points: IManagePzuPoints;
  pointName: string;
  licenseeId: string;
}

@Component({
  selector: '[manage-pzu]',
  templateUrl: './manage-pzu.component.html'
})
export class ManagePzuComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  user: IUser;
  points: IManagePzuPoints;
  pointName: string;
  isValid: boolean = false;
  licenseeId: string;
  public isAllPointsSelected: boolean;


  constructor(
    @Inject('UsersService') private usersService: IUsersService,
    private notificationService: NotificationService
  ) {
    super();
  }

  //Инициализация формы
  ngOnInit(): void {
    this.updateIsAllPointsSelected();
  }

  //Переключение точек на форме
  changeStatePzu(point: IManagePzuPoint) {
    point.isSelected = !point.isSelected
    this.updateIsAllPointsSelected();
  }

  //Отправка новых точек
  onSubmit() {
    if (!this.isValid) { return; }

    //Формирование массива Guid с Id точек
    let pointsToPost  =  [...this.points.pointsAvailable.filter(point => point.isSelected),
                          ... this.points.pointsAdded.filter(point => point.isSelected)];
    this.usersService.updateEmpPoints(this.licenseeId, this.user.id, pointsToPost.map(({ pointId }) => pointId)).subscribe(
      data => {
        this.user.pointList = pointsToPost;
        this.result = true;
        this.close();
      },
      error => {
        if (error){
          this.notificationService.errorNotify(error, 'Ошибка');
        }
      });
  }

  public toggleSelectAllPoints(): void {
    this.setSelectForAllPoints(!this.isAllPointsSelected);
  }

  private setSelectForAllPoints(value: boolean): void {
    this.points.pointsAdded.forEach(p => p.isSelected = value);
    this.points.pointsAvailable.forEach(p => p.isSelected = value);
    this.updateIsAllPointsSelected();
  }

  private isEveryPointSelected(): boolean {
    return this.points.pointsAdded?.every(p => p.isSelected) && this.points.pointsAvailable?.every(p => p.isSelected);
  }
  
  private isAnyPointSelected(): boolean {
    return this.points.pointsAdded?.some(p => p.isSelected) || this.points.pointsAvailable?.some(p => p.isSelected);
  }
  
  private updateIsAllPointsSelected(): void {
    this.isValid = this.isAnyPointSelected();
    this.isAllPointsSelected = this.isEveryPointSelected();
  }
}
