<div class="popup__wrapper">
	
	<div class="popup__header">
		<h4 class="h4">Добавить "{{pointName}}"</h4>
		<a id="id1538408377" class="tap-to-close" (click)="close()"></a>
	</div>

	<div class="popup__main">
		<table class="popup__table" *ngIf="points.pointsAdded?.length || points.pointsAvailable?.length">
			<tr class="popup__table-row">
				<td class="popup__table-data"></td>
				<td class="popup__table-data"></td>
				<td class="popup__table-data" >
					<div class="popup__table-chekbox">
						<input
						    id="id1707889000"
						    type="checkbox"
							class="checkbox"
							[checked]="isAllPointsSelected"
							(change)="toggleSelectAllPoints()"/>
						<label for="id1707889000"></label>
					</div>
				</td>
			</tr>
		</table>

		<h6 class="h6">Cвязанные</h6>
		<table class="popup__table">
			<tr class="popup__table-row" *ngFor="let point of points.pointsAdded; let i = index">
				<td class="popup__table-data" id="id1538408133{{i}}">{{point.title}}</td>
				<td class="popup__table-data" id="id1538408168{{i}}">{{point.address}}</td>
				<td class="popup__table-data" >
					<div class="popup__table-chekbox">
						<input id="id17079000{{i}}" type="checkbox" class="checkbox" [checked]="point.isSelected" (change)="changeStatePzu(point)"/>
						<label for="id17079000{{i}}"></label>
					</div>
				</td>
			</tr>
		</table>
		
		<h6 class="h6">Не связанные</h6>
		<table class="popup__table">
			<tr class="popup__table-row" *ngFor="let point of points.pointsAvailable; let i = index">
				<td class="popup__table-data" id="id1538408241{{i}}">{{point.title}}</td>
				<td class="popup__table-data" id="id1538408252{{i}}">{{point.address}}</td>
				<td class="popup__table-data" >
					<div class="popup__table-chekbox">
						<input id="id17079100{{i}}" type="checkbox" class="checkbox" [checked]="point.isSelected" (change)="changeStatePzu(point)" />
						<label for="id17079100{{i}}"></label>
					</div>
				</td>
			</tr>			
		</table>
		
		<div class="popup__footer" (click)="onSubmit()">
			<a id="id1538408300" class="btn" [ngClass]="{'btn--active': isValid}">Сохранить</a>
		</div>
	</div>
</div>