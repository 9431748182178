<div class="">
    <div class="upblock">
      <h1 class="h1" *ngIf="points">Список "{{settingList?.pointName}}"</h1>
      <div>
        <tsuz-points-spreadsheet-actions 
          *hasPermission="[[userRoles.Administrator]]"
          (download)="downloadPoints()">
        </tsuz-points-spreadsheet-actions>

        <a class="legend__reset-filtres" (click)="resetFilters()">Сбросить фильтры</a>
      </div>
    </div>

    <app-select-licensee
      #selectLicenseeRef
      (loaded)="getPoints()"
      (change)="resetFilters()"
      [loadOnStart]="false"
      [initLicensee]="currentLicensee"
      [(ngModel)]="currentLicensee"
    ></app-select-licensee>

    <tsuz-points-page 
      [points]="points" 
      [licensee]="licensee"
      [settingList]="settingList"
      [filter]="filter"
      (refreshPoints)="applyFilter()"
      (changed)="onChanged($event)"
      (updatePointLimits)="onUpdatePointLimits($event)" 
      (resetPointLimits)="onResetPointLimits($event)"
      (updatePointSettings)="onUpdatePointSettings($event)"
    ></tsuz-points-page>

    <app-pagination [(model)]="filter" (apply)="applyFilter()"></app-pagination>
</div>

