import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SimpleModalService } from "ngx-simple-modal";
import { EditActivityPostComponent } from './edit-activity-post/edit-activity-post.component';
import { IUser } from '../../../models/IUser';

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
}

@Component({
  selector: '[edit-activity]',
  templateUrl: './edit-activity.component.html'
})
export class EditActivityComponent implements ConfirmModel, OnInit {
  @Input() user: IUser;
  @Input() readonly: boolean = false;
  
  activityForm: FormGroup;
  reason: FormControl;
  title: string;
  message: string;

  constructor(private simpleModalService: SimpleModalService) {
  }

  ngOnInit(): void {
    // Создание формы
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.reason = new FormControl('', Validators.required);
  }

  createForm() {
    this.activityForm = new FormGroup({
      Reason: this.reason
    });
  }

  // Модальное окно с изменением статуса Сотрудника
  blockPost() {
    const titleModal = 'Блокировка пользователя';
    const disposable = this.simpleModalService.addModal(EditActivityPostComponent, {
      title: titleModal,
      message: 'Пользователь',
      user: this.user
    }, { closeOnClickOutside: true })
      .subscribe((resultActive) => {
      });
  }
}
