import {DateRange, NumberRange, PaginationFilter} from 'tsuz-common';


export class DealFilter extends PaginationFilter {
  states: string[];
  query: string;
  organizationQuery: string;
  dealNumber: string;
  xplatNumber: string;
  summ = new NumberRange('summFrom', 'summTo');
  cardNumber: string;
  phone: string;
  dateRange = new DateRange();
  withdrawTypeKeys: string[];
}
