<div class="holding-licensees-container-component">
  <app-waiting
    [showSpinner]="isLoading"
    [errorHasHappened]="hasError"
    [errorMessage]="error">
    <div class="holding-licensees-container-component--licensee-count-area">
      Лицензиатов {{sortedLicenseeInfo ? sortedLicenseeInfo.length : 0}}
    </div>
    <div class="holding-licensees-container-component--licensee-controls-area">
      <span class="holding-licensees-container-component--licensee-controls-area--left-control">
        <ng-select
          [items]="sortOptions"
          bindLabel="display"
          bindValue="code"
          placeholder="Сортировка"
          [(ngModel)] = "selectedSortOption"
          (ngModelChange)="onChangeSorting()"
        >
        </ng-select>
      </span>
      <span class="holding-licensees-container-component--licensee-controls-area--right-control">
        <input matInput type="search" [(ngModel)]="searchableValue" (ngModelChange)="onChangeSearching()" placeholder="Название лицензиата…"/>
      </span>
    </div>
    <div class="holding-licensees-container-component--licensee-cards-area">
      <div class="holding-licensees-container-component--grid">
        <app-licensee-widget
          *ngFor="let licenseeInfo of displayedLicenseeInfo"
          [licenseeInfo]="licenseeInfo">
        </app-licensee-widget>
      </div>
    </div>
  </app-waiting>  
</div>
