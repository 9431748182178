import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: "phoneFormated"
})
//Пайп для форматирования номера телефона на стороне клиента
export class PhoneFormatedPipe implements PipeTransform {
    transform(
        value: string
    ): string {
        if (!value) {
            return value;
        }
        
        let isnum = /^\d+$/.test(value);
        if (!isnum) {
            return value;
        }
        
        if (value.length == 11) {
            // RU номер
            return `+${value.substring(0, 1)}(${value.substring(1, 4)})${value.substring(4, 7)}-${value.substring(7, 9)}-${value.substring(9, 11)}`;
        }
        else if (value.length == 13) {
            // KZ номер
            return `+${value.substring(0, 3)}(${value.substring(3, 6)})${value.substring(6, 9)}-${value.substring(9, 11)}-${value.substring(11, 13)}`;
        }
        else {
            return value;
        }
    }
}
