import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AbstractTextInput } from '../abstract-base-controls/abstract-text-input';

@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PasswordInputComponent),
    multi: true,
  }]
})
export class PasswordInputComponent extends AbstractTextInput {
  @Input() maxlength;
}
