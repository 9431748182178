import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {BackButtonDirective} from './directives/back-button.directive';
import {SimpleModalModule} from 'ngx-simple-modal';
import {SubmitDisableDirective} from './directives/submit-disable/SubmitDisableDirective';
import {ValidationInputDirective} from './directives/validation-input.directive';
import {FocusPlaceholderDirective} from './directives/focus-placeholder.directive';
import {PhoneStarsPipe} from './directives/custom-pipes/phoneStars.pipe';
import {CardStarsPipe} from './directives/custom-pipes/cardStars.pipe';
import {ApiDateTimeFormatPipe} from './directives/custom-pipes/api-datetime-format.pipe';
import {TimeZonePipe} from './directives/custom-pipes/timezone.pipe';
import {TimeZoneOffsetPipe} from './directives/custom-pipes/timezone-offset.pipe';
import {TimeZoneNamePipe} from './directives/custom-pipes/timezone-name.pipe';
import {RegistryStatusesStringPipe} from './directives/custom-pipes/registry-statuses-string.pipe';
import {RegistryStatusesClassPipe} from './directives/custom-pipes/registry-statuses-class.pipe';
import {XplatRegistryStatusesStringPipe} from './directives/custom-pipes/xplatregistry-statuses-string.pipe';
import {XplatRegistryStatusesClassPipe} from './directives/custom-pipes/xplatregistry-statuses-class.pipe';
import {GroupByPipe} from './directives/custom-pipes/groupBy-pipe';
import {ActivationRequestsStatusesStringPipe} from './directives/custom-pipes/activation-requests-statuses-string.pipe';
import {DealStatusesClassPipe} from './directives/custom-pipes/deal-statuses-class.pipe';
import {IntegrationTypesStringPipe} from './directives/custom-pipes/integration-types-string.pipe';
import {NullableStringPipe} from './directives/custom-pipes/nullable-string.pipe';
import {CodeFnsForSelfEmployedPipe} from './directives/custom-pipes/code-fns-for-self-employed.pipe';
import {ToastrModule} from 'ngx-toastr';
import {MaterialModule} from '../modules/material-module';
import {HttpClientModule} from '@angular/common/http';
import {SimpleInputComponent} from './controls/simple-input/simple-input.component';
import {ListFilterComponent} from './controls/filters/list-filter/list-filter.component';
import {ToggleButtonComponent} from './controls/toggle-button/toggle-button.component';
import {AddressControlComponent} from './controls/address-control/address-control.component';
import {DateRangeFilterComponent} from './components/date-range-filter/date-range-filter.component';
import {LoadingModalComponent} from './components/loading-modal/loading-modal.component';
import {ValidationErrorsOutputComponent} from './components/validation-errors-output/validation-errors-output.component';
import {PhoneControlComponent} from './controls/phone-control/phone-control.component';
import { ValidationErrorItemComponent } from './components/validation-error-item/validation-error-item.component';
import {DateInputFormControlComponent} from '../controls/date-time-dropdown/date-input-form-control/date-input-form-control.component';
import {DateInputComponent} from '../controls/date-time-dropdown/date-input/date-input.component';
import {DateRangeInputComponent} from '../controls/date-time-dropdown/date-range-input/date-range-input.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchSelectComponent } from './controls/search-select/search-select.component';
import { PassportInfoInputControlComponent } from './controls/passport-info-input-control/passport-info-input-control.component';
import { ProductTrashDirective } from './directives/product-trash.directive';
import { ProductMeasureDirective } from './directives/product-measure.directive';
import { ProductPriceDirective } from './directives/product-price.directive';
import { BooleanToStringPipe } from './custom-pipes/booleanToString.pipe';
import { BalanceViewComponent } from './components/balance-view/balance-view.component';
import { LimitInfoComponent } from './components/limit-info/limit-info.component';
import { TextMaskModule } from '@myndmanagement/text-mask';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { TsuzCommonModule } from 'tsuz-common';
import { environment } from 'environments/environment';
import { UsersService } from '@services/users.service';
import { LicenseeService } from '@services/licensee.service';
import { UserSettingService } from '@services/user-setting.service';
import { PointService } from '@services/point.service';
import { WithdrawTypeService } from '@services/withdraw-type.service';
import { ProductService } from '@services/product.service';

export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the up second button (used by screen readers).  */
  upSecondLabel = 'Add a second';

  /** A label for the down second button (used by screen readers).  */
  downSecondLabel = 'Minus a second';

  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel = 'Add a minute';

  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel = 'Minus a minute';

  /** A label for the up hour button (used by screen readers).  */
  upHourLabel = 'Add a hour';

  /** A label for the down hour button (used by screen readers).  */
  downHourLabel = 'Minus a hour';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = 'Previous month';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = 'Next month';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = 'Previous year';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel = 'Next year';

  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel = 'Previous 21 years';

  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel = 'Next 21 years';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = 'Change to month view';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel = 'Choose month and year';

  /** A label for the cancel button */
  cancelBtnLabel = 'Отмена';

  /** A label for the set button */
  setBtnLabel = 'Готово';

  /** A label for the range 'from' in picker info */
  rangeFromLabel = 'С';

  /** A label for the range 'to' in picker info */
  rangeToLabel = 'По';

  /** A label for the hour12 button (AM) */
  hour12AMLabel = 'AM';

  /** A label for the hour12 button (PM) */
  hour12PMLabel = 'PM';
}

@NgModule({
  declarations: [
    DateInputComponent,
    DateRangeInputComponent,
    DateRangeFilterComponent,
    DateInputFormControlComponent,
    ProductPriceDirective,
    ProductMeasureDirective,
    ProductTrashDirective,
    BooleanToStringPipe,
    BackButtonDirective,
    SubmitDisableDirective,
    ValidationInputDirective,
    FocusPlaceholderDirective,
    PhoneStarsPipe,
    CardStarsPipe,
    ApiDateTimeFormatPipe,
    TimeZonePipe,
    TimeZoneOffsetPipe,
    TimeZoneNamePipe,
    RegistryStatusesStringPipe,
    RegistryStatusesClassPipe,
    XplatRegistryStatusesStringPipe,
    XplatRegistryStatusesClassPipe,
    GroupByPipe,
    ActivationRequestsStatusesStringPipe,
    DealStatusesClassPipe,
    IntegrationTypesStringPipe,
    NullableStringPipe,
    CodeFnsForSelfEmployedPipe,
    SimpleInputComponent,
    ListFilterComponent,
    ToggleButtonComponent,
    AddressControlComponent,
    DateRangeFilterComponent,
    LoadingModalComponent,
    ValidationErrorsOutputComponent,
    PhoneControlComponent,
    ValidationErrorItemComponent,
    SearchSelectComponent,
    PassportInfoInputControlComponent,
    BalanceViewComponent,
    LimitInfoComponent,
  ],
  providers: [
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru'},
    {provide: OwlDateTimeIntl, useClass: DefaultIntl},
    {provide: LOCALE_ID, useValue: 'ru-RU'},
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    ToastrModule.forRoot(), // ToastrModule added,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    ShowHidePasswordModule,
    MaterialModule,
    AngularMyDatePickerModule,
    FontAwesomeModule,
    TextMaskModule,
    TsuzCommonModule.forRoot(environment, LicenseeService, UsersService, UserSettingService, PointService, WithdrawTypeService, ProductService),
    SimpleModalModule.forRoot({container: document.body}),
  ],
  exports: [
    DateInputComponent,
    DateRangeInputComponent,
    DateRangeFilterComponent,
    DateInputFormControlComponent,
    ProductPriceDirective,
    ProductMeasureDirective,
    ProductTrashDirective,
    BooleanToStringPipe,
    BackButtonDirective,
    SubmitDisableDirective,
    NgxMaskModule,
    ToastrModule, // ToastrModule added,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    ShowHidePasswordModule,
    MaterialModule,
    NgSelectModule,
    GroupByPipe,
    DealStatusesClassPipe,
    ApiDateTimeFormatPipe,
    TimeZonePipe,
    TimeZoneOffsetPipe,
    TimeZoneNamePipe,
    FocusPlaceholderDirective,
    CodeFnsForSelfEmployedPipe,
    RegistryStatusesClassPipe,
    RegistryStatusesStringPipe,
    XplatRegistryStatusesClassPipe,
    XplatRegistryStatusesStringPipe,
    ActivationRequestsStatusesStringPipe,
    IntegrationTypesStringPipe,
    NullableStringPipe,
    SimpleInputComponent,
    ToggleButtonComponent,
    ListFilterComponent,
    AddressControlComponent,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    SimpleModalModule,
    CardStarsPipe,
    DateRangeFilterComponent,
    LoadingModalComponent,
    ValidationErrorsOutputComponent,
    PhoneControlComponent,
    ValidationErrorItemComponent,
    SearchSelectComponent,
    PassportInfoInputControlComponent,
    LimitInfoComponent,
    BalanceViewComponent,
  ]
})
export class SharedModule {
}
