import {Component, Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { PaginationFilter } from '../../filters/pagination-filter';
import { SortModel } from '../../models/sort/sort.model';


@Component({template: ''})
export abstract class FilterComponentBase<TModel> implements OnInit, OnChanges {

  isShowModal = false;
  protected filterService: FilterService;

  private _model: TModel;
  get model(): TModel {
    return this._model || this.getDefaultModelValue();
  }
  @Input()
  set model(value: TModel) {
    const previousValue = this.model;
    const currentValue = this.onModelChanging(value);
    if (previousValue === currentValue) return;
    this._model = currentValue;
    this.onModelChanged(previousValue, currentValue);
  }
  
  _sort: SortModel;
  get sort(): SortModel {
    return this._sort;
  }
  @Input()
  set sort(value: SortModel) {
    this._sort = value;
    this.sortChange.emit(this._sort);
  }

  @Input()
  sortField: string;

  @Input()
  placeholder: string;

  @Input()
  queryName: string;

  /** Use model property instead of query params to determine if the filter is active */
  @Input()
  useModel: Boolean = false;

  @Input()
  paginationFilter: PaginationFilter;

  @Output()
  sortChange = new EventEmitter<SortModel>(true);

  @Output()
  modelChange = new EventEmitter<TModel>(true);

  @Output()
  apply = new EventEmitter(true);

  constructor(filterService: FilterService) {
    this.filterService = filterService;
    this.model = this.getDefaultModelValue();
  }
  
  protected readonly modelPropertyName: string = 'model';

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes[this.modelPropertyName]) return;
    const previousValue = changes[this.modelPropertyName].previousValue;
    const currentValue = changes[this.modelPropertyName].currentValue;
    if (currentValue === previousValue) return;
    this.onModelChanged(previousValue, currentValue);
  }

  /**
   * This method is called before modify the 'model' property
   * To use in inherited classes to override and modify a new model value before assigning
   * 
   * @param {TModel} value - a new value for 'model' property
   * @returns {TModel} - By default it returns the same 'value'.
   */
  protected onModelChanging(value: TModel): TModel {
    return value;
  }
  
  /**
   * This method is called after the 'model' property was modified
   * To use in inherited classes to override and do actions after model was changed
   * 
   * @param {TModel} previousValue - previous value
   * @param {TModel} currentValue  - new value
   */
  protected onModelChanged(previousValue: TModel, currentValue: TModel): void {
  }

  get isActive(): boolean {
    if (this.useModel) {
      return Array.isArray(this.model) ? !!this.model?.length : !!this.model;
    }
    return this.model?.toString() !== this.getDefaultModelValue()?.toString() 
      || this.filterService.isActive(this.queryName);
  }
  
  /**
   * Gets an event value for 'modelChange' event before the filter will be closed
   * 
   * @returns {TModel} - this.model by default
   */
  protected getModelChangeEventValue(): TModel {
    return this.model;
  }

  onApply() {
    if (this.paginationFilter) {
      this.paginationFilter.page = 1;
    }
    const eventValue = this.getModelChangeEventValue();
    this.modelChange.emit(eventValue);
    this.apply.emit();
    this.closeModal();
  }

  /**
   * Gets an empty value for model property.
   * Used by other methods to clear the model.
   * 
   * @returns {TModel} - an empty value for model
   */
  protected abstract getDefaultModelValue(): TModel;

  onClear() {
    this[this.modelPropertyName] = this.getDefaultModelValue();
    this.onApply();
  }

  onCancel() {
    this.closeModal();
  }

  showModal() {
    if (!this.isShowModal) {
      this.isShowModal = true;
    }
  }

  protected closeModal() {
    if (this.isShowModal) {
      this.isShowModal = false;
    }
  }

  onSortClick() {
    this.sortChange.emit(this._sort);
    this.apply.emit();
  }
}
