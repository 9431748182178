export enum DealStateEnum {
    New = 0,
    InProgress = 1,
    LimitError = 2,
    DayLimitError = 3,
    TransactionLimitError = 4,
    MinLimitError = 5,
    MaxLimitError = 6,
    IsAcept = 7,
    IsAceptAdmin = 8,
    IsAceptAccountant = 9,
    Completed = 10,
    Rejected = 11,
    SmsLimitError = 12,
    ZeroError = 13,
    ReadyForPay = 14,
    GoToCheckPay = 15,
    PaymentNotFound = 16,
    InfoError = 17,
    ApprovalBan = 18,
    PointNotActive = 19,
    PayError = 20,
    KycError = 21,
    Canceled = 22,
    LimitedAttemptsEntryCode = 23,
    SuccessfulFiscalization = 24,
    DeferredFiscalization = 25
}
