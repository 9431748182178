import { Component, OnInit } from '@angular/core';
import {SimpleModalComponent} from 'ngx-simple-modal';

export interface ConfirmDialogResultModel {
  title: string;
  message: string;
}

@Component({
  selector: 'tsuz-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends SimpleModalComponent<ConfirmDialogResultModel, boolean> implements OnInit {

  title: string;
  message: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  setResult(res: boolean) {
    this.result = res;
    this.close();
  }
}
