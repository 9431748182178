<div class="staff__new-worker">
  <div class="upblock">
    <h1 class="h1">Новый сотрудник</h1>
  </div>
  <ng-container *ngIf="form" [formGroup]="form">
    <div class="staff__section">
      <div class="staff__left-section">
        <div class="staff__block">
          <fio-input formControlName="Surname"   [id]="'id1807052124'" [labelName]="'Фамилия *'" [formCtrl]="form.controls.Surname" ></fio-input>
        </div>
        <div class="staff__block">
            <fio-input formControlName="Name"   [id]="'id1807052125'" [labelName]="'Имя *'" [formCtrl]="form.controls.Name" ></fio-input>
        </div>
        <div class="staff__block">
            <middlename-input formControlName="MiddleName"   [id]="'id1807052126'" [labelName]="'Отчество'" [formCtrl]="form.controls.MiddleName" ></middlename-input>
        </div>
        <div class="staff__block">
          <login-input formControlName="Login" [id]="'id1807052128'" [labelName]="'Логин'" [formCtrl]="form.controls.Login" ></login-input>
        </div>
        <div class="staff__block">
            <label for="id1807052127" class="label empty">Телефон *</label>
            <input id="id1807052127"
              [textMask]="{mask: getPhoneMask}"
              placeholder="+"
              formControlName="Phone"
              required type="text"
              class="input min-bored input--nonvalidate"
              [ngClass]="{'input--nonvalidate': form.controls.Phone.hasError('InvalidPhone') == true || form.controls.Phone.hasError('required') == true,
            'input--validate': form.controls.Phone.hasError('InvalidPhone') == false}">
            <input type="checkbox"
                    id="464656"
                    class="checkbox-filter"
                    (change)="hasFakePhoneChange()"
                    formControlName="HasFakePhone">
            <label class="checkbox-filter-label" for="464656"> Запрет отправки СМС</label>
            <span class="report report--false report--valid"
                [ngClass]="{'report--active': form.controls.Phone.hasError('InvalidPhone') == true}">
                *Номер телефона должен быть в формате +7(000)000-00-01</span>
        </div>
        <div class="staff__block">
            <label for="email34443" class="label">Email</label>
            <input id="email34443"
              type="text"
              class="input"
              formControlName="Email"
              [ngClass]="{'input--nonvalidate': form.controls.Email.invalid,
                          'input--validate': form.controls.Email.valid}">
            <span class="report report--false report--valid" [ngClass]="{'report--active': form.controls.Email.hasError('pattern') == true}">
                *Неверный формат электронной почты.
            </span>
          <span class="report report--false report--valid" [ngClass]="{'report--active': form.controls.Email.hasError('emailRequired') == true}">
                *Электронная почта является обязательны полем.
            </span>
        </div>
        <div class="label">Выберите роль сотрудника *</div>
        <ng-container *ngIf="roleArray">
          <ng-container *ngFor="let role of roleArray.controls; let i=index">
            <div class="flex-wrapper-start checkbox-filter-label-wrapper">
              <input class="checkbox-filter"
              id="idssd{{i}}"
              type="checkbox"
              [formControl]="role"
              (change)="validRole(i)">
              <label class="checkbox-filter-label" for="idssd{{i}}">{{roleList[i] | roleName}}</label>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="right-section">
        <div class="pzu-item">
          <input
            id="id1707836000"
            type="checkbox"
            class="checkbox-filter"
            formControlName="IsAllPointsSelectedControl"
            (change)="toggleSelectAllPoints()">
          <label class="checkbox-filter-label" for="id1707836000">Выберите "{{settingList.pointName}}" *</label>
        </div>
        <ul class="pzu-list" *ngIf="pointAll && pointArray">
          <li class="pzu-item" *ngFor="let point of pointArray.controls; let i=index">
            <input type="checkbox"
              id="idpoi{{i}}"
              class="checkbox-filter"
              [formControl]="point"
              (change)="pointCheckChanged()">
            <label class="checkbox-filter-label" for="idpoi{{i}}">
              <label class="label-item">{{pointAll[i].title}}</label>
              <label class="label-item">{{pointAll[i].address}}</label>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <span class="report report--false report--valid" [ngClass]="{'report--active': errorCheck == true}">*{{errorInfo}}.</span>
    <div class="staff__footer">
      <button class="btn btn--active btn--back2" (click)="goBack()">Назад</button>
      <button id="id1538405810"
        class="btn"
        [ngClass]="{'btn--active': form.invalid == false && roleCheck == true && pointCheck == true && submitting == false}"
        [disabled]="submitting"
        (click)="toPost()">
        Сохранить
      </button>
    </div>
  </ng-container>
</div>
