import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ImportHistoryFilter} from '../../models/prepaids/import-history-filter';
import {BaseHttpService} from 'tsuz-common';

@Injectable()
export class PrepaidRegistryCardService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  getList(filter: ImportHistoryFilter): Observable<any> {
    return this.baseHttpService.get('/PrepaidCards?' + filter.toString());
  }

  uploadRegistry(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('registry', file, file.name);
    return this.baseHttpService.post('/PrepaidCards', formData);
  }
}
