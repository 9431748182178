<div class="dropdown__header">
  <h5 class="h5">Настройка лимитов</h5>
</div>
<div class="dropdown__form-balance">
  <div class="label">
    Остаток:
  </div>
  <div id="id1538401694" class="dropdown__day-limit-wrapper">
    {{point.dayLimitBalance | money: licensee.currency}}
  </div>
  <div class="btn-reset__wrapper">
    <input (click)="resetLimitById()" class="btn-reset__limit" id="btn-reset-limit-01" type="button" value="" >
    <label class="btn-reset__label" for="btn-reset-limit-01">Обнулить остаток</label>
  </div>
</div>

<form id="id1538401734" class="dropdown__form dropdown__limit-settings" [class.disabled]="readonly"
  [formGroup]="limitForm" (ngSubmit)="onSubmit()" *ngIf="point">
  
  <div class="dropdown__section relative">
    <div class="relative">
      <label class="label" for="id1807070930">Суточный лимит, {{licensee.currency}}</label>
      <div class="relative">
        <input id="id1807070930" type="text" class="input" formControlName="Limit" [dropSpecialCharacters]="false" mask="0*.00"
        maxlength="12" (input)="checkMaxLimit()">        
        <div *ngIf="limitForm.controls.Limit?.value === '0'" class="label--unlimit absolute">Безлимит</div>
      </div>
    </div>
    <div>
      <span id="id1538400962" class="report report--false report--valid" [ngClass]="{'report--active': limitMaxError == true}">*Максимально возможный
        лимит 1 000 000 000.00 {{licensee.currency}}</span></div>
    <div> <span id="id1538400984" class="report report--false report--valid" [ngClass]="{'report--active': limitForm.controls.Limit.errors?.limitCheck == true}">*Лимит
        не может быть меньше суммы, потраченной за сегодня.</span></div>
  </div>
  
  <div class="dropdown__section">
    <label class="label" for="transaction-limit-{{point.id}}">
      Лимит на транзакцию,<br>
      <ng-container *ngIf="licensee.transactionLimit !== 0; else unlimited">
         (не больше {{licensee.transactionLimit}} {{licensee.currency}})
      </ng-container>
      <ng-template #unlimited>(не ограничен)</ng-template>
    </label>
    <input id="transaction-limit-{{point.id}}" class="input" type="text" formControlName="TransactionLimit" [dropSpecialCharacters]="false"
      mask="0*.00" maxlength="12" (input)="checkMaxTransactionLimit()">
    <span id="id1538401019" class="report report--false report--valid" [ngClass]="{'report--active': transactionMaxError == true}">*Максимально возможный
      лимит транзакции {{licensee.transactionLimit}} {{licensee.currency}}</span>
  </div>

  <div class="dropdown__section">
    <div class="label">Сверхлимитные оплаты</div>
    <app-select-box-control
      id="id1538403997"
      [items]="listGate"
      bindLabel="title"
      bindValue="value"
      formControlName="isAcceptedByManagerPlus">
    </app-select-box-control>
    <div class="">
      <span id="id1538402005" class="message report report--false" *ngIf="errorMessage"> {{errorMessage}}</span>
    </div>
  </div>

  <div class="dropdown__footer">
    <button id="id1538401152" [disabled]="limitForm.invalid && limitMaxError && transactionMaxError" value="Submit" class="btn" [ngClass]="{'btn--active': limitForm.valid == true && limitMaxError == false && transactionMaxError == false}"
      type="submit">Сохранить</button>
  </div>
</form>
