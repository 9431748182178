<app-base-widget
[header]="'Подключите кэш-пулинг!'"
[icoType]="'cashpooling-ad'"
[isLoading]="!isLoadingCompleted"
[hasError]="hasError"
[loadingError]="error"
[hidePrimaryButton]="true"
[backgoundColor] = "'#3F50B4'"
[headerFontColor] = "'#fff'"
>
  <div class="cashpooling-ad-widget-component widget-content-columns-container">
    <div class="widget-content-column-100">
      <div class="widget-content-row-left cashpooling-ad-widget-row-main">
        Подключите номинальный счет и используйте механизм КЭШ-ПУЛИНГА
      </div>
      <div class="widget-content-row-left cashpooling-ad-widget-row"></div>
      <div class="widget-content-row-left cashpooling-ad-widget-row"></div>
      <div class="widget-content-row-left cashpooling-ad-widget-row">
        За дополнительной информацией обращайтесь к нашим менеджерам
      </div>
      <div class="widget-content-row-left cashpooling-ad-widget-row"></div>
      <div class="widget-content-row-left cashpooling-ad-widget-row"></div>
      <div class="widget-content-row-left cashpooling-ad-widget-row"></div>
      <div class="widget-content-row-left cashpooling-ad-widget-row">
        Как работает? 
        <a href="mailto:finnance@asumet.ru">
          finnance@asumet.ru
        </a>
      </div>
      <div class="widget-content-row-left cashpooling-ad-widget-row">
        Как подключить? 
        <a href="mailto:support@asumet.ru">
          support@asumet.ru
        </a>
      </div>
    </div>
  </div>
</app-base-widget>