import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, LengthValidator, passwordMinLength, passwordMaxLength } from 'tsuz-common';
import { UserService } from '@services/user.service';
import { ChangePasswordConfirmComponent } from './change-password-confirm/change-password-confirm.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent
  implements  OnInit {
  changePasswordFormGroup: FormGroup;
  newPassword: string;
  oldPassword: string;
  newPasswordControl: FormControl;
  oldPasswordControl: FormControl;
  repeatNewPassword: FormControl;

  readonly passwordMinLength = passwordMinLength;
  readonly passwordMaxLength = passwordMaxLength;

  constructor(
    private simpleModalService: SimpleModalService,
    private userService: UserService,
    private notifyService: NotificationService
  ) {}

  // Создание формы
  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.newPasswordControl = new FormControl('', [Validators.required, LengthValidator(this.passwordMinLength, this.passwordMaxLength)]);
    this.oldPasswordControl = new FormControl('', [Validators.required, LengthValidator(this.passwordMinLength, this.passwordMaxLength)]);
    this.repeatNewPassword = new FormControl('', [Validators.required, LengthValidator(this.passwordMinLength, this.passwordMaxLength)]);
  }

  createForm() {
    this.changePasswordFormGroup = new FormGroup(
      {
        oldPassword: this.oldPasswordControl,
        newPassword: this.newPasswordControl,
        repeatNewPassword: this.repeatNewPassword
      },
      this.codeMatchValidator
    );
  }

  codeMatchValidator(formGroup: FormGroup) {
    return formGroup.get('repeatNewPassword').value === formGroup.get('newPassword').value
      ? null
      : { mismatch: true };
  }

  async sendConfirmCode() {
    if (this.changePasswordFormGroup.valid) {
      this.userService
      .sendOtpForPasswordChange()
      .subscribe(
        () => this.addConfirmCodeModal(),
        error => this.notifyService.errorNotify('Ошибка отправки кода подтверждения'));
    }
  }

  addConfirmCodeModal(){
    this.simpleModalService
    .addModal(
      ChangePasswordConfirmComponent,
      {
        newPassword: this.changePasswordFormGroup.value.newPassword,
        oldPassword: this.changePasswordFormGroup.value.oldPassword
      },
      {
        closeOnClickOutside: true
      }
    )
    .subscribe({
      complete: () => this.resetInputs(),
    });
  }

  resetInputs(){
    this.newPasswordControl.reset();
    this.oldPasswordControl.reset();
    this.repeatNewPassword.reset();
  }
}
