import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {

  @Output()
  cancel = new EventEmitter();

  @Input()
  message: string;
  @Input()
  title: string;
  constructor() {
  }

  ngOnInit() {
  }

  close() {
    this.cancel.emit();
  }

}
