import { Pipe, PipeTransform } from '@angular/core';
import {IntegrationTypesEnumLabels} from '../../../models/support/Turnover/IntegrationTypes';

@Pipe({
  name: 'integrationTypesDescription'
})
export class IntegrationTypesStringPipe implements PipeTransform {
  transform(value: any, args?: any): string {
    const label = IntegrationTypesEnumLabels.get(value);
    return  label ?  label : 'Не определен';
  }
}
