import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { v4 as randomUUID } from 'uuid';
import { SimpleModalService } from 'ngx-simple-modal';
import { UploadProductsComponent } from '../upload-products/upload-products.component';
import { DEFAULT_CATEGORY, DEFAULT_MEASURE_UNITS } from '../constants/products-options';
import { NotificationService } from '../../services/notification.service';
import { IProductService } from '../../services/interfaces/IProductService';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserRoles } from '../../models/auth/user-roles';

class TreeNode {
  id: string;
  name: string;
  children: TreeNode[];

  key?: string;
  shortName?: string;
  isActive?: boolean;
}

class TreeFlatNode {
  id: string;
  expandable: boolean;
  name: string;
  level: number;

  key?: string;
  shortName?: string;
  total?: number;
}

@Component({
  selector: 'tsuz-products-tree',
  templateUrl: './products-tree.component.html',
  styleUrls: ['./products-tree.component.scss'],
})
export class ProductsTreeComponent implements OnInit {

  @Input() licenseeId: string;
  @Input() readonly: boolean = false;
  
  initialProducts: TreeNode[];

  private _products: TreeNode[] = [];
  get products(): TreeNode[] { return this._products; }
  set products(value: TreeNode[]) {
    this._products = value;
    this.dataSource.data = this._products;
    this.refreshTree();
  }

  readonly defaultCategory = DEFAULT_CATEGORY;
  readonly defaultMeasureUnits = DEFAULT_MEASURE_UNITS;
  readonly userRoles = UserRoles;

  flatNodeMap = new Map<TreeFlatNode, TreeNode>();
  nestedNodeMap = new Map<TreeNode, TreeFlatNode>();

  transformer = (node: TreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name ? existingNode : new TreeFlatNode();

    flatNode.id = node.id;
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;

    if (level === 0) flatNode.total = node.children ? node.children.length : 0;
    if (node.key) flatNode.key = node.key;
    if (node.shortName) flatNode.shortName = node.shortName;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  treeControl = new FlatTreeControl<TreeFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    @Inject("ProductService") private productService: IProductService,
    private simpleModalService: SimpleModalService,
    private notificationService: NotificationService
  ) { 
  }

  ngOnInit() {
    this.getProducts();
  }

  get productsWereChanged() {
    return JSON.stringify(this.initialProducts) !== JSON.stringify(this.products);
  }

  hasNoContent = (_: number, node: TreeFlatNode) => node.name === '';
  isUnit = (_: number, node: TreeFlatNode) => node.level === 3;
  
  refreshTree() {
    this.dataSource.data = this.dataSource.data; // для принудительного обновления mat-tree
  }

  addCategory() {
    const data = this.dataSource.data;
    data.push({id: randomUUID(), name: '', children: []})
    this.refreshTree();
  }

  addNewItem(node: TreeFlatNode) {
    if (node.level === 0) {
      this.openAddProductModal(node);
      return;
    }

    const parentNode = this.flatNodeMap.get(node);
    const childNode = <TreeNode>{ id: randomUUID(), name: '', children: [] };
    parentNode.children.push(childNode);

    this.refreshTree();
    this.treeControl.expand(node);
  }

  openAddProductModal(node: TreeFlatNode) {
    this.simpleModalService.addModal(UploadProductsComponent, 
      {licenseeId: this.licenseeId, action: 'add-product', category: node.name}, { closeOnEscape: true })
      .subscribe(result => {
        if (!result) return;

        const product = <TreeNode>{ 
          id: randomUUID(), 
          name: result.productName, 
          key: result.productKey, 
          children: [{
              id: randomUUID(), 
              name: result.measure, 
              children: result.measureUnits.map(u => <TreeNode>{id: randomUUID(), name: u.name, shortName: u.shortName, children: null})
          }] 
        };

        const parentNode = this.flatNodeMap.get(node);
        parentNode.children.push(product);

        this.refreshTree();
        this.treeControl.expand(node);
      });
  }

  saveNode(node: TreeFlatNode, name: string, additional?: string) {
    // additional будет undefined когда параметр вообще не передаётся
    // если передаётся дополнительный параметр - то нужно и имя и дополнительный параметр, чтобы сохранить
    if (additional !== undefined && !(name && additional)) return;

    const flatNode = this.flatNodeMap.get(node);
    flatNode.name = name;

    switch (node.level) {
      case 1: flatNode.key = additional; break;
      case 3: flatNode.shortName = additional; break;
    }
    this.refreshTree();
  }

  updateNodeProperty(node: TreeFlatNode, value: string, prop = 'name') {
    const flatNode = this.flatNodeMap.get(node);
    flatNode[prop] = value;

    this.refreshTree();
  }

  onMeasureUnitSelected(unitName: string, unitShortNameElement) {
    const unit = this.defaultMeasureUnits.find(u => u.name === unitName);
    if (unit) {
      unitShortNameElement.value = unit.shortName;
    }
  }

  getProducts() {
    this.productService.get(this.licenseeId)
      .subscribe(result => {
          this.products = result;
          this.initialProducts = JSON.parse(JSON.stringify(this.products)); // deep copy
        });
  }

  saveProducts() {
    var categories = this.products.map(x => x.name);
    var isValid = true;
    categories.forEach((value, index) => {
        if (categories.indexOf(value) !== index) {
          this.notificationService.errorNotify('Категория ' + value + ' уже существует', 'Ошибка');
          isValid = false;
        }
    });

    if (isValid){
      this.productService.post(this.licenseeId, this.products)
        .subscribe(result => {
          this.notificationService.successNotify('Товары успешно сохранены', 'Сохранено');
          this.getProducts();
        }, error => this.notificationService.errorNotify(error, 'Ошибка'))}
  }

  openUploadProductsModal() {
    const categories = this.products.map(p => <any>{id: p.id, name: p.name});

    this.simpleModalService.addModal(UploadProductsComponent, {licenseeId: this.licenseeId, action: 'upload-file', categories: categories}, { closeOnEscape: true })
      .subscribe(result => {
        if (result) {
          this.notificationService.successNotify('Товары были успешно загружены из файла.', 'Загрузка товаров');
          this.getProducts();
        }
      });
  }

  downloadProducts() {
    this.productService.downloadFile(this.licenseeId);
  }

  deleteProduct(node: TreeFlatNode) {
    // если товар не был сохренён в БД - удаляем его без модалки
    if (!JSON.stringify(this.initialProducts).includes(node.id)) {
      for (let i = 0; i < this.products.length; i++) {
        const element = this.products[i];
        const productToDeleteIndex = element.children.findIndex(c => c.id === node.id);
        if (productToDeleteIndex >= 0) {
          element.children.splice(productToDeleteIndex, 1);
          this.refreshTree();
          break;
        } 
      }

      return;
    }

    const data = { message: 'Вы уверенны, что хотите удалить этот товар?', title: 'Удаление товара' };
    this.simpleModalService.addModal(ConfirmDialogComponent, data)
      .subscribe(result => {
        if (result) {
          this.productService.deleteProduct(this.licenseeId, node.id)
            .subscribe(_ => {
              this.notificationService.successNotify('Товар был успешно удалён.', 'Удаление товара');
              this.getProducts();
            }, error => this.notificationService.errorNotify(error, 'Ошибка'));
        }
      });
  }

}
