import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { SelectLicenseeComponent } from 'app/widgets/select-licensee/select-licensee.component';
import { PointService } from '@services/point.service';
import { LicenseeService } from '@services/licensee.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService, IPzuPoint, UserRoles, LoaderService, ILicenseeSetting, LicenseeModel, 
  NotificationService, PointFilter, FilterService, BaseHttpService } from 'tsuz-common';
import { saveAs } from 'file-saver/FileSaver';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})

export class PointsComponent implements OnInit {
  @ViewChild("selectLicenseeRef", { static: true })
  selectLicensee: SelectLicenseeComponent;

  points: IPzuPoint[];
  licensee: LicenseeModel;
  filter = new PointFilter();
  
  settingList: ILicenseeSetting;
  isFinDirector = false;
  licensees: LicenseeModel[];
  currentLicensee: string = null;
  readonly userRoles = UserRoles;

  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  licenseeId: string;

  constructor(
    private pointService: PointService,
    private licenseeService: LicenseeService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private filterService: FilterService,
    private baseHttpService: BaseHttpService,
    @Inject("LOCALSTORAGE") private localStorage: any,
  ) {

  }

  ngOnInit(): void {
    this.licenseeId = this.localStorage.getItem('licenseeId');

    // Получение настроек лицензиата
    this.licenseeService.getUserLicensees()
      .subscribe(result => {
        console.log(result)
        if (result && result[0]) {
          this.licensee = result[0];
        }
      })

    this.licenseeService.getSettings(this.licenseeId)
      .subscribe(result => {
        this.settingList = result as ILicenseeSetting;
        if (this.authService.inRole(UserRoles.FinancialDirector) || this.authService.inRole(UserRoles.Accountant)) {
          this.setCurrentLicenseeFromQuery();
          this.selectLicensee.loadLicensees();
        }

        if (this.authService.inRole(UserRoles.FinancialDirector)) {
          this.isFinDirector = true;
        }
      }, error => {
        console.error(error);
      });

      this.route.queryParams
        .subscribe(params => {
          this.filter = new PointFilter();
          this.filter.initFromParam(params);
          
          this.getPoints();
    });
  }

  applyFilter() {
    this.filterService.applyFromModel(this.filter, true);
  }

  resetFilters() {
    this.filter = new PointFilter();
    this.filter.licenseeId = this.currentLicensee;
    this.applyFilter();
  } 

  setCurrentLicenseeFromQuery() {
    this.route.queryParams.pipe(takeUntil(this.destroy))
      .subscribe(keys => {
        const licenseeFromQuery = keys['licenseeId'];
        this.currentLicensee = this.currentLicensee || licenseeFromQuery;
      });
  }

  // Получение точек
  getPoints(): void {
    this.loaderService.display(true);

    this.pointService.getPage(this.currentLicensee, this.filter).subscribe(result => {
      this.points = result.items as IPzuPoint[];
      this.filter.total = result.total;
      this.loaderService.display(false);
    }, error => console.error(error));
  }

  // Эмиттер обновления точек
  onChanged(edit: IPzuPoint) {
    this.getPoints();
  }

  //Обнуление лимитов точки
  onResetPointLimits(point: IPzuPoint) {
  this.pointService.resetLimits(point.id).subscribe(result => {
      this.notificationService.successNotify('Лимит успешно обнулён', 'Обнуление лимита');
      point.dayLimitBalance = point.limit;
    },
      error => console.error(error));
  }

  //Обновление лимитов точки
  onUpdatePointLimits(updated: any) {
    const limits = (({ pointId, ...o }) => o)(updated);
    const point = this.points.find(p => p.id === updated.pointId);
    if (point) {
      limits.timeZoneId = point.timeZoneId;
    }
    this.pointService.update(this.licenseeId, updated.pointId, limits).subscribe(data => {
      this.notificationService.successNotify('Лимиты точки успешно сохранены', 'Лимиты сохранены');
      if (point) {
        point.limit = +updated.Limit;
        point.transactionLimit = +updated.TransactionLimit;
        point.dayLimitBalance = point.limit - point.currentlimit;
        point.isAcceptedByManagerPlus = updated.isAcceptedByManagerPlus;
      }
    },
      error => console.error(error));
  }

  //Обновление настроек точки
  onUpdatePointSettings(updated: any) {
    const settings = (({ pointId, ...o }) => o)(updated);

    this.pointService.update(this.licenseeId, updated.pointId, settings).subscribe(data => {
      this.notificationService.successNotify('Настройки успешно сохранены', 'Настройки сохранены');
      const point = this.points.find(p => p.id === updated.pointId);
      if (point) {
        point.title = updated.Title;
        point.address = updated.Address;
        point.active = updated.Active;
        point.timeZoneId = updated.TimeZoneId;
      }
    },
      error => {
        this.notificationService.errorNotify(error, updated.pointId);
        console.error(error);
      }
    );
  }

  downloadPoints() {
    this.baseHttpService.getFile(`/points/file`)
      .subscribe(response => saveAs(response.body, `Точки_${this.licenseeId}.xlsx`));
  }
}
