<form  [formGroup]="codeForm" (ngSubmit)=" sendConfirmCode()" >
<div class="dropdown__header"><h5 class="h5">Смена кода подтверждения</h5></div>
<section class="dropdown__section">
  <div class="dropdown__staff-settings-inpgroup">
    <div class="dropdown__staff-settings-inpgroup-item">
        <password-input
          id="Code"
          formControlName="Code"
          maxlength="6"
          [labelName]="'Код подтверждения'"
          ></password-input>
        <span class="report report--false report--valid" [ngClass]="{'report--active': codeForm.controls.Code?.hasError('minLength') == true}">*Минимальная длина поля составялет 6 символов.</span>
        <span class="report report--false report--valid" [ngClass]="{'report--active': codeForm.controls.Code?.hasError('onlyDigits') == true}">*Допустимо вводить только цифры.</span>
      </div>
  </div>
  <div class="dropdown__staff-settings-inpgroup">
      <div class="dropdown__staff-settings-inpgroup-item">
          <password-input
            id="ReEnterCode"
            formControlName="ReEnterCode"
            maxlength="6"
            [labelName]="'Повторите код подтверждения'"
            ></password-input>
          <span class="report report--false report--valid" [ngClass]="{'report--active': codeForm.controls.ReEnterCode?.hasError('minLength') == true}">*Минимальная длина поля составялет 6 символов.</span>
          <span class="report report--false report--valid" [ngClass]="{'report--active': codeForm.controls.ReEnterCode?.hasError('onlyDigits') == true}">*Допустимо вводить только цифры.</span>
          </div>
    </div>
     <div class="message report report--false text-center" *ngIf="codeForm.hasError('mismatch') ">
       Введенные значения не совпадают
      </div>
</section>
<div class="dropdown__footer">
  <button type="button"
    (click)="sendConfimCodeOnReset()"
    class="btn btn--active">
      Сбросить код
  </button>

    <button  [disabled]="codeForm.invalid"
        [ngClass]="{'btn--active': codeForm.valid == true}"
        type="submit"
        class="btn">Изменить код</button>
</div>
</form>


