import { Component, OnInit } from '@angular/core';
import { LicenseeService} from '@services/licensee.service';
import {BalanceOrganization} from 'tsuz-common';

@Component({
  selector: 'app-licensee-balances',
  templateUrl: './licensee-balances.component.html'
})
export class LicenseeBalancesComponent implements OnInit {
  balance: BalanceOrganization[];

  constructor(private licenseeService: LicenseeService) { }

  ngOnInit() {
    this.getBalances();
  }

  getBalances(): void {
    this.licenseeService.getBalances().subscribe(result => {
      this.balance = result;
    }, error => console.error(error));
  }
}

