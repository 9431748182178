import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProductService, BaseHttpService } from 'tsuz-common';
import { saveAs } from 'file-saver/FileSaver';

@Injectable()
export class ProductService implements IProductService {

  private readonly baseUrl: string = '/licensees/products';

  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  get(licenseeId: string): Observable<any> {
    return this.baseHttpService.get(this.baseUrl);
  }

  post(licenseeId: string, products: any) {
    return this.baseHttpService.post(this.baseUrl, products)
  }

  uploadFile(licenseeId: string, data: any) {
    return this.baseHttpService.post(`${this.baseUrl}/upload`, data);
  }
  
  downloadFile(licenseeId: string) {
    this.baseHttpService.getFile(`/licensees/products/file`)
      .subscribe(response => saveAs(response.body, `Товары_${licenseeId}.xlsx`));
  }

  deleteProduct(licenseeId: string, productId: string) {
    //only available for webclien-admin
  }

}
