import { Component } from '@angular/core';
import { FilterComponentBase } from '../filter-component-base';
import { FilterService } from '../../../services/filter.service';
import { NumberRange } from '../../../filters/number-range';

@Component({
  selector: 'app-number-range-filter',
  templateUrl: './number-range-filter.component.html',
  styleUrls: ['./number-range-filter.component.scss']
})

export class NumberRangeFilterComponent extends FilterComponentBase<NumberRange> {
  public numberFrom: string;
  public numberTo: string;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  protected getDefaultModelValue(): NumberRange {
    return new NumberRange('', '');
  }
  
  protected onModelChanged(previousValue: NumberRange, currentValue: NumberRange) {
    this.numberFrom = String(currentValue.numberFrom);
    this.numberTo = String(currentValue.numberTo);
    super.onModelChanged(previousValue, currentValue);
  }

  protected getModelChangeEventValue(): NumberRange {
    const result = new NumberRange(this.model.queryNameFrom, this.model.queryNameTo, this.getNumber(this.numberFrom), this.getNumber(this.numberTo));
    return result;
  }

  private getNumber(item: string): number | null {
    let value = item as unknown as number;
    if (value === undefined) {
      return null;
    }
    return value;
  }

  get isActive(): boolean {
    return this.model != null  && (this.model.numberFrom > 0 || this.model.numberTo > 0);
  }
}
