import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { PaymentModalComponent } from '../../components/people-deal/payment-modal/payment-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DealService } from './deal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { AuthService, NotificationService, ConfirmDialogComponent } from 'tsuz-common';
import {Observable} from 'rxjs';
import {DealDraftViewModel} from '@models/peopledeal/DealDraftViewModel';

@Injectable()
export class DealFormService {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dealService: DealService,
    private notificationService: NotificationService,
    private simpleModalService: SimpleModalService,
    private authService: AuthService) { }

  // Создание оплаты
  createDeal() {
    this.getDraftModel().subscribe(draft => {
      this.openCreateForm(draft);
     }, error => {
       this.openCreateForm(null);
     });
  }

  // Редактирование оплаты
  editDeal(dealId: string) {
    this.router.navigate(['/electronic-psa'], {queryParams: {dealId: dealId}});
  }

  // Копирование оплаты
  copyDeal(dealId: string) {
    this.dealService.getForEdit(dealId).pipe(take(1)).subscribe(result => {
      result.scanDocuments = null;
      this.simpleModalService.addModal(PaymentModalComponent, {
      title: 'Новая оплата (копирование)', // + deal.dealNumber,
      message: 'Confirm message',
      initialFormValues: result,
      isEditingForm: false,

    }).pipe(take(1)).subscribe((isConfirmed) => {});
    });
  }

  public getDraftModel(): Observable<DealDraftViewModel> {
    return  new Observable<DealDraftViewModel>(observer => {

      this.dealService.getDraft().subscribe(draft => {
        if (!draft) {
          observer.next(null);
          return observer.complete();
        }

        this.simpleModalService.addModal(ConfirmDialogComponent, {
          title: 'Восстановление данных',
          message: 'Хотите восстановить данные?',
        })
          .pipe(take(1))
          .subscribe((isConfirmed) => {
            if (isConfirmed) {
              observer.next(draft);
              return observer.complete();
            }

            this.dealService.deleteDraft().subscribe(x => {
              observer.next(null);
              return observer.complete();
            });
          });
      }, error => {
        observer.next(null);
        return observer.complete();
      });
    });
  }

  private openCreateForm(initialValues: DealDraftViewModel) {
    this.simpleModalService.addModal(PaymentModalComponent, {
      title: 'Новая оплата',
      message: 'Confirm message',
      initialFormValues: initialValues,
      isEditingForm: false
    })
      .pipe(take(1))
      .subscribe((isConfirmed) => {});
  }
}
