import {AbstractControl, ValidatorFn} from '@angular/forms';

export function DecimalValidator(): ValidatorFn {
  return (control: AbstractControl): { 'maxLength': boolean } | { 'pattern': boolean } | null => {
    if (!control.value) {
      return null;
    }
    let value = control.value;

    const pattern = new RegExp('^(\\d*)(\\.\\d\\d?)?$');
    return pattern.test(value) ? null : { 'pattern': true };
  };
}
