<div class="popup__wrapper">
    <h5 class="h5">
      <span>{{title}}</span>
    </h5>
    <div class="big-icon big-icon--blocking"></div>
    <div class="label">Пользователь</div>
    <div class="user-name">{{user.surname}} {{user.name}} {{user.middleName}}</div>
    <div class="message">будет {{user.active ? "заблокирован" : "разблокирован"}}</div>

    <div class="popup__footer text-center">
      <button id="id1538406090" class="btn btn--active" (click)="toPost()">{{user.active ? "Заблокировать" : "Разблокировать"}}</button>
    </div>
</div>
