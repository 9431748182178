import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'tsuz-common';

@Injectable()
export class LicenseeSettingService {

    constructor(private baseHttpService: BaseHttpService) {
    }

    getListMethods(): Observable<any> {
      return this.baseHttpService.get('/settings/channel'); }
    sendConfirmCode(): Observable<any> {
      return this.baseHttpService.post('/settings/confirmations/send', null); }

    changeChannel(confirmCode: string, sendMethod: string,  defaultCode: string): Observable<any> {
      const requestModel = {
        confirmCode: confirmCode,
        sendMethod: sendMethod,
        directorCode: defaultCode,
      }

      return this.baseHttpService.patch('/settings/channel', requestModel); }
}
