<div *ngIf="!isHidden">
    <app-waiting
        [showSpinner]="isLoading"
        [errorHasHappened]="hasError"
        [errorMessage]="error">
        <div class="holding-summary-info-component--refresh-info-area">
            <label>Последнее обновление </label>
            <span>{{summaryInfo.lastRefreshDate | date: dateTimeFormat}}</span>
        </div>

        <!-- поля скрыты так как передавались с аккаутинга -->
        <div class="holding-summary-info-component--summary-info-area">
            <div hidden>
                <label>
                    Баланс на начало дня
                </label>
                <div>
                    <span class="blue-font">{{summaryInfo.amountStartDayBalance | money: summaryInfo.currency}}</span>
                </div>
            </div>
            <div>
                <label>
                    Суточный расход по всем лицензиатам
                </label>
                <div>
                    <span class="blue-font">{{summaryInfo.amountDailyExpense | money: summaryInfo.currency}}</span>
                </div>
            </div>
            <div hidden>
                <label>
                    Остаток
                </label>
                <div>
                    <span class="red-font">{{summaryInfo.amountRest | money: summaryInfo.currency}}</span>
                </div>
            </div>
            <div hidden>
                <label>
                    Общая сумма пополнений баланса за сутки
                </label>
                <div>
                    <span class="blue-font">{{summaryInfo.amountBalanceRefill | money: summaryInfo.currency}}</span>
                </div>
            </div>
        </div>
    </app-waiting>
</div>