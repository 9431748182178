<div class="table__cell">
  <div class="table__data">
    <div class="flex-wrapper">
      <div class="flex-wrapper btn-group1">
        <a id="id1538406806" class="btn-for-open"></a>
        <a id="id1538406823" class="status-btn " [ngClass]="{'status-btn--green': user.active == true,'status-btn--red': user.active == false}"></a>
      </div>
    </div>
  </div>
</div>

<div class="table__cell">
  <div class="table__data">
    <div class="flex-wrapper">
      <div id="id1538406982" class="staff__name">
        {{user.surname}} {{user.name}} {{user.middleName}}
    </div>
  </div>
</div>
</div>
<div class="table__cell">
  <div id="id1538406920" class="table__data">
    <span class="label--mobile">Логин:</span>
    {{user.login}}
  </div>
</div>

<div class="table__cell">
  <div id="id1538406919" class="table__data">
    <span class="label--mobile">Телефон:</span>
    {{user.phone | phoneFormated}}
  </div>
</div>

<div class="table__cell">
  <div is="id1538406919" class="table__data">
    <span class="label--mobile">E-mail:</span>
    {{user.email}}
  </div>
</div>
<div class="table__cell">

<div is="id1538406919" class="table__data">
  <span class="label--mobile">Роль:</span>
  <span *ngFor="let role of user.roles; let isLast=last">
    {{ role | roleName }}{{ isLast ? '' : ',&nbsp;' }}
  </span>
</div>
</div>

