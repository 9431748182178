import { Component, Input, ViewChild, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'middlename-input',
  templateUrl: './middlename-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MiddlenameInputComponent),
    multi: true,
  }]
})
export class MiddlenameInputComponent implements ControlValueAccessor {
  value: string = '';
  @Input() id: string;
  @Input() labelName: string;
  @Input() formCtrl: any;
  @Input() placeholder: string = '';

  @ViewChild('inputElement', { static: true }) private _inputElement: ElementRef;

  constructor(
    private _renderer: Renderer2
  ) { }

  get inputElement(): ElementRef {
    return this._inputElement;
  }

  private _onChange = (_: any) => { };
  private _onTouched = () => { };

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._inputElement.nativeElement, 'disabled', isDisabled);
  }

  onChange(event: any) {
    let s = event.target.value;
    this._onChange(s);
  }
  onKeyup(event: any) {
    let s = this.trimStart(event);
    this._onChange(s);
  }
  onBlur(event: any) {
    this._onTouched();
  }
  onFocusOut(event: any) {
    this.trimInput(event)
    this.onChange(event);
  }

  private trimInput(event:any){
    let s="";
    if( event.target && event.target.value)
    {
     s=event.target.value.toString().trim();
    this._renderer.setProperty(this._inputElement.nativeElement, 'value', s);

    }
    return s ? s: "";
  }
  private trimStart(event:any) {
    let s="";
    if( event.target && event.target.value)
    {
     s=event.target.value.toString().trimStart();
    this._renderer.setProperty(this._inputElement.nativeElement, 'value', s); }
    return s ? s: "";
  }
}
