<label for="id" class="label empty">{{labelName}}</label>
<input
  #inputElement
  class="input min-bored"
  [ngClass]="{'nonvalidate': formCtrl?.invalid  ,'validate': formCtrl?.valid}"
  [value]="value"
  type="password"
  [id]="id"
  [attr.maxlength]="maxlength"
  [placeholder]="placeholder"
  (change)="onChange($event)"
  (keyup)="onKeyup($event)"
  (focusout)="onFocusOut($event)"
  (blur)="onBlur($event)"
  >


  <span class="report report--false report--valid" [ngClass]="{'report--active': formCtrl?.hasError('required') == true}">*Поле является обязательным.</span>
  <span class="report report--false report--valid" [ngClass]="{'report--active': formCtrl?.hasError('maxLength') == true}">*Превышена максимальная длина поля.</span>

