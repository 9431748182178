import {Component, EventEmitter, forwardRef, Input, OnInit} from '@angular/core';
import {IAngularMyDpOptions, IMyDate, IMyDateModel} from 'angular-mydatepicker';
import { DatePickerSettingsService } from '@services/date-picker-settings.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-input-component',
  templateUrl: 'date-input-component.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateInputComponent),
    multi: true,
  }]
})

export class DateInputComponent implements OnInit, ControlValueAccessor {

  @Input()
  placeholder: string;

  @Input()
  iconId: string;

  @Input()
  pluginInputId: string;

  private value: string;

  myDatePickerOptionsNormal: IAngularMyDpOptions;
  myDateModel: IMyDateModel;

  @Input()
  alignRight = true;

  _onChange = (val: any) => {};
  private _onTouched = () => { };

  constructor(private datePickerSettingsService: DatePickerSettingsService) { }

  ngOnInit() {
    this.myDatePickerOptionsNormal = this.datePickerSettingsService.GetDefaultOptions(this.alignRight);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.setPickerValue();
  }

  private setPickerValue() {
    if (!this.value) {
      this.myDateModel = {
        isRange: true,
        singleDate: null
      };

      return;
    }

    const date = this.getDateModel();

    if (date) {
      this.myDateModel = {isRange: false, singleDate: {date: date}};
    }
  }

  private getDateModel() {
    if (this.value.length >= 10) {
      const day = Number(this.value.substring(0, 2));
      const month = Number(this.value.substring(3, 5));
      const year = Number(this.value.substring(6, 10));

      return { year: year, month: month, day: day};
    }

    return null;
  }
}
