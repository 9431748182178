import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dealStatusesClass'
})
export class DealStatusesClassPipe implements PipeTransform {
  transform(value: string, args?: any): string {
      switch (value) {
        case "Completed":
            return "payment-paid";
        case "PayError":
            return "payment-pay-error";
        case "InProgress":
            return "payment-waiting";
        case "Canceled":
        case "Rejected":
        case "SmsLimitError":
        case "LimitedAttemptsEntryCode":
            return "payment-refusal";
        case "IsAcept":
        case "IsAceptAccountant":
        case "IsAceptAdmin":
            return "payment-approval";
        case "MinLimitError":
        case "MaxLimitError":
        case "DayLimitError":
        case "TransactionLimitError":
        case "LimitError":
        case "GoToCheckPay":
        case "ReadyForPay":
        case "New":
            return "payment-not-paid";
        case "KycError":
          return "payment-kyc-error";
    }

    return "";
  }
}
