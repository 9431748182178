<form  [formGroup]="codeForm" (ngSubmit)=" sendConfirmCode()"  *ngIf="codeForm" >
<section class="dropdown__section">
    <div class="dropdown__staff-settings-inpgroup">
        <div class="dropdown__staff-settings-inpgroup-item">
          <app-select-box-control
            id="id1538461008"
            [items]="listSendMethods"
            bindLabel="title"
            bindValue="key"
            placeholder="Выберите канал оповещений"
            formControlName="SendMethod"
            (change)="changeSendMethod($event)">

          </app-select-box-control>
        </div>
      </div>
      <ng-container *ngIf="showCode">
  <div class="dropdown__staff-settings-inpgroup">
    <div class="dropdown__staff-settings-inpgroup-item">
        <code-input formControlName="Code" [labelName]="'Код подтверждения'" [formCtrl]="codeForm.controls.Code"  ></code-input>
        <span class="report report--false report--valid" [ngClass]="{'report--active': codeForm.controls.Code?.hasError('minLength') == true}">*Минимальная длина поля составялет 6 символов.</span>
    </div>
  </div>
    <div class="dropdown__staff-settings-inpgroup">
      <div class="dropdown__staff-settings-inpgroup-item">
          <code-input formControlName="ReEnterCode" [labelName]="'Повторите код подтверждения'" [formCtrl]="codeForm.controls.ReEnterCode" ></code-input>
          <span class="report report--false report--valid" [ngClass]="{'report--active': codeForm.controls.ReEnterCode?.hasError('minLength') == true}">*Минимальная длина поля составялет 6 символов.</span>
        </div>
    </div>
    </ng-container>
     <div class="message report report--false text-center" *ngIf="codeForm.hasError('mismatch') ">Введенные значения не совпадают</div>
</section>
<div class="dropdown__footer">
    <button  [disabled]="codeForm.invalid" [ngClass]="{'btn--active': codeForm.valid == true}" type="submit" class="btn">Подтвердить</button>
</div>
</form>


