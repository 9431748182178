export function getSuccessMessage(newCode) {
    const action = newCode ? "изменен" : "сброшен";
    return `Код подтверждения успешно ${action}`;
}

export function getChannelNameMessageByCode(code: number) {
    const dictionary = new Map([
        [0, "СМС"],
        [1, "Электронная почта"],
        [2, "Фиксированный код"],
    ]);

    let channelName = dictionary.get(code);
    channelName = channelName || `Неизвестный канал (${code}`;

    const will = code == 0 ? "Будут" : "Будет";
    const prefix = `${will} использоваться`;
    const usePostfix = code != 2 && dictionary.has(code);
    const postfix = usePostfix ? "в качестве канала связи" : "";

    return `${prefix} ${channelName} ${postfix}`;
}

export const notifyTitle = 'Код подтверждения';