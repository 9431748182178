import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISystemService } from './interfaces/ISystemService';
import { BaseHttpService } from './base-http.service';
import { TimeZoneModel } from '../models/TimeZoneModel';

@Injectable({
  providedIn: 'root',
})
export class SystemService implements ISystemService {
  private readonly baseUrl = '/timezones'
  private static IsoCountryCodeMap = {
    'KAZ': 'KZ',
    'RUS': 'RU'
  }

  private static DefaultTimeZoneMap = {
    'KZ': 'Asia/Almaty',
    'RU': 'Europe/Moscow'
  }

  constructor(private baseHttpService: BaseHttpService) {
  }

  public countryCodeToIso2(countryCode: string): string {
    const upperCountryCode = countryCode?.toUpperCase();
    return SystemService.IsoCountryCodeMap[upperCountryCode] ?? upperCountryCode;
  }

  public getDefaultTimeZoneIdForCountry(countryCode: string): string {
    const countryCodeIso2 = this.countryCodeToIso2(countryCode);
    return SystemService.DefaultTimeZoneMap[countryCodeIso2];
  }

  private getDateUrl(date: Date) {
    return date.toISOString();
  }

  public getTimeZones$(date: Date, timeZoneIds: string[] = null): Observable<TimeZoneModel[]> {
    const dateUrl = this.getDateUrl(date);
    let url = `${this.baseUrl}/${dateUrl}`;
    if (timeZoneIds?.length > 0) {
      const timeZoneIdsStr = timeZoneIds.join();
      url = `${url}?timeZoneIds=${timeZoneIdsStr}`;
    }

    return this.baseHttpService.get(url);
  }

  public getTimeZonesByCountry$(countryCode: string, date: Date): Observable<TimeZoneModel[]> {
    const countryCodeIso2 = this.countryCodeToIso2(countryCode);
    const dateUrl = this.getDateUrl(date);
    return this.baseHttpService.get(`${this.baseUrl}/${dateUrl}/country/${countryCodeIso2}`);
  }
}
