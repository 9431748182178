import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPzuPoint } from '../../../models/points/IPzuPoint';
import { UserRoles } from '../../../models/auth/user-roles';

@Component({
  selector: 'tsuz-point-item',
  templateUrl: './point-item.component.html',
})
export class PointItemComponent implements OnInit {

  @Input() point: IPzuPoint;
  @Input() pointName: string;
  @Input() currency: string;
  @Input() isDirector = false;
  @Output() changePoint = new EventEmitter<IPzuPoint>();

  userRoles = UserRoles;
  checkLim = false;

  constructor() {
  }

  // Формирование меню точки
  ngOnInit() {
    this.point.isShowDropDown = false;
    this.point.isShowLimit = true;
    this.point.isShowUser = false;
    this.point.isShowSetting = false;
  }

  // открытие окна редактирования точки
  // showEditModal() {
  //   if (this.isDirector) {
  //     const namePoint = new CasePipe().transform(this.pointName, 'imcase');

  //     const disposable = this.simpleModalService.addModal(EditPointComponent, {
  //       title: `Редактирвоание "${namePoint}"`,
  //       pointName: namePoint,
  //       message: 'Confirm message',
  //       point: this.point,
  //       minLim: null
  //     }, { closeOnClickOutside: true })
  //       .subscribe((point) => {
  //         this.changePoint.emit(this.point as IPzuPoint);

  //       });
  //   }
  // }

}
