<div *ngIf="isNotEmpty"
     class="upblock upblock--flex-start select-licensor__block">
  Выберите лицензиата

  <app-select-box-control
    id="id1541669400"
    class="licensee-select"
    bindValue="id"
    bindLabel="title"
    placeholder="Выберите лицензиата"
    [(ngModel)]="value"
    [items]="licensees"
    (change)="handleChange($event)">
  </app-select-box-control>
</div>
