
import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { LicenseeService } from '@services/licensee.service';
import { LicenseeType, AuthService, UserRoles, ILicenseeSetting } from 'tsuz-common';
@Component({
  selector: 'app-licensee-settings',
  templateUrl: './licensee-settings.component.html'
})
export class LicenseeSettingsComponent implements OnInit {
  @ViewChildren("settingSelector") settingSelector: QueryList<any> | undefined;
  userRoles = UserRoles;
  isDefaultLicenseeType: boolean;
  showIndex: string;
  licenseeId: string;
  readonly: boolean = false;
  settingList: ILicenseeSetting;

  constructor(
    private authService: AuthService,
    @Inject("LOCALSTORAGE") private localStorage: any,
    @Inject("LicenseeService") private licenseeService: LicenseeService,
    ) { 
      this.licenseeId = this.localStorage.getItem('licenseeId');
  }

  async ngOnInit() {
    const res = this.authService.getLicenseeType();
    this.isDefaultLicenseeType = res === LicenseeType.Default;

    this.licenseeService.getSettings(this.licenseeId).subscribe(result => {
      this.settingList = result as ILicenseeSetting;
    }, error => {
      console.error(error);
    });
  }

  ngAfterViewInit(){
    this.selectFirstAvailableSetting();
  }

  selectFirstAvailableSetting() {
    this.showIndex = this.settingSelector.first.nativeElement.id;
  }
}
