<div class="calendar-icon"
     [attr.id]="iconId ? iconId : null"
     (click)="datepicker.toggleCalendar()">
</div>
<div class="input-group relative">
  <input
    [attr.id]="pluginInputId ? pluginInputId : null"
    class="d-none float-none form-control ng-clear-wrapper"
    placeholder="{{placeholder}}"
    angular-mydatepicker
    [options]="myDatePickerOptionsNormal"
    #datepicker="angular-mydatepicker"
    [(ngModel)]="myDateModel"
    (inputFieldChanged)="_onChange($event)"/>
</div>
