<tsuz-default-logo *ngIf="showDefault"></tsuz-default-logo>

<ng-container *ngIf="!showDefault">
<div class="custom-logo"[ngClass]="{'colapsed': showLogoForCollapsedMenu}">
  <img [src]="logoUrl" (error) = "logoLoadError = true; onResize()" (load)="onResize()"/>
</div>

<div class="custom-small-logo">
  <img [src]="mobileLogoUrl" (error) = "mobileLogoLoadError = true; onResize()" (load)="onResize()"/>
</div>
</ng-container>

