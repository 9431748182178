import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SortModel } from '../../../models/sort/sort.model';
import { SortType } from '../../../models/sort/sort-type.enum';


@Component({
  selector: 'app-sorting-header',
  templateUrl: './sorting-header.component.html',
  styleUrls: ['./sorting-header.component.scss']
})
export class SortingHeaderComponent implements OnInit {

  @Input()
  sortField: string;
  @Input()
  sort: SortModel;

  @Output()
  sortChange = new EventEmitter<SortModel>();
  SortType =  SortType;

  constructor() {
  }

  ngOnInit() {
  }

  onSortClick() {
    if(this.sort.fieldName != this.sortField) {
      this.sort.fieldName = this.sortField;
      this.sort.type = SortType.None;
    }
    this.sort.toggleSortType();
    this.sortChange.emit(this.sort);
  }
}
