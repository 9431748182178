import {Component, EventEmitter, Input, OnInit, Optional, Output, Renderer2, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {AbstractListValueAccessor} from '../abstract-base-controls/abstract-list-value-accessor';

@Component({
  selector: 'app-select-box-control',
  templateUrl: './select-box-control.component.html',
  styleUrls: ['./select-box-control.component.scss']
})
export class SelectBoxControlComponent extends AbstractListValueAccessor implements  OnInit {

  value: string;
  disabledValue: boolean;

  @Input()
  id: string;
  @Input()
  items: any[];
  @Input()
  bindValue: string;
  @Input()
  bindLabel: string;
  @Input()
  placeholder = 'Выберите элемент из списка';

  @Output()
  change = new EventEmitter<string>();

  ngControl: NgControl;

  constructor(@Optional() @Self() public ctrl: NgControl) {
    super();
    this.ngControl = ctrl;
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
  }

  handleChange(event: any) {
    this._onChange(this.value);
    this.change.emit(event);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledValue = isDisabled;
  }
}
