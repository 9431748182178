import { UploadedFile } from "./UploadedFile";
import { UploadedFileList } from "./UploadedFileList";


export class UploadedFileListChangedEvent {
  public addedfiles: UploadedFile[];
  public deletedfiles: UploadedFile[];
  public uploadedFileList: UploadedFileList;

  constructor(addedfiles: UploadedFile[], deletedfiles: UploadedFile[], uploadedFileList: UploadedFileList) {
    this.addedfiles = addedfiles;
    this.deletedfiles = deletedfiles;
    this.uploadedFileList = uploadedFileList;
  }
}
