<ng-container *ngTemplateOutlet="cellTemplate; context: { value: index, labelName: '№'}"></ng-container>
<ng-container *ngTemplateOutlet="cellTemplate; context: { value: organization.id, labelName: 'ИД' }"></ng-container>
<ng-container *ngTemplateOutlet="cellTemplate; context: { value: organization.inn, labelName: 'ИНН' }"></ng-container>
<ng-container *ngTemplateOutlet="cellTemplate; context: { value: organization.name, labelName: 'Название' }"></ng-container>
<ng-container *ngTemplateOutlet="cellTemplate; context: { value: organization.integrationType | integrationTypesDescription, labelName: 'Интеграция' }"></ng-container>
<ng-container *ngTemplateOutlet="cellTemplate; context: { value: organization.commission | nullableString, labelName: 'Комиссия' }"></ng-container>
<ng-container *ngTemplateOutlet="cellTemplate; context: { value: organization.totalSum |  money: organization.currency, labelName: 'Сумма операций' }"></ng-container>
<ng-container *ngTemplateOutlet="cellTemplate; context: { value: organization.paymentCount | nullableString, labelName: 'Количество оплат' }"></ng-container>

<ng-template #cellTemplate let-value="value" let-labelName="labelName">
  <div class="table__cell">
    <div class="table__data">
      <span class="label--mobile">{{labelName}}:</span >
      {{value}}
    </div>
  </div>
</ng-template>
