import { Component, Input } from '@angular/core';
import {ListFilterItemModel} from '../../../filters/list-filter-item.model';
import { FilterComponentBase } from '../filter-component-base';
import { FilterService } from '../../../services/filter.service';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-checkbox-filter.component.html',
  styleUrls: ['./list-checkbox-filter.component.scss']
})
export class ListCheckboxFilterComponent extends FilterComponentBase<string[]> {
  _items: ListFilterItemModel[] = [];

  @Input()
  get items(): ListFilterItemModel[] {
    return this._items;
  }

  set items(value: ListFilterItemModel[]) {
    this._items = value;
    this.initCheckboxes();
  }

  protected getDefaultModelValue(): string[] {
    return [];
  }

  protected onModelChanging(value: string[]): string[] {
    const newValue = super.onModelChanging(value);
    let result: string[];
    if (newValue) {
      result = Array.isArray(newValue) ? newValue : String(newValue).split(',');
    } else {
      result = this.getDefaultModelValue();
    }

    return result;
  }

  protected onModelChanged(previousValue: string[], currentValue: string[]) {
    this.initCheckboxes();
    super.onModelChanged(previousValue, currentValue);
  }

  constructor(filterService: FilterService) {
    super(filterService);
 }

  get isAll(): boolean {
    return this.items && (this.items.filter(x => x.checked).length === this.items.length);
  }

  editState(i: number) {
    this.items[i].checked = !this.items[i].checked;
  }

  allCheck() {
    const checked = !this.isAll;
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].checked = checked;
    }
  }

  protected getModelChangeEventValue(): string[] {
    const result = this.items.filter(x => x.checked).map(x => x.value);
    return result;
  }

  onCancel() {
    const wasModal = this.isShowModal;
    super.onCancel();
    if (wasModal) {
      this.initCheckboxes();
    }
  }

  initCheckboxes() {
    if (!this.items || this.items.length < 1) {
      return;
    }

    if (!this.model || this.model.length < 1) {
      this.items.forEach(item => {
        item.checked = false;
      });

      return;
    }

    this.items.forEach(item => {
      item.checked = this.model.findIndex(x => String(x) === String(item.value)) > -1;
    });
  }
}
