<input
  [attr.id]="inputId ? inputId : null"
  type="text"
  class="input flex-shrink-max"
  autocomplete="bday"
  mask="00.00.0000"
  [(ngModel)]="value"
  (input)="onChangeInput()"
  [dropSpecialCharacters]="false"
  [ngClass]="{
                'input--nonvalidate': control.invalid,
                'input--validate':control.valid
              }"
  [placeholder]="placeholder"
  [focusPlaceholder]="'ДД.ММ.ГГГГ'"/>
<app-date-input-component
  iconId="{{iconId}}"
  pluginInputId="{{pluginInputId}}"
  [(ngModel)]="value"
  (ngModelChange)="onDateChanged($event)">
</app-date-input-component>
