import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { saveAs } from 'file-saver/FileSaver';
import { ToastrService } from 'ngx-toastr';
import { ScanType } from '../../../../core/enums/scan-type.enum';
import { LoaderService } from 'tsuz-common';
import { DealService } from '@services/deal.service';
import { ScanDocumentsService } from '@services/scan-documents.service';
import {ISupportDeal} from '../../../../models/supportdeal/IPageSupportDeal';

@Component({
  selector: '[support-deal-item]',
  templateUrl: './support-deal-item.component.html'
})
export class SupportDealItemComponent implements OnInit {
  @Input() deal: ISupportDeal;
  @Input() index: number;
  @Input() columns: string[] = [];
  @Output() dealIdInfo = new EventEmitter<string>();
  @Output() payInfo = new EventEmitter<string>();

  constructor(
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private dealService: DealService,
    private scanDocumentsService: ScanDocumentsService) { }

  ngOnInit() {
  }

  columnIsVisible(id: string): boolean{
    return this.columns.some(x => x === id);
  }

  dealInfoEmit() {
    this.dealIdInfo.emit(this.deal.id);
  }

  payInfoEmit() {
    this.payInfo.emit(this.deal.id);
  }

  getScanDocumentDescription(type: ScanType): string {
    return this.scanDocumentsService.getScanDocumentDescription(type);
  }

  async downloadScanDocumentAsync(id: string, type: ScanType) {
    this.loaderService.displayFullWidth(true);

    try {
      const result = await this.dealService.downloadFile(id, type).toPromise();
      saveAs(result.data.body || result.data, result.filename);

    } catch (error) {
      this.toastr.error('Не удалось загрузить документ', 'Ошибка',
      {
        closeButton: true,
        progressBar: true
        });
    } finally {
      this.loaderService.displayFullWidth(false);
    }
  }
}
