import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsuz-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {

  @Input() text: string = 'Добавить строку';

  constructor() {
  }

  ngOnInit(): void {
  }
}
