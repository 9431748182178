import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'tsuz-default-logo',
  templateUrl: './default-logo.component.html'
})
export class DefaultLogoComponent implements OnInit {

  title: string;

  constructor(@Inject('environment') environment: any) { 
    this.title = environment.title;
  }

  ngOnInit(): void {
  }

}
