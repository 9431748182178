import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { IUser } from '../../../models/IUser';
import { AuthService } from '../../../services/auth.service';
import { UserRoles } from '../../../models/auth/user-roles';
import { IUsersService as IUsersService } from '../../../services/interfaces/IUsersService';
import { NotificationService } from '../../../services/notification.service';
import { changeFormGroupDisabledState } from '../../../helpers/form.helper';

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
  hasOne: boolean;
}

@Component({
  selector: '[edit-role]',
  templateUrl: './edit-role.component.html'
})
export class EditRoleComponent implements ConfirmModel, OnInit {
  @Input() licenseeId: string
  @Input() user: IUser;
  @Input() userMainRole: string;
  @Input() readonly: boolean = false;

  isDirector: boolean;
  isExecutiveOfficer: boolean;
  roleForm: FormGroup;
  roleArray: FormArray;
  hasOne: boolean;
  title: string;
  message: string;
  roleList: any[];
  errorMsg: string;

  constructor(
    @Inject('UsersService') private readonly usersService: IUsersService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    // Определение прав просмотра
    this.isDirector = this.authService.inRole(UserRoles.Administrator);
    this.isExecutiveOfficer = this.authService.inRole(UserRoles.ExecutiveOfficer);

    // Создание формы
    this.createForm();
    // Проверка активности кнопки для отправки
    this.startCheck();
  }

  // Получение главной роли Сотрудника(самой высокой по приеритету просмотра)
  compareRoles() {
    const empMainRole = this.authService.getMainRole(this.user.roles);
    return this.authService.showEditRole(this.userMainRole, empMainRole);
  }

  // Определение прав просмотра
  isAdmin() {
    const adminIndex = this.user.roles.findIndex(x => x === UserRoles.Administrator || x === UserRoles.ExecutiveOfficer);
    this.user.hideRoles = adminIndex > -1;
    return adminIndex > -1;
  }

  isFinDirector(): boolean {
    const finFirIndex = this.user.roles.findIndex(x => x === UserRoles.FinancialDirector);
    this.user.hideRoles = finFirIndex > -1;
    return finFirIndex > -1;
  }

  startCheck() {
    if (this.user.roles.length === 1) {
      this.hasOne = (this.user.roles.length === 1);
    }
  }

  checkValid(num: Number) {
    if (this.roleForm) {
      for (let i = 0; i < this.roleArray.length; i++) {
        if (i !== num) {
          this.roleArray.controls[i].setValue(false);
        }
      }
      this.hasOne = (this.roleForm.value.roles.filter(x => x).length === 1);
    }
  }

  createForm() {
    this.roleList = this.authService.getAllRoles().filter(x => x !== UserRoles.Support);
    // Создаем политику отображения ролей
    if (!this.isExecutiveOfficer) {
      this.roleList = this.roleList.filter(x => x !== UserRoles.FinancialDirector && x !== UserRoles.Administrator);
    }
    if (!this.isDirector && !this.isExecutiveOfficer) {
      this.roleList = this.roleList.filter(x => x !== UserRoles.Administrator && x !== UserRoles.ManagerPlus);
    }

    this.roleArray = new FormArray([]);
    const arrCheckBoxs = this.roleList.map(role => {
      return new FormControl(this.user.roles.indexOf(role) > -1);
    });
    for (let i = 0; i < arrCheckBoxs.length; i++) {
      this.roleArray.push(arrCheckBoxs[i]);
    }
    this.roleForm = new FormGroup({
      roles: this.roleArray
    });

    changeFormGroupDisabledState(this.roleForm, !this.readonly);
  }

  // Отправка данных
  toPost() {
    if (this.hasOne && this.roleForm) {
      const newRole = [];
      let newRoleGetPoints = false;
      for (let i = 0; i < this.roleList.length; i++) {
        newRole.push(
          {
            roleId: this.roleList[i],
            state: this.roleForm.value.roles[i]
          }
        );
        if (this.roleList[i] === UserRoles.Administrator || this.roleList[i] === UserRoles.Accountant) {
          newRoleGetPoints = this.roleForm.value.roles[i];
        }
      }
      this.usersService.addUserRole(this.licenseeId, this.user.id, newRole).subscribe(data => {
        this.notificationService.successNotify(
          `Роль ${this.user.surname} ${this.user.name} успешно обновлена`, 'Роль обновлена');
        this.user.roles = newRole.filter(r => r.state).map(r => r.roleId);
        this.isAdmin();
      },
        error => {
          this.notificationService.errorNotify(
            `Возникла ошибка при обновлении роли ${this.user.surname} ${this.user.name}`, 'Роль не обновлена');

          this.errorMsg = error;
        }
      );
    }
  }
}
