import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivationRequestFilter} from '../../models/prepaid/activation-request-filter';
import {BaseHttpService} from 'tsuz-common';

@Injectable()
export class ActivationRequestService {

  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  get(filter: ActivationRequestFilter): Observable<any> {
    return this.baseHttpService.get('/ActivationRequests?' + filter.getQueryString());
  }
}
