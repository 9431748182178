<div class="widgets-component">
  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'CashpoolingAd'"
  [style.display]="hideCashpoolingWidget ? 'none' : 'flex'">
  </app-widget-container>

  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'Holding'">
  </app-widget-container>

  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'MoneyTurnover'">
  </app-widget-container>
  
  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'Deals'">
  </app-widget-container>
  
  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'RnkoRegister'">
  </app-widget-container>
    
  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'DealsInProcessing'">
  </app-widget-container>
  
  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'Limits'">
  </app-widget-container>

  <app-widget-container [widthKey]="'normal'" [widgetTypeKey]="'ProductsIncoming'">
  </app-widget-container>

</div>
