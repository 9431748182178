import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError as observableThrowError, BehaviorSubject, timer } from 'rxjs';
import { Profile, AuthService, getLoginMask, getPhoneMask, passwordMaxLength, passwordMinLength } from 'tsuz-common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, AfterViewInit {
  // СОздание события для фокуса на строке ввода телефона
  @ViewChild('autofocus') autofocusElement: ElementRef;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  loginForm: FormGroup;
  phone: FormControl;
  password: FormControl;
  approvedForm: FormGroup;
  code: FormControl;
  passForm: FormGroup;
  newPass: FormControl;
  newReEnterPass: FormControl;
  apiUrl = '';
  sessionId: string;
  isApproved = false;
  isTemp = false;
  isError: boolean;
  isAuthRedirect: boolean;
  redirectUrl: string;
  isErrorApproved = false;
  isErrorPass: boolean;
  isErrorPassPattern: boolean;
  errorMessage: string;
  errorMessageApproved: string;
  errorPassMessage: string;
  errorsMessages: Object;
  profile: Profile;
  secondTxt: String;
  timerStopped = false;
  timemerSubscribe: any;
  // backdoor для тестирования отображения валидации с сервера, чтобы отключать клиентскую валидацию (устанвоить в false для отключения клиентской валидации)
  onlyValitadedFormSend = true;
  maxAttemptsCountExceeded: boolean = false;
  // События поведения элемента авторизации
  private authNavStatusSource = new BehaviorSubject<boolean>(false);
  private authNavStatus$ = this.authNavStatusSource.asObservable();
  private phoneOrLoginModel: string;
  private inputtedSymbolsCount: number = 1;
  title: string;
  
  readonly passwordMinLength = passwordMinLength;
  readonly passwordMaxLength = passwordMaxLength;
  
  @ViewChild('phoneOrLoginInput') phoneOrLoginInput: ElementRef;

  constructor(private readonly authService: AuthService,
    private readonly router: Router,
    @Inject('environment') environment: any,
    @Inject('LOCALSTORAGE') private localStorage: any) {
    // Создание формы для логина и ввода смс
    this.createFormControls();
    this.createForms();
    this.apiUrl = environment.apiUrl;
    this.title = environment.title;
    this.isErrorPassPattern = false;
  }

  ngOnInit() {
    // Формирование паттерна для телефона
    this.isErrorPassPattern = true;
    this.loginForm.get('PhoneOrLogin').valueChanges.subscribe(raw => {
      this.phoneOrLoginModel = this.loginForm.get('PhoneOrLogin').value;
      if (this.phoneOrLoginModel[0] !== '+') {
        this.isErrorPassPattern = true;
        return;
      }
      this.phoneOrLoginModel = this.phoneOrLoginModel.replace('-', '');
      this.phoneOrLoginModel = this.phoneOrLoginModel.replace(/\D/g, '');
      this.phoneOrLoginModel = this.phoneOrLoginModel.replace(/\s/g, '');

      // если не заполнены все плейсхолдеры
      if (!raw.includes('_')) {
        this.isErrorPassPattern = true;
      } else {
        this.isErrorPassPattern = false;
      }
    });
  }

  ngAfterViewInit() {
    this.phoneOrLoginInput.nativeElement.selectionStart = this.inputtedSymbolsCount;
    this.phoneOrLoginInput.nativeElement.selectionEnd = this.inputtedSymbolsCount;
  }

  changeMaskForTesting() {
    const loginMask: RegExp[] = [];
    for (let i = 0; i < 11; i++){
      loginMask.push(/[A-Za-z0-9А-Яа-я]/);
    }
    return loginMask;
  }

  changeMask(rawValue: string): (string | RegExp)[] {
    // вход по телефону
    if (rawValue[0] === '+') {
      return getPhoneMask(rawValue);
    }

    // вход по логину
    return getLoginMask(rawValue);
  }

  onChangeInput(rawValue : string) {
    this.setLastInputtedNumberIndex(rawValue);
  }

  setLastInputtedNumberIndex(inputtedPhone) {
    let re = /\d/g;
    var lastNumberIndex = re.lastIndex;
    while (re.test(inputtedPhone)) {
      lastNumberIndex = re.lastIndex;
    }
    this.inputtedSymbolsCount = lastNumberIndex;
  }

  onFocusGet() {
    this.phoneOrLoginInput.nativeElement.selectionStart =this.inputtedSymbolsCount;
    this.phoneOrLoginInput.nativeElement.selectionEnd = this.inputtedSymbolsCount;
  }

  createFormControls() {
    this.phone = new FormControl('+', Validators.required);
    // +7(000)000-00-00
    this.password = new FormControl('', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]);
    this.code = new FormControl('', [Validators.minLength(4), Validators.maxLength(8)]);
    this.newPass = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]);
    this.newReEnterPass = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]);
  }

  createForms() {
    this.loginForm = new FormGroup({
      PhoneOrLogin: this.phone,
      Password: this.password
    });
    this.approvedForm = new FormGroup({
      Code: this.code
    });
    this.passForm = new FormGroup({
      Pass: this.newPass,
      ReEnterPass: this.newReEnterPass,
    }, this.passwordMatchValidator);
  }

  // Проверка паролей на совпадение при обновлении из временного пароля
  passwordMatchValidator(g: FormGroup) {
    return g.get('Pass').value === g.get('ReEnterPass').value
      ? null : { 'mismatch': true };
  }

  get passCtrl() : FormControl {
    return this.passForm.get('Pass') as FormControl;
  }

  get reenterPassCtrl() : FormControl {
    return this.passForm.get('ReEnterPass') as FormControl;
  }

  // Отправка данных
  onSubmit() {
    const isPossibleToSendForm = this.onlyValitadedFormSend ?
      this.loginForm.valid &&
      this.isErrorPassPattern :
      true;
    if (isPossibleToSendForm) {
      this.errorsMessages = null;
      this.authService
        .createSession(this.phoneOrLoginModel, this.loginForm.value.Password, this.handleError)
        .subscribe(result => {
          if (!result.sessionId) {
            return;
          }
          this.sessionId = result.sessionId;
          this.authService.setSessionId(this.sessionId);
          this.authService.setLicenseeType(result.licenseeType);
            this.authService.sendOtp().subscribe(resultOtp => {
              this.isApproved = true;
              this.initTimer(resultOtp.timeout);

          }, error => {
            this.isError = true;
            this.errorMessage = error.message;
          });
        }, error => {
          if (error.status === 423) {
            this.isAuthRedirect = true;
            this.redirectUrl = error.model.redirectUrl;
            return;
          }
          this.isError = true;
          this.errorMessage = error.message;
          this.errorsMessages = error.fields || {};
        });
    }
  }

  // Генерация кода смс
  sendCode() {
    this.approvedForm.reset();
    this.authService.sendOtp().subscribe(resultOtp => {
      this.initTimer(resultOtp.timeout);
    }, error => {
      this.errorMessageApproved = error.message;
    });
  }

  // Инициализация таймера для ввода смс
  initTimer(startInSeconds) {
    if (!this.isTemp) {
      this.timerStopped = false;
      startInSeconds = startInSeconds + 1; // компенсируем последнюю секунду
      let currentMin = Math.floor(startInSeconds / 60);
      let currentSecond = startInSeconds - currentMin * 60;
      // Подпись на события таймера
      this.timemerSubscribe = timer(0, 1000).subscribe(t => {
        this.autofocusElement.nativeElement.focus();
        if (currentSecond === 0) {
          currentMin--;
          currentSecond = 59;
        }
        currentSecond--;
        if (currentMin <= 0 && currentSecond <= 0) {
          this.stopTimer();
        }
        this.secondTxt = `${currentMin}:${currentSecond < 10 ? '0' + currentSecond : '' + currentSecond}`;
      });
    }
  }

  // Остановка таймера
  stopTimer() {
    this.timemerSubscribe.unsubscribe();
    this.timerStopped = true;
    this.maxAttemptsCountExceeded = false;
  }

  // Отправка кода смс
  onSubmitCode() {
    const isPossibleToSendForm = this.onlyValitadedFormSend ?
      this.approvedForm.valid :
      true;
    if (isPossibleToSendForm) {
      this.authService.verifySessionThroughOtp(this.approvedForm.value.Code).subscribe(resultFinish => {
          this.authService.getProfile().subscribe(profileResult => {
            this.profile = profileResult as Profile;
            this.localStorage.setItem('userId', this.profile.id);
            this.localStorage.setItem('licenseeId', this.profile.licensee.id);
            if (!this.profile.isTemporaryPassword) {
              this.localStorage.setItem('currentRoles', this.profile.roles);
              this.localStorage.setItem('currency', this.profile.licensee.currency);
              this.localStorage.setItem('country', this.profile.licensee.country);
              this.localStorage.setItem('isAuth', true);
              this.authService.authNavStatusSource.next(true);
              this.router.navigate([this.authService.getHomePage()]);
              this.isApproved = false;
            } else {
              this.stopTimer();
              this.isApproved = false;
              this.isTemp = this.profile.isTemporaryPassword;
            }
          }, error => {
            this.isError = error;
            this.errorMessage = error.message;
          });
      }, error => {
        this.errorMessageApproved = error.message;
        this.maxAttemptsCountExceeded = error.code === 422;
        this.isErrorApproved = !this.maxAttemptsCountExceeded;
      });
    }
  }

  // Валидация пароля
  onSubmitPass() {
    const isPossibleToSendForm = this.onlyValitadedFormSend ?
      this.passForm.valid :
      true;
    if (isPossibleToSendForm) {
      this.authService.changePassword(this.passForm.value.Pass)
        .subscribe(resultOtp => {
          this.localStorage.setItem('currentSession', this.sessionId);
          this.localStorage.setItem('currentRoles', this.profile.roles);
          this.localStorage.setItem('currency', this.profile.licensee.currency);
          this.localStorage.setItem('isAuth', true);
          this.authService.authNavStatusSource.next(true);
          this.router.navigate([this.authService.getHomePage()]);
        }, error => {
          const message = error.errors && Object.keys(error.errors).some(x => x) 
            ? Object.values(error.errors).map((e: any) => e.join('.')) : error.message;
          this.isErrorPass = true;
          this.errorPassMessage = message;
        });
    }
  }

  onBackToLoginPage() {
    this.isAuthRedirect = false;
  }

  handleError(error: HttpErrorResponse) {
    const errorObject = {
      status: error.status,
      message: error.error.message,
      fields: error.error.fields,
      model: error.error
    };

    return observableThrowError(errorObject);
  }
}
