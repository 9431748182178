<div class="licensee-settings" *ngIf="settingForm">
  <form id="id1538395961" class="licensee-settings__form" [formGroup]="settingForm" (ngSubmit)="onSubmit()">
    <div class="licensee-settings__wrapper">
      <div id="id1538395990" class="licensee-settings__block">
        <ng-container *ngIf="settingList.licenseeType === 'Default'">
          <app-toggle formControlName="HideDealProduct" id="id1560864402" title="{{settingList.hideDealProductTitle}}"></app-toggle>
          <app-toggle formControlName="HideTrashPercent" id="id1560864390" title="{{settingList.hideTrashPercentTitle}}"></app-toggle>
          <app-toggle formControlName="HideUnit" id="id1560864366" title="{{settingList.hideUnitTitle}}"></app-toggle>
          <app-toggle formControlName="HideWeight" id="id1560864290 " title="{{settingList.hideWeightTitle}}"></app-toggle>
          <app-toggle formControlName="OnlyIdenticalProduct" id="id1560864277 "  title="{{settingList.onlyIdenticalProductTitle}}"></app-toggle>
          <app-toggle formControlName="SendReceipt" id="id1560864279 " title="{{settingList.sendReceiptTitle}}"
            (change)="onSendReceiptChange($event)"></app-toggle>
          <app-toggle formControlName="DisableLicenseeBalance" id="id1560864278 "  title="{{settingList.disableLicenseeBalanceTitle}}"></app-toggle>
        </ng-container>
      </div>
    </div>
    <button id="id1538396439" value="Submit" class="btn" [ngClass]="{'btn--active':settingForm.valid}" [disabled]="settingForm.invalid" type="submit">
      Сохранить
    </button>
  </form>
</div>