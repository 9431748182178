<!--Default-->
<div class="payment-modal__passport-info-block__inputs-item" *ngIf="licenseeType=='Default'">
    <ng-select 
        class="rounded-dropdown"
        [items]="citizenshipsList"
        bindLabel="title"
        bindValue="value"
        (change)="onCitizenshipChanged($event)"
        [(ngModel)] = "selectedCitizenshipModel"
        >
    </ng-select>
</div>
<div class="payment-modal__passport-info-block__inputs-item" *ngIf="licenseeType=='Default'">
    <input type="text"
    class="input"
    [ngClass]="{
        'input--nonvalidate': hasValidationError == true,
        'input--validate': hasValidationError == false
      }"
    [(ngModel)]="passportNumberModel"
    [placeholder]="placeholder"
    mask="{{mask}}"
    [dropSpecialCharacters]="false"
    (ngModelChange)="onPassportNumberChanged($event)"
    />
</div>

<!--CarPrice-->
<div class="requisites__item-wr passport-info-input-width-46"  *ngIf="licenseeType=='CarPrice'">
    <div class="requisites__item">
        <label class="label requisites__label">Гражданство</label>
        <div class="requisites__select">
            <ng-select 
                class="rounded-dropdown"
                [items]="citizenshipsList"
                bindLabel="title"
                bindValue="value"
                (change)="onCitizenshipChanged($event)"
                [(ngModel)] = "selectedCitizenshipModel"
                >
            </ng-select>
        </div>
    </div>
    <validation-errors-output
                [errorsDictionary]="errorMessages"
                [fieldName]="'country'"
                >
    </validation-errors-output>
</div>

<div class="requisites__item-wr passport-info-input-width-46" *ngIf="licenseeType=='CarPrice'">
    <div class="requisites__item">
        <label class="label requisites__label" [ngClass]="{'empty': passportNumberIsRequired === true}">
        Номер и серия паспорта
        </label>
        <input type="text"
        class="input"
        [ngClass]="{
            'input--nonvalidate': hasValidationError == true,
            'input--validate': hasValidationError == false
        }"
        [(ngModel)]="passportNumberModel"
        [placeholder]="placeholder"
        mask="{{mask}}"
        [dropSpecialCharacters]="false"
        (ngModelChange)="onPassportNumberChanged($event)"
        />
    </div>
    <validation-errors-output
    [errorsDictionary]="errorMessages"
    [fieldName]="'passportseries'"
    ></validation-errors-output>
</div>