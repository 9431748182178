import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'tsuz-common';
import { IPlatformBindingRequest } from './platform-binding-request.model';
import { Paginated } from '../../core/models/paginated';
import { PlatformBindingRequestFilter } from './platform-binding-request-filter.model';

@Injectable({
  providedIn: 'root'
})
export class PlatformBindingRequestService {
  constructor(private baseHttpService: BaseHttpService) { }

  getRequests(filter: PlatformBindingRequestFilter): Observable<Paginated<IPlatformBindingRequest[]>> {
    return this.baseHttpService.get(`/api/platformBindingRequests?${filter}`);
  }

  checkState(id: string): Observable<IPlatformBindingRequest> {
    return this.baseHttpService.post(`/api/platformBindingRequests/${id}/updateState`, null);
  }

  unbind(id: string): Observable<IPlatformBindingRequest> {
    return this.baseHttpService.post(`/api/platformBindingRequests/${id}/unbind`, null);
  }

  createRequest(inn: string, phone:string, email:string): Observable<IPlatformBindingRequest> {
    return this.baseHttpService.post(`/api/platformBindingRequests`, { 'inn': inn, 'phone':phone,'email':email });
  }
}
