import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserPermissions } from '../user-permissions';
import { PlatformBindingRequestListComponent } from './platform-binding-request-list/platform-binding-request-list.component';
import { AuthGuard } from 'tsuz-common';

const routes: Routes = [
  {
    path: 'platform-binding-requests-list',
    component: PlatformBindingRequestListComponent,
    data: UserPermissions.dataPermissionsNotSupportRoles,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelfEmployedRoutingModule { }
