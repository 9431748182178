import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IN } from 'angular-mydatepicker';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss', '../holding-structure.component.scss'],
  animations: [
    trigger('contentTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
  
export class WaitingComponent implements OnInit{
  @Input()
  showSpinner: boolean = true;

  @Input()
  errorHasHappened: boolean = false;

  @Input()
  errorMessage: string;

  @Input()
  delay: number;

  constructor() { }

  ngOnInit() {
  }
}
