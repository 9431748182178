export enum CodeFnsForSelfEmployed {
  None = 0,
  /// <summary>
  /// Не отвечает.
  /// </summary>
  ApiNotResponding,
  /// <summary>
  /// Партнер не привязан к налогоплательщику.
  /// </summary>
  TaxpayerUnbound,
  /// <summary>
  /// Снят с учета или не обнаружен.
  /// </summary>
  /// <remarks>
  /// (пользователь в этом случае может создать новую платежку и провести как не самозанятого)
  /// </remarks>
  TaxpayerUnregistered,
  /// <summary>
  /// Партнеру отказано в регистрации дохода для НП НПД.
  /// </summary>
  /// <remarks>
  ///(по идее, такой не словим)
  /// </remarks>
  PartnerDeny,
  /// <summary>
  /// Такой доход уже был зарегистрирован
  /// </summary>
  /// <remarks>
  /// (Key: RECEIPT_URL, Value: ID уже зарегистрированного дохода.)
  /// </remarks>
  Duplicate,
  /// <summary>
  /// У партнера нет прав на регистрацию дохода.
  /// </summary>
  /// <remarks>
  /// (по идее, такой не словим; в этом случае требуется перепривязка)
  /// </remarks>
  PermissionNotGranted,
  /// <summary>
  /// Чек с таким ID не зарегистрирован
  /// </summary>
  ReceiptIdNotFound,
  /// <summary>
  /// Чек не может быть принят, так как у него не корректный фискальный признак.
  /// </summary>
  InvalidHash,
  /// <summary>
  /// Для получения фискального признака был использован не валидный номер последовательности.
  /// </summary>
  InvalidSequence
}
