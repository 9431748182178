import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationFilter} from '../../filters/pagination-filter';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
   _model: PaginationFilter;

  @Output()
  modelChange = new EventEmitter<PaginationFilter>();

  @Input()
  get model(): PaginationFilter {
    return this._model;
  }
  set model(value: PaginationFilter) {
    this._model = value;
  }

  @Output()
  apply = new EventEmitter();

  constructor() {}

  ngOnInit() {
  }

  onApply(key?: string) {
    this.modelChange.emit(this._model);
    this.apply.emit();
  }

  nextPage() {
    this._model.page = Number(this.model.page) + 1;
    this.onApply('page');
  }

  prevPage() {
    this._model.page = Number(this.model.page) - 1;
    this.onApply('page');
  }
}
