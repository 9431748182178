import {Component, ElementRef, forwardRef, Renderer2, ViewChild} from '@angular/core';
import { AbstractTextInput } from '../abstract-base-controls/abstract-text-input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'code-input',
  templateUrl: './code-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodeInputComponent),
    multi: true,
  }]
})

export class CodeInputComponent extends AbstractTextInput {
}
