import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SystemDictionaryService} from '../../../services/system-dictionary-service';
import { IUser } from '../../../models/IUser';
import { NotificationService } from '../../../services/notification.service';
import { IUserAvailableResources } from '../../../models/user-settings/IUserAvailableResources';
import { ISystemDictionary } from '../../../models/ISystemDictionary';
import { ChangeAvailableResources } from '../../../models/user-settings/change-availableResources';
import { IUserSettingService } from '../../../services/interfaces/IUserSettingService';
import { changeFormGroupDisabledState } from '../../../helpers/form.helper';

@Component({
  selector: '[edit-permissions]',
  templateUrl: './edit-permissions.component.html',
})
export class EditPermissionsComponent implements OnInit {
  @Input() user: IUser;
  @Input() readonly: boolean = false;

  form: FormGroup;
  userAvailableResources: IUserAvailableResources;
  systemDictionary: ISystemDictionary;

  constructor(private formBuilder: FormBuilder,
              @Inject('UserSettingsService') private userSettingService: IUserSettingService,
              private systemDictionaryService: SystemDictionaryService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      availableResources: new FormArray([])
    });

    this.getSystemDictionaries();
  }

  getAvailableResources() {
    this.userSettingService.getUserAvailableResources(this.user.id).subscribe(result => {
      this.userAvailableResources = result as IUserAvailableResources;

      this.addCheckboxes();
    });
  }

  getSystemDictionaries() {
    this.systemDictionaryService.getSystemDictionaries().subscribe(result => {
      this.systemDictionary = result as ISystemDictionary;

      this.getAvailableResources();
    });
  }

  addCheckboxes() {
    this.systemDictionary.availableResources.forEach((o, i) => {
        const control = new FormControl(this.userAvailableResources && this.userAvailableResources.availableResources ?
          this.userAvailableResources.availableResources.indexOf(o.key) > -1 : false);
        (this.form.controls.availableResources as FormArray).push(control);
    });

    if (this.readonly) {
      changeFormGroupDisabledState(this.form, !this.readonly);
    }
  }

  submit() {
    const model = new ChangeAvailableResources();

    const selectedValues = this.form.value.availableResources
      .map((v, i) => v ? this.systemDictionary.availableResources[i].key : null)
      .filter(v => v !== null);
    model.availableResources = selectedValues;

    this.userSettingService.changeUserAvailableResourcesPermissions(this.user.id, model).subscribe(result => {
      this.notificationService.successNotify('Уведомление', 'Данные успешно изменены');
    }, error => {
      this.notificationService.errorNotify('Уведомление', 'Ошибка изменения данных');
    });
  }
}
