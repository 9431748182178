import { Component, OnInit, Input, Inject } from "@angular/core";
import { SimpleModalComponent } from "ngx-simple-modal";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IUser } from '../../../../models/IUser';
import { IShortPoint } from '../../../../models/points/IShortPoint';
import { NotificationService } from '../../../../services/notification.service';
import { IUserSettingService } from "../../../../services/interfaces/IUserSettingService";
import { getChannelNameMessageByCode, getSuccessMessage, notifyTitle } from "../change-code-messages";

interface ConfirmModel {
  title: string;
  message: string;
  user: IUser;
  newCode: string;
}

@Component({
  selector: "app-change-confirm",
  templateUrl: "./change-confirm.component.html",
  styleUrls: ['./change-confirm.component.scss'],
})
export class ChangeCodeConfirmComponent
  extends SimpleModalComponent<ConfirmModel, IShortPoint>
  implements ConfirmModel, OnInit {
  codeForm: FormGroup;
  newCode: string;
  user: IUser;
  title: string;
  message: string;
  confirmCode: FormControl;
  newCodeDesc: string;
  showChannelNotification: boolean = false;

  constructor(
    private notifyService: NotificationService, 
    @Inject('UserSettingsService') private userSettingService: IUserSettingService,
    ) {
    super();
  }

  // Создание формы
  ngOnInit() {
    if (this.newCode) {
      this.newCodeDesc = 'код подтверждения будет изменен на ' + this.getHidenNewCode();
    } else {
      this.newCodeDesc = 'будет сброшен код подтверждения ';
      this.showChannelNotification = true;
    }
    this.createForm();
  }

  getHidenNewCode() {
    if (!this.newCode) {
      return;
    }

    const codeLength = this.newCode.length;
    const firstLetter = this.newCode.charAt(0);
    const lastLetter = this.newCode.charAt(codeLength - 1);

    const hidenLettersCount = codeLength - 2;
    const hidenLetters = "*".repeat(hidenLettersCount);

    return `${firstLetter}${hidenLetters}${lastLetter}`;
  }

  createForm() {
    this.codeForm = new FormGroup(
      {
        ConfirmCode: new FormControl('', [Validators.required]),
      }
    );
  }

  async confirm() {
    if (this.codeForm.valid) {
      try {
        await this.userSettingService.changeCode(this.codeForm.value.ConfirmCode, this.user.id, this.newCode).subscribe(
          result => {
            this.notifyService.successNotify(getSuccessMessage(this.newCode), notifyTitle);
            const message = getChannelNameMessageByCode(result.actualChannel);
            this.notifyService.warningNotify(message, notifyTitle);
            this.codeForm.reset();
            this.close();
          },
          error => {
            this.notifyService.errorNotify(error , notifyTitle);
          }
       );
      } catch (error) {
        this.notifyService.errorNotify(error , notifyTitle);
      }
    }
  }

}
