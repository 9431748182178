<div class="products-tree">
    <ng-container *hasPermission="[[userRoles.ExecutiveOfficer, userRoles.Administrator]]">
        <div *ngIf="!readonly" class="row">
            <button class="payment-modal__psa-block__attach-button" (click)="downloadProducts()">
                <img src="/img/download.svg"/> Выгрузить
            </button>
            <button class="payment-modal__psa-block__attach-button" (click)="openUploadProductsModal()">
                <img src="/img/attach.svg"/> Загрузить
            </button>
        </div>
    </ng-container>
        
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

        <!-- Добавление новых елементов -->
        <ng-container *ngIf="!readonly">
            <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
                <!-- выравнивание -->
                <button mat-icon-button class="hidden">
                    <mat-icon class="mat-icon-rtl-mirror">
                        expand_more
                    </mat-icon>
                </button>

                <ng-container [ngSwitch]="node.level">
                    <ng-container *ngSwitchCase="0">
                        <mat-form-field class="col-4">
                            <mat-label>Название категории</mat-label>
                            <input matInput #itemValue 
                                (focusout)="saveNode(node, itemValue.value)"
                                (keyup.enter)="saveNode(node, itemValue.value)"
                                [matAutocomplete]="categoryAuto">

                                <mat-autocomplete #categoryAuto="matAutocomplete">
                                    <mat-option [value]="defaultCategory">{{defaultCategory}}</mat-option>
                                </mat-autocomplete>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                        <div class="col-4 row">
                            <mat-form-field class="col-8">
                                <mat-label>Название товара</mat-label>
                                <input matInput #productName
                                    (focusout)="saveNode(node, productName.value, productKey.value)"
                                    (keyup.enter)="saveNode(node, productName.value, productKey.value)">
                            </mat-form-field>
                            <mat-form-field class="col-4">
                                <mat-label>Ключ товара</mat-label>
                                <input matInput #productKey
                                    (focusout)="saveNode(node, productName.value, productKey.value)"
                                    (keyup.enter)="saveNode(node, productName.value, productKey.value)">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <mat-form-field class="col-4">
                            <mat-label>Система измерения</mat-label>
                            <input matInput #itemValue 
                                (focusout)="saveNode(node, itemValue.value)"
                                (keyup.enter)="saveNode(node, itemValue.value)">
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                        <div class="col-4">
                            <mat-form-field class="col-8">
                                <mat-label>Полное название</mat-label>
                                <input matInput #unitName [matAutocomplete]="measureUnitAuto"
                                    (focusout)="saveNode(node, unitName.value, unitShortName.value)"
                                    (keyup.enter)="saveNode(node, unitName.value, unitShortName.value)">

                                <mat-autocomplete #measureUnitAuto="matAutocomplete" 
                                    (optionSelected)="onMeasureUnitSelected($event.option.value, unitShortName)">
                                    <mat-option *ngFor="let unit of defaultMeasureUnits" [value]="unit.name">{{unit.name}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="col-4">
                                <mat-label>Сокращённое название</mat-label>
                                <input matInput #unitShortName 
                                    (focusout)="saveNode(node, unitName.value, unitShortName.value)"
                                    (keyup.enter)="saveNode(node, unitName.value, unitShortName.value)">
                            </mat-form-field>
                        </div>
                    </ng-container>
                </ng-container>
            </mat-tree-node>
        </ng-container>
        
        <!-- Отображение елементов -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [class.hidden]="!node.expandable">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>

            <ng-container [ngSwitch]="node.level">
                <ng-container *ngSwitchCase="0">
                    <div>
                        <app-editable-text [ngModel]="node.name" (save)="updateNodeProperty(node, $event)" [readonly]="readonly"
                        label="Название категории" class="text-edit"></app-editable-text>
                        <div class="category-total-products">Количество товаров: {{node.total}}</div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <div>
                        <app-editable-text [ngModel]="node.name" (save)="updateNodeProperty(node, $event)" [readonly]="readonly"
                            label="Название товара" class="text-edit"></app-editable-text>
                        <app-editable-text [ngModel]="node.key" (save)="updateNodeProperty(node, $event, 'key')" [readonly]="readonly"
                            label="Ключ товара" class="text-edit"></app-editable-text>
                        <mat-icon (click)="deleteProduct(node)" *hasPermission="[[userRoles.ExecutiveOfficer]]" class="delete-product" title="Удалить">delete</mat-icon>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <app-editable-text [ngModel]="node.name" (save)="updateNodeProperty(node, $event)" [readonly]="readonly"
                        label="Система измерения" class="text-edit"></app-editable-text>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <div class="row">
                        <app-editable-text [ngModel]="node.name" (save)="updateNodeProperty(node, $event)" [readonly]="readonly"
                            label="Полное название единиц измерения" class="text-edit"></app-editable-text>
                        <app-editable-text [ngModel]="node.shortName" (save)="updateNodeProperty(node, $event, 'shortName')"
                            [readonly]="readonly" label="Сокращённое название единиц измерения" class="text-edit"></app-editable-text>
                    </div>
                    </ng-container>
            </ng-container>
            
            <!-- <button mat-icon-button (click)="remove(node)"><mat-icon>remove</mat-icon></button> -->
            <!-- <button *ngIf="node.level < 3" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button> -->
            <tsuz-add-button
                id="id1706085501"
                class="add-button"
                [text]="'Добавить ' + ['товар', 'систему измерений', 'единицу измерений'][node.level]"
                (click)="addNewItem(node)"
                *ngIf="!readonly && node.level < 3">
            </tsuz-add-button>
        </mat-tree-node>
    </mat-tree>

    <tsuz-add-button id="id1706085502" [text]="'Добавить категорию'" (click)="addCategory()" *ngIf="!readonly"></tsuz-add-button>

    <hr />

    <button *ngIf="!readonly" (click)="saveProducts()" class="btn btn--active">Сохранить</button>

</div>