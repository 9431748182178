<div class="support-licensee-points">
  <div class="flex-wrapper header">
    <h5 class="h5" *ngIf="points">Список Точек"</h5>
    <div class="flex-wrapper">
      <a id="id1538477805" class="legend__reset-filtres" (click)="resetFilters()">
        Сбросить фильтры
      </a>
    </div>
  </div>

  <div class="table">
    <ng-container id="id1542712972" *ngIf="points">
      <tsuz-points-page-header class="table__header-row" [filter]="filter" (apply)="applyFilter()"></tsuz-points-page-header>
      
      <ng-container id="id1542712978" *ngFor="let point of points;let i = index">
        <tsuz-point-item id="id1542712990{{i}}" class="table__row" [ngClass]="{'opened': point.isShowDropDown == true}"
             [point]="point" [pointName]="settingList.pointName" isDirector="false"
             [currency]="currency" (changePoint)="onChanged($event)"></tsuz-point-item>
      </ng-container>
    </ng-container>
  </div>

  <app-pagination [(model)]="filter" (apply)="getPoints()"></app-pagination>

</div>
