import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPzuPoint } from '../../../models/points/IPzuPoint';
import { changeFormGroupDisabledState } from '../../../helpers/form.helper';
import { LicenseeModel } from '../../../models/LicenseeModel';
import { UserRoles } from '../../../models/auth/user-roles';

@Component({
  selector: 'tsuz-point-setting',
  templateUrl: './point-setting.component.html',
  styleUrls: ['./point-setting.component.scss']
})
export class PointSettingComponent implements OnInit {
  @Input() point: IPzuPoint;
  @Input() readonly: boolean = false;

  private _licensee: LicenseeModel;
  get licensee(): LicenseeModel { return this._licensee; }
  @Input() set licensee(value: LicenseeModel) {
    this._licensee = value;
  }

  @Output() pointChange = new EventEmitter<any>();
  @Output() updatePointSettings = new EventEmitter<any>();
  @Output() deletePoint = new EventEmitter<any>();
  
  setForm: FormGroup;
  name: FormControl;
  address: FormControl;
  active: FormControl;
  timeZoneIdControl: FormControl;
  readonly userRoles = UserRoles;

  constructor(
  ) { }

  ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    if (this.point) {
      this.name = new FormControl(this.point.title, [Validators.required, Validators.maxLength(256)]);
      this.address = new FormControl(this.point.address, [Validators.required, Validators.maxLength(512)]);
      this.active = new FormControl(this.point.active);
      this.timeZoneIdControl = new FormControl(this.point.timeZoneId);
    } else {
      this.name = new FormControl('', [Validators.required, Validators.maxLength(256)]);
      this.address = new FormControl('', [Validators.required, Validators.maxLength(512)]);
      this.active = new FormControl(this.point.active);
      this.timeZoneIdControl = new FormControl('');
    }
  }

  createForm() {
    this.setForm = new FormGroup({
      Title: this.name,
      Address: this.address,
      Active: this.active,
      TimeZoneId: this.timeZoneIdControl
    });
    
    if (this.readonly) {
      changeFormGroupDisabledState(this.setForm, !this.readonly);
    }
  }

  //отправка новых настроек
  onSubmit() {
    if (this.setForm.valid) {
      this.updatePointSettings.emit({...this.setForm.getRawValue(), pointId: this.point.id});
    }
    //TODO: если не отвязаны работники - нельзя деактивировать
    //this.setForm.controls['Active'].setValue(this.point.active);
  }

}
