<div class="popup3__wrapper">
    <div class="popup3__main">
      <div class="popup__header">
        <h4 class="h4">Отказ оплаты</h4>
        <a id="id1538476538" class="tap-to-close" (click)="closeClick()"></a>
      </div>
      <div class="popup3__pay-info">
        <div class="comment-textarea__block">
          <div class="texarea-label">Причина отказа</div>
          <div class="comment-textarea__wrapper">
            <textarea id="id1538476558" class="comment-textarea" [(ngModel)]="reason" placeholder="Причина отказа..."></textarea>
          </div>
        </div>
      </div>       
      <ng-container>
        <button id="id1538476592" class="btn float-right" [disabled]="!reason || reason.length > 200 || reason.length < 1" [ngClass]="{ 'btn--active': reason && reason.length > 0 && reason.length <= 200 }"  (click)="reject()">Подтвердить</button>        
      </ng-container>
    </div>
</div>
