import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IAuthGuardPermission } from '../models/auth/auth-guard-permission';

// Получение ролей
@Injectable()
export class AuthGuard implements CanActivate {
  state: RouterStateSnapshot;

  constructor(private router: Router,
              @Inject('LOCALSTORAGE') private localStorage: any) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.state = state;
    if (this.localStorage != null && this.localStorage.getItem('isAuth')) {
      return this.decodeRolesJst(route);
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  private decodeRolesJst(route: ActivatedRouteSnapshot): boolean {
    const jwtRole = this.localStorage.getItem('currentRoles') as string;
    let roleName = jwtRole;

    if (!roleName) {
      roleName = 'any';
    }
    const listRoles = roleName.split(',');
    const permissionData = route.data['permission'] as IAuthGuardPermission;
    if (permissionData && permissionData.permittedRoles) {
      return this.hasAuthUserAccessToThisRoute(permissionData.permittedRoles, listRoles);
    }

    return false;
  }

  // check permissions user (if user have role from route data return true)
  private hasAuthUserAccessToThisRoute(permissions: string[], roleList: string[]): boolean {
    if (roleList && roleList.length > 0) {
      for (const roleName of roleList) {
        if (permissions.indexOf(roleName) > -1) {
          return true;
        }
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}
